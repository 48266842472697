import { mergeCells, getHotValue } from './pgridCell.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import lodash from 'lodash';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'
import PGridCell from './pgridCell.js';
import { extractLabel } from '../formula-parser/helper/cell.js';


const DEFAULT_MERGE_STRATIGIES = ["KeepValOnEmptyValStr"];

// Basic Interface to warn, whenever an not overridden method is used
export class PGridLR_Base {

    constructor(x, y, pcell, pgridTableStatic) {
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //when only set schema in child class
            return;
        }
        this.linkedRange = JSON.parse(pcell.RefType);
        this.name = this.linkedRange.LinkedRangeName;
        this.type = this.linkedRange.Type;
        this.x = x;
        this.y = y;
        this.yLength = lodash.get(pcell, "Meta.Load.DimSizeY", null);
        this.xLength = lodash.get(pcell, "Meta.Load.DimSizeX", null);
        this.ySafeOffsetDistance = lodash.get(pcell, "Meta.Load.SafeOffsetDistanceY", null);
        this.xSafeOffsetDistance = lodash.get(pcell, "Meta.Load.SafeOffsetDistanceX", null);


        // this.filterKeyConstrains = lodash.get(this.linkedRange, "FilterKeyConstrains", null);  //To allow multiple LR with same dimenstion to co-exist witout collitions 
        this.lrIsReadOnly = lodash.get(this.linkedRange, "ReadOnly", false);
        this.lrIsHidden = lodash.get(this.linkedRange, "Hidden", false);
        // this.requireAssociatedLRMatch = lodash.get(this.linkedRange, "RequireAssociatedLRMatch", false);
        this.overrideGridKey = PGridUtils.trimAndUpperCaseIfNotNull(lodash.get(this.linkedRange, "OverrideGridKey", null));
        this.overrideTabKey = PGridUtils.trimAndUpperCaseIfNotNull(lodash.get(this.linkedRange, "OverrideTabKey", null));
        this.overrideRemoveOrTranslateCellKeyDims = lodash.get(this.linkedRange, "OverrideRemoveOrTranslateCellKeyDims", null);
        this.lrHotSettings = lodash.get(this.linkedRange, "LRHotSettings", null);
        this.lrPGridSettings = lodash.get(this.linkedRange, "LRPGridSettings", null);
        this.replaceNodeProps = lodash.get(this.linkedRange, "ReplaceNodeProps", null);
        this.overlayCSSClass = lodash.get(this.linkedRange, "OverlayCSSClass", null);

        this.adjacentCellsRecalc = function () {
            let cellAbove = lodash.get(pgridTableStatic, `[${this.y - 1}][${this.x}]`, null);
            let cellLeft = lodash.get(pgridTableStatic, `[${this.y}][${x - 1}]`, null);

            this.adjacentCells = {
                // FUU selfCell: JSON.parse(JSON.stringify(pgridTableStatic[this.y][this.x])),
                selfCell: pgridTableStatic[this.y][this.x],
                aboveCell: (this.y > 0 && cellAbove != null) ? JSON.parse(JSON.stringify(cellAbove)) : null,
                leftCell: (this.x > 0 && cellLeft != null) ? JSON.parse(JSON.stringify(cellLeft)) : null
            }
        }
        this.adjacentCellsRecalc();

        this.axisData = {} //Set in Phase2_Load_DimDataInAxisData
        this.axisMeta = {} //Set in Phase2_Load_DimDataInAxisData
        // this.RowsOffset = 0; //Set in Phase3, used in Phase 3 and 4
        this.errors = Array()
    }

    whoAmI() {
        return 'I am a PGridLR_Base'
    }

    //contect: Server (resolver_common.js)
    //inputs: linked range definition (this.linkedRange<axis>)
    //returns: ["DataSetNameA","DataSetNameB",...]
    Phase1_Get_DataSetDefs({ }) {
        let fName = 'Phase1_Get_DataSetDefs'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    //contect: Server (resolver_common.js)
    //inputs: pgridDim 
    //sets: this.axisData ()
    Phase2_Load_DimDataInAxisData(pgridDim) {

        try {
            const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

            for (let j = 0; j < axisToLoad.length; j++) {

                let axisTypeInDef = axisToLoad[j].value;

                if (!(axisTypeInDef.mapsTo in this.linkedRange)) {
                    throw `Missing dimension: ${axisTypeInDef.mapsTo} in linked range: ${this.name}`;
                }

                this.axisData[axisToLoad[j].key] = Array();

                let lrDimDef = this.linkedRange[axisTypeInDef.mapsTo];

                for (let k = 0; k < lrDimDef.length; k++) {

                    let lrDs = lrDimDef[k];

                    let dsName = lrDs["DataSet"];

                    if (!dsName || !(dsName in pgridDim)) {
                        throw `No dataset "${dsName}" in LinkeReange`;
                    } else {
                        let pgridDimDSCopy = JSON.parse(JSON.stringify(pgridDim[dsName]));
                        if (pgridDimDSCopy === undefined) {
                            throw `Could not find dataset (${dsName}) in response from server`;
                        } else {

                            this.axisData[axisToLoad[j].key].push({
                                Name: dsName,
                                Header: pgridDimDSCopy.shift(),
                                DataSet: pgridDimDSCopy

                            });
                        }
                    }
                }

            }
        } catch (err) {

            let errMsg = `> Phase2_Load_DimDataInAxisData: ${this.name}  got exception: ${err.message || err}`;
            this.errors.push(errMsg);
        }
        return null;
    }

    Phase3_Insert_DynDimData = async function (pgridDataDyn, pgridAxis, addedRowsOffset, state /* ROWS, COLS */) {
        let fName = 'Phase3_Insert_DynDimData'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    Phase3_1_Update_HOTSettings(commit, source = "unknown") {
        false && console.debug(`Phase3_1_Update_HOTSettings() source :${source}`);

        if (this.lrHotSettings) {
            commit('Mutation_UpdateHOTSettings', { prop: 'LRHotSettings', val: this.lrHotSettings, op: 'push', source: `pgridLR_Base Phase3_1_Update_HOTSettings` });
        }
        if (this.lrPGridSettings) {
            commit('Mutation_UpdatePGridSettings', { prop: 'LRPGridSettingsCollection', val: this.lrPGridSettings, op: 'merge', source: `pgridLR_Base Phase3_1_Update_PGridSettings` });
        }
    }

    Phase4_Insert_Metadata(pgridDataDyn, pgridAxis /* FACTS */, linkedRange = null) {
        let fName = 'Phase4_Insert_Metadata'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }


    Phase6_Generate_FactRange(pgridDataDyn, factData) {
        let fName = 'Phase6_Generate_FactRange'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }


    Phase7_Insert_FactRange(pgridDataDyn, hotTable, factRangeData) {

        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_Base.js Phase7_Insert_FactRange() name: ${this.name}` }).y;

        let ret = { pgridDataInserted: null, hotTableInserted: null };

        let hotTableInserted = null;
        let pgridDataInserted = null;
        try {

            pgridDataInserted = lodash.clone(pgridDataDyn); //FUB is clone nessecary?
            hotTableInserted = hotTable //lodash.clone(hotTable);

            if (factRangeData.length > 0
                && this.yLength !== factRangeData.length
                && this.xLength !== factRangeData[0].length) {
                throw `Invalid factRangeData dimension`;
            }


            for (let y = 0; y < this.yLength/*pgridDataLRCell.Meta.Load.DimSizeY*/; y++) {

                for (let x = 0; x < this.xLength /* pgridDataLRCell.Meta.Load.DimSizeX */; x++) {

                    const insertY = this.y + y;
                    const insertX = this.x + x;

                    let insertFact = factRangeData[y][x];

                    if (insertFact != null) {

                        let currCell = pgridDataInserted[insertY][insertX];
                        let mergedCell = mergeCells(currCell, insertFact, ["KeepValStr", "KeepVal"]);

                        pgridDataInserted[insertY][insertX] = mergedCell;


                        if (PGridVueStore.state.LowestRightNonHiddenLR && PGridVueStore.state.LowestRightOptimization
                            &&
                            (
                                insertY > PGridVueStore.state.LowestRightNonHiddenLR.y
                                ||
                                insertX > PGridVueStore.state.LowestRightNonHiddenLR.x
                            )) {
                            //Skipp this
                        } else {
                            let hotValue = getHotValue(mergedCell);
                            hotTableInserted[insertY][insertX] = hotValue;
                        }
                    }
                }
            }

        } catch (err) {
            let errMsg = `> Phase7_Insert_FactRange_error got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }

        ret.pgridDataInserted = pgridDataInserted;
        ret.hotTableInserted = hotTableInserted;

        return ret;
    }


    Phase8_Insert_Overlay(pgridData, hotTable, context, currentEventType = "initEvent") {

        let ret = { pgridDataInserted: null, hotTableInserted: hotTable /* not used */, hotCellsToUpdate: [], formulaWasUpdated: false };

        if (this.name.indexOf("Meta") != -1) {
            console.warn(`Base.Phase8_Insert_Overlay(): META ${this.name}`);
        }

        let lrCoord = PGridMatrices.Get_LRsFromTable(pgridData, { onlyThisLR: this.name, source: `pgridLR_Base.js Phase8_Insert_Overlay() name: ${this.name}` });

        this.y = lrCoord.y;
        this.x = lrCoord.x;


        let extractDynRowMeta = (y, path) => {
            let xSearch = this.x - 1;
            let ySearch = y;
            let dimMetaVal = lodash.get(pgridData[ySearch][xSearch], path, null);
            return dimMetaVal;
        }


        let items = lodash.get(this, "Overlay.Items", []);


        for (let oIdx = 0; oIdx < items.length; oIdx++) {

            let itm = items[oIdx];

            let Description = null; //The overlay item description

            try {


                let Name = lodash.get(itm, "Name", null);
                Description = lodash.get(itm, "Description", null);
                let MergeStrategies = lodash.get(itm, "MergeStrategies", DEFAULT_MERGE_STRATIGIES);


                let Disabled = lodash.get(itm, "Disabled", false);


                let CustomRepeatReplace = lodash.clone(lodash.get(itm, "RepeatReplace", [{}]));

                for (let crr_idx = 0; crr_idx < CustomRepeatReplace.length; crr_idx++) {

                    let rr = CustomRepeatReplace[crr_idx];

                    let CellTemplate = lodash.clone(lodash.get(itm, "CellTemplate", null));
                    let PGridRowProps = lodash.clone(lodash.get(itm, "PGridRowProps", null));
                    let FormatClassesRemove = lodash.clone(lodash.get(itm, "FormatClassesRemove", null));
                    let FormatClassesAdd = lodash.clone(lodash.get(itm, "FormatClassesAdd", null));
                    let ReportAttest = lodash.clone(lodash.get(itm, "ReportAttest", null));
                    let HotCellBehavior = lodash.clone(lodash.get(itm, "HotCellBehavior", null));

                    if (CellTemplate || ReportAttest || HotCellBehavior || FormatClassesRemove || FormatClassesAdd) {

                        let customReapeatForEach = null;


                        if (customReapeatForEach == null) {
                            // FUU let ForEachCount = lodash.clone(lodash.get(rr, "ForEachCount", null));
                            let ForEachCount = lodash.get(rr, "ForEachCount", null);

                            if (ForEachCount) {

                                let ForEachCountStr = JSON.stringify(ForEachCount);
                                if (ForEachCountStr.indexOf('[<<') != -1) {
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<GRID_TABLE_EDGE_Y>>]', pgridData.length);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<GRID_TABLE_EDGE_X>>]', pgridData[0].length);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_Y>>]', this.y);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_X>>]', this.x);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_DATA_Y>>]', this.y + this.yLengthHeaders);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_DATA_X>>]', this.x + this.xLengthHeaders);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_LENGTH_Y>>]', this.yLength);
                                    ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_LENGTH_X>>]', this.xLength);
                                    ForEachCount = JSON.parse(ForEachCountStr);
                                }

                                customReapeatForEach = [];

                                let start = ForEachCount.Start;
                                let count = ForEachCount.Count;
                                let max = ForEachCount.Max;

                                for (let nIdx = 0; max ? Number(start) + nIdx <= Number(max) : nIdx < Number(count); nIdx++) {

                                    customReapeatForEach.push(Number(start) + nIdx);
                                }
                            }
                        }

                        if (customReapeatForEach == null) {
                            customReapeatForEach = lodash.clone(lodash.get(rr, "ForEach", [null]));
                        }

                        // console.log(`Got this fine result for targetCellCoord (${itm.Address}) = ${JSON.stringify(targetCellCoord)}`);

                        let CellTemplate_tmpl = null;
                        let PGridRowProps_tmpl = null;


                        //CellTemplate_tmpl and PGridRowProps_tmplis used insiede forEach loop
                        if (CellTemplate) {
                            CellTemplate_tmpl = JSON.stringify(CellTemplate);
                        }
                        if (PGridRowProps) {
                            PGridRowProps_tmpl = JSON.stringify(PGridRowProps);
                        }

                        for (let crfe_idx = 0; crfe_idx < customReapeatForEach.length; crfe_idx++) {


                            let crfe = customReapeatForEach[crfe_idx];


                            let ruleApprove_ALL_RESULTS = [];
                            let ruleApprove_ALL_OUTCOME = null;
                            // let ruleApprove = false;

                            if (Disabled) {
                                ruleApprove_ALL_OUTCOME = false;
                            }
                            else {


                                //Collect rules. One (zero) or Many

                                //Many
                                let rules = lodash.get(itm, "Rules", []);

                                // One (or zero)
                                if (rules.length == 0) {
                                    let rule = lodash.get(itm, "Rule", null); //If single rule

                                    rules = [rule]; //Also add "null" rules
                                }

                                for (let ru = 0; rules.length > ru; ru++) {

                                    let rule = rules[ru];

                                    let ruleApprove = false;


                                    if (currentEventType.indexOf("initEvent") != -1) {
                                        ruleApprove = true; //If currentEventType is initEvent approve "null" rules, but only then
                                    }


                                    if (rule) {

                                        rule = JSON.parse(JSON.stringify(rule).replaceAll(rr.Replace, crfe));


                                        let Rule__If_RowMeta = lodash.clone(lodash.get(rule, "If_RowMeta", undefined));
                                        let Rule__If_RowMeta__Path = lodash.clone(lodash.get(rule, "If_RowMeta.Path", undefined));
                                        let Rule__If_RowMeta__Equals = lodash.clone(lodash.get(rule, "If_RowMeta.Equals", undefined));
                                        let Rule__If_RowMeta__LargerThan = lodash.clone(lodash.get(rule, "If_RowMeta.LargerThan", undefined));


                                        let If_Cell_Value_Is_True = lodash.get(rule, "If_Cell_Value_Is_True", null);
                                        let If_Cell_Value_Is_True_Or_Anything = lodash.get(rule, "If_Cell_Value_Is_True_Or_Anything", false);

                                        let If_Cell_Value_Is_True_Inverse = lodash.get(rule, "If_Cell_Value_Is_True_Inverse", false);

                                        let If_Cell_Is_Selected = lodash.get(rule, "If_Cell_Is_Selected", null);
                                        let If_Cell_Is_Selected_Inverse = lodash.get(rule, "If_Cell_Is_Selected_Inverse", false);

                                        let If_Grid_Have_Unsaved_Facts = lodash.get(rule, "If_Grid_Have_Unsaved_Facts", null);
                                        let If_Grid_Have_Unsaved_Facts_Inverse = lodash.get(rule, "If_Grid_Have_Unsaved_Facts_Inverse", null);


                                        let If_Cell_Value_Is_True__CellToExamine = null;
                                        let If_Cell_Is_Selected__CellToExamine = null;

                                        if (currentEventType.indexOf("initEvent") != -1) {
                                            //Register cellsThatAreReferencingLinkedRangeOverlays on init

                                            ruleApprove = true; //Default apply on init

                                            //If_Cell_Calue_Is_True
                                            if (If_Cell_Value_Is_True) {
                                                If_Cell_Value_Is_True__CellToExamine = PGridUtils.extractDynAddr(this, pgridData, If_Cell_Value_Is_True);
                                                context.commit('Mutation_UpdatePGridSettings', {
                                                    prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                    , op: 'addkey'
                                                    , key: `overlay_postcalc:${If_Cell_Value_Is_True__CellToExamine.y}:${If_Cell_Value_Is_True__CellToExamine.x}`
                                                    , mergeValue: true
                                                    , val: [`${this.name}:${this.y}:${this.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                                });
                                            }

                                            //If_Cell_Is_Selected
                                            if (If_Cell_Is_Selected) {
                                                If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(this, pgridData, If_Cell_Is_Selected);
                                                context.commit('Mutation_UpdatePGridSettings', {
                                                    prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                    , op: 'addkey'
                                                    , key: `overlay_selectionchange:${If_Cell_Is_Selected__CellToExamine.y}:${If_Cell_Is_Selected__CellToExamine.x}`
                                                    , mergeValue: true
                                                    , val: [`${this.name}:${this.y}:${this.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                                });
                                            }

                                            if (If_Grid_Have_Unsaved_Facts) {
                                                context.commit('Mutation_UpdatePGridSettings', {
                                                    prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                    , op: 'addkey'
                                                    , key: `overlay_postcalc:-1:-1`
                                                    , mergeValue: true
                                                    , val: [`${this.name}:${this.y}:${this.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                                });

                                                context.commit('Mutation_UpdatePGridSettings', {
                                                    prop: 'attestRequired'
                                                    , val: true
                                                    , source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                                });
                                            }

                                        } else {
                                            ruleApprove = false;
                                        }



                                        if (If_Cell_Value_Is_True) {

                                            ruleApprove = false;


                                            if (currentEventType.indexOf("postcalcEvent") != -1) {

                                                if (If_Cell_Value_Is_True__CellToExamine == null) {
                                                    If_Cell_Value_Is_True__CellToExamine = PGridUtils.extractDynAddr(this, pgridData, If_Cell_Value_Is_True);
                                                }


                                                let cellValue = hotTable[If_Cell_Value_Is_True__CellToExamine.y][If_Cell_Value_Is_True__CellToExamine.x];

                                                if (PGridUtils.IsNumeric(cellValue) && Number(cellValue) > 0) {
                                                    ruleApprove = true;
                                                } else {
                                                    if (cellValue == "Ja" || cellValue == "Yes" || cellValue == "True"
                                                    ) {
                                                        ruleApprove = true;
                                                    }
                                                }

                                                if (If_Cell_Value_Is_True_Or_Anything) {
                                                    ruleApprove = !!cellValue;
                                                }
                                                if (If_Cell_Value_Is_True_Inverse) {
                                                    ruleApprove = !ruleApprove;
                                                }
                                            }



                                        }


                                        if (currentEventType.indexOf("selectionchangeEvent") != -1 && If_Cell_Is_Selected) {

                                            ruleApprove = false;


                                            let lastSel = null;
                                            if (context.state.lastSelected) {
                                                lastSel = JSON.parse(context.state.lastSelected)

                                                if (If_Cell_Is_Selected__CellToExamine == null) {
                                                    If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(this, pgridData, If_Cell_Is_Selected);
                                                }

                                                if (lastSel.y == If_Cell_Is_Selected__CellToExamine.y && lastSel.x == If_Cell_Is_Selected__CellToExamine.x) {
                                                    ruleApprove = true;
                                                }

                                                if (If_Cell_Is_Selected_Inverse) {
                                                    ruleApprove = !ruleApprove;
                                                }
                                            }

                                        }

                                        if (currentEventType.indexOf("unsavedfactsEvent") != -1 && If_Grid_Have_Unsaved_Facts) {

                                            ruleApprove = false;


                                            if (context.state.pgridSettings.attestStatusOK == false) {
                                                ruleApprove = true;
                                            }

                                            if (If_Grid_Have_Unsaved_Facts_Inverse) {
                                                ruleApprove = !ruleApprove;
                                            }
                                        }



                                        //Not sure if currentEventType.indexOf("selectionchangeEvent") != -1 && ....
                                        if (Rule__If_RowMeta) {

                                            ruleApprove = false;


                                            if (Rule__If_RowMeta__Path != undefined) {
                                                let metaPathVal = extractDynRowMeta(crfe - 1/*acume crfe is the row*/, Rule__If_RowMeta__Path);

                                                if (Rule__If_RowMeta__Equals != undefined) {

                                                    if (String(Rule__If_RowMeta__Equals) == String(metaPathVal)) {
                                                        ruleApprove = true;
                                                    }
                                                } else if (Rule__If_RowMeta__LargerThan != undefined) {
                                                    if (PGridUtils.IsNumeric(metaPathVal) && (Number(metaPathVal) > Number(Rule__If_RowMeta__LargerThan))) {
                                                        ruleApprove = true;
                                                    }
                                                }
                                            }

                                            // if (!ruleApprove) {
                                            //     //Did not pass Rule__If_RowMeta resolved ruleApprove == false
                                            //     return;
                                            // }
                                        }

                                    } //end if(rule)


                                    ruleApprove_ALL_RESULTS.push(ruleApprove);

                                } //end of for rules


                                ruleApprove_ALL_OUTCOME = ruleApprove_ALL_RESULTS.every(x => x === true);
                            }

                            window.PGridClientDebugMode >= 3 && console.debug(`Insert overlay ${crfe_idx} ${currentEventType} item: ${Name}, ${Description}  noRuleFaild: ${ruleApprove_ALL_OUTCOME} `);


                            if (true) {
                                if (ReportAttest && currentEventType.indexOf("postcalcEvent") != -1 /* assumes If_Cell_Value_Is_True */) {
                                    //Handle attest data

                                    context.commit('Mutation_UpdatePGridSettings', {
                                        prop: 'attestStatusOK'
                                        , op: 'set'
                                        , val: ruleApprove_ALL_OUTCOME
                                    });
                                }

                                if (!ruleApprove_ALL_OUTCOME) {
                                    //Did not pass the above tests;
                                    continue;
                                }

                                // If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(this, pgridData, If_Cell_Is_Selected);

                                if (HotCellBehavior && currentEventType.indexOf("selectionchangeEvent") != -1 /* assumes If_Cell_Value_Is_True */) {
                                    //Handle attest data

                                    // If_Cell_Is_Selected
                                    // if (row == row2 && column == column2) {
                                    //     if (context.state.hotRef.getActiveEditor != null) {
                                    // actEditor = context.state.hotRef.getActiveEditor();
                                    if (lodash.get(HotCellBehavior, "EnableFullEditor", false)) {
                                        context.state.hotRef.getActiveEditor().enableFullEditMode();
                                        context.state.hotRef.getActiveEditor().beginEditing();
                                    }
                                    //     }
                                    // }
                                }


                                if (CellTemplate || PGridRowProps) {

                                    try {

                                        //CellTemplate

                                        // let CellTemplate_tmpl = JSON.stringify(CellTemplate);

                                        let targetAddresses = itm.Address.split(",");

                                        let targetAddressOffsetYOverrride = lodash.get(itm, "AddressOffsetYOverrride", null);
                                        let targetAddressOffsetXOverrride = lodash.get(itm, "AddressOffsetXOverrride", null);

                                        //FUB Put into general translate function? 
                                        if (targetAddressOffsetYOverrride != null) {
                                            if (!PGridUtils.IsNumeric(targetAddressOffsetYOverrride)) {
                                                targetAddressOffsetYOverrride = JSON.parse(JSON.stringify(targetAddressOffsetYOverrride).replaceAll('[<<SAFE_OFFSET_DISTANCE_Y>>]', this.ySafeOffsetDistance));

                                                if (!PGridUtils.IsNumeric(targetAddressOffsetYOverrride)) {
                                                    throw new Error(`Phase8_Insert_overlay() got exception: Could not parse targetAddressOffsetYOverrride number from: ${targetAddressOffsetYOverrride} `);
                                                }
                                                targetAddressOffsetYOverrride = Number(targetAddressOffsetYOverrride);
                                            }
                                        }

                                        //FUB Put into general translate function? 
                                        if (targetAddressOffsetXOverrride != null) {
                                            if (!PGridUtils.IsNumeric(targetAddressOffsetXOverrride)) {
                                                targetAddressOffsetXOverrride = JSON.parse(JSON.stringify(targetAddressOffsetXOverrride).replaceAll('[<<SAFE_OFFSET_DISTANCE_X>>]', this.xSafeOffsetDistance));

                                                if (!PGridUtils.IsNumeric(targetAddressOffsetXOverrride)) {
                                                    throw new Error(`Phase8_Insert_overlay() got exception: Could not parse targetAddressOffsetXOverrride number from: ${targetAddressOffsetXOverrride} `);
                                                }
                                                targetAddressOffsetXOverrride = Number(targetAddressOffsetXOverrride);
                                            }
                                        }


                                        for (let ta_idx = 0; ta_idx < targetAddresses.length; ta_idx++) {

                                            let targetAddress = targetAddresses[ta_idx];

                                            let CellTemplate_acc = CellTemplate_tmpl;
                                            let PGridRowProps_acc = PGridRowProps_tmpl;

                                            if (crfe != null) {

                                                //Address                                    
                                                targetAddress = targetAddress.replaceAll(rr.Replace, crfe);

                                                let InsertInFormulaByTheseRows = [null];  //Always one "empty" row at least

                                                {
                                                    let InnerRepeatRows = lodash.clone(lodash.get(itm, "InnerRepeatRows", null));

                                                    //Fill up InserByRows if InnerRepeatRows if definded

                                                    if (InnerRepeatRows) {

                                                        let Rule__If_RowMeta = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rule.If_RowMeta", undefined));
                                                        let Rule__If_RowMeta__Path = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rule.If_RowMeta.Path", undefined));
                                                        let Rule__If_RowMeta__Equals = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rule.If_RowMeta.Equals", undefined));
                                                        let Rule__If_RowMeta__Not_Equals = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rule.If_RowMeta.Not_Equals", undefined));
                                                        let Rows__Start_After_Row_Equals = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rows.Start_After_Row_Equals", undefined));
                                                        let Rows__Start_After_Row_Match = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rows.Start_After_Row_Match", undefined));
                                                        let Rows__Stop_Before_Row_Equals = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rows.Stop_Before_Row_Equals", undefined));
                                                        let Rows__Stop_Before_Row_Match = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rows.Stop_Before_Row_Match", undefined));
                                                        let Rows__Stop_RepeatRows_On_NoMatch = lodash.clone(lodash.get(itm, "InnerRepeatRows.Rows.Stop_RepeatRows_On_NoMatch", undefined));

                                                        let First_NonValid_Found = false;

                                                        InsertInFormulaByTheseRows = [];

                                                        let innerRepeatIsActive = true;

                                                        if (Rows__Start_After_Row_Equals != undefined || Rows__Start_After_Row_Match != undefined) {
                                                            innerRepeatIsActive = false
                                                        }

                                                        for (let inRepIdx = 0; inRepIdx < this.yLength && First_NonValid_Found == false; inRepIdx++) { //For all rows (i)

                                                            let iterY = this.y + inRepIdx;

                                                            if (innerRepeatIsActive) {

                                                                let metaPathVal = extractDynRowMeta(iterY, "Meta.DimVal", null);
                                                                if (metaPathVal != null) {
                                                                    if (Rows__Stop_Before_Row_Match != undefined
                                                                        ? (new RegExp(Rows__Stop_Before_Row_Match)).test(`[${String(metaPathVal)}]`)
                                                                        : String(Rows__Stop_Before_Row_Equals) == `[${String(metaPathVal)}]`) {
                                                                        innerRepeatIsActive = false;
                                                                        continue; //The "Before" row equals
                                                                    }
                                                                }

                                                                let ruleGo = true;
                                                                let ruleForceNoGo = false;
                                                                let ruleStopOnFirstNoGo = false;

                                                                if (Rule__If_RowMeta) {

                                                                    if (Rule__If_RowMeta__Path != undefined) {
                                                                        let metaPathVal = extractDynRowMeta(iterY, Rule__If_RowMeta__Path);

                                                                        ruleGo = true;
                                                                        if (Rule__If_RowMeta__Equals != undefined) {

                                                                            if (String(Rule__If_RowMeta__Equals) == String(metaPathVal)) {
                                                                                ruleGo = true;
                                                                            } else {
                                                                                ruleGo = false;
                                                                            }
                                                                        }

                                                                        if (Rule__If_RowMeta__Not_Equals != undefined) {

                                                                            if (String(Rule__If_RowMeta__Not_Equals) != String(metaPathVal)) {
                                                                                ruleGo = true;
                                                                            } else {
                                                                                ruleGo = false;
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                if (ruleGo/* && ruleForceNoGo == false*/) {
                                                                    InsertInFormulaByTheseRows.push(this.y + inRepIdx); //Rows to process for formula
                                                                } else {
                                                                    if (Rows__Stop_RepeatRows_On_NoMatch) {
                                                                        First_NonValid_Found = true;
                                                                    }
                                                                }
                                                            }
                                                            else {

                                                                let metaPathVal = extractDynRowMeta(iterY, "Meta.DimVal", null);
                                                                if (metaPathVal != null) {
                                                                    if (Rows__Stop_Before_Row_Match != undefined
                                                                        ? (new RegExp(Rows__Start_After_Row_Match)).test(`[${String(metaPathVal)}]`)
                                                                        : String(Rows__Start_After_Row_Equals) == `[${String(metaPathVal)}]`) {
                                                                        innerRepeatIsActive = true;
                                                                    }

                                                                }

                                                            }
                                                        }

                                                    }
                                                }

                                                //Formula

                                                let RepeatRowsReplaceWithRow = lodash.clone(lodash.get(itm, "InnerRepeatRows.ReplaceWithRow", "<<ROW>>"));
                                                let RepeatRowsInsertBefore = lodash.clone(lodash.get(itm, "InnerRepeatRows.InsertBefore", "<<INSERT>>"));
                                                let RepeatRowsInsertTemplate = lodash.clone(lodash.get(itm, "InnerRepeatRows.InsertTemplate", "+<<COLUMN>><<ROW>>"));


                                                for (let iifbtr_idx = 0; iifbtr_idx < InsertInFormulaByTheseRows.length; iifbtr_idx++) {


                                                    let rowForFormula = InsertInFormulaByTheseRows[iifbtr_idx];


                                                    if (rowForFormula != null) {

                                                        let indexOfInsert = CellTemplate_acc.indexOf(RepeatRowsInsertBefore);

                                                        if (indexOfInsert != -1) {

                                                            let insertStr = RepeatRowsInsertTemplate.replaceAll(RepeatRowsReplaceWithRow, rowForFormula + 1) //Replace <<ROW>> to Excel style row number

                                                            CellTemplate_acc = PGridUtils.spliceSplit(CellTemplate_acc, indexOfInsert, 0, insertStr);
                                                        }
                                                    }
                                                }


                                                if (RepeatRowsInsertBefore) {
                                                    if (CellTemplate) {
                                                        CellTemplate_acc = CellTemplate_acc.replaceAll(RepeatRowsInsertBefore, '');
                                                    }
                                                    if (PGridRowProps) {
                                                        PGridRowProps_acc = PGridRowProps_acc.replaceAll(RepeatRowsInsertBefore, '');
                                                    }
                                                }

                                                //The "normal custom repeat stuff"
                                                if (CellTemplate) {
                                                    CellTemplate_acc = CellTemplate_acc.replaceAll(rr.Replace, crfe); //Custom reapeat replaces, usually columns 
                                                }
                                                if (PGridRowProps) {
                                                    PGridRowProps_acc = PGridRowProps_acc.replaceAll(rr.Replace, crfe); //Custom reapeat replaces, usually rows 
                                                }
                                            }

                                            //Parse back the string modified cell object 
                                            if (CellTemplate) {
                                                CellTemplate = JSON.parse(CellTemplate_acc);
                                            }
                                            if (PGridRowProps) {
                                                PGridRowProps = JSON.parse(PGridRowProps_acc);
                                            }


                                            if (CellTemplate) {


                                                //Extract dynamic target cell address and dynamic references in CellTemplate
                                                //Allow ranges in this form "A1:B2"
                                                let startCell = null;
                                                let endCell = null;

                                                if (targetAddress.indexOf(":") != -1) {

                                                    try {
                                                        startCell = PGridUtils.extractDynAddr(this, pgridData, targetAddress.split(":")[0]);
                                                        endCell = PGridUtils.extractDynAddr(this, pgridData, targetAddress.split(":")[1], startCell);
                                                    } catch (err) {
                                                        throw new Error(`Phase8_Insert_overlay() got exception: Could not find targetAddres: ${targetAddress} `);
                                                    }

                                                    if (startCell.y > endCell.y) { throw new Error("End cell y is smaller than start cells") };
                                                    if (startCell.x > endCell.x) { throw new Error("End cell x is smaller than start cells") };

                                                }
                                                else {
                                                    //Single cell range in this form "A1"
                                                    startCell = endCell = PGridUtils.extractDynAddr(this, pgridData, targetAddress);
                                                }

                                                for (let yIter = startCell.y; yIter < endCell.y + 1; yIter++) {

                                                    for (let xIter = startCell.x; xIter < endCell.x + 1; xIter++) {


                                                        let targetCell = { y: yIter, yOffset: 0, x: xIter, xOffset: 0 };
                                                        let targetCell_FormatOfSaveHasChanged = false;

                                                        if (targetAddressOffsetYOverrride != null) {
                                                            targetCell.yOffset = targetAddressOffsetYOverrride;
                                                        }
                                                        if (targetAddressOffsetXOverrride != null) {
                                                            targetCell.xOffset = targetAddressOffsetXOverrride;
                                                        }

                                                        let finalCell_y = targetCell.y + targetCell.yOffset
                                                        let finalCell_x = targetCell.x + targetCell.xOffset

                                                        let originalTemplateFormula = null;
                                                        if ("Formula" in CellTemplate) {

                                                            originalTemplateFormula = CellTemplate.Formula;

                                                            if (originalTemplateFormula != null && (originalTemplateFormula.indexOf("[") != -1 || originalTemplateFormula.indexOf("{") != -1)) {
                                                                CellTemplate.Formula = PGridUtils.replaceAllDynAddr(this, pgridData, originalTemplateFormula);
                                                            }

                                                            false && console.log(`Got this fine result for targetCellCoord(${targetAddress}) = ${JSON.stringify(targetCell)}  ${originalTemplateFormula} => ${CellTemplate.Formula} `);
                                                        }

                                                        let old_cell = lodash.clone(pgridData[targetCell.y][targetCell.x]);
                                                        // let hotCellVal_old = PGridCell.getHotValue2(old_cell);
                                                        let merged_cell = mergeCells(pgridData[targetCell.y][targetCell.x], CellTemplate, MergeStrategies);
                                                        // let hotCellVal_examineNew = PGridCell.getHotValue2(merged_cell);





                                                        if (false) {//Never used
                                                            if (UpdateHotData) {
                                                                true && console.debug(`Phase8_InsertOverlay() updating hot data(${targetCell.y}: ${targetCell.x})`)

                                                                let hotCellVal = PGridCell.getHotValue2(merged_cell);
                                                                ret.hotCellsToUpdate.push([targetCell.y + targetCell.yOffset, targetCell.x + targetCell.xOffset, hotCellVal]);

                                                                PGridCell.expandHOTIfNeeded(hotTable, targetCell.y + targetCell.yOffset, targetCell.x + targetCell.xOffset, undefined);
                                                                hotTable[targetCell.y + targetCell.yOffset][targetCell.x + targetCell.xOffset] = hotCellVal

                                                                ret.hotTableInserted = hotTable; //This incicates to caller that data was inserted to HOT
                                                            }
                                                        }

                                                        let formulaReferenseText = null


                                                        if (window.PGridClientDebugMode >= 3) {
                                                            formulaReferenseText = `Overlay(${window.PGridDTC.CallCount}): `;
                                                        } else {
                                                            formulaReferenseText = `Overlay: `;
                                                        }

                                                        if (Description || originalTemplateFormula) {
                                                            if (Description) {
                                                                formulaReferenseText += `${Description} `;
                                                            }

                                                            if (originalTemplateFormula) {
                                                                if (Description) {
                                                                    formulaReferenseText += `: \n`;
                                                                }
                                                                formulaReferenseText += `${originalTemplateFormula} `;
                                                            }
                                                        }

                                                        if (formulaReferenseText) {
                                                            let currentReferences = lodash.get(pgridData[targetCell.y][targetCell.x], "Meta.References", []);
                                                            if (currentReferences.indexOf(formulaReferenseText) == -1) {
                                                                currentReferences.push(formulaReferenseText);
                                                            }
                                                            lodash.set(merged_cell, "Meta.References", currentReferences);
                                                        }

                                                        // if(finalCell_y == 22 && finalCell_x == 18){
                                                        //     let foo = "stop here"
                                                        // }

                                                        PGridCell.expandIfNeeded(pgridData, finalCell_y, finalCell_x, undefined);
                                                        pgridData[finalCell_y][finalCell_x] = merged_cell; //lodash.merge(pgridDataDyn[targetCell.y][targetCell.x], CellTemplate);


                                                        let isOutsideLowestRightNonHiddenCell = function (LowestRightNonHiddenCell, y, x) {
                                                            let ret = false;
                                                            if (context.state.LowestRightNonHiddenCell && context.state.LowestRightOptimization
                                                                &&
                                                                (
                                                                    targetCell.y > context.state.LowestRightNonHiddenCell.y
                                                                    ||
                                                                    targetCell.x > context.state.LowestRightNonHiddenCell.x
                                                                )
                                                            ) {
                                                                ret = true;
                                                            }

                                                            return ret;
                                                        }


                                                        if (isOutsideLowestRightNonHiddenCell(context.state.LowestRightNonHiddenCell, targetCell.y, targetCell.x)) {
                                                            //Skipp out of counds HOT cell
                                                        }
                                                        else {

                                                            //After Handle add and remove classes on merged_cell object (linked above to pgridData)*/

                                                            if (FormatClassesAdd) {

                                                                for (let d = 0; d < FormatClassesAdd.length; d++) {

                                                                    let formatClass = FormatClassesAdd[d];


                                                                    if (!("Format" in merged_cell)) {
                                                                        merged_cell.Format = formatClass; //Just add
                                                                    } else {
                                                                        let CellTemplate_acc_Format = merged_cell.Format.split(",");

                                                                        if (CellTemplate_acc_Format.indexOf(formatClass) == -1) {
                                                                            window.PGridClientDebugMode > 3 && console.debug(`Phase8_Insert_Overlay() adding class:  ${formatClass} `)
                                                                            CellTemplate_acc_Format.push(formatClass);
                                                                        }

                                                                        merged_cell.Format = CellTemplate_acc_Format.join(",");
                                                                    }
                                                                }
                                                            }


                                                            if (FormatClassesRemove) {

                                                                for (let d = 0; d < FormatClassesRemove.length; d++) {

                                                                    let formatClass = FormatClassesRemove[d];

                                                                    if ("Format" in merged_cell) {
                                                                        let CellTemplate_Format = merged_cell.Format.split(",");

                                                                        let q = CellTemplate_Format.length
                                                                        while (q--) {
                                                                            let CellTemplate_Format_class = CellTemplate_Format[q];
                                                                            if (CellTemplate_Format_class === formatClass) {
                                                                                window.PGridClientDebugMode > 3 && console.debug(`Phase8_Insert_Overlay() removeing class:  ${formatClass} `)
                                                                                CellTemplate_Format.splice(q, 1);
                                                                            }
                                                                        }

                                                                        merged_cell.Format = CellTemplate_Format.join(",");
                                                                    }
                                                                }
                                                            }




                                                            function getNested(obj, ...args) {
                                                                return args.reduce((obj, level) => obj && obj[level], obj)
                                                            }

                                                            // let addOrUpdateToHotCellsToUpdate = function (hotCellsToUpdate, newY, newX, newVal) {

                                                            //     let foundCurrent = hotCellsToUpdate.find(o => {
                                                            //         // let [oy, ox, oval] = o; // .split(":");
                                                            //         return o[0] == newY && o[1] == newX;
                                                            //     });
                                                            //     if (foundCurrent) {
                                                            //         let [fy, fx, fval] = foundCurrent;

                                                            //         if (fval != newVal) {
                                                            //             console.warn(`fval != refValue`);
                                                            //             hotCellsToUpdate = hotCellsToUpdate.filter(q => q[0] == newY && q[1] == newX);
                                                            //         }
                                                            //     }

                                                            //     hotCellsToUpdate.push([newY, newX, newVal]);

                                                            // }

                                                            function RecurseAddRefCells(cellsThatAreReferenced, hotCellsToUpdate, y, x, val, depth = 0) {


                                                                PGridCell.addOrUpdateToHotCellsToUpdate(hotCellsToUpdate, y, x, val);


                                                                let cellRefKey = y + ":" + x;
                                                                // let cellsThatAreReferencedKeyList = Object.keys(context.state.pgridSettings.cellsThatAreReferenced);
                                                                let cellsThatAreReferencedKeyList_parents = cellsThatAreReferenced[cellRefKey];
                                                                if (cellsThatAreReferencedKeyList_parents) {
                                                                    for (let k = 0; k < cellsThatAreReferencedKeyList_parents.length; k++) {
                                                                        let searchRefKey = cellsThatAreReferencedKeyList_parents[k];

                                                                        // let searchRefs = context.state.pgridSettings.cellsThatAreReferenced[searchRefKey];

                                                                        // for (let i = 0; i < searchRefs.length; i++) {
                                                                        //     if (searchRefs[i] == cellRefKey) {
                                                                        let [refYStr, refXStr] = searchRefKey.split(":");
                                                                        let [refY, refX] = [Number(refYStr), Number(refXStr)];
                                                                        let refValue = PGridCell.getHotValue2(pgridData[refY][refX]);

                                                                        //Recursive call
                                                                        RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, hotCellsToUpdate, refY, refX, refValue, depth + 1);

                                                                        // }
                                                                        // }
                                                                    }
                                                                }
                                                            }



                                                            let old_cell_format = "Format" in (old_cell || {}) ? old_cell.Format : null;
                                                            let merged_cell_format = "Format" in (merged_cell || {}) ? merged_cell.Format : null;




                                                            //Looking for changes that required rendering of ht cell
                                                            if (old_cell_format != merged_cell_format) {
                                                                targetCell_FormatOfSaveHasChanged = true;
                                                            } else {

                                                                let old_cell_readonly = getNested(old_cell, "Meta", "Save", "ReadOnly") || false;
                                                                let merged_cell_readonly = getNested(merged_cell, "Meta", "Save", "ReadOnly") || false;

                                                                if (old_cell_readonly != merged_cell_readonly) {

                                                                    targetCell_FormatOfSaveHasChanged = true;
                                                                } else {

                                                                    let old_cell_writable = getNested(old_cell, "Meta", "Save", "Writable");
                                                                    let merged_cell_writable = getNested(merged_cell, "Meta", "Save", "Writable");

                                                                    if (old_cell_writable != merged_cell_writable) {
                                                                        targetCell_FormatOfSaveHasChanged = true;
                                                                    }
                                                                }
                                                            }


                                                            //Looking for changes in Meta.Format
                                                            if (JSON.stringify(lodash.get(old_cell, "Meta.Format", null)) != JSON.stringify(lodash.get(merged_cell_format, "Meta.Format", null))) {
                                                                targetCell_FormatOfSaveHasChanged = true;
                                                            }


                                                            let oldValFormula = PGridCell.getFormula(old_cell);
                                                            let newValFormula = PGridCell.getFormula(merged_cell);
                                                            // let oldAndNewDiffsInHasFormula = oldValHasFormula != newValHasFormula;
                                                            let oldValVal = PGridCell.getHotValue2(old_cell);
                                                            let newValVal = PGridCell.getHotValue2(merged_cell);


                                                            if (PGridCell.isFormula(oldValFormula) || PGridCell.isFormula(newValFormula)) {


                                                                if (oldValFormula != newValFormula /* hotCellVal_old !== hotCellVal_examineNew */) {

                                                                    ret.formulaWasUpdated = true;

                                                                    delete merged_cell.Val;
                                                                    delete merged_cell.ValStr;

                                                                    // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValFormula]);
                                                                    RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                                }
                                                            } else {
                                                                if (targetCell_FormatOfSaveHasChanged) {

                                                                    // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValVal]);
                                                                    RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                                } else if (oldValVal != newValVal) {

                                                                    // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValVal]);
                                                                    RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                                }
                                                            }


                                                        }

                                                    }
                                                }
                                            }

                                            if (PGridRowProps) {

                                                if (!this.lrHotSettings) {
                                                    this.lrHotSettings = {};
                                                }
                                                if (!("Rows" in this.lrHotSettings)) {
                                                    this.lrHotSettings["Rows"] = {};
                                                }
                                                let tableRowCell = extractLabel(targetAddress); //Only supports single cell targets for now
                                                let tableRowIdx = tableRowCell[0].index + 1;


                                                let Row_PGridRowProps = {};
                                                Row_PGridRowProps[tableRowIdx] = PGridRowProps;

                                                lodash.merge(this.lrHotSettings.Rows, Row_PGridRowProps);
                                            }
                                        }

                                        if (this.lrHotSettings) {
                                            context.commit('Mutation_UpdatePGridSettings', { prop: "LRPGridSettingsCollection", val: this.lrHotSettings, op: 'merge', source: `Phase8_Insert_Overlay()` });
                                        }

                                    } catch (err2) {
                                        throw new Error(`Phase8_Insert_overlay() CellTemplate apply got exception: ${err2.message} `);
                                    }
                                }
                            }
                        }
                    }
                }

            } catch (err) {

                if (window.PGridClientDebugMode >= 3) {
                    let errMsg = `Phase8_Insert_Overlay()(${this.name}) got exception for Overlay item('${Description}' oIdx: ${oIdx}) ${PGridUtils.truncString(err.message, 10000)} `;
                    console.warn(errMsg, itm);
                } else {
                    if (err.message.indexOf("Phase8_Insert_overlay() got exception: Could not find targetAddres") != -1 || err.message.indexOf("was not found in any cells in LR:s") != -1) {
                        //Mute this error
                    }
                    else {
                        let errMsg = `Phase8_Insert_Overlay()(${this.name}) got exception for Overlay item('${Description}' oIdx: ${oIdx}) ${PGridUtils.truncString(err.message, 150)} `;
                        console.warn(errMsg);
                    }
                }
            }
        }

        ret.pgridDataInserted = pgridData;

        return ret;
    }
}

