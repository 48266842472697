<template>
  <div class="pgrid-filters" ref="pgridfilters">
    <div class="pgrid-filter-list-container" ref="pgridfilterslistcontainer">
      <div class="pgrid-filter-list">
        <pgrid-filter-chooser
          v-for="(f, idx) in this.$store.state.pgridFilter.Filters"
          v-bind:key="idx"
          v-bind:filterIdx="idx"
          v-on:filterUpdate="handleFilterChange($event)"
        />
      </div>
      <!-- <ou-button
        type="primary"
        class="stack-inline pgrid-save-button noprint"
        @click="uiButtonSaveFact"
        v-bind:disabled="
          this.$store.state.pgridSettings.delayedAfterChangeChanges.length > 0 || !this.$store.state.pgridFilter.EnableSaveButton
        "
        v-show="
          this.$store.state.pgridSettings.isLoading == false &&
          pgridSettings.viewMode == 'Default' &&
          this.$store.state.pgridFilter.ShowSaveButton != 'false'
        "
        >Spara</ou-button
      >
      <ou-button
        type="primary"
        class="stack-inline pgrid-save-button noprint"
        @click="uiButtonPublishFact"
        v-bind:disabled="this.$store.state.pgridSettings.delayedAfterChangeChanges.length > 0"
        v-show="
          this.$store.state.pgridSettings.isLoading == false &&
          pgridSettings.viewMode == 'Default' &&
          this.$store.state.pgridFilter.ShowPublisButton
        "
        >Publicera</ou-button
      > -->

      <!-- Caution! Don't use properties that can flicker for the 'disabled' property. As it might make the save function not working when clicking (as it just was wery quickly disabled when pressed) -->
      <ou-button
        v-for="(btn, idx) in this.$store.state.pgridFilter.DynamicButtons"
        v-bind:key="idx"
        type="primary"
        class="stack-inline pgrid-save-button noprint"
        @click="uiDynButtonPush(btn)"
        v-bind:disabled="
          btn.Disabled || (btn.Title != 'PUBLICERA' && pgridSettings.hasUnsavedFactChangesForUrl == null) || pgridSettings.isSaving
        "
        v-show="btn.Visible && pgridSettings.viewMode == 'Default'"
        >{{ btn.Title }}</ou-button
      >
    </div>
    <pgrid-guidance
      v-show="show_guidance && this.$store.state.pgridFilter.GuidanceHtml && !this.$store.state.pgridSettings.isLoading"
      v-bind:my-available-width="freeSpace_guidance"
      v-bind:myContentHtml="this.$store.state.pgridFilter.GuidanceHtml"
    />
  </div>
</template>
<script>
import Vue from "vue/dist/vue.js";
import lodash from "lodash";
import PgridFilterChooser from "./pgrid-filter-chooser.vue";
import PGridGuidance from "./pgrid-guidance.vue";
import PGridUtils from "./pgridUtils";

import PGridDynExtract from "./pgridDynExtract.js";
// const PGridDynExtract = require("./pgridDynExtract");
import PGridHandlers from "./pgridHandlers";

export default {
  name: "pgrid-filters",

  data: function () {
    return {
      pgridSettings: this.$store.state.pgridSettings,
      freeSpace_guidance: 0,
      freeSpace_guidance_margin: 30, //Smaller value may result that Version filter may bounce down the pgrid-guidance after save, if it gets longer filter value text 
      show_guidance: false,
      show_guidance_threshold: 150,
    };
  },

  mounted: async function () {
    /******************************************* */
    //Check this out: https://jsfiddle.net/8wg7o6c6/
    /******************************************* */

    let context = this.$store;

    await PGridHandlers.Handle_FilterMounted(context);

    context.commit("Mutation_UpdateRoot", { prop: "pgridfiltersRef", val: this, source: `pgrid-filters.vue.mounted()` });

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleFilterChange(filterUpdate) {
      let context = this.$store;
      PGridUtils.HandleFilterSelectionChange(context, filterUpdate);
    },
    applyButtonClick() {
      this.$store.dispatch("action_pgridFiltersHandleUpdates", {
        source: "applyButtonClick",
        isApply: true,
      });
    },

    // uiButtonSaveFact: async function (event) {
    //   let context = this.$store;
    //   await PGridUtils.HandleButtonPress("save", context);
    // },

    // uiButtonPublishFact: async function (event) {
    //   let context = this.$store;

    //   await PGridUtils.HandleButtonPress("publish", context);
    // },

    uiDynButtonPush: async function (dynBtn) {
      let context = this.$store;

      /* Delay HandleButtonPress untill we are sure no pending cell editing, loading or */
      /* isApplyGridChange (reqired for non referenced cells) are whaiting for processing */
      const trySaveMaxCount = 100;
      let tryCount = 0;
      while (
        tryCount < trySaveMaxCount &&
        (context.state.pgridSettings.isCellEditing ||
          context.state.pgridSettings.isLoading ||
          context.state.pgridSettings.isSaving ||
          context.state.pgridSettings.isApplyGridChange)
      ) {
        await PGridUtils.sleep(50);
        tryCount++;
      }

      if (tryCount >= trySaveMaxCount) {
        alert(
          `Faild to trigger button action as calculation was not finished, or cell edit not finished, within timeout.\n\nTry press button again`
        );
      } else {
        false && console.debug(`uiDynButtonPush(): ${dynBtn.Title}`);

        await PGridUtils.HandleButtonPress(dynBtn, context);
      }
    },

    handleResize(event) {
      window.PGridClientDebugMode >= 3 && console.debug(`handleResize if working....`);
      if (this.$refs.pgridfilterslistcontainer && this.$refs.pgridfilters) {
        this.freeSpace_guidance =
          this.$refs.pgridfilters.clientWidth - this.$refs.pgridfilterslistcontainer.clientWidth - this.freeSpace_guidance_margin;
        if (this.freeSpace_guidance > this.show_guidance_threshold) {
          this.show_guidance = true;
        } else {
          this.show_guidance = false;
        }
      }
    },
  },
  components: { "pgrid-filter-chooser": PgridFilterChooser, "pgrid-guidance": PGridGuidance },
};
</script>
<style>
.pgrid-filter-list {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  float: left;

  /* flex-wrap: nowrap; */

  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.pgrid-filter-item {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 6px;

  min-width: 330px;
  font-size: 15px;
}

button.pgrid-save-button {
  margin-top: 20px;
  margin-left: 7px;
  /* width: 100px; */
}

.pgrid-filters button.pgBackButton {
  height: 48px;
  min-width: 48px;

  padding-left: 2px;
  padding-right: 4px;
  padding-top: 5px;

  margin-top: 4px;
  margin-right: 4px;
}
</style>
