import Emitter from 'tiny-emitter';
import Parser from '../formula-parser/parser';

/**
 * @class EnhancedParser
 */
export default class EnhancedParser extends Emitter {
  constructor(sourceCell) {
    super();

    if (sourceCell === null) {
      let errMsg = `EnhancedParser() sourceCell not specifyed`
      console.error(errMsg);
      throw errMsg;
    }
    else {
      this.sourceCell = sourceCell;
    }


    this.parser = new Parser(sourceCell);


    // this.parser.parser.yy.rangeValue = (start, end) => this._callRangeValueEnhanced(start, end),
    // this = {
    //   reEmittCellValue: (label, row, column ) => this._callCellValue(label, sourceCell),
    //   reEmittRangeValue: (start, end) => this._callRangeValue(start, end, sourceCell),
    // }

    this.foundRefs = {};
    this.onlyFindRefs = false;

    let self = this;

    this.parser.on('callCellValue', function (cell, done) {
      const { label, row, column } = cell;
      let value = void 0;

      if (self.onlyFindRefs) {
        const key = `${row.index}:${column.index}`;
        self.foundRefs[key] = true;
        value = 0; //dummy value, as undefined braks parsing
      }
      else {
        self.emit('reCallCellValue', { label, row, column }, (_value = []) => {
          value = _value;
        });
        // value = 1000 * (row.index + 1) + 1 * (column.index + 1);
      }

      done(value);
    });


    this.parser.on('callRangeValue', (startCell, endCell, done) => {

      let value = void 0;

      if (self.onlyFindRefs) {
        for (let y = startCell.row.index; y <= endCell.row.index; y++) {
          for (let x = startCell.column.index; x <= endCell.column.index; x++) {
            const key = `${y}:${x}`;
            self.foundRefs[key] = true;
            value = 0; //dummy value, as undefined braks parsing
          }
        }
      } else {


        self.emit('reCallRangeValue', startCell, endCell, (_value = []) => {
          value = _value;
        });


        // if (startCell.label === 'K9' && endCell.label === 'L11') {
        //   done([[1, 2], [3, 4]]);
        // }
      }

      done(value);

    });
  }

  /**
   * Parse formula expression.
   *
   * @param {String} expression to parse.
   * @return {*} Returns an object with tow properties `error` and `result`.
   */
  parse(expression, sourceCell) {
    this.onlyFindRefs = false;
    let ret = this.parser.parse(expression, sourceCell)
    return ret;
  }

  /**
 * Parse formula expression.
 *
 * @param {String} expression to parse.
 * @return {*} Returns an object with properties `error` and `result`.
 */
  getRefs(expression) {


    this.onlyFindRefs = true;
    this.parser.parse(expression)
    this.onlyFindRefs = false;

    return this.foundRefs;
  }


  // /**
  //  * Retrieve value by its label (`B3`, `B$3`, `B$3`, `$B$3`).
  //  *
  //  * @param {String} label Coordinates.
  //  * @returns {*}
  //  * @private
  //  */
  // _callCellValue(label, sourceCell = null) {
  //   label = label.toUpperCase();

  //   const [row, column] = extractLabel(label, sourceCell);
  //   let value = void 0;

  //   this.emit('callCellValue', { label, row, column }, (_value) => {
  //     value = _value;
  //   });

  //   return value;
  // }

  /**
   * Retrieve value by its label (`B3:A1`, `B$3:A1`, `B$3:$A1`, `$B$3:A$1`, `R0C0:R2C2`).
   *
   * @param {String} startLabel Coordinates of the first cell.
   * @param {String} endLabel Coordinates of the last cell.
   * @returns {Array} Returns an array of mixed values.
   * @private
   */
  _callRangeValueEnhanced(startLabel, endLabel) {
    startLabel = startLabel.toUpperCase();
    endLabel = endLabel.toUpperCase();

    const [startRow, startColumn] = extractLabel(startLabel, this.sourceCell);
    const [endRow, endColumn] = extractLabel(endLabel, this.sourceCell);
    let startCell = {};
    let endCell = {};

    if (startRow.index <= endRow.index) {
      startCell.row = startRow;
      endCell.row = endRow;
    } else {
      startCell.row = endRow;
      endCell.row = startRow;
    }

    if (startColumn.index <= endColumn.index) {
      startCell.column = startColumn;
      endCell.column = endColumn;
    } else {
      startCell.column = endColumn;
      endCell.column = startColumn;
    }

    startCell.label = toLabel(startCell.row, startCell.column, sourceCell);
    endCell.label = toLabel(endCell.row, endCell.column, sourceCell);

    let value = [];

    this.emit('callRangeValue', startCell, endCell, (_value = []) => {
      value = _value;
    });

    return value;
  }

}

// export default EnhancedParser;

// export var __useDefault = true;

