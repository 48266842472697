(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}

console.warn('Compiled in DEV mode. Follow the advice at https://elm-lang.org/0.19.1/optimize for better performance and smaller assets.');


var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log_UNUSED = F2(function(tag, value)
{
	return value;
});

var _Debug_log = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString_UNUSED(value)
{
	return '<internals>';
}

function _Debug_toString(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash_UNUSED(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.start.line === region.end.line)
	{
		return 'on line ' + region.start.line;
	}
	return 'on lines ' + region.start.line + ' through ' + region.end.line;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**_UNUSED/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**_UNUSED/
	if (typeof x.$ === 'undefined')
	//*/
	/**/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0_UNUSED = 0;
var _Utils_Tuple0 = { $: '#0' };

function _Utils_Tuple2_UNUSED(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3_UNUSED(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr_UNUSED(c) { return c; }
function _Utils_chr(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil_UNUSED = { $: 0 };
var _List_Nil = { $: '[]' };

function _List_Cons_UNUSED(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap(value) { return { $: 0, a: value }; }
function _Json_unwrap(value) { return value.a; }

function _Json_wrap_UNUSED(value) { return value; }
function _Json_unwrap_UNUSED(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**_UNUSED/
	var node = args['node'];
	//*/
	/**/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		message: func(record.message),
		stopPropagation: record.stopPropagation,
		preventDefault: record.preventDefault
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.message;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.stopPropagation;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.preventDefault) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var view = impl.view;
			/**_UNUSED/
			var domNode = args['node'];
			//*/
			/**/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.setup && impl.setup(sendToApp)
			var view = impl.view;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.body);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.title) && (_VirtualDom_doc.title = title = doc.title);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.onUrlChange;
	var onUrlRequest = impl.onUrlRequest;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		setup: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.protocol === next.protocol
							&& curr.host === next.host
							&& curr.port_.a === next.port_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		init: function(flags)
		{
			return A3(impl.init, flags, _Browser_getUrl(), key);
		},
		view: impl.view,
		update: impl.update,
		subscriptions: impl.subscriptions
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hidden: 'hidden', change: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hidden: 'mozHidden', change: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hidden: 'msHidden', change: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hidden: 'webkitHidden', change: 'webkitvisibilitychange' }
		: { hidden: 'hidden', change: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		scene: _Browser_getScene(),
		viewport: {
			x: _Browser_window.pageXOffset,
			y: _Browser_window.pageYOffset,
			width: _Browser_doc.documentElement.clientWidth,
			height: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		width: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		height: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			scene: {
				width: node.scrollWidth,
				height: node.scrollHeight
			},
			viewport: {
				x: node.scrollLeft,
				y: node.scrollTop,
				width: node.clientWidth,
				height: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			scene: _Browser_getScene(),
			viewport: {
				x: x,
				y: y,
				width: _Browser_doc.documentElement.clientWidth,
				height: _Browser_doc.documentElement.clientHeight
			},
			element: {
				x: x + rect.left,
				y: y + rect.top,
				width: rect.width,
				height: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.multiline) { flags += 'm'; }
	if (options.caseInsensitive) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.expect.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.expect.b, xhr)); });
		$elm$core$Maybe$isJust(request.tracker) && _Http_track(router, xhr, request.tracker.a);

		try {
			xhr.open(request.method, request.url, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.url));
		}

		_Http_configureRequest(xhr, request);

		request.body.a && xhr.setRequestHeader('Content-Type', request.body.a);
		xhr.send(request.body.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.headers; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.timeout.a || 0;
	xhr.responseType = request.expect.d;
	xhr.withCredentials = request.allowCookiesFromOtherDomains;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		url: xhr.responseURL,
		statusCode: xhr.status,
		statusText: xhr.statusText,
		headers: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			sent: event.loaded,
			size: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			received: event.loaded,
			size: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Messages$LinkClicked = function (a) {
	return {$: 'LinkClicked', a: a};
};
var $author$project$Messages$UrlChanged = function (a) {
	return {$: 'UrlChanged', a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (node.$ === 'SubTree') {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = {$: 'EQ'};
var $elm$core$Basics$GT = {$: 'GT'};
var $elm$core$Basics$LT = {$: 'LT'};
var $elm$core$Result$Err = function (a) {
	return {$: 'Err', a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 'Failure', a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 'Index', a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 'OneOf', a: a};
};
var $elm$core$Basics$False = {$: 'False'};
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 'Just', a: a};
};
var $elm$core$Maybe$Nothing = {$: 'Nothing'};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 'Field':
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 'Nothing') {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'Index':
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'OneOf':
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 'Array_elm_builtin', a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 'SubTree', a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.nodeListSize) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.tail);
		} else {
			var treeLen = builder.nodeListSize * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.nodeList) : builder.nodeList;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.nodeListSize);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.tail);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{nodeList: nodeList, nodeListSize: (len / $elm$core$Array$branchFactor) | 0, tail: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = {$: 'True'};
var $elm$core$Result$isOk = function (result) {
	if (result.$ === 'Ok') {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 'Normal':
			return 0;
		case 'MayStopPropagation':
			return 1;
		case 'MayPreventDefault':
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 'External', a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 'Internal', a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = function (a) {
	return {$: 'NotFound', a: a};
};
var $elm$url$Url$Http = {$: 'Http'};
var $elm$url$Url$Https = {$: 'Https'};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fragment: fragment, host: host, path: path, port_: port_, protocol: protocol, query: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 'Nothing') {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Http,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Https,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0.a;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = function (a) {
	return {$: 'Perform', a: a};
};
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(_Utils_Tuple0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0.a;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return _Utils_Tuple0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(_Utils_Tuple0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0.a;
		return $elm$core$Task$Perform(
			A2($elm$core$Task$map, tagger, task));
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2($elm$core$Task$map, toMessage, task)));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Common$Types$Loading = {$: 'Loading'};
var $author$project$Common$Types$NotAsked = {$: 'NotAsked'};
var $author$project$Messages$ReceiveDate = function (a) {
	return {$: 'ReceiveDate', a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$AzureAD$checkIfLoggedIn = _Platform_outgoingPort(
	'checkIfLoggedIn',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: 'RBEmpty_elm_builtin'};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Common$Session$init = F5(
	function (token, customerKey, loginType, isCustomerAdmin, isMobile) {
		return {customerKey: customerKey, isCustomerAdmin: isCustomerAdmin, isMobile: isMobile, loginType: loginType, token: token};
	});
var $author$project$Main$emptySession = A5($author$project$Common$Session$init, '', '', '', false, false);
var $author$project$Common$Brand$Model = function (primary) {
	return function (secondary) {
		return function (title) {
			return function (image) {
				return function (faviconUrl) {
					return function (showTitleInHeader) {
						return function (showLogoInHeader) {
							return function (showTitleInAnnouncements) {
								return function (showLogoInAnnouncements) {
									return function (useColorInHeaderAndNav) {
										return {faviconUrl: faviconUrl, image: image, primary: primary, secondary: secondary, showLogoInAnnouncements: showLogoInAnnouncements, showLogoInHeader: showLogoInHeader, showTitleInAnnouncements: showTitleInAnnouncements, showTitleInHeader: showTitleInHeader, title: title, useColorInHeaderAndNav: useColorInHeaderAndNav};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Common$Color$Color = F3(
	function (rgb, hex, hsl) {
		return {hex: hex, hsl: hsl, rgb: rgb};
	});
var $author$project$Common$Color$Hsl = F3(
	function (hue, saturation, luminance) {
		return {hue: hue, luminance: luminance, saturation: saturation};
	});
var $author$project$Common$Color$Rgb = F3(
	function (red, green, blue) {
		return {blue: blue, green: green, red: red};
	});
var $author$project$Common$Color$empty = function () {
	var rgb = A3($author$project$Common$Color$Rgb, 0, 0, 0);
	var hsl = A3($author$project$Common$Color$Hsl, 0, 0, 0);
	var hex = '#000000';
	return A3($author$project$Common$Color$Color, rgb, hex, hsl);
}();
var $author$project$Common$Brand$init = $author$project$Common$Brand$Model($author$project$Common$Color$empty)($author$project$Common$Color$empty)('')('')('favicon.ico')(false)(false)(false)(false)(false);
var $author$project$Drawer$init = F2(
	function (token, isMobile) {
		return {customerService: $author$project$Common$Types$NotAsked, customers: $author$project$Common$Types$NotAsked, isMobile: isMobile, open: true, selectedApplication: $elm$core$Maybe$Nothing, selectedCustomer: $elm$core$Maybe$Nothing, token: token};
	});
var $author$project$Header$init = F3(
	function (flags, username, customerKey) {
		return {accountOpen: false, customerKey: customerKey, flags: flags, username: username};
	});
var $author$project$Language$Language$init = {
	error: {notfound: 'Kunde inte hitta sidan', userExists: 'Användaren finns redan!'},
	login: {office365: 'Logga in med Office 365'},
	main: {title: 'Business Vision | RE(a)ACT'},
	settings: {
		addRole: 'Skapa ny roll',
		apply: 'APPLICERA',
		cancel: 'Avbryt',
		discard: 'ÅNGRA',
		documents: {
			buttons: {_delete: 'Ta bort', refresh: 'Uppdatera', upload: 'Ladda upp'},
			deleteConfirmationContent: ' objekt kommer att tas bort.',
			deleteConfirmationTitle: 'Är du säker?',
			moreInfo: 'Visa mer information',
			table: {headerCreated: 'Skapad', headerDescription: 'Beskrivning', headerFilename: 'Filnamn', headerLastModified: 'Ändrad', headerUploadedBy: 'Uppladdad av'},
			textbox: {selectedFile: 'Vald fil'}
		},
		fileDrop: 'Släpp fil här',
		microsoftAccount: 'Microsoft account',
		report: {choosePbiTenant: 'Väj Power BI Tenant', desc: 'Rapportbeskrivning', group: 'Power BI Group ID', name: 'Rapportnamn', orCreateNewPbiTenant: 'eller skapa ny', pbiTenantName: 'Power BI Tenant namn', report: 'Power BI Report ID', save: 'Spara', savePbiTenant: 'Spara Power BI Tenant'},
		save: 'SPARA',
		sidepanel: {assignedMembersTitle: 'MEDLEMMAR', assignedRolesTitle: 'ANVÄNDARENS ROLLER', groupAccessFailed: 'Anslutningen till gruppen misslyckades', groupAccessSuccess: 'Anslutningen till gruppen lyckades', notAssignedMembersTitle: 'ICKE TILLDELADE', notAssignedRolesTitle: 'EJ TILLDELADE ROLLER', userAdd: 'Lägg till användare', userEmail: 'Email', userMobilePhone: 'Mobiltelefon', userName: 'Namn', userWorkPhone: 'Jobbtelefon'},
		statusDisabled: 'Inaktiv',
		statusEnabled: 'Aktiv',
		table: {
			permissions: {headerRoleName: 'ROLLNAMN'},
			reaact: {headerApp: 'APPLIKATION', headerName: 'RAPPORTNAMN', headerService: 'SERVICE', headerTab: 'TAB'},
			roles: {headerAssignedUsers: 'TILLDELADE ANVÄNDARE', headerLevelDescription: 'BESKRIVNING', headerName: 'NAMN'},
			usermgt: {headerEmail: 'EMAIL', headerLoginType: 'KONTOTYP', headerName: 'NAMN'},
			users: {headerEmail: 'EMAIL', headerName: 'NAMN', headerRoles: 'ROLL(ER)'}
		},
		topbar: {roleSwitch: 'Roller', userSwitch: 'Användare'},
		upload: 'Ladda upp fil',
		workOrSchoolAccount: 'Work or school account'
	}
};
var $author$project$Navbar$init = function (isMobile) {
	return {customerKey: '', isMobile: isMobile, selectedTab: $elm$core$Maybe$Nothing, tabs: _List_Nil};
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Pages$AppRegistration$emptyNewService = {applicationIcon: '', applicationKey: '', applicationName: '', applicationSort: -1, applicationTypeKey: '', serviceIcon: '', serviceKey: '', serviceName: '', serviceSort: -1, tabKey: '', tabName: '', tabPath: '', tabSort: -1};
var $author$project$Pages$AppRegistration$init = function (session) {
	return {applicationTypes: $author$project$Common$Types$NotAsked, debug: '', newService: $author$project$Pages$AppRegistration$emptyNewService, selectedApplication: $elm$core$Maybe$Nothing, selectedApplicationTabs: _List_Nil, selectedExistingApplication: $elm$core$Maybe$Nothing, selectedExistingService: $elm$core$Maybe$Nothing, selectedExistingTab: $elm$core$Maybe$Nothing, selectedService: $elm$core$Maybe$Nothing, selectedServiceApplications: _List_Nil, services: $author$project$Common$Types$NotAsked, session: session, updatedService: $author$project$Pages$AppRegistration$emptyNewService};
};
var $author$project$Common$Types$Browse = {$: 'Browse'};
var $author$project$Pages$Branding$Primary = {$: 'Primary'};
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$String$reverse = _String_reverse;
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$Char$toLower = _Char_toLower;
var $author$project$Common$Color$calculateHex = function (hex) {
	var getDecimal = function (_v3) {
		var i = _v3.a;
		var c = _v3.b;
		var _v0 = $elm$core$Char$isHexDigit(c);
		if (_v0) {
			var _v1 = $elm$core$Char$toLower(c);
			switch (_v1.valueOf()) {
				case 'a':
					return 10 * A2($elm$core$Basics$pow, 16, i);
				case 'b':
					return 11 * A2($elm$core$Basics$pow, 16, i);
				case 'c':
					return 12 * A2($elm$core$Basics$pow, 16, i);
				case 'd':
					return 13 * A2($elm$core$Basics$pow, 16, i);
				case 'e':
					return 14 * A2($elm$core$Basics$pow, 16, i);
				case 'f':
					return 15 * A2($elm$core$Basics$pow, 16, i);
				default:
					var _char = _v1;
					var _v2 = $elm$core$String$toInt(
						$elm$core$String$fromChar(_char));
					if (_v2.$ === 'Nothing') {
						return 0;
					} else {
						var _int = _v2.a;
						return _int * A2($elm$core$Basics$pow, 16, i);
					}
			}
		} else {
			return 0;
		}
	};
	var resultList = A2(
		$elm$core$List$map,
		getDecimal,
		A2(
			$elm$core$List$indexedMap,
			$elm$core$Tuple$pair,
			$elm$core$String$toList(
				$elm$core$String$reverse(hex))));
	return $elm$core$List$sum(resultList);
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$Common$Color$convertHexToRgb = function (hex) {
	var convert_ = function (s) {
		var _v0 = $elm$core$String$uncons(s);
		if (_v0.$ === 'Nothing') {
			return '';
		} else {
			var _v1 = _v0.a;
			var h = _v1.a;
			var t = _v1.b;
			return _Utils_ap(
				_Utils_ap(
					$elm$core$String$fromChar(h),
					$elm$core$String$fromChar(h)),
				convert_(t));
		}
	};
	var hex_ = function () {
		var _v2 = A3($elm$core$String$replace, '#', '', hex);
		var s = _v2;
		var _v3 = $elm$core$String$length(s);
		if (_v3 === 3) {
			return convert_(s);
		} else {
			return s;
		}
	}();
	var g = A2(
		$elm$core$String$dropRight,
		2,
		A2($elm$core$String$dropLeft, 2, hex_));
	var r = A2($elm$core$String$left, 2, hex_);
	var b = A2($elm$core$String$right, 2, hex_);
	var rgb = A3(
		$author$project$Common$Color$Rgb,
		$author$project$Common$Color$calculateHex(r),
		$author$project$Common$Color$calculateHex(g),
		$author$project$Common$Color$calculateHex(b));
	return rgb;
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$Basics$round = _Basics_round;
var $author$project$Common$Color$convertRgbToHsl = function (rgb) {
	var _v0 = _Utils_Tuple3(rgb.red / 255, rgb.green / 255, rgb.blue / 255);
	var tr = _v0.a;
	var tg = _v0.b;
	var tb = _v0.c;
	var maxVal = A2(
		$elm$core$Basics$max,
		tb,
		A2($elm$core$Basics$max, tr, tg));
	var minVal = A2(
		$elm$core$Basics$min,
		tb,
		A2($elm$core$Basics$min, tr, tg));
	var lum = (maxVal + minVal) / 2;
	var sat = _Utils_eq(maxVal, minVal) ? 0 : ((lum < 0.5) ? ((maxVal - minVal) / (maxVal + minVal)) : ((maxVal - minVal) / ((2 - maxVal) - minVal)));
	var tempHue = ((_Utils_cmp(tg, tr) < 0) && (_Utils_cmp(tr, tb) > 0)) ? ((tg - tb) / (maxVal - minVal)) : (((_Utils_cmp(tr, tg) < 0) && (_Utils_cmp(tg, tb) > 0)) ? (2 + ((tb - tr) / (maxVal - minVal))) : (((_Utils_cmp(tr, tb) < 0) && (_Utils_cmp(tb, tg) > 0)) ? (4 + ((tr - tg) / (maxVal - minVal))) : 0));
	var hue = (tempHue < 0) ? ((tempHue * 60) + 360) : (tempHue * 60);
	return A3(
		$author$project$Common$Color$Hsl,
		$elm$core$Basics$round(hue),
		$elm$core$Basics$round(sat * 100),
		$elm$core$Basics$round(lum * 100));
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {index: index, match: match, number: number, submatches: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{caseInsensitive: false, multiline: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Common$Format$match = F2(
	function (regex, target) {
		return A2(
			$elm$regex$Regex$contains,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString(regex)),
			target);
	});
var $author$project$Common$Color$rgxHex = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$';
var $author$project$Common$Color$isHexValid = function (hex) {
	var isValidLength = ($elm$core$String$length(hex) === 7) || ($elm$core$String$length(hex) === 4);
	var isHex = A2($author$project$Common$Format$match, $author$project$Common$Color$rgxHex, hex);
	var hasValidFirstChar = A2($elm$core$String$left, 1, hex) === '#';
	return isValidLength && (hasValidFirstChar && isHex);
};
var $author$project$Common$Color$hexToColor = F2(
	function (hex, color) {
		var rgb = $author$project$Common$Color$convertHexToRgb(hex);
		var hsl = $author$project$Common$Color$convertRgbToHsl(rgb);
		return $author$project$Common$Color$isHexValid(hex) ? $elm$core$Maybe$Just(
			A3($author$project$Common$Color$Color, rgb, hex, hsl)) : $elm$core$Maybe$Just(
			A3($author$project$Common$Color$Color, color.rgb, hex, color.hsl));
	});
var $elm$core$Dict$Black = {$: 'Black'};
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: 'RBNode_elm_builtin', a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = {$: 'Red'};
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Red')) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) && (left.d.$ === 'RBNode_elm_builtin')) && (left.d.a.$ === 'Red')) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1.$) {
				case 'LT':
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 'EQ':
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $author$project$Elements$ColorPicker$Cursor = F2(
	function (state, position) {
		return {position: position, state: state};
	});
var $author$project$Pages$Branding$CursorDown = function (a) {
	return {$: 'CursorDown', a: a};
};
var $author$project$Pages$Branding$CursorLeave = function (a) {
	return {$: 'CursorLeave', a: a};
};
var $author$project$Pages$Branding$CursorMove = function (a) {
	return {$: 'CursorMove', a: a};
};
var $author$project$Pages$Branding$CursorUp = function (a) {
	return {$: 'CursorUp', a: a};
};
var $author$project$Pages$Branding$HexInput = function (a) {
	return {$: 'HexInput', a: a};
};
var $author$project$Pages$Branding$HslInput = F2(
	function (a, b) {
		return {$: 'HslInput', a: a, b: b};
	});
var $author$project$Pages$Branding$KeyHsl = F2(
	function (a, b) {
		return {$: 'KeyHsl', a: a, b: b};
	});
var $author$project$Pages$Branding$KeyRgb = F2(
	function (a, b) {
		return {$: 'KeyRgb', a: a, b: b};
	});
var $author$project$Elements$ColorPicker$NotBeingDragged = {$: 'NotBeingDragged'};
var $author$project$Pages$Branding$RgbInput = F2(
	function (a, b) {
		return {$: 'RgbInput', a: a, b: b};
	});
var $author$project$Elements$ColorPicker$Slider = F2(
	function (state, value) {
		return {state: state, value: value};
	});
var $author$project$Pages$Branding$SliderOnInput = function (a) {
	return {$: 'SliderOnInput', a: a};
};
var $author$project$Pages$Branding$pickerSettings = {
	cursor: A2(
		$author$project$Elements$ColorPicker$Cursor,
		$author$project$Elements$ColorPicker$NotBeingDragged,
		{offsetHeight: 0, offsetWidth: 0, offsetX: 0, offsetY: 0}),
	cursorDown: $author$project$Pages$Branding$CursorDown,
	cursorLeave: $author$project$Pages$Branding$CursorLeave,
	cursorMove: $author$project$Pages$Branding$CursorMove,
	cursorUp: $author$project$Pages$Branding$CursorUp,
	height: '200px',
	hexInputEvent: $author$project$Pages$Branding$HexInput,
	hslInputEvent: $author$project$Pages$Branding$HslInput,
	hslKeyUpDown: $elm$core$Maybe$Just($author$project$Pages$Branding$KeyHsl),
	id: 'brand-colorpicker',
	rgbInputEvent: $author$project$Pages$Branding$RgbInput,
	rgbKeyUpDown: $elm$core$Maybe$Just($author$project$Pages$Branding$KeyRgb),
	slider: A2($author$project$Elements$ColorPicker$Slider, $author$project$Elements$ColorPicker$NotBeingDragged, 0),
	sliderOnInput: $author$project$Pages$Branding$SliderOnInput,
	width: '100%'
};
var $author$project$Pages$Branding$showLogoInAnnouncements = 'showLogoInAnnouncements';
var $author$project$Pages$Branding$showLogoInHeader = 'showLogoInHeader';
var $author$project$Pages$Branding$showTitleInAnnouncements = 'showTitleInAnnouncements';
var $author$project$Pages$Branding$showTitleInHeader = 'showTitleInHeader';
var $author$project$Pages$Branding$useColorInHeaderAndNav = 'useColorInHeaderAndNav';
var $author$project$Pages$Branding$init = F3(
	function (session, brand, logoContainer) {
		var logoContainerUrl = A2($elm$core$String$endsWith, '/', logoContainer) ? logoContainer : (logoContainer + '/');
		var logoUrl = logoContainerUrl + (session.customerKey + '_logo.png');
		var color2 = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Color$empty,
			A2($author$project$Common$Color$hexToColor, brand.secondary.hex, brand.secondary));
		var color = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Color$empty,
			A2($author$project$Common$Color$hexToColor, brand.primary.hex, brand.primary));
		var checkboxesValues = A3(
			$elm$core$Dict$insert,
			$author$project$Pages$Branding$useColorInHeaderAndNav,
			brand.useColorInHeaderAndNav,
			A3(
				$elm$core$Dict$insert,
				$author$project$Pages$Branding$showLogoInAnnouncements,
				brand.showLogoInAnnouncements,
				A3(
					$elm$core$Dict$insert,
					$author$project$Pages$Branding$showTitleInAnnouncements,
					brand.showTitleInAnnouncements,
					A3(
						$elm$core$Dict$insert,
						$author$project$Pages$Branding$showLogoInHeader,
						brand.showLogoInHeader,
						A3($elm$core$Dict$insert, $author$project$Pages$Branding$showTitleInHeader, brand.showTitleInHeader, $elm$core$Dict$empty)))));
		return {brand: brand, checked: checkboxesValues, color: color, color2: color2, colorpickerSettings: $author$project$Pages$Branding$pickerSettings, debug: '', logoUrl: logoUrl, logos: $author$project$Common$Types$NotAsked, mobileView: $author$project$Common$Types$Browse, selection: $author$project$Pages$Branding$Primary, session: session};
	});
var $author$project$Pages$Customers$View = {$: 'View'};
var $author$project$Common$Types$Customer = F4(
	function (customerKey, customerName, customerDomain, defaultIssuer) {
		return {customerDomain: customerDomain, customerKey: customerKey, customerName: customerName, defaultIssuer: defaultIssuer};
	});
var $author$project$Pages$Customers$emptyCustomer = A4($author$project$Common$Types$Customer, '', '', '', '');
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Elements$Table$Asc = function (a) {
	return {$: 'Asc', a: a};
};
var $author$project$Pages$Customers$tableSettings = {
	buttons: _List_Nil,
	dropdowns: _List_Nil,
	isLoading: false,
	search: false,
	searchAction: $elm$core$Maybe$Nothing,
	selectedTab: $elm$core$Maybe$Nothing,
	sort: $author$project$Elements$Table$Asc(0),
	switches: _List_Nil,
	tabSelectMsg: $elm$core$Maybe$Nothing,
	tabs: _List_Nil
};
var $author$project$Pages$Customers$init = F2(
	function (session, customers) {
		var initialCustomer = function () {
			if (customers.$ === 'Success') {
				var cs = customers.a;
				var _v1 = $elm$core$List$head(cs);
				if (_v1.$ === 'Just') {
					var c = _v1.a;
					return c;
				} else {
					return $author$project$Pages$Customers$emptyCustomer;
				}
			} else {
				return $author$project$Pages$Customers$emptyCustomer;
			}
		}();
		return {addCustomerMessage: $author$project$Common$Types$NotAsked, addedCustomerResponse: $author$project$Common$Types$NotAsked, customers: customers, debug: '', mode: $author$project$Pages$Customers$View, removedCustomerMessage: $author$project$Common$Types$NotAsked, selectedCustomer: initialCustomer, session: session, showConfirmation: false, tableSettings: $author$project$Pages$Customers$tableSettings};
	});
var $author$project$Pages$Document$init = function (session) {
	return {
		blobDocuments: _List_Nil,
		blobs: _List_Nil,
		checkedDocs: $elm$core$Dict$empty,
		debug: '',
		deleteStatus: $author$project$Common$Types$NotAsked,
		description: '',
		folder: $elm$core$Maybe$Nothing,
		selectedBlobDocument: $elm$core$Maybe$Nothing,
		session: session,
		showDeleteConfirmation: false,
		showFileDetails: false,
		showUpload: false,
		sort: $author$project$Elements$Table$Asc(2),
		uploadFile: $elm$core$Maybe$Nothing
	};
};
var $author$project$Pages$Home$Announcement = {$: 'Announcement'};
var $author$project$Pages$Home$View = {$: 'View'};
var $author$project$Pages$Home$init = F3(
	function (session, brand, user) {
		return {announcementServices: _List_Nil, announcements: _List_Nil, brand: brand, createModeOn: false, mobileView: $author$project$Pages$Home$Announcement, mode: $author$project$Pages$Home$View, selectedAnnouncement: $elm$core$Maybe$Nothing, selectedAnnouncementService: $elm$core$Maybe$Nothing, session: session, user: user};
	});
var $author$project$Pages$HomeEdit$View = {$: 'View'};
var $author$project$Pages$HomeEdit$init = F3(
	function (session, brand, user) {
		return {announcementServices: _List_Nil, announcements: _List_Nil, brand: brand, createModeOn: false, editModeOn: false, mode: $author$project$Pages$HomeEdit$View, newAnnouncement: $elm$core$Maybe$Nothing, publishInformation: $elm$core$Maybe$Nothing, selectedAnnouncement: $elm$core$Maybe$Nothing, selectedAnnouncementService: $elm$core$Maybe$Nothing, session: session, showConfirmation: false, user: user};
	});
var $author$project$Pages$Login$init = function (flags) {
	return {context: $elm$core$Maybe$Nothing, flags: flags};
};
var $author$project$Common$Types$LoadingReport = {$: 'LoadingReport'};
var $author$project$Pages$PowerBI$init = {currentReport: $author$project$Common$Types$LoadingReport, embedToken: $author$project$Common$Types$NotAsked, reports: $elm$core$Dict$empty};
var $author$project$Common$Types$PBITenant = F5(
	function (pbiTenantKey, pbiTenantName, azureTenantId, spnId, spnSecret) {
		return {azureTenantId: azureTenantId, pbiTenantKey: pbiTenantKey, pbiTenantName: pbiTenantName, spnId: spnId, spnSecret: spnSecret};
	});
var $author$project$Pages$ReportSettings$emptyPBITenant = A5($author$project$Common$Types$PBITenant, '', '', '', '', '');
var $author$project$Pages$ReportSettings$tableConfig = {
	buttons: _List_Nil,
	dropdowns: _List_Nil,
	isLoading: true,
	search: false,
	searchAction: $elm$core$Maybe$Nothing,
	selectedTab: $elm$core$Maybe$Nothing,
	sort: $author$project$Elements$Table$Asc(0),
	switches: _List_Nil,
	tabSelectMsg: $elm$core$Maybe$Nothing,
	tabs: _List_Nil
};
var $author$project$Pages$ReportSettings$init = function (session) {
	return {customers: _List_Nil, debug: $elm$core$Maybe$Nothing, groupAccess: $author$project$Common$Types$NotAsked, groupMetaData: $author$project$Common$Types$NotAsked, newPbiTenant: $author$project$Pages$ReportSettings$emptyPBITenant, pbiTenants: _List_Nil, reportApplications: _List_Nil, reportMetaData: $author$project$Common$Types$NotAsked, reportServices: _List_Nil, reports: $elm$core$Dict$empty, selectedCustomer: $elm$core$Maybe$Nothing, selectedReport: $author$project$Common$Types$NotAsked, selectedTab: $elm$core$Maybe$Nothing, session: session, switches: $elm$core$Dict$empty, tableConfig: $author$project$Pages$ReportSettings$tableConfig};
};
var $author$project$Elements$Table$None = {$: 'None'};
var $author$project$Pages$RoleManagement$ToggleRoleSetting = {$: 'ToggleRoleSetting'};
var $author$project$Pages$RoleManagement$tableSwitches = _List_fromArray(
	[
		{isChecked: false, msg: $author$project$Pages$RoleManagement$ToggleRoleSetting, offText: 'Användare', onText: 'Roller'}
	]);
var $author$project$Pages$RoleManagement$tableConfig = {
	buttons: _List_Nil,
	dropdowns: _List_Nil,
	isLoading: true,
	search: false,
	searchAction: $elm$core$Maybe$Nothing,
	selectedTab: $elm$core$Maybe$Nothing,
	sort: $author$project$Elements$Table$None,
	switches: $author$project$Pages$RoleManagement$tableSwitches,
	tabSelectMsg: $elm$core$Maybe$Just($author$project$Pages$RoleManagement$ToggleRoleSetting),
	tabs: _List_Nil
};
var $author$project$Pages$RoleManagement$init = function (session) {
	return {availableRoles: $author$project$Common$Types$NotAsked, mobileView: $author$project$Common$Types$Browse, roleToggle: false, searches: $elm$core$Dict$empty, selectedRole: $elm$core$Maybe$Nothing, selectedUser: $elm$core$Maybe$Nothing, session: session, tableConfig: $author$project$Pages$RoleManagement$tableConfig, users: $author$project$Common$Types$NotAsked};
};
var $author$project$Pages$RolePermissions$tableSettings = {buttons: _List_Nil, dropdowns: _List_Nil, isLoading: false, search: false, searchAction: $elm$core$Maybe$Nothing, selectedTab: $elm$core$Maybe$Nothing, sort: $author$project$Elements$Table$None, switches: _List_Nil, tabSelectMsg: $elm$core$Maybe$Nothing, tabs: _List_Nil};
var $author$project$Pages$RolePermissions$init = function (session) {
	return {collapsibles: $elm$core$Dict$empty, debug: '', initialTree: _List_Nil, mobileView: $author$project$Common$Types$Browse, modifiedRolePermissions: $author$project$Common$Types$NotAsked, rolePermissions: $author$project$Common$Types$NotAsked, selectedApplications: $elm$core$Dict$empty, selectedRolePermission: $elm$core$Maybe$Nothing, selectedServices: $elm$core$Dict$empty, selectedTabs: $elm$core$Dict$empty, services: $author$project$Common$Types$NotAsked, session: session, tableSettings: $author$project$Pages$RolePermissions$tableSettings, tree: _List_Nil};
};
var $CurrySoftware$elm_datepicker$DatePicker$CurrentDate = function (a) {
	return {$: 'CurrentDate', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$DatePicker = function (a) {
	return {$: 'DatePicker', a: a};
};
var $elm$time$Time$May = {$: 'May'};
var $justinmimbs$date$Date$RD = function (a) {
	return {$: 'RD', a: a};
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m.$) {
			case 'Jan':
				return 0;
			case 'Feb':
				return 31;
			case 'Mar':
				return 59 + leapDays;
			case 'Apr':
				return 90 + leapDays;
			case 'May':
				return 120 + leapDays;
			case 'Jun':
				return 151 + leapDays;
			case 'Jul':
				return 181 + leapDays;
			case 'Aug':
				return 212 + leapDays;
			case 'Sep':
				return 243 + leapDays;
			case 'Oct':
				return 273 + leapDays;
			case 'Nov':
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m.$) {
			case 'Jan':
				return 31;
			case 'Feb':
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 'Mar':
				return 31;
			case 'Apr':
				return 30;
			case 'May':
				return 31;
			case 'Jun':
				return 30;
			case 'Jul':
				return 31;
			case 'Aug':
				return 31;
			case 'Sep':
				return 30;
			case 'Oct':
				return 31;
			case 'Nov':
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return $justinmimbs$date$Date$RD(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
				$elm$core$Basics$clamp,
				1,
				A2($justinmimbs$date$Date$daysInMonth, y, m),
				d));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$initDate = A3($justinmimbs$date$Date$fromCalendarDate, 1992, $elm$time$Time$May, 31);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0.a;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.start, posixMinutes) < 0) {
					return posixMinutes + era.offset;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		day: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		month: month,
		year: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).day;
	});
var $elm$time$Time$Apr = {$: 'Apr'};
var $elm$time$Time$Aug = {$: 'Aug'};
var $elm$time$Time$Dec = {$: 'Dec'};
var $elm$time$Time$Feb = {$: 'Feb'};
var $elm$time$Time$Jan = {$: 'Jan'};
var $elm$time$Time$Jul = {$: 'Jul'};
var $elm$time$Time$Jun = {$: 'Jun'};
var $elm$time$Time$Mar = {$: 'Mar'};
var $elm$time$Time$Nov = {$: 'Nov'};
var $elm$time$Time$Oct = {$: 'Oct'};
var $elm$time$Time$Sep = {$: 'Sep'};
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).month;
		switch (_v0) {
			case 1:
				return $elm$time$Time$Jan;
			case 2:
				return $elm$time$Time$Feb;
			case 3:
				return $elm$time$Time$Mar;
			case 4:
				return $elm$time$Time$Apr;
			case 5:
				return $elm$time$Time$May;
			case 6:
				return $elm$time$Time$Jun;
			case 7:
				return $elm$time$Time$Jul;
			case 8:
				return $elm$time$Time$Aug;
			case 9:
				return $elm$time$Time$Sep;
			case 10:
				return $elm$time$Time$Oct;
			case 11:
				return $elm$time$Time$Nov;
			default:
				return $elm$time$Time$Dec;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).year;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 'Name', a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 'Offset', a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 'Zone', a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(_Utils_Tuple0);
var $elm$time$Time$Posix = function (a) {
	return {$: 'Posix', a: a};
};
var $elm$time$Time$millisToPosix = $elm$time$Time$Posix;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $CurrySoftware$elm_datepicker$DatePicker$init = _Utils_Tuple2(
	$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
		{
			focused: $elm$core$Maybe$Just($CurrySoftware$elm_datepicker$DatePicker$Date$initDate),
			forceOpen: false,
			inputText: $elm$core$Maybe$Nothing,
			open: false,
			today: $CurrySoftware$elm_datepicker$DatePicker$Date$initDate
		}),
	A2($elm$core$Task$perform, $CurrySoftware$elm_datepicker$DatePicker$CurrentDate, $justinmimbs$date$Date$today));
var $author$project$Pages$Subscriptions$init = F2(
	function (session, customers) {
		var initialCustomer = $elm$core$List$head(customers);
		var _v0 = $CurrySoftware$elm_datepicker$DatePicker$init;
		var datePicker = _v0.a;
		var datePickerFx = _v0.b;
		return {customers: customers, date: $elm$core$Maybe$Nothing, datepicker: datePicker, debug: '', endDate: $elm$core$Maybe$Nothing, endPicker: datePicker, selectedApplication: $elm$core$Maybe$Nothing, selectedCustomer: initialCustomer, selectedDropdownValues: $elm$core$Dict$empty, selectedService: $elm$core$Maybe$Nothing, services: $author$project$Common$Types$NotAsked, session: session, startDate: $elm$core$Maybe$Nothing, startPicker: datePicker};
	});
var $author$project$Pages$UserInfo$Search = F2(
	function (a, b) {
		return {$: 'Search', a: a, b: b};
	});
var $author$project$Pages$UserInfo$tableConfig = {
	buttons: _List_Nil,
	dropdowns: _List_Nil,
	isLoading: true,
	search: true,
	searchAction: $elm$core$Maybe$Just($author$project$Pages$UserInfo$Search),
	selectedTab: $elm$core$Maybe$Nothing,
	sort: $author$project$Elements$Table$None,
	switches: _List_Nil,
	tabSelectMsg: $elm$core$Maybe$Nothing,
	tabs: _List_Nil
};
var $author$project$Pages$UserInfo$init = function (session) {
	return {mobileView: $author$project$Common$Types$Browse, searches: $elm$core$Dict$empty, selectedUser: $elm$core$Maybe$Nothing, session: session, tableConfig: $author$project$Pages$UserInfo$tableConfig, users: $author$project$Common$Types$NotAsked};
};
var $author$project$Common$Types$User = function (customerKey) {
	return function (roles) {
		return function (userKey) {
			return function (userEmail) {
				return function (userLoginType) {
					return function (userFullName) {
						return function (userWorkPhone) {
							return function (userMobilePhone) {
								return function (userCreated) {
									return function (userCreatedBy) {
										return function (userModified) {
											return function (userModifiedBy) {
												return function (userLastLogin) {
													return function (issuer) {
														return {customerKey: customerKey, issuer: issuer, roles: roles, userCreated: userCreated, userCreatedBy: userCreatedBy, userEmail: userEmail, userFullName: userFullName, userKey: userKey, userLastLogin: userLastLogin, userLoginType: userLoginType, userMobilePhone: userMobilePhone, userModified: userModified, userModifiedBy: userModifiedBy, userWorkPhone: userWorkPhone};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Pages$UserManagement$initNewUser = function (customer) {
	var selectedCustomerIssuer = function () {
		if (customer.$ === 'Just') {
			var c = customer.a;
			return c.defaultIssuer;
		} else {
			return '';
		}
	}();
	return $author$project$Common$Types$User('')(_List_Nil)('')('')('WORK_OR_SCHOOL_ACCOUNT')('')('')('')('')('')('')('')('')(selectedCustomerIssuer);
};
var $author$project$Common$Types$UserVerification = F6(
	function (name, email, workphone, mobilephone, logintype, userExists) {
		return {email: email, logintype: logintype, mobilephone: mobilephone, name: name, userExists: userExists, workphone: workphone};
	});
var $author$project$Pages$UserManagement$initUserVerification = A6($author$project$Common$Types$UserVerification, false, false, false, false, false, false);
var $author$project$Pages$UserManagement$tableConfig = {buttons: _List_Nil, dropdowns: _List_Nil, isLoading: true, search: false, searchAction: $elm$core$Maybe$Nothing, selectedTab: $elm$core$Maybe$Nothing, sort: $author$project$Elements$Table$None, switches: _List_Nil, tabSelectMsg: $elm$core$Maybe$Nothing, tabs: _List_Nil};
var $author$project$Pages$UserManagement$init = F2(
	function (session, customer) {
		var newUser = $author$project$Pages$UserManagement$initNewUser(customer);
		return {
			addedUsers: _List_Nil,
			customer: customer,
			newUser: $elm$core$Maybe$Just(newUser),
			newUsers: _List_Nil,
			session: session,
			tableConfig: $author$project$Pages$UserManagement$tableConfig,
			userVerification: $author$project$Pages$UserManagement$initUserVerification,
			users: $author$project$Common$Types$NotAsked
		};
	});
var $author$project$Common$Types$Drawer = {$: 'Drawer'};
var $author$project$Common$Types$DrawerNavbar = {$: 'DrawerNavbar'};
var $author$project$Common$Types$Header = {$: 'Header'};
var $author$project$Common$Types$HeaderDrawer = {$: 'HeaderDrawer'};
var $author$project$Common$Types$HeaderNavbar = {$: 'HeaderNavbar'};
var $author$project$Common$Types$Navbar = {$: 'Navbar'};
var $author$project$Common$Types$None = {$: 'None'};
var $author$project$Common$Types$Simple = {$: 'Simple'};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {frag: frag, params: params, unvisited: unvisited, value: value, visited: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.unvisited;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.value);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.value);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 'Nothing') {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1.$) {
					case 'LT':
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 'EQ':
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.e.d.$ === 'RBNode_elm_builtin') && (dict.e.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.d.d.$ === 'RBNode_elm_builtin') && (dict.d.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Black')) {
					if (right.d.$ === 'RBNode_elm_builtin') {
						if (right.d.a.$ === 'Black') {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor.$ === 'Black') {
			if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === 'RBNode_elm_builtin') {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Black')) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === 'RBNode_elm_builtin') {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBNode_elm_builtin') {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === 'RBNode_elm_builtin') {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (_v0.$ === 'Just') {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 'Nothing') {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 'Nothing') {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 'Nothing') {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0.a;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.path),
					$elm$url$Url$Parser$prepareQuery(url.query),
					url.fragment,
					$elm$core$Basics$identity)));
	});
var $elm$url$Url$Parser$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0.a;
	return $elm$url$Url$Parser$Parser(
		function (_v1) {
			var visited = _v1.visited;
			var unvisited = _v1.unvisited;
			var params = _v1.params;
			var frag = _v1.frag;
			var value = _v1.value;
			return _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					visited,
					unvisited,
					params,
					frag,
					value(
						queryParser(params)))
				]);
		});
};
var $elm$url$Url$Parser$Internal$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return $elm$url$Url$Parser$Internal$Parser(
			function (dict) {
				return func(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2($elm$core$Dict$get, key, dict)));
			});
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$Common$Helpers$extractQueryParameter = F2(
	function (key, location) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$url$Url$Parser$parse,
				$elm$url$Url$Parser$query(
					$elm$url$Url$Parser$Query$string(key)),
				_Utils_update(
					location,
					{path: ''})));
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$Common$Helpers$mapEmbedView = function (url) {
	var _v0 = A2($author$project$Common$Helpers$extractQueryParameter, 'embedView', url);
	if (_v0.$ === 'Nothing') {
		return $author$project$Common$Types$None;
	} else {
		var s = _v0.a;
		var _v1 = $elm$core$String$toLower(s);
		switch (_v1) {
			case 'simple':
				return $author$project$Common$Types$Simple;
			case 'header':
				return $author$project$Common$Types$Header;
			case 'navbar':
				return $author$project$Common$Types$Navbar;
			case 'drawer':
				return $author$project$Common$Types$Drawer;
			case 'headernavbar':
				return $author$project$Common$Types$HeaderNavbar;
			case 'headerdrawer':
				return $author$project$Common$Types$HeaderDrawer;
			case 'drawernavbar':
				return $author$project$Common$Types$DrawerNavbar;
			default:
				return $author$project$Common$Types$None;
		}
	}
};
var $author$project$Pages$Pages$NotFound = {$: 'NotFound'};
var $author$project$Pages$Pages$ApplicationRegistration = {$: 'ApplicationRegistration'};
var $author$project$Pages$Pages$Branding = {$: 'Branding'};
var $author$project$Pages$Pages$CustomerSubscription = {$: 'CustomerSubscription'};
var $author$project$Pages$Pages$Customers = {$: 'Customers'};
var $author$project$Pages$Pages$Document = function (a) {
	return {$: 'Document', a: a};
};
var $author$project$Pages$Pages$Home = {$: 'Home'};
var $author$project$Pages$Pages$HomeEdit = {$: 'HomeEdit'};
var $author$project$Pages$Pages$PlannicaGrid = F3(
	function (a, b, c) {
		return {$: 'PlannicaGrid', a: a, b: b, c: c};
	});
var $author$project$Pages$Pages$Reaact = F2(
	function (a, b) {
		return {$: 'Reaact', a: a, b: b};
	});
var $author$project$Pages$Pages$ReportSettings = {$: 'ReportSettings'};
var $author$project$Pages$Pages$RolePermissions = {$: 'RolePermissions'};
var $author$project$Pages$Pages$Roles = {$: 'Roles'};
var $author$project$Pages$Pages$UserInfo = {$: 'UserInfo'};
var $author$project$Pages$Pages$UserManagement = {$: 'UserManagement'};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.visited;
		var unvisited = _v0.unvisited;
		var params = _v0.params;
		var frag = _v0.frag;
		var value = _v0.value;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0.a;
		return $elm$url$Url$Parser$Parser(
			function (_v1) {
				var visited = _v1.visited;
				var unvisited = _v1.unvisited;
				var params = _v1.params;
				var frag = _v1.frag;
				var value = _v1.value;
				return A2(
					$elm$core$List$map,
					$elm$url$Url$Parser$mapState(value),
					parseArg(
						A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
			});
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return $elm$url$Url$Parser$Parser(
		function (state) {
			return A2(
				$elm$core$List$concatMap,
				function (_v0) {
					var parser = _v0.a;
					return parser(state);
				},
				parsers);
		});
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0.a;
		var parseAfter = _v1.a;
		return $elm$url$Url$Parser$Parser(
			function (state) {
				return A2(
					$elm$core$List$concatMap,
					parseAfter,
					parseBefore(state));
			});
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return $elm$url$Url$Parser$Parser(
		function (_v0) {
			var visited = _v0.visited;
			var unvisited = _v0.unvisited;
			var params = _v0.params;
			var frag = _v0.frag;
			var value = _v0.value;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				return _Utils_eq(next, str) ? _List_fromArray(
					[
						A5(
						$elm$url$Url$Parser$State,
						A2($elm$core$List$cons, next, visited),
						rest,
						params,
						frag,
						value)
					]) : _List_Nil;
			}
		});
};
var $elm$url$Url$Parser$top = $elm$url$Url$Parser$Parser(
	function (state) {
		return _List_fromArray(
			[state]);
	});
var $author$project$Pages$Pages$route = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Pages$Pages$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$HomeEdit,
			$elm$url$Url$Parser$s('editannouncements')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$Roles,
			$elm$url$Url$Parser$s('roles')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$Reaact,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('report'),
					$elm$url$Url$Parser$Query$string('tk')),
				$elm$url$Url$Parser$Query$string('ck'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$PlannicaGrid,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('grid'),
						$elm$url$Url$Parser$Query$string('tk')),
					$elm$url$Url$Parser$Query$string('ck')),
				$elm$url$Url$Parser$Query$string('filters'))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$ReportSettings,
			$elm$url$Url$Parser$s('reportsettings')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$UserManagement,
			$elm$url$Url$Parser$s('usermanagement')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$UserInfo,
			$elm$url$Url$Parser$s('userinfo')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$Branding,
			$elm$url$Url$Parser$s('branding')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$CustomerSubscription,
			$elm$url$Url$Parser$s('subscriptions')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$ApplicationRegistration,
			$elm$url$Url$Parser$s('appregistration')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$Customers,
			$elm$url$Url$Parser$s('customers')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$RolePermissions,
			$elm$url$Url$Parser$s('rolepermissions')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Pages$Pages$Document,
			A2(
				$elm$url$Url$Parser$questionMark,
				$elm$url$Url$Parser$s('documents'),
				$elm$url$Url$Parser$Query$string('tk')))
		]));
var $author$project$Pages$Pages$toRoute = function (string) {
	var _v0 = $elm$url$Url$fromString(string);
	if (_v0.$ === 'Nothing') {
		return $author$project$Pages$Pages$NotFound;
	} else {
		var url = _v0.a;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Pages$Pages$NotFound,
			A2($elm$url$Url$Parser$parse, $author$project$Pages$Pages$route, url));
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 'Nothing') {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 'Nothing') {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.protocol;
		if (_v0.$ === 'Http') {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.fragment,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.query,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.port_,
					_Utils_ap(http, url.host)),
				url.path)));
};
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var requestedPage = $author$project$Pages$Pages$toRoute(
			$elm$url$Url$toString(url));
		var embedView = $author$project$Common$Helpers$mapEmbedView(url);
		return _Utils_Tuple2(
			{
				appregpage: $author$project$Pages$AppRegistration$init($author$project$Main$emptySession),
				authContext: $author$project$Common$Types$Loading,
				authenticationContext: $author$project$Common$Types$NotAsked,
				brand: $author$project$Common$Types$NotAsked,
				brandingpage: A3($author$project$Pages$Branding$init, $author$project$Main$emptySession, $author$project$Common$Brand$init, flags.cfgLogoContainer),
				customerspage: A2($author$project$Pages$Customers$init, $author$project$Main$emptySession, $author$project$Common$Types$Loading),
				date: $elm$core$Maybe$Nothing,
				debug: '',
				docpage: $author$project$Pages$Document$init($author$project$Main$emptySession),
				drawer: A2($author$project$Drawer$init, $elm$core$Maybe$Nothing, flags.isMobile),
				embedView: embedView,
				flags: flags,
				header: A3($author$project$Header$init, flags, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
				homeeditpage: A3($author$project$Pages$HomeEdit$init, $author$project$Main$emptySession, $author$project$Common$Types$NotAsked, $author$project$Common$Types$NotAsked),
				homepage: A3($author$project$Pages$Home$init, $author$project$Main$emptySession, $author$project$Common$Types$NotAsked, $author$project$Common$Types$NotAsked),
				isCustomerAdmin: false,
				key: key,
				language: $author$project$Language$Language$init,
				loginpage: $author$project$Pages$Login$init(flags),
				navbar: $author$project$Navbar$init(flags.isMobile),
				page: requestedPage,
				powerbipage: $author$project$Pages$PowerBI$init,
				reaactpage: $author$project$Pages$ReportSettings$init($author$project$Main$emptySession),
				reports: $author$project$Common$Types$NotAsked,
				rolepage: $author$project$Pages$RoleManagement$init($author$project$Main$emptySession),
				rppage: $author$project$Pages$RolePermissions$init($author$project$Main$emptySession),
				subspage: A2($author$project$Pages$Subscriptions$init, $author$project$Main$emptySession, _List_Nil),
				tabReportInformation: $elm$core$Dict$empty,
				url: url,
				userContext: $elm$core$Maybe$Nothing,
				userLogin: $author$project$Common$Types$NotAsked,
				userinfopage: $author$project$Pages$UserInfo$init($author$project$Main$emptySession),
				userpage: A2($author$project$Pages$UserManagement$init, $author$project$Main$emptySession, $elm$core$Maybe$Nothing)
			},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Ports$AzureAD$checkIfLoggedIn(_Utils_Tuple0),
						A2($elm$core$Task$perform, $author$project$Messages$ReceiveDate, $justinmimbs$date$Date$today)
					])));
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Messages$CheckAuthContext = function (a) {
	return {$: 'CheckAuthContext', a: a};
};
var $author$project$Messages$LanguageMsg = function (a) {
	return {$: 'LanguageMsg', a: a};
};
var $author$project$Messages$ReceiveLoginContext = function (a) {
	return {$: 'ReceiveLoginContext', a: a};
};
var $author$project$Messages$ReceiveStorageState = function (a) {
	return {$: 'ReceiveStorageState', a: a};
};
var $author$project$Messages$ReceiveStorageStates = function (a) {
	return {$: 'ReceiveStorageStates', a: a};
};
var $author$project$Messages$ReceiveUpdatedToken = function (a) {
	return {$: 'ReceiveUpdatedToken', a: a};
};
var $author$project$Messages$ToggleDrawerState = function (a) {
	return {$: 'ToggleDrawerState', a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 'Every', a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {processes: processes, taggers: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 'Nothing') {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.processes;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(_Utils_Tuple0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.taggers);
		if (_v0.$ === 'Nothing') {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Ports$AzureAD$loginContext = _Platform_incomingPort(
	'loginContext',
	A2(
		$elm$json$Json$Decode$andThen,
		function (tenantId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (name) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (issuer) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (idToken) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (expiresOn) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (email) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (claims) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (accountType) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (accessToken) {
																			return $elm$json$Json$Decode$succeed(
																				{accessToken: accessToken, accountType: accountType, claims: claims, email: email, expiresOn: expiresOn, idToken: idToken, issuer: issuer, name: name, tenantId: tenantId});
																		},
																		A2($elm$json$Json$Decode$field, 'accessToken', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'accountType', $elm$json$Json$Decode$string));
														},
														A2(
															$elm$json$Json$Decode$field,
															'claims',
															$elm$json$Json$Decode$list(
																A2(
																	$elm$json$Json$Decode$andThen,
																	function (val) {
																		return A2(
																			$elm$json$Json$Decode$andThen,
																			function (typ) {
																				return $elm$json$Json$Decode$succeed(
																					{typ: typ, val: val});
																			},
																			A2($elm$json$Json$Decode$field, 'typ', $elm$json$Json$Decode$string));
																	},
																	A2($elm$json$Json$Decode$field, 'val', $elm$json$Json$Decode$string)))));
												},
												A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'expiresOn', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'idToken', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'issuer', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'tenantId', $elm$json$Json$Decode$string)));
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Ports$PlannicaGridPorts$plannicaGridReciveToggleDrawerClose = _Platform_incomingPort('plannicaGridReciveToggleDrawerClose', $elm$json$Json$Decode$bool);
var $author$project$Ports$AzureAD$receiveUpdatedToken = _Platform_incomingPort(
	'receiveUpdatedToken',
	A2(
		$elm$json$Json$Decode$andThen,
		function (tenantId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (name) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (issuer) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (idToken) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (expiresOn) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (email) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (claims) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (accountType) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (accessToken) {
																			return $elm$json$Json$Decode$succeed(
																				{accessToken: accessToken, accountType: accountType, claims: claims, email: email, expiresOn: expiresOn, idToken: idToken, issuer: issuer, name: name, tenantId: tenantId});
																		},
																		A2($elm$json$Json$Decode$field, 'accessToken', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'accountType', $elm$json$Json$Decode$string));
														},
														A2(
															$elm$json$Json$Decode$field,
															'claims',
															$elm$json$Json$Decode$list(
																A2(
																	$elm$json$Json$Decode$andThen,
																	function (val) {
																		return A2(
																			$elm$json$Json$Decode$andThen,
																			function (typ) {
																				return $elm$json$Json$Decode$succeed(
																					{typ: typ, val: val});
																			},
																			A2($elm$json$Json$Decode$field, 'typ', $elm$json$Json$Decode$string));
																	},
																	A2($elm$json$Json$Decode$field, 'val', $elm$json$Json$Decode$string)))));
												},
												A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'expiresOn', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'idToken', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'issuer', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'tenantId', $elm$json$Json$Decode$string)));
var $author$project$Ports$LocalStorage$state = _Platform_incomingPort(
	'state',
	A2(
		$elm$json$Json$Decode$andThen,
		function (value) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (key) {
					return $elm$json$Json$Decode$succeed(
						{key: key, value: value});
				},
				A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string)));
var $author$project$Ports$LocalStorage$states = _Platform_incomingPort(
	'states',
	$elm$json$Json$Decode$list(
		A2(
			$elm$json$Json$Decode$andThen,
			function (value) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (key) {
						return $elm$json$Json$Decode$succeed(
							{key: key, value: value});
					},
					A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
			},
			A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string))));
var $author$project$Language$Language$ReceiveLang = function (a) {
	return {$: 'ReceiveLang', a: a};
};
var $author$project$Ports$Language$receiveLang = _Platform_incomingPort(
	'receiveLang',
	A2(
		$elm$json$Json$Decode$andThen,
		function (settings) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (main) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (login) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (error) {
									return $elm$json$Json$Decode$succeed(
										{error: error, login: login, main: main, settings: settings});
								},
								A2(
									$elm$json$Json$Decode$field,
									'error',
									A2(
										$elm$json$Json$Decode$andThen,
										function (userExists) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (notfound) {
													return $elm$json$Json$Decode$succeed(
														{notfound: notfound, userExists: userExists});
												},
												A2($elm$json$Json$Decode$field, 'notfound', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'userExists', $elm$json$Json$Decode$string))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'login',
							A2(
								$elm$json$Json$Decode$andThen,
								function (office365) {
									return $elm$json$Json$Decode$succeed(
										{office365: office365});
								},
								A2($elm$json$Json$Decode$field, 'office365', $elm$json$Json$Decode$string))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'main',
					A2(
						$elm$json$Json$Decode$andThen,
						function (title) {
							return $elm$json$Json$Decode$succeed(
								{title: title});
						},
						A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string))));
		},
		A2(
			$elm$json$Json$Decode$field,
			'settings',
			A2(
				$elm$json$Json$Decode$andThen,
				function (workOrSchoolAccount) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (upload) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (topbar) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (table) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (statusEnabled) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (statusDisabled) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (sidepanel) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (save) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (report) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (microsoftAccount) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (fileDrop) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (documents) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (discard) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (cancel) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (apply) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (addRole) {
																																			return $elm$json$Json$Decode$succeed(
																																				{addRole: addRole, apply: apply, cancel: cancel, discard: discard, documents: documents, fileDrop: fileDrop, microsoftAccount: microsoftAccount, report: report, save: save, sidepanel: sidepanel, statusDisabled: statusDisabled, statusEnabled: statusEnabled, table: table, topbar: topbar, upload: upload, workOrSchoolAccount: workOrSchoolAccount});
																																		},
																																		A2($elm$json$Json$Decode$field, 'addRole', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'apply', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'cancel', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'discard', $elm$json$Json$Decode$string));
																										},
																										A2(
																											$elm$json$Json$Decode$field,
																											'documents',
																											A2(
																												$elm$json$Json$Decode$andThen,
																												function (textbox) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (table) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (moreInfo) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (deleteConfirmationTitle) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (deleteConfirmationContent) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (buttons) {
																																							return $elm$json$Json$Decode$succeed(
																																								{buttons: buttons, deleteConfirmationContent: deleteConfirmationContent, deleteConfirmationTitle: deleteConfirmationTitle, moreInfo: moreInfo, table: table, textbox: textbox});
																																						},
																																						A2(
																																							$elm$json$Json$Decode$field,
																																							'buttons',
																																							A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (upload) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (refresh) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (_delete) {
																																													return $elm$json$Json$Decode$succeed(
																																														{_delete: _delete, refresh: refresh, upload: upload});
																																												},
																																												A2($elm$json$Json$Decode$field, 'delete', $elm$json$Json$Decode$string));
																																										},
																																										A2($elm$json$Json$Decode$field, 'refresh', $elm$json$Json$Decode$string));
																																								},
																																								A2($elm$json$Json$Decode$field, 'upload', $elm$json$Json$Decode$string))));
																																				},
																																				A2($elm$json$Json$Decode$field, 'deleteConfirmationContent', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'deleteConfirmationTitle', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'moreInfo', $elm$json$Json$Decode$string));
																														},
																														A2(
																															$elm$json$Json$Decode$field,
																															'table',
																															A2(
																																$elm$json$Json$Decode$andThen,
																																function (headerUploadedBy) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (headerLastModified) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (headerFilename) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (headerDescription) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (headerCreated) {
																																									return $elm$json$Json$Decode$succeed(
																																										{headerCreated: headerCreated, headerDescription: headerDescription, headerFilename: headerFilename, headerLastModified: headerLastModified, headerUploadedBy: headerUploadedBy});
																																								},
																																								A2($elm$json$Json$Decode$field, 'headerCreated', $elm$json$Json$Decode$string));
																																						},
																																						A2($elm$json$Json$Decode$field, 'headerDescription', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'headerFilename', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'headerLastModified', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'headerUploadedBy', $elm$json$Json$Decode$string))));
																												},
																												A2(
																													$elm$json$Json$Decode$field,
																													'textbox',
																													A2(
																														$elm$json$Json$Decode$andThen,
																														function (selectedFile) {
																															return $elm$json$Json$Decode$succeed(
																																{selectedFile: selectedFile});
																														},
																														A2($elm$json$Json$Decode$field, 'selectedFile', $elm$json$Json$Decode$string))))));
																								},
																								A2($elm$json$Json$Decode$field, 'fileDrop', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'microsoftAccount', $elm$json$Json$Decode$string));
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'report',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (savePbiTenant) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (save) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (report) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (pbiTenantName) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (orCreateNewPbiTenant) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (name) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (group) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (desc) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (choosePbiTenant) {
																																							return $elm$json$Json$Decode$succeed(
																																								{choosePbiTenant: choosePbiTenant, desc: desc, group: group, name: name, orCreateNewPbiTenant: orCreateNewPbiTenant, pbiTenantName: pbiTenantName, report: report, save: save, savePbiTenant: savePbiTenant});
																																						},
																																						A2($elm$json$Json$Decode$field, 'choosePbiTenant', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'desc', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'group', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'orCreateNewPbiTenant', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'pbiTenantName', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'report', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'save', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'savePbiTenant', $elm$json$Json$Decode$string))));
																		},
																		A2($elm$json$Json$Decode$field, 'save', $elm$json$Json$Decode$string));
																},
																A2(
																	$elm$json$Json$Decode$field,
																	'sidepanel',
																	A2(
																		$elm$json$Json$Decode$andThen,
																		function (userWorkPhone) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (userName) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (userMobilePhone) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (userEmail) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (userAdd) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (notAssignedRolesTitle) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (notAssignedMembersTitle) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (groupAccessSuccess) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (groupAccessFailed) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (assignedRolesTitle) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (assignedMembersTitle) {
																																							return $elm$json$Json$Decode$succeed(
																																								{assignedMembersTitle: assignedMembersTitle, assignedRolesTitle: assignedRolesTitle, groupAccessFailed: groupAccessFailed, groupAccessSuccess: groupAccessSuccess, notAssignedMembersTitle: notAssignedMembersTitle, notAssignedRolesTitle: notAssignedRolesTitle, userAdd: userAdd, userEmail: userEmail, userMobilePhone: userMobilePhone, userName: userName, userWorkPhone: userWorkPhone});
																																						},
																																						A2($elm$json$Json$Decode$field, 'assignedMembersTitle', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'assignedRolesTitle', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'groupAccessFailed', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'groupAccessSuccess', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'notAssignedMembersTitle', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'notAssignedRolesTitle', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'userAdd', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'userEmail', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'userMobilePhone', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'userName', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'userWorkPhone', $elm$json$Json$Decode$string))));
														},
														A2($elm$json$Json$Decode$field, 'statusDisabled', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'statusEnabled', $elm$json$Json$Decode$string));
										},
										A2(
											$elm$json$Json$Decode$field,
											'table',
											A2(
												$elm$json$Json$Decode$andThen,
												function (users) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (usermgt) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (roles) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (reaact) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (permissions) {
																					return $elm$json$Json$Decode$succeed(
																						{permissions: permissions, reaact: reaact, roles: roles, usermgt: usermgt, users: users});
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'permissions',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (headerRoleName) {
																							return $elm$json$Json$Decode$succeed(
																								{headerRoleName: headerRoleName});
																						},
																						A2($elm$json$Json$Decode$field, 'headerRoleName', $elm$json$Json$Decode$string))));
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'reaact',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (headerTab) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (headerService) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (headerName) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (headerApp) {
																											return $elm$json$Json$Decode$succeed(
																												{headerApp: headerApp, headerName: headerName, headerService: headerService, headerTab: headerTab});
																										},
																										A2($elm$json$Json$Decode$field, 'headerApp', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'headerName', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'headerService', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'headerTab', $elm$json$Json$Decode$string))));
																},
																A2(
																	$elm$json$Json$Decode$field,
																	'roles',
																	A2(
																		$elm$json$Json$Decode$andThen,
																		function (headerName) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (headerLevelDescription) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (headerAssignedUsers) {
																							return $elm$json$Json$Decode$succeed(
																								{headerAssignedUsers: headerAssignedUsers, headerLevelDescription: headerLevelDescription, headerName: headerName});
																						},
																						A2($elm$json$Json$Decode$field, 'headerAssignedUsers', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'headerLevelDescription', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'headerName', $elm$json$Json$Decode$string))));
														},
														A2(
															$elm$json$Json$Decode$field,
															'usermgt',
															A2(
																$elm$json$Json$Decode$andThen,
																function (headerName) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (headerLoginType) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (headerEmail) {
																					return $elm$json$Json$Decode$succeed(
																						{headerEmail: headerEmail, headerLoginType: headerLoginType, headerName: headerName});
																				},
																				A2($elm$json$Json$Decode$field, 'headerEmail', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'headerLoginType', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'headerName', $elm$json$Json$Decode$string))));
												},
												A2(
													$elm$json$Json$Decode$field,
													'users',
													A2(
														$elm$json$Json$Decode$andThen,
														function (headerRoles) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (headerName) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (headerEmail) {
																			return $elm$json$Json$Decode$succeed(
																				{headerEmail: headerEmail, headerName: headerName, headerRoles: headerRoles});
																		},
																		A2($elm$json$Json$Decode$field, 'headerEmail', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'headerName', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'headerRoles', $elm$json$Json$Decode$string))))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'topbar',
									A2(
										$elm$json$Json$Decode$andThen,
										function (userSwitch) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (roleSwitch) {
													return $elm$json$Json$Decode$succeed(
														{roleSwitch: roleSwitch, userSwitch: userSwitch});
												},
												A2($elm$json$Json$Decode$field, 'roleSwitch', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'userSwitch', $elm$json$Json$Decode$string))));
						},
						A2($elm$json$Json$Decode$field, 'upload', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'workOrSchoolAccount', $elm$json$Json$Decode$string)))));
var $author$project$Language$Language$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Ports$Language$receiveLang($author$project$Language$Language$ReceiveLang)
			]));
};
var $author$project$Main$subscriptions = function (model) {
	var langSubs = A2(
		$elm$core$Platform$Sub$map,
		$author$project$Messages$LanguageMsg,
		$author$project$Language$Language$subscriptions(model.language));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				langSubs,
				$author$project$Ports$AzureAD$loginContext($author$project$Messages$ReceiveLoginContext),
				$author$project$Ports$LocalStorage$state($author$project$Messages$ReceiveStorageState),
				$author$project$Ports$LocalStorage$states($author$project$Messages$ReceiveStorageStates),
				A2($elm$time$Time$every, 110000, $author$project$Messages$CheckAuthContext),
				$author$project$Ports$AzureAD$receiveUpdatedToken($author$project$Messages$ReceiveUpdatedToken),
				$author$project$Ports$PlannicaGridPorts$plannicaGridReciveToggleDrawerClose($author$project$Messages$ToggleDrawerState)
			]));
};
var $author$project$Messages$AppRegMsg = function (a) {
	return {$: 'AppRegMsg', a: a};
};
var $author$project$Messages$ApplicationSelectionClick = function (a) {
	return {$: 'ApplicationSelectionClick', a: a};
};
var $author$project$Messages$BrandingMsg = function (a) {
	return {$: 'BrandingMsg', a: a};
};
var $author$project$Messages$CustomersMsg = function (a) {
	return {$: 'CustomersMsg', a: a};
};
var $author$project$Messages$DocMsg = function (a) {
	return {$: 'DocMsg', a: a};
};
var $author$project$Messages$DrawerMsg = function (a) {
	return {$: 'DrawerMsg', a: a};
};
var $author$project$Common$Types$Failed = function (a) {
	return {$: 'Failed', a: a};
};
var $author$project$Messages$HeaderMsg = function (a) {
	return {$: 'HeaderMsg', a: a};
};
var $author$project$Messages$HomeEditMsg = function (a) {
	return {$: 'HomeEditMsg', a: a};
};
var $author$project$Messages$HomeMsg = function (a) {
	return {$: 'HomeMsg', a: a};
};
var $author$project$Messages$InitModels = {$: 'InitModels'};
var $author$project$Messages$InitPage = {$: 'InitPage'};
var $author$project$Common$Types$LoadingFailed = F2(
	function (a, b) {
		return {$: 'LoadingFailed', a: a, b: b};
	});
var $author$project$Common$Types$LoadingToken = function (a) {
	return {$: 'LoadingToken', a: a};
};
var $author$project$Messages$LoginMsg = function (a) {
	return {$: 'LoginMsg', a: a};
};
var $author$project$Messages$NavbarMsg = function (a) {
	return {$: 'NavbarMsg', a: a};
};
var $author$project$Messages$NoOp = {$: 'NoOp'};
var $author$project$Pages$Document$NoOp = {$: 'NoOp'};
var $author$project$Pages$Home$NoOp = {$: 'NoOp'};
var $author$project$Pages$HomeEdit$NoOp = {$: 'NoOp'};
var $author$project$Pages$ReportSettings$NoOp = {$: 'NoOp'};
var $author$project$Messages$PowerBIMsg = function (a) {
	return {$: 'PowerBIMsg', a: a};
};
var $author$project$Messages$RPMsg = function (a) {
	return {$: 'RPMsg', a: a};
};
var $author$project$Messages$ReaactMsg = function (a) {
	return {$: 'ReaactMsg', a: a};
};
var $author$project$Messages$ReceiveUserLogin = function (a) {
	return {$: 'ReceiveUserLogin', a: a};
};
var $author$project$Messages$Reset = {$: 'Reset'};
var $author$project$Messages$RoleMsg = function (a) {
	return {$: 'RoleMsg', a: a};
};
var $author$project$Drawer$SelectApplication = function (a) {
	return {$: 'SelectApplication', a: a};
};
var $author$project$Pages$Home$SetAnnouncements = function (a) {
	return {$: 'SetAnnouncements', a: a};
};
var $author$project$Pages$HomeEdit$SetAnnouncements = function (a) {
	return {$: 'SetAnnouncements', a: a};
};
var $author$project$Pages$ReportSettings$SetApplicationTabs = function (a) {
	return {$: 'SetApplicationTabs', a: a};
};
var $author$project$Drawer$SetCustomerService = function (a) {
	return {$: 'SetCustomerService', a: a};
};
var $author$project$Drawer$SetCustomers = F2(
	function (a, b) {
		return {$: 'SetCustomers', a: a, b: b};
	});
var $author$project$Pages$ReportSettings$SetCustomers = F2(
	function (a, b) {
		return {$: 'SetCustomers', a: a, b: b};
	});
var $author$project$Pages$Document$SetFolder = function (a) {
	return {$: 'SetFolder', a: a};
};
var $author$project$Pages$PowerBI$SetReport = F2(
	function (a, b) {
		return {$: 'SetReport', a: a, b: b};
	});
var $author$project$Pages$PowerBI$SetReportToken = F2(
	function (a, b) {
		return {$: 'SetReportToken', a: a, b: b};
	});
var $author$project$Pages$RolePermissions$SetRolePermissions = function (a) {
	return {$: 'SetRolePermissions', a: a};
};
var $author$project$Pages$RoleManagement$SetRoles = function (a) {
	return {$: 'SetRoles', a: a};
};
var $author$project$Pages$Home$SetServices = function (a) {
	return {$: 'SetServices', a: a};
};
var $author$project$Pages$HomeEdit$SetServices = function (a) {
	return {$: 'SetServices', a: a};
};
var $author$project$Pages$ReportSettings$SetServices = function (a) {
	return {$: 'SetServices', a: a};
};
var $author$project$Pages$RolePermissions$SetServices = function (a) {
	return {$: 'SetServices', a: a};
};
var $author$project$Navbar$SetTabs = F2(
	function (a, b) {
		return {$: 'SetTabs', a: a, b: b};
	});
var $author$project$Pages$RoleManagement$SetUsers = function (a) {
	return {$: 'SetUsers', a: a};
};
var $author$project$Pages$UserInfo$SetUsers = function (a) {
	return {$: 'SetUsers', a: a};
};
var $author$project$Pages$UserManagement$SetUsers = function (a) {
	return {$: 'SetUsers', a: a};
};
var $author$project$Common$State$State = F2(
	function (key, value) {
		return {key: key, value: value};
	});
var $author$project$Messages$SubsMsg = function (a) {
	return {$: 'SubsMsg', a: a};
};
var $author$project$Common$Types$Success = function (a) {
	return {$: 'Success', a: a};
};
var $author$project$Drawer$Toggle = {$: 'Toggle'};
var $author$project$Drawer$ToggleClose = function (a) {
	return {$: 'ToggleClose', a: a};
};
var $author$project$Messages$UpdateApplicationAndTabs = {$: 'UpdateApplicationAndTabs'};
var $author$project$Messages$UpdateSession = {$: 'UpdateSession'};
var $author$project$Messages$UserInfoMsg = function (a) {
	return {$: 'UserInfoMsg', a: a};
};
var $author$project$Messages$UserMsg = function (a) {
	return {$: 'UserMsg', a: a};
};
var $Janiczek$cmd_extra$Cmd$Extra$andThen = F2(
	function (fn, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = fn(model);
		var newModel = _v1.a;
		var newCmd = _v1.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, newCmd])));
	});
var $author$project$Common$Helpers$andThen = F3(
	function (update, msg, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = A2(update, msg, model);
		var model_ = _v1.a;
		var cmd_ = _v1.b;
		return _Utils_Tuple2(
			model_,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])));
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Common$Helpers$authContextToUserContext = function (authCtx) {
	var username = function () {
		var _v2 = A2(
			$elm$core$List$filter,
			function (c) {
				return c.typ === 'name';
			},
			authCtx.userClaims);
		if (_v2.b) {
			var x = _v2.a;
			var xs = _v2.b;
			return $elm$core$Maybe$Just(x.val);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var loginType = function () {
		var _v1 = authCtx.providerName;
		if (_v1 === 'microsoftaccount') {
			return 'MICROSOFT_ACCOUNT';
		} else {
			return 'WORK_OR_SCHOOL_ACCOUNT';
		}
	}();
	if (username.$ === 'Just') {
		var name = username.a;
		return $elm$core$Maybe$Just(
			{loginType: loginType, token: authCtx.accessToken, username: name});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Common$Helpers$authContextToUserContext_ = function (context) {
	return $elm$core$Maybe$Just(
		{loginType: context.accountType, token: context.idToken, username: context.name});
};
var $author$project$Config$configuration = {aadLoginUri: '/.auth/login/aad?post_login_redirect_url=', aadLogoutUri: '/.auth/logout', embedOffsetSeconds: 30 * 60, logoutUri: '/.auth/logout?post_logout_redirect_uri=', maLoginUri: '/.auth/login/microsoftaccount?post_login_redirect_url=', userOffsetSeconds: 30 * 60};
var $author$project$Common$Types$UserLogin = F6(
	function (userKey, userFullName, userEmail, defaultCustomerKey, customerDomain, errors) {
		return {customerDomain: customerDomain, defaultCustomerKey: defaultCustomerKey, errors: errors, userEmail: userEmail, userFullName: userFullName, userKey: userKey};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (_v0.$ === 'Ok') {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (_v1.$ === 'Ok') {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Main$decodeUserLogin = function () {
	var decodeUserLogin_ = A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['data', 'UserLogin', 'CustomerDomain']),
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['data', 'UserLogin', 'DefaultCustomerKey']),
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['data', 'UserLogin', 'UserEmail']),
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['data', 'UserLogin', 'UserFullName']),
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['data', 'UserLogin', 'UserKey']),
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Common$Types$UserLogin))))));
	var decodeErrorMessage = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['message']),
		$elm$json$Json$Decode$string);
	var decodeErrors = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['errors']),
		$elm$json$Json$Decode$list(decodeErrorMessage));
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['errors']),
		$elm$json$Json$Decode$list(decodeErrorMessage),
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
			_List_fromArray(
				['data', 'UserLogin', 'CustomerDomain']),
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
				_List_fromArray(
					['data', 'UserLogin', 'DefaultCustomerKey']),
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
					_List_fromArray(
						['data', 'UserLogin', 'UserEmail']),
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['data', 'UserLogin', 'UserFullName']),
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
							_List_fromArray(
								['data', 'UserLogin', 'UserKey']),
							$elm$json$Json$Decode$string,
							'',
							$elm$json$Json$Decode$succeed($author$project$Common$Types$UserLogin)))))));
}();
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(_Utils_Tuple0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Common$Encoder$encodePrimaryAndSecondaryColor = F2(
	function (primary, secondary) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'PrimaryColor',
					$elm$json$Json$Encode$string(primary)),
					_Utils_Tuple2(
					'SecondaryColor',
					$elm$json$Json$Encode$string(secondary))
				]));
	});
var $author$project$Common$Encoder$encodeStyleVariables = function (brand) {
	var secondaryColor = brand.secondary.hex;
	var primaryColor = brand.primary.hex;
	return A2($author$project$Common$Encoder$encodePrimaryAndSecondaryColor, primaryColor, secondaryColor);
};
var $author$project$Main$getAllApplicationTabs = function (applications) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.tabs;
			},
			applications));
};
var $author$project$Main$getAllServiceApplications = function (services) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.applications;
			},
			services));
};
var $author$project$Messages$ReceiveBrand = function (a) {
	return {$: 'ReceiveBrand', a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$API$Decoder$decodeTheme = function (match) {
	var defaultColor = function () {
		switch (match) {
			case 'PrimaryColorHEX':
				return '#77B1AB';
			case 'AccentColorHEX':
				return '#1A2E52';
			default:
				return '#000';
		}
	}();
	return A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		A3($author$project$Common$Color$Hsl, 0, 100, 50),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			match,
			$elm$json$Json$Decode$string,
			defaultColor,
			A2(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
				A3($author$project$Common$Color$Rgb, 0, 0, 0),
				$elm$json$Json$Decode$succeed($author$project$Common$Color$Color))));
};
var $author$project$API$Decoder$decodeBrand = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
	_List_fromArray(
		['data', 'getCustomerBrand', 'UseColorInHeaderAndNav']),
	$elm$json$Json$Decode$bool,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['data', 'getCustomerBrand', 'ShowLogoInAnnouncements']),
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
			_List_fromArray(
				['data', 'getCustomerBrand', 'ShowTitleInAnnouncements']),
			$elm$json$Json$Decode$bool,
			false,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
				_List_fromArray(
					['data', 'getCustomerBrand', 'ShowLogoInHeader']),
				$elm$json$Json$Decode$bool,
				false,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
					_List_fromArray(
						['data', 'getCustomerBrand', 'ShowTitleInHeader']),
					$elm$json$Json$Decode$bool,
					false,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['data', 'getCustomerBrand', 'FaviconUrl']),
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
							_List_fromArray(
								['data', 'getCustomerBrand', 'LogoRelativeUrl']),
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
								_List_fromArray(
									['data', 'getCustomerBrand', 'Title']),
								$elm$json$Json$Decode$string,
								'',
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
									_List_fromArray(
										['data', 'getCustomerBrand']),
									$author$project$API$Decoder$decodeTheme('AccentColorHEX'),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
										_List_fromArray(
											['data', 'getCustomerBrand']),
										$author$project$API$Decoder$decodeTheme('PrimaryColorHEX'),
										$elm$json$Json$Decode$succeed($author$project$Common$Brand$Model)))))))))));
var $author$project$API$Queries$getBrand = function (customerKey) {
	return '\r\n    query{\r\n        getCustomerBrand(CustomerKey: \"' + (customerKey + '\")\r\n        {\r\n            PrimaryColorHEX\r\n            AccentColorHEX\r\n            Title\r\n            LogoRelativeUrl\r\n            CustomCSSRelativeUrl\r\n            FaviconUrl\r\n            ShowTitleInHeader\r\n            ShowLogoInHeader\r\n            ShowTitleInAnnouncements\r\n            ShowLogoInAnnouncements\r\n            UseColorInHeaderAndNav\r\n        }\r\n    }\r\n    ');
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 'BadStatus_', a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 'BadUrl_', a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 'GoodStatus_', a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 'NetworkError_'};
var $elm$http$Http$Receiving = function (a) {
	return {$: 'Receiving', a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 'Sending', a: a};
};
var $elm$http$Http$Timeout_ = {$: 'Timeout_'};
var $elm$core$Maybe$isJust = function (maybe) {
	if (maybe.$ === 'Just') {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (result.$ === 'Ok') {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 'BadBody', a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 'BadStatus', a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $elm$http$Http$NetworkError = {$: 'NetworkError'};
var $elm$http$Http$Timeout = {$: 'Timeout'};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 'BadUrl_':
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 'Timeout_':
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 'NetworkError_':
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 'BadStatus_':
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.statusCode));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 'Header', a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {reqs: reqs, subs: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (cmd.$ === 'Cancel') {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 'Nothing') {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.tracker;
							if (_v4.$ === 'Nothing') {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.reqs));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (_v0.$ === 'Just') {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.subs)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 'Cancel', a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (cmd.$ === 'Cancel') {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					allowCookiesFromOtherDomains: r.allowCookiesFromOtherDomains,
					body: r.body,
					expect: A2(_Http_mapExpect, func, r.expect),
					headers: r.headers,
					method: r.method,
					timeout: r.timeout,
					tracker: r.tracker,
					url: r.url
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 'MySub', a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: false, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $elm$http$Http$stringBody = _Http_pair;
var $author$project$API$API$requestGraphQL = F5(
	function (token, loginType, query, msg, decoder) {
		return $elm$http$Http$request(
			{
				body: A2(
					$elm$http$Http$stringBody,
					'application/json',
					A2(
						$elm$json$Json$Encode$encode,
						0,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(query))
								])))),
				expect: A2($elm$http$Http$expectJson, msg, decoder),
				headers: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
						A2($elm$http$Http$header, 'LoginType', loginType)
					]),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: '/graphql'
			});
	});
var $author$project$Main$getBrand = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$getBrand(customerKey),
			$author$project$Messages$ReceiveBrand,
			$author$project$API$Decoder$decodeBrand);
	});
var $author$project$Messages$ReceiveCustomerService = function (a) {
	return {$: 'ReceiveCustomerService', a: a};
};
var $author$project$API$Queries$customerSerivces = function (customerkey) {
	return '\r\n    query {\r\n        UserCustomerService(CustomerKey: \"' + (customerkey + '\") {\r\n            CustomerKey\r\n            CustomerName\r\n            IsCustomerAdmin\r\n            Services {\r\n                ServiceKey\r\n                ServiceName\r\n                ServiceSortKey\r\n                Applications {\r\n                    ApplicationKey\r\n                    ApplicationName\r\n                    ApplicationSortKey\r\n                    ApplicationTypeKey\r\n                    ApplicationIsDefault\r\n                    ApplicationStartDateTime\r\n                    ApplicationEndDateTime\r\n                    ApplicationIsPaid\r\n                    ApplicationTileBackgroundImageUrl\r\n                    ApplicationTileBackgroundColorHEX\r\n                    ApplicationTileOpacity\r\n                    ApplicationTileTextColorHEX\r\n                    Tabs {\r\n                        TabKey\r\n                        TabName\r\n                        TabSortKey\r\n                        TabPath\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n    ');
};
var $author$project$Common$Types$CustomerService = F4(
	function (customerKey, customerName, isCustomerAdmin, services) {
		return {customerKey: customerKey, customerName: customerName, isCustomerAdmin: isCustomerAdmin, services: services};
	});
var $author$project$Common$Types$Service = F4(
	function (serviceKey, serviceName, serviceSortKey, applications) {
		return {applications: applications, serviceKey: serviceKey, serviceName: serviceName, serviceSortKey: serviceSortKey};
	});
var $author$project$Common$Types$Application = function (applicationKey) {
	return function (applicationName) {
		return function (applicationSortKey) {
			return function (applicationTypeKey) {
				return function (applicationIsDefault) {
					return function (applicationStartDateTime) {
						return function (applicationEndDateTime) {
							return function (applicationIsPaid) {
								return function (applicationTileBackgroundImageUrl) {
									return function (applicationTileBackgroundColorHEX) {
										return function (applicationTileOpacity) {
											return function (applicationTileColorHEX) {
												return function (tabs) {
													return {applicationEndDateTime: applicationEndDateTime, applicationIsDefault: applicationIsDefault, applicationIsPaid: applicationIsPaid, applicationKey: applicationKey, applicationName: applicationName, applicationSortKey: applicationSortKey, applicationStartDateTime: applicationStartDateTime, applicationTileBackgroundColorHEX: applicationTileBackgroundColorHEX, applicationTileBackgroundImageUrl: applicationTileBackgroundImageUrl, applicationTileColorHEX: applicationTileColorHEX, applicationTileOpacity: applicationTileOpacity, applicationTypeKey: applicationTypeKey, tabs: tabs};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Common$Types$Tab = F4(
	function (tabKey, tabName, tabSortKey, tabPath) {
		return {tabKey: tabKey, tabName: tabName, tabPath: tabPath, tabSortKey: tabSortKey};
	});
var $author$project$API$Decoder$decodeTabs = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'TabPath',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'TabSortKey',
		$elm$json$Json$Decode$int,
		0,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'TabName',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'TabKey',
				$elm$json$Json$Decode$string,
				'',
				$elm$json$Json$Decode$succeed($author$project$Common$Types$Tab)))));
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$API$Decoder$decodeApps = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'Tabs',
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeTabs),
	_List_Nil,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'ApplicationTileTextColorHEX',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'ApplicationTileOpacity',
			$elm$json$Json$Decode$float,
			0.0,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'ApplicationTileBackgroundColorHEX',
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'ApplicationTileBackgroundImageUrl',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'ApplicationIsPaid',
						$elm$json$Json$Decode$int,
						0,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'ApplicationEndDateTime',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'ApplicationStartDateTime',
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'ApplicationIsDefault',
									$elm$json$Json$Decode$int,
									0,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'ApplicationTypeKey',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'ApplicationSortKey',
											$elm$json$Json$Decode$int,
											0,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'ApplicationName',
												$elm$json$Json$Decode$string,
												'',
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'ApplicationKey',
													$elm$json$Json$Decode$string,
													'',
													$elm$json$Json$Decode$succeed($author$project$Common$Types$Application))))))))))))));
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$API$Decoder$decodeService = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Common$Types$Service,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['ServiceKey']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['ServiceName']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['ServiceSortKey']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['Applications']),
		$elm$json$Json$Decode$list($author$project$API$Decoder$decodeApps)));
var $author$project$API$Decoder$decodeCustomerService = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
	_List_fromArray(
		['data', 'UserCustomerService', 'Services']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeService),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['data', 'UserCustomerService', 'IsCustomerAdmin']),
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['data', 'UserCustomerService', 'CustomerName']),
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['data', 'UserCustomerService', 'CustomerKey']),
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Common$Types$CustomerService)))));
var $author$project$Main$getCustomerService = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$customerSerivces(customerKey),
			$author$project$Messages$ReceiveCustomerService,
			$author$project$API$Decoder$decodeCustomerService);
	});
var $author$project$Messages$ReceiveCustomers = F2(
	function (a, b) {
		return {$: 'ReceiveCustomers', a: a, b: b};
	});
var $author$project$API$Queries$customers = '\r\n    query {\r\n        customers(email: \"\", logintype: \"\") {\r\n            CustomerKey\r\n            CustomerName\r\n            CustomerDomain\r\n            DefaultIssuer\r\n        }\r\n    }\r\n    ';
var $author$project$API$Decoder$decodeCustomer = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Common$Types$Customer,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['CustomerKey']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['CustomerName']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['CustomerDomain']),
		$elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['DefaultIssuer']),
		$elm$json$Json$Decode$string));
var $author$project$API$Decoder$decodeCustomers = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'customers']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeCustomer));
var $author$project$Main$getCustomers = F3(
	function (token, loginType, initialCustomer) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$customers,
			$author$project$Messages$ReceiveCustomers(initialCustomer),
			$author$project$API$Decoder$decodeCustomers);
	});
var $author$project$Messages$ReceiveEmbedToken = F2(
	function (a, b) {
		return {$: 'ReceiveEmbedToken', a: a, b: b};
	});
var $author$project$Common$Types$EmbedToken = F2(
	function (id, expiration) {
		return {expiration: expiration, id: id};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$API$Decoder$decodeEmbedToken = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'expiration',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'token',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Common$Types$EmbedToken)));
var $author$project$API$Queries$embedToken = F8(
	function (email, groupid, reportid, azureTenantId, spnId, spnSecret, provider, rls) {
		var boolToJson = function (b) {
			return b ? 'true' : 'false';
		};
		return '\r\n            { "id": \"' + (email + ('\"\r\n            , "groupid": \"' + (groupid + ('\"\r\n            , "reportid": \"' + (reportid + ('\"\r\n            , "azuretenantid": \"' + (azureTenantId + ('\"\r\n            , "spnid": \"' + (spnId + ('\"\r\n            , "spnsecret": \"' + (spnSecret + ('\"\r\n            , "type": \"' + (provider + ('\"\r\n            , "rls": ' + (boolToJson(rls) + '\r\n            }\r\n        ')))))))))))))));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$API$API$post = F5(
	function (url_, token, loginType, expectation, query) {
		return $elm$http$Http$request(
			{
				body: A2($elm$http$Http$stringBody, 'application/json', query),
				expect: expectation,
				headers: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
						A2($elm$http$Http$header, 'LoginType', loginType)
					]),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: url_
			});
	});
var $author$project$Main$getEmbedToken = F3(
	function (model, tabKey, report) {
		var _v0 = _Utils_Tuple2(model.authContext, model.userLogin);
		if ((_v0.a.$ === 'Success') && (_v0.b.$ === 'Success')) {
			var ac = _v0.a.a;
			var ul = _v0.b.a;
			return A5(
				$author$project$API$API$post,
				'/embedtoken',
				ac.idToken,
				model.flags.cfgLoginType,
				A2(
					$elm$http$Http$expectJson,
					$author$project$Messages$ReceiveEmbedToken(tabKey),
					$author$project$API$Decoder$decodeEmbedToken),
				A8($author$project$API$Queries$embedToken, ul.userEmail, report.groupId, report.reportId, report.azureTenantId, report.spnId, report.spnSecret, '', report.rls));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Messages$ReceiveReport = F2(
	function (a, b) {
		return {$: 'ReceiveReport', a: a, b: b};
	});
var $author$project$Common$Types$Report = function (reportKey) {
	return function (reportName) {
		return function (reportSortKey) {
			return function (groupId) {
				return function (reportId) {
					return function (reportDescription) {
						return function (pbiTenantKey) {
							return function (azureTenantId) {
								return function (spnId) {
									return function (spnSecret) {
										return function (rls) {
											return function (transparentBackground) {
												return function (showFilters) {
													return function (showPageNavigation) {
														return function (reportInitialPage) {
															return {azureTenantId: azureTenantId, groupId: groupId, pbiTenantKey: pbiTenantKey, reportDescription: reportDescription, reportId: reportId, reportInitialPage: reportInitialPage, reportKey: reportKey, reportName: reportName, reportSortKey: reportSortKey, rls: rls, showFilters: showFilters, showPageNavigation: showPageNavigation, spnId: spnId, spnSecret: spnSecret, transparentBackground: transparentBackground};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$API$Decoder$decodeReport = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'ReportInitialPage',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'ShowPageNavigation',
		$elm$json$Json$Decode$bool,
		false,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'ShowFilters',
			$elm$json$Json$Decode$bool,
			false,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'TransparentBackground',
				$elm$json$Json$Decode$bool,
				false,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'RLS',
					$elm$json$Json$Decode$bool,
					false,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'SPNSecret',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'SPNId',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'AzureTenantId',
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'PBITenantKey',
									$elm$json$Json$Decode$string,
									'',
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'ReportDescription',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'PowerBIReportGUID',
											$elm$json$Json$Decode$string,
											'',
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'PowerBIGroupGUID',
												$elm$json$Json$Decode$string,
												'',
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'ReportSortKey',
													$elm$json$Json$Decode$int,
													0,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'ReportName',
														$elm$json$Json$Decode$string,
														'',
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'ReportKey',
															$elm$json$Json$Decode$string,
															$elm$json$Json$Decode$succeed($author$project$Common$Types$Report))))))))))))))));
var $author$project$API$Decoder$decodeReports = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'getreports']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeReport));
var $author$project$API$Queries$report = F2(
	function (customerKey, tabKey) {
		return '\r\n    query {\r\n        getreports(email: \"\", logintype: \"\", customerkey: \"' + (customerKey + ('\", tabkey: \"' + (tabKey + '\") {\r\n            ReportKey\r\n            ReportName\r\n            ReportSortKey\r\n            PowerBIGroupGUID\r\n            PowerBIReportGUID\r\n            ReportDescription\r\n            PBITenantKey\r\n            AzureTenantId\r\n            SPNId\r\n            SPNSecret\r\n            RLS\r\n            TransparentBackground\r\n            ShowFilters\r\n            ShowPageNavigation\r\n            ReportInitialPage\r\n        }\r\n    }\r\n    ')));
	});
var $author$project$Main$getReport = F4(
	function (token, loginType, customerKey, tabKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$report, customerKey, tabKey),
			$author$project$Messages$ReceiveReport(tabKey),
			$author$project$API$Decoder$decodeReports);
	});
var $author$project$Pages$PowerBI$isTokenExpired = F2(
	function (posix, token) {
		var tokenExpirationInMS = function () {
			var _v0 = $elm$core$String$toInt(token.expiration);
			if (_v0.$ === 'Just') {
				var time = _v0.a;
				return time;
			} else {
				return 0;
			}
		}();
		var posixInMS = $elm$time$Time$posixToMillis(posix);
		return _Utils_cmp(posixInMS, tokenExpirationInMS) > 0;
	});
var $author$project$Main$getReportIfTokenIsExpired = F6(
	function (posix, powerbiModel, token, loginType, customerKey, tabKey) {
		var _v0 = A2($elm$core$Dict$get, tabKey, powerbiModel.reports);
		if ((_v0.$ === 'Just') && (_v0.a.$ === 'Ready')) {
			var _v1 = _v0.a;
			var embedToken = _v1.b;
			return A2($author$project$Pages$PowerBI$isTokenExpired, posix, embedToken) ? A4($author$project$Main$getReport, token, loginType, customerKey, tabKey) : $elm$core$Platform$Cmd$none;
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Pages$Pages$getRoutedApplication = F2(
	function (tab, applications) {
		var _v0 = A2(
			$elm$core$List$filter,
			function (a) {
				return A2($elm$core$List$member, tab, a.tabs);
			},
			applications);
		if (_v0.b) {
			var app = _v0.a;
			var apps = _v0.b;
			return $elm$core$Maybe$Just(app);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Pages$Pages$getRoutedTab = F2(
	function (url, applications) {
		var _v0 = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.tabs;
				},
				applications));
		var list = _v0;
		var _v1 = A2(
			$elm$core$List$filter,
			function (tab) {
				return _Utils_eq(tab.tabPath, url);
			},
			list);
		if (_v1.b) {
			var tab = _v1.a;
			var tabs = _v1.b;
			return $elm$core$Maybe$Just(tab);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Ports$LocalStorage$getState = _Platform_outgoingPort('getState', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(_Utils_Tuple0),
				entries));
	});
var $author$project$Ports$LocalStorage$getStates = _Platform_outgoingPort(
	'getStates',
	$elm$json$Json$Encode$list($elm$json$Json$Encode$string));
var $author$project$Common$Brand$initBrandColors = F2(
	function (brand, imageUrl) {
		var secondary = A2(
			$elm$core$Maybe$withDefault,
			brand.secondary,
			A2($author$project$Common$Color$hexToColor, brand.secondary.hex, brand.secondary));
		var primary = A2(
			$elm$core$Maybe$withDefault,
			brand.primary,
			A2($author$project$Common$Color$hexToColor, brand.primary.hex, brand.primary));
		var brandWithUpdatedImage = $elm$core$String$isEmpty(imageUrl) ? _Utils_update(
			brand,
			{image: imageUrl}) : brand;
		return _Utils_update(
			brandWithUpdatedImage,
			{primary: primary, secondary: secondary});
	});
var $author$project$Drawer$initLoading = F2(
	function (token, isMobile) {
		return {customerService: $author$project$Common$Types$Loading, customers: $author$project$Common$Types$NotAsked, isMobile: isMobile, open: true, selectedApplication: $elm$core$Maybe$Nothing, selectedCustomer: $elm$core$Maybe$Nothing, token: token};
	});
var $author$project$Pages$PowerBI$InitNewReport = function (a) {
	return {$: 'InitNewReport', a: a};
};
var $author$project$Pages$AppRegistration$InitRequests = {$: 'InitRequests'};
var $author$project$Pages$Subscriptions$InitRequests = {$: 'InitRequests'};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Pages$AppRegistration$RequestApplicationTypes = {$: 'RequestApplicationTypes'};
var $author$project$Pages$AppRegistration$RequestServices = {$: 'RequestServices'};
var $author$project$Pages$AppRegistration$ReceiveCreateService = function (a) {
	return {$: 'ReceiveCreateService', a: a};
};
var $author$project$API$Queries$createService = function (service) {
	return '\r\n    mutation {\r\n        CreateService(ServiceKey: \"' + (service.serviceKey + ('\", ServiceName: \"' + (service.serviceName + ('\", ServiceSort: ' + ($elm$core$String$fromInt(service.serviceSort) + ('\r\n        , ServiceIcon: \"' + (service.serviceIcon + ('\", ApplicationKey: \"' + (service.applicationKey + ('\", ApplicationName: \"' + (service.applicationName + ('\"\r\n        , ApplicationIcon: \"' + (service.applicationIcon + ('\", ApplicationSort: ' + ($elm$core$String$fromInt(service.applicationSort) + (', ApplicationTypeKey: \"' + (service.applicationTypeKey + ('\"\r\n        , TabName: \"' + (service.tabName + ('\", TabSort: ' + ($elm$core$String$fromInt(service.tabSort) + (', TabPath: \"' + (service.tabPath + '\", ) {\r\n            Error\r\n        }\r\n    }\r\n    ')))))))))))))))))))))));
};
var $author$project$API$Decoder$decodeResponse = function (targets) {
	return $elm$json$Json$Decode$oneOf(
		A2(
			$elm$core$List$map,
			function (target) {
				return A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['data', target, 'Error']),
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$null('NULL')
							])));
			},
			targets));
};
var $author$project$Pages$AppRegistration$createService = F3(
	function (token, loginType, service) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$createService(service),
			$author$project$Pages$AppRegistration$ReceiveCreateService,
			$author$project$API$Decoder$decodeResponse(
				_List_fromArray(
					['CreateService'])));
	});
var $author$project$Pages$AppRegistration$ReceiveApplicationTypes = function (a) {
	return {$: 'ReceiveApplicationTypes', a: a};
};
var $author$project$Common$Types$ApplicationType = F4(
	function (key, name, description, usesParameters) {
		return {description: description, key: key, name: name, usesParameters: usesParameters};
	});
var $author$project$API$Decoder$decodeApplicationType = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'UsesParameters',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'ApplicationTypeDescription',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'ApplicationTypeName',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'ApplicationTypeKey',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Common$Types$ApplicationType)))));
var $author$project$API$Decoder$decodeApplicationTypes = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'GetApplicationTypes']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeApplicationType));
var $author$project$API$Queries$getApplicationTypes = '\r\n    query {\r\n        GetApplicationTypes {\r\n            ApplicationTypeKey\r\n            ApplicationTypeName\r\n            ApplicationTypeDescription\r\n            UsesParameters\r\n        }\r\n    }\r\n    ';
var $author$project$Pages$AppRegistration$getApplicationTypes = F2(
	function (token, loginType) {
		return A5($author$project$API$API$requestGraphQL, token, loginType, $author$project$API$Queries$getApplicationTypes, $author$project$Pages$AppRegistration$ReceiveApplicationTypes, $author$project$API$Decoder$decodeApplicationTypes);
	});
var $author$project$Pages$AppRegistration$ReceiveServices = function (a) {
	return {$: 'ReceiveServices', a: a};
};
var $author$project$API$Decoder$decodeServices = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'Services']),
			$elm$json$Json$Decode$list($author$project$API$Decoder$decodeService)),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'PurchasedServices']),
			$elm$json$Json$Decode$list($author$project$API$Decoder$decodeService)),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'getreportservices']),
			$elm$json$Json$Decode$list($author$project$API$Decoder$decodeService))
		]));
var $author$project$API$Queries$queryServices = function (customerKey) {
	return '\r\n    query {\r\n        Services(CustomerKey: \"' + (customerKey + '\") {\r\n            ServiceKey\r\n            ServiceName\r\n            ServiceSortKey\r\n            Applications {\r\n                ApplicationKey\r\n                ApplicationName\r\n                ApplicationSortKey\r\n                ApplicationTypeKey\r\n                ApplicationIsDefault\r\n                ApplicationStartDateTime\r\n                ApplicationEndDateTime\r\n                ApplicationIsPaid\r\n                ApplicationTileBackgroundImageUrl\r\n                ApplicationTileBackgroundColorHEX\r\n                ApplicationTileOpacity\r\n                ApplicationTileTextColorHEX\r\n                Tabs {\r\n                    TabKey\r\n                    TabName\r\n                    TabSortKey\r\n                    TabPath\r\n                }\r\n            }\r\n        }\r\n    }\r\n    ');
};
var $author$project$Pages$AppRegistration$getServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$queryServices(customerKey),
			$author$project$Pages$AppRegistration$ReceiveServices,
			$author$project$API$Decoder$decodeServices);
	});
var $author$project$Pages$AppRegistration$mapApplicationTypeToPath = function (applicationType) {
	var _v0 = applicationType.name;
	switch (_v0) {
		case 'Power BI Report Page':
			return '/report';
		case 'Plannica Grid':
			return '/grid';
		case 'Documents':
			return '/documents';
		default:
			return '';
	}
};
var $author$project$Pages$AppRegistration$sortApplicationsByName = F2(
	function (a, b) {
		var _v0 = A2($elm$core$Basics$compare, a.applicationName, b.applicationName);
		switch (_v0.$) {
			case 'LT':
				return $elm$core$Basics$LT;
			case 'EQ':
				return $elm$core$Basics$EQ;
			default:
				return $elm$core$Basics$GT;
		}
	});
var $author$project$Pages$AppRegistration$sortTabsByName = F2(
	function (a, b) {
		var _v0 = A2($elm$core$Basics$compare, a.tabName, b.tabName);
		switch (_v0.$) {
			case 'LT':
				return $elm$core$Basics$LT;
			case 'EQ':
				return $elm$core$Basics$EQ;
			default:
				return $elm$core$Basics$GT;
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Pages$AppRegistration$sortApplications = function (applications) {
	var _v0 = A2($elm$core$List$sortWith, $author$project$Pages$AppRegistration$sortApplicationsByName, applications);
	if (_v0.b) {
		var a = _v0.a;
		var aa = _v0.b;
		return A2(
			$elm$core$List$cons,
			_Utils_update(
				a,
				{
					tabs: A2($elm$core$List$sortWith, $author$project$Pages$AppRegistration$sortTabsByName, a.tabs)
				}),
			$author$project$Pages$AppRegistration$sortApplications(aa));
	} else {
		return _List_Nil;
	}
};
var $author$project$Pages$AppRegistration$sortServices = function (services) {
	if (services.b) {
		var s = services.a;
		var ss = services.b;
		return A2(
			$elm$core$List$cons,
			_Utils_update(
				s,
				{
					applications: $author$project$Pages$AppRegistration$sortApplications(s.applications)
				}),
			$author$project$Pages$AppRegistration$sortServices(ss));
	} else {
		return _List_Nil;
	}
};
var $elm$core$Debug$toString = _Debug_toString;
var $author$project$Pages$AppRegistration$ReceiveUpdateService = function (a) {
	return {$: 'ReceiveUpdateService', a: a};
};
var $author$project$API$Queries$updateService = function (service) {
	return '\r\n    mutation {\r\n        UpdateService(ServiceKey: \"' + (service.serviceKey + ('\", ServiceName: \"' + (service.serviceName + ('\", ServiceSort: ' + ($elm$core$String$fromInt(service.serviceSort) + ('\r\n        , ApplicationKey: \"' + (service.applicationKey + ('\", ApplicationName: \"' + (service.applicationName + ('\", ApplicationSort: ' + ($elm$core$String$fromInt(service.applicationSort) + ('\r\n        , TabKey: \"' + (service.tabKey + ('\", TabName: \"' + (service.tabName + ('\", TabSort: ' + ($elm$core$String$fromInt(service.tabSort) + ') {\r\n            Error\r\n        }\r\n    }\r\n    ')))))))))))))))));
};
var $author$project$Pages$AppRegistration$updateService = F3(
	function (token, loginType, service) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$updateService(service),
			$author$project$Pages$AppRegistration$ReceiveUpdateService,
			$author$project$API$Decoder$decodeResponse(
				_List_fromArray(
					['UpdateService'])));
	});
var $author$project$Pages$AppRegistration$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SelectNoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'InitRequests':
				return A2(
					$Janiczek$cmd_extra$Cmd$Extra$andThen,
					$author$project$Pages$AppRegistration$update($author$project$Pages$AppRegistration$RequestApplicationTypes),
					A2($author$project$Pages$AppRegistration$update, $author$project$Pages$AppRegistration$RequestServices, model));
			case 'RequestServices':
				return _Utils_Tuple2(
					model,
					A3($author$project$Pages$AppRegistration$getServices, model.session.token, model.session.loginType, model.session.customerKey));
			case 'ReceiveServices':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var services = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								services: $author$project$Common$Types$Success(
									$author$project$Pages$AppRegistration$sortServices(services))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								services: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RequestApplicationTypes':
				return _Utils_Tuple2(
					model,
					A2($author$project$Pages$AppRegistration$getApplicationTypes, model.session.token, model.session.loginType));
			case 'ReceiveApplicationTypes':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var applicationTypes = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								applicationTypes: $author$project$Common$Types$Success(applicationTypes)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								applicationTypes: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SelectService':
				var value = msg.a;
				var newService = model.newService;
				var _v3 = function () {
					var _v4 = model.services;
					if (_v4.$ === 'Success') {
						var services = _v4.a;
						var _v5 = A2(
							$elm$core$List$filter,
							function (s) {
								return _Utils_eq(s.serviceKey, value);
							},
							services);
						if (_v5.b) {
							var s = _v5.a;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(s),
								s.applications);
						} else {
							return _Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil);
						}
					} else {
						return _Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil);
					}
				}();
				var service = _v3.a;
				var applications = _v3.b;
				var updatedNewService = function () {
					if (service.$ === 'Just') {
						var s = service.a;
						return _Utils_update(
							newService,
							{applicationKey: '', applicationTypeKey: '', serviceIcon: '', serviceKey: s.serviceKey, serviceName: '', serviceSort: 0, tabPath: ''});
					} else {
						return _Utils_update(
							newService,
							{applicationKey: '', applicationTypeKey: '', serviceIcon: '', serviceKey: '', serviceName: '', serviceSort: 0, tabPath: ''});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newService: updatedNewService, selectedApplication: $elm$core$Maybe$Nothing, selectedService: service, selectedServiceApplications: applications}),
					$elm$core$Platform$Cmd$none);
			case 'SelectApplication':
				var value = msg.a;
				var newService = model.newService;
				var _v7 = function () {
					var _v8 = model.selectedService;
					if (_v8.$ === 'Just') {
						var service = _v8.a;
						var _v9 = A2(
							$elm$core$List$filter,
							function (a) {
								return _Utils_eq(a.applicationKey, value);
							},
							service.applications);
						if (_v9.b) {
							var a = _v9.a;
							return _Utils_Tuple2(
								$elm$core$Maybe$Just(a),
								a.tabs);
						} else {
							return _Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil);
						}
					} else {
						return _Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil);
					}
				}();
				var application = _v7.a;
				var tabs = _v7.b;
				var tabPath = function () {
					var _v11 = _Utils_Tuple2(application, model.applicationTypes);
					if ((_v11.a.$ === 'Just') && (_v11.b.$ === 'Success')) {
						var app = _v11.a.a;
						var applicationTypes = _v11.b.a;
						var _v12 = A2(
							$elm$core$List$filter,
							function (at) {
								return _Utils_eq(at.key, app.applicationTypeKey);
							},
							applicationTypes);
						if (!_v12.b) {
							return '';
						} else {
							var x = _v12.a;
							return $author$project$Pages$AppRegistration$mapApplicationTypeToPath(x);
						}
					} else {
						return '';
					}
				}();
				var updatedNewService = function () {
					if (application.$ === 'Just') {
						var a = application.a;
						return _Utils_update(
							newService,
							{applicationIcon: '', applicationKey: a.applicationKey, applicationName: '', applicationSort: 0, applicationTypeKey: a.applicationTypeKey, tabPath: tabPath});
					} else {
						return _Utils_update(
							newService,
							{applicationIcon: '', applicationKey: '', applicationName: '', applicationSort: 0, applicationTypeKey: '', tabPath: ''});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newService: updatedNewService, selectedApplication: application, selectedApplicationTabs: tabs}),
					$elm$core$Platform$Cmd$none);
			case 'SelectApplicationType':
				var value = msg.a;
				var tabPath = function () {
					var _v13 = model.applicationTypes;
					if (_v13.$ === 'Success') {
						var applicationTypes = _v13.a;
						var _v14 = A2(
							$elm$core$List$filter,
							function (at) {
								return _Utils_eq(at.key, value);
							},
							applicationTypes);
						if (!_v14.b) {
							return '';
						} else {
							var x = _v14.a;
							return $author$project$Pages$AppRegistration$mapApplicationTypeToPath(x);
						}
					} else {
						return '';
					}
				}();
				var newService = model.newService;
				var updatedNewService = _Utils_update(
					newService,
					{applicationTypeKey: value, tabPath: tabPath});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newService: updatedNewService}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateField':
				var field = msg.a;
				var value = msg.b;
				var newService = model.newService;
				var updatedNewService = function () {
					switch (field.$) {
						case 'ServiceField':
							return _Utils_update(
								newService,
								{serviceName: value});
						case 'ServiceIconField':
							return _Utils_update(
								newService,
								{serviceIcon: value});
						case 'ApplicationField':
							return _Utils_update(
								newService,
								{applicationName: value});
						case 'ApplicationIconField':
							return _Utils_update(
								newService,
								{applicationIcon: value});
						default:
							return _Utils_update(
								newService,
								{tabName: value});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newService: updatedNewService}),
					$elm$core$Platform$Cmd$none);
			case 'RequestCreateService':
				return _Utils_Tuple2(
					model,
					A3($author$project$Pages$AppRegistration$createService, model.session.token, model.session.loginType, model.newService));
			case 'ReceiveCreateService':
				var result = msg.a;
				if (result.$ === 'Ok') {
					return A2(
						$author$project$Pages$AppRegistration$update,
						$author$project$Pages$AppRegistration$RequestServices,
						_Utils_update(
							model,
							{newService: $author$project$Pages$AppRegistration$emptyNewService, selectedApplication: $elm$core$Maybe$Nothing, selectedApplicationTabs: _List_Nil, selectedService: $elm$core$Maybe$Nothing, selectedServiceApplications: _List_Nil, services: $author$project$Common$Types$NotAsked}));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								debug: $elm$core$Debug$toString(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SelectExistingService':
				var service = msg.a;
				var app = msg.b;
				var tab = msg.c;
				var updatedService = model.updatedService;
				var updatedUpdatedService = _Utils_update(
					updatedService,
					{applicationIcon: app.applicationTileBackgroundImageUrl, applicationKey: app.applicationKey, applicationName: app.applicationName, applicationSort: app.applicationSortKey, applicationTypeKey: app.applicationTypeKey, serviceKey: service.serviceKey, serviceName: service.serviceName, serviceSort: service.serviceSortKey, tabKey: tab.tabKey, tabName: tab.tabName, tabPath: tab.tabPath, tabSort: tab.tabSortKey});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							selectedExistingApplication: $elm$core$Maybe$Just(app),
							selectedExistingService: $elm$core$Maybe$Just(service),
							selectedExistingTab: $elm$core$Maybe$Just(tab),
							updatedService: updatedUpdatedService
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectOrder':
				var field = msg.a;
				var value = msg.b;
				var valueAsInt = function () {
					var _v18 = $elm$core$String$toInt(value);
					if (_v18.$ === 'Just') {
						var i = _v18.a;
						return i;
					} else {
						return -1;
					}
				}();
				var newService = model.newService;
				var updatedNewService = function () {
					switch (field.$) {
						case 'ServiceField':
							return _Utils_update(
								newService,
								{serviceSort: valueAsInt});
						case 'ApplicationField':
							return _Utils_update(
								newService,
								{applicationSort: valueAsInt});
						case 'TabField':
							return _Utils_update(
								newService,
								{tabSort: valueAsInt});
						default:
							return newService;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newService: updatedNewService}),
					$elm$core$Platform$Cmd$none);
			case 'CloseExisting':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedExistingApplication: $elm$core$Maybe$Nothing, selectedExistingService: $elm$core$Maybe$Nothing, selectedExistingTab: $elm$core$Maybe$Nothing, updatedService: $author$project$Pages$AppRegistration$emptyNewService}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateExistingField':
				var field = msg.a;
				var value = msg.b;
				var updatedService = model.updatedService;
				var updatedUpdatedService = function () {
					switch (field.$) {
						case 'ServiceField':
							return _Utils_update(
								updatedService,
								{serviceName: value});
						case 'ApplicationField':
							return _Utils_update(
								updatedService,
								{applicationName: value});
						case 'TabField':
							return _Utils_update(
								updatedService,
								{tabName: value});
						default:
							return updatedService;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{updatedService: updatedUpdatedService}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateExistingSort':
				var field = msg.a;
				var value = msg.b;
				var valueAsInt = function () {
					var _v21 = $elm$core$String$toInt(value);
					if (_v21.$ === 'Just') {
						var s = _v21.a;
						return s;
					} else {
						return 0;
					}
				}();
				var updatedService = model.updatedService;
				var updatedUpdatedService = function () {
					switch (field.$) {
						case 'ServiceField':
							return _Utils_update(
								updatedService,
								{serviceSort: valueAsInt});
						case 'ApplicationField':
							return _Utils_update(
								updatedService,
								{applicationSort: valueAsInt});
						case 'TabField':
							return _Utils_update(
								updatedService,
								{tabSort: valueAsInt});
						default:
							return updatedService;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{updatedService: updatedUpdatedService}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateExistingApplicationType':
				var value = msg.a;
				var updatedService = model.updatedService;
				var updatedUpdatedService = _Utils_update(
					updatedService,
					{applicationTypeKey: value});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{updatedService: updatedUpdatedService}),
					$elm$core$Platform$Cmd$none);
			case 'RequestUpdateService':
				var _v22 = !_Utils_eq(model.updatedService, $author$project$Pages$AppRegistration$emptyNewService);
				if (_v22) {
					return _Utils_Tuple2(
						model,
						A3($author$project$Pages$AppRegistration$updateService, model.session.token, model.session.loginType, model.updatedService));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var result = msg.a;
				if (result.$ === 'Ok') {
					return A2(
						$author$project$Pages$AppRegistration$update,
						$author$project$Pages$AppRegistration$RequestServices,
						_Utils_update(
							model,
							{selectedExistingApplication: $elm$core$Maybe$Nothing, selectedExistingService: $elm$core$Maybe$Nothing, selectedExistingTab: $elm$core$Maybe$Nothing, updatedService: $author$project$Pages$AppRegistration$emptyNewService}));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$PowerBI$CheckIfEmbedTokenIsExpired = F2(
	function (a, b) {
		return {$: 'CheckIfEmbedTokenIsExpired', a: a, b: b};
	});
var $author$project$Pages$PowerBI$Embed = function (a) {
	return {$: 'Embed', a: a};
};
var $author$project$Common$Types$Ready = F2(
	function (a, b) {
		return {$: 'Ready', a: a, b: b};
	});
var $author$project$Pages$PowerBI$ResetReport = {$: 'ResetReport'};
var $author$project$Ports$PowerBI$bootstrap = _Platform_outgoingPort('bootstrap', $elm$json$Json$Encode$string);
var $author$project$Common$Types$PowerBIEmbedConfig = F9(
	function (embedType, embedToken, embedURL, reportID, editMode, transparentBackground, showFilters, showPageNavigation, initialPage) {
		return {editMode: editMode, embedToken: embedToken, embedType: embedType, embedURL: embedURL, initialPage: initialPage, reportID: reportID, showFilters: showFilters, showPageNavigation: showPageNavigation, transparentBackground: transparentBackground};
	});
var $author$project$Pages$PowerBI$createPowerBIConfig = F2(
	function (tabReportInfo, tabKey) {
		var _v0 = function () {
			var _v1 = A2($elm$core$Dict$get, tabKey, tabReportInfo);
			if ((_v1.$ === 'Just') && (_v1.a.$ === 'Ready')) {
				var _v2 = _v1.a;
				var r = _v2.a;
				var t = _v2.b;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(r),
					$elm$core$Maybe$Just(t));
			} else {
				return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
			}
		}();
		var report = _v0.a;
		var token = _v0.b;
		var embedUrl = function () {
			if (report.$ === 'Just') {
				var r = report.a;
				return 'https://app.powerbi.com/reportEmbed?reportId=' + r.reportId;
			} else {
				return '';
			}
		}();
		var config = function () {
			var _v3 = _Utils_Tuple2(report, token);
			if ((_v3.a.$ === 'Just') && (_v3.b.$ === 'Just')) {
				var r = _v3.a.a;
				var t = _v3.b.a;
				return $elm$core$Maybe$Just(
					A9($author$project$Common$Types$PowerBIEmbedConfig, 'report', t.id, embedUrl, r.reportId, false, r.transparentBackground, r.showFilters, r.showPageNavigation, r.reportInitialPage));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return config;
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$PowerBI$embed = _Platform_outgoingPort(
	'embed',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'editMode',
					$elm$json$Json$Encode$bool($.editMode)),
					_Utils_Tuple2(
					'embedToken',
					$elm$json$Json$Encode$string($.embedToken)),
					_Utils_Tuple2(
					'embedType',
					$elm$json$Json$Encode$string($.embedType)),
					_Utils_Tuple2(
					'embedURL',
					$elm$json$Json$Encode$string($.embedURL)),
					_Utils_Tuple2(
					'initialPage',
					$elm$json$Json$Encode$string($.initialPage)),
					_Utils_Tuple2(
					'reportID',
					$elm$json$Json$Encode$string($.reportID)),
					_Utils_Tuple2(
					'showFilters',
					$elm$json$Json$Encode$bool($.showFilters)),
					_Utils_Tuple2(
					'showPageNavigation',
					$elm$json$Json$Encode$bool($.showPageNavigation)),
					_Utils_Tuple2(
					'transparentBackground',
					$elm$json$Json$Encode$bool($.transparentBackground))
				]));
	});
var $author$project$Ports$PowerBI$reset = _Platform_outgoingPort(
	'reset',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Pages$PowerBI$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'Embed':
				var tabKey = msg.a;
				var _v1 = A2($author$project$Pages$PowerBI$createPowerBIConfig, model.reports, tabKey);
				if (_v1.$ === 'Just') {
					var config = _v1.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$PowerBI$embed(config)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SetReport':
				var tabKey = msg.a;
				var report = msg.b;
				var reportId = function () {
					switch (report.$) {
						case 'Ready':
							var r = report.a;
							var et = report.b;
							return r.reportId;
						case 'LoadingToken':
							var r = report.a;
							return r.reportId;
						default:
							return '';
					}
				}();
				var newReports = A3($elm$core$Dict$insert, tabKey, report, model.reports);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{currentReport: report, reports: newReports}),
					$author$project$Ports$PowerBI$bootstrap(reportId));
			case 'SetReportToken':
				var tabKey = msg.a;
				var embedToken = msg.b;
				var newReports = function () {
					var _v4 = A2($elm$core$Dict$get, tabKey, model.reports);
					if ((_v4.$ === 'Just') && (_v4.a.$ === 'LoadingToken')) {
						var report = _v4.a.a;
						return A3(
							$elm$core$Dict$insert,
							tabKey,
							A2($author$project$Common$Types$Ready, report, embedToken),
							model.reports);
					} else {
						return model.reports;
					}
				}();
				var _v3 = A2($elm$core$Dict$get, tabKey, model.reports);
				if ((_v3.$ === 'Just') && (_v3.a.$ === 'LoadingToken')) {
					var report = _v3.a.a;
					return A2(
						$author$project$Pages$PowerBI$update,
						$author$project$Pages$PowerBI$Embed(tabKey),
						_Utils_update(
							model,
							{
								currentReport: A2($author$project$Common$Types$Ready, report, embedToken),
								reports: newReports
							}));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{currentReport: $author$project$Common$Types$LoadingReport}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ResetReport':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$PowerBI$reset(_Utils_Tuple0));
			case 'GetDateNow':
				var receiveMsg = msg.a;
				return _Utils_Tuple2(
					model,
					A2($elm$core$Task$perform, receiveMsg, $elm$time$Time$now));
			case 'CheckExpiration':
				var tabKey = msg.a;
				var now = msg.b;
				var isExpired = function () {
					var _v6 = A2($elm$core$Dict$get, tabKey, model.reports);
					if ((_v6.$ === 'Just') && (_v6.a.$ === 'Ready')) {
						var _v7 = _v6.a;
						var report = _v7.a;
						var token = _v7.b;
						return A2($author$project$Pages$PowerBI$isTokenExpired, now, token);
					} else {
						return true;
					}
				}();
				var updatedModel = function () {
					if (!isExpired) {
						return model;
					} else {
						return _Utils_update(
							model,
							{
								reports: A2($elm$core$Dict$remove, tabKey, model.reports)
							});
					}
				}();
				return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
			case 'CheckIfEmbedTokenIsExpired':
				var tabKey = msg.a;
				var posix = msg.b;
				var commands = function (model_) {
					var _v8 = A2($elm$core$Dict$get, tabKey, model.reports);
					if ((_v8.$ === 'Just') && (_v8.a.$ === 'Ready')) {
						var _v9 = _v8.a;
						var token = _v9.b;
						return A2($author$project$Pages$PowerBI$isTokenExpired, posix, token) ? A2($author$project$Pages$PowerBI$update, $author$project$Pages$PowerBI$ResetReport, model_) : A3(
							$author$project$Common$Helpers$andThen,
							$author$project$Pages$PowerBI$update,
							$author$project$Pages$PowerBI$Embed(tabKey),
							A2($author$project$Pages$PowerBI$update, $author$project$Pages$PowerBI$ResetReport, model_));
					} else {
						return A2($author$project$Pages$PowerBI$update, $author$project$Pages$PowerBI$ResetReport, model_);
					}
				};
				return commands(model);
			default:
				var tabKey = msg.a;
				var _v10 = A2($elm$core$Dict$get, tabKey, model.reports);
				if ((_v10.$ === 'Just') && (_v10.a.$ === 'Ready')) {
					var _v11 = _v10.a;
					var report = _v11.a;
					var token = _v11.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								currentReport: A2($author$project$Common$Types$Ready, report, token)
							}),
						A2(
							$elm$core$Task$perform,
							$author$project$Pages$PowerBI$CheckIfEmbedTokenIsExpired(tabKey),
							$elm$time$Time$now));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{currentReport: $author$project$Common$Types$LoadingReport}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$PowerBI$reset(_Utils_Tuple0)
								])));
				}
		}
	});
var $author$project$Pages$Subscriptions$RequestCustomerSubscription = function (a) {
	return {$: 'RequestCustomerSubscription', a: a};
};
var $author$project$Pages$Subscriptions$SelectSubApplication = function (a) {
	return {$: 'SelectSubApplication', a: a};
};
var $author$project$Pages$Subscriptions$SelectSubService = function (a) {
	return {$: 'SelectSubService', a: a};
};
var $justinmimbs$date$Date$Months = {$: 'Months'};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m.$) {
		case 'Jan':
			return 1;
		case 'Feb':
			return 2;
		case 'Mar':
			return 3;
		case 'Apr':
			return 4;
		case 'May':
			return 5;
		case 'Jun':
			return 6;
		case 'Jul':
			return 7;
		case 'Aug':
			return 8;
		case 'Sep':
			return 9;
		case 'Oct':
			return 10;
		case 'Nov':
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return $elm$time$Time$Jan;
		case 2:
			return $elm$time$Time$Feb;
		case 3:
			return $elm$time$Time$Mar;
		case 4:
			return $elm$time$Time$Apr;
		case 5:
			return $elm$time$Time$May;
		case 6:
			return $elm$time$Time$Jun;
		case 7:
			return $elm$time$Time$Jul;
		case 8:
			return $elm$time$Time$Aug;
		case 9:
			return $elm$time$Time$Sep;
		case 10:
			return $elm$time$Time$Oct;
		case 11:
			return $elm$time$Time$Nov;
		default:
			return $elm$time$Time$Dec;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {day: d, month: m, year: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0.a;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0.a;
	var y = $justinmimbs$date$Date$year(
		$justinmimbs$date$Date$RD(rd));
	return {
		ordinalDay: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		year: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0.a;
	var date = $justinmimbs$date$Date$toOrdinalDate(
		$justinmimbs$date$Date$RD(rd));
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.year, $elm$time$Time$Jan, date.ordinalDay);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0.a;
		switch (unit.$) {
			case 'Years':
				return A3(
					$justinmimbs$date$Date$add,
					$justinmimbs$date$Date$Months,
					12 * n,
					$justinmimbs$date$Date$RD(rd));
			case 'Months':
				var date = $justinmimbs$date$Date$toCalendarDate(
					$justinmimbs$date$Date$RD(rd));
				var wholeMonths = ((12 * (date.year - 1)) + ($justinmimbs$date$Date$monthToNumber(date.month) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return $justinmimbs$date$Date$RD(
					($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
						$elm$core$Basics$min,
						date.day,
						A2($justinmimbs$date$Date$daysInMonth, y, m)));
			case 'Weeks':
				return $justinmimbs$date$Date$RD(rd + (7 * n));
			default:
				return $justinmimbs$date$Date$RD(rd + n);
		}
	});
var $author$project$API$Queries$addCustomerPurchase = F5(
	function (customerKey, serviceKey, startDate, endDate, applicationKey) {
		return '\r\n    mutation {\r\n        AddCustomerPurchase(CustomerKey: \"' + (customerKey + ('\", ServiceKey: \"' + (serviceKey + ('\"\r\n        , StartDate: \"' + (startDate + ('\", EndDate: \"' + (endDate + ('\", ApplicationKey: \"' + (applicationKey + '\"\r\n        ) {Error}\r\n    }\r\n    ')))))))));
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 'Bad', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 'Good', a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parseA(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					var _v2 = callback(a);
					var parseB = _v2.a;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
					}
				}
			});
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 'UnexpectedChar'};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 'AddRight', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {col: col, contextStack: contextStack, problem: problem, row: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 'Empty'};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.row, s.col, x, s.context));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.offset, s.src);
				return _Utils_eq(newOffset, -1) ? A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: 1, context: s.context, indent: s.indent, offset: s.offset + 1, row: s.row + 1, src: s.src}) : A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: s.col + 1, context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src}));
			});
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.problem;
	if (problem.$ === 'Problem') {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 'ExpectingEnd'};
var $elm$parser$Parser$Advanced$end = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return _Utils_eq(
				$elm$core$String$length(s.src),
				s.offset) ? A3($elm$parser$Parser$Advanced$Good, false, _Utils_Tuple0, s) : A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0.a;
		var parseB = _v1.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v2 = parseA(s0);
				if (_v2.$ === 'Bad') {
					var p = _v2.a;
					var x = _v2.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v2.a;
					var a = _v2.b;
					var s1 = _v2.c;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3(
							$elm$parser$Parser$Advanced$Good,
							p1 || p2,
							A2(func, a, b),
							s2);
					}
				}
			});
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						func(a),
						s1);
				} else {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				}
			});
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 'Append', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
		});
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 'MonthAndDay', a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 'OrdinalDay', a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 'WeekAndWeekday', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0.a;
	return $elm$parser$Parser$Advanced$Parser(
		function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 'Bad') {
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, false, x);
			} else {
				var a = _v1.b;
				var s1 = _v1.c;
				return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
			}
		});
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$Good, true, a, s);
		});
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						A2(
							func,
							A3($elm$core$String$slice, s0.offset, s1.offset, s0.src),
							a),
						s1);
				}
			});
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$Good, false, a, s);
		});
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(_Utils_Tuple0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(_Utils_Tuple0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 'Expecting', a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 'Token', a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
				$elm$parser$Parser$Advanced$Good,
				progress,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$RD(
				($justinmimbs$date$Date$daysBeforeYear(y) + A2(
					$justinmimbs$date$Date$daysBeforeMonth,
					y,
					$justinmimbs$date$Date$numberToMonth(mn))) + d)) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$RD(
				$justinmimbs$date$Date$daysBeforeYear(y) + od)) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0.a;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$RD(jan4));
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$RD(
		$justinmimbs$date$Date$daysBeforeYear(y) + 1);
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$RD(
				($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn)) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 'MonthAndDay':
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 'WeekAndWeekday':
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(_Utils_Tuple0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return _Utils_eq(
											c,
											_Utils_chr('-'));
									}),
									$elm$parser$Parser$succeed(_Utils_Tuple0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Problem = function (a) {
	return {$: 'Problem', a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (result.$ === 'Ok') {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {col: col, problem: problem, row: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.row, p.col, p.problem);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 'Empty':
					return list;
				case 'AddRight':
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0.a;
		var _v1 = parse(
			{col: 1, context: _List_Nil, indent: 1, offset: 0, row: 1, src: src});
		if (_v1.$ === 'Good') {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (_v0.$ === 'Ok') {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq(
										_Utils_chr('T')))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map($justinmimbs$date$Date$deadEndToString),
			$elm$core$String$join('; '))));
var $author$project$Pages$Subscriptions$getInitialServiceAndApplication = function (services) {
	var _v0 = $elm$core$List$head(services);
	if (_v0.$ === 'Just') {
		var service = _v0.a;
		var _v1 = $elm$core$List$head(service.applications);
		if (_v1.$ === 'Just') {
			var application = _v1.a;
			return _Utils_Tuple2(service.serviceKey, application.applicationKey);
		} else {
			return _Utils_Tuple2(service.serviceKey, '');
		}
	} else {
		return _Utils_Tuple2('', '');
	}
};
var $author$project$Pages$Subscriptions$ReceiveCustomerSubscription = function (a) {
	return {$: 'ReceiveCustomerSubscription', a: a};
};
var $author$project$Pages$Subscriptions$getServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$queryServices(customerKey),
			$author$project$Pages$Subscriptions$ReceiveCustomerSubscription,
			$author$project$API$Decoder$decodeServices);
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$Between = F2(
	function (a, b) {
		return {$: 'Between', a: a, b: b};
	});
var $CurrySoftware$elm_datepicker$DatePicker$between = F2(
	function (start, end) {
		return (_Utils_cmp(start, end) > 0) ? A2($CurrySoftware$elm_datepicker$DatePicker$Date$Between, end, start) : A2($CurrySoftware$elm_datepicker$DatePicker$Date$Between, start, end);
	});
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0.a;
		var b = _v1.a;
		return A2($elm$core$Basics$compare, a, b);
	});
var $elm$time$Time$Sun = {$: 'Sun'};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.day;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.month;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.ordinalDay;
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				_Utils_chr('0'),
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$time$Time$Fri = {$: 'Fri'};
var $elm$time$Time$Mon = {$: 'Mon'};
var $elm$time$Time$Sat = {$: 'Sat'};
var $elm$time$Time$Thu = {$: 'Thu'};
var $elm$time$Time$Tue = {$: 'Tue'};
var $elm$time$Time$Wed = {$: 'Wed'};
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return $elm$time$Time$Mon;
		case 2:
			return $elm$time$Time$Tue;
		case 3:
			return $elm$time$Time$Wed;
		case 4:
			return $elm$time$Time$Thu;
		case 5:
			return $elm$time$Time$Fri;
		case 6:
			return $elm$time$Time$Sat;
		default:
			return $elm$time$Time$Sun;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0.a;
	var wdn = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$RD(rd));
	var wy = $justinmimbs$date$Date$year(
		$justinmimbs$date$Date$RD(rd + (4 - wdn)));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		weekNumber: 1 + (((rd - week1Day1) / 7) | 0),
		weekYear: wy,
		weekday: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.weekNumber;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.weekYear;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char.valueOf()) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.monthNameShort(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.monthName(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.monthNameShort(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.dayWithSuffix(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							_Utils_chr('0'),
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.weekdayNameShort(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.weekdayNameShort(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.weekdayNameShort(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.weekdayName(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.weekdayNameShort(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.weekdayNameShort(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4(
							$justinmimbs$date$Date$formatField,
							language,
							_Utils_chr('E'),
							length,
							date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (token.$ === 'Field') {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 'Literal', a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.src);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.offset, offset) < 0,
					_Utils_Tuple0,
					{col: col, context: s0.context, indent: s0.indent, offset: offset, row: row, src: s0.src});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.offset, s.row, s.col, s);
		});
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = $elm$parser$Parser$Advanced$Parser(
	function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, s.offset, s);
	});
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 'Literal') && _v0.b.b) && (_v0.b.a.$ === 'Literal')) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v0 = thunk(_Utils_Tuple0);
			var parse = _v0.a;
			return parse(s);
		});
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (!_Utils_eq(
		_char,
		_Utils_chr('\''))) && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(_Utils_Tuple0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(_Utils_Tuple0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq(
									_Utils_chr('\'')))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq(
								_Utils_chr('\'')))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq(
				_Utils_chr('\'')))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq(
						_Utils_chr('\''))),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (result.$ === 'Ok') {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m.$) {
		case 'Jan':
			return 'January';
		case 'Feb':
			return 'February';
		case 'Mar':
			return 'March';
		case 'Apr':
			return 'April';
		case 'May':
			return 'May';
		case 'Jun':
			return 'June';
		case 'Jul':
			return 'July';
		case 'Aug':
			return 'August';
		case 'Sep':
			return 'September';
		case 'Oct':
			return 'October';
		case 'Nov':
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd.$) {
		case 'Mon':
			return 'Monday';
		case 'Tue':
			return 'Tuesday';
		case 'Wed':
			return 'Wednesday';
		case 'Thu':
			return 'Thursday';
		case 'Fri':
			return 'Friday';
		case 'Sat':
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	dayWithSuffix: $justinmimbs$date$Date$withOrdinalSuffix,
	monthName: $justinmimbs$date$Date$monthToName,
	monthNameShort: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	weekdayName: $justinmimbs$date$Date$weekdayToName,
	weekdayNameShort: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $CurrySoftware$elm_datepicker$DatePicker$formatCell = function (day) {
	return $elm$html$Html$text(day);
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay = function (day) {
	switch (day.$) {
		case 'Mon':
			return 'Mo';
		case 'Tue':
			return 'Tu';
		case 'Wed':
			return 'We';
		case 'Thu':
			return 'Th';
		case 'Fri':
			return 'Fr';
		case 'Sat':
			return 'Sa';
		default:
			return 'Su';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth = function (month) {
	switch (month.$) {
		case 'Jan':
			return 'January';
		case 'Feb':
			return 'February';
		case 'Mar':
			return 'March';
		case 'Apr':
			return 'April';
		case 'May':
			return 'May';
		case 'Jun':
			return 'June';
		case 'Jul':
			return 'July';
		case 'Aug':
			return 'August';
		case 'Sep':
			return 'September';
		case 'Oct':
			return 'October';
		case 'Nov':
			return 'November';
		default:
			return 'December';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$Off = {$: 'Off'};
var $CurrySoftware$elm_datepicker$DatePicker$off = $CurrySoftware$elm_datepicker$DatePicker$Date$Off;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $CurrySoftware$elm_datepicker$DatePicker$defaultSettings = {
	cellFormatter: $CurrySoftware$elm_datepicker$DatePicker$formatCell,
	changeYear: $CurrySoftware$elm_datepicker$DatePicker$off,
	classNamespace: 'elm-datepicker--',
	containerClassList: _List_Nil,
	dateFormatter: $justinmimbs$date$Date$format('yyyy-MM-dd'),
	dayFormatter: $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay,
	firstDayOfWeek: $elm$time$Time$Sun,
	inputAttributes: _List_fromArray(
		[
			$elm$html$Html$Attributes$required(false)
		]),
	inputClassList: _List_Nil,
	inputId: $elm$core$Maybe$Nothing,
	inputName: $elm$core$Maybe$Nothing,
	isDisabled: $elm$core$Basics$always(false),
	monthFormatter: $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth,
	parser: $justinmimbs$date$Date$fromIsoString,
	placeholder: 'Please pick a date...',
	yearFormatter: $elm$core$String$fromInt
};
var $author$project$Pages$Subscriptions$settings = function (datePicker) {
	var isDisabled = F2(
		function (today, date) {
			return !_Utils_eq(
				A2($justinmimbs$date$Date$compare, today, date),
				$elm$core$Basics$LT);
		});
	return _Utils_update(
		$CurrySoftware$elm_datepicker$DatePicker$defaultSettings,
		{
			changeYear: A2($CurrySoftware$elm_datepicker$DatePicker$between, 2000, 2099)
		});
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $CurrySoftware$elm_datepicker$DatePicker$Disabled = function (a) {
	return {$: 'Disabled', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$EmptyString = {$: 'EmptyString'};
var $CurrySoftware$elm_datepicker$DatePicker$FailedInput = function (a) {
	return {$: 'FailedInput', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$Invalid = function (a) {
	return {$: 'Invalid', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$None = {$: 'None'};
var $CurrySoftware$elm_datepicker$DatePicker$Picked = function (a) {
	return {$: 'Picked', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$update = F3(
	function (settings, msg, _v0) {
		var model = _v0.a;
		var forceOpen = model.forceOpen;
		var focused = model.focused;
		switch (msg.$) {
			case 'CurrentDate':
				var date = msg.a;
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{
								focused: $elm$core$Maybe$Just(date),
								today: date
							})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 'ChangeFocus':
				var date = msg.a;
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{
								focused: $elm$core$Maybe$Just(date)
							})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 'Pick':
				var date = msg.a;
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{focused: $elm$core$Maybe$Nothing, inputText: $elm$core$Maybe$Nothing, open: false})),
					$CurrySoftware$elm_datepicker$DatePicker$Picked(date));
			case 'Text':
				var text = msg.a;
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{
								inputText: $elm$core$Maybe$Just(text)
							})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 'SubmitText':
				if (forceOpen) {
					return _Utils_Tuple2(
						$CurrySoftware$elm_datepicker$DatePicker$DatePicker(model),
						$CurrySoftware$elm_datepicker$DatePicker$None);
				} else {
					var dateEvent = function () {
						var _v5 = model.inputText;
						if (_v5.$ === 'Nothing') {
							return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
						} else {
							if (_v5.a === '') {
								return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
							} else {
								var rawInput = _v5.a;
								var _v6 = settings.parser(rawInput);
								if (_v6.$ === 'Ok') {
									var date = _v6.a;
									return settings.isDisabled(date) ? $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Disabled(date)) : $CurrySoftware$elm_datepicker$DatePicker$Picked(date);
								} else {
									var e = _v6.a;
									return $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Invalid(e));
								}
							}
						}
					}();
					return _Utils_Tuple2(
						$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
							_Utils_update(
								model,
								{
									focused: function () {
										if (dateEvent.$ === 'Picked') {
											var date = dateEvent.a;
											return $elm$core$Maybe$Just(date);
										} else {
											return model.focused;
										}
									}(),
									inputText: function () {
										if (dateEvent.$ === 'Picked') {
											return $elm$core$Maybe$Nothing;
										} else {
											return model.inputText;
										}
									}()
								})),
						dateEvent);
				}
			case 'Focus':
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{forceOpen: false, open: true})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 'Blur':
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{open: forceOpen})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 'MouseDown':
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{forceOpen: true})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			default:
				return _Utils_Tuple2(
					$CurrySoftware$elm_datepicker$DatePicker$DatePicker(
						_Utils_update(
							model,
							{forceOpen: false})),
					$CurrySoftware$elm_datepicker$DatePicker$None);
		}
	});
var $author$project$API$Queries$updateCustomerPurchase = F5(
	function (customerKey, serviceKey, startDate, endDate, applicationKey) {
		return '\r\n    mutation {\r\n        UpdateCustomerPurchase(CustomerKey: \"' + (customerKey + ('\", ServiceKey: \"' + (serviceKey + ('\"\r\n        , StartDate: \"' + (startDate + ('\", EndDate: \"' + (endDate + ('\", ApplicationKey: \"' + (applicationKey + '\"\r\n        ) {Error}\r\n    }\r\n    ')))))))));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Pick = function (a) {
	return {$: 'Pick', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$pick = $CurrySoftware$elm_datepicker$DatePicker$Pick;
var $author$project$Pages$Subscriptions$updatePickerDate = F2(
	function (date, datepicker) {
		var subMsg = function () {
			if (date.$ === 'Just') {
				var d = date.a;
				return $elm$core$Maybe$Just(
					$CurrySoftware$elm_datepicker$DatePicker$pick(d));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var _v0 = function () {
			if (subMsg.$ === 'Just') {
				var msg = subMsg.a;
				return A3(
					$CurrySoftware$elm_datepicker$DatePicker$update,
					$author$project$Pages$Subscriptions$settings(datepicker),
					msg,
					datepicker);
			} else {
				return _Utils_Tuple2(datepicker, $CurrySoftware$elm_datepicker$DatePicker$None);
			}
		}();
		var newDatePicker = _v0.a;
		var dateEvent = _v0.b;
		var newDate = function () {
			if (dateEvent.$ === 'Picked') {
				var changedDate = dateEvent.a;
				return $elm$core$Maybe$Just(changedDate);
			} else {
				return date;
			}
		}();
		return _Utils_Tuple2(newDatePicker, newDate);
	});
var $author$project$Pages$Subscriptions$ReceiveUpdatedPurchase = function (a) {
	return {$: 'ReceiveUpdatedPurchase', a: a};
};
var $author$project$Pages$Subscriptions$updatePurchase = F3(
	function (token, loginType, query) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			query,
			$author$project$Pages$Subscriptions$ReceiveUpdatedPurchase,
			$author$project$API$Decoder$decodeResponse(
				_List_fromArray(
					['AddCustomerPurchase', 'UpdateCustomerPurchase'])));
	});
var $author$project$Pages$Subscriptions$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'InitRequests':
				var _v1 = model.selectedCustomer;
				if (_v1.$ === 'Nothing') {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var customer = _v1.a;
					return A2(
						$author$project$Pages$Subscriptions$update,
						$author$project$Pages$Subscriptions$RequestCustomerSubscription(customer.customerKey),
						model);
				}
			case 'SelectSubApplication':
				var application = msg.a;
				var startDate = function () {
					var _v5 = $justinmimbs$date$Date$fromIsoString(application.applicationStartDateTime);
					if (_v5.$ === 'Ok') {
						var date = _v5.a;
						return $elm$core$Maybe$Just(date);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var endDate = function () {
					var _v4 = $justinmimbs$date$Date$fromIsoString(application.applicationEndDateTime);
					if (_v4.$ === 'Ok') {
						var date = _v4.a;
						return $elm$core$Maybe$Just(date);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var _v2 = A2($author$project$Pages$Subscriptions$updatePickerDate, startDate, model.startPicker);
				var newStartPicker = _v2.a;
				var newStartDate = _v2.b;
				var _v3 = A2($author$project$Pages$Subscriptions$updatePickerDate, endDate, model.endPicker);
				var newEndPicker = _v3.a;
				var newEndDate = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							endDate: newEndDate,
							endPicker: newEndPicker,
							selectedApplication: $elm$core$Maybe$Just(application),
							startDate: newStartDate,
							startPicker: newStartPicker
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectSubService':
				var service = msg.a;
				var initialApplication = function () {
					var _v7 = service.applications;
					if (_v7.b) {
						var appHead = _v7.a;
						var appTail = _v7.b;
						return $elm$core$Maybe$Just(appHead);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (initialApplication.$ === 'Just') {
					var application = initialApplication.a;
					return A2(
						$author$project$Pages$Subscriptions$update,
						$author$project$Pages$Subscriptions$SelectSubApplication(application),
						_Utils_update(
							model,
							{
								selectedService: $elm$core$Maybe$Just(service)
							}));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								selectedService: $elm$core$Maybe$Just(service)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RequestCustomerSubscription':
				var customerKey = msg.a;
				var appliedCustomer = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (c) {
							return _Utils_eq(c.customerKey, customerKey);
						},
						model.customers));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedCustomer: appliedCustomer}),
					A3($author$project$Pages$Subscriptions$getServices, model.session.token, model.session.loginType, customerKey));
			case 'ReceiveCustomerSubscription':
				var result = msg.a;
				var _v8 = function () {
					var _v9 = _Utils_Tuple2(model.selectedService, model.selectedApplication);
					if ((_v9.a.$ === 'Just') && (_v9.b.$ === 'Just')) {
						var service = _v9.a.a;
						var application = _v9.b.a;
						return _Utils_Tuple2(service.serviceKey, application.applicationKey);
					} else {
						if (result.$ === 'Ok') {
							var services = result.a;
							return $author$project$Pages$Subscriptions$getInitialServiceAndApplication(services);
						} else {
							return _Utils_Tuple2('', '');
						}
					}
				}();
				var selectedServiceKey = _v8.a;
				var selectedApplicationKey = _v8.b;
				var _v11 = function () {
					if (result.$ === 'Ok') {
						var services = result.a;
						var _v13 = A2(
							$elm$core$List$filter,
							function (s) {
								return _Utils_eq(s.serviceKey, selectedServiceKey);
							},
							services);
						if (_v13.b) {
							var serviceHead = _v13.a;
							var serviceTail = _v13.b;
							var _v14 = A2(
								$elm$core$List$filter,
								function (a) {
									return _Utils_eq(a.applicationKey, selectedApplicationKey);
								},
								serviceHead.applications);
							if (_v14.b) {
								var appHead = _v14.a;
								var appTail = _v14.b;
								return _Utils_Tuple2(
									$elm$core$Maybe$Just(serviceHead),
									$elm$core$Maybe$Just(appHead));
							} else {
								return _Utils_Tuple2(
									$elm$core$Maybe$Just(serviceHead),
									$elm$core$List$head(serviceHead.applications));
							}
						} else {
							return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
						}
					} else {
						return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
					}
				}();
				var updatedSelectedService = _v11.a;
				var updatedSelectedApp = _v11.b;
				if (result.$ === 'Ok') {
					var services = result.a;
					var _v16 = _Utils_Tuple2(updatedSelectedService, updatedSelectedApp);
					if ((_v16.a.$ === 'Just') && (_v16.b.$ === 'Just')) {
						var service = _v16.a.a;
						var app = _v16.b.a;
						return A3(
							$author$project$Common$Helpers$andThen,
							$author$project$Pages$Subscriptions$update,
							$author$project$Pages$Subscriptions$SelectSubApplication(app),
							A2(
								$author$project$Pages$Subscriptions$update,
								$author$project$Pages$Subscriptions$SelectSubService(service),
								_Utils_update(
									model,
									{
										services: $author$project$Common$Types$Success(services)
									})));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									services: $author$project$Common$Types$Success(services)
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								services: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'UpdateCustomerPurchase':
				var isActive = msg.a;
				var startDate = function () {
					var _v23 = model.startDate;
					if (_v23.$ === 'Just') {
						var date = _v23.a;
						return $elm$core$Maybe$Just(
							$justinmimbs$date$Date$toIsoString(date));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var serviceKey = function () {
					var _v22 = model.selectedService;
					if (_v22.$ === 'Just') {
						var s = _v22.a;
						return s.serviceKey;
					} else {
						return '';
					}
				}();
				var endDate = function () {
					var _v21 = model.endDate;
					if (_v21.$ === 'Just') {
						var date = _v21.a;
						return $elm$core$Maybe$Just(
							$justinmimbs$date$Date$toIsoString(date));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var customerKey = function () {
					var _v20 = model.selectedCustomer;
					if (_v20.$ === 'Just') {
						var c = _v20.a;
						return c.customerKey;
					} else {
						return '';
					}
				}();
				var applicationKey = function () {
					var _v19 = model.selectedApplication;
					if (_v19.$ === 'Just') {
						var a = _v19.a;
						return a.applicationKey;
					} else {
						return '';
					}
				}();
				var cmd = function () {
					var _v18 = _Utils_Tuple2(startDate, endDate);
					if ((_v18.a.$ === 'Just') && (_v18.b.$ === 'Just')) {
						var sDate = _v18.a.a;
						var eDate = _v18.b.a;
						return isActive ? A3(
							$author$project$Pages$Subscriptions$updatePurchase,
							model.session.token,
							model.session.loginType,
							A5($author$project$API$Queries$updateCustomerPurchase, customerKey, serviceKey, sDate, eDate, applicationKey)) : A3(
							$author$project$Pages$Subscriptions$updatePurchase,
							model.session.token,
							model.session.loginType,
							A5($author$project$API$Queries$addCustomerPurchase, customerKey, serviceKey, sDate, eDate, applicationKey));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				var _v17 = _Utils_Tuple2(startDate, endDate);
				if ((_v17.a.$ === 'Just') && (_v17.b.$ === 'Just')) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{services: $author$project$Common$Types$Loading}),
						cmd);
				} else {
					return _Utils_Tuple2(model, cmd);
				}
			case 'ReceiveUpdatedPurchase':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var value = result.a;
					var _v25 = model.selectedCustomer;
					if (_v25.$ === 'Just') {
						var c = _v25.a;
						return A2(
							$author$project$Pages$Subscriptions$update,
							$author$project$Pages$Subscriptions$RequestCustomerSubscription(c.customerKey),
							_Utils_update(
								model,
								{
									debug: $elm$core$Debug$toString(value)
								}));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									debug: $elm$core$Debug$toString(value)
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								debug: $elm$core$Debug$toString(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SelectDropdown':
				var key = msg.a;
				var value = msg.b;
				var updatedDropdownValues = A3($elm$core$Dict$insert, key, value, model.selectedDropdownValues);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedDropdownValues: updatedDropdownValues}),
					$elm$core$Platform$Cmd$none);
			case 'ToDatePicker':
				var startOrEnd = msg.a;
				var subMsg = msg.b;
				var _v26 = function () {
					var _v27 = $elm$core$String$toLower(startOrEnd);
					switch (_v27) {
						case 'start':
							return A3(
								$CurrySoftware$elm_datepicker$DatePicker$update,
								$author$project$Pages$Subscriptions$settings(model.startPicker),
								subMsg,
								model.startPicker);
						case 'end':
							return A3(
								$CurrySoftware$elm_datepicker$DatePicker$update,
								$author$project$Pages$Subscriptions$settings(model.endPicker),
								subMsg,
								model.endPicker);
						default:
							return A3(
								$CurrySoftware$elm_datepicker$DatePicker$update,
								$author$project$Pages$Subscriptions$settings(model.datepicker),
								subMsg,
								model.datepicker);
					}
				}();
				var newDatePicker = _v26.a;
				var dateEvent = _v26.b;
				var newDate = function () {
					if (dateEvent.$ === 'Picked') {
						var changedDate = dateEvent.a;
						return $elm$core$Maybe$Just(changedDate);
					} else {
						var _v30 = $elm$core$String$toLower(startOrEnd);
						switch (_v30) {
							case 'start':
								return model.startDate;
							case 'end':
								return model.endDate;
							default:
								return model.date;
						}
					}
				}();
				var _v28 = $elm$core$String$toLower(startOrEnd);
				switch (_v28) {
					case 'start':
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									debug: $elm$core$Debug$toString(subMsg),
									startDate: newDate,
									startPicker: newDatePicker
								}),
							$elm$core$Platform$Cmd$none);
					case 'end':
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{endDate: newDate, endPicker: newDatePicker}),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{date: newDate, datepicker: newDatePicker}),
							$elm$core$Platform$Cmd$none);
				}
			default:
				var unit = msg.a;
				var count = msg.b;
				var updatedEndDate = function () {
					var _v33 = model.date;
					if (_v33.$ === 'Just') {
						var date = _v33.a;
						return $elm$core$Maybe$Just(
							A3($justinmimbs$date$Date$add, unit, count, date));
					} else {
						return model.endDate;
					}
				}();
				var _v31 = (count < 0) ? A2($author$project$Pages$Subscriptions$updatePickerDate, updatedEndDate, model.startPicker) : A2($author$project$Pages$Subscriptions$updatePickerDate, model.date, model.startPicker);
				var newStartDatePicker = _v31.a;
				var newStartDate = _v31.b;
				var _v32 = A2($author$project$Pages$Subscriptions$updatePickerDate, updatedEndDate, model.endPicker);
				var newEndDatePicker = _v32.a;
				var newEndDate = _v32.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{endDate: newEndDate, endPicker: newEndDatePicker, startDate: newStartDate, startPicker: newStartDatePicker}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$initPageModel = function (model) {
	var _v0 = function () {
		var _v1 = model.page;
		_v1$3:
		while (true) {
			switch (_v1.$) {
				case 'Reaact':
					if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
						var tk = _v1.a.a;
						var ck = _v1.b.a;
						var _v2 = A2(
							$author$project$Pages$PowerBI$update,
							$author$project$Pages$PowerBI$InitNewReport(tk),
							model.powerbipage);
						var subModel = _v2.a;
						var subMsg = _v2.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{powerbipage: subModel}),
							A2($elm$core$Platform$Cmd$map, $author$project$Messages$PowerBIMsg, subMsg));
					} else {
						break _v1$3;
					}
				case 'CustomerSubscription':
					var _v3 = A2($author$project$Pages$Subscriptions$update, $author$project$Pages$Subscriptions$InitRequests, model.subspage);
					var subModel = _v3.a;
					var subMsg = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{subspage: subModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Messages$SubsMsg, subMsg));
				case 'ApplicationRegistration':
					var _v4 = A2($author$project$Pages$AppRegistration$update, $author$project$Pages$AppRegistration$InitRequests, model.appregpage);
					var subModel = _v4.a;
					var subMsg = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{appregpage: subModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Messages$AppRegMsg, subMsg));
				default:
					break _v1$3;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}();
	var updatedModel = _v0.a;
	var updateMsg = _v0.b;
	return _Utils_Tuple2(updatedModel, updateMsg);
};
var $CurrySoftware$elm_datepicker$DatePicker$initFromDate = function (date) {
	return $CurrySoftware$elm_datepicker$DatePicker$DatePicker(
		{
			focused: $elm$core$Maybe$Just(date),
			forceOpen: false,
			inputText: $elm$core$Maybe$Nothing,
			open: false,
			today: date
		});
};
var $author$project$Pages$Subscriptions$initWithDatepicker = F3(
	function (session, customers, maybeDate) {
		var initialCustomer = $elm$core$List$head(customers);
		var date = function () {
			if (maybeDate.$ === 'Just') {
				var d = maybeDate.a;
				return d;
			} else {
				return A3($justinmimbs$date$Date$fromCalendarDate, 2020, $elm$time$Time$Jan, 1);
			}
		}();
		return {
			customers: customers,
			date: $elm$core$Maybe$Just(date),
			datepicker: $CurrySoftware$elm_datepicker$DatePicker$initFromDate(date),
			debug: '',
			endDate: $elm$core$Maybe$Nothing,
			endPicker: $CurrySoftware$elm_datepicker$DatePicker$initFromDate(date),
			selectedApplication: $elm$core$Maybe$Nothing,
			selectedCustomer: initialCustomer,
			selectedDropdownValues: $elm$core$Dict$empty,
			selectedService: $elm$core$Maybe$Nothing,
			services: $author$project$Common$Types$NotAsked,
			session: session,
			startDate: $elm$core$Maybe$Nothing,
			startPicker: $CurrySoftware$elm_datepicker$DatePicker$initFromDate(date)
		};
	});
var $author$project$Main$initSession = function (model) {
	var session = function () {
		var _v3 = _Utils_Tuple2(model.userContext, model.drawer.selectedCustomer);
		if ((_v3.a.$ === 'Just') && (_v3.b.$ === 'Just')) {
			var uc = _v3.a.a;
			var customer = _v3.b.a;
			return A5($author$project$Common$Session$init, uc.token, customer.customerKey, model.flags.cfgLoginType, model.isCustomerAdmin, model.flags.isMobile);
		} else {
			return A5($author$project$Common$Session$init, '', '', '', false, false);
		}
	}();
	var customers = function () {
		var _v2 = model.drawer.customers;
		if (_v2.$ === 'Success') {
			var c = _v2.a;
			return c;
		} else {
			return _List_Nil;
		}
	}();
	var brand = function () {
		var _v1 = model.brand;
		if (_v1.$ === 'Success') {
			var b = _v1.a;
			return b;
		} else {
			return $author$project$Common$Brand$init;
		}
	}();
	var _v0 = model.page;
	switch (_v0.$) {
		case 'Home':
			return _Utils_update(
				model,
				{
					homepage: A3($author$project$Pages$Home$init, session, model.brand, model.userLogin)
				});
		case 'HomeEdit':
			return _Utils_update(
				model,
				{
					homeeditpage: A3($author$project$Pages$HomeEdit$init, session, model.brand, model.userLogin)
				});
		case 'Roles':
			return _Utils_update(
				model,
				{
					rolepage: $author$project$Pages$RoleManagement$init(session)
				});
		case 'UserManagement':
			return _Utils_update(
				model,
				{
					userpage: A2($author$project$Pages$UserManagement$init, session, model.drawer.selectedCustomer)
				});
		case 'UserInfo':
			return _Utils_update(
				model,
				{
					userinfopage: $author$project$Pages$UserInfo$init(session)
				});
		case 'Branding':
			return _Utils_update(
				model,
				{
					brandingpage: A3($author$project$Pages$Branding$init, session, brand, model.flags.cfgLogoContainer)
				});
		case 'ReportSettings':
			return _Utils_update(
				model,
				{
					reaactpage: $author$project$Pages$ReportSettings$init(session)
				});
		case 'CustomerSubscription':
			return _Utils_update(
				model,
				{
					subspage: A3($author$project$Pages$Subscriptions$initWithDatepicker, session, customers, model.date)
				});
		case 'ApplicationRegistration':
			return _Utils_update(
				model,
				{
					appregpage: $author$project$Pages$AppRegistration$init(session)
				});
		case 'Customers':
			return _Utils_update(
				model,
				{
					customerspage: A2($author$project$Pages$Customers$init, session, model.drawer.customers)
				});
		case 'RolePermissions':
			return _Utils_update(
				model,
				{
					rppage: $author$project$Pages$RolePermissions$init(session)
				});
		case 'Document':
			return _Utils_update(
				model,
				{
					docpage: $author$project$Pages$Document$init(session)
				});
		default:
			return model;
	}
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Common$State$decodeState = F2(
	function (state, decoder) {
		return A2($elm$json$Json$Decode$decodeString, decoder, state.value);
	});
var $author$project$LocalStorage$updateDrawerOpen = F2(
	function (model, value) {
		var drawer = model.drawer;
		return _Utils_update(
			model,
			{
				drawer: _Utils_update(
					drawer,
					{open: value})
			});
	});
var $author$project$LocalStorage$mapModelState = F2(
	function (model, state) {
		var _v0 = state.key;
		if (_v0 === 'drawerOpen') {
			var _v1 = A2($author$project$Common$State$decodeState, state, $elm$json$Json$Decode$bool);
			if (_v1.$ === 'Ok') {
				var openState = _v1.a;
				return A2($author$project$LocalStorage$updateDrawerOpen, model, openState);
			} else {
				var err = _v1.a;
				return _Utils_update(
					model,
					{
						debug: $elm$core$Debug$toString(err)
					});
			}
		} else {
			return model;
		}
	});
var $author$project$LocalStorage$mapModelStates = F2(
	function (model, states) {
		mapModelStates:
		while (true) {
			if (states.b) {
				var state = states.a;
				var statesTail = states.b;
				var $temp$model = A2($author$project$LocalStorage$mapModelState, model, state),
					$temp$states = statesTail;
				model = $temp$model;
				states = $temp$states;
				continue mapModelStates;
			} else {
				return model;
			}
		}
	});
var $author$project$Common$Helpers$replace = F3(
	function (target, replacement, str) {
		return A2(
			$elm$core$String$join,
			replacement,
			A2($elm$core$String$split, target, str));
	});
var $author$project$Common$Helpers$myUrlEncode = function (inUrl) {
	return A3(
		$author$project$Common$Helpers$replace,
		' ',
		'%20',
		A3(
			$author$project$Common$Helpers$replace,
			'/',
			'%2F',
			A3($author$project$Common$Helpers$replace, ':', '%3A', inUrl)));
};
var $author$project$Messages$CheckEmbedTokenExpiration = function (a) {
	return {$: 'CheckEmbedTokenExpiration', a: a};
};
var $author$project$Messages$ReceiveAnnouncementServices = function (a) {
	return {$: 'ReceiveAnnouncementServices', a: a};
};
var $author$project$Main$getAnnouncementServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$customerSerivces(customerKey),
			$author$project$Messages$ReceiveAnnouncementServices,
			$author$project$API$Decoder$decodeCustomerService);
	});
var $author$project$Messages$ReceiveAnnouncementServicesEdit = function (a) {
	return {$: 'ReceiveAnnouncementServicesEdit', a: a};
};
var $author$project$Main$getAnnouncementServicesEdit = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$customerSerivces(customerKey),
			$author$project$Messages$ReceiveAnnouncementServicesEdit,
			$author$project$API$Decoder$decodeCustomerService);
	});
var $author$project$Messages$ReceiveAnnouncements = function (a) {
	return {$: 'ReceiveAnnouncements', a: a};
};
var $author$project$API$Queries$announcements = function (customerKey) {
	return '\r\n    query {\r\n        getannouncements(customerkey: \"' + (customerKey + '\") {\r\n            AnnouncementKey\r\n            AnnouncementServiceKey\r\n            AnnouncementSubject\r\n            AnnouncementBody\r\n            AnnouncementAttachmentUrl\r\n            AnnouncementCreatedBy\r\n            AnnouncementCreated\r\n            AnnouncementRolePermissionLevelLimit\r\n        }\r\n    }\r\n    ');
};
var $author$project$Common$Types$Announcement = F8(
	function (announcementKey, announcementServiceKey, announcementSubject, announcementBody, announcementAttachmentUrl, announcementCreatedBy, announcementCreated, announcementRolePermissionLevelLimit) {
		return {announcementAttachmentUrl: announcementAttachmentUrl, announcementBody: announcementBody, announcementCreated: announcementCreated, announcementCreatedBy: announcementCreatedBy, announcementKey: announcementKey, announcementRolePermissionLevelLimit: announcementRolePermissionLevelLimit, announcementServiceKey: announcementServiceKey, announcementSubject: announcementSubject};
	});
var $author$project$API$Decoder$decodeAnnouncement = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'AnnouncementRolePermissionLevelLimit',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'AnnouncementCreated',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'AnnouncementCreatedBy',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'AnnouncementAttachmentUrl',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'AnnouncementBody',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'AnnouncementSubject',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'AnnouncementServiceKey',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'AnnouncementKey',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Common$Types$Announcement)))))))));
var $author$project$API$Decoder$decodeAnnouncements = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'getannouncements']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeAnnouncement));
var $author$project$Main$getAnnouncements = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$announcements(customerKey),
			$author$project$Messages$ReceiveAnnouncements,
			$author$project$API$Decoder$decodeAnnouncements);
	});
var $author$project$Messages$ReceiveAnnouncementsEdit = function (a) {
	return {$: 'ReceiveAnnouncementsEdit', a: a};
};
var $author$project$Main$getAnnouncementsEdit = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$announcements(customerKey),
			$author$project$Messages$ReceiveAnnouncementsEdit,
			$author$project$API$Decoder$decodeAnnouncements);
	});
var $author$project$Messages$ReceiveContainer = function (a) {
	return {$: 'ReceiveContainer', a: a};
};
var $author$project$Common$Types$Folder = F3(
	function (folderKey, folderName, documents) {
		return {documents: documents, folderKey: folderKey, folderName: folderName};
	});
var $author$project$Common$Types$Document = F4(
	function (documentKey, documentName, description, uploader) {
		return {description: description, documentKey: documentKey, documentName: documentName, uploader: uploader};
	});
var $author$project$API$Decoder$decodeDocument = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'Uploader',
	$elm$json$Json$Decode$string,
	'-',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Description',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'DocumentName',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'DocumentKey',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Common$Types$Document)))));
var $author$project$API$Decoder$decodeFolder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'Documents',
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeDocument),
	_List_Nil,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'FolderName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'FolderKey',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Common$Types$Folder))));
var $author$project$API$Decoder$decodeFolders = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'Folders']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeFolder));
var $author$project$API$Queries$folders = F2(
	function (customerKey, tabKey) {
		return '\r\n    query {\r\n        Folders(CustomerKey: \"' + (customerKey + ('\", TabKey: \"' + (tabKey + '\") {\r\n            FolderKey\r\n            FolderName\r\n            Documents {\r\n                DocumentKey\r\n                DocumentName\r\n                Description\r\n                Uploader\r\n            }\r\n        }\r\n    }\r\n    ')));
	});
var $author$project$Main$getContainers = F4(
	function (token, loginType, customerKey, tabKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$folders, customerKey, tabKey),
			$author$project$Messages$ReceiveContainer,
			$author$project$API$Decoder$decodeFolders);
	});
var $author$project$Messages$ReceiveAllServices = function (a) {
	return {$: 'ReceiveAllServices', a: a};
};
var $author$project$API$Queries$queryPurchasedServices = function (customerKey) {
	return '\r\n    query {\r\n        PurchasedServices(CustomerKey: \"' + (customerKey + '\") {\r\n            ServiceKey\r\n            ServiceName\r\n            ServiceSortKey\r\n            Applications {\r\n                ApplicationKey\r\n                ApplicationName\r\n                ApplicationSortKey\r\n                ApplicationTypeKey\r\n                ApplicationIsDefault\r\n                ApplicationStartDateTime\r\n                ApplicationEndDateTime\r\n                ApplicationIsPaid\r\n                ApplicationTileBackgroundImageUrl\r\n                ApplicationTileBackgroundColorHEX\r\n                ApplicationTileOpacity\r\n                ApplicationTileTextColorHEX\r\n                Tabs {\r\n                    TabKey\r\n                    TabName\r\n                    TabSortKey\r\n                    TabPath\r\n                }\r\n            }\r\n        }\r\n    }\r\n    ');
};
var $author$project$Main$getPurchasedServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$queryPurchasedServices(customerKey),
			$author$project$Messages$ReceiveAllServices,
			$author$project$API$Decoder$decodeServices);
	});
var $author$project$Messages$ReceiveReaactCustomers = function (a) {
	return {$: 'ReceiveReaactCustomers', a: a};
};
var $author$project$Main$getReaactCustomers = F2(
	function (token, loginType) {
		return A5($author$project$API$API$requestGraphQL, token, loginType, $author$project$API$Queries$customers, $author$project$Messages$ReceiveReaactCustomers, $author$project$API$Decoder$decodeCustomers);
	});
var $author$project$Messages$ReceiveReportServices = function (a) {
	return {$: 'ReceiveReportServices', a: a};
};
var $author$project$API$Queries$reportServices = function (customerKey) {
	return '\r\n    query {\r\n        getreportservices(email: \"\", logintype: \"\", customerkey: \"' + (customerKey + '\") {\r\n            ServiceKey\r\n            ServiceName\r\n            ServiceSortKey\r\n            Applications {\r\n                ApplicationKey\r\n                ApplicationName\r\n                ApplicationSortKey\r\n                ApplicationTypeKey\r\n                ApplicationIsDefault\r\n                ApplicationStartDateTime\r\n                ApplicationEndDateTime\r\n                ApplicationIsPaid\r\n                ApplicationTileBackgroundImageUrl\r\n                ApplicationTileBackgroundColorHEX\r\n                ApplicationTileOpacity\r\n                ApplicationTileTextColorHEX\r\n                Tabs {\r\n                    TabKey\r\n                    TabName\r\n                    TabSortKey\r\n                    TabPath\r\n                }\r\n            }\r\n        }\r\n    }\r\n    ');
};
var $author$project$Main$getReportServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$reportServices(customerKey),
			$author$project$Messages$ReceiveReportServices,
			$author$project$API$Decoder$decodeServices);
	});
var $author$project$Messages$ReceiveRolePermissions = function (a) {
	return {$: 'ReceiveRolePermissions', a: a};
};
var $author$project$Common$Types$RolePermission = F5(
	function (roleKey, roleName, rolePermissionLevelKey, customerKey, services) {
		return {customerKey: customerKey, roleKey: roleKey, roleName: roleName, rolePermissionLevelKey: rolePermissionLevelKey, services: services};
	});
var $author$project$API$Decoder$decodeRolePermission = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
	_List_fromArray(
		['Services']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeService),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
		_List_fromArray(
			['CustomerKey']),
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
			_List_fromArray(
				['RolePermissionLevelKey']),
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['RoleName']),
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['RoleKey']),
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$Types$RolePermission))))));
var $author$project$API$Decoder$decodeRolePermissions = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'RolePermissions']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeRolePermission));
var $author$project$API$Queries$rolePermissions = function (customerKey) {
	return '\r\n        query {\r\n            RolePermissions(CustomerKey: \"' + (customerKey + '\") {\r\n                RoleKey\r\n                RoleName\r\n                RolePermissionLevelKey\r\n                CustomerKey\r\n                Services {\r\n                    ServiceKey\r\n                    ServiceName\r\n                    ServiceSortKey\r\n                    Applications {\r\n                        ApplicationKey\r\n                        ApplicationName\r\n                        ApplicationSortKey\r\n                        ApplicationTypeKey\r\n                        ApplicationIsDefault\r\n                        ApplicationStartDateTime\r\n                        ApplicationEndDateTime\r\n                        ApplicationIsPaid\r\n                        ApplicationTileBackgroundImageUrl\r\n                        ApplicationTileBackgroundColorHEX\r\n                        ApplicationTileOpacity\r\n                        ApplicationTileTextColorHEX\r\n                        Tabs {\r\n                            TabKey\r\n                            TabName\r\n                            TabSortKey\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    ');
};
var $author$project$Main$getRolePermissions = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$rolePermissions(customerKey),
			$author$project$Messages$ReceiveRolePermissions,
			$author$project$API$Decoder$decodeRolePermissions);
	});
var $author$project$Messages$ReceiveRoles = function (a) {
	return {$: 'ReceiveRoles', a: a};
};
var $author$project$Common$Types$Role = F6(
	function (roleKey, roleName, customerRolePermissionLevelKey, customerRolePermissionLevelName, customerRolePermissionLevelDescription, customerRolePermissionLevel) {
		return {customerRolePermissionLevel: customerRolePermissionLevel, customerRolePermissionLevelDescription: customerRolePermissionLevelDescription, customerRolePermissionLevelKey: customerRolePermissionLevelKey, customerRolePermissionLevelName: customerRolePermissionLevelName, roleKey: roleKey, roleName: roleName};
	});
var $author$project$API$Decoder$decodeRole = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'CustomerRolePermissionLevel',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'CustomerRolePermissionLevelDescription',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'CustomerRolePermissionLevelName',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'CustomerRolePermissionLevelKey',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'RoleName',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'RoleKey',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Common$Types$Role)))))));
var $author$project$API$Decoder$decodeRoles = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'getcustomerroles']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeRole));
var $author$project$API$Queries$roles = function (customerKey) {
	return '\r\n        query {\r\n            getcustomerroles(email: \""\", logintype: \""\", customerkey: \"' + (customerKey + '\") {\r\n                RoleKey\r\n                RoleName\r\n                CustomerRolePermissionLevelKey\r\n                CustomerRolePermissionLevelName\r\n                CustomerRolePermissionLevelDescription\r\n                CustomerRolePermissionLevel\r\n            }\r\n        }\r\n    ');
};
var $author$project$Main$getRoles = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$roles(customerKey),
			$author$project$Messages$ReceiveRoles,
			$author$project$API$Decoder$decodeRoles);
	});
var $author$project$Messages$ReceiveUsers = function (a) {
	return {$: 'ReceiveUsers', a: a};
};
var $author$project$API$Decoder$decodeUser = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'Issuer',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'UserLastLogin',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'UserModifiedBy',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'UserModified',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'UserCreatedBy',
					$elm$json$Json$Decode$string,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'UserCreated',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'UserMobilePhone',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'UserWorkPhone',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'UserUserFullName',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'UserLoginType',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'UserEmail',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'UserKey',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'Roles',
													$elm$json$Json$Decode$list($author$project$API$Decoder$decodeRole),
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'CustomerKey',
														$elm$json$Json$Decode$string,
														$elm$json$Json$Decode$succeed($author$project$Common$Types$User)))))))))))))));
var $author$project$API$Decoder$decodeUsers = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'getusers']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeUser));
var $author$project$API$Queries$users = function (customerKey) {
	return '\r\n    query {\r\n        getusers(email: \"\", logintype: \"\", customerkey: \"' + (customerKey + '\") {\r\n            CustomerKey\r\n            Roles {\r\n                RoleKey\r\n                RoleName\r\n                CustomerRolePermissionLevelKey\r\n                CustomerRolePermissionLevelName\r\n                CustomerRolePermissionLevelDescription\r\n                CustomerRolePermissionLevel\r\n            }\r\n            UserKey\r\n            UserEmail\r\n            UserLoginType\r\n            UserUserFullName\r\n            UserWorkPhone\r\n            UserMobilePhone\r\n            UserCreated\r\n            UserCreatedBy\r\n            UserModified\r\n            UserModifiedBy\r\n            UserLastLogin\r\n        }\r\n    }\r\n    ');
};
var $author$project$Main$getUsers = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$users(customerKey),
			$author$project$Messages$ReceiveUsers,
			$author$project$API$Decoder$decodeUsers);
	});
var $author$project$Pages$PowerBI$isReportLoaded = F2(
	function (tabKey, model) {
		var _v0 = A2($elm$core$Dict$get, tabKey, model.reports);
		if ((_v0.$ === 'Just') && (_v0.a.$ === 'Ready')) {
			var _v1 = _v0.a;
			var report = _v1.a;
			var token = _v1.b;
			return true;
		} else {
			return false;
		}
	});
var $author$project$Main$pageInit = function (model) {
	var token = function () {
		var _v2 = model.userContext;
		if (_v2.$ === 'Just') {
			var uc = _v2.a;
			return uc.token;
		} else {
			return '';
		}
	}();
	var selectedCustomerKey = function () {
		var _v1 = model.drawer.selectedCustomer;
		if (_v1.$ === 'Just') {
			var customer = _v1.a;
			return customer.customerKey;
		} else {
			return '';
		}
	}();
	var _v0 = model.page;
	_v0$9:
	while (true) {
		switch (_v0.$) {
			case 'Home':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getAnnouncements, token, model.flags.cfgLoginType, selectedCustomerKey),
							A3($author$project$Main$getAnnouncementServices, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'HomeEdit':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getAnnouncementsEdit, token, model.flags.cfgLoginType, selectedCustomerKey),
							A3($author$project$Main$getAnnouncementServicesEdit, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'Roles':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getUsers, token, model.flags.cfgLoginType, selectedCustomerKey),
							A3($author$project$Main$getRoles, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'UserManagement':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getUsers, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'UserInfo':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getUsers, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'Reaact':
				if ((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) {
					var tk = _v0.a.a;
					var ck = _v0.b.a;
					return A2($author$project$Pages$PowerBI$isReportLoaded, tk, model.powerbipage) ? A2($elm$core$Task$perform, $author$project$Messages$CheckEmbedTokenExpiration, $elm$time$Time$now) : $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A4($author$project$Main$getReport, token, model.flags.cfgLoginType, ck, tk)
							]));
				} else {
					break _v0$9;
				}
			case 'ReportSettings':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getReportServices, token, model.flags.cfgLoginType, selectedCustomerKey),
							A2($author$project$Main$getReaactCustomers, token, model.flags.cfgLoginType)
						]));
			case 'RolePermissions':
				return $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3($author$project$Main$getRolePermissions, token, model.flags.cfgLoginType, selectedCustomerKey),
							A3($author$project$Main$getPurchasedServices, token, model.flags.cfgLoginType, selectedCustomerKey)
						]));
			case 'Document':
				if (_v0.a.$ === 'Just') {
					var tk = _v0.a.a;
					return $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A4($author$project$Main$getContainers, token, model.flags.cfgLoginType, selectedCustomerKey, tk)
							]));
				} else {
					break _v0$9;
				}
			default:
				break _v0$9;
		}
	}
	return $elm$core$Platform$Cmd$none;
};
var $author$project$Ports$PlannicaGridPorts$plannicaGridApplicationClick = _Platform_outgoingPort('plannicaGridApplicationClick', $elm$json$Json$Encode$string);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Main$queryUserLogin = '\r\n    query {\r\n        UserLogin {\r\n            UserKey\r\n            UserFullName\r\n            UserEmail\r\n            DefaultCustomerKey\r\n            CustomerDomain\r\n        }\r\n    }\r\n    ';
var $author$project$Common$Requests$requestGraphQL = F5(
	function (token, loginType, query, msg, decoder) {
		return $elm$http$Http$request(
			{
				body: A2(
					$elm$http$Http$stringBody,
					'application/json',
					A2(
						$elm$json$Json$Encode$encode,
						0,
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(query))
								])))),
				expect: A2($elm$http$Http$expectJson, msg, decoder),
				headers: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
						A2($elm$http$Http$header, 'LoginType', loginType)
					]),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: '/graphql'
			});
	});
var $author$project$Ports$Functions$setFavicon = _Platform_outgoingPort('setFavicon', $elm$json$Json$Encode$string);
var $author$project$Ports$LocalStorage$setState = _Platform_outgoingPort(
	'setState',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string($.key)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string($.value))
				]));
	});
var $author$project$Config$statesToTrack = _List_fromArray(
	['drawerOpen']);
var $author$project$Drawer$ToggleDrawerClickPort = {$: 'ToggleDrawerClickPort'};
var $author$project$Ports$PlannicaGridPorts$plannicaGridToggleDrawerClick = _Platform_outgoingPort('plannicaGridToggleDrawerClick', $elm$json$Json$Encode$bool);
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Drawer$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'Toggle':
				var boolString = A2(
					$elm$json$Json$Encode$encode,
					0,
					$elm$json$Json$Encode$bool(!model.open));
				return A3(
					$author$project$Common$Helpers$andThen,
					$author$project$Drawer$update,
					$author$project$Drawer$ToggleDrawerClickPort,
					_Utils_Tuple2(
						_Utils_update(
							model,
							{open: !model.open}),
						$author$project$Ports$LocalStorage$setState(
							A2($author$project$Common$State$State, 'drawerOpen', boolString))));
			case 'ToggleClose':
				var setClosed = msg.a;
				var boolString = A2(
					$elm$json$Json$Encode$encode,
					0,
					$elm$json$Json$Encode$bool(!setClosed));
				return A3(
					$author$project$Common$Helpers$andThen,
					$author$project$Drawer$update,
					$author$project$Drawer$ToggleDrawerClickPort,
					_Utils_Tuple2(
						_Utils_update(
							model,
							{open: !setClosed}),
						$author$project$Ports$LocalStorage$setState(
							A2($author$project$Common$State$State, 'drawerOpen', boolString))));
			case 'SetCustomerService':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var customerServices = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								customerService: $author$project$Common$Types$Success(customerServices)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								customerService: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SetCustomers':
				var initialCustomer = msg.a;
				var res = msg.b;
				var selectedCustomer = function () {
					if (res.$ === 'Ok') {
						var customers = res.a;
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (c) {
									return _Utils_eq(c.customerKey, initialCustomer);
								},
								customers));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (res.$ === 'Ok') {
					var c = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								customers: $author$project$Common$Types$Success(
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.customerName;
										},
										c)),
								selectedCustomer: selectedCustomer
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								customers: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RequestCustomerService':
				var customerKey = msg.a;
				var selectedCustomer = function () {
					var _v4 = model.customers;
					if (_v4.$ === 'Success') {
						var customers = _v4.a;
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (c) {
									return _Utils_eq(c.customerKey, customerKey);
								},
								customers));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedCustomer: selectedCustomer}),
					$elm$core$Platform$Cmd$none);
			case 'SelectApplication':
				var application = msg.a;
				var updatedModel = model.isMobile ? _Utils_update(
					model,
					{open: false}) : model;
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{
							selectedApplication: $elm$core$Maybe$Just(application)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$PlannicaGridPorts$plannicaGridToggleDrawerClick(model.open));
		}
	});
var $author$project$Ports$AzureAD$logoutAzureAD = _Platform_outgoingPort(
	'logoutAzureAD',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Header$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'ToggleAccount':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{accountOpen: !model.accountOpen}),
					$elm$core$Platform$Cmd$none);
			case 'ToggleDrawer':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$AzureAD$logoutAzureAD(_Utils_Tuple0));
		}
	});
var $author$project$Ports$Language$getDefaultLang = _Platform_outgoingPort(
	'getDefaultLang',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Language$Language$update = F2(
	function (msg, model) {
		if (msg.$ === 'RequestLang') {
			return _Utils_Tuple2(
				model,
				$author$project$Ports$Language$getDefaultLang(_Utils_Tuple0));
		} else {
			var newModel = msg.a;
			return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Ports$PowerBI$fullscreen = _Platform_outgoingPort(
	'fullscreen',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$PowerBI$print = _Platform_outgoingPort(
	'print',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Navbar$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ToggleReportFullscreen':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$PowerBI$fullscreen(_Utils_Tuple0));
			case 'PrintReport':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$PowerBI$print(_Utils_Tuple0));
			default:
				var tabs = msg.a;
				var selectedTab = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedTab: selectedTab, tabs: tabs}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Elements$ColorPicker$BeingDragged = {$: 'BeingDragged'};
var $author$project$Common$Types$Selected = {$: 'Selected'};
var $author$project$Pages$Branding$ReceiveLogos = function (a) {
	return {$: 'ReceiveLogos', a: a};
};
var $author$project$Common$Types$UploadedFile = function (fileUrl) {
	return {fileUrl: fileUrl};
};
var $author$project$API$Decoder$decodeUploadedFile = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'fileUrl',
	$elm$json$Json$Decode$string,
	'',
	$elm$json$Json$Decode$succeed($author$project$Common$Types$UploadedFile));
var $author$project$API$Decoder$decodeUploadedFiles = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeUploadedFile));
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $author$project$API$API$postEmpty = F6(
	function (url_, token, loginType, msg, decoder, customerKey) {
		return $elm$http$Http$request(
			{
				body: $elm$http$Http$emptyBody,
				expect: A2($elm$http$Http$expectJson, msg, decoder),
				headers: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
						A2($elm$http$Http$header, 'CustomerKey', customerKey),
						A2($elm$http$Http$header, 'LoginType', loginType)
					]),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: url_
			});
	});
var $author$project$Pages$Branding$getLogos = F3(
	function (token, customerKey, loginType) {
		return A6($author$project$API$API$postEmpty, '/logos', token, loginType, $author$project$Pages$Branding$ReceiveLogos, $author$project$API$Decoder$decodeUploadedFiles, customerKey);
	});
var $author$project$Common$Color$isHslValid = function (hsl) {
	var saturationIsValid = (hsl.saturation >= 0) && (hsl.saturation <= 100);
	var luminanceIsValid = (hsl.luminance >= 0) && (hsl.luminance <= 100);
	var hueIsValid = (hsl.hue >= 0) && (hsl.hue <= 360);
	return hueIsValid && (saturationIsValid && luminanceIsValid);
};
var $author$project$Common$Color$isRgbValid = function (rgb) {
	var r = (rgb.red >= 0) && (rgb.red <= 255);
	var g = (rgb.green >= 0) && (rgb.green <= 255);
	var b = (rgb.blue >= 0) && (rgb.blue <= 255);
	return r && (g && b);
};
var $author$project$Common$Color$isColorValid = function (color) {
	return $author$project$Common$Color$isRgbValid(color.rgb) && ($author$project$Common$Color$isHexValid(color.hex) && $author$project$Common$Color$isHslValid(color.hsl));
};
var $elm$file$File$mime = _File_mime;
var $author$project$Elements$ColorPicker$positionToSaturationAndLuminance = function (position) {
	var satCalc = $elm$core$Basics$round((position.offsetX / position.offsetWidth) * 100);
	var saturation = (satCalc > 100) ? 100 : ((satCalc < 0) ? 0 : satCalc);
	var lumCalc = $elm$core$Basics$round((1 - (position.offsetY / position.offsetHeight)) * 100);
	var luminance = (lumCalc > 100) ? 100 : ((lumCalc < 0) ? 0 : lumCalc);
	return _Utils_Tuple2(saturation, luminance);
};
var $author$project$Common$Color$convertHslToRgb = function (hsl) {
	var _v0 = _Utils_Tuple3(hsl.hue, hsl.saturation / 100, hsl.luminance / 100);
	var hue = _v0.a;
	var sat = _v0.b;
	var lum = _v0.c;
	var newHue = hue / 360;
	var _v1 = _Utils_Tuple3(newHue + 0.33333, newHue, newHue - 0.33333);
	var tr = _v1.a;
	var tg = _v1.b;
	var tb = _v1.c;
	var b = (tb < 0) ? (tb + 1) : ((tb > 1) ? (tb - 1) : tb);
	var g = (tg < 0) ? (tg + 1) : ((tg > 1) ? (tg - 1) : tg);
	var r = (tr < 0) ? (tr + 1) : ((tr > 1) ? (tr - 1) : tr);
	var temp1 = (lum < 0.5) ? (lum * (1 + sat)) : ((lum + sat) - (lum * sat));
	var temp2 = (2 * lum) - temp1;
	var test = function (c) {
		return ((6 * c) < 1) ? (temp2 + (((temp1 - temp2) * 6) * c)) : (((2 * c) < 1) ? temp1 : (((3 * c) < 2) ? (temp2 + (((temp1 - temp2) * (0.66666 - c)) * 6)) : temp2));
	};
	var _v2 = _Utils_Tuple3(
		$elm$core$Basics$round(
			test(r) * 255),
		$elm$core$Basics$round(
			test(g) * 255),
		$elm$core$Basics$round(
			test(b) * 255));
	var red = _v2.a;
	var green = _v2.b;
	var blue = _v2.c;
	return A3($author$project$Common$Color$Rgb, red, green, blue);
};
var $author$project$Common$Color$calculateBase16 = function (value) {
	var result = $elm$core$Basics$floor(value / 16);
	var remainderBase10 = ((value / 16) - result) * 16;
	var remainderBase16 = function () {
		var _v1 = $elm$core$Basics$round(remainderBase10);
		switch (_v1) {
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var _int = _v1;
				return $elm$core$String$fromInt(_int);
		}
	}();
	if (!value) {
		return remainderBase16;
	} else {
		return _Utils_ap(
			$author$project$Common$Color$calculateBase16(result),
			remainderBase16);
	}
};
var $author$project$Common$Color$convertRgbToHex = function (rgb) {
	var formatHexadecimal = function (v) {
		return ($elm$core$String$length(v) > 2) ? A2($elm$core$String$dropLeft, 1, v) : (($elm$core$String$length(v) < 2) ? ('0' + v) : v);
	};
	var g = formatHexadecimal(
		$author$project$Common$Color$calculateBase16(rgb.green));
	var r = formatHexadecimal(
		$author$project$Common$Color$calculateBase16(rgb.red));
	var b = formatHexadecimal(
		$author$project$Common$Color$calculateBase16(rgb.blue));
	return '#' + (r + (g + b));
};
var $author$project$Common$Color$hslToColor = function (hsl) {
	var rgb = $author$project$Common$Color$convertHslToRgb(hsl);
	var hex = $author$project$Common$Color$convertRgbToHex(rgb);
	return $author$project$Common$Color$isHslValid(hsl) ? $elm$core$Maybe$Just(
		A3($author$project$Common$Color$Color, rgb, hex, hsl)) : $elm$core$Maybe$Nothing;
};
var $author$project$Common$Color$setHslValue = F3(
	function (color, hslInput, value) {
		var hsl = color.hsl;
		var updatedHsl = function () {
			switch (hslInput.$) {
				case 'Hue':
					return _Utils_update(
						hsl,
						{hue: value});
				case 'Saturation':
					return _Utils_update(
						hsl,
						{saturation: value});
				default:
					return _Utils_update(
						hsl,
						{luminance: value});
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			color,
			$author$project$Common$Color$hslToColor(updatedHsl));
	});
var $author$project$Common$Color$setHue = F2(
	function (color, hue) {
		var hsl = color.hsl;
		var newColor = _Utils_update(
			color,
			{
				hsl: _Utils_update(
					hsl,
					{hue: hue})
			});
		return A2(
			$elm$core$Maybe$withDefault,
			color,
			$author$project$Common$Color$hslToColor(newColor.hsl));
	});
var $author$project$Common$Color$rgbToColor = function (rgb) {
	var hsl = $author$project$Common$Color$convertRgbToHsl(rgb);
	var hex = $author$project$Common$Color$convertRgbToHex(rgb);
	return $author$project$Common$Color$isRgbValid(rgb) ? $elm$core$Maybe$Just(
		A3($author$project$Common$Color$Color, rgb, hex, hsl)) : $elm$core$Maybe$Nothing;
};
var $author$project$Common$Color$setRgbValue = F3(
	function (color, rgbInput, value) {
		var rgb = color.rgb;
		var updatedRgb = function () {
			switch (rgbInput.$) {
				case 'Red':
					return _Utils_update(
						rgb,
						{red: value});
				case 'Green':
					return _Utils_update(
						rgb,
						{green: value});
				default:
					return _Utils_update(
						rgb,
						{blue: value});
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			color,
			$author$project$Common$Color$rgbToColor(updatedRgb));
	});
var $author$project$Common$Color$setSatAndLum = F3(
	function (color, sat, lum) {
		var hsl = color.hsl;
		var newColor = _Utils_update(
			color,
			{
				hsl: _Utils_update(
					hsl,
					{luminance: lum, saturation: sat})
			});
		return A2(
			$elm$core$Maybe$withDefault,
			color,
			$author$project$Common$Color$hslToColor(newColor.hsl));
	});
var $author$project$Pages$Branding$ReceiveUpdate = function (a) {
	return {$: 'ReceiveUpdate', a: a};
};
var $author$project$API$Decoder$decodeMutationResponse = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'AddCustomerPurchase', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'adduserrole', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'addnewuser', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'UpdateCustomerPurchase', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'AddRolePermission', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'RemoveRolePermission', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'AddCustomer', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'RemoveCustomer', 'Error']),
			$elm$json$Json$Decode$string),
			A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['data', 'CreateService', 'Error']),
			$elm$json$Json$Decode$string)
		]));
var $author$project$API$Queries$updateBrand = F2(
	function (customerKey, brand) {
		var stih = brand.showTitleInHeader ? '1' : '0';
		var stia = brand.showTitleInAnnouncements ? '1' : '0';
		var slih = brand.showLogoInHeader ? '1' : '0';
		var slia = brand.showLogoInAnnouncements ? '1' : '0';
		var boolToInt = function (b) {
			return b ? '1' : '0';
		};
		return '\r\n        mutation{\r\n            updateCustomerBrand(input:\r\n                {\r\n                    CustomerKey: \"' + (customerKey + ('\",\r\n                    Title: \"' + (brand.title + ('\",\r\n                    LogoRelativeUrl: \"' + (brand.image + ('\",\r\n                    PrimaryColorHEX: \"' + (brand.primary.hex + ('\",\r\n                    AccentColorHEX: \"' + (brand.secondary.hex + ('\",\r\n                    CustomCSSRelativeUrl: \"' + ('' + ('\",\r\n                    FaviconUrl: \"' + (brand.faviconUrl + ('\",\r\n                    ShowTitleInHeader: ' + (stih + (',\r\n                    ShowLogoInHeader: ' + (slih + (',\r\n                    ShowTitleInAnnouncements: ' + (stia + (',\r\n                    ShowLogoInAnnouncements: ' + (slia + (',\r\n                    UseColorInHeaderAndNav: ' + (boolToInt(brand.useColorInHeaderAndNav) + '\r\n                }\r\n            ) {\r\n                Error\r\n            }\r\n        }\r\n        ')))))))))))))))))))))));
	});
var $author$project$Pages$Branding$updateBrand = F4(
	function (token, customerKey, loginType, brand) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$updateBrand, customerKey, brand),
			$author$project$Pages$Branding$ReceiveUpdate,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$Branding$updateSelectedBrand = F3(
	function (brand, selection, color) {
		if (selection.$ === 'Primary') {
			return _Utils_update(
				brand,
				{primary: color});
		} else {
			return _Utils_update(
				brand,
				{secondary: color});
		}
	});
var $author$project$Pages$Branding$ReceiveUploadResult = function (a) {
	return {$: 'ReceiveUploadResult', a: a};
};
var $elm$http$Http$filePart = _Http_pair;
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $author$project$API$API$postFile = F7(
	function (url_, token, loginType, msg, decoder, customerKey, file) {
		return $elm$http$Http$request(
			{
				body: $elm$http$Http$multipartBody(
					_List_fromArray(
						[
							A2($elm$http$Http$filePart, 'file[]', file)
						])),
				expect: A2($elm$http$Http$expectJson, msg, decoder),
				headers: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
						A2($elm$http$Http$header, 'CustomerKey', customerKey),
						A2($elm$http$Http$header, 'LoginType', loginType)
					]),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: url_
			});
	});
var $author$project$Pages$Branding$uploadFile = F4(
	function (token, customerKey, loginType, file) {
		return A7($author$project$API$API$postFile, '/file/upload', token, loginType, $author$project$Pages$Branding$ReceiveUploadResult, $author$project$API$Decoder$decodeUploadedFile, customerKey, file);
	});
var $author$project$Pages$Branding$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'UpdateBrandTitle':
				var text = msg.a;
				var brand_ = model.brand;
				var updatedBrand = _Utils_update(
					brand_,
					{title: text});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{brand: updatedBrand}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateFaviconUrl':
				var text = msg.a;
				var brand = model.brand;
				var updatedBrand = _Utils_update(
					brand,
					{faviconUrl: text});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{brand: updatedBrand}),
					$elm$core$Platform$Cmd$none);
			case 'RequestSaveBrand':
				return ($author$project$Common$Color$isColorValid(model.brand.primary) && $author$project$Common$Color$isColorValid(model.brand.secondary)) ? _Utils_Tuple2(
					model,
					A4($author$project$Pages$Branding$updateBrand, model.session.token, model.session.customerKey, model.session.loginType, model.brand)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'KeyRgb':
				var rgbInput = msg.a;
				var key = msg.b;
				var color = function () {
					var _v4 = model.selection;
					if (_v4.$ === 'Primary') {
						return model.color;
					} else {
						return model.color2;
					}
				}();
				var targetRgbColor = function () {
					switch (rgbInput.$) {
						case 'Red':
							return color.rgb.red;
						case 'Green':
							return color.rgb.green;
						default:
							return color.rgb.blue;
					}
				}();
				var updatedColor = function () {
					switch (key) {
						case 38:
							return A3($author$project$Common$Color$setRgbValue, color, rgbInput, targetRgbColor + 1);
						case 40:
							return A3($author$project$Common$Color$setRgbValue, color, rgbInput, targetRgbColor - 1);
						default:
							return color;
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v1 = model.selection;
				if (_v1.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: updatedColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: updatedColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'KeyHsl':
				var hslInput = msg.a;
				var key = msg.b;
				var color = function () {
					var _v8 = model.selection;
					if (_v8.$ === 'Primary') {
						return model.color;
					} else {
						return model.color2;
					}
				}();
				var targetHslColor = function () {
					switch (hslInput.$) {
						case 'Hue':
							return color.hsl.hue;
						case 'Saturation':
							return color.hsl.saturation;
						default:
							return color.hsl.luminance;
					}
				}();
				var updatedColor = function () {
					switch (key) {
						case 38:
							return A3($author$project$Common$Color$setHslValue, color, hslInput, targetHslColor + 1);
						case 40:
							return A3($author$project$Common$Color$setHslValue, color, hslInput, targetHslColor - 1);
						default:
							return color;
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v5 = model.selection;
				if (_v5.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: updatedColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: updatedColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SelectLogo':
				var url = msg.a;
				var brand_ = model.brand;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							brand: _Utils_update(
								brand_,
								{image: url})
						}),
					$elm$core$Platform$Cmd$none);
			case 'Apply':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ReceiveUpdate':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'RequestLogos':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{logos: $author$project$Common$Types$Loading}),
					A3($author$project$Pages$Branding$getLogos, model.session.token, model.session.customerKey, model.session.loginType));
			case 'ReceiveLogos':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var logos = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								logos: $author$project$Common$Types$Success(logos)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								logos: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'UploadToStorageAccount':
				var files = msg.a;
				var acceptedMimeTypes = _List_fromArray(
					['image/png', 'image/jpeg']);
				if (!files.b) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var f = files.a;
					return A2(
						$elm$core$List$member,
						$elm$file$File$mime(f),
						acceptedMimeTypes) ? _Utils_Tuple2(
						model,
						A4($author$project$Pages$Branding$uploadFile, model.session.token, model.session.customerKey, model.session.loginType, f)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveUploadResult':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var val = result.a;
					var _v12 = model.brand;
					var brand_ = _v12;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								brand: _Utils_update(
									brand_,
									{image: val.fileUrl})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SliderOnInput':
				var value = msg.a;
				var updatedColor = function () {
					var _v14 = model.selection;
					if (_v14.$ === 'Primary') {
						return A2($author$project$Common$Color$setHue, model.color, value);
					} else {
						return A2($author$project$Common$Color$setHue, model.color2, value);
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v13 = model.selection;
				if (_v13.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: updatedColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: updatedColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'CursorDown':
				var position = msg.a;
				var settings = model.colorpickerSettings;
				var cursor = settings.cursor;
				var updatedCursor = _Utils_update(
					cursor,
					{position: position, state: $author$project$Elements$ColorPicker$BeingDragged});
				var updatedSettings = _Utils_update(
					settings,
					{cursor: updatedCursor});
				var _v15 = $author$project$Elements$ColorPicker$positionToSaturationAndLuminance(position);
				var saturation = _v15.a;
				var luminance = _v15.b;
				var updatedColor = function () {
					var _v17 = model.selection;
					if (_v17.$ === 'Primary') {
						return A3($author$project$Common$Color$setSatAndLum, model.color, saturation, luminance);
					} else {
						return A3($author$project$Common$Color$setSatAndLum, model.color2, saturation, luminance);
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v16 = model.selection;
				if (_v16.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: updatedColor, colorpickerSettings: updatedSettings}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: updatedColor, colorpickerSettings: updatedSettings}),
						$elm$core$Platform$Cmd$none);
				}
			case 'CursorUp':
				var position = msg.a;
				var settings = model.colorpickerSettings;
				var cursor = settings.cursor;
				var _v18 = $author$project$Elements$ColorPicker$positionToSaturationAndLuminance(position);
				var saturation = _v18.a;
				var luminance = _v18.b;
				var updatedColor = function () {
					var _v20 = model.selection;
					if (_v20.$ === 'Primary') {
						return A3($author$project$Common$Color$setSatAndLum, model.color, saturation, luminance);
					} else {
						return A3($author$project$Common$Color$setSatAndLum, model.color2, saturation, luminance);
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v19 = model.selection;
				if (_v19.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								brand: updatedBrand,
								color: updatedColor,
								colorpickerSettings: _Utils_update(
									settings,
									{
										cursor: _Utils_update(
											cursor,
											{position: position, state: $author$project$Elements$ColorPicker$NotBeingDragged})
									})
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								brand: updatedBrand,
								color2: updatedColor,
								colorpickerSettings: _Utils_update(
									settings,
									{
										cursor: _Utils_update(
											cursor,
											{position: position, state: $author$project$Elements$ColorPicker$NotBeingDragged})
									})
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'CursorMove':
				var position = msg.a;
				var settings = model.colorpickerSettings;
				var cursor = settings.cursor;
				var updatedCursor = _Utils_update(
					cursor,
					{position: position});
				var _v21 = $author$project$Elements$ColorPicker$positionToSaturationAndLuminance(position);
				var saturation = _v21.a;
				var luminance = _v21.b;
				var updatedColor = function () {
					var _v24 = model.selection;
					if (_v24.$ === 'Primary') {
						return A3($author$project$Common$Color$setSatAndLum, model.color, saturation, luminance);
					} else {
						return A3($author$project$Common$Color$setSatAndLum, model.color2, saturation, luminance);
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v22 = cursor.state;
				if (_v22.$ === 'NotBeingDragged') {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var _v23 = model.selection;
					if (_v23.$ === 'Primary') {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									brand: updatedBrand,
									color: updatedColor,
									colorpickerSettings: _Utils_update(
										settings,
										{cursor: updatedCursor})
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									brand: updatedBrand,
									color2: updatedColor,
									colorpickerSettings: _Utils_update(
										settings,
										{cursor: updatedCursor})
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 'CursorLeave':
				var position = msg.a;
				var settings = model.colorpickerSettings;
				var cursor = settings.cursor;
				var updatedCursor = _Utils_update(
					cursor,
					{position: position, state: $author$project$Elements$ColorPicker$NotBeingDragged});
				var _v25 = $author$project$Elements$ColorPicker$positionToSaturationAndLuminance(position);
				var saturation = _v25.a;
				var luminance = _v25.b;
				var updatedColor = function () {
					var _v27 = model.selection;
					if (_v27.$ === 'Primary') {
						return A3($author$project$Common$Color$setSatAndLum, model.color, saturation, luminance);
					} else {
						return A3($author$project$Common$Color$setSatAndLum, model.color2, saturation, luminance);
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, updatedColor);
				var _v26 = cursor.state;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'RgbInput':
				var rgbType = msg.a;
				var value = msg.b;
				var newColor = function () {
					var _v29 = model.selection;
					if (_v29.$ === 'Primary') {
						return A3(
							$author$project$Common$Color$setRgbValue,
							model.color,
							rgbType,
							A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(value)));
					} else {
						return A3(
							$author$project$Common$Color$setRgbValue,
							model.color2,
							rgbType,
							A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(value)));
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, newColor);
				var _v28 = model.selection;
				if (_v28.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: newColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: newColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'HslInput':
				var hslType = msg.a;
				var value = msg.b;
				var newColor = function () {
					var _v31 = model.selection;
					if (_v31.$ === 'Primary') {
						return A3(
							$author$project$Common$Color$setHslValue,
							model.color,
							hslType,
							A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(value)));
					} else {
						return A3(
							$author$project$Common$Color$setHslValue,
							model.color2,
							hslType,
							A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(value)));
					}
				}();
				var updatedBrand = A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, newColor);
				var _v30 = model.selection;
				if (_v30.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: newColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: newColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'HexInput':
				var value = msg.a;
				var newColor = function () {
					var _v33 = model.selection;
					if (_v33.$ === 'Primary') {
						return A2(
							$elm$core$Maybe$withDefault,
							model.color,
							A2($author$project$Common$Color$hexToColor, value, model.color));
					} else {
						return A2(
							$elm$core$Maybe$withDefault,
							model.color2,
							A2($author$project$Common$Color$hexToColor, value, model.color2));
					}
				}();
				var updatedBrand = $author$project$Common$Color$isHexValid(value) ? A3($author$project$Pages$Branding$updateSelectedBrand, model.brand, model.selection, newColor) : model.brand;
				var _v32 = model.selection;
				if (_v32.$ === 'Primary') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color: newColor}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: updatedBrand, color2: newColor}),
						$elm$core$Platform$Cmd$none);
				}
			case 'Select':
				var selection = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mobileView: $author$project$Common$Types$Selected, selection: selection}),
					$elm$core$Platform$Cmd$none);
			case 'Check':
				var id = msg.a;
				var oldValue = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2($elm$core$Dict$get, id, model.checked));
				var brand = model.brand;
				var updatedBrand = _Utils_eq(id, $author$project$Pages$Branding$showTitleInHeader) ? _Utils_update(
					brand,
					{showTitleInHeader: !oldValue}) : (_Utils_eq(id, $author$project$Pages$Branding$showLogoInHeader) ? _Utils_update(
					brand,
					{showLogoInHeader: !oldValue}) : (_Utils_eq(id, $author$project$Pages$Branding$showTitleInAnnouncements) ? _Utils_update(
					brand,
					{showTitleInAnnouncements: !oldValue}) : (_Utils_eq(id, $author$project$Pages$Branding$showLogoInAnnouncements) ? _Utils_update(
					brand,
					{showLogoInAnnouncements: !oldValue}) : (_Utils_eq(id, $author$project$Pages$Branding$useColorInHeaderAndNav) ? _Utils_update(
					brand,
					{useColorInHeaderAndNav: !oldValue}) : brand))));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							brand: updatedBrand,
							checked: A3($elm$core$Dict$insert, id, !oldValue, model.checked)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var _v34 = model.mobileView;
				if (_v34.$ === 'Selected') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Browse}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Selected}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$Customers$Create = {$: 'Create'};
var $author$project$Elements$Table$Desc = function (a) {
	return {$: 'Desc', a: a};
};
var $author$project$Pages$Customers$ReceiveAddCustomerMessage = F2(
	function (a, b) {
		return {$: 'ReceiveAddCustomerMessage', a: a, b: b};
	});
var $author$project$API$Queries$addCustomer = F3(
	function (customerName, customerDomain, defaultIssuer) {
		return '\r\n    mutation {\r\n        AddCustomer(CustomerName: \"' + (customerName + ('\", CustomerDomain: \"' + (customerDomain + ('\", DefaultIssuer: \"' + (defaultIssuer + '\") {Message}\r\n    }\r\n    ')))));
	});
var $elm$core$String$toUpper = _String_toUpper;
var $author$project$Common$Helpers$capitalizeFirstLetter = function (str) {
	var otherLetters = A2(
		$elm$core$String$right,
		$elm$core$String$length(str) - 1,
		str);
	var capitalFirstLetter = $elm$core$String$toUpper(
		A2($elm$core$String$left, 1, str));
	return _Utils_ap(capitalFirstLetter, otherLetters);
};
var $author$project$Common$Types$Response = function (message) {
	return {message: message};
};
var $author$project$API$Decoder$decodeGraphQLResponse = function (atString) {
	return A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['data', atString, 'Message']),
		$elm$json$Json$Decode$string,
		'',
		$elm$json$Json$Decode$succeed($author$project$Common$Types$Response));
};
var $author$project$Pages$Customers$addCustomer = F3(
	function (token, customer, loginType) {
		var capCustomer = _Utils_update(
			customer,
			{
				customerName: $author$project$Common$Helpers$capitalizeFirstLetter(customer.customerName)
			});
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$addCustomer, capCustomer.customerName, capCustomer.customerDomain, capCustomer.defaultIssuer),
			$author$project$Pages$Customers$ReceiveAddCustomerMessage(capCustomer),
			$author$project$API$Decoder$decodeGraphQLResponse('AddCustomer'));
	});
var $author$project$Pages$Customers$ReceiveCustomerAzureStorageContainer = function (a) {
	return {$: 'ReceiveCustomerAzureStorageContainer', a: a};
};
var $author$project$Common$Types$BasicResponse = F2(
	function (statusCode, error) {
		return {error: error, statusCode: statusCode};
	});
var $author$project$API$Decoder$decodeBasicResponse = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'error',
	$elm$json$Json$Decode$string,
	'',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'statusCode',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Common$Types$BasicResponse)));
var $author$project$API$API$postWithHeaders = F6(
	function (url_, token, loginType, expect, extraHeaders, bodyMsg) {
		return $elm$http$Http$request(
			{
				body: bodyMsg,
				expect: expect,
				headers: A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
							A2($elm$http$Http$header, 'LoginType', loginType),
							A2($elm$http$Http$header, 'Accept-Charset', 'utf-8')
						]),
					extraHeaders),
				method: 'POST',
				timeout: $elm$core$Maybe$Nothing,
				tracker: $elm$core$Maybe$Nothing,
				url: url_
			});
	});
var $author$project$Pages$Customers$createAzureStorageContainer = function (model) {
	var _v0 = model.addedCustomerResponse;
	if (_v0.$ === 'Success') {
		var message = _v0.a;
		return A6(
			$author$project$API$API$postWithHeaders,
			'/container/create',
			model.session.token,
			model.session.loginType,
			A2($elm$http$Http$expectJson, $author$project$Pages$Customers$ReceiveCustomerAzureStorageContainer, $author$project$API$Decoder$decodeBasicResponse),
			_List_fromArray(
				[
					A2($elm$http$Http$header, 'CustomerKey', message.customerKey)
				]),
			$elm$http$Http$emptyBody);
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Common$Types$SQLResponse = F5(
	function (operation, customerKey, status, rowCount, message) {
		return {customerKey: customerKey, message: message, operation: operation, rowCount: rowCount, status: status};
	});
var $author$project$API$Decoder$decodeSqlResponse = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'Message',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'RowCount',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'Status',
			$elm$json$Json$Decode$string,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'CustomerKey',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Operation',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$Types$SQLResponse))))));
var $author$project$Pages$Customers$ReceiveRemoveCustomerMessage = F2(
	function (a, b) {
		return {$: 'ReceiveRemoveCustomerMessage', a: a, b: b};
	});
var $author$project$Common$Types$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $author$project$Common$Types$SqlError = function (a) {
	return {$: 'SqlError', a: a};
};
var $author$project$API$Decoder$decodeSqlError = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['message']),
	$elm$json$Json$Decode$string);
var $author$project$API$Decoder$decodeApiResponse = function (atString) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['data', atString, 'Error']),
				$elm$json$Json$Decode$null(''),
				$elm$json$Json$Decode$succeed($author$project$Common$Types$Ok)),
				A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['errors']),
				$elm$json$Json$Decode$list($author$project$API$Decoder$decodeSqlError),
				$elm$json$Json$Decode$succeed($author$project$Common$Types$SqlError))
			]));
};
var $author$project$API$Queries$removeCustomer = function (customerKey) {
	return '\r\n    mutation {\r\n        RemoveCustomer(CustomerKey: \"' + (customerKey + '\") {Error}\r\n    }\r\n    ');
};
var $author$project$Pages$Customers$removeCustomer = F3(
	function (token, customer, loginType) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$removeCustomer(customer.customerKey),
			$author$project$Pages$Customers$ReceiveRemoveCustomerMessage(customer),
			$author$project$API$Decoder$decodeApiResponse('RemoveCustomer'));
	});
var $author$project$Pages$Customers$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SetCustomers':
				var customers = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{customers: customers}),
					$elm$core$Platform$Cmd$none);
			case 'SelectCustomer':
				var customer = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mode: $author$project$Pages$Customers$View, selectedCustomer: customer}),
					$elm$core$Platform$Cmd$none);
			case 'CreateNewCustomer':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mode: $author$project$Pages$Customers$Create, selectedCustomer: $author$project$Pages$Customers$emptyCustomer}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateCustomerName':
				var value = msg.a;
				var editedCustomer = model.selectedCustomer;
				var newEditedCustomer = _Utils_update(
					editedCustomer,
					{customerName: value});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedCustomer: newEditedCustomer}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateCustomerDomain':
				var value = msg.a;
				var editedCustomer = model.selectedCustomer;
				var newEditedCustomer = _Utils_update(
					editedCustomer,
					{customerDomain: value});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedCustomer: newEditedCustomer}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateCustomerDefaultIssuer':
				var value = msg.a;
				var editedCustomer = model.selectedCustomer;
				var newEditedCustomer = _Utils_update(
					editedCustomer,
					{defaultIssuer: value});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedCustomer: newEditedCustomer}),
					$elm$core$Platform$Cmd$none);
			case 'ToggleConfirmation':
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showConfirmation: state}),
					$elm$core$Platform$Cmd$none);
			case 'AddCustomer':
				var customer = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{addCustomerMessage: $author$project$Common$Types$Loading, mode: $author$project$Pages$Customers$View}),
					A3($author$project$Pages$Customers$addCustomer, model.session.token, customer, model.session.loginType));
			case 'ReceiveAddCustomerMessage':
				var customer = msg.a;
				var result = msg.b;
				var updatedCustomerList = function () {
					var _v3 = model.customers;
					if (_v3.$ === 'Success') {
						var customers = _v3.a;
						return $author$project$Common$Types$Success(
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.customerName;
								},
								A2($elm$core$List$cons, customer, customers)));
					} else {
						return model.customers;
					}
				}();
				if (result.$ === 'Ok') {
					var message = result.a;
					var decodedSqlResponse = A2($elm$json$Json$Decode$decodeString, $author$project$API$Decoder$decodeSqlResponse, message.message);
					var addedCustomerResponseResult = function () {
						if (decodedSqlResponse.$ === 'Ok') {
							var value = decodedSqlResponse.a;
							return $author$project$Common$Types$Success(value);
						} else {
							var err = decodedSqlResponse.a;
							return $author$project$Common$Types$Failed(err);
						}
					}();
					var updatedModel = $elm$core$String$isEmpty(message.message) ? _Utils_update(
						model,
						{addCustomerMessage: $author$project$Common$Types$NotAsked}) : _Utils_update(
						model,
						{
							addCustomerMessage: $author$project$Common$Types$Success(message),
							addedCustomerResponse: addedCustomerResponseResult,
							customers: updatedCustomerList
						});
					return _Utils_Tuple2(
						updatedModel,
						$author$project$Pages$Customers$createAzureStorageContainer(updatedModel));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								addCustomerMessage: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RemoveCustomer':
				var customer = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{removedCustomerMessage: $author$project$Common$Types$Loading}),
					$elm$core$String$isEmpty(customer.customerKey) ? $elm$core$Platform$Cmd$none : A3($author$project$Pages$Customers$removeCustomer, model.session.token, customer, model.session.loginType));
			case 'ReceiveRemoveCustomerMessage':
				var customer = msg.a;
				var result = msg.b;
				var updatedCustomerList = function () {
					var _v8 = model.customers;
					if (_v8.$ === 'Success') {
						var cs = _v8.a;
						return $author$project$Common$Types$Success(
							A2(
								$elm$core$List$filter,
								function (c) {
									return !_Utils_eq(c, customer);
								},
								cs));
					} else {
						return model.customers;
					}
				}();
				var selectedCustomer = function () {
					var _v7 = model.customers;
					if (_v7.$ === 'Success') {
						var cs = _v7.a;
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$Pages$Customers$emptyCustomer,
							$elm$core$List$head(cs));
					} else {
						return $author$project$Pages$Customers$emptyCustomer;
					}
				}();
				if (result.$ === 'Ok') {
					var response = result.a;
					_v5$2:
					while (true) {
						switch (response.$) {
							case 'Ok':
								var message = response.a;
								return $elm$core$String$isEmpty(message) ? _Utils_Tuple2(
									_Utils_update(
										model,
										{customers: updatedCustomerList, removedCustomerMessage: $author$project$Common$Types$NotAsked, selectedCustomer: selectedCustomer, showConfirmation: false}),
									$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
									_Utils_update(
										model,
										{
											removedCustomerMessage: $author$project$Common$Types$Success(message)
										}),
									$elm$core$Platform$Cmd$none);
							case 'SqlError':
								if (response.a.b) {
									var _v6 = response.a;
									var m = _v6.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												removedCustomerMessage: $author$project$Common$Types$Success(m)
											}),
										$elm$core$Platform$Cmd$none);
								} else {
									break _v5$2;
								}
							default:
								break _v5$2;
						}
					}
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								removedCustomerMessage: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'UpdateTableSort':
				var columnIndex = msg.a;
				var updatedTableConfig = model.tableSettings;
				var updatedSort = function () {
					var _v9 = updatedTableConfig.sort;
					switch (_v9.$) {
						case 'Asc':
							var index = _v9.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Desc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						case 'Desc':
							var index = _v9.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Asc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						default:
							return $author$project$Elements$Table$Asc(columnIndex);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							tableSettings: _Utils_update(
								updatedTableConfig,
								{sort: updatedSort})
						}),
					$elm$core$Platform$Cmd$none);
			case 'RequestCustomerAzureStorageContainer':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Document$FileSelected = function (a) {
	return {$: 'FileSelected', a: a};
};
var $author$project$Pages$Document$RequestBlobs = {$: 'RequestBlobs'};
var $author$project$Pages$Document$ReceiveDeleteResponse = function (a) {
	return {$: 'ReceiveDeleteResponse', a: a};
};
var $author$project$Pages$Document$encodeDocument = function (blobDoc) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'documentkey',
				$elm$json$Json$Encode$string(blobDoc.document.documentKey)),
				_Utils_Tuple2(
				'documentname',
				$elm$json$Json$Encode$string(blobDoc.blob.name))
			]));
};
var $author$project$Pages$Document$encodedFolder = function (folder) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'folderkey',
				$elm$json$Json$Encode$string(folder.folderKey)),
				_Utils_Tuple2(
				'foldername',
				$elm$json$Json$Encode$string(folder.folderName))
			]));
};
var $author$project$Pages$Document$encodeDeleteBlob = function (model) {
	var filteredNames = A2(
		$elm$core$List$filterMap,
		function (_v1) {
			var k = _v1.a;
			var v = _v1.b;
			return v ? $elm$core$Maybe$Just(k) : $elm$core$Maybe$Nothing;
		},
		$elm$core$Dict$toList(model.checkedDocs));
	var documents = A2(
		$elm$core$List$filter,
		function (bd) {
			return A2($elm$core$List$member, bd.blob.name, filteredNames);
		},
		model.blobDocuments);
	var _v0 = model.folder;
	if (_v0.$ === 'Nothing') {
		return $elm$json$Json$Encode$string('');
	} else {
		var c = _v0.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'folder',
					$author$project$Pages$Document$encodedFolder(c)),
					_Utils_Tuple2(
					'documents',
					A2($elm$json$Json$Encode$list, $author$project$Pages$Document$encodeDocument, documents))
				]));
	}
};
var $author$project$Pages$Document$deleteBlob = function (model) {
	var _v0 = model.folder;
	if (_v0.$ === 'Nothing') {
		return $elm$core$Platform$Cmd$none;
	} else {
		var c = _v0.a;
		return A6(
			$author$project$API$API$postWithHeaders,
			'/blobdelete',
			model.session.token,
			model.session.loginType,
			A2($elm$http$Http$expectJson, $author$project$Pages$Document$ReceiveDeleteResponse, $author$project$API$Decoder$decodeBasicResponse),
			_List_fromArray(
				[
					A2($elm$http$Http$header, 'CustomerKey', model.session.customerKey)
				]),
			A2(
				$elm$http$Http$stringBody,
				'application/json',
				A2(
					$elm$json$Json$Encode$encode,
					0,
					$author$project$Pages$Document$encodeDeleteBlob(model))));
	}
};
var $author$project$Common$FileHelpers$documentApprovedMimeTypes = _List_fromArray(
	['video/3gpp', 'audio/3gpp', 'video/3gpp2', 'audio/3gpp2', 'application/x-7z-compressed', 'audio/aac', 'application/x-abiword', 'application/x-freearc', 'video/x-msvideo', 'application/vnd.amazon.ebook', 'application/octet-stream', 'image/bmp', 'application/x-bzip', 'application/x-bzip2', 'application/x-csh', 'text/css', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-fontobject', 'application/epub+zip', 'image/gif', 'text/html', 'image/vnd.microsoft.icon', 'text/calendar', 'application/java-archive', 'image/jpeg', 'text/javascript', 'application/json', 'audio/midi, audio/x-midi', 'text/javascript', 'audio/mpeg', 'video/mpeg', 'application/vnd.apple.installer+xml', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text', 'audio/ogg', 'video/ogg', 'application/ogg', 'font/otf', 'image/png', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/x-rar-compressed', 'application/rtf', 'application/x-sh', 'image/svg+xml', 'application/x-shockwave-flash', 'application/x-tar', 'image/tiff', 'font/ttf', 'text/plain', 'application/vnd.visio', 'audio/wav', 'audio/webm', 'video/webm', 'image/webm', 'font/woff', 'font/woff2', 'application/xhtml+xml', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml, text/xml', 'application/vnd.mozilla.xul+xml', 'application/zip']);
var $author$project$Pages$Document$Downloaded = F2(
	function (a, b) {
		return {$: 'Downloaded', a: a, b: b};
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Pages$Document$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 'BadUrl_':
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 'Timeout_':
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 'NetworkError_':
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 'BadStatus_':
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.statusCode));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $author$project$Pages$Document$downloadBlob = F3(
	function (model, folder, blob) {
		return A5(
			$author$project$API$API$post,
			'/blobsdownload',
			model.session.token,
			model.session.loginType,
			A2(
				$elm$http$Http$expectBytesResponse,
				$author$project$Pages$Document$Downloaded(blob),
				$author$project$Pages$Document$resolve($elm$core$Result$Ok)),
			'{  "container": \"' + (model.session.customerKey + ('\", "folder": \"' + (folder.folderName + ('\", "fileName": \"' + (blob.name + '\" }'))))));
	});
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$Pages$Document$downloadFile = F2(
	function (blob, content) {
		return A3($elm$file$File$Download$bytes, blob.name, blob.contentType, content);
	});
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$Pages$Document$ReceiveBlobs = function (a) {
	return {$: 'ReceiveBlobs', a: a};
};
var $author$project$Common$Types$Blob = F4(
	function (name, createdOn, lastModified, contentType) {
		return {contentType: contentType, createdOn: createdOn, lastModified: lastModified, name: name};
	});
var $author$project$API$Decoder$decodeBlob = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'contentType',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'lastModified',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'createdOn',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Common$Types$Blob)))));
var $author$project$API$Decoder$decodeBlobs = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['blobs']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeBlob));
var $author$project$Pages$Document$getBlobs = F2(
	function (model, folder) {
		return A5(
			$author$project$API$API$post,
			'/blobs',
			model.session.token,
			model.session.loginType,
			A2($elm$http$Http$expectJson, $author$project$Pages$Document$ReceiveBlobs, $author$project$API$Decoder$decodeBlobs),
			'{ "container": \"' + (model.session.customerKey + ('\", "folder": \"' + (folder.folderName + '\" }'))));
	});
var $author$project$Pages$Document$ReceiveDocuments = function (a) {
	return {$: 'ReceiveDocuments', a: a};
};
var $author$project$API$Decoder$decodeDocuments = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'Documents']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodeDocument));
var $author$project$API$Queries$documents = function (folderKey) {
	return '\r\n    query {\r\n        Documents(FolderKey: \"' + (folderKey + '\") {\r\n            DocumentKey\r\n            DocumentName\r\n            Description\r\n            Uploader\r\n        }\r\n    }\r\n    ');
};
var $author$project$Pages$Document$getDocuments = F2(
	function (model, folder) {
		return A5(
			$author$project$API$API$requestGraphQL,
			model.session.token,
			model.session.loginType,
			$author$project$API$Queries$documents(folder.folderKey),
			$author$project$Pages$Document$ReceiveDocuments,
			$author$project$API$Decoder$decodeDocuments);
	});
var $author$project$Common$Types$BlobDocument = F2(
	function (document, blob) {
		return {blob: blob, document: document};
	});
var $author$project$Pages$Document$mapDocument = F2(
	function (documents, blob) {
		var doc = A2(
			$elm$core$List$any,
			function (d) {
				return _Utils_eq(d.documentName, blob.name);
			},
			documents) ? $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (d) {
					return _Utils_eq(d.documentName, blob.name);
				},
				documents)) : $elm$core$Maybe$Just(
			A4($author$project$Common$Types$Document, '-', '-', '-', '-'));
		if (doc.$ === 'Nothing') {
			return $elm$core$Maybe$Nothing;
		} else {
			var d = doc.a;
			return $elm$core$Maybe$Just(
				A2($author$project$Common$Types$BlobDocument, d, blob));
		}
	});
var $author$project$Pages$Document$mapDocuments = F2(
	function (documents, blobs) {
		return A2(
			$elm$core$List$filterMap,
			$author$project$Pages$Document$mapDocument(documents),
			blobs);
	});
var $author$project$Pages$Document$ReceiveUploadResponse = function (a) {
	return {$: 'ReceiveUploadResponse', a: a};
};
var $elm$file$File$name = _File_name;
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$core$String$trim = _String_trim;
var $author$project$Pages$Document$uploadBlob = F2(
	function (model, file) {
		var _v0 = model.folder;
		if (_v0.$ === 'Nothing') {
			return $elm$core$Platform$Cmd$none;
		} else {
			var c = _v0.a;
			return A6(
				$author$project$API$API$postWithHeaders,
				'/blobupload',
				model.session.token,
				model.session.loginType,
				A2($elm$http$Http$expectJson, $author$project$Pages$Document$ReceiveUploadResponse, $author$project$API$Decoder$decodeBasicResponse),
				_List_fromArray(
					[
						A2($elm$http$Http$header, 'CustomerKey', model.session.customerKey),
						A2($elm$http$Http$header, 'FolderKey', c.folderKey),
						A2($elm$http$Http$header, 'FolderName', c.folderName),
						A2(
						$elm$http$Http$header,
						'DocumentName',
						$elm$url$Url$percentEncode(
							$elm$file$File$name(file))),
						A2(
						$elm$http$Http$header,
						'Description',
						$elm$url$Url$percentEncode(
							$elm$core$String$trim(model.description)))
					]),
				$elm$http$Http$multipartBody(
					_List_fromArray(
						[
							A2($elm$http$Http$filePart, 'file[]', file)
						])));
		}
	});
var $author$project$Pages$Document$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'RequestBlobs':
				var _v1 = model.folder;
				if (_v1.$ === 'Just') {
					var folder = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{blobDocuments: _List_Nil, blobs: _List_Nil}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Pages$Document$getBlobs, model, folder),
									A2($author$project$Pages$Document$getDocuments, model, folder)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveBlobs':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var blobs = result.a;
					var _v3 = model.folder;
					if (_v3.$ === 'Just') {
						var c = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									blobDocuments: A2($author$project$Pages$Document$mapDocuments, c.documents, blobs),
									blobs: blobs
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveFolders':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var folders = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								folder: $elm$core$List$head(folders)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SetFolder':
				var folder = msg.a;
				return A2(
					$author$project$Pages$Document$update,
					$author$project$Pages$Document$RequestBlobs,
					_Utils_update(
						model,
						{folder: folder}));
			case 'DownloadFile':
				var blob = msg.a;
				var _v5 = model.folder;
				if (_v5.$ === 'Just') {
					var folder = _v5.a;
					return _Utils_Tuple2(
						model,
						A3($author$project$Pages$Document$downloadBlob, model, folder, blob));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'DownloadFinished':
				var result = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'Downloaded':
				if (msg.b.$ === 'Ok') {
					var blob = msg.a;
					var response = msg.b.a;
					return _Utils_Tuple2(
						model,
						A2($author$project$Pages$Document$downloadFile, blob, response));
				} else {
					var blob = msg.a;
					var err = msg.b.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'Upload':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showUpload: true}),
					$elm$core$Platform$Cmd$none);
			case 'FileRequested':
				return _Utils_Tuple2(
					model,
					A2($elm$file$File$Select$file, $author$project$Common$FileHelpers$documentApprovedMimeTypes, $author$project$Pages$Document$FileSelected));
			case 'FileSelected':
				var file = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							uploadFile: $elm$core$Maybe$Just(file)
						}),
					$elm$core$Platform$Cmd$none);
			case 'FileLoaded':
				var bytes = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'UploadBlob':
				var _v6 = model.uploadFile;
				if (_v6.$ === 'Just') {
					var file = _v6.a;
					return _Utils_Tuple2(
						model,
						A2($author$project$Pages$Document$uploadBlob, model, file));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveUploadResponse':
				var result = msg.a;
				var updatedModel = _Utils_update(
					model,
					{description: '', showUpload: false, uploadFile: $elm$core$Maybe$Nothing});
				if (result.$ === 'Ok') {
					var v = result.a;
					var _v8 = model.folder;
					if (_v8.$ === 'Just') {
						var c = _v8.a;
						return _Utils_Tuple2(
							updatedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($author$project$Pages$Document$getBlobs, model, c),
										A2($author$project$Pages$Document$getDocuments, model, c)
									])));
					} else {
						return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
				}
			case 'UpdateDescription':
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{description: str}),
					$elm$core$Platform$Cmd$none);
			case 'CancelUpload':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{description: '', showUpload: false, uploadFile: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 'CheckFile':
				var blobDoc = msg.a;
				var _v9 = A2($elm$core$Dict$get, blobDoc.blob.name, model.checkedDocs);
				if (_v9.$ === 'Just') {
					var isChecked = _v9.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								checkedDocs: A3($elm$core$Dict$insert, blobDoc.blob.name, !isChecked, model.checkedDocs)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								checkedDocs: A3($elm$core$Dict$insert, blobDoc.blob.name, true, model.checkedDocs)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ToggleDeleteConfirmation':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showDeleteConfirmation: !model.showDeleteConfirmation}),
					$elm$core$Platform$Cmd$none);
			case 'RequestDelete':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{deleteStatus: $author$project$Common$Types$Loading}),
					$author$project$Pages$Document$deleteBlob(model));
			case 'ReceiveDeleteResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var v = result.a;
					var _v11 = v.statusCode;
					if (_v11 === 200) {
						var _v12 = model.folder;
						if (_v12.$ === 'Just') {
							var c = _v12.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{checkedDocs: $elm$core$Dict$empty, deleteStatus: $author$project$Common$Types$NotAsked, showDeleteConfirmation: false}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2($author$project$Pages$Document$getBlobs, model, c),
											A2($author$project$Pages$Document$getDocuments, model, c)
										])));
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{checkedDocs: $elm$core$Dict$empty, deleteStatus: $author$project$Common$Types$NotAsked, showDeleteConfirmation: false}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									deleteStatus: $author$project$Common$Types$Failed(v.error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								deleteStatus: $author$project$Common$Types$Failed('Något gick fel')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RequestDocuments':
				var _v13 = model.folder;
				if (_v13.$ === 'Just') {
					var c = _v13.a;
					return _Utils_Tuple2(
						model,
						A2($author$project$Pages$Document$getDocuments, model, c));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveDocuments':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var documents = result.a;
					var _v15 = model.folder;
					if (_v15.$ === 'Just') {
						var c = _v15.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									blobDocuments: A2($author$project$Pages$Document$mapDocuments, documents, model.blobs),
									folder: $elm$core$Maybe$Just(
										_Utils_update(
											c,
											{documents: documents}))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UpdateSort':
				var columnIndex = msg.a;
				var updatedModel = function () {
					var _v16 = model.sort;
					switch (_v16.$) {
						case 'Asc':
							var currentIndex = _v16.a;
							return _Utils_eq(columnIndex, currentIndex) ? _Utils_update(
								model,
								{
									sort: $author$project$Elements$Table$Desc(columnIndex)
								}) : _Utils_update(
								model,
								{
									sort: $author$project$Elements$Table$Asc(columnIndex)
								});
						case 'Desc':
							var currentIndex = _v16.a;
							return _Utils_eq(columnIndex, currentIndex) ? _Utils_update(
								model,
								{
									sort: $author$project$Elements$Table$Asc(columnIndex)
								}) : _Utils_update(
								model,
								{
									sort: $author$project$Elements$Table$Desc(columnIndex)
								});
						default:
							return _Utils_update(
								model,
								{
									sort: $author$project$Elements$Table$Asc(columnIndex)
								});
					}
				}();
				return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
			case 'ShowFileDetails':
				var blobDocument = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							selectedBlobDocument: $elm$core$Maybe$Just(blobDocument),
							showFileDetails: true
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedBlobDocument: $elm$core$Maybe$Nothing, showFileDetails: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$Home$Browse = {$: 'Browse'};
var $author$project$Pages$Home$SelectAnnouncement = function (a) {
	return {$: 'SelectAnnouncement', a: a};
};
var $author$project$Pages$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SetAnnouncements':
				var announcements = msg.a;
				var selectFirstAction = function () {
					var _v1 = $elm$core$List$head(announcements);
					if (_v1.$ === 'Just') {
						var a = _v1.a;
						return $author$project$Pages$Home$SelectAnnouncement(a);
					} else {
						return $author$project$Pages$Home$NoOp;
					}
				}();
				return A2(
					$author$project$Pages$Home$update,
					selectFirstAction,
					_Utils_update(
						model,
						{announcements: announcements}));
			case 'SelectAnnouncement':
				var announcement = msg.a;
				var _v2 = model.mode;
				if (_v2.$ === 'View') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								mobileView: $author$project$Pages$Home$Announcement,
								selectedAnnouncement: $elm$core$Maybe$Just(announcement)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveAnnouncements':
				var result = msg.a;
				if (result.$ === 'Err') {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var announcements = result.a;
					return A2(
						$author$project$Pages$Home$update,
						$author$project$Pages$Home$SetAnnouncements(announcements),
						model);
				}
			case 'SetServices':
				var services = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							announcementServices: services,
							selectedAnnouncementService: $elm$core$List$head(services)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectAnnouncementService':
				var serviceKey = msg.a;
				var selectedService = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(s.serviceKey, serviceKey);
						},
						model.announcementServices));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedAnnouncementService: selectedService}),
					$elm$core$Platform$Cmd$none);
			default:
				var updatedModel = function () {
					var _v4 = model.mobileView;
					if (_v4.$ === 'Announcement') {
						return _Utils_update(
							model,
							{mobileView: $author$project$Pages$Home$Browse});
					} else {
						return _Utils_update(
							model,
							{mobileView: $author$project$Pages$Home$Announcement});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{selectedAnnouncement: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Pages$HomeEdit$SelectAnnouncement = function (a) {
	return {$: 'SelectAnnouncement', a: a};
};
var $author$project$Pages$HomeEdit$SelectAnnouncementService = function (a) {
	return {$: 'SelectAnnouncementService', a: a};
};
var $author$project$Pages$HomeEdit$ReceiveUpdatedAnnouncementResult = function (a) {
	return {$: 'ReceiveUpdatedAnnouncementResult', a: a};
};
var $author$project$API$Queries$createAnnouncement = F3(
	function (customerKey, serviceKey, announcement) {
		var escapedBody = A3(
			$elm$core$String$replace,
			'\n',
			'<br>',
			A3($elm$core$String$replace, '\"', '\\\"', announcement.announcementBody));
		return '\r\n        mutation {\r\n            CreateAnnouncement(CustomerKey: \"' + (customerKey + ('\"\r\n            , ServiceKey: \"' + (serviceKey + ('\"\r\n            , Subject: \"' + (announcement.announcementSubject + ('\"\r\n            , Body: \"' + (escapedBody + ('\"\r\n            , ImageUrl: \"' + (announcement.announcementAttachmentUrl + '\"\r\n            ) {\r\n                Error\r\n            }\r\n        }\r\n        ')))))))));
	});
var $author$project$Pages$HomeEdit$createAnnouncement = F5(
	function (token, loginType, customerKey, serviceKey, announcement) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$createAnnouncement, customerKey, serviceKey, announcement),
			$author$project$Pages$HomeEdit$ReceiveUpdatedAnnouncementResult,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $author$project$Pages$HomeEdit$ReceiveAnnouncements = function (a) {
	return {$: 'ReceiveAnnouncements', a: a};
};
var $author$project$Pages$HomeEdit$getAnnouncements = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$announcements(customerKey),
			$author$project$Pages$HomeEdit$ReceiveAnnouncements,
			$author$project$API$Decoder$decodeAnnouncements);
	});
var $author$project$Pages$HomeEdit$getSelectedDropdownServiceKey = F2(
	function (services, announcement) {
		if (announcement.$ === 'Just') {
			var a = announcement.a;
			return a.announcementServiceKey;
		} else {
			var _v1 = $elm$core$List$head(services);
			if (_v1.$ === 'Just') {
				var x = _v1.a;
				return x.serviceKey;
			} else {
				return '';
			}
		}
	});
var $author$project$Pages$HomeEdit$newAnnouncement = A8($author$project$Common$Types$Announcement, '', '', '', '', '', '', '', 0);
var $author$project$API$Queries$removeAnnouncement = F2(
	function (customerKey, announcement) {
		return '\r\n    mutation {\r\n        RemoveAnnouncement(CustomerKey: \"' + (customerKey + ('\"\r\n        , AnnouncementKey: \"' + (announcement.announcementKey + '\"\r\n        ) {\r\n            Error\r\n        }\r\n    }\r\n    ')));
	});
var $author$project$Pages$HomeEdit$removeAnnouncement = F4(
	function (token, loginType, customerKey, announcement) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$removeAnnouncement, customerKey, announcement),
			$author$project$Pages$HomeEdit$ReceiveUpdatedAnnouncementResult,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$HomeEdit$stringContainsScriptTag = function (text) {
	return A2($author$project$Common$Format$match, '<[^>]*script', text);
};
var $author$project$API$Queries$updateAnnouncement = F3(
	function (customerKey, serviceKey, announcement) {
		var escapedBody = A3(
			$elm$core$String$replace,
			'\n',
			'<br>',
			A3($elm$core$String$replace, '\"', '\\\"', announcement.announcementBody));
		return '\r\n        mutation {\r\n            UpdateAnnouncement(CustomerKey: \"' + (customerKey + ('\"\r\n            , AnnouncementKey: \"' + (announcement.announcementKey + ('\"\r\n            , ServiceKey: \"' + (serviceKey + ('\"\r\n            , Subject: \"' + (announcement.announcementSubject + ('\"\r\n            , Body: \"' + (escapedBody + '\"\r\n            ) {\r\n                Error\r\n            }\r\n        }\r\n        ')))))))));
	});
var $author$project$Pages$HomeEdit$updateAnnouncement = F5(
	function (token, loginType, customerKey, serviceKey, announcement) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$updateAnnouncement, customerKey, serviceKey, announcement),
			$author$project$Pages$HomeEdit$ReceiveUpdatedAnnouncementResult,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$HomeEdit$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SetAnnouncements':
				var announcements = msg.a;
				var selectFirstAction = function () {
					var _v1 = $elm$core$List$head(announcements);
					if (_v1.$ === 'Just') {
						var a = _v1.a;
						return $author$project$Pages$HomeEdit$SelectAnnouncement(a);
					} else {
						return $author$project$Pages$HomeEdit$NoOp;
					}
				}();
				return A2(
					$author$project$Pages$HomeEdit$update,
					selectFirstAction,
					_Utils_update(
						model,
						{announcements: announcements}));
			case 'SelectAnnouncement':
				var announcement = msg.a;
				var _v2 = model.mode;
				if (_v2.$ === 'View') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								selectedAnnouncement: $elm$core$Maybe$Just(announcement)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ToggleCreateMode':
				var updatedAnnouncements = model.createModeOn ? A2(
					$elm$core$List$filter,
					function (a) {
						return !_Utils_eq(a, $author$project$Pages$HomeEdit$newAnnouncement);
					},
					model.announcements) : A2($elm$core$List$cons, $author$project$Pages$HomeEdit$newAnnouncement, model.announcements);
				var selectedAnnouncement = model.createModeOn ? $elm$core$List$head(updatedAnnouncements) : $elm$core$Maybe$Just($author$project$Pages$HomeEdit$newAnnouncement);
				return model.editModeOn ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : A2(
					$author$project$Pages$HomeEdit$update,
					$author$project$Pages$HomeEdit$SelectAnnouncementService(
						A2($author$project$Pages$HomeEdit$getSelectedDropdownServiceKey, model.announcementServices, $elm$core$Maybe$Nothing)),
					_Utils_update(
						model,
						{announcements: updatedAnnouncements, createModeOn: !model.createModeOn, selectedAnnouncement: selectedAnnouncement}));
			case 'ToggleEditMode':
				var announcementToBeEdited = function () {
					var _v3 = model.selectedAnnouncement;
					if (_v3.$ === 'Just') {
						var a = _v3.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								a,
								{
									announcementBody: A3($elm$core$String$replace, '<br>', '\n', a.announcementBody)
								}));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return model.createModeOn ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : ((!model.editModeOn) ? A2(
					$author$project$Pages$HomeEdit$update,
					$author$project$Pages$HomeEdit$SelectAnnouncementService(
						A2($author$project$Pages$HomeEdit$getSelectedDropdownServiceKey, model.announcementServices, announcementToBeEdited)),
					_Utils_update(
						model,
						{editModeOn: !model.editModeOn, newAnnouncement: announcementToBeEdited})) : _Utils_Tuple2(
					_Utils_update(
						model,
						{editModeOn: !model.editModeOn, newAnnouncement: $elm$core$Maybe$Nothing, selectedAnnouncement: model.selectedAnnouncement}),
					$elm$core$Platform$Cmd$none));
			case 'ToggleMode':
				var mode = msg.a;
				var updatedModel = _Utils_update(
					model,
					{mode: mode});
				switch (mode.$) {
					case 'View':
						return _Utils_Tuple2(
							_Utils_update(
								updatedModel,
								{newAnnouncement: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					case 'Create':
						var _v5 = updatedModel.newAnnouncement;
						if (_v5.$ === 'Just') {
							return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									updatedModel,
									{
										newAnnouncement: $elm$core$Maybe$Just($author$project$Pages$HomeEdit$newAnnouncement)
									}),
								$elm$core$Platform$Cmd$none);
						}
					case 'Edit':
						var _v6 = model.selectedAnnouncement;
						if (_v6.$ === 'Just') {
							var a = _v6.a;
							return _Utils_Tuple2(
								_Utils_update(
									updatedModel,
									{
										newAnnouncement: $elm$core$Maybe$Just(
											_Utils_update(
												a,
												{
													announcementBody: A3($elm$core$String$replace, '<br>', '\n', a.announcementBody)
												}))
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									updatedModel,
									{newAnnouncement: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$none);
						}
					default:
						var _v7 = model.newAnnouncement;
						if (_v7.$ === 'Just') {
							var a = _v7.a;
							return $author$project$Pages$HomeEdit$stringContainsScriptTag(a.announcementBody) ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										publishInformation: $elm$core$Maybe$Just('<script> kan ej användas')
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
						}
				}
			case 'UpdateNewAnnouncementSubject':
				var subject = msg.a;
				var announcement = function () {
					var _v8 = model.newAnnouncement;
					if (_v8.$ === 'Nothing') {
						return A8($author$project$Common$Types$Announcement, '', '', subject, '', '', '', '', 0);
					} else {
						var a = _v8.a;
						return _Utils_update(
							a,
							{announcementSubject: subject});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							newAnnouncement: $elm$core$Maybe$Just(announcement)
						}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateNewAnnouncementBody':
				var body = msg.a;
				var announcement = function () {
					var _v9 = model.newAnnouncement;
					if (_v9.$ === 'Nothing') {
						return A8($author$project$Common$Types$Announcement, '', '', '', body, '', '', '', 0);
					} else {
						var a = _v9.a;
						return _Utils_update(
							a,
							{announcementBody: body});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							newAnnouncement: $elm$core$Maybe$Just(announcement)
						}),
					$elm$core$Platform$Cmd$none);
			case 'InsertTabInBody':
				var keycode = msg.a;
				var _char = (keycode === 9) ? '\t' : $elm$core$String$fromChar(
					$elm$core$Char$fromCode(keycode));
				var updatedAnnouncement = function () {
					var _v10 = model.newAnnouncement;
					if (_v10.$ === 'Nothing') {
						return A8($author$project$Common$Types$Announcement, '', '', '', _char, '', '', '', 0);
					} else {
						var a = _v10.a;
						return _Utils_update(
							a,
							{
								announcementBody: _Utils_ap(a.announcementBody, _char)
							});
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							newAnnouncement: $elm$core$Maybe$Just(updatedAnnouncement)
						}),
					$elm$core$Platform$Cmd$none);
			case 'PublishAnnouncement':
				var announcement = msg.a;
				var serviceKey = function () {
					var _v12 = model.selectedAnnouncementService;
					if (_v12.$ === 'Just') {
						var s = _v12.a;
						return s.serviceKey;
					} else {
						return '';
					}
				}();
				var publishCommand = function () {
					var _v11 = model.mode;
					switch (_v11.$) {
						case 'Create':
							return $elm$core$String$isEmpty(announcement.announcementKey) ? A5($author$project$Pages$HomeEdit$createAnnouncement, model.session.token, model.session.loginType, model.session.customerKey, serviceKey, announcement) : A5($author$project$Pages$HomeEdit$updateAnnouncement, model.session.token, model.session.loginType, model.session.customerKey, serviceKey, announcement);
						case 'Edit':
							return A5($author$project$Pages$HomeEdit$updateAnnouncement, model.session.token, model.session.loginType, model.session.customerKey, serviceKey, announcement);
						default:
							return $elm$core$Platform$Cmd$none;
					}
				}();
				return $author$project$Pages$HomeEdit$stringContainsScriptTag(announcement.announcementBody) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{
							publishInformation: $elm$core$Maybe$Just('<script> kan ej användas')
						}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{mode: $author$project$Pages$HomeEdit$View, newAnnouncement: $elm$core$Maybe$Nothing, publishInformation: $elm$core$Maybe$Nothing, selectedAnnouncement: $elm$core$Maybe$Nothing}),
					publishCommand);
			case 'RemoveAnnouncement':
				var announcement = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showConfirmation: false}),
					A4($author$project$Pages$HomeEdit$removeAnnouncement, model.session.token, model.session.loginType, model.session.customerKey, announcement));
			case 'ReceiveUpdatedAnnouncementResult':
				return _Utils_Tuple2(
					model,
					A3($author$project$Pages$HomeEdit$getAnnouncements, model.session.token, model.session.loginType, model.session.customerKey));
			case 'ReceiveAnnouncements':
				var result = msg.a;
				if (result.$ === 'Err') {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var announcements = result.a;
					return A2(
						$author$project$Pages$HomeEdit$update,
						$author$project$Pages$HomeEdit$SetAnnouncements(announcements),
						model);
				}
			case 'SetServices':
				var services = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							announcementServices: services,
							selectedAnnouncementService: $elm$core$List$head(services)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectAnnouncementService':
				var serviceKey = msg.a;
				var selectedService = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(s.serviceKey, serviceKey);
						},
						model.announcementServices));
				var selectedServiceKey = function () {
					if (selectedService.$ === 'Just') {
						var s = selectedService.a;
						return s.serviceKey;
					} else {
						return '';
					}
				}();
				var _v14 = model.newAnnouncement;
				if (_v14.$ === 'Just') {
					var a = _v14.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								newAnnouncement: $elm$core$Maybe$Just(
									_Utils_update(
										a,
										{announcementServiceKey: selectedServiceKey})),
								selectedAnnouncementService: selectedService
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{selectedAnnouncementService: selectedService}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{showConfirmation: state}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Ports$AzureAD$loginAzureAD = _Platform_outgoingPort(
	'loginAzureAD',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Pages$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'LoginMsal':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$AzureAD$loginAzureAD(_Utils_Tuple0));
			case 'LogoutMsal':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$AzureAD$logoutAzureAD(_Utils_Tuple0));
			default:
				var context = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							context: $elm$core$Maybe$Just(context)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Elements$Table$Dropdown = F3(
	function (options, selected, msg) {
		return {msg: msg, options: options, selected: selected};
	});
var $author$project$Pages$ReportSettings$GetGroupMetaData = {$: 'GetGroupMetaData'};
var $author$project$Pages$ReportSettings$GetReportMetaData = {$: 'GetReportMetaData'};
var $author$project$Pages$ReportSettings$RequestAddPBITenant = {$: 'RequestAddPBITenant'};
var $author$project$Pages$ReportSettings$RequestServices = function (a) {
	return {$: 'RequestServices', a: a};
};
var $author$project$Pages$ReportSettings$ReceiveAddPBITenant = function (a) {
	return {$: 'ReceiveAddPBITenant', a: a};
};
var $author$project$API$Queries$addPBITenant = F4(
	function (customerKey, customerName, pbiTenantName, azureTenantId) {
		return '\r\n    mutation {\r\n        AddPBITenant(CustomerKey: \"' + (customerKey + ('\"\r\n        , CustomerName: \"' + (customerName + ('\"\r\n        , PBITenantName: \"' + (pbiTenantName + ('\"\r\n        , AzureTenantId: \"' + (azureTenantId + '\"\r\n        ) {\r\n            Error\r\n        }\r\n    }\r\n    ')))))));
	});
var $author$project$Pages$ReportSettings$addPBITenant = F6(
	function (token, loginType, customerKey, customerName, pbiTenantName, azureTenantId) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A4($author$project$API$Queries$addPBITenant, customerKey, customerName, pbiTenantName, azureTenantId),
			$author$project$Pages$ReportSettings$ReceiveAddPBITenant,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$ReportSettings$ReceiveGroupAccess = function (a) {
	return {$: 'ReceiveGroupAccess', a: a};
};
var $author$project$Pages$ReportSettings$encodeReport = function (report) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'groupId',
				$elm$json$Json$Encode$string(report.groupId)),
				_Utils_Tuple2(
				'reportId',
				$elm$json$Json$Encode$string(report.reportId)),
				_Utils_Tuple2(
				'azureTenantId',
				$elm$json$Json$Encode$string(report.azureTenantId)),
				_Utils_Tuple2(
				'spnId',
				$elm$json$Json$Encode$string(report.spnId)),
				_Utils_Tuple2(
				'spnSecret',
				$elm$json$Json$Encode$string(report.spnSecret))
			]));
};
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $author$project$Pages$ReportSettings$checkGroupAccess = F3(
	function (token, loginType, report) {
		return A5(
			$author$project$API$API$post,
			'/mimica/pbi/group',
			token,
			loginType,
			$elm$http$Http$expectString($author$project$Pages$ReportSettings$ReceiveGroupAccess),
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Pages$ReportSettings$encodeReport(report)));
	});
var $author$project$Pages$ReportSettings$emptyReport = $author$project$Common$Types$Report('')('')(0)('')('')('')('')('')('')('')(false)(false)(false)(false)('');
var $author$project$Pages$ReportSettings$ReceivePBITenants = function (a) {
	return {$: 'ReceivePBITenants', a: a};
};
var $author$project$API$Decoder$decodePbiTenant = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'SPNSecret',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'SPNId',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'AzureTenantId',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'PBITenantName',
				$elm$json$Json$Decode$string,
				'',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'PBITenantKey',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$Types$PBITenant))))));
var $author$project$API$Decoder$decodePbiTenants = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['data', 'getPbiTenants']),
	$elm$json$Json$Decode$list($author$project$API$Decoder$decodePbiTenant));
var $author$project$API$Queries$pbiTenants = function (customerKey) {
	return '\r\n    query {\r\n        getPbiTenants(CustomerKey: \"' + (customerKey + '\") {\r\n            PBITenantKey\r\n            PBITenantName\r\n            SPNId\r\n            SPNSecret\r\n        }\r\n    }\r\n    ');
};
var $author$project$Pages$ReportSettings$getPBITenants = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$pbiTenants(customerKey),
			$author$project$Pages$ReportSettings$ReceivePBITenants,
			$author$project$API$Decoder$decodePbiTenants);
	});
var $author$project$Pages$ReportSettings$ReceiveGroupMetaData = function (a) {
	return {$: 'ReceiveGroupMetaData', a: a};
};
var $author$project$Common$Types$GroupMetaData = F2(
	function (id, name) {
		return {id: id, name: name};
	});
var $author$project$API$Decoder$decodeGroupMetaData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Common$Types$GroupMetaData)));
var $author$project$Pages$ReportSettings$getPowerBIGroupMetaData = F3(
	function (token, loginType, report) {
		return A5(
			$author$project$API$API$post,
			'/mimica/group/metadata',
			token,
			loginType,
			A2($elm$http$Http$expectJson, $author$project$Pages$ReportSettings$ReceiveGroupMetaData, $author$project$API$Decoder$decodeGroupMetaData),
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Pages$ReportSettings$encodeReport(report)));
	});
var $author$project$Pages$ReportSettings$ReceiveReportMetaData = function (a) {
	return {$: 'ReceiveReportMetaData', a: a};
};
var $author$project$Common$Types$ReportMetaData = F3(
	function (id, reportType, name) {
		return {id: id, name: name, reportType: reportType};
	});
var $author$project$API$Decoder$decodeReportMetaData = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'reportType',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Common$Types$ReportMetaData))));
var $author$project$Pages$ReportSettings$getPowerBIReportMetaData = F3(
	function (token, loginType, report) {
		return A5(
			$author$project$API$API$post,
			'/mimica/report/metadata',
			token,
			loginType,
			A2($elm$http$Http$expectJson, $author$project$Pages$ReportSettings$ReceiveReportMetaData, $author$project$API$Decoder$decodeReportMetaData),
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Pages$ReportSettings$encodeReport(report)));
	});
var $author$project$Pages$ReportSettings$ReceiveReportInformation = function (a) {
	return {$: 'ReceiveReportInformation', a: a};
};
var $author$project$API$Queries$reportInformation = F2(
	function (customerKey, tabKey) {
		return '\r\n    query {\r\n        getreports(email: \"\", logintype: \"\", customerkey: \"' + (customerKey + ('\", tabkey: \"' + (tabKey + '\") {\r\n            ReportKey\r\n            ReportName\r\n            ReportSortKey\r\n            PowerBIGroupGUID\r\n            PowerBIReportGUID\r\n            ReportDescription\r\n            PBITenantKey\r\n            AzureTenantId\r\n            SPNId\r\n            SPNSecret\r\n            RLS\r\n            TransparentBackground\r\n            ShowFilters\r\n            ShowPageNavigation\r\n            ReportInitialPage\r\n        }\r\n    }\r\n    ')));
	});
var $author$project$Pages$ReportSettings$getReportInformation = F4(
	function (token, loginType, customerKey, tabKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$reportInformation, customerKey, tabKey),
			$author$project$Pages$ReportSettings$ReceiveReportInformation,
			$author$project$API$Decoder$decodeReports);
	});
var $author$project$Pages$ReportSettings$ReceiveServices = function (a) {
	return {$: 'ReceiveServices', a: a};
};
var $author$project$Pages$ReportSettings$getReportServices = F3(
	function (token, loginType, customerKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			$author$project$API$Queries$reportServices(customerKey),
			$author$project$Pages$ReportSettings$ReceiveServices,
			$author$project$API$Decoder$decodeServices);
	});
var $author$project$Pages$ReportSettings$isPBITenantFilledOut = function (pbiTenant) {
	return (!$elm$core$String$isEmpty(pbiTenant.pbiTenantName)) && ((!$elm$core$String$isEmpty(pbiTenant.spnId)) && ((!$elm$core$String$isEmpty(pbiTenant.spnSecret)) && (!$elm$core$String$isEmpty(pbiTenant.azureTenantId))));
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Common$Helpers$keepOnlyLettersAndNumbersAndDash = function (value) {
	var _v0 = $elm$regex$Regex$fromString('[^0-9a-zA-Z-]');
	if (_v0.$ === 'Nothing') {
		return value;
	} else {
		var regex = _v0.a;
		return A3(
			$elm$regex$Regex$replace,
			regex,
			function (_v1) {
				return '';
			},
			value);
	}
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Pages$ReportSettings$mapDictValuesFromReport = function (report) {
	return $elm$core$Dict$fromList(
		_List_fromArray(
			[
				_Utils_Tuple2('rls', report.rls),
				_Utils_Tuple2('bg', report.transparentBackground),
				_Utils_Tuple2('paneFilters', report.showFilters),
				_Utils_Tuple2('paneNav', report.showPageNavigation)
			]));
};
var $author$project$Pages$ReportSettings$ReceiveSetSPNResponse = function (a) {
	return {$: 'ReceiveSetSPNResponse', a: a};
};
var $author$project$Pages$ReportSettings$encodeSPN = function (model) {
	var _v0 = _Utils_Tuple2(
		model.selectedCustomer,
		$author$project$Pages$ReportSettings$isPBITenantFilledOut(model.newPbiTenant));
	if ((_v0.a.$ === 'Just') && _v0.b) {
		var customer = _v0.a.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'customerName',
					$elm$json$Json$Encode$string(
						$author$project$Common$Helpers$keepOnlyLettersAndNumbersAndDash(customer.customerName))),
					_Utils_Tuple2(
					'azureTenantId',
					$elm$json$Json$Encode$string(model.newPbiTenant.azureTenantId)),
					_Utils_Tuple2(
					'spnId',
					$elm$json$Json$Encode$string(model.newPbiTenant.spnId)),
					_Utils_Tuple2(
					'spnSecret',
					$elm$json$Json$Encode$string(model.newPbiTenant.spnSecret))
				]));
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(_Utils_Tuple0);
			}));
};
var $author$project$Pages$ReportSettings$setSPN = function (model) {
	return A6(
		$author$project$API$API$postWithHeaders,
		'/setspn',
		model.session.token,
		model.session.loginType,
		$elm$http$Http$expectWhatever($author$project$Pages$ReportSettings$ReceiveSetSPNResponse),
		_List_Nil,
		A2(
			$elm$http$Http$stringBody,
			'application/json',
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Pages$ReportSettings$encodeSPN(model))));
};
var $author$project$Pages$ReportSettings$ReceiveUpdatedReport = function (a) {
	return {$: 'ReceiveUpdatedReport', a: a};
};
var $author$project$API$Queries$updateReport = F3(
	function (customerKey, tabKey, reportinfo) {
		var boolConvert = function (b) {
			return b ? 'true' : 'false';
		};
		return '\r\n        mutation {\r\n            updatereport(input:\r\n                {\r\n                    Email: \"\",\r\n                    LoginType: \"\",\r\n                    CustomerKey: \"' + (customerKey + ('\",\r\n                    TabKey: \"' + (tabKey + ('\",\r\n                    ReportKey: \"' + (reportinfo.reportKey + ('\",\r\n                    ReportName: \"' + (reportinfo.reportName + ('\",\r\n                    ReportDescription: \"' + (reportinfo.reportDescription + ('\",\r\n                    ReportPowerBIGroupGUID: \"' + (reportinfo.groupId + ('\",\r\n                    ReportPowerBIReportGUID: \"' + (reportinfo.reportId + ('\",\r\n                    ReportSortKey: ' + ($elm$core$String$fromInt(reportinfo.reportSortKey) + (',\r\n                    PBITenantKey: \"' + (reportinfo.pbiTenantKey + ('\",\r\n                    RLS: ' + (boolConvert(reportinfo.rls) + (',\r\n                    TransparentBackground: ' + (boolConvert(reportinfo.transparentBackground) + (',\r\n                    ShowFilters: ' + (boolConvert(reportinfo.showFilters) + (',\r\n                    ShowPageNavigation: ' + (boolConvert(reportinfo.showPageNavigation) + (',\r\n                    ReportInitialPage: \"' + (reportinfo.reportInitialPage + '\"\r\n                }\r\n            )\r\n            { Error }\r\n        }\r\n        ')))))))))))))))))))))))))));
	});
var $author$project$Pages$ReportSettings$updateReport = F5(
	function (token, loginType, customerKey, tabKey, report) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$updateReport, customerKey, tabKey, report),
			$author$project$Pages$ReportSettings$ReceiveUpdatedReport,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$ReportSettings$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'RequestServices':
				var customerKey = msg.a;
				var selectedCustomer = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (c) {
							return _Utils_eq(c.customerKey, customerKey);
						},
						model.customers));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{reportServices: _List_Nil, reports: $elm$core$Dict$empty, selectedCustomer: selectedCustomer, selectedReport: $author$project$Common$Types$NotAsked}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A3($author$project$Pages$ReportSettings$getReportServices, model.session.token, model.session.loginType, customerKey),
								A3($author$project$Pages$ReportSettings$getPBITenants, model.session.token, model.session.loginType, customerKey)
							])));
			case 'ReceiveServices':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var services = result.a;
					var filteredServices = A2(
						$elm$core$List$filter,
						function (s) {
							return !$elm$core$String$isEmpty(s.serviceKey);
						},
						services);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{reportServices: filteredServices}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveReportInformation':
				var result = msg.a;
				var selectedTabKey = function () {
					var _v4 = model.selectedTab;
					if (_v4.$ === 'Just') {
						var tab = _v4.a;
						return tab.tabKey;
					} else {
						return '';
					}
				}();
				if (result.$ === 'Ok') {
					var reports = result.a;
					var _v3 = $elm$core$List$head(reports);
					if (_v3.$ === 'Just') {
						var report = _v3.a;
						return A2(
							$Janiczek$cmd_extra$Cmd$Extra$andThen,
							$author$project$Pages$ReportSettings$update($author$project$Pages$ReportSettings$GetReportMetaData),
							A2(
								$author$project$Pages$ReportSettings$update,
								$author$project$Pages$ReportSettings$GetGroupMetaData,
								_Utils_update(
									model,
									{
										reports: A3($elm$core$Dict$insert, selectedTabKey, report, model.reports),
										selectedReport: $author$project$Common$Types$Success(report),
										switches: $author$project$Pages$ReportSettings$mapDictValuesFromReport(report)
									})));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									debug: $elm$core$Maybe$Just('Ingen rapport'),
									groupAccess: $author$project$Common$Types$NotAsked,
									selectedReport: $author$project$Common$Types$Success($author$project$Pages$ReportSettings$emptyReport)
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = result.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SelectReaactReport':
				var tab = msg.a;
				var resetModel = _Utils_update(
					model,
					{groupMetaData: $author$project$Common$Types$NotAsked, reportMetaData: $author$project$Common$Types$NotAsked});
				var report = function () {
					var _v7 = A2($elm$core$Dict$get, tab.tabKey, model.reports);
					if (_v7.$ === 'Just') {
						var r = _v7.a;
						return $elm$core$Maybe$Just(r);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (report.$ === 'Just') {
					var r = report.a;
					return A2(
						$Janiczek$cmd_extra$Cmd$Extra$andThen,
						$author$project$Pages$ReportSettings$update($author$project$Pages$ReportSettings$GetReportMetaData),
						A2(
							$author$project$Pages$ReportSettings$update,
							$author$project$Pages$ReportSettings$GetGroupMetaData,
							_Utils_update(
								resetModel,
								{
									selectedReport: $author$project$Common$Types$Success(r),
									selectedTab: $elm$core$Maybe$Just(tab),
									switches: $author$project$Pages$ReportSettings$mapDictValuesFromReport(r)
								})));
				} else {
					var _v6 = model.selectedCustomer;
					if (_v6.$ === 'Just') {
						var customer = _v6.a;
						return _Utils_Tuple2(
							_Utils_update(
								resetModel,
								{
									groupAccess: $author$project$Common$Types$NotAsked,
									selectedTab: $elm$core$Maybe$Just(tab)
								}),
							A4($author$project$Pages$ReportSettings$getReportInformation, model.session.token, model.session.loginType, customer.customerKey, tab.tabKey));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								resetModel,
								{
									selectedTab: $elm$core$Maybe$Just(tab)
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 'UpdateReaactReport':
				var tabKey = msg.a;
				var report = msg.b;
				var selectedCustomerKey = function () {
					var _v8 = model.selectedCustomer;
					if (_v8.$ === 'Just') {
						var customer = _v8.a;
						return customer.customerKey;
					} else {
						return '';
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							groupAccess: $author$project$Common$Types$Loading,
							reports: A3($elm$core$Dict$insert, tabKey, report, model.reports)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A5($author$project$Pages$ReportSettings$updateReport, model.session.token, model.session.loginType, selectedCustomerKey, tabKey, report),
								A3($author$project$Pages$ReportSettings$checkGroupAccess, model.session.token, model.session.loginType, report)
							])));
			case 'ReceiveUpdatedReport':
				var result = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'UpdateReportSettings':
				var index = msg.a;
				var value = msg.b;
				var updatedReport = function () {
					var _v10 = model.selectedReport;
					if (_v10.$ === 'Success') {
						var report = _v10.a;
						switch (index) {
							case 0:
								return $elm$core$Maybe$Just(
									_Utils_update(
										report,
										{reportName: value}));
							case 1:
								return $elm$core$Maybe$Just(
									_Utils_update(
										report,
										{reportDescription: value}));
							case 2:
								return $elm$core$Maybe$Just(
									_Utils_update(
										report,
										{groupId: value}));
							case 3:
								return $elm$core$Maybe$Just(
									_Utils_update(
										report,
										{reportId: value}));
							case 4:
								return $elm$core$Maybe$Just(
									_Utils_update(
										report,
										{reportInitialPage: value}));
							default:
								return $elm$core$Maybe$Just(report);
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (updatedReport.$ === 'Just') {
					var report = updatedReport.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								selectedReport: $author$project$Common$Types$Success(report)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SetApplicationTabs':
				var applications = msg.a;
				var apps = A2(
					$elm$core$List$filter,
					function (a) {
						return !$elm$core$String$isEmpty(a.applicationKey);
					},
					applications);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{reportApplications: apps}),
					$elm$core$Platform$Cmd$none);
			case 'SetCustomers':
				var customers = msg.a;
				var initialCustomerKey = msg.b;
				var tableConfiguration = model.tableConfig;
				var sortedCustomers = A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.customerName;
					},
					customers);
				var initialCustomer = function () {
					if (initialCustomerKey.$ === 'Just') {
						var ck = initialCustomerKey.a;
						return $elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (c) {
									return _Utils_eq(c.customerKey, ck);
								},
								customers));
					} else {
						return $elm$core$List$head(customers);
					}
				}();
				var selectedCustomerKey = function () {
					if (initialCustomer.$ === 'Just') {
						var c = initialCustomer.a;
						return c.customerKey;
					} else {
						return '';
					}
				}();
				var dropdowns = function () {
					if (initialCustomer.$ === 'Just') {
						var customer = initialCustomer.a;
						return _List_fromArray(
							[
								A3(
								$author$project$Elements$Table$Dropdown,
								A2(
									$elm$core$List$map,
									function (c) {
										return _Utils_Tuple2(c.customerName, c.customerKey);
									},
									sortedCustomers),
								customer.customerKey,
								$author$project$Pages$ReportSettings$RequestServices)
							]);
					} else {
						return _List_fromArray(
							[
								A3(
								$author$project$Elements$Table$Dropdown,
								A2(
									$elm$core$List$map,
									function (c) {
										return _Utils_Tuple2(c.customerName, c.customerKey);
									},
									sortedCustomers),
								'',
								$author$project$Pages$ReportSettings$RequestServices)
							]);
					}
				}();
				var updatedTableConfig = _Utils_update(
					tableConfiguration,
					{dropdowns: dropdowns});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{customers: sortedCustomers, selectedCustomer: initialCustomer, tableConfig: updatedTableConfig}),
					A3($author$project$Pages$ReportSettings$getPBITenants, model.session.token, model.session.loginType, selectedCustomerKey));
			case 'ReceiveGroupAccess':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var access = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								groupAccess: $author$project$Common$Types$Success(access)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								groupAccess: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ReceiveGroupMetaData':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var groupMetaData = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								groupMetaData: $author$project$Common$Types$Success(groupMetaData)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								groupMetaData: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ReceiveReportMetaData':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var reportMetaData = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								reportMetaData: $author$project$Common$Types$Success(reportMetaData)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								reportMetaData: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'GetGroupMetaData':
				var _v18 = model.selectedReport;
				if (_v18.$ === 'Success') {
					var report = _v18.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{groupAccess: $author$project$Common$Types$Loading, groupMetaData: $author$project$Common$Types$Loading}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A3($author$project$Pages$ReportSettings$getPowerBIGroupMetaData, model.session.token, model.session.loginType, report),
									A3($author$project$Pages$ReportSettings$checkGroupAccess, model.session.token, model.session.loginType, report)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{groupAccess: $author$project$Common$Types$NotAsked}),
						$elm$core$Platform$Cmd$none);
				}
			case 'GetReportMetaData':
				var _v19 = model.selectedReport;
				if (_v19.$ === 'Success') {
					var report = _v19.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{reportMetaData: $author$project$Common$Types$Loading}),
						A3($author$project$Pages$ReportSettings$getPowerBIReportMetaData, model.session.token, model.session.loginType, report));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'SetServices':
				var services = msg.a;
				var filteredServices = A2(
					$elm$core$List$filter,
					function (s) {
						return !$elm$core$String$isEmpty(s.serviceKey);
					},
					services);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							debug: $elm$core$Maybe$Just(
								$elm$core$Debug$toString(services)),
							reportServices: filteredServices
						}),
					$elm$core$Platform$Cmd$none);
			case 'Switch':
				var id = msg.a;
				var oldValue = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2($elm$core$Dict$get, id, model.switches));
				var updatedReport = function () {
					var _v20 = model.selectedReport;
					if (_v20.$ === 'Success') {
						var report = _v20.a;
						switch (id) {
							case 'rls':
								return $author$project$Common$Types$Success(
									_Utils_update(
										report,
										{rls: !oldValue}));
							case 'bg':
								return $author$project$Common$Types$Success(
									_Utils_update(
										report,
										{transparentBackground: !oldValue}));
							case 'paneFilters':
								return $author$project$Common$Types$Success(
									_Utils_update(
										report,
										{showFilters: !oldValue}));
							case 'paneNav':
								return $author$project$Common$Types$Success(
									_Utils_update(
										report,
										{showPageNavigation: !oldValue}));
							default:
								return $author$project$Common$Types$Success(report);
						}
					} else {
						return model.selectedReport;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							selectedReport: updatedReport,
							switches: A3($elm$core$Dict$insert, id, !oldValue, model.switches)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectServicePrincipal':
				var value = msg.a;
				var selectedPBITenant = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Pages$ReportSettings$emptyPBITenant,
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (p) {
								return _Utils_eq(p.pbiTenantKey, value);
							},
							model.pbiTenants)));
				var updatedReport = function () {
					var _v22 = model.selectedReport;
					if (_v22.$ === 'Success') {
						var report = _v22.a;
						return $author$project$Common$Types$Success(
							_Utils_update(
								report,
								{pbiTenantKey: selectedPBITenant.pbiTenantKey, spnId: selectedPBITenant.spnId, spnSecret: selectedPBITenant.spnSecret}));
					} else {
						return model.selectedReport;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{selectedReport: updatedReport}),
					$elm$core$Platform$Cmd$none);
			case 'ReceivePBITenants':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var pbiTenants = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{pbiTenants: pbiTenants}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UpdatePBITenant':
				var field = msg.a;
				var value = msg.b;
				var newPbiTenant = model.newPbiTenant;
				var updatedPBITenant = function () {
					switch (field) {
						case 0:
							return _Utils_update(
								newPbiTenant,
								{pbiTenantName: value});
						case 1:
							return _Utils_update(
								newPbiTenant,
								{spnId: value});
						case 2:
							return _Utils_update(
								newPbiTenant,
								{spnSecret: value});
						case 3:
							return _Utils_update(
								newPbiTenant,
								{azureTenantId: value});
						default:
							return model.newPbiTenant;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newPbiTenant: updatedPBITenant}),
					$elm$core$Platform$Cmd$none);
			case 'RequestAddPBITenant':
				var _v25 = function () {
					var _v26 = model.selectedCustomer;
					if (_v26.$ === 'Just') {
						var c = _v26.a;
						return _Utils_Tuple2(
							c.customerKey,
							$author$project$Common$Helpers$keepOnlyLettersAndNumbersAndDash(c.customerName));
					} else {
						return _Utils_Tuple2('', '');
					}
				}();
				var selectedCustomerKey = _v25.a;
				var selectedCustomerName = _v25.b;
				return $author$project$Pages$ReportSettings$isPBITenantFilledOut(model.newPbiTenant) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{newPbiTenant: $author$project$Pages$ReportSettings$emptyPBITenant}),
					A6($author$project$Pages$ReportSettings$addPBITenant, model.session.token, model.session.loginType, selectedCustomerKey, selectedCustomerName, model.newPbiTenant.pbiTenantName, model.newPbiTenant.azureTenantId)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ReceiveAddPBITenant':
				var result = msg.a;
				var selectedCustomerKey = function () {
					var _v27 = model.selectedCustomer;
					if (_v27.$ === 'Just') {
						var c = _v27.a;
						return c.customerKey;
					} else {
						return '';
					}
				}();
				return _Utils_Tuple2(
					model,
					A3($author$project$Pages$ReportSettings$getPBITenants, model.session.token, model.session.loginType, selectedCustomerKey));
			case 'RequestSetSPN':
				var customerName = function () {
					var _v28 = model.selectedCustomer;
					if (_v28.$ === 'Just') {
						var c = _v28.a;
						return c.customerName;
					} else {
						return '';
					}
				}();
				return _Utils_Tuple2(
					model,
					$author$project$Pages$ReportSettings$setSPN(model));
			default:
				var result = msg.a;
				if (result.$ === 'Ok') {
					return A2($author$project$Pages$ReportSettings$update, $author$project$Pages$ReportSettings$RequestAddPBITenant, model);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$RoleManagement$NoOp = {$: 'NoOp'};
var $author$project$Pages$RoleManagement$RequestAddUserRole = F2(
	function (a, b) {
		return {$: 'RequestAddUserRole', a: a, b: b};
	});
var $author$project$Pages$RoleManagement$RequestRemoveUserRole = F2(
	function (a, b) {
		return {$: 'RequestRemoveUserRole', a: a, b: b};
	});
var $author$project$Pages$RoleManagement$ReceiveUpdate = function (a) {
	return {$: 'ReceiveUpdate', a: a};
};
var $author$project$API$Queries$addUserRole = F3(
	function (customerKey, userKey, roleKey) {
		return '\r\n        mutation {\r\n            adduserrole(input: \r\n                { Email: \"\"\r\n                , LoginType: \"\"\r\n                , CustomerKey: \"' + (customerKey + ('\"\r\n                , UserKey: \"' + (userKey + ('\"\r\n                , RoleKey: \"' + (roleKey + '\"}\r\n                ) {\r\n                    Error\r\n                }\r\n            }\r\n    ')))));
	});
var $author$project$Pages$RoleManagement$addUserRole = F5(
	function (token, loginType, customerKey, userKey, roleKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$addUserRole, customerKey, userKey, roleKey),
			$author$project$Pages$RoleManagement$ReceiveUpdate,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$API$Queries$removeUserRole = F3(
	function (customerKey, userKey, roleKey) {
		return '\r\n    mutation {\r\n        removeuserrole(input:\r\n            { Email: \"\"\r\n            , LoginType: \"\"\r\n            , CustomerKey: \"' + (customerKey + ('\"\r\n            , UserKey: \"' + (userKey + ('\"\r\n            , RoleKey: \"' + (roleKey + '\"}\r\n            ) {\r\n                Error\r\n            }\r\n        }\r\n    ')))));
	});
var $author$project$Pages$RoleManagement$removeUserRole = F5(
	function (token, loginType, customerKey, userKey, roleKey) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A3($author$project$API$Queries$removeUserRole, customerKey, userKey, roleKey),
			$author$project$Pages$RoleManagement$ReceiveUpdate,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$RoleManagement$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ToggleRoleSetting':
				var thisTableConfig = model.tableConfig;
				var switches = function () {
					var _v1 = A2($elm_community$list_extra$List$Extra$getAt, 0, thisTableConfig.switches);
					if (_v1.$ === 'Just') {
						var s = _v1.a;
						return _List_fromArray(
							[
								_Utils_update(
								s,
								{isChecked: !model.roleToggle})
							]);
					} else {
						return thisTableConfig.switches;
					}
				}();
				var updateTableConfig = _Utils_update(
					thisTableConfig,
					{switches: switches});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{roleToggle: !model.roleToggle, tableConfig: updateTableConfig}),
					$elm$core$Platform$Cmd$none);
			case 'AddUserRole':
				var user = msg.a;
				var role = msg.b;
				var newRoles = function () {
					if (user.$ === 'Just') {
						var u = user.a;
						var _v13 = model.selectedRole;
						if (_v13.$ === 'Just') {
							var r = _v13.a;
							return A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.roleName;
								},
								A2($elm$core$List$cons, r, u.roles));
						} else {
							return u.roles;
						}
					} else {
						var _v14 = model.selectedUser;
						if (_v14.$ === 'Just') {
							var u = _v14.a;
							if (role.$ === 'Just') {
								var r = role.a;
								return A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.roleName;
									},
									A2($elm$core$List$cons, r, u.roles));
							} else {
								return u.roles;
							}
						} else {
							return _List_Nil;
						}
					}
				}();
				var updatedUser = function () {
					if (user.$ === 'Just') {
						var u = user.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								u,
								{roles: newRoles}));
					} else {
						var _v11 = model.selectedUser;
						if (_v11.$ === 'Just') {
							var u = _v11.a;
							return $elm$core$Maybe$Just(
								_Utils_update(
									u,
									{roles: newRoles}));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}
				}();
				var updatedUsers = function () {
					var _v7 = model.users;
					if (_v7.$ === 'Success') {
						var users = _v7.a;
						if (updatedUser.$ === 'Just') {
							var user_ = updatedUser.a;
							var _v9 = A2(
								$elm$core$List$filter,
								function (u) {
									return _Utils_eq(u.userKey, user_.userKey);
								},
								users);
							if (_v9.b) {
								var x = _v9.a;
								var xs = _v9.b;
								return $author$project$Common$Types$Success(
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.userFullName;
										},
										A2(
											$elm$core$List$cons,
											_Utils_update(
												x,
												{roles: newRoles}),
											A2(
												$elm$core$List$filter,
												function (u) {
													return !_Utils_eq(u.userKey, user_.userKey);
												},
												users))));
							} else {
								return $author$project$Common$Types$Success(users);
							}
						} else {
							return model.users;
						}
					} else {
						return model.users;
					}
				}();
				var action = function () {
					var _v2 = _Utils_Tuple2(user, role);
					_v2$2:
					while (true) {
						if (_v2.a.$ === 'Nothing') {
							if (_v2.b.$ === 'Just') {
								var _v3 = _v2.a;
								var r = _v2.b.a;
								var _v4 = model.selectedUser;
								if (_v4.$ === 'Just') {
									var u = _v4.a;
									return A2($author$project$Pages$RoleManagement$RequestAddUserRole, u.userKey, r.roleKey);
								} else {
									return $author$project$Pages$RoleManagement$NoOp;
								}
							} else {
								break _v2$2;
							}
						} else {
							if (_v2.b.$ === 'Nothing') {
								var u = _v2.a.a;
								var _v5 = _v2.b;
								var _v6 = model.selectedRole;
								if (_v6.$ === 'Just') {
									var r = _v6.a;
									return A2($author$project$Pages$RoleManagement$RequestAddUserRole, u.userKey, r.roleKey);
								} else {
									return $author$project$Pages$RoleManagement$NoOp;
								}
							} else {
								break _v2$2;
							}
						}
					}
					return $author$project$Pages$RoleManagement$NoOp;
				}();
				return A2(
					$author$project$Pages$RoleManagement$update,
					action,
					_Utils_update(
						model,
						{selectedUser: updatedUser, users: updatedUsers}));
			case 'RemoveUserRole':
				var user = msg.a;
				var role = msg.b;
				var newRoles = function () {
					if (user.$ === 'Just') {
						var u = user.a;
						var _v27 = model.selectedRole;
						if (_v27.$ === 'Just') {
							var r = _v27.a;
							return A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.roleName;
								},
								A2(
									$elm$core$List$filter,
									function (r_) {
										return !_Utils_eq(r_, r);
									},
									u.roles));
						} else {
							return u.roles;
						}
					} else {
						var _v28 = model.selectedUser;
						if (_v28.$ === 'Just') {
							var u = _v28.a;
							return A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.roleName;
								},
								A2(
									$elm$core$List$filter,
									function (r) {
										return !_Utils_eq(
											$elm$core$Maybe$Just(r),
											role);
									},
									u.roles));
						} else {
							return _List_Nil;
						}
					}
				}();
				var updatedUser = function () {
					if (user.$ === 'Just') {
						var u = user.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								u,
								{roles: newRoles}));
					} else {
						var _v25 = model.selectedUser;
						if (_v25.$ === 'Just') {
							var u = _v25.a;
							return $elm$core$Maybe$Just(
								_Utils_update(
									u,
									{roles: newRoles}));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}
				}();
				var updatedUsers = function () {
					var _v21 = model.users;
					if (_v21.$ === 'Success') {
						var users = _v21.a;
						if (updatedUser.$ === 'Just') {
							var user_ = updatedUser.a;
							var _v23 = A2(
								$elm$core$List$filter,
								function (u) {
									return _Utils_eq(u.userKey, user_.userKey);
								},
								users);
							if (_v23.b) {
								var x = _v23.a;
								var xs = _v23.b;
								return $author$project$Common$Types$Success(
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.userFullName;
										},
										A2(
											$elm$core$List$cons,
											_Utils_update(
												x,
												{roles: newRoles}),
											A2(
												$elm$core$List$filter,
												function (u) {
													return !_Utils_eq(u.userKey, user_.userKey);
												},
												users))));
							} else {
								return $author$project$Common$Types$Success(users);
							}
						} else {
							return model.users;
						}
					} else {
						return model.users;
					}
				}();
				var action = function () {
					var _v16 = _Utils_Tuple2(user, role);
					_v16$2:
					while (true) {
						if (_v16.a.$ === 'Nothing') {
							if (_v16.b.$ === 'Just') {
								var _v17 = _v16.a;
								var r = _v16.b.a;
								var _v18 = model.selectedUser;
								if (_v18.$ === 'Just') {
									var u = _v18.a;
									return A2($author$project$Pages$RoleManagement$RequestRemoveUserRole, u.userKey, r.roleKey);
								} else {
									return $author$project$Pages$RoleManagement$NoOp;
								}
							} else {
								break _v16$2;
							}
						} else {
							if (_v16.b.$ === 'Nothing') {
								var u = _v16.a.a;
								var _v19 = _v16.b;
								var _v20 = model.selectedRole;
								if (_v20.$ === 'Just') {
									var r = _v20.a;
									return A2($author$project$Pages$RoleManagement$RequestRemoveUserRole, u.userKey, r.roleKey);
								} else {
									return $author$project$Pages$RoleManagement$NoOp;
								}
							} else {
								break _v16$2;
							}
						}
					}
					return $author$project$Pages$RoleManagement$NoOp;
				}();
				return A2(
					$author$project$Pages$RoleManagement$update,
					action,
					_Utils_update(
						model,
						{selectedUser: updatedUser, users: updatedUsers}));
			case 'SelectRole':
				var role = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							mobileView: $author$project$Common$Types$Selected,
							selectedRole: $elm$core$Maybe$Just(role)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SelectUser':
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							mobileView: $author$project$Common$Types$Selected,
							selectedUser: $elm$core$Maybe$Just(user)
						}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateSort':
				var columnIndex = msg.a;
				var updatedTableConfig = model.tableConfig;
				var updatedSort = function () {
					var _v29 = updatedTableConfig.sort;
					switch (_v29.$) {
						case 'Asc':
							var index = _v29.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Desc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						case 'Desc':
							var index = _v29.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Asc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						default:
							return $author$project$Elements$Table$Asc(columnIndex);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							tableConfig: _Utils_update(
								updatedTableConfig,
								{sort: updatedSort})
						}),
					$elm$core$Platform$Cmd$none);
			case 'SetUsers':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var users = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								users: $author$project$Common$Types$Success(users)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								users: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SetRoles':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var availableRoles = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								availableRoles: $author$project$Common$Types$Success(availableRoles)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								availableRoles: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'RequestAddUserRole':
				var userKey = msg.a;
				var roleKey = msg.b;
				return _Utils_Tuple2(
					model,
					A5($author$project$Pages$RoleManagement$addUserRole, model.session.token, model.session.loginType, model.session.customerKey, userKey, roleKey));
			case 'RequestRemoveUserRole':
				var userKey = msg.a;
				var roleKey = msg.b;
				return _Utils_Tuple2(
					model,
					A5($author$project$Pages$RoleManagement$removeUserRole, model.session.token, model.session.loginType, model.session.customerKey, userKey, roleKey));
			case 'ReceiveUpdate':
				var result = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'Search':
				var k = msg.a;
				var s = msg.b;
				var updateRecord = $elm$core$Maybe$map(
					function (x) {
						return $elm$core$Maybe$Just(s);
					});
				var _v32 = A2($elm$core$Dict$get, k, model.searches);
				if (_v32.$ === 'Just') {
					var s_ = _v32.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								searches: A3(
									$elm$core$Dict$update,
									k,
									function (_v33) {
										return $elm$core$Maybe$Just(s);
									},
									model.searches)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								searches: A3($elm$core$Dict$insert, k, s, model.searches)
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var _v34 = model.mobileView;
				if (_v34.$ === 'Selected') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Browse, selectedRole: $elm$core$Maybe$Nothing, selectedUser: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Selected}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Elements$Tree$Level = function (a) {
	return {$: 'Level', a: a};
};
var $author$project$Pages$RolePermissions$RolePermissionUpdate = F5(
	function (roleKey, rolePermissionLevelKey, serviceKey, applicationKey, tabKey) {
		return {applicationKey: applicationKey, roleKey: roleKey, rolePermissionLevelKey: rolePermissionLevelKey, serviceKey: serviceKey, tabKey: tabKey};
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Elements$Tree$flatten = function (tree) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var l = _v0.a;
				return ($elm$core$List$length(l.levels) > 0) ? A2(
					$elm$core$List$cons,
					$author$project$Elements$Tree$Level(l),
					$author$project$Elements$Tree$flatten(l.levels)) : A2(
					$elm$core$List$cons,
					$author$project$Elements$Tree$Level(l),
					l.levels);
			},
			tree));
};
var $author$project$Elements$Tree$isAllChecked = function (tree) {
	return A2(
		$elm$core$List$all,
		function (_v0) {
			var l = _v0.a;
			return l.isChecked;
		},
		$author$project$Elements$Tree$flatten(tree));
};
var $author$project$Pages$RolePermissions$createRolePermissionUpdates = F2(
	function (role, tree) {
		createRolePermissionUpdates:
		while (true) {
			if (tree.b) {
				var l = tree.a.a;
				var ls = tree.b;
				if (l.isChecked && $author$project$Elements$Tree$isAllChecked(l.levels)) {
					return A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A5($author$project$Pages$RolePermissions$RolePermissionUpdate, role.roleKey, role.rolePermissionLevelKey, l.key, '', '')
							]),
						A2($author$project$Pages$RolePermissions$createRolePermissionUpdates, role, ls));
				} else {
					if (l.isChecked) {
						return A2(
							$elm$core$List$append,
							A2($author$project$Pages$RolePermissions$createRolePermissionUpdates, role, ls),
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									function (_v1) {
										var x = _v1.a;
										return (x.isChecked && $author$project$Elements$Tree$isAllChecked(x.levels)) ? _List_fromArray(
											[
												A5($author$project$Pages$RolePermissions$RolePermissionUpdate, role.roleKey, role.rolePermissionLevelKey, l.key, x.key, '')
											]) : (x.isChecked ? $elm$core$List$concat(
											A2(
												$elm$core$List$map,
												function (_v2) {
													var y = _v2.a;
													return y.isChecked ? _List_fromArray(
														[
															A5($author$project$Pages$RolePermissions$RolePermissionUpdate, role.roleKey, role.rolePermissionLevelKey, l.key, x.key, y.key)
														]) : _List_Nil;
												},
												x.levels)) : _List_Nil);
									},
									l.levels)));
					} else {
						var $temp$role = role,
							$temp$tree = ls;
						role = $temp$role;
						tree = $temp$tree;
						continue createRolePermissionUpdates;
					}
				}
			} else {
				return _List_Nil;
			}
		}
	});
var $author$project$Elements$Tree$levelContainsChecked = function (_v1) {
	var level = _v1.a;
	var _v2 = level.levels;
	if (_v2.b) {
		var l = _v2.a.a;
		var ls = _v2.b;
		return l.isChecked || $author$project$Elements$Tree$treeContainsChecked(ls);
	} else {
		return false;
	}
};
var $author$project$Elements$Tree$treeContainsChecked = function (tree) {
	if (tree.b) {
		var l = tree.a.a;
		var ls = tree.b;
		return l.isChecked || ($author$project$Elements$Tree$treeContainsChecked(ls) || $author$project$Elements$Tree$levelContainsChecked(
			$author$project$Elements$Tree$Level(l)));
	} else {
		return false;
	}
};
var $author$project$Elements$Tree$updateAllChecked = function (tree) {
	if (tree.b) {
		var l = tree.a.a;
		var ls = tree.b;
		return A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					l,
					{
						isChecked: true,
						levels: $author$project$Elements$Tree$updateAllChecked(l.levels)
					})),
			$author$project$Elements$Tree$updateAllChecked(ls));
	} else {
		return _List_Nil;
	}
};
var $author$project$Elements$Tree$checkChildrenIfParentIsChecked = function (tree) {
	if (tree.b) {
		var l = tree.a.a;
		var ls = tree.b;
		return (l.isChecked && (!$author$project$Elements$Tree$treeContainsChecked(l.levels))) ? A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					l,
					{
						levels: $author$project$Elements$Tree$updateAllChecked(l.levels)
					})),
			$author$project$Elements$Tree$checkChildrenIfParentIsChecked(ls)) : A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					l,
					{
						levels: $author$project$Elements$Tree$checkChildrenIfParentIsChecked(l.levels)
					})),
			$author$project$Elements$Tree$checkChildrenIfParentIsChecked(ls));
	} else {
		return _List_Nil;
	}
};
var $author$project$Elements$Tree$createLevel = F6(
	function (key, name, sort, isChecked, isCollapsed, levels) {
		return $author$project$Elements$Tree$Level(
			{isChecked: isChecked, isCollapsed: isCollapsed, key: key, levels: levels, name: name, sort: sort});
	});
var $author$project$Pages$RolePermissions$getAllApplicationKeys = function (services) {
	if (services.b) {
		var x = services.a;
		var xs = services.b;
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (a) {
					return a.applicationKey;
				},
				x.applications),
			$author$project$Pages$RolePermissions$getAllApplicationKeys(xs));
	} else {
		return _List_Nil;
	}
};
var $author$project$Pages$RolePermissions$getAllServiceKeys = function (services) {
	if (services.b) {
		var x = services.a;
		var xs = services.b;
		return A2(
			$elm$core$List$cons,
			x.serviceKey,
			$author$project$Pages$RolePermissions$getAllServiceKeys(xs));
	} else {
		return _List_Nil;
	}
};
var $author$project$Pages$RolePermissions$getAllTabKeys = function (services) {
	var tabs = $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (s) {
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						function (a) {
							return A2(
								$elm$core$List$map,
								function (t) {
									return t.tabKey;
								},
								a.tabs);
						},
						s.applications));
			},
			services));
	return tabs;
};
var $author$project$Pages$RolePermissions$createTree = F2(
	function (services, rolePermission) {
		var rpTabs = $author$project$Pages$RolePermissions$getAllTabKeys(rolePermission.services);
		var rpServices = $author$project$Pages$RolePermissions$getAllServiceKeys(rolePermission.services);
		var rpApplications = $author$project$Pages$RolePermissions$getAllApplicationKeys(rolePermission.services);
		var tree = A2(
			$elm$core$List$map,
			function (s) {
				return A6(
					$author$project$Elements$Tree$createLevel,
					s.serviceKey,
					s.serviceName,
					s.serviceSortKey,
					A2($elm$core$List$member, s.serviceKey, rpServices),
					false,
					A2(
						$elm$core$List$map,
						function (a) {
							return A6(
								$author$project$Elements$Tree$createLevel,
								a.applicationKey,
								a.applicationName,
								a.applicationSortKey,
								A2($elm$core$List$member, a.applicationKey, rpApplications),
								false,
								A2(
									$elm$core$List$map,
									function (t) {
										return A6(
											$author$project$Elements$Tree$createLevel,
											t.tabKey,
											t.tabName,
											t.tabSortKey,
											A2($elm$core$List$member, t.tabKey, rpTabs),
											false,
											_List_Nil);
									},
									a.tabs));
						},
						s.applications));
			},
			services);
		var treeWithChildrenChecked = $author$project$Elements$Tree$checkChildrenIfParentIsChecked(tree);
		return treeWithChildrenChecked;
	});
var $author$project$Pages$RolePermissions$filterEmpty = F2(
	function (attribute, list) {
		return A2(
			$elm$core$List$filter,
			function (l) {
				return attribute(l) !== '';
			},
			list);
	});
var $author$project$Pages$RolePermissions$filterEmptyRolePermissions = function (rolePermissions) {
	if (rolePermissions.b) {
		var rp = rolePermissions.a;
		var rps = rolePermissions.b;
		return A2(
			$elm$core$List$cons,
			_Utils_update(
				rp,
				{
					services: A2(
						$elm$core$List$map,
						function (s) {
							return _Utils_update(
								s,
								{
									applications: A2(
										$elm$core$List$map,
										function (a) {
											return _Utils_update(
												a,
												{
													tabs: A2(
														$author$project$Pages$RolePermissions$filterEmpty,
														function ($) {
															return $.tabKey;
														},
														a.tabs)
												});
										},
										A2(
											$author$project$Pages$RolePermissions$filterEmpty,
											function ($) {
												return $.applicationKey;
											},
											s.applications))
								});
						},
						rp.services)
				}),
			$author$project$Pages$RolePermissions$filterEmptyRolePermissions(rps));
	} else {
		return _List_Nil;
	}
};
var $author$project$Elements$Tree$replaceLevelInTree = F2(
	function (_v0, tree) {
		var level = _v0.a;
		if (tree.b) {
			var l = tree.a.a;
			var ls = tree.b;
			return _Utils_eq(l.key, level.key) ? A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(level),
				ls) : A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							levels: A2(
								$author$project$Elements$Tree$replaceLevelInTree,
								$author$project$Elements$Tree$Level(level),
								l.levels)
						})),
				A2(
					$author$project$Elements$Tree$replaceLevelInTree,
					$author$project$Elements$Tree$Level(level),
					ls));
		} else {
			return tree;
		}
	});
var $author$project$Elements$Tree$updateCollapseStateAll = F2(
	function (state, tree) {
		if (tree.b) {
			var l = tree.a.a;
			var ls = tree.b;
			return A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							isCollapsed: state,
							levels: A2($author$project$Elements$Tree$updateCollapseStateAll, state, l.levels)
						})),
				A2($author$project$Elements$Tree$updateCollapseStateAll, state, ls));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Elements$Tree$updateCollapseStateAt = F3(
	function (state, level, tree) {
		var _v0 = _Utils_Tuple2(
			A2($elm$core$Basics$compare, level, 0),
			tree);
		if (_v0.b.b) {
			switch (_v0.a.$) {
				case 'EQ':
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					var l = _v2.a.a;
					var ls = _v2.b;
					return A2(
						$elm$core$List$cons,
						$author$project$Elements$Tree$Level(
							_Utils_update(
								l,
								{
									isCollapsed: state,
									levels: A3($author$project$Elements$Tree$updateCollapseStateAt, state, level - 1, l.levels)
								})),
						A3($author$project$Elements$Tree$updateCollapseStateAt, state, level, ls));
				case 'LT':
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					var l = _v4.a.a;
					var ls = _v4.b;
					return A2(
						$elm$core$List$cons,
						$author$project$Elements$Tree$Level(
							_Utils_update(
								l,
								{
									isCollapsed: !state,
									levels: A3($author$project$Elements$Tree$updateCollapseStateAt, state, level - 1, l.levels)
								})),
						A3($author$project$Elements$Tree$updateCollapseStateAt, state, level, ls));
				default:
					var _v5 = _v0.a;
					var _v6 = _v0.b;
					var l = _v6.a.a;
					var ls = _v6.b;
					return A2(
						$elm$core$List$cons,
						$author$project$Elements$Tree$Level(
							_Utils_update(
								l,
								{
									isCollapsed: state,
									levels: A3($author$project$Elements$Tree$updateCollapseStateAt, state, level - 1, l.levels)
								})),
						A3($author$project$Elements$Tree$updateCollapseStateAt, state, level, ls));
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$RolePermissions$ReceiveUpdatePermission = function (a) {
	return {$: 'ReceiveUpdatePermission', a: a};
};
var $author$project$Pages$RolePermissions$createGraphQLObject = function (dict) {
	var list = $elm$core$Dict$toList(dict);
	var propertiesAndValues = A2(
		$elm$core$String$join,
		', ',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var k = _v0.a;
				var v = _v0.b;
				return k + (': \"' + (v + '\"'));
			},
			list));
	return '{' + (propertiesAndValues + '}');
};
var $author$project$Pages$RolePermissions$createGraphQLObjects = F2(
	function (objects, mapping) {
		var objectsString = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				function (o) {
					return $author$project$Pages$RolePermissions$createGraphQLObject(
						mapping(o));
				},
				objects));
		return '[' + (objectsString + ']');
	});
var $author$project$Pages$RolePermissions$rolePermissionUpdateDictMap = function (rpu) {
	var dict = $elm$core$Dict$empty;
	return A3(
		$elm$core$Dict$insert,
		'TabKey',
		rpu.tabKey,
		A3(
			$elm$core$Dict$insert,
			'ApplicationKey',
			rpu.applicationKey,
			A3(
				$elm$core$Dict$insert,
				'ServiceKey',
				rpu.serviceKey,
				A3(
					$elm$core$Dict$insert,
					'RolePermissionLevelKey',
					rpu.rolePermissionLevelKey,
					A3($elm$core$Dict$insert, 'RoleKey', rpu.roleKey, dict)))));
};
var $author$project$API$Queries$updateCustomerRolePermissions = F2(
	function (customerKey, updates) {
		return '\r\n    mutation {\r\n        UpdateRolePermissions(CustomerKey: \"' + (customerKey + ('\", RolePermissionUpdates: ' + (updates + ') {\r\n            Error\r\n        }\r\n    }\r\n    ')));
	});
var $author$project$Pages$RolePermissions$updateRolePermission = F4(
	function (token, loginType, customerKey, rpus) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2(
				$author$project$API$Queries$updateCustomerRolePermissions,
				customerKey,
				A2($author$project$Pages$RolePermissions$createGraphQLObjects, rpus, $author$project$Pages$RolePermissions$rolePermissionUpdateDictMap)),
			$author$project$Pages$RolePermissions$ReceiveUpdatePermission,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Elements$Tree$updateTreeCheckedInKeyList = F2(
	function (keys, tree) {
		if (tree.b) {
			var l = tree.a.a;
			var ls = tree.b;
			return A2($elm$core$List$member, l.key, keys) ? A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							isChecked: true,
							levels: A2($author$project$Elements$Tree$updateTreeCheckedInKeyList, keys, l.levels)
						})),
				A2($author$project$Elements$Tree$updateTreeCheckedInKeyList, keys, ls)) : A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							isChecked: false,
							levels: A2($author$project$Elements$Tree$updateTreeCheckedInKeyList, keys, l.levels)
						})),
				A2($author$project$Elements$Tree$updateTreeCheckedInKeyList, keys, ls));
		} else {
			return _List_Nil;
		}
	});
var $author$project$Pages$RolePermissions$updateTreePermissions = F2(
	function (tree, rolePermission) {
		var rpTabs = $author$project$Pages$RolePermissions$getAllTabKeys(rolePermission.services);
		var rpServices = $author$project$Pages$RolePermissions$getAllServiceKeys(rolePermission.services);
		var rpApplications = $author$project$Pages$RolePermissions$getAllApplicationKeys(rolePermission.services);
		var allKeys = A2(
			$elm$core$List$append,
			rpTabs,
			A2($elm$core$List$append, rpServices, rpApplications));
		var updatedTreePermissions = A2($author$project$Elements$Tree$updateTreeCheckedInKeyList, allKeys, tree);
		var updateTreePermissionsChecked = $author$project$Elements$Tree$checkChildrenIfParentIsChecked(updatedTreePermissions);
		return updateTreePermissionsChecked;
	});
var $author$project$Pages$RolePermissions$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ToggleCollapse':
				var key = msg.a;
				var updatedCollapsibles = function () {
					var _v1 = A2($elm$core$Dict$get, key, model.collapsibles);
					if (_v1.$ === 'Nothing') {
						return A3($elm$core$Dict$insert, key, true, model.collapsibles);
					} else {
						var b = _v1.a;
						return A3($elm$core$Dict$insert, key, !b, model.collapsibles);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{collapsibles: updatedCollapsibles}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateCollapseAll':
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							initialTree: A2($author$project$Elements$Tree$updateCollapseStateAll, state, model.initialTree),
							tree: A2($author$project$Elements$Tree$updateCollapseStateAll, state, model.tree)
						}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateCollapseAt':
				var level = msg.a;
				var state = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							initialTree: A3($author$project$Elements$Tree$updateCollapseStateAt, state, level, model.initialTree),
							tree: A3($author$project$Elements$Tree$updateCollapseStateAt, state, level, model.tree)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SetRolePermissions':
				var result = msg.a;
				if (result.$ === 'Err') {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								rolePermissions: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var rps = result.a;
					var filteredRps = $author$project$Pages$RolePermissions$filterEmptyRolePermissions(rps);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								modifiedRolePermissions: $author$project$Common$Types$Success(filteredRps),
								rolePermissions: $author$project$Common$Types$Success(filteredRps)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SetServices':
				var result = msg.a;
				if (result.$ === 'Err') {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								services: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var s = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								services: $author$project$Common$Types$Success(s)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'SelectRole':
				var rolePermission = msg.a;
				var tree = function () {
					var _v4 = _Utils_Tuple2(model.tree, model.services);
					if ((!_v4.a.b) && (_v4.b.$ === 'Success')) {
						var s = _v4.b.a;
						return A2($author$project$Pages$RolePermissions$createTree, s, rolePermission);
					} else {
						var currentTree = _v4.a;
						return A2($author$project$Pages$RolePermissions$updateTreePermissions, currentTree, rolePermission);
					}
				}();
				var selectedServices = $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (s) {
							return _Utils_Tuple2(s.serviceKey, true);
						},
						rolePermission.services));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							initialTree: tree,
							mobileView: $author$project$Common$Types$Selected,
							selectedRolePermission: $elm$core$Maybe$Just(rolePermission),
							selectedServices: selectedServices,
							tree: tree
						}),
					$elm$core$Platform$Cmd$none);
			case 'ToggleTreeChecked':
				var tree = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{tree: tree}),
					$elm$core$Platform$Cmd$none);
			case 'ToggleTreeCollapsed':
				var tree = msg.a;
				var level = msg.b.a;
				var updatedInitialTree = A2(
					$author$project$Elements$Tree$replaceLevelInTree,
					$author$project$Elements$Tree$Level(
						_Utils_update(
							level,
							{isCollapsed: !level.isCollapsed})),
					model.initialTree);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{initialTree: updatedInitialTree, tree: tree}),
					$elm$core$Platform$Cmd$none);
			case 'SaveRolePermissions':
				var tree = msg.a;
				var _v5 = model.selectedRolePermission;
				if (_v5.$ === 'Just') {
					var rp = _v5.a;
					return _Utils_Tuple2(
						model,
						A4(
							$author$project$Pages$RolePermissions$updateRolePermission,
							model.session.token,
							model.session.loginType,
							model.session.customerKey,
							A2($author$project$Pages$RolePermissions$createRolePermissionUpdates, rp, tree)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveUpdatePermission':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var _v6 = model.mobileView;
				if (_v6.$ === 'Selected') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Browse, selectedRolePermission: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Selected}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$UserInfo$initNewUser = $author$project$Common$Types$User('')(_List_Nil)('')('')('WORK_OR_SCHOOL_ACCOUNT')('')('')('')('')('')('')('')('')('');
var $author$project$Pages$UserInfo$ReceiveUpdate = function (a) {
	return {$: 'ReceiveUpdate', a: a};
};
var $author$project$API$Queries$updateUser = F2(
	function (customerkey, user) {
		return '\r\n    mutation{\r\n        updateuser(input:\r\n            {\r\n                Email: \"\",\r\n                LoginType: \"\",\r\n                CustomerKey: \"' + (customerkey + ('\",\r\n                UserKey: \"' + (user.userKey + ('\",\r\n                UserName: \"' + (user.userFullName + ('\",\r\n                UserEmail: \"' + (user.userEmail + ('\",\r\n                UserLoginType: \"' + (user.userLoginType + ('\",\r\n                UserEnabled: ' + ('1' + (',\r\n                UserWorkPhone: \"' + (user.userWorkPhone + ('\",\r\n                UserMobilePhone: \"' + (user.userMobilePhone + '\"\r\n            }\r\n        ) {\r\n            Error\r\n        }\r\n    }\r\n    ')))))))))))))));
	});
var $author$project$Pages$UserInfo$updateUser = F4(
	function (token, loginType, customerKey, user) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$updateUser, customerKey, user),
			$author$project$Pages$UserInfo$ReceiveUpdate,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $author$project$Pages$UserInfo$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'SelectUser':
				var user = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							mobileView: $author$project$Common$Types$Selected,
							selectedUser: $elm$core$Maybe$Just(user)
						}),
					$elm$core$Platform$Cmd$none);
			case 'RequestUpdateUser':
				var user = msg.a;
				return _Utils_Tuple2(
					model,
					A4($author$project$Pages$UserInfo$updateUser, model.session.token, model.session.loginType, model.session.customerKey, user));
			case 'ReceiveUpdateUser':
				var res = msg.a;
				var newUserList = function () {
					var _v2 = model.selectedUser;
					if (_v2.$ === 'Just') {
						var u = _v2.a;
						var _v3 = model.users;
						if (_v3.$ === 'Success') {
							var us = _v3.a;
							var _v4 = A2(
								$elm$core$List$filter,
								function (x) {
									return !_Utils_eq(x.userKey, u.userKey);
								},
								us);
							var list = _v4;
							return $author$project$Common$Types$Success(
								A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.userFullName;
									},
									A2($elm$core$List$cons, u, list)));
						} else {
							return model.users;
						}
					} else {
						return model.users;
					}
				}();
				if (res.$ === 'Ok') {
					var val = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{users: newUserList}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UpdateUser':
				var n = msg.a;
				var val = msg.b;
				var currentUser = function () {
					var _v8 = model.selectedUser;
					if (_v8.$ === 'Just') {
						var u = _v8.a;
						return u;
					} else {
						return $author$project$Pages$UserInfo$initNewUser;
					}
				}();
				var user_ = function () {
					switch (n) {
						case 0:
							return _Utils_update(
								currentUser,
								{userFullName: val});
						case 1:
							return _Utils_update(
								currentUser,
								{userEmail: val});
						case 2:
							var _v6 = $elm$core$String$length(val);
							if (_v6 === 10) {
								return _Utils_update(
									currentUser,
									{userWorkPhone: val});
							} else {
								return _Utils_update(
									currentUser,
									{userWorkPhone: val});
							}
						case 3:
							var _v7 = $elm$core$String$length(val);
							if (_v7 === 10) {
								return _Utils_update(
									currentUser,
									{userMobilePhone: val});
							} else {
								return _Utils_update(
									currentUser,
									{userMobilePhone: val});
							}
						case 4:
							return _Utils_update(
								currentUser,
								{userLoginType: val});
						default:
							return currentUser;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							selectedUser: $elm$core$Maybe$Just(user_)
						}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateSort':
				var columnIndex = msg.a;
				var updatedTableConfig = model.tableConfig;
				var updatedSort = function () {
					var _v9 = updatedTableConfig.sort;
					switch (_v9.$) {
						case 'Asc':
							var index = _v9.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Desc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						case 'Desc':
							var index = _v9.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Asc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						default:
							return $author$project$Elements$Table$Asc(columnIndex);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							tableConfig: _Utils_update(
								updatedTableConfig,
								{sort: updatedSort})
						}),
					$elm$core$Platform$Cmd$none);
			case 'SetUsers':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var users = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								mobileView: $author$project$Common$Types$Browse,
								selectedUser: model.session.isMobile ? $elm$core$Maybe$Nothing : $elm$core$List$head(users),
								users: $author$project$Common$Types$Success(users)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								users: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ReceiveUpdate':
				var result = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'Search':
				var k = msg.a;
				var s = msg.b;
				var updateRecord = $elm$core$Maybe$map(
					function (x) {
						return $elm$core$Maybe$Just(s);
					});
				var _v11 = A2($elm$core$Dict$get, k, model.searches);
				if (_v11.$ === 'Just') {
					var s_ = _v11.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								searches: A3(
									$elm$core$Dict$update,
									k,
									function (_v12) {
										return $elm$core$Maybe$Just(s);
									},
									model.searches)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								searches: A3($elm$core$Dict$insert, k, s, model.searches)
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var _v13 = model.mobileView;
				if (_v13.$ === 'Selected') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Browse}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mobileView: $author$project$Common$Types$Selected}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Pages$UserManagement$CsvLoaded = function (a) {
	return {$: 'CsvLoaded', a: a};
};
var $author$project$Pages$UserManagement$CsvSelected = function (a) {
	return {$: 'CsvSelected', a: a};
};
var $author$project$Pages$UserManagement$RequestSaveUsers = {$: 'RequestSaveUsers'};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{nodeList: nodeList, nodeListSize: nodeListSize, tail: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (_v0.$ === 'SubTree') {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $author$project$Common$Helpers$parseCsv = function (list) {
	var list_ = _List_Nil;
	var get_ = F2(
		function (n, l) {
			var _v2 = A2(
				$elm$core$Array$get,
				n,
				$elm$core$Array$fromList(l));
			if (_v2.$ === 'Just') {
				var s = _v2.a;
				return s;
			} else {
				return '';
			}
		});
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		var _v1 = A2($elm$core$String$split, ';', x);
		var y = _v1;
		return A2(
			$elm$core$List$cons,
			$author$project$Common$Types$User('')(_List_Nil)('')(
				A2(get_, 0, y))(
				A2(get_, 1, y))(
				A2(get_, 2, y))(
				A2(get_, 3, y))(
				A2(get_, 4, y))('')('')('')('')('')(''),
			$author$project$Common$Helpers$parseCsv(xs));
	} else {
		return _List_Nil;
	}
};
var $author$project$Pages$UserManagement$ReceiveUpdate = function (a) {
	return {$: 'ReceiveUpdate', a: a};
};
var $author$project$API$Queries$saveUser = F2(
	function (customerKey, user) {
		return '\r\n        mutation {\r\n            addnewuser(input:\r\n                { Email: \"\"\r\n                , LoginType: \"\"\r\n                , CustomerKey: \"' + (customerKey + ('\"\r\n                , UserName: \"' + (user.userFullName + ('\"\r\n                , UserEmail: \"' + (user.userEmail + ('\"\r\n                , UserLoginType: \"' + (user.userLoginType + ('\"\r\n                , UserEnabled: ' + ('1' + ('\r\n                , UserWorkPhone: \"' + (user.userWorkPhone + ('\"\r\n                , UserMobilePhone: \"' + (user.userMobilePhone + ('\"\r\n                , Issuer: \"' + (user.issuer + '\"\r\n                }\r\n            ) {\r\n                Error\r\n            }\r\n        }\r\n    ')))))))))))))));
	});
var $author$project$Pages$UserManagement$saveUser = F4(
	function (token, loginType, customerKey, user) {
		return A5(
			$author$project$API$API$requestGraphQL,
			token,
			loginType,
			A2($author$project$API$Queries$saveUser, customerKey, user),
			$author$project$Pages$UserManagement$ReceiveUpdate,
			$author$project$API$Decoder$decodeMutationResponse);
	});
var $elm$file$File$toString = _File_toString;
var $author$project$Common$Format$rxEmail = '.*\\@\\w+\\.\\w+';
var $author$project$Common$Format$verifyUser = function (user) {
	return A2($author$project$Common$Format$match, $author$project$Common$Format$rxEmail, user.userEmail) && ((user.userLoginType !== '') && (user.userFullName !== ''));
};
var $Janiczek$cmd_extra$Cmd$Extra$withNoCmd = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Pages$UserManagement$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'RequestSaveUsers':
				var updateAllUsers = A2(
					$elm$core$List$map,
					function (u) {
						return A4($author$project$Pages$UserManagement$saveUser, model.session.token, model.session.loginType, model.session.customerKey, u);
					},
					model.newUsers);
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(updateAllUsers));
			case 'DiscardAddedUsers':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{newUsers: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			case 'SetUsers':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var users = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								users: $author$project$Common$Types$Success(users)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								users: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'AddUser':
				var verif = model.userVerification;
				var users = model.newUsers;
				var completeUserList = function () {
					var _v4 = model.users;
					if (_v4.$ === 'Success') {
						var users_ = _v4.a;
						return A2($elm$core$List$append, users_, users);
					} else {
						return _List_Nil;
					}
				}();
				var commandsToRunOnMobile = $author$project$Pages$UserManagement$update($author$project$Pages$UserManagement$RequestSaveUsers);
				var _v2 = model.newUser;
				if (_v2.$ === 'Just') {
					var u = _v2.a;
					var _v3 = _Utils_Tuple2(
						$author$project$Common$Format$verifyUser(u),
						A2(
							$elm$core$List$filter,
							function (x) {
								return _Utils_eq(x.userEmail, u.userEmail) && _Utils_eq(x.userLoginType, u.userLoginType);
							},
							completeUserList));
					if (_v3.a) {
						if (!_v3.b.b) {
							return (model.session.isMobile ? commandsToRunOnMobile : $Janiczek$cmd_extra$Cmd$Extra$withNoCmd)(
								_Utils_update(
									model,
									{
										newUsers: A2($elm$core$List$cons, u, users)
									}));
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										userVerification: _Utils_update(
											verif,
											{userExists: true})
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UpdateUser':
				var n = msg.a;
				var val = msg.b;
				var currentUser = function () {
					var _v8 = model.newUser;
					if (_v8.$ === 'Just') {
						var u = _v8.a;
						return u;
					} else {
						return $author$project$Pages$UserManagement$initNewUser(model.customer);
					}
				}();
				var newUser = function () {
					switch (n) {
						case 0:
							return _Utils_update(
								currentUser,
								{userFullName: val});
						case 1:
							return _Utils_update(
								currentUser,
								{userEmail: val});
						case 2:
							var _v6 = $elm$core$String$length(val);
							if (_v6 === 10) {
								return _Utils_update(
									currentUser,
									{userWorkPhone: val});
							} else {
								return _Utils_update(
									currentUser,
									{userWorkPhone: val});
							}
						case 3:
							var _v7 = $elm$core$String$length(val);
							if (_v7 === 10) {
								return _Utils_update(
									currentUser,
									{userMobilePhone: val});
							} else {
								return _Utils_update(
									currentUser,
									{userMobilePhone: val});
							}
						case 4:
							return _Utils_update(
								currentUser,
								{userLoginType: val});
						case 5:
							return _Utils_update(
								currentUser,
								{issuer: val});
						default:
							return currentUser;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							newUser: $elm$core$Maybe$Just(newUser)
						}),
					$elm$core$Platform$Cmd$none);
			case 'CsvRequested':
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/csv']),
						$author$project$Pages$UserManagement$CsvSelected));
			case 'CsvSelected':
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$UserManagement$CsvLoaded,
						$elm$file$File$toString(file)));
			case 'GotFiles':
				var file = msg.a;
				var files = msg.b;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Pages$UserManagement$CsvLoaded,
						$elm$file$File$toString(file)));
			case 'CsvLoaded':
				var content = msg.a;
				var parsedUsers = $author$project$Common$Helpers$parseCsv(
					A2($elm$core$String$split, '\r\n', content));
				var verifiedUsers = A2($elm$core$List$filter, $author$project$Common$Format$verifyUser, parsedUsers);
				var numberOfInvalidUsers = $elm$core$List$length(parsedUsers) - $elm$core$List$length(verifiedUsers);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							newUsers: A2($elm$core$List$append, model.newUsers, verifiedUsers)
						}),
					$elm$core$Platform$Cmd$none);
			case 'DragEnter':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'DragLeave':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'ReceiveUpdate':
				var result = msg.a;
				var newUserList = function () {
					if (result.$ === 'Ok') {
						var val = result.a;
						var _v10 = model.users;
						if (_v10.$ === 'Success') {
							var u = _v10.a;
							return $author$project$Common$Types$Success(
								A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.userFullName;
									},
									A2($elm$core$List$append, model.newUsers, u)));
						} else {
							return model.users;
						}
					} else {
						return model.users;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{users: newUserList}),
					$elm$core$Platform$Cmd$none);
			default:
				var columnIndex = msg.a;
				var updatedTableConfig = model.tableConfig;
				var updatedSort = function () {
					var _v11 = updatedTableConfig.sort;
					switch (_v11.$) {
						case 'Asc':
							var index = _v11.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Desc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						case 'Desc':
							var index = _v11.a;
							return _Utils_eq(index, columnIndex) ? $author$project$Elements$Table$Asc(columnIndex) : $author$project$Elements$Table$Asc(columnIndex);
						default:
							return $author$project$Elements$Table$Asc(columnIndex);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							tableConfig: _Utils_update(
								updatedTableConfig,
								{sort: updatedSort})
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Ports$AzureAD$updateAuthContext = _Platform_outgoingPort(
	'updateAuthContext',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Main$updatePageToken = F2(
	function (model, ac) {
		var token = ac.idToken;
		var selectedCustomerKey = function () {
			var _v1 = model.drawer.selectedCustomer;
			if (_v1.$ === 'Just') {
				var customer = _v1.a;
				return customer.customerKey;
			} else {
				return '';
			}
		}();
		var session = {customerKey: selectedCustomerKey, isCustomerAdmin: model.isCustomerAdmin, isMobile: model.flags.isMobile, loginType: model.flags.cfgLoginType, token: token};
		var _v0 = model.page;
		switch (_v0.$) {
			case 'Home':
				var page = model.homepage;
				return _Utils_update(
					model,
					{
						homepage: _Utils_update(
							page,
							{session: session})
					});
			case 'HomeEdit':
				var page = model.homeeditpage;
				return _Utils_update(
					model,
					{
						homeeditpage: _Utils_update(
							page,
							{session: session})
					});
			case 'Roles':
				var page = model.rolepage;
				return _Utils_update(
					model,
					{
						rolepage: _Utils_update(
							page,
							{session: session})
					});
			case 'Reaact':
				var page = model.reaactpage;
				return _Utils_update(
					model,
					{
						reaactpage: _Utils_update(
							page,
							{session: session})
					});
			case 'UserManagement':
				var page = model.userpage;
				return _Utils_update(
					model,
					{
						userpage: _Utils_update(
							page,
							{session: session})
					});
			case 'UserInfo':
				var page = model.userinfopage;
				return _Utils_update(
					model,
					{
						userinfopage: _Utils_update(
							page,
							{session: session})
					});
			case 'Branding':
				var page = model.brandingpage;
				return _Utils_update(
					model,
					{
						brandingpage: _Utils_update(
							page,
							{session: session})
					});
			case 'Customers':
				var page = model.customerspage;
				return _Utils_update(
					model,
					{
						customerspage: _Utils_update(
							page,
							{session: session})
					});
			case 'RolePermissions':
				var page = model.rppage;
				return _Utils_update(
					model,
					{
						rppage: _Utils_update(
							page,
							{session: session})
					});
			case 'CustomerSubscription':
				var page = model.subspage;
				return _Utils_update(
					model,
					{
						subspage: _Utils_update(
							page,
							{session: session})
					});
			case 'ApplicationRegistration':
				var page = model.appregpage;
				return _Utils_update(
					model,
					{
						appregpage: _Utils_update(
							page,
							{session: session})
					});
			case 'Document':
				var page = model.docpage;
				return _Utils_update(
					model,
					{
						docpage: _Utils_update(
							page,
							{session: session})
					});
			default:
				return model;
		}
	});
var $Janiczek$cmd_extra$Cmd$Extra$withCmd = F2(
	function (cmd, model) {
		return _Utils_Tuple2(model, cmd);
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'NoOp':
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 'LinkClicked':
				var urlRequest = msg.a;
				var authUrl = _Utils_ap(
					model.flags.cfgAuthUrl,
					_Utils_ap(
						$author$project$Config$configuration.aadLoginUri,
						$author$project$Common$Helpers$myUrlEncode(model.flags.cfgBaseUrl)));
				if (urlRequest.$ === 'Internal') {
					var url = urlRequest.a;
					var _v6 = _Utils_eq(
						$elm$url$Url$toString(url),
						authUrl);
					if (_v6) {
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(
								$elm$url$Url$toString(url)));
					} else {
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.key,
								$elm$url$Url$toString(url)));
					}
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 'UrlChanged':
				var url = msg.a;
				var urlString = $elm$url$Url$toString(url);
				var route = $author$project$Pages$Pages$toRoute(urlString);
				return A3(
					$author$project$Common$Helpers$andThen,
					$author$project$Main$update,
					$author$project$Messages$InitPage,
					A2(
						$author$project$Main$updateApplicationAndTabs,
						url,
						_Utils_update(
							model,
							{page: route, url: url})));
			case 'CheckAuthContext':
				return _Utils_Tuple2(
					model,
					$author$project$Ports$AzureAD$updateAuthContext(_Utils_Tuple0));
			case 'UpdateSession':
				var _v7 = model.authContext;
				if (_v7.$ === 'Success') {
					var authContext = _v7.a;
					return _Utils_Tuple2(
						A2($author$project$Main$updatePageToken, model, authContext),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveUpdatedToken':
				var authContext = msg.a;
				var updatedModel = A2($author$project$Main$updatePageToken, model, authContext);
				return _Utils_Tuple2(
					_Utils_update(
						updatedModel,
						{
							authContext: $author$project$Common$Types$Success(authContext),
							userContext: $author$project$Common$Helpers$authContextToUserContext_(authContext)
						}),
					$elm$core$Platform$Cmd$none);
			case 'SetStorage':
				var state = msg.a;
				return A2(
					$Janiczek$cmd_extra$Cmd$Extra$withCmd,
					$author$project$Ports$LocalStorage$setState(state),
					model);
			case 'GetStorage':
				var key = msg.a;
				return A2(
					$Janiczek$cmd_extra$Cmd$Extra$withCmd,
					$author$project$Ports$LocalStorage$getState(key),
					model);
			case 'ReceiveStorageState':
				var state = msg.a;
				return $Janiczek$cmd_extra$Cmd$Extra$withNoCmd(
					A2($author$project$LocalStorage$mapModelState, model, state));
			case 'ReceiveStorageStates':
				var states = msg.a;
				return $Janiczek$cmd_extra$Cmd$Extra$withNoCmd(
					A2($author$project$LocalStorage$mapModelStates, model, states));
			case 'ReceiveDate':
				var date = msg.a;
				return $Janiczek$cmd_extra$Cmd$Extra$withNoCmd(
					_Utils_update(
						model,
						{
							date: $elm$core$Maybe$Just(date)
						}));
			case 'ReceiveAuthenticationContext':
				var res = msg.a;
				var authCtx = function () {
					if (res.$ === 'Ok') {
						if (res.a.b) {
							var _v12 = res.a;
							var x = _v12.a;
							var xs = _v12.b;
							return $author$project$Common$Types$Success(x);
						} else {
							return $author$project$Common$Types$NotAsked;
						}
					} else {
						var err = res.a;
						return $author$project$Common$Types$Failed(err);
					}
				}();
				var userContext = function () {
					if (authCtx.$ === 'Success') {
						var ctx = authCtx.a;
						return $author$project$Common$Helpers$authContextToUserContext(ctx);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var requestUserLogin = function () {
					if (userContext.$ === 'Just') {
						var ctx = userContext.a;
						return A5($author$project$Common$Requests$requestGraphQL, ctx.token, model.flags.cfgLoginType, $author$project$Main$queryUserLogin, $author$project$Messages$ReceiveUserLogin, $author$project$Main$decodeUserLogin);
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				if (res.$ === 'Ok') {
					var x = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{authenticationContext: authCtx, userContext: userContext, userLogin: $author$project$Common$Types$Loading}),
						requestUserLogin);
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{authenticationContext: authCtx, userContext: userContext}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ReceiveLoginContext':
				var context = msg.a;
				var requestUserLogin = A5($author$project$Common$Requests$requestGraphQL, context.idToken, model.flags.cfgLoginType, $author$project$Main$queryUserLogin, $author$project$Messages$ReceiveUserLogin, $author$project$Main$decodeUserLogin);
				return (context.idToken === '') ? _Utils_Tuple2(
					_Utils_update(
						model,
						{authContext: $author$project$Common$Types$NotAsked}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							authContext: $author$project$Common$Types$Success(context),
							userContext: $author$project$Common$Helpers$authContextToUserContext_(context),
							userLogin: $author$project$Common$Types$Loading
						}),
					requestUserLogin);
			case 'ReceiveUserLogin':
				var res = msg.a;
				var actionsOnSuccessfulUserLogin = function (model_) {
					return A2($author$project$Main$update, $author$project$Messages$InitModels, model_);
				};
				if (res.$ === 'Ok') {
					var ul = res.a;
					var _v14 = _Utils_Tuple2(
						$elm$core$String$isEmpty(ul.userKey),
						$elm$core$List$isEmpty(ul.errors));
					if (_v14.a) {
						if (_v14.b) {
							var url = model.flags.cfgBaseUrl + ('/' + model.flags.cfgLoginType);
							var urlChangedMessage = A2($elm$browser$Browser$Navigation$pushUrl, model.key, url);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										userLogin: $author$project$Common$Types$Success(
											_Utils_update(
												ul,
												{
													errors: _List_fromArray(
														['Vi kunde inte hitta den angivna användaren'])
												}))
									}),
								urlChangedMessage);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										userLogin: $author$project$Common$Types$Success(ul)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						return A2(
							$Janiczek$cmd_extra$Cmd$Extra$andThen,
							$Janiczek$cmd_extra$Cmd$Extra$withCmd(
								$author$project$Ports$LocalStorage$getStates($author$project$Config$statesToTrack)),
							actionsOnSuccessfulUserLogin(
								_Utils_update(
									model,
									{
										brand: $author$project$Common$Types$Loading,
										userLogin: $author$project$Common$Types$Success(ul)
									})));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								userLogin: $author$project$Common$Types$Failed(err)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 'ReceiveBrand':
				var result = msg.a;
				var session = function () {
					var _v21 = _Utils_Tuple2(model.userContext, model.drawer.selectedCustomer);
					if ((_v21.a.$ === 'Just') && (_v21.b.$ === 'Just')) {
						var uc = _v21.a.a;
						var customer = _v21.b.a;
						return A5($author$project$Common$Session$init, uc.token, customer.customerKey, model.flags.cfgLoginType, model.isCustomerAdmin, model.flags.isMobile);
					} else {
						return A5($author$project$Common$Session$init, '', '', '', false, false);
					}
				}();
				var _v15 = function () {
					if (result.$ === 'Ok') {
						var brand = result.a;
						var _v17 = A2($author$project$Common$Brand$initBrandColors, brand, model.flags.cfgLogoContainer);
						var initiatedBrand = _v17;
						return _Utils_Tuple2(
							$author$project$Common$Types$Success(initiatedBrand),
							initiatedBrand);
					} else {
						var err = result.a;
						return _Utils_Tuple2(
							$author$project$Common$Types$Failed(err),
							$author$project$Common$Brand$init);
					}
				}();
				var maybeUpdatedBrand = _v15.a;
				var updatedBrand = _v15.b;
				var test = function () {
					if (maybeUpdatedBrand.$ === 'Success') {
						var brand = maybeUpdatedBrand.a;
						return A2(
							$elm$json$Json$Encode$encode,
							0,
							$author$project$Common$Encoder$encodeStyleVariables(brand));
					} else {
						return '';
					}
				}();
				var _v18 = model.page;
				if (_v18.$ === 'Branding') {
					if (maybeUpdatedBrand.$ === 'Success') {
						var brand = maybeUpdatedBrand.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									brand: maybeUpdatedBrand,
									brandingpage: A3($author$project$Pages$Branding$init, session, brand, model.flags.cfgLogoContainer)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$LocalStorage$setState(
										A2($author$project$Common$State$State, 'StyleVariables', test)),
										$author$project$Ports$Functions$setFavicon(updatedBrand.faviconUrl)
									])));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{brand: maybeUpdatedBrand}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$LocalStorage$setState(
										A2($author$project$Common$State$State, 'StyleVariables', test)),
										$author$project$Ports$Functions$setFavicon(updatedBrand.faviconUrl)
									])));
					}
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{brand: maybeUpdatedBrand}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$LocalStorage$setState(
									A2($author$project$Common$State$State, 'StyleVariables', test)),
									$author$project$Ports$Functions$setFavicon(updatedBrand.faviconUrl)
								])));
				}
			case 'SetFavicon':
				var _v22 = model.brand;
				if (_v22.$ === 'Success') {
					var brand = _v22.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$Functions$setFavicon(brand.faviconUrl));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'InitModels':
				var navbarModel = function () {
					var _v28 = _Utils_Tuple2(model.userLogin, model.navbar);
					if (_v28.a.$ === 'Success') {
						var ul = _v28.a.a;
						var navbar = _v28.b;
						return _Utils_update(
							navbar,
							{customerKey: ul.defaultCustomerKey});
					} else {
						return model.navbar;
					}
				}();
				var initialCustomerKey = function () {
					var _v26 = model.page;
					_v26$2:
					while (true) {
						switch (_v26.$) {
							case 'Reaact':
								if ((_v26.a.$ === 'Just') && (_v26.b.$ === 'Just')) {
									var tk = _v26.a.a;
									var ck = _v26.b.a;
									return ck;
								} else {
									break _v26$2;
								}
							case 'PlannicaGrid':
								if (((_v26.a.$ === 'Just') && (_v26.b.$ === 'Just')) && (_v26.c.$ === 'Just')) {
									var tk = _v26.a.a;
									var ck = _v26.b.a;
									var filters = _v26.c.a;
									return ck;
								} else {
									break _v26$2;
								}
							default:
								break _v26$2;
						}
					}
					var _v27 = model.userLogin;
					if (_v27.$ === 'Success') {
						var ul = _v27.a;
						return $elm$core$String$isEmpty(model.flags.selectedCustomer) ? ul.defaultCustomerKey : model.flags.selectedCustomer;
					} else {
						return '';
					}
				}();
				var initRequests = function () {
					var _v25 = _Utils_Tuple2(model.userContext, model.userLogin);
					if ((_v25.a.$ === 'Just') && (_v25.b.$ === 'Success')) {
						var uc = _v25.a.a;
						var ul = _v25.b.a;
						return _List_fromArray(
							[
								A3($author$project$Main$getBrand, uc.token, model.flags.cfgLoginType, initialCustomerKey),
								A3($author$project$Main$getCustomers, uc.token, model.flags.cfgLoginType, initialCustomerKey),
								A3($author$project$Main$getCustomerService, uc.token, model.flags.cfgLoginType, initialCustomerKey)
							]);
					} else {
						return _List_Nil;
					}
				}();
				var headerModel = function () {
					var _v24 = _Utils_Tuple2(model.userContext, model.header);
					if (_v24.a.$ === 'Just') {
						var ctx = _v24.a.a;
						var header = _v24.b;
						return _Utils_update(
							header,
							{
								customerKey: $elm$core$Maybe$Just(initialCustomerKey),
								username: $elm$core$Maybe$Just(ctx.username)
							});
					} else {
						return model.header;
					}
				}();
				var drawerModel = function () {
					var _v23 = model.userContext;
					if (_v23.$ === 'Just') {
						var ctx = _v23.a;
						return A2(
							$author$project$Drawer$initLoading,
							$elm$core$Maybe$Just(ctx.token),
							model.flags.isMobile);
					} else {
						return A2($author$project$Drawer$init, $elm$core$Maybe$Nothing, model.flags.isMobile);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{drawer: drawerModel, header: headerModel, navbar: navbarModel}),
					$elm$core$Platform$Cmd$batch(initRequests));
			case 'InitPage':
				var _v29 = $author$project$Main$initPageModel(
					$author$project$Main$initSession(model));
				var updatedModel = _v29.a;
				var updateMsg = _v29.b;
				return _Utils_Tuple2(
					updatedModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Main$pageInit(model),
								updateMsg
							])));
			case 'Reset':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							brandingpage: A3($author$project$Pages$Branding$init, $author$project$Main$emptySession, $author$project$Common$Brand$init, model.flags.cfgLogoContainer),
							homepage: A3($author$project$Pages$Home$init, $author$project$Main$emptySession, model.brand, model.userLogin),
							powerbipage: $author$project$Pages$PowerBI$init,
							reports: $author$project$Common$Types$NotAsked,
							rolepage: $author$project$Pages$RoleManagement$init($author$project$Main$emptySession),
							tabReportInformation: $elm$core$Dict$empty,
							userinfopage: $author$project$Pages$UserInfo$init($author$project$Main$emptySession),
							userpage: A2($author$project$Pages$UserManagement$init, $author$project$Main$emptySession, $elm$core$Maybe$Nothing)
						}),
					$elm$core$Platform$Cmd$none);
			case 'UpdateApplicationAndTabs':
				return A2($author$project$Main$updateApplicationAndTabs, model.url, model);
			case 'ReceiveCustomers':
				var initialCustomer = msg.a;
				var result = msg.b;
				var initialCustomerKey = $elm$core$String$isEmpty(model.flags.selectedCustomer) ? initialCustomer : model.flags.selectedCustomer;
				return A3(
					$author$project$Common$Helpers$andThen,
					$author$project$Main$update,
					$author$project$Messages$InitPage,
					A2(
						$author$project$Main$update,
						$author$project$Messages$DrawerMsg(
							A2($author$project$Drawer$SetCustomers, initialCustomerKey, result)),
						model));
			case 'ReceiveCustomerService':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var res = result.a;
					return A3(
						$author$project$Common$Helpers$andThen,
						$author$project$Main$update,
						$author$project$Messages$UpdateSession,
						A3(
							$author$project$Common$Helpers$andThen,
							$author$project$Main$update,
							$author$project$Messages$UpdateApplicationAndTabs,
							A2(
								$author$project$Main$update,
								$author$project$Messages$DrawerMsg(
									$author$project$Drawer$SetCustomerService(result)),
								_Utils_update(
									model,
									{isCustomerAdmin: res.isCustomerAdmin}))));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveReaactCustomers':
				var result = msg.a;
				var selectedCustomerKey = function () {
					var _v32 = model.drawer.selectedCustomer;
					if (_v32.$ === 'Just') {
						var c = _v32.a;
						return $elm$core$Maybe$Just(c.customerKey);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				var customers = function () {
					if (result.$ === 'Ok') {
						var c = result.a;
						return c;
					} else {
						var err = result.a;
						return _List_Nil;
					}
				}();
				return A2(
					$author$project$Main$update,
					$author$project$Messages$ReaactMsg(
						A2($author$project$Pages$ReportSettings$SetCustomers, customers, selectedCustomerKey)),
					model);
			case 'ReceiveUsers':
				var result = msg.a;
				var setCurrentPageUsers = function () {
					var _v33 = model.page;
					switch (_v33.$) {
						case 'Roles':
							return $author$project$Messages$RoleMsg(
								$author$project$Pages$RoleManagement$SetUsers(result));
						case 'UserManagement':
							return $author$project$Messages$UserMsg(
								$author$project$Pages$UserManagement$SetUsers(result));
						case 'UserInfo':
							return $author$project$Messages$UserInfoMsg(
								$author$project$Pages$UserInfo$SetUsers(result));
						default:
							return $author$project$Messages$NoOp;
					}
				}();
				return A2($author$project$Main$update, setCurrentPageUsers, model);
			case 'ReceiveRoles':
				var result = msg.a;
				return A2(
					$author$project$Main$update,
					$author$project$Messages$RoleMsg(
						$author$project$Pages$RoleManagement$SetRoles(result)),
					model);
			case 'ReceiveRolePermissions':
				var result = msg.a;
				return A2(
					$author$project$Main$update,
					$author$project$Messages$RPMsg(
						$author$project$Pages$RolePermissions$SetRolePermissions(result)),
					model);
			case 'ReceiveAllServices':
				var result = msg.a;
				return A2(
					$author$project$Main$update,
					$author$project$Messages$RPMsg(
						$author$project$Pages$RolePermissions$SetServices(result)),
					model);
			case 'RequestReport':
				var tabKey = msg.a;
				var _v34 = _Utils_Tuple2(model.userContext, model.drawer.selectedCustomer);
				if ((_v34.a.$ === 'Just') && (_v34.b.$ === 'Just')) {
					var uc = _v34.a.a;
					var customer = _v34.b.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A4($author$project$Main$getReport, uc.token, model.flags.cfgLoginType, customer.customerKey, tabKey)
								])));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ReceiveReport':
				var tabKey = msg.a;
				var result = msg.b;
				var _v35 = function () {
					if (result.$ === 'Ok') {
						if (result.a.b) {
							var _v37 = result.a;
							var r = _v37.a;
							var rs = _v37.b;
							return A2(
								$author$project$Pages$PowerBI$update,
								A2(
									$author$project$Pages$PowerBI$SetReport,
									tabKey,
									$author$project$Common$Types$LoadingToken(r)),
								model.powerbipage);
						} else {
							return A2(
								$author$project$Pages$PowerBI$update,
								A2(
									$author$project$Pages$PowerBI$SetReport,
									tabKey,
									A2(
										$author$project$Common$Types$LoadingFailed,
										$elm$core$Maybe$Nothing,
										$elm$core$Maybe$Just('Could not get report'))),
								model.powerbipage);
						}
					} else {
						var err = result.a;
						return A2(
							$author$project$Pages$PowerBI$update,
							A2(
								$author$project$Pages$PowerBI$SetReport,
								tabKey,
								A2(
									$author$project$Common$Types$LoadingFailed,
									$elm$core$Maybe$Just(err),
									$elm$core$Maybe$Nothing)),
							model.powerbipage);
					}
				}();
				var subModel = _v35.a;
				var subMsg = _v35.b;
				if ((result.$ === 'Ok') && result.a.b) {
					var _v39 = result.a;
					var r = _v39.a;
					var rs = _v39.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{powerbipage: subModel}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Messages$PowerBIMsg, subMsg),
									A3($author$project$Main$getEmbedToken, model, tabKey, r)
								])));
				} else {
					return _Utils_Tuple2(
						model,
						A2($elm$core$Platform$Cmd$map, $author$project$Messages$PowerBIMsg, subMsg));
				}
			case 'ReceiveContainer':
				var result = msg.a;
				var _v40 = function () {
					if (result.$ === 'Ok') {
						var folders = result.a;
						return A2(
							$author$project$Pages$Document$update,
							$author$project$Pages$Document$SetFolder(
								$elm$core$List$head(folders)),
							model.docpage);
					} else {
						return A2($author$project$Pages$Document$update, $author$project$Pages$Document$NoOp, model.docpage);
					}
				}();
				var subModel = _v40.a;
				var subMsg = _v40.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{docpage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$DocMsg, subMsg));
			case 'ReceiveEmbedToken':
				var tabKey = msg.a;
				var result = msg.b;
				var _v42 = function () {
					if (result.$ === 'Ok') {
						var token = result.a;
						return A2(
							$author$project$Pages$PowerBI$update,
							A2($author$project$Pages$PowerBI$SetReportToken, tabKey, token),
							model.powerbipage);
					} else {
						var err = result.a;
						return A2(
							$author$project$Pages$PowerBI$update,
							A2(
								$author$project$Pages$PowerBI$SetReport,
								tabKey,
								A2(
									$author$project$Common$Types$LoadingFailed,
									$elm$core$Maybe$Just(err),
									$elm$core$Maybe$Nothing)),
							model.powerbipage);
					}
				}();
				var subModel = _v42.a;
				var subMsg = _v42.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{powerbipage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$PowerBIMsg, subMsg));
			case 'ReceiveApplicationTabs':
				var result = msg.a;
				var _v44 = function () {
					if (result.$ === 'Ok') {
						var applications = result.a;
						return A2(
							$author$project$Pages$ReportSettings$update,
							$author$project$Pages$ReportSettings$SetApplicationTabs(applications),
							model.reaactpage);
					} else {
						return A2($author$project$Pages$ReportSettings$update, $author$project$Pages$ReportSettings$NoOp, model.reaactpage);
					}
				}();
				var subModel = _v44.a;
				var subMsg = _v44.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{reaactpage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$ReaactMsg, subMsg));
			case 'ReceiveReportServices':
				var result = msg.a;
				var _v46 = function () {
					if (result.$ === 'Ok') {
						var services = result.a;
						return A2(
							$author$project$Pages$ReportSettings$update,
							$author$project$Pages$ReportSettings$SetServices(services),
							model.reaactpage);
					} else {
						return A2($author$project$Pages$ReportSettings$update, $author$project$Pages$ReportSettings$NoOp, model.reaactpage);
					}
				}();
				var subModel = _v46.a;
				var subMsg = _v46.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							debug: $elm$core$Debug$toString(result),
							reaactpage: subModel
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$ReaactMsg, subMsg));
			case 'ReceiveAnnouncements':
				var result = msg.a;
				var _v48 = function () {
					if (result.$ === 'Ok') {
						var announcements = result.a;
						return A2(
							$author$project$Pages$Home$update,
							$author$project$Pages$Home$SetAnnouncements(announcements),
							model.homepage);
					} else {
						return A2($author$project$Pages$Home$update, $author$project$Pages$Home$NoOp, model.homepage);
					}
				}();
				var subModel = _v48.a;
				var subMsg = _v48.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homepage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeMsg, subMsg));
			case 'ReceiveAnnouncementServices':
				var result = msg.a;
				var _v50 = function () {
					if (result.$ === 'Ok') {
						var customerServices = result.a;
						return A2(
							$author$project$Pages$Home$update,
							$author$project$Pages$Home$SetServices(customerServices.services),
							model.homepage);
					} else {
						return A2($author$project$Pages$Home$update, $author$project$Pages$Home$NoOp, model.homepage);
					}
				}();
				var subModel = _v50.a;
				var subMsg = _v50.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homepage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeMsg, subMsg));
			case 'ReceiveAnnouncementsEdit':
				var result = msg.a;
				var _v52 = function () {
					if (result.$ === 'Ok') {
						var announcements = result.a;
						return A2(
							$author$project$Pages$HomeEdit$update,
							$author$project$Pages$HomeEdit$SetAnnouncements(announcements),
							model.homeeditpage);
					} else {
						return A2($author$project$Pages$HomeEdit$update, $author$project$Pages$HomeEdit$NoOp, model.homeeditpage);
					}
				}();
				var subModel = _v52.a;
				var subMsg = _v52.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homeeditpage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeEditMsg, subMsg));
			case 'ReceiveAnnouncementServicesEdit':
				var result = msg.a;
				var _v54 = function () {
					if (result.$ === 'Ok') {
						var customerServices = result.a;
						return A2(
							$author$project$Pages$HomeEdit$update,
							$author$project$Pages$HomeEdit$SetServices(customerServices.services),
							model.homeeditpage);
					} else {
						return A2($author$project$Pages$HomeEdit$update, $author$project$Pages$HomeEdit$NoOp, model.homeeditpage);
					}
				}();
				var subModel = _v54.a;
				var subMsg = _v54.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homeeditpage: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeEditMsg, subMsg));
			case 'LanguageMsg':
				var subMsg = msg.a;
				var _v56 = A2($author$project$Language$Language$update, subMsg, model.language);
				var langModel = _v56.a;
				var langMsg = _v56.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{language: langModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$LanguageMsg, langMsg));
			case 'ToggleDrawerState':
				var setClosed = msg.a;
				return A2(
					$author$project$Main$update,
					$author$project$Messages$DrawerMsg(
						$author$project$Drawer$ToggleClose(setClosed)),
					model);
			case 'HeaderMsg':
				var subMsg = msg.a;
				var _v57 = A2($author$project$Header$update, subMsg, model.header);
				var headerModel = _v57.a;
				var headerMsg = _v57.b;
				if (subMsg.$ === 'ToggleDrawer') {
					return A2(
						$author$project$Main$update,
						$author$project$Messages$DrawerMsg($author$project$Drawer$Toggle),
						model);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{header: headerModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Messages$HeaderMsg, headerMsg));
				}
			case 'DrawerMsg':
				var subMsg = msg.a;
				var navbar = model.navbar;
				var _v59 = function () {
					if (subMsg.$ === 'RequestCustomerService') {
						var key = subMsg.a;
						var _v61 = model.userContext;
						if (_v61.$ === 'Just') {
							var uc = _v61.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										navbar: _Utils_update(
											navbar,
											{customerKey: key})
									}),
								$elm$core$Maybe$Just(
									_List_fromArray(
										[
											A3($author$project$Main$getCustomerService, uc.token, model.flags.cfgLoginType, key),
											A3($author$project$Main$getBrand, uc.token, model.flags.cfgLoginType, key),
											A2($elm$browser$Browser$Navigation$pushUrl, model.key, model.flags.cfgBaseUrl),
											$author$project$Ports$LocalStorage$setState(
											A2($author$project$Common$State$State, 'selectedCustomer', key))
										])));
						} else {
							return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
					}
				}();
				var model_ = _v59.a;
				var requests = _v59.b;
				var _v62 = A2($author$project$Main$update, $author$project$Messages$Reset, model_);
				var resetModel = _v62.a;
				var _v63 = A2($author$project$Drawer$update, subMsg, model.drawer);
				var drawerModel = _v63.a;
				var drawerMsg = _v63.b;
				if (requests.$ === 'Just') {
					var req = requests.a;
					return A3(
						$author$project$Common$Helpers$andThen,
						$author$project$Main$update,
						$author$project$Messages$UrlChanged(
							A2(
								$elm$core$Maybe$withDefault,
								model.url,
								$elm$url$Url$fromString(model.flags.cfgBaseUrl))),
						_Utils_Tuple2(
							_Utils_update(
								resetModel,
								{drawer: drawerModel, page: $author$project$Pages$Pages$Home}),
							$elm$core$Platform$Cmd$batch(req)));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model_,
							{drawer: drawerModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Messages$DrawerMsg, drawerMsg));
				}
			case 'NavbarMsg':
				var subMsg = msg.a;
				var _v65 = A2($author$project$Navbar$update, subMsg, model.navbar);
				var navbarModel = _v65.a;
				var navbarMsg = _v65.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{navbar: navbarModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$NavbarMsg, navbarMsg));
			case 'LoginMsg':
				var subMsg = msg.a;
				var _v66 = A2($author$project$Pages$Login$update, subMsg, model.loginpage);
				var loginModel = _v66.a;
				var loginMsg = _v66.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{loginpage: loginModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$LoginMsg, loginMsg));
			case 'HomeMsg':
				var subMsg = msg.a;
				var token = function () {
					var _v69 = model.userContext;
					if (_v69.$ === 'Just') {
						var uc = _v69.a;
						return uc.token;
					} else {
						return '';
					}
				}();
				var selectedCustomerKey = function () {
					var _v68 = model.drawer.selectedCustomer;
					if (_v68.$ === 'Just') {
						var customer = _v68.a;
						return customer.customerKey;
					} else {
						return '';
					}
				}();
				var _v67 = A2($author$project$Pages$Home$update, subMsg, model.homepage);
				var homeModel = _v67.a;
				var homeMsg = _v67.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homepage: homeModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeMsg, homeMsg));
			case 'HomeEditMsg':
				var subMsg = msg.a;
				var token = function () {
					var _v72 = model.userContext;
					if (_v72.$ === 'Just') {
						var uc = _v72.a;
						return uc.token;
					} else {
						return '';
					}
				}();
				var selectedCustomerKey = function () {
					var _v71 = model.drawer.selectedCustomer;
					if (_v71.$ === 'Just') {
						var customer = _v71.a;
						return customer.customerKey;
					} else {
						return '';
					}
				}();
				var _v70 = A2($author$project$Pages$HomeEdit$update, subMsg, model.homeeditpage);
				var homeeditModel = _v70.a;
				var homeeditMsg = _v70.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{homeeditpage: homeeditModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$HomeEditMsg, homeeditMsg));
			case 'UserMsg':
				var subMsg = msg.a;
				var _v73 = A2($author$project$Pages$UserManagement$update, subMsg, model.userpage);
				var userModel = _v73.a;
				var userMsg = _v73.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{userpage: userModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$UserMsg, userMsg));
			case 'RoleMsg':
				var subMsg = msg.a;
				var _v74 = A2($author$project$Pages$RoleManagement$update, subMsg, model.rolepage);
				var roleModel = _v74.a;
				var roleMsg = _v74.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{rolepage: roleModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$RoleMsg, roleMsg));
			case 'UserInfoMsg':
				var subMsg = msg.a;
				var _v75 = A2($author$project$Pages$UserInfo$update, subMsg, model.userinfopage);
				var userinfoModel = _v75.a;
				var userinfoMsg = _v75.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{userinfopage: userinfoModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$UserInfoMsg, userinfoMsg));
			case 'BrandingMsg':
				var subMsg = msg.a;
				var updatedBrand = function () {
					if (subMsg.$ === 'Apply') {
						return $author$project$Common$Types$Success(model.brandingpage.brand);
					} else {
						return model.brand;
					}
				}();
				var _v76 = A2($author$project$Pages$Branding$update, subMsg, model.brandingpage);
				var brandingModel = _v76.a;
				var brandingMsg = _v76.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{brand: updatedBrand, brandingpage: brandingModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$BrandingMsg, brandingMsg));
			case 'PowerBIMsg':
				var subMsg = msg.a;
				var _v78 = A2($author$project$Pages$PowerBI$update, subMsg, model.powerbipage);
				var powerbiModel = _v78.a;
				var powerbiMsg = _v78.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{powerbipage: powerbiModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$PowerBIMsg, powerbiMsg));
			case 'ReaactMsg':
				var subMsg = msg.a;
				var _v79 = A2($author$project$Pages$ReportSettings$update, subMsg, model.reaactpage);
				var reaactModel = _v79.a;
				var reaactMsg = _v79.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{reaactpage: reaactModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$ReaactMsg, reaactMsg));
			case 'SubsMsg':
				var subMsg = msg.a;
				var _v80 = A2($author$project$Pages$Subscriptions$update, subMsg, model.subspage);
				var subsModel = _v80.a;
				var subsMsg = _v80.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{subspage: subsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$SubsMsg, subsMsg));
			case 'AppRegMsg':
				var subMsg = msg.a;
				var _v81 = A2($author$project$Pages$AppRegistration$update, subMsg, model.appregpage);
				var appRegModel = _v81.a;
				var appRegMsg = _v81.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{appregpage: appRegModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$AppRegMsg, appRegMsg));
			case 'CustomersMsg':
				var subMsg = msg.a;
				var _v82 = A2($author$project$Pages$Customers$update, subMsg, model.customerspage);
				var subsModel = _v82.a;
				var subsMsg = _v82.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{customerspage: subsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$CustomersMsg, subsMsg));
			case 'RPMsg':
				var subMsg = msg.a;
				var _v83 = A2($author$project$Pages$RolePermissions$update, subMsg, model.rppage);
				var subsModel = _v83.a;
				var subsMsg = _v83.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{rppage: subsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$RPMsg, subsMsg));
			case 'DocMsg':
				var subMsg = msg.a;
				var _v84 = A2($author$project$Pages$Document$update, subMsg, model.docpage);
				var subsModel = _v84.a;
				var subsMsg = _v84.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{docpage: subsModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Messages$DocMsg, subsMsg));
			case 'ApplicationSelectionClick':
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$PlannicaGridPorts$plannicaGridApplicationClick(
						$elm$url$Url$toString(url)));
			default:
				var posix = msg.a;
				var token = function () {
					var _v86 = model.userContext;
					if (_v86.$ === 'Just') {
						var uc = _v86.a;
						return uc.token;
					} else {
						return '';
					}
				}();
				var commands = function () {
					var _v85 = model.page;
					if (((_v85.$ === 'Reaact') && (_v85.a.$ === 'Just')) && (_v85.b.$ === 'Just')) {
						var tk = _v85.a.a;
						var ck = _v85.b.a;
						return A6($author$project$Main$getReportIfTokenIsExpired, posix, model.powerbipage, token, model.flags.cfgLoginType, ck, tk);
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(model, commands);
		}
	});
var $author$project$Main$updateApplicationAndTabs = F2(
	function (url, model) {
		var allApplications = function () {
			var _v3 = model.drawer.customerService;
			if (_v3.$ === 'Success') {
				var cs = _v3.a;
				return $author$project$Main$getAllServiceApplications(cs.services);
			} else {
				return _List_Nil;
			}
		}();
		var allTabs = $author$project$Main$getAllApplicationTabs(allApplications);
		var selectedTab = function () {
			var _v2 = model.page;
			_v2$3:
			while (true) {
				switch (_v2.$) {
					case 'Reaact':
						if ((_v2.a.$ === 'Just') && (_v2.b.$ === 'Just')) {
							var tk = _v2.a.a;
							var ck = _v2.b.a;
							return $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (t) {
										return _Utils_eq(t.tabKey, tk);
									},
									allTabs));
						} else {
							break _v2$3;
						}
					case 'PlannicaGrid':
						if ((_v2.a.$ === 'Just') && (_v2.b.$ === 'Just')) {
							var tk = _v2.a.a;
							var ck = _v2.b.a;
							var filters = _v2.c;
							return $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (t) {
										return _Utils_eq(t.tabKey, tk);
									},
									allTabs));
						} else {
							break _v2$3;
						}
					case 'Document':
						if (_v2.a.$ === 'Just') {
							var tk = _v2.a.a;
							return $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (t) {
										return _Utils_eq(t.tabKey, tk);
									},
									allTabs));
						} else {
							break _v2$3;
						}
					default:
						break _v2$3;
				}
			}
			return A2($author$project$Pages$Pages$getRoutedTab, url.path, allApplications);
		}();
		var selectedApplication = function () {
			if (selectedTab.$ === 'Just') {
				var tab = selectedTab.a;
				return A2($author$project$Pages$Pages$getRoutedApplication, tab, allApplications);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		if (selectedApplication.$ === 'Just') {
			var application = selectedApplication.a;
			return A3(
				$author$project$Common$Helpers$andThen,
				$author$project$Main$update,
				$author$project$Messages$ApplicationSelectionClick(url),
				A3(
					$author$project$Common$Helpers$andThen,
					$author$project$Main$update,
					$author$project$Messages$DrawerMsg(
						$author$project$Drawer$SelectApplication(application)),
					A2(
						$author$project$Main$update,
						$author$project$Messages$NavbarMsg(
							A2($author$project$Navbar$SetTabs, application.tabs, selectedTab)),
						model)));
		} else {
			return A2($author$project$Main$update, $author$project$Messages$NoOp, model);
		}
	});
var $author$project$Main$checkIfLoading = function (model) {
	return _Utils_eq(model.brand, $author$project$Common$Types$Loading) || (_Utils_eq(model.userLogin, $author$project$Common$Types$Loading) || (_Utils_eq(model.authenticationContext, $author$project$Common$Types$Loading) || (_Utils_eq(model.authContext, $author$project$Common$Types$Loading) || _Utils_eq(model.drawer.customerService, $author$project$Common$Types$Loading))));
};
var $author$project$Common$Helpers$createLogoUrl = F2(
	function (baseUrl, customerKey) {
		return $elm$core$String$isEmpty(baseUrl) ? '' : (baseUrl + ('/' + (customerKey + '_logo.png')));
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Common$Helpers$mapEmbedViewClassName = function (embedView) {
	switch (embedView.$) {
		case 'Simple':
			return 'main simple';
		case 'Header':
			return 'main header';
		case 'Navbar':
			return 'main navbar';
		case 'Drawer':
			return 'main drawer';
		case 'HeaderNavbar':
			return 'main headernavbar';
		case 'HeaderDrawer':
			return 'main headerdrawer';
		case 'DrawerNavbar':
			return 'main drawernavbar';
		default:
			return 'main';
	}
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Pages$Pages$isUrlAllowed = F2(
	function (path, tabs) {
		return A2(
			$elm$core$List$any,
			function (t) {
				return _Utils_eq(t.tabPath, path);
			},
			tabs);
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$Pages$AppRegistration$ApplicationField = {$: 'ApplicationField'};
var $author$project$Pages$AppRegistration$ApplicationIconField = {$: 'ApplicationIconField'};
var $author$project$Pages$AppRegistration$NoOp = {$: 'NoOp'};
var $author$project$Pages$AppRegistration$RequestCreateService = {$: 'RequestCreateService'};
var $author$project$Pages$AppRegistration$SelectApplication = function (a) {
	return {$: 'SelectApplication', a: a};
};
var $author$project$Pages$AppRegistration$SelectApplicationType = function (a) {
	return {$: 'SelectApplicationType', a: a};
};
var $author$project$Pages$AppRegistration$SelectOrder = F2(
	function (a, b) {
		return {$: 'SelectOrder', a: a, b: b};
	});
var $author$project$Pages$AppRegistration$SelectService = function (a) {
	return {$: 'SelectService', a: a};
};
var $author$project$Pages$AppRegistration$ServiceField = {$: 'ServiceField'};
var $author$project$Pages$AppRegistration$ServiceIconField = {$: 'ServiceIconField'};
var $author$project$Pages$AppRegistration$TabField = {$: 'TabField'};
var $author$project$Pages$AppRegistration$UpdateField = F2(
	function (a, b) {
		return {$: 'UpdateField', a: a, b: b};
	});
var $author$project$Pages$AppRegistration$checkIfFieldsAreValid = function (model) {
	return (((!$elm$core$String$isEmpty(model.newService.serviceKey)) || (!$elm$core$String$isEmpty(model.newService.serviceName))) && (((!$elm$core$String$isEmpty(model.newService.applicationKey)) || (!$elm$core$String$isEmpty(model.newService.applicationName))) && ((!$elm$core$String$isEmpty(model.newService.tabName)) && ((!$elm$core$String$isEmpty(model.newService.applicationTypeKey)) && ((_Utils_cmp(model.newService.serviceSort, -1) > 0) && ((_Utils_cmp(model.newService.applicationSort, -1) > 0) && (_Utils_cmp(model.newService.tabSort, -1) > 0))))))) ? true : false;
};
var $author$project$Elements$Core$default = {blur: $elm$core$Maybe$Nothing, color: '#000', display: 'block', height: '', input: $elm$core$Maybe$Nothing, margin: 'auto', readonly: false, width: '100%'};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Elements$Core$dropdownItem = F2(
	function (selectedItem, _v0) {
		var itemText = _v0.a;
		var itemValue = _v0.b;
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(itemValue),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(itemValue, selectedItem))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(itemText)
				]));
	});
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 'MayStopPropagation', a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$select = _VirtualDom_node('select');
var $author$project$Elements$Core$dropdown = F6(
	function (brand, items, selectedItem, msg, styles, enabled) {
		return A2(
			$elm$html$Html$select,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('app-dropdown', true),
								_Utils_Tuple2('bordered', true)
							])),
						$elm$html$Html$Events$onInput(msg),
						A2($elm$html$Html$Attributes$style, 'border-color', brand.primary.hex),
						$elm$html$Html$Attributes$disabled(!enabled)
					]),
				styles),
			A2(
				$elm$core$List$map,
				$author$project$Elements$Core$dropdownItem(selectedItem),
				items));
	});
var $author$project$Pages$AppRegistration$getMaxIntByField = F2(
	function (item, field) {
		var _v0 = $elm$core$List$reverse(
			A2($elm$core$List$sortBy, field, item));
		if (_v0.b) {
			var x = _v0.a;
			return field(x);
		} else {
			return 0;
		}
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 'Normal', a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Elements$Core$renderButton = F5(
	function (theme, text_, isActive, update_, attributes) {
		var backgroundColor = isActive ? theme.hex : '#909696';
		return A2(
			$elm$html$Html$button,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn-default'),
						$elm$html$Html$Events$onClick(update_),
						isActive ? A2($elm$html$Html$Attributes$attribute, 'enabled', 'true') : A2($elm$html$Html$Attributes$attribute, 'disabled', 'true'),
						A2($elm$html$Html$Attributes$style, 'background-color', backgroundColor)
					]),
				attributes),
			_List_fromArray(
				[
					$elm$html$Html$text(text_)
				]));
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Elements$Core$textbox = F5(
	function (settings, forName, label, icon, value) {
		var icon_ = function () {
			if (icon.$ === 'Just') {
				var iconName = icon.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(iconName)
							]))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var eventAttribute = function () {
			var _v0 = _Utils_Tuple2(settings.input, settings.blur);
			if (_v0.a.$ === 'Just') {
				if (_v0.b.$ === 'Nothing') {
					var inputEvent = _v0.a.a;
					var _v3 = _v0.b;
					return _List_fromArray(
						[
							$elm$html$Html$Events$onInput(inputEvent)
						]);
				} else {
					var inputEvent = _v0.a.a;
					var blurEvent = _v0.b.a;
					return _List_fromArray(
						[
							$elm$html$Html$Events$onInput(inputEvent),
							$elm$html$Html$Events$onBlur(blurEvent)
						]);
				}
			} else {
				if (_v0.b.$ === 'Nothing') {
					var _v1 = _v0.a;
					var _v2 = _v0.b;
					return _List_Nil;
				} else {
					var _v4 = _v0.a;
					var blurEvent = _v0.b.a;
					return _List_fromArray(
						[
							$elm$html$Html$Events$onBlur(blurEvent)
						]);
				}
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('textbox-animated', true),
							_Utils_Tuple2('empty', value === '')
						])),
					A2($elm$html$Html$Attributes$style, 'margin', settings.margin),
					A2($elm$html$Html$Attributes$style, 'width', settings.width),
					A2($elm$html$Html$Attributes$style, 'display', settings.display)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$name(forName),
								$elm$html$Html$Attributes$required(true),
								$elm$html$Html$Attributes$value(value),
								$elm$html$Html$Attributes$readonly(settings.readonly)
							]),
						eventAttribute),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(forName),
							A2($elm$html$Html$Attributes$style, 'color', settings.color)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							A2(
								$elm$core$List$append,
								icon_,
								_List_fromArray(
									[
										$elm$html$Html$text(label)
									])))
						]))
				]));
	});
var $author$project$Pages$AppRegistration$renderSide = F2(
	function (model, brand) {
		var sortOrderItems = _List_fromArray(
			[
				_Utils_Tuple2('Välj sortingsordning', ''),
				_Utils_Tuple2('1', '1'),
				_Utils_Tuple2('2', '2'),
				_Utils_Tuple2('3', '3'),
				_Utils_Tuple2('4', '4'),
				_Utils_Tuple2('5', '5')
			]);
		var settings = $author$project$Elements$Core$default;
		var selectedServiceSortOrder = function () {
			var _v8 = model.selectedService;
			if (_v8.$ === 'Just') {
				var s = _v8.a;
				return $elm$core$String$fromInt(s.serviceSortKey);
			} else {
				return '';
			}
		}();
		var selectedServiceKey = function () {
			var _v7 = model.selectedService;
			if (_v7.$ === 'Just') {
				var service = _v7.a;
				return service.serviceKey;
			} else {
				return '';
			}
		}();
		var selectedApplicationSortOrder = function () {
			var _v6 = model.selectedApplication;
			if (_v6.$ === 'Just') {
				var a = _v6.a;
				return $elm$core$String$fromInt(a.applicationSortKey);
			} else {
				return '';
			}
		}();
		var selectedApplicationKey = function () {
			var _v5 = model.selectedApplication;
			if (_v5.$ === 'Just') {
				var a = _v5.a;
				return a.applicationKey;
			} else {
				return '';
			}
		}();
		var maxTabSortOrder = function () {
			var _v4 = model.selectedApplication;
			if (_v4.$ === 'Just') {
				var application = _v4.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					application.tabs,
					function ($) {
						return $.tabSortKey;
					}) + 1;
			} else {
				return 1;
			}
		}();
		var tabSortOrderItems = A2(
			$elm$core$List$cons,
			_Utils_Tuple2('Välj sortingsordning', ''),
			A2(
				$elm$core$List$map,
				function (i) {
					return _Utils_Tuple2(
						$elm$core$String$fromInt(i),
						$elm$core$String$fromInt(i));
				},
				A2($elm$core$List$range, 1, maxTabSortOrder)));
		var maxServiceSortOrder = function () {
			var _v3 = model.services;
			if (_v3.$ === 'Success') {
				var services = _v3.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					services,
					function ($) {
						return $.serviceSortKey;
					}) + 1;
			} else {
				return 1;
			}
		}();
		var serviceSortOrderItems = A2(
			$elm$core$List$cons,
			_Utils_Tuple2('Välj sortingsordning', ''),
			A2(
				$elm$core$List$map,
				function (i) {
					return _Utils_Tuple2(
						$elm$core$String$fromInt(i),
						$elm$core$String$fromInt(i));
				},
				A2($elm$core$List$range, 1, maxServiceSortOrder)));
		var maxApplicationSortOrder = function () {
			var _v2 = model.selectedService;
			if (_v2.$ === 'Just') {
				var service = _v2.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					service.applications,
					function ($) {
						return $.applicationSortKey;
					}) + 1;
			} else {
				return 1;
			}
		}();
		var isServiceDropdownReadOnly = selectedServiceKey !== '';
		var isApplicationTypeDropdownEnabled = selectedApplicationKey === '';
		var isApplicationDropdownReadOnly = selectedApplicationKey !== '';
		var dropdownServices = function () {
			var _v1 = model.services;
			if (_v1.$ === 'Success') {
				var services = _v1.a;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2('Skapa ny', ''),
					A2(
						$elm$core$List$sortBy,
						$elm$core$Tuple$first,
						A2(
							$elm$core$List$map,
							function (s) {
								return _Utils_Tuple2(s.serviceName, s.serviceKey);
							},
							services)));
			} else {
				return _List_Nil;
			}
		}();
		var dropdownApplications = A2(
			$elm$core$List$cons,
			_Utils_Tuple2('Skapa ny', ''),
			A2(
				$elm$core$List$sortBy,
				$elm$core$Tuple$first,
				A2(
					$elm$core$List$map,
					function (a) {
						return _Utils_Tuple2(a.applicationName, a.applicationKey);
					},
					model.selectedServiceApplications)));
		var dropdownApplicationTypes = function () {
			var _v0 = model.applicationTypes;
			if (_v0.$ === 'Success') {
				var applicationTypes = _v0.a;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2('Välj applikationstyp', ''),
					A2(
						$elm$core$List$sortBy,
						$elm$core$Tuple$first,
						A2(
							$elm$core$List$map,
							function (at) {
								return _Utils_Tuple2(at.name, at.key);
							},
							applicationTypes)));
			} else {
				return _List_Nil;
			}
		}();
		var createButtonEnabled = $author$project$Pages$AppRegistration$checkIfFieldsAreValid(model);
		var applicationSortOrderItems = A2(
			$elm$core$List$cons,
			_Utils_Tuple2('Välj sortingsordning', ''),
			A2(
				$elm$core$List$map,
				function (i) {
					return _Utils_Tuple2(
						$elm$core$String$fromInt(i),
						$elm$core$String$fromInt(i));
				},
				A2($elm$core$List$range, 1, maxApplicationSortOrder)));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', 'calc(100% - 40px)'),
					A2($elm$html$Html$Attributes$style, 'height', 'calc(100% - 20px)'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px 20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0 0 20px 0'),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'font-size', '20px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Skapa Service/App/Tab')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Service')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Service')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							dropdownServices,
							selectedServiceKey,
							$author$project$Pages$AppRegistration$SelectService,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							serviceSortOrderItems,
							selectedServiceSortOrder,
							$author$project$Pages$AppRegistration$SelectOrder($author$project$Pages$AppRegistration$ServiceField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							selectedServiceSortOrder === ''),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateField($author$project$Pages$AppRegistration$ServiceField)),
									margin: '0 0 0 0',
									readonly: isServiceDropdownReadOnly
								}),
							'Service',
							'Servicenamn',
							$elm$core$Maybe$Nothing,
							model.newService.serviceName),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateField($author$project$Pages$AppRegistration$ServiceIconField)),
									margin: '0 0 30px 0',
									readonly: isServiceDropdownReadOnly
								}),
							'ikon',
							'Ikon',
							$elm$core$Maybe$Nothing,
							model.newService.serviceIcon)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Applikation')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Applikation')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							dropdownApplications,
							selectedApplicationKey,
							$author$project$Pages$AppRegistration$SelectApplication,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Applikationstyp')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							dropdownApplicationTypes,
							model.newService.applicationTypeKey,
							$author$project$Pages$AppRegistration$SelectApplicationType,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'inline'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							isApplicationTypeDropdownEnabled),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							applicationSortOrderItems,
							selectedApplicationSortOrder,
							$author$project$Pages$AppRegistration$SelectOrder($author$project$Pages$AppRegistration$ApplicationField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							selectedApplicationSortOrder === ''),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateField($author$project$Pages$AppRegistration$ApplicationField)),
									margin: '0 0 0 0',
									readonly: isApplicationDropdownReadOnly
								}),
							'Applikation',
							'Applikationnamn',
							$elm$core$Maybe$Nothing,
							model.newService.applicationName),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateField($author$project$Pages$AppRegistration$ApplicationIconField)),
									margin: '0 0 30px 0',
									readonly: isApplicationDropdownReadOnly
								}),
							'ikon',
							'Ikon',
							$elm$core$Maybe$Nothing,
							model.newService.applicationIcon)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Tab')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							tabSortOrderItems,
							'',
							$author$project$Pages$AppRegistration$SelectOrder($author$project$Pages$AppRegistration$TabField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateField($author$project$Pages$AppRegistration$TabField)),
									margin: '0 0 40px 0'
								}),
							'tabName',
							'Tabnamn',
							$elm$core$Maybe$Nothing,
							model.newService.tabName)
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Skapa',
					createButtonEnabled,
					createButtonEnabled ? $author$project$Pages$AppRegistration$RequestCreateService : $author$project$Pages$AppRegistration$NoOp,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'padding', '5px 10px'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]))
				]));
	});
var $author$project$Pages$AppRegistration$CloseExisting = {$: 'CloseExisting'};
var $author$project$Pages$AppRegistration$RequestUpdateService = {$: 'RequestUpdateService'};
var $author$project$Pages$AppRegistration$UpdateExistingApplicationType = function (a) {
	return {$: 'UpdateExistingApplicationType', a: a};
};
var $author$project$Pages$AppRegistration$UpdateExistingField = F2(
	function (a, b) {
		return {$: 'UpdateExistingField', a: a, b: b};
	});
var $author$project$Pages$AppRegistration$UpdateExistingSort = F2(
	function (a, b) {
		return {$: 'UpdateExistingSort', a: a, b: b};
	});
var $author$project$Pages$AppRegistration$checkIfServiceIsUpdated = function (model) {
	var _v0 = _Utils_Tuple3(model.selectedExistingService, model.selectedExistingApplication, model.selectedExistingTab);
	if (((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) && (_v0.c.$ === 'Just')) {
		var s = _v0.a.a;
		var a = _v0.b.a;
		var t = _v0.c.a;
		return ((!_Utils_eq(model.updatedService.serviceName, s.serviceName)) || ((!_Utils_eq(model.updatedService.serviceSort, s.serviceSortKey)) || ((!_Utils_eq(model.updatedService.applicationName, a.applicationName)) || ((!_Utils_eq(model.updatedService.applicationSort, a.applicationSortKey)) || ((!_Utils_eq(model.updatedService.applicationTypeKey, a.applicationTypeKey)) || ((!_Utils_eq(model.updatedService.tabName, t.tabName)) || (!_Utils_eq(model.updatedService.tabSort, t.tabSortKey)))))))) ? true : false;
	} else {
		return false;
	}
};
var $author$project$Pages$AppRegistration$renderSideExisting = F2(
	function (model, brand) {
		var settings = $author$project$Elements$Core$default;
		var selectedTabSortOrder = function () {
			var _v7 = model.selectedExistingTab;
			if (_v7.$ === 'Just') {
				var t = _v7.a;
				return $elm$core$String$fromInt(t.tabSortKey);
			} else {
				return '';
			}
		}();
		var selectedServiceSortOrder = function () {
			var _v6 = model.selectedExistingService;
			if (_v6.$ === 'Just') {
				var s = _v6.a;
				return $elm$core$String$fromInt(s.serviceSortKey);
			} else {
				return '';
			}
		}();
		var selectedApplicationSortOrder = function () {
			var _v5 = model.selectedExistingApplication;
			if (_v5.$ === 'Just') {
				var a = _v5.a;
				return $elm$core$String$fromInt(a.applicationSortKey);
			} else {
				return '';
			}
		}();
		var maxTabSortOrder = function () {
			var _v4 = model.selectedExistingApplication;
			if (_v4.$ === 'Just') {
				var application = _v4.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					application.tabs,
					function ($) {
						return $.tabSortKey;
					});
			} else {
				return 1;
			}
		}();
		var tabSortOrderItems = A2(
			$elm$core$List$map,
			function (i) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(i),
					$elm$core$String$fromInt(i));
			},
			A2($elm$core$List$range, 1, maxTabSortOrder));
		var maxServiceSortOrder = function () {
			var _v3 = model.services;
			if (_v3.$ === 'Success') {
				var services = _v3.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					services,
					function ($) {
						return $.serviceSortKey;
					});
			} else {
				return 1;
			}
		}();
		var serviceSortOrderItems = A2(
			$elm$core$List$map,
			function (i) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(i),
					$elm$core$String$fromInt(i));
			},
			A2($elm$core$List$range, 1, maxServiceSortOrder));
		var maxApplicationSortOrder = function () {
			var _v2 = model.selectedExistingService;
			if (_v2.$ === 'Just') {
				var service = _v2.a;
				return A2(
					$author$project$Pages$AppRegistration$getMaxIntByField,
					service.applications,
					function ($) {
						return $.applicationSortKey;
					});
			} else {
				return 1;
			}
		}();
		var isServiceUpdated = $author$project$Pages$AppRegistration$checkIfServiceIsUpdated(model);
		var existingAppTypeKey = function () {
			var _v1 = model.selectedExistingApplication;
			if (_v1.$ === 'Just') {
				var app = _v1.a;
				return app.applicationTypeKey;
			} else {
				return '';
			}
		}();
		var dropdownApplicationTypes = function () {
			var _v0 = model.applicationTypes;
			if (_v0.$ === 'Success') {
				var applicationTypes = _v0.a;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2('Välj applikationstyp', ''),
					A2(
						$elm$core$List$sortBy,
						$elm$core$Tuple$first,
						A2(
							$elm$core$List$map,
							function (at) {
								return _Utils_Tuple2(at.name, at.key);
							},
							applicationTypes)));
			} else {
				return _List_Nil;
			}
		}();
		var applicationSortOrderItems = A2(
			$elm$core$List$map,
			function (i) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(i),
					$elm$core$String$fromInt(i));
			},
			A2($elm$core$List$range, 1, maxApplicationSortOrder));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', 'calc(100% - 40px)'),
					A2($elm$html$Html$Attributes$style, 'height', 'calc(100% - 20px)'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px 20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0 0 20px 0'),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'font-size', '20px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Uppdatera Service/App/Tab')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Service')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							serviceSortOrderItems,
							selectedServiceSortOrder,
							$author$project$Pages$AppRegistration$UpdateExistingSort($author$project$Pages$AppRegistration$ServiceField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateExistingField($author$project$Pages$AppRegistration$ServiceField)),
									margin: '0 0 30px 0'
								}),
							'Service',
							'Servicenamn',
							$elm$core$Maybe$Nothing,
							model.updatedService.serviceName)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Applikation')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Applikationstyp')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							dropdownApplicationTypes,
							existingAppTypeKey,
							$author$project$Pages$AppRegistration$UpdateExistingApplicationType,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							applicationSortOrderItems,
							selectedApplicationSortOrder,
							$author$project$Pages$AppRegistration$UpdateExistingSort($author$project$Pages$AppRegistration$ApplicationField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateExistingField($author$project$Pages$AppRegistration$ApplicationField)),
									margin: '0 0 30px 0'
								}),
							'Applikation',
							'Applikationnamn',
							$elm$core$Maybe$Nothing,
							model.updatedService.applicationName)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'padding', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'font-size', '16px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Tab')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'font-size', '11px'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'margin', '5px 0 0 0')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Sorteringsordning')
								])),
							A6(
							$author$project$Elements$Core$dropdown,
							brand,
							tabSortOrderItems,
							selectedTabSortOrder,
							$author$project$Pages$AppRegistration$UpdateExistingSort($author$project$Pages$AppRegistration$TabField),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'display', 'block'),
									A2($elm$html$Html$Attributes$style, 'width', '50%'),
									A2($elm$html$Html$Attributes$style, 'margin', '0 0 5px 0')
								]),
							true),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just(
										$author$project$Pages$AppRegistration$UpdateExistingField($author$project$Pages$AppRegistration$TabField)),
									margin: '0 0 40px 0'
								}),
							'tabName',
							'Tabnamn',
							$elm$core$Maybe$Nothing,
							model.updatedService.tabName)
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Uppdatera',
					isServiceUpdated,
					isServiceUpdated ? $author$project$Pages$AppRegistration$RequestUpdateService : $author$project$Pages$AppRegistration$NoOp,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'padding', '5px 10px'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Avbryt',
					true,
					$author$project$Pages$AppRegistration$CloseExisting,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '10px 0'),
							A2($elm$html$Html$Attributes$style, 'padding', '5px 10px'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '5px'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]))
				]));
	});
var $author$project$Pages$AppRegistration$SelectExistingService = F3(
	function (a, b, c) {
		return {$: 'SelectExistingService', a: a, b: b, c: c};
	});
var $author$project$Elements$Table$Row = F2(
	function (items, msg) {
		return {items: items, msg: msg};
	});
var $author$project$Elements$Table$Item = F2(
	function (name, styles) {
		return {name: name, styles: styles};
	});
var $author$project$Elements$Table$overflowStyle = function (maxWidth) {
	return _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'white-space', 'nowrap'),
			A2($elm$html$Html$Attributes$style, 'text-overflow', 'ellipsis'),
			A2($elm$html$Html$Attributes$style, 'overflow-x', 'hidden'),
			A2($elm$html$Html$Attributes$style, 'max-width', maxWidth)
		]);
};
var $author$project$Elements$Table$createItem = F3(
	function (overflowWidth, styles, name) {
		if (overflowWidth.$ === 'Just') {
			var width = overflowWidth.a;
			return A2(
				$author$project$Elements$Table$Item,
				name,
				A2(
					$elm$core$List$append,
					$author$project$Elements$Table$overflowStyle(width),
					styles));
		} else {
			return A2($author$project$Elements$Table$Item, name, styles);
		}
	});
var $elm$core$List$map3 = _List_map3;
var $author$project$Elements$Table$createRow2 = F4(
	function (overflowWidths, styles, names, msg) {
		var listOfStyles = function () {
			if (!styles.b) {
				return _List_fromArray(
					[_List_Nil, _List_Nil, _List_Nil]);
			} else {
				var list = styles;
				return list;
			}
		}();
		return A2(
			$author$project$Elements$Table$Row,
			A4($elm$core$List$map3, $author$project$Elements$Table$createItem, overflowWidths, listOfStyles, names),
			msg);
	});
var $elm$html$Html$th = _VirtualDom_node('th');
var $author$project$Elements$Table$renderHeaderItem = F2(
	function (sort, _v0) {
		var index = _v0.a;
		var item = _v0.b;
		var enabledColor = A2($elm$html$Html$Attributes$style, 'color', '#000');
		var disabledColor = A2($elm$html$Html$Attributes$style, 'color', '#e0e0e0');
		var descColor = function () {
			switch (sort.$) {
				case 'None':
					return disabledColor;
				case 'Asc':
					return disabledColor;
				default:
					var columnIndex = sort.a;
					return _Utils_eq(columnIndex, index) ? enabledColor : disabledColor;
			}
		}();
		var ascColor = function () {
			switch (sort.$) {
				case 'None':
					return disabledColor;
				case 'Asc':
					var columnIndex = sort.a;
					return _Utils_eq(columnIndex, index) ? enabledColor : disabledColor;
				default:
					return disabledColor;
			}
		}();
		return A2(
			$elm$html$Html$th,
			item.styles,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[ascColor]),
					_List_fromArray(
						[
							$elm$html$Html$text('▼')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[descColor]),
					_List_fromArray(
						[
							$elm$html$Html$text('▲')
						])),
					$elm$html$Html$text(item.name)
				]));
	});
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Elements$Table$renderHeader = F3(
	function (brand, sort, row) {
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex + '66')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Elements$Table$renderHeaderItem(sort),
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, row.items)));
	});
var $author$project$Elements$Core$renderLoading = F2(
	function (brand, customStyles) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('loader'),
						A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
						A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex)
					]),
				customStyles),
			_List_Nil);
	});
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Elements$Table$renderRowItem = function (item) {
	return A2(
		$elm$html$Html$td,
		item.styles,
		_List_fromArray(
			[
				$elm$html$Html$text(item.name)
			]));
};
var $author$project$Elements$Table$selectedStyle = A2($elm$html$Html$Attributes$style, 'background-color', '#F5F5F5');
var $author$project$Elements$Table$renderRow = F2(
	function (selectedRow, row) {
		var attributes = _Utils_eq(
			$elm$core$Maybe$Just(row),
			selectedRow) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(row.msg),
				$author$project$Elements$Table$selectedStyle
			]) : _List_fromArray(
			[
				$elm$html$Html$Events$onClick(row.msg)
			]);
		return A2(
			$elm$html$Html$tr,
			attributes,
			A2($elm$core$List$map, $author$project$Elements$Table$renderRowItem, row.items));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Elements$Table$renderActionbarSwitch = function (_v0) {
	var index = _v0.a;
	var _switch = _v0.b;
	var switchId = 'switch' + $elm$core$String$fromInt(index);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('switchbox')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Attributes$id(switchId),
						$elm$html$Html$Attributes$name('switch'),
						$elm$html$Html$Attributes$checked(_switch.isChecked)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$for(switchId),
						A2($elm$html$Html$Attributes$attribute, 'data-on', 'on'),
						A2($elm$html$Html$Attributes$attribute, 'data-on', 'off'),
						$elm$html$Html$Events$onClick(_switch.msg)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('on')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(_switch.onText)
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('off')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(_switch.offText)
							]))
					]))
			]));
};
var $author$project$Elements$Table$renderActionbarSwitches = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('switches')
			]),
		A2(
			$elm$core$List$map,
			$author$project$Elements$Table$renderActionbarSwitch,
			A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, config.switches)));
};
var $author$project$Elements$Table$renderActionbarTab = F3(
	function (brand, config, tab) {
		var _v0 = config.tabSelectMsg;
		if (_v0.$ === 'Just') {
			var msg = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('switch-control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('switch'),
								_Utils_eq(
								$elm$core$Maybe$Just(tab),
								config.selectedTab) ? A2($elm$html$Html$Attributes$style, 'background-color', brand.secondary.hex) : A2($elm$html$Html$Attributes$style, 'background-color', '#FFF')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										_Utils_eq(
										$elm$core$Maybe$Just(tab),
										config.selectedTab) ? A2($elm$html$Html$Attributes$style, 'color', '#FFF') : A2($elm$html$Html$Attributes$style, 'color', brand.secondary.hex)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(tab.text)
									]))
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('switch-control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('switch'),
								_Utils_eq(
								$elm$core$Maybe$Just(tab),
								config.selectedTab) ? A2($elm$html$Html$Attributes$style, 'background-color', brand.secondary.hex) : A2($elm$html$Html$Attributes$style, 'background-color', '#FFF')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										_Utils_eq(
										$elm$core$Maybe$Just(tab),
										config.selectedTab) ? A2($elm$html$Html$Attributes$style, 'color', '#FFF') : A2($elm$html$Html$Attributes$style, 'color', brand.secondary.hex)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(tab.text)
									]))
							]))
					]));
		}
	});
var $author$project$Elements$Table$renderActionbarTabs = F2(
	function (brand, config) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('switch-control')
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Elements$Table$renderActionbarTab, brand, config),
				config.tabs));
	});
var $author$project$Elements$Core$renderTextbox = F6(
	function (theme, for_, text_, icon, divAttr, inputAttr) {
		var labelContent = function () {
			if (text_.$ === 'Just') {
				var s = text_.a;
				return $elm$core$String$isEmpty(icon) ? _List_fromArray(
					[
						$elm$html$Html$text(s)
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icon)
							])),
						$elm$html$Html$text(s)
					]);
			} else {
				return $elm$core$String$isEmpty(icon) ? _List_Nil : _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icon)
							]))
					]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('textbox-default')
					]),
				divAttr),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(for_),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'color', theme.hex)
						]),
					labelContent),
					A2(
					$elm$html$Html$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$name(for_),
								A2($elm$html$Html$Attributes$style, 'border-bottom', '3px solid ' + theme.hex)
							]),
						inputAttr),
					_List_Nil)
				]));
	});
var $author$project$Elements$Core$renderSearch = F4(
	function (brand, id, attr, msg) {
		return A6(
			$author$project$Elements$Core$renderTextbox,
			brand.primary,
			'Search',
			$elm$core$Maybe$Nothing,
			'search',
			attr,
			_List_fromArray(
				[
					$elm$html$Html$Events$onInput(
					msg(id))
				]));
	});
var $author$project$Elements$Table$renderTableActionbar = F2(
	function (config, brand) {
		var tabs = A2($author$project$Elements$Table$renderActionbarTabs, brand, config);
		var switches = $author$project$Elements$Table$renderActionbarSwitches(config);
		var search = function () {
			var _v0 = _Utils_Tuple2(config.search, config.searchAction);
			if (_v0.a && (_v0.b.$ === 'Just')) {
				var action = _v0.b.a;
				return A4(
					$author$project$Elements$Core$renderSearch,
					brand,
					'userinfo',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '250px'),
							A2($elm$html$Html$Attributes$style, 'height', '100%'),
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
						]),
					action);
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var loading = config.isLoading ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-loading')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Elements$Core$renderLoading,
					brand,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '3px')
						]))
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-loading')
				]),
			_List_Nil);
		var dropdowns = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'height', '100%')
				]),
			A2(
				$elm$core$List$map,
				function (d) {
					return A6($author$project$Elements$Core$dropdown, brand, d.options, d.selected, d.msg, _List_Nil, true);
				},
				config.dropdowns));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('topbar-content'),
					A2($elm$html$Html$Attributes$style, 'height', '25px')
				]),
			_List_fromArray(
				[dropdowns, switches, tabs, search]));
	});
var $author$project$Elements$Table$ascend = F3(
	function (index, items1, items2) {
		var _v0 = _Utils_Tuple2(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.name;
				},
				items1),
			A2(
				$elm$core$List$map,
				function ($) {
					return $.name;
				},
				items2));
		var names1 = _v0.a;
		var names2 = _v0.b;
		var _v1 = _Utils_Tuple2(
			A2($elm_community$list_extra$List$Extra$getAt, index, names1),
			A2($elm_community$list_extra$List$Extra$getAt, index, names2));
		if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
			var name1 = _v1.a.a;
			var name2 = _v1.b.a;
			var _v2 = A2($elm$core$Basics$compare, name1, name2);
			switch (_v2.$) {
				case 'LT':
					return $elm$core$Basics$LT;
				case 'EQ':
					return $elm$core$Basics$EQ;
				default:
					return $elm$core$Basics$GT;
			}
		} else {
			return $elm$core$Basics$EQ;
		}
	});
var $author$project$Elements$Table$descend = F3(
	function (index, items1, items2) {
		var _v0 = _Utils_Tuple2(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.name;
				},
				items1),
			A2(
				$elm$core$List$map,
				function ($) {
					return $.name;
				},
				items2));
		var names1 = _v0.a;
		var names2 = _v0.b;
		var _v1 = _Utils_Tuple2(
			A2($elm_community$list_extra$List$Extra$getAt, index, names1),
			A2($elm_community$list_extra$List$Extra$getAt, index, names2));
		if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
			var name1 = _v1.a.a;
			var name2 = _v1.b.a;
			var _v2 = A2($elm$core$Basics$compare, name1, name2);
			switch (_v2.$) {
				case 'LT':
					return $elm$core$Basics$GT;
				case 'EQ':
					return $elm$core$Basics$EQ;
				default:
					return $elm$core$Basics$LT;
			}
		} else {
			return $elm$core$Basics$EQ;
		}
	});
var $author$project$Elements$Table$sortRows = F2(
	function (sort, rows) {
		return A2(
			$elm$core$List$sortWith,
			F2(
				function (r1, r2) {
					switch (sort.$) {
						case 'Asc':
							var index = sort.a;
							return A3($author$project$Elements$Table$ascend, index, r1.items, r2.items);
						case 'Desc':
							var index = sort.a;
							return A3($author$project$Elements$Table$descend, index, r1.items, r2.items);
						default:
							return $elm$core$Basics$EQ;
					}
				}),
			rows);
	});
var $elm$html$Html$table = _VirtualDom_node('table');
var $author$project$Elements$Table$renderTable = F5(
	function (config, brand, header, rows, selectedRow) {
		var sortedRows = A2($author$project$Elements$Table$sortRows, config.sort, rows);
		var content = (config.isLoading && $elm$core$List$isEmpty(rows)) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('load-wrapper')
				]),
			_List_fromArray(
				[
					A2($author$project$Elements$Core$renderLoading, brand, _List_Nil)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('load-wrapper')
				]),
			_List_Nil);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%')
				]),
			_List_fromArray(
				[
					A2($author$project$Elements$Table$renderTableActionbar, config, brand),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('default')
						]),
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								A3($author$project$Elements$Table$renderHeader, brand, config.sort, header)
							]),
						A2(
							$elm$core$List$map,
							$author$project$Elements$Table$renderRow(selectedRow),
							sortedRows))),
					content
				]));
	});
var $author$project$Pages$AppRegistration$tableColumnWidths = _List_fromArray(
	[
		$elm$core$Maybe$Just('450px'),
		$elm$core$Maybe$Just('450px'),
		$elm$core$Maybe$Just('450px')
	]);
var $author$project$Pages$AppRegistration$tableConfig = {
	buttons: _List_Nil,
	dropdowns: _List_Nil,
	isLoading: true,
	search: false,
	searchAction: $elm$core$Maybe$Nothing,
	selectedTab: $elm$core$Maybe$Nothing,
	sort: $author$project$Elements$Table$Asc(0),
	switches: _List_Nil,
	tabSelectMsg: $elm$core$Maybe$Nothing,
	tabs: _List_Nil
};
var $author$project$Pages$AppRegistration$renderTable = F2(
	function (model, brand) {
		var tableRow = F3(
			function (service, app, tab) {
				return A4(
					$author$project$Elements$Table$createRow2,
					$author$project$Pages$AppRegistration$tableColumnWidths,
					_List_Nil,
					_List_fromArray(
						[service.serviceName, app.applicationName, tab.tabName]),
					A3($author$project$Pages$AppRegistration$SelectExistingService, service, app, tab));
			});
		var tableHeader = A4(
			$author$project$Elements$Table$createRow2,
			$author$project$Pages$AppRegistration$tableColumnWidths,
			_List_Nil,
			_List_fromArray(
				['Service', 'Applikation', 'Tab']),
			$author$project$Pages$AppRegistration$NoOp);
		var services = function () {
			var _v1 = model.services;
			if (_v1.$ === 'Success') {
				var s = _v1.a;
				return s;
			} else {
				return _List_Nil;
			}
		}();
		var tableRows = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (a) {
								return A2(
									$elm$core$List$map,
									A2(tableRow, s, a),
									a.tabs);
							},
							s.applications));
				},
				services));
		var selectedTabRow = function () {
			var _v0 = _Utils_Tuple3(model.selectedExistingService, model.selectedExistingApplication, model.selectedExistingTab);
			if (((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) && (_v0.c.$ === 'Just')) {
				var s = _v0.a.a;
				var a = _v0.b.a;
				var t = _v0.c.a;
				return $elm$core$Maybe$Just(
					A3(tableRow, s, a, t));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var table = A5($author$project$Elements$Table$renderTable, $author$project$Pages$AppRegistration$tableConfig, brand, tableHeader, tableRows, selectedTabRow);
		return table;
	});
var $author$project$Pages$AppRegistration$view = F2(
	function (model, brand) {
		var sidePanel = function () {
			var _v0 = model.selectedExistingService;
			if (_v0.$ === 'Just') {
				return A2($author$project$Pages$AppRegistration$renderSideExisting, model, brand);
			} else {
				return A2($author$project$Pages$AppRegistration$renderSide, model, brand);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-full')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-main')
						]),
					_List_fromArray(
						[
							A2($author$project$Pages$AppRegistration$renderTable, model, brand)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('settings-sidepanel')
						]),
					_List_fromArray(
						[sidePanel]))
				]));
	});
var $author$project$Pages$Branding$Apply = {$: 'Apply'};
var $author$project$Pages$Branding$RequestSaveBrand = {$: 'RequestSaveBrand'};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $author$project$Elements$ColorPicker$renderTextbox = F2(
	function (value, attrbiutes) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '26.6667%')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('text'),
								A2($elm$html$Html$Attributes$style, 'border-bottom', '3px solid #000'),
								$elm$html$Html$Attributes$value(value)
							]),
						attrbiutes),
					_List_Nil)
				]));
	});
var $author$project$Elements$ColorPicker$renderHexValue = F2(
	function (hex, event) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('--color-values')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'width', '20%')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Hex')
						])),
					A2(
					$author$project$Elements$ColorPicker$renderTextbox,
					hex,
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput(event)
						]))
				]));
	});
var $author$project$Common$Color$Hue = {$: 'Hue'};
var $author$project$Common$Color$Luminance = {$: 'Luminance'};
var $author$project$Common$Color$Saturation = {$: 'Saturation'};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Elements$ColorPicker$onKeyDownHsl = F2(
	function (hslInput, tagger) {
		return A2(
			$elm$html$Html$Events$on,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				tagger(hslInput),
				$elm$html$Html$Events$keyCode));
	});
var $author$project$Elements$ColorPicker$renderHslValues = F3(
	function (hsl, event, keyEvent) {
		var _v0 = function () {
			if (keyEvent.$ === 'Just') {
				var e = keyEvent.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.hue),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Hue)),
								A2($author$project$Elements$ColorPicker$onKeyDownHsl, $author$project$Common$Color$Hue, e)
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.saturation),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Saturation)),
								A2($author$project$Elements$ColorPicker$onKeyDownHsl, $author$project$Common$Color$Saturation, e)
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.luminance),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Luminance)),
								A2($author$project$Elements$ColorPicker$onKeyDownHsl, $author$project$Common$Color$Luminance, e)
							])));
			} else {
				return _Utils_Tuple3(
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.hue),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Hue))
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.saturation),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Saturation))
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(hsl.luminance),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Luminance))
							])));
			}
		}();
		var hBox = _v0.a;
		var sBox = _v0.b;
		var lBox = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('--color-values')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'width', '20%')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('HSL')
						])),
					hBox,
					sBox,
					lBox
				]));
	});
var $author$project$Common$Color$Blue = {$: 'Blue'};
var $author$project$Common$Color$Green = {$: 'Green'};
var $author$project$Common$Color$Red = {$: 'Red'};
var $author$project$Elements$ColorPicker$onKeyDownRgb = F2(
	function (rgbInput, tagger) {
		return A2(
			$elm$html$Html$Events$on,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				tagger(rgbInput),
				$elm$html$Html$Events$keyCode));
	});
var $author$project$Elements$ColorPicker$renderRgbValues = F3(
	function (rgb, event, keyEvent) {
		var _v0 = function () {
			if (keyEvent.$ === 'Just') {
				var e = keyEvent.a;
				return _Utils_Tuple3(
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.red),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Red)),
								A2($author$project$Elements$ColorPicker$onKeyDownRgb, $author$project$Common$Color$Red, e)
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.green),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Green)),
								A2($author$project$Elements$ColorPicker$onKeyDownRgb, $author$project$Common$Color$Green, e)
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.blue),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Blue)),
								A2($author$project$Elements$ColorPicker$onKeyDownRgb, $author$project$Common$Color$Blue, e)
							])));
			} else {
				return _Utils_Tuple3(
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.red),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Red))
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.green),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Green))
							])),
					A2(
						$author$project$Elements$ColorPicker$renderTextbox,
						$elm$core$String$fromInt(rgb.blue),
						_List_fromArray(
							[
								$elm$html$Html$Events$onInput(
								event($author$project$Common$Color$Blue))
							])));
			}
		}();
		var redBox = _v0.a;
		var greenBox = _v0.b;
		var blueBox = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('--color-values')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'width', '20%')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('RGB')
						])),
					redBox,
					greenBox,
					blueBox
				]));
	});
var $elm$html$Html$canvas = _VirtualDom_node('canvas');
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Elements$ColorPicker$Position = F4(
	function (offsetX, offsetY, offsetHeight, offsetWidth) {
		return {offsetHeight: offsetHeight, offsetWidth: offsetWidth, offsetX: offsetX, offsetY: offsetY};
	});
var $author$project$Elements$ColorPicker$positionDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Elements$ColorPicker$Position,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['offsetX']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['offsetY']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['target', 'offsetHeight']),
		$elm$json$Json$Decode$float),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['target', 'offsetWidth']),
		$elm$json$Json$Decode$float));
var $author$project$Elements$ColorPicker$renderSaturationAndLuminancePicker = F3(
	function (settings, hsl, cursor) {
		var marginStyleCalc = function (pos) {
			return 'calc(' + ($elm$core$String$fromFloat(pos) + '% - 9px)');
		};
		var testX = marginStyleCalc(hsl.saturation);
		var testY = marginStyleCalc(100 - hsl.luminance);
		var marginStyle = function (pos) {
			return $elm$core$String$fromInt(pos) + 'px';
		};
		var cursorY = marginStyle(cursor.position.offsetY - 9);
		var cursorX = marginStyleCalc((cursor.position.offsetX / cursor.position.offsetWidth) * 100);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('--saturation-luminance')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('--colorpicker-overlay'),
							A2($elm$html$Html$Attributes$style, 'background-color', 'transparent'),
							A2($elm$html$Html$Attributes$style, 'width', settings.width),
							A2($elm$html$Html$Attributes$style, 'height', settings.height),
							A2(
							$elm$html$Html$Events$on,
							'mousedown',
							A2($elm$json$Json$Decode$map, settings.cursorDown, $author$project$Elements$ColorPicker$positionDecoder)),
							A2(
							$elm$html$Html$Events$on,
							'mouseup',
							A2($elm$json$Json$Decode$map, settings.cursorUp, $author$project$Elements$ColorPicker$positionDecoder)),
							A2(
							$elm$html$Html$Events$on,
							'mousemove',
							A2($elm$json$Json$Decode$map, settings.cursorMove, $author$project$Elements$ColorPicker$positionDecoder)),
							A2(
							$elm$html$Html$Events$on,
							'mouseleave',
							A2($elm$json$Json$Decode$map, settings.cursorLeave, $author$project$Elements$ColorPicker$positionDecoder)),
							A2($elm$html$Html$Attributes$style, 'z-index', '999')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cursor'),
							A2(
							$elm$html$Html$Attributes$style,
							'background-color',
							'hsl(' + ($elm$core$String$fromInt(hsl.hue) + (', ' + ($elm$core$String$fromInt(hsl.saturation) + ('%, ' + ($elm$core$String$fromInt(hsl.luminance) + '%)')))))),
							A2(
							$elm$html$Html$Attributes$style,
							'border-color',
							'hsl(' + ($elm$core$String$fromInt(hsl.hue) + (', ' + ($elm$core$String$fromInt(100 - hsl.saturation) + ('%, ' + ($elm$core$String$fromInt(100 - hsl.luminance) + '%)')))))),
							A2($elm$html$Html$Attributes$style, 'left', testX),
							A2($elm$html$Html$Attributes$style, 'top', testY),
							A2($elm$html$Html$Attributes$style, 'position', 'absolute')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$canvas,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(settings.id),
							$elm$html$Html$Attributes$class('color'),
							A2(
							$elm$html$Html$Attributes$style,
							'background-color',
							'hsl(' + ($elm$core$String$fromInt(hsl.hue) + ', 100%, 50%)')),
							A2($elm$html$Html$Attributes$style, 'width', settings.width),
							A2($elm$html$Html$Attributes$style, 'height', settings.height)
						]),
					_List_Nil)
				]));
	});
var $author$project$Elements$ColorPicker$inputDecoder = A2(
	$elm$json$Json$Decode$map,
	function (value) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(value));
	},
	$elm$html$Html$Events$targetValue);
var $author$project$Elements$ColorPicker$sliderOnInput = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'input',
		A2($elm$json$Json$Decode$map, msg, $author$project$Elements$ColorPicker$inputDecoder));
};
var $author$project$Elements$ColorPicker$view = F3(
	function (settings, color, cursor) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('colorpicker'),
					A2($elm$html$Html$Attributes$style, 'width', settings.width)
				]),
			_List_fromArray(
				[
					A3($author$project$Elements$ColorPicker$renderSaturationAndLuminancePicker, settings, color.hsl, cursor),
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('color-slider'),
							A2($elm$html$Html$Attributes$style, 'width', settings.width),
							A2(
							$elm$html$Html$Attributes$style,
							'background-color',
							'hsl(' + ($elm$core$String$fromInt(color.hsl.hue) + ', 100%, 50%)')),
							$elm$html$Html$Attributes$type_('range'),
							$elm$html$Html$Attributes$min('0'),
							$elm$html$Html$Attributes$max('360'),
							$elm$html$Html$Attributes$value(
							$elm$core$String$fromInt(color.hsl.hue)),
							$author$project$Elements$ColorPicker$sliderOnInput(settings.sliderOnInput)
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'border', '2px solid #e0e0e0'),
							A2($elm$html$Html$Attributes$style, 'border-radius', '10px'),
							A2($elm$html$Html$Attributes$style, 'padding', '7px')
						]),
					_List_fromArray(
						[
							A2($author$project$Elements$ColorPicker$renderHexValue, color.hex, settings.hexInputEvent),
							A3($author$project$Elements$ColorPicker$renderRgbValues, color.rgb, settings.rgbInputEvent, settings.rgbKeyUpDown),
							A3($author$project$Elements$ColorPicker$renderHslValues, color.hsl, settings.hslInputEvent, settings.hslKeyUpDown)
						]))
				]));
	});
var $author$project$Pages$Branding$renderBrandingSidepanel = F3(
	function (model, brand, lang) {
		var mobilePreview = model.session.isMobile ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('m-preview'),
						A2(
						$elm$html$Html$Attributes$style,
						'background-color',
						_Utils_eq(model.selection, $author$project$Pages$Branding$Primary) ? model.color.hex : model.color2.hex)
					]),
				_List_Nil)
			]) : _List_Nil;
		var colorPickerView = function () {
			var _v0 = model.selection;
			if (_v0.$ === 'Primary') {
				return A3($author$project$Elements$ColorPicker$view, $author$project$Pages$Branding$pickerSettings, model.color, model.colorpickerSettings.cursor);
			} else {
				return A3($author$project$Elements$ColorPicker$view, $author$project$Pages$Branding$pickerSettings, model.color2, model.colorpickerSettings.cursor);
			}
		}();
		var buttons = model.session.isMobile ? _List_Nil : _List_fromArray(
			[
				A5(
				$author$project$Elements$Core$renderButton,
				brand.primary,
				lang.settings.save,
				true,
				$author$project$Pages$Branding$RequestSaveBrand,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
						A2($elm$html$Html$Attributes$style, 'margin-right', '10px')
					])),
				A5(
				$author$project$Elements$Core$renderButton,
				brand.primary,
				lang.settings.apply,
				true,
				$author$project$Pages$Branding$Apply,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'inline-block')
					]))
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-content'),
					A2($elm$html$Html$Attributes$style, 'margin-top', '0'),
					A2($elm$html$Html$Attributes$style, 'text-align', 'left')
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[colorPickerView]),
				A2($elm$core$List$append, mobilePreview, buttons)));
	});
var $author$project$Pages$Branding$UpdateBrandTitle = function (a) {
	return {$: 'UpdateBrandTitle', a: a};
};
var $author$project$Pages$Branding$UpdateFaviconUrl = function (a) {
	return {$: 'UpdateFaviconUrl', a: a};
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Pages$Branding$Secondary = {$: 'Secondary'};
var $author$project$Pages$Branding$Select = function (a) {
	return {$: 'Select', a: a};
};
var $author$project$Common$Color$getColorContrast = function (rgb) {
	return ((((rgb.red * 0.299) + (rgb.green * 0.587)) + (rgb.blue * 0.114)) > 186) ? '#000' : '#FFF';
};
var $author$project$Pages$Branding$renderColorPreview = function (model) {
	var secondaryStyle = function () {
		var _v1 = model.selection;
		if (_v1.$ === 'Secondary') {
			return _List_fromArray(
				[
					model.session.isMobile ? A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(model.brand.secondary.rgb)) : A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(
						$author$project$Common$Color$convertHexToRgb(
							$author$project$Common$Color$getColorContrast(model.brand.secondary.rgb)))),
					model.session.isMobile ? A2($elm$html$Html$Attributes$style, 'background-color', 'none') : A2(
					$elm$html$Html$Attributes$style,
					'background-color',
					$author$project$Common$Color$getColorContrast(model.brand.secondary.rgb))
				]);
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(model.brand.secondary.rgb)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'transparent')
				]);
		}
	}();
	var primaryStyle = function () {
		var _v0 = model.selection;
		if (_v0.$ === 'Primary') {
			return _List_fromArray(
				[
					model.session.isMobile ? A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(model.brand.primary.rgb)) : A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(
						$author$project$Common$Color$convertHexToRgb(
							$author$project$Common$Color$getColorContrast(model.brand.primary.rgb)))),
					model.session.isMobile ? A2($elm$html$Html$Attributes$style, 'background-color', 'none') : A2(
					$elm$html$Html$Attributes$style,
					'background-color',
					$author$project$Common$Color$getColorContrast(model.brand.primary.rgb))
				]);
		} else {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(model.brand.primary.rgb)),
					A2($elm$html$Html$Attributes$style, 'background-color', 'transparent')
				]);
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('--preview')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '140px'),
						A2($elm$html$Html$Attributes$style, 'width', '50%'),
						$elm$html$Html$Events$onClick(
						$author$project$Pages$Branding$Select($author$project$Pages$Branding$Primary))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('selected', true)
									])),
								A2($elm$html$Html$Attributes$style, 'background-color', model.brand.primary.hex),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'height', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								A2(
									$elm$core$List$append,
									primaryStyle,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'padding', '0 5px'),
											A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
											A2($elm$html$Html$Attributes$style, 'margin', '0')
										])),
								_List_fromArray(
									[
										$elm$html$Html$text('Primär')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '140px'),
						A2($elm$html$Html$Attributes$style, 'width', '50%'),
						$elm$html$Html$Events$onClick(
						$author$project$Pages$Branding$Select($author$project$Pages$Branding$Secondary))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('selected', true)
									])),
								A2($elm$html$Html$Attributes$style, 'background-color', model.brand.secondary.hex),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'height', '100%')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								A2(
									$elm$core$List$append,
									secondaryStyle,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'padding', '0 5px'),
											A2($elm$html$Html$Attributes$style, 'border-radius', '20px'),
											A2($elm$html$Html$Attributes$style, 'margin', '0')
										])),
								_List_fromArray(
									[
										$elm$html$Html$text('Sekundär')
									]))
							]))
					]))
			]));
};
var $author$project$Pages$Branding$Check = function (a) {
	return {$: 'Check', a: a};
};
var $author$project$Pages$Branding$checkbox = F3(
	function (checkboxId, checkboxText, values) {
		var value = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, checkboxId, values));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(checkboxId),
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(value),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Branding$Check(checkboxId))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(checkboxId),
							A2($elm$html$Html$Attributes$style, 'color', '#000'),
							A2($elm$html$Html$Attributes$style, 'margin-left', '5px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(checkboxText)
						]))
				]));
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$Pages$Branding$renderOptions = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'padding', '0px 10px'),
				A2($elm$html$Html$Attributes$style, 'text-align', 'left')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h4,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'margin-bottom', '5px'),
						A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Inställningar')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'margin-left', '10px')
					]),
				_List_fromArray(
					[
						A3($author$project$Pages$Branding$checkbox, $author$project$Pages$Branding$showTitleInHeader, 'Visa titel i header', model.checked),
						A3($author$project$Pages$Branding$checkbox, $author$project$Pages$Branding$showLogoInHeader, 'Visa logga i header', model.checked),
						A3($author$project$Pages$Branding$checkbox, $author$project$Pages$Branding$showTitleInAnnouncements, 'Visa titel på sidan \"Notiser\"', model.checked),
						A3($author$project$Pages$Branding$checkbox, $author$project$Pages$Branding$showLogoInAnnouncements, 'Visa logga på sidan  \"Notiser\"', model.checked),
						A3($author$project$Pages$Branding$checkbox, $author$project$Pages$Branding$useColorInHeaderAndNav, 'Använd färg i header och navbar', model.checked)
					]))
			]));
};
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Pages$Branding$renderPreview = F3(
	function (model, brand, lang) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('preview')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('top')
						]),
					_List_fromArray(
						[
							A5(
							$author$project$Elements$Core$textbox,
							{
								blur: $elm$core$Maybe$Nothing,
								color: brand.primary.hex,
								display: 'block',
								height: '',
								input: $elm$core$Maybe$Just($author$project$Pages$Branding$UpdateBrandTitle),
								margin: 'auto',
								readonly: false,
								width: '100%'
							},
							'title',
							'Titel',
							$elm$core$Maybe$Nothing,
							model.brand.title),
							A5(
							$author$project$Elements$Core$textbox,
							{
								blur: $elm$core$Maybe$Nothing,
								color: brand.primary.hex,
								display: 'block',
								height: '',
								input: $elm$core$Maybe$Just($author$project$Pages$Branding$UpdateFaviconUrl),
								margin: 'auto',
								readonly: false,
								width: '100%'
							},
							'favicon',
							'Favicon URL',
							$elm$core$Maybe$Nothing,
							model.brand.faviconUrl)
						])),
					$author$project$Pages$Branding$renderColorPreview(model),
					$author$project$Pages$Branding$renderOptions(model),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(model.logoUrl),
							A2($elm$html$Html$Attributes$style, 'display', 'block'),
							A2($elm$html$Html$Attributes$style, 'width', '80px'),
							A2($elm$html$Html$Attributes$style, 'margin', '20px auto')
						]),
					_List_Nil)
				]));
	});
var $author$project$Pages$Branding$viewDesktop = F3(
	function (model, brand, lang) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'text-align', 'center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-main')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Branding$renderPreview, model, brand, lang)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('settings-sidepanel')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Branding$renderBrandingSidepanel, model, brand, lang)
						]))
				]));
	});
var $author$project$Pages$Branding$MobileToggleView = {$: 'MobileToggleView'};
var $author$project$Pages$Branding$viewMobile = F3(
	function (model, brand, lang) {
		var textColorPrimary = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.primary.rgb));
		var buttons = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'bottom', '0')
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Elements$Core$renderButton,
						brand.primary,
						lang.settings.apply,
						true,
						$author$project$Pages$Branding$Apply,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'display', 'block'),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'margin-bottom', '2px'),
								A2($elm$html$Html$Attributes$style, 'padding', '10px 0')
							])),
						A5(
						$author$project$Elements$Core$renderButton,
						brand.primary,
						lang.settings.save,
						true,
						$author$project$Pages$Branding$RequestSaveBrand,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'display', 'block'),
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'margin', '0'),
								A2($elm$html$Html$Attributes$style, 'padding', '10px 0')
							]))
					]))
			]);
		var _v0 = model.mobileView;
		if (_v0.$ === 'Browse') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('management-main'),
						A2($elm$html$Html$Attributes$style, 'position', 'relative')
					]),
				A2(
					$elm$core$List$cons,
					A3($author$project$Pages$Branding$renderPreview, model, brand, lang),
					buttons));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings-sidepanel')
					]),
				_List_fromArray(
					[
						A3($author$project$Pages$Branding$renderBrandingSidepanel, model, brand, lang),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('m-nav'),
								A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
								$elm$html$Html$Events$onClick($author$project$Pages$Branding$MobileToggleView)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons'),
										textColorPrimary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('arrow_back_ios')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[textColorPrimary]),
								_List_fromArray(
									[
										$elm$html$Html$text('Tillbaka')
									]))
							]))
					]));
		}
	});
var $author$project$Pages$Branding$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$Branding$viewMobile, model, brand, lang) : A3($author$project$Pages$Branding$viewDesktop, model, brand, lang);
	});
var $author$project$Pages$Customers$NoOp = {$: 'NoOp'};
var $author$project$Pages$Customers$RemoveCustomer = function (a) {
	return {$: 'RemoveCustomer', a: a};
};
var $author$project$Pages$Customers$ToggleConfirmation = function (a) {
	return {$: 'ToggleConfirmation', a: a};
};
var $author$project$Elements$Core$notificationOverlay = F7(
	function (color, show, continueMsg, closeMsg, title, contentText, customComponent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('notification', true),
							_Utils_Tuple2('show', show)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('notification-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(contentText)
								])),
							customComponent,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('notification-actions')
								]),
							_List_fromArray(
								[
									A5($author$project$Elements$Core$renderButton, color, 'Fortsätt', true, continueMsg, _List_Nil),
									A5($author$project$Elements$Core$renderButton, color, 'Stäng', true, closeMsg, _List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('notification-overlay'),
							$elm$html$Html$Events$onClick(closeMsg)
						]),
					_List_Nil)
				]));
	});
var $author$project$Pages$Customers$SelectCustomer = function (a) {
	return {$: 'SelectCustomer', a: a};
};
var $author$project$Pages$Customers$UpdateTableSort = function (a) {
	return {$: 'UpdateTableSort', a: a};
};
var $author$project$Pages$Customers$renderContent = F2(
	function (model, brand) {
		var selectedRow = $elm$core$Maybe$Just(
			A4(
				$author$project$Elements$Table$createRow2,
				_List_fromArray(
					[
						$elm$core$Maybe$Just('50%'),
						$elm$core$Maybe$Just('50%')
					]),
				_List_Nil,
				_List_fromArray(
					[model.selectedCustomer.customerName, model.selectedCustomer.customerDomain]),
				$author$project$Pages$Customers$SelectCustomer(model.selectedCustomer)));
		var customers = function () {
			var _v0 = model.customers;
			if (_v0.$ === 'Success') {
				var c = _v0.a;
				return c;
			} else {
				return _List_Nil;
			}
		}();
		var customerRows = A2(
			$elm$core$List$map,
			function (c) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('50%'),
							$elm$core$Maybe$Just('50%')
						]),
					_List_Nil,
					_List_fromArray(
						[c.customerName, c.customerDomain]),
					$author$project$Pages$Customers$SelectCustomer(c));
			},
			customers);
		var customerHeader = A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('50%'),
					$elm$core$Maybe$Just('50%')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$Customers$UpdateTableSort(0))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$Customers$UpdateTableSort(1))
					])
				]),
			_List_fromArray(
				['Customer Name', 'Domain']),
			$author$project$Pages$Customers$NoOp);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content')
				]),
			_List_fromArray(
				[
					A5($author$project$Elements$Table$renderTable, model.tableSettings, brand, customerHeader, customerRows, selectedRow)
				]));
	});
var $author$project$Pages$Customers$UpdateCustomerDefaultIssuer = function (a) {
	return {$: 'UpdateCustomerDefaultIssuer', a: a};
};
var $author$project$Pages$Customers$UpdateCustomerDomain = function (a) {
	return {$: 'UpdateCustomerDomain', a: a};
};
var $author$project$Pages$Customers$UpdateCustomerName = function (a) {
	return {$: 'UpdateCustomerName', a: a};
};
var $author$project$Pages$Customers$AddCustomer = function (a) {
	return {$: 'AddCustomer', a: a};
};
var $author$project$Pages$Customers$CreateNewCustomer = {$: 'CreateNewCustomer'};
var $author$project$Pages$Customers$renderSidepanelButtons = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'row'),
					A2($elm$html$Html$Attributes$style, 'padding', '10px 0')
				]),
			_List_fromArray(
				[
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'New',
					true,
					$author$project$Pages$Customers$CreateNewCustomer,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-right', '10px')
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Add',
					(!$elm$core$String$isEmpty(model.selectedCustomer.customerName)) && ((!$elm$core$String$isEmpty(model.selectedCustomer.customerDomain)) && _Utils_eq(model.mode, $author$project$Pages$Customers$Create)),
					$author$project$Pages$Customers$AddCustomer(model.selectedCustomer),
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin-right', '10px')
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Remove',
					(model.selectedCustomer.customerName !== 'Business Vision') && (!_Utils_eq(model.mode, $author$project$Pages$Customers$Create)),
					$author$project$Pages$Customers$ToggleConfirmation(true),
					_List_Nil)
				]));
	});
var $author$project$Pages$Customers$renderSidepanel = F2(
	function (model, brand) {
		var textboxDefaultSettings = $author$project$Elements$Core$default;
		var loading = function () {
			var _v0 = model.addCustomerMessage;
			switch (_v0.$) {
				case 'Loading':
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('load-wrapper'),
								A2($elm$html$Html$Attributes$style, 'z-index', '0')
							]),
						_List_fromArray(
							[
								A2($author$project$Elements$Core$renderLoading, brand, _List_Nil)
							]));
				case 'Success':
					var message = _v0.a;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('')
							]));
				case 'Failed':
					var err = _v0.a;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$Debug$toString(err))
							]));
				default:
					return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var isReadonly = _Utils_eq(model.mode, $author$project$Pages$Customers$View);
		var settings = _Utils_update(
			textboxDefaultSettings,
			{color: brand.primary.hex, readonly: isReadonly});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidepanel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sidepanel-content')
						]),
					_List_fromArray(
						[
							A2($author$project$Pages$Customers$renderSidepanelButtons, model, brand),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just($author$project$Pages$Customers$UpdateCustomerName)
								}),
							'customerName',
							'Customer Name',
							$elm$core$Maybe$Nothing,
							model.selectedCustomer.customerName),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just($author$project$Pages$Customers$UpdateCustomerDomain)
								}),
							'domainName',
							'Domain (i.e. contoso.se)',
							$elm$core$Maybe$Nothing,
							model.selectedCustomer.customerDomain),
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									input: $elm$core$Maybe$Just($author$project$Pages$Customers$UpdateCustomerDefaultIssuer)
								}),
							'deaultissuer',
							'Default Issuer',
							$elm$core$Maybe$Nothing,
							model.selectedCustomer.defaultIssuer),
							loading
						]))
				]));
	});
var $author$project$Pages$Customers$updateResult = F2(
	function (model, brand) {
		var loading = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('load-wrapper')
				]),
			_List_fromArray(
				[
					A2($author$project$Elements$Core$renderLoading, brand, _List_Nil)
				]));
		var _v0 = model.removedCustomerMessage;
		switch (_v0.$) {
			case 'Success':
				var message = _v0.a;
				return $elm$core$String$isEmpty(message) ? A2($elm$html$Html$div, _List_Nil, _List_Nil) : A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
							A2($elm$html$Html$Attributes$style, 'color', 'red'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '50px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Customer added!')
						]));
			case 'Loading':
				return loading;
			case 'Failed':
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'text-align', 'center'),
							A2($elm$html$Html$Attributes$style, 'color', 'red'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '50px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Could not retrieve response')
						]));
			default:
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		}
	});
var $author$project$Pages$Customers$view = F2(
	function (model, brand) {
		var _v0 = _Utils_Tuple2('Remove ' + (model.selectedCustomer.customerName + '?'), 'This action will remove all roles, purchases and connections for ' + model.selectedCustomer.customerName);
		var confirmationTitle = _v0.a;
		var confirmationText = _v0.b;
		var _v1 = function () {
			var _v2 = model.removedCustomerMessage;
			if (_v2.$ === 'Loading') {
				return _Utils_Tuple2($author$project$Pages$Customers$NoOp, $author$project$Pages$Customers$NoOp);
			} else {
				return _Utils_Tuple2(
					$author$project$Pages$Customers$RemoveCustomer(model.selectedCustomer),
					$author$project$Pages$Customers$ToggleConfirmation(false));
			}
		}();
		var continueMsg = _v1.a;
		var closeMsg = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Customers$renderContent, model, brand),
					A2($author$project$Pages$Customers$renderSidepanel, model, brand),
					A7(
					$author$project$Elements$Core$notificationOverlay,
					brand.primary,
					model.showConfirmation,
					continueMsg,
					closeMsg,
					confirmationTitle,
					confirmationText,
					A2($author$project$Pages$Customers$updateResult, model, brand)),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('ADDCUSTOMERRESPONSE_DEBUG')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(model.debug)
						]))
				]));
	});
var $author$project$Pages$Document$RequestDelete = {$: 'RequestDelete'};
var $author$project$Pages$Document$ToggleDeleteConfirmation = {$: 'ToggleDeleteConfirmation'};
var $author$project$Pages$Document$Upload = {$: 'Upload'};
var $author$project$Elements$Core$iconButton = F5(
	function (color, isEnabled, icon, buttonText, clickMsg) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn-icon'),
					$elm$html$Html$Attributes$disabled(!isEnabled),
					$elm$html$Html$Events$onClick(clickMsg),
					A2($elm$html$Html$Attributes$style, 'background-color', color.hex),
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(color.rgb))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(icon)
						])),
					$elm$html$Html$text(buttonText)
				]));
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Pages$Document$actionMenu = F3(
	function (model, brand, lang) {
		var deleteIsEnabled = A2(
			$elm$core$List$any,
			function (v) {
				return v;
			},
			$elm$core$Dict$values(model.checkedDocs));
		var deleteAction = deleteIsEnabled ? $author$project$Pages$Document$ToggleDeleteConfirmation : $author$project$Pages$Document$NoOp;
		var buttons = _List_fromArray(
			[
				_Utils_Tuple2(
				false,
				A5($author$project$Elements$Core$iconButton, brand.secondary, true, 'refresh', lang.settings.documents.buttons.refresh, $author$project$Pages$Document$RequestBlobs)),
				_Utils_Tuple2(
				true,
				A5($author$project$Elements$Core$iconButton, brand.secondary, true, 'publish', lang.settings.documents.buttons.upload, $author$project$Pages$Document$Upload)),
				_Utils_Tuple2(
				true,
				A5($author$project$Elements$Core$iconButton, brand.secondary, deleteIsEnabled, 'delete', lang.settings.documents.buttons._delete, deleteAction))
			]);
		var onlyPermittedButtons = model.session.isCustomerAdmin ? A2(
			$elm$core$List$map,
			function (_v0) {
				var button = _v0.b;
				return button;
			},
			buttons) : A2(
			$elm$core$List$filterMap,
			function (_v1) {
				var isAdminOnlyButton = _v1.a;
				var button = _v1.b;
				return (!isAdminOnlyButton) ? $elm$core$Maybe$Just(button) : $elm$core$Maybe$Nothing;
			},
			buttons);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('actions')
				]),
			onlyPermittedButtons);
	});
var $author$project$Pages$Document$CheckFile = function (a) {
	return {$: 'CheckFile', a: a};
};
var $author$project$Pages$Document$DownloadFile = function (a) {
	return {$: 'DownloadFile', a: a};
};
var $author$project$Pages$Document$ShowFileDetails = function (a) {
	return {$: 'ShowFileDetails', a: a};
};
var $author$project$Common$FileHelpers$documentFileExtensionImage = _List_fromArray(
	[
		_Utils_Tuple2('pdf', 'icons8_pdf_4.svg'),
		_Utils_Tuple2('xls', 'icons8_xls.svg'),
		_Utils_Tuple2('xlsx', 'icons8_xls.svg'),
		_Utils_Tuple2('xlsm', 'icons8_xls.svg'),
		_Utils_Tuple2('docx', 'icons8_word.svg'),
		_Utils_Tuple2('docm', 'icons8_word.svg'),
		_Utils_Tuple2('dotx', 'icons8_word.svg'),
		_Utils_Tuple2('dotm', 'icons8_word.svg'),
		_Utils_Tuple2('docb', 'icons8_word.svg'),
		_Utils_Tuple2('txt', 'icons8_txt.svg'),
		_Utils_Tuple2('xml', 'icons8_xml.svg'),
		_Utils_Tuple2('zip', 'icons8_zip.svg'),
		_Utils_Tuple2('ppt', 'icons8_ppt.svg'),
		_Utils_Tuple2('dll', 'icons8_dll.svg'),
		_Utils_Tuple2('gif', 'icons8_gif_1.svg'),
		_Utils_Tuple2('jpg', 'icons8_jpg.svg'),
		_Utils_Tuple2('jpeg', 'icons8_jpg.svg'),
		_Utils_Tuple2('json', 'icons8_json_2.svg'),
		_Utils_Tuple2('png', 'icons8_png.svg'),
		_Utils_Tuple2('csv', 'icons8_csv.svg')
	]);
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$Common$FileHelpers$getFileExtension = function (fileName) {
	var splitArray = $elm$core$Array$fromList(
		A2($elm$core$String$split, '.', fileName));
	var lastIndex = $elm$core$Array$length(splitArray) - 1;
	var extension = A2($elm$core$Array$get, lastIndex, splitArray);
	return extension;
};
var $author$project$Common$FileHelpers$getFileExtensionImage = function (fileName) {
	var extension = $author$project$Common$FileHelpers$getFileExtension(fileName);
	if (extension.$ === 'Nothing') {
		return $elm$core$Maybe$Nothing;
	} else {
		var ext = extension.a;
		var _v1 = A2(
			$elm$core$List$filter,
			function (x) {
				return _Utils_eq(
					x.a,
					$elm$core$String$toLower(ext));
			},
			$author$project$Common$FileHelpers$documentFileExtensionImage);
		if (_v1.b && (!_v1.b.b)) {
			var _v2 = _v1.a;
			var e = _v2.a;
			var p = _v2.b;
			return $elm$core$Maybe$Just(p);
		} else {
			return $elm$core$Maybe$Just('icons8_document_1.svg');
		}
	}
};
var $elm$html$Html$object = _VirtualDom_node('object');
var $author$project$Pages$Document$renderBlob = F3(
	function (lang, checkedDocs, blobDoc) {
		var verticalAlign = A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle');
		var svgPath = $author$project$Common$FileHelpers$getFileExtensionImage(blobDoc.blob.name);
		var svg = function () {
			if (svgPath.$ === 'Just') {
				var p = svgPath.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$object,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data', '/svg/fileextensions/' + p),
								$elm$html$Html$Attributes$type_('image/svg+xml'),
								$elm$html$Html$Attributes$class('file-extension')
							]),
						_List_Nil)
					]);
			} else {
				return _List_Nil;
			}
		}();
		var isChecked = function () {
			var _v0 = A2($elm$core$Dict$get, blobDoc.blob.name, checkedDocs);
			if (_v0.$ === 'Just') {
				var v = _v0.a;
				return v;
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Document$CheckFile(blobDoc)),
									$elm$html$Html$Attributes$checked(isChecked),
									verticalAlign
								]),
							_List_Nil)
						])),
					A2($elm$html$Html$td, _List_Nil, svg),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Document$DownloadFile(blobDoc.blob))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(blobDoc.blob.name)
								]))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(blobDoc.document.description)
								]))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(blobDoc.document.uploader)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(blobDoc.blob.createdOn)
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(blobDoc.blob.lastModified)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clickable'),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Document$ShowFileDetails(blobDoc)),
							A2($elm$html$Html$Attributes$attribute, 'tooltip', lang.settings.documents.moreInfo),
							A2($elm$html$Html$Attributes$attribute, 'flow', 'left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('material-icons', true)
										])),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('more_horiz')
								]))
						]))
				]));
	});
var $author$project$Pages$Document$UpdateSort = function (a) {
	return {$: 'UpdateSort', a: a};
};
var $author$project$Common$Color$convertColorWithOpacityToColor = F2(
	function (color, opacity) {
		var rgb = color.rgb;
		var opacity_ = (opacity > 1) ? (opacity / 100) : opacity;
		var calculateNewColor = function (x) {
			var xFloat = x;
			return $elm$core$Basics$round((xFloat * opacity_) + (255.0 * (1.0 - opacity_)));
		};
		var updatedColor = $author$project$Common$Color$rgbToColor(
			_Utils_update(
				rgb,
				{
					blue: calculateNewColor(rgb.blue),
					green: calculateNewColor(rgb.green),
					red: calculateNewColor(rgb.red)
				}));
		if (updatedColor.$ === 'Just') {
			var c = updatedColor.a;
			return c;
		} else {
			return color;
		}
	});
var $author$project$Pages$Document$renderBlobHeader = F3(
	function (color, sort, _v0) {
		var index = _v0.a;
		var _v1 = _v0.b;
		var header = _v1.a;
		var widthPx = _v1.b;
		var widthStyle = $elm$core$String$isEmpty(widthPx) ? _List_Nil : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', widthPx)
			]);
		var sortColor = A2($elm$html$Html$Attributes$style, 'color', '#000');
		var sortText = function () {
			switch (sort.$) {
				case 'Asc':
					var i = sort.a;
					return _Utils_eq(i, index) ? A2(
						$elm$html$Html$span,
						_List_fromArray(
							[sortColor]),
						_List_fromArray(
							[
								$elm$html$Html$text('▼')
							])) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
				case 'Desc':
					var i = sort.a;
					return _Utils_eq(i, index) ? A2(
						$elm$html$Html$span,
						_List_fromArray(
							[sortColor]),
						_List_fromArray(
							[
								$elm$html$Html$text('▲')
							])) : A2($elm$html$Html$span, _List_Nil, _List_Nil);
				default:
					return A2($elm$html$Html$span, _List_Nil, _List_Nil);
			}
		}();
		var text_ = ((!_Utils_eq(
			sort,
			$author$project$Elements$Table$Asc(index))) && (!_Utils_eq(
			sort,
			$author$project$Elements$Table$Desc(index)))) ? _List_fromArray(
			[
				$elm$html$Html$text(header)
			]) : (A2(
			$elm$core$List$member,
			index,
			_List_fromArray(
				[1, 2, 3, 4, 5, 6])) ? _List_fromArray(
			[
				$elm$html$Html$text(header),
				sortText
			]) : _List_fromArray(
			[
				$elm$html$Html$text(header)
			]));
		var lightColor = A2($author$project$Common$Color$convertColorWithOpacityToColor, color, 0.4);
		var hslColor = $author$project$Common$Color$convertRgbToHsl(color.rgb);
		var common = _List_fromArray(
			[
				$elm$html$Html$Attributes$class('clickable'),
				$elm$html$Html$Events$onClick(
				$author$project$Pages$Document$UpdateSort(index)),
				A2($elm$html$Html$Attributes$style, 'background-color', lightColor.hex)
			]);
		var elStyle = A2($elm$core$List$append, widthStyle, common);
		return A2($elm$html$Html$th, elStyle, text_);
	});
var $author$project$Pages$Document$renderBlobHeaders = F3(
	function (color, sort, headerList) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A2($author$project$Pages$Document$renderBlobHeader, color, sort),
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, headerList)));
	});
var $author$project$Pages$Document$compareAsc = F3(
	function (attr, a, b) {
		var _v0 = A2(
			$elm$core$Basics$compare,
			attr(a),
			attr(b));
		switch (_v0.$) {
			case 'LT':
				return $elm$core$Basics$LT;
			case 'EQ':
				return $elm$core$Basics$EQ;
			default:
				return $elm$core$Basics$GT;
		}
	});
var $author$project$Pages$Document$compareDesc = F3(
	function (attr, a, b) {
		var _v0 = A2(
			$elm$core$Basics$compare,
			attr(a),
			attr(b));
		switch (_v0.$) {
			case 'LT':
				return $elm$core$Basics$GT;
			case 'EQ':
				return $elm$core$Basics$EQ;
			default:
				return $elm$core$Basics$LT;
		}
	});
var $author$project$Pages$Document$compareFileExtensionAsc = F3(
	function (attr, a, b) {
		var bFileExt = $author$project$Common$FileHelpers$getFileExtension(
			attr(b));
		var aFileExt = $author$project$Common$FileHelpers$getFileExtension(
			attr(a));
		var _v0 = _Utils_Tuple2(aFileExt, bFileExt);
		if ((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) {
			var afe = _v0.a.a;
			var bfe = _v0.b.a;
			var _v1 = A2($elm$core$Basics$compare, afe, bfe);
			switch (_v1.$) {
				case 'LT':
					return $elm$core$Basics$LT;
				case 'EQ':
					return $elm$core$Basics$EQ;
				default:
					return $elm$core$Basics$GT;
			}
		} else {
			return $elm$core$Basics$EQ;
		}
	});
var $author$project$Pages$Document$compareFileExtensionDesc = F3(
	function (attr, a, b) {
		var bFileExt = $author$project$Common$FileHelpers$getFileExtension(
			attr(b));
		var aFileExt = $author$project$Common$FileHelpers$getFileExtension(
			attr(a));
		var _v0 = _Utils_Tuple2(aFileExt, bFileExt);
		if ((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) {
			var afe = _v0.a.a;
			var bfe = _v0.b.a;
			var _v1 = A2($elm$core$Basics$compare, afe, bfe);
			switch (_v1.$) {
				case 'LT':
					return $elm$core$Basics$GT;
				case 'EQ':
					return $elm$core$Basics$EQ;
				default:
					return $elm$core$Basics$LT;
			}
		} else {
			return $elm$core$Basics$EQ;
		}
	});
var $author$project$Pages$Document$sortBlobDocuments = F3(
	function (sort, a, b) {
		switch (sort.$) {
			case 'Asc':
				var index = sort.a;
				switch (index) {
					case 1:
						return A3(
							$author$project$Pages$Document$compareFileExtensionAsc,
							function ($) {
								return $.name;
							},
							a.blob,
							b.blob);
					case 2:
						return A3(
							$author$project$Pages$Document$compareAsc,
							function ($) {
								return $.name;
							},
							a.blob,
							b.blob);
					case 3:
						return A3(
							$author$project$Pages$Document$compareAsc,
							function ($) {
								return $.description;
							},
							a.document,
							b.document);
					case 4:
						return A3(
							$author$project$Pages$Document$compareAsc,
							function ($) {
								return $.uploader;
							},
							a.document,
							b.document);
					case 5:
						return A3(
							$author$project$Pages$Document$compareAsc,
							function ($) {
								return $.createdOn;
							},
							a.blob,
							b.blob);
					case 6:
						return A3(
							$author$project$Pages$Document$compareAsc,
							function ($) {
								return $.lastModified;
							},
							a.blob,
							b.blob);
					default:
						return $elm$core$Basics$EQ;
				}
			case 'Desc':
				var index = sort.a;
				switch (index) {
					case 1:
						return A3(
							$author$project$Pages$Document$compareFileExtensionDesc,
							function ($) {
								return $.name;
							},
							a.blob,
							b.blob);
					case 2:
						return A3(
							$author$project$Pages$Document$compareDesc,
							function ($) {
								return $.name;
							},
							a.blob,
							b.blob);
					case 3:
						return A3(
							$author$project$Pages$Document$compareDesc,
							function ($) {
								return $.description;
							},
							a.document,
							b.document);
					case 4:
						return A3(
							$author$project$Pages$Document$compareDesc,
							function ($) {
								return $.uploader;
							},
							a.document,
							b.document);
					case 5:
						return A3(
							$author$project$Pages$Document$compareDesc,
							function ($) {
								return $.createdOn;
							},
							a.blob,
							b.blob);
					case 6:
						return A3(
							$author$project$Pages$Document$compareDesc,
							function ($) {
								return $.lastModified;
							},
							a.blob,
							b.blob);
					default:
						return $elm$core$Basics$EQ;
				}
			default:
				return $elm$core$Basics$EQ;
		}
	});
var $author$project$Pages$Document$renderBlobTable = F3(
	function (brand, lang, model) {
		var sortedBlobDocuments = A2(
			$elm$core$List$sortWith,
			$author$project$Pages$Document$sortBlobDocuments(model.sort),
			model.blobDocuments);
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('blob-table')
				]),
			A2(
				$elm$core$List$cons,
				A3(
					$author$project$Pages$Document$renderBlobHeaders,
					brand.primary,
					model.sort,
					_List_fromArray(
						[
							_Utils_Tuple2('', '25px'),
							_Utils_Tuple2('', '25px'),
							_Utils_Tuple2(lang.settings.documents.table.headerFilename, ''),
							_Utils_Tuple2(lang.settings.documents.table.headerDescription, '40%'),
							_Utils_Tuple2(lang.settings.documents.table.headerUploadedBy, ''),
							_Utils_Tuple2(lang.settings.documents.table.headerCreated, ''),
							_Utils_Tuple2(lang.settings.documents.table.headerLastModified, ''),
							_Utils_Tuple2('', '35px')
						])),
				A2(
					$elm$core$List$map,
					A2($author$project$Pages$Document$renderBlob, lang, model.checkedDocs),
					sortedBlobDocuments)));
	});
var $author$project$Pages$Document$HideFileDetails = {$: 'HideFileDetails'};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Pages$Document$infoBoxHeaders = function (numberOfHeaders) {
	return A2(
		$elm$html$Html$tr,
		_List_Nil,
		A2(
			$elm$core$List$repeat,
			numberOfHeaders,
			A2($elm$html$Html$th, _List_Nil, _List_Nil)));
};
var $author$project$Pages$Document$infoBoxItem = F2(
	function (color, _v0) {
		var label = _v0.a;
		var info = _v0.b;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color.hex),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'vertical-align', 'baseline'),
							A2($elm$html$Html$Attributes$style, 'padding-bottom', '10px'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '10px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'vertical-align', 'baseline')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(info)
						]))
				]));
	});
var $author$project$Pages$Document$infoBoxV2 = F2(
	function (color, items) {
		return A2(
			$elm$html$Html$table,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				$author$project$Pages$Document$infoBoxHeaders(
					$elm$core$List$length(items)),
				A2(
					$elm$core$List$map,
					$author$project$Pages$Document$infoBoxItem(color),
					items)));
	});
var $author$project$Pages$Document$renderFileDetails = F3(
	function (model, brand, lang) {
		var _v0 = model.selectedBlobDocument;
		if (_v0.$ === 'Nothing') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('sidebar', true),
								_Utils_Tuple2('show', model.showFileDetails)
							]))
					]),
				_List_Nil);
		} else {
			var bd = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('sidebar', true),
								_Utils_Tuple2('show', model.showFileDetails)
							])),
						A2($elm$html$Html$Attributes$style, 'z-index', '2')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Pages$Document$infoBoxV2,
						brand.secondary,
						_List_fromArray(
							[
								_Utils_Tuple2('Filnamn', bd.blob.name),
								_Utils_Tuple2('Skapad', bd.blob.createdOn),
								_Utils_Tuple2('Ändrad', bd.blob.lastModified),
								_Utils_Tuple2('Typ', bd.blob.contentType),
								_Utils_Tuple2('Uppladdad av', bd.document.uploader),
								_Utils_Tuple2('Beskrivning', bd.document.description)
							])),
						A5(
						$author$project$Elements$Core$renderButton,
						brand.secondary,
						'Stäng',
						true,
						$author$project$Pages$Document$HideFileDetails,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								A2($elm$html$Html$Attributes$style, 'margin-top', '10px')
							]))
					]));
		}
	});
var $author$project$Pages$Document$CancelUpload = {$: 'CancelUpload'};
var $author$project$Pages$Document$FileRequested = {$: 'FileRequested'};
var $author$project$Pages$Document$UpdateDescription = function (a) {
	return {$: 'UpdateDescription', a: a};
};
var $author$project$Pages$Document$UploadBlob = {$: 'UploadBlob'};
var $elm$html$Html$Attributes$cols = function (n) {
	return A2(
		_VirtualDom_attribute,
		'cols',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Elements$Core$renderTextArea = F6(
	function (theme, for_, text_, icon, divAttr, inputAttr) {
		var labelContent = function () {
			if (text_.$ === 'Just') {
				var s = text_.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icon)
							])),
						$elm$html$Html$text(s)
					]);
			} else {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icon)
							]))
					]);
			}
		}();
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('textarea-default')
					]),
				divAttr),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(for_),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
							A2($elm$html$Html$Attributes$style, 'color', theme.hex),
							_Utils_eq(text_, $elm$core$Maybe$Nothing) ? A2($elm$html$Html$Attributes$style, 'display', 'none') : A2($elm$html$Html$Attributes$style, 'display', 'block')
						]),
					labelContent),
					A2(
					$elm$html$Html$textarea,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$rows(4),
								$elm$html$Html$Attributes$cols(50),
								$elm$html$Html$Attributes$name(for_),
								A2($elm$html$Html$Attributes$style, 'border', '2px solid ' + theme.hex)
							]),
						inputAttr),
					_List_Nil)
				]));
	});
var $author$project$Pages$Document$renderFileUpload = F3(
	function (model, brand, lang) {
		var uploadEnabled = !_Utils_eq(model.uploadFile, $elm$core$Maybe$Nothing);
		var fileName = function () {
			var _v0 = model.uploadFile;
			if (_v0.$ === 'Just') {
				var file = _v0.a;
				return $elm$file$File$name(file);
			} else {
				return '';
			}
		}();
		var defaultSettings = $author$project$Elements$Core$default;
		var settings = _Utils_update(
			defaultSettings,
			{display: 'inline-block', readonly: true, width: '70%'});
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('sidebar', true),
							_Utils_Tuple2('show', model.showUpload)
						])),
					A2($elm$html$Html$Attributes$style, 'z-index', '2')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('-choose-file')
						]),
					_List_fromArray(
						[
							A5(
							$author$project$Elements$Core$renderButton,
							brand.secondary,
							'Välj en fil',
							true,
							$author$project$Pages$Document$FileRequested,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '25%')
								])),
							A5($author$project$Elements$Core$textbox, settings, 'file', lang.settings.documents.textbox.selectedFile, $elm$core$Maybe$Nothing, fileName)
						])),
					A6(
					$author$project$Elements$Core$renderTextArea,
					brand.secondary,
					'desc',
					$elm$core$Maybe$Just(lang.settings.documents.table.headerDescription),
					'',
					_List_Nil,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '97%'),
							$elm$html$Html$Attributes$value(model.description),
							$elm$html$Html$Events$onInput($author$project$Pages$Document$UpdateDescription)
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.secondary,
					lang.settings.documents.buttons.upload,
					uploadEnabled,
					$author$project$Pages$Document$UploadBlob,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '10px')
						])),
					A5(
					$author$project$Elements$Core$renderButton,
					brand.secondary,
					lang.settings.cancel,
					true,
					$author$project$Pages$Document$CancelUpload,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'margin-top', '10px')
						]))
				]));
	});
var $author$project$Pages$Document$viewDesktop = F3(
	function (model, brand, lang) {
		var numberOfObjects = $elm$core$String$fromInt(
			$elm$core$List$length(
				A2(
					$elm$core$List$filter,
					function (v) {
						return v;
					},
					$elm$core$Dict$values(model.checkedDocs))));
		var loading = function () {
			var _v0 = model.deleteStatus;
			if (_v0.$ === 'Loading') {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('load-wrapper')
						]),
					_List_fromArray(
						[
							A2($author$project$Elements$Core$renderLoading, brand, _List_Nil)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('document-wrap')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('documents')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Document$actionMenu, model, brand, lang),
							A3($author$project$Pages$Document$renderBlobTable, brand, lang, model)
						])),
					A3($author$project$Pages$Document$renderFileUpload, model, brand, lang),
					A3($author$project$Pages$Document$renderFileDetails, model, brand, lang),
					A7(
					$author$project$Elements$Core$notificationOverlay,
					brand.secondary,
					model.showDeleteConfirmation,
					$author$project$Pages$Document$RequestDelete,
					$author$project$Pages$Document$ToggleDeleteConfirmation,
					lang.settings.documents.deleteConfirmationTitle,
					_Utils_ap(numberOfObjects, lang.settings.documents.deleteConfirmationContent),
					loading)
				]));
	});
var $author$project$Pages$Document$renderBlobMobile = F3(
	function (lang, checkedDocs, blobDoc) {
		var verticalAlign = A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle');
		var svgPath = $author$project$Common$FileHelpers$getFileExtensionImage(blobDoc.blob.name);
		var svg = function () {
			if (svgPath.$ === 'Just') {
				var p = svgPath.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$object,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data', '/svg/fileextensions/' + p),
								$elm$html$Html$Attributes$type_('image/svg+xml'),
								$elm$html$Html$Attributes$class('file-extension')
							]),
						_List_Nil)
					]);
			} else {
				return _List_Nil;
			}
		}();
		var isChecked = function () {
			var _v0 = A2($elm$core$Dict$get, blobDoc.blob.name, checkedDocs);
			if (_v0.$ === 'Just') {
				var v = _v0.a;
				return v;
			} else {
				return false;
			}
		}();
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Document$CheckFile(blobDoc)),
									$elm$html$Html$Attributes$checked(isChecked),
									verticalAlign
								]),
							_List_Nil)
						])),
					A2($elm$html$Html$td, _List_Nil, svg),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Pages$Document$DownloadFile(blobDoc.blob))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(blobDoc.blob.name)
								]))
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clickable'),
							$elm$html$Html$Events$onClick(
							$author$project$Pages$Document$ShowFileDetails(blobDoc))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('material-icons', true)
										])),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('more_horiz')
								]))
						]))
				]));
	});
var $author$project$Pages$Document$renderBlobTableMobile = F3(
	function (brand, lang, model) {
		var sortedBlobDocuments = A2(
			$elm$core$List$sortWith,
			$author$project$Pages$Document$sortBlobDocuments(model.sort),
			model.blobDocuments);
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('blob-table')
				]),
			A2(
				$elm$core$List$cons,
				A3(
					$author$project$Pages$Document$renderBlobHeaders,
					brand.primary,
					model.sort,
					_List_fromArray(
						[
							_Utils_Tuple2('', '25px'),
							_Utils_Tuple2('', '25px'),
							_Utils_Tuple2(lang.settings.documents.table.headerFilename, ''),
							_Utils_Tuple2('', '35px')
						])),
				A2(
					$elm$core$List$map,
					A2($author$project$Pages$Document$renderBlobMobile, lang, model.checkedDocs),
					sortedBlobDocuments)));
	});
var $author$project$Pages$Document$viewMobile = F3(
	function (model, brand, lang) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('document-wrap')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('documents')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Document$renderBlobTableMobile, brand, lang, model)
						])),
					A3($author$project$Pages$Document$renderFileDetails, model, brand, lang)
				]));
	});
var $author$project$Pages$Document$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$Document$viewMobile, model, brand, lang) : A3($author$project$Pages$Document$viewDesktop, model, brand, lang);
	});
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Pages$Home$renderAnnouncementSubject = F3(
	function (color, selected, announcement) {
		var hsl = color.hsl;
		var lightColorHex = function () {
			var _v0 = $author$project$Common$Color$hslToColor(
				_Utils_update(
					hsl,
					{
						luminance: 90,
						saturation: $elm$core$Basics$round(color.hsl.saturation * 0.30)
					}));
			if (_v0.$ === 'Just') {
				var newColor = _v0.a;
				return newColor.hex;
			} else {
				return '#d0d0d0';
			}
		}();
		var backgroundColor = _Utils_eq(
			$elm$core$Maybe$Just(announcement),
			selected) ? A2($elm$html$Html$Attributes$style, 'background-color', lightColorHex) : A2($elm$html$Html$Attributes$style, 'background-color', '#fff');
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Pages$Home$SelectAnnouncement(announcement)),
					backgroundColor
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'height', '24px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('createdBy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(announcement.announcementCreatedBy)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('created')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(announcement.announcementCreated)
								]))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('headline')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(announcement.announcementSubject)
						]))
				]));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Pages$Home$renderAnnouncementSubjects = F3(
	function (brand, selected, announcements) {
		if (!announcements.b) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'min-width', '300px'),
						A2($elm$html$Html$Attributes$style, 'max-width', '300px')
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('subjects')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							A2($author$project$Pages$Home$renderAnnouncementSubject, brand.secondary, selected),
							announcements))
					]));
		}
	});
var $author$project$Pages$Home$renderAnnouncements = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('home-section')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h4,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', '#000')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Notiser')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('announcement')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Home$renderAnnouncementSubjects, brand, model.selectedAnnouncement, model.announcements)
						]))
				]));
	});
var $author$project$Pages$Home$renderBasicAccountCard = function (brand) {
	var imageHeight = (brand.showLogoInAnnouncements && (!brand.showTitleInAnnouncements)) ? A2($elm$html$Html$Attributes$style, 'height', '110px') : A2($elm$html$Html$Attributes$style, 'height', '80px');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('account-basic')
			]),
		_List_fromArray(
			[
				($elm$core$String$isEmpty(brand.image) || (!brand.showLogoInAnnouncements)) ? A2($elm$html$Html$div, _List_Nil, _List_Nil) : A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(brand.image),
						imageHeight
					]),
				_List_Nil),
				brand.showTitleInAnnouncements ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$core$String$isEmpty(brand.image) ? A2($elm$html$Html$Attributes$style, 'padding-left', '0') : A2($elm$html$Html$Attributes$style, 'padding-left', '5px'),
								A2(
								$elm$html$Html$Attributes$style,
								'color',
								$author$project$Common$Color$getColorContrast(brand.secondary.rgb))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(brand.title)
							]))
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info')
					]),
				_List_Nil)
			]));
};
var $author$project$Pages$Home$renderLeftSide = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'column')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Home$renderBasicAccountCard(brand),
					A2($author$project$Pages$Home$renderAnnouncements, model, brand)
				]));
	});
var $author$project$Pages$Home$renderServiceTag = F2(
	function (model, brand) {
		var serviceName = function () {
			var _v0 = model.selectedAnnouncement;
			if (_v0.$ === 'Just') {
				var a = _v0.a;
				var _v1 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(a.announcementServiceKey, s.serviceKey);
						},
						model.announcementServices));
				if (_v1.$ === 'Just') {
					var s = _v1.a;
					return s.serviceName;
				} else {
					return '';
				}
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tag'),
					A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(brand.primary.rgb))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(serviceName)
				]));
	});
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 'Element', a: a, b: b, c: c};
	});
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return _Utils_eq(
		c,
		_Utils_chr(' ')) || (_Utils_eq(
		c,
		_Utils_chr('\t')) || (_Utils_eq(
		c,
		_Utils_chr('\n')) || (_Utils_eq(
		c,
		_Utils_chr('\u000D')) || (_Utils_eq(
		c,
		_Utils_chr('\u000C')) || _Utils_eq(
		c,
		_Utils_chr('\u00A0'))))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(_Utils_Tuple0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (!_Utils_eq(
						c,
						_Utils_chr('>')));
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('<'))),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('/')))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq(
				_Utils_chr('>'))));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 'Comment', a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.context)) : A3(
				$elm$parser$Parser$Advanced$Good,
				_Utils_cmp(s.offset, newOffset) < 0,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$comment = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($hecrj$html_parser$Html$Parser$Comment),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0.a;
			var _v1 = parse(s0);
			if (_v1.$ === 'Good') {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (step.$ === 'Loop') {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
			});
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (step.$ === 'Loop') {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((!_Utils_eq(
		c,
		_Utils_chr('\"'))) && ((!_Utils_eq(
		c,
		_Utils_chr('\''))) && ((!_Utils_eq(
		c,
		_Utils_chr('>'))) && ((!_Utils_eq(
		c,
		_Utils_chr('/'))) && (!_Utils_eq(
		c,
		_Utils_chr('=')))))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(
		_Utils_chr(';')));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char.valueOf()) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (ra.$ === 'Ok') {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (_v0.$ === 'Ok') {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 'ExpectingInt'};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {col: s.col + (newOffset - s.offset), context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 'Err') {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.offset, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.src);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.row, s.col - (floatOffset + s.offset), invalid, s.context));
		} else {
			if (_Utils_eq(s.offset, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.offset, intPair, s);
				} else {
					if (floatSettings.$ === 'Err') {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.offset, floatOffset, s.src));
						if (_v1.$ === 'Nothing') {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.offset, s.src)) {
				var zeroOffset = s.offset + 1;
				var baseOffset = zeroOffset + 1;
				return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.hex,
					baseOffset,
					A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.src),
					s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.octal,
					baseOffset,
					A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.src),
					s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.src) ? A5(
					$elm$parser$Parser$Advanced$finalizeInt,
					c.invalid,
					c.binary,
					baseOffset,
					A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.src),
					s) : A6(
					$elm$parser$Parser$Advanced$finalizeFloat,
					c.invalid,
					c.expecting,
					c._int,
					c._float,
					_Utils_Tuple2(zeroOffset, 0),
					s)));
			} else {
				return A6(
					$elm$parser$Parser$Advanced$finalizeFloat,
					c.invalid,
					c.expecting,
					c._int,
					c._float,
					A3($elm$parser$Parser$Advanced$consumeBase, 10, s.offset, s.src),
					s);
			}
		});
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				binary: $elm$core$Result$Err(invalid),
				expecting: expecting,
				_float: $elm$core$Result$Err(invalid),
				hex: $elm$core$Result$Err(invalid),
				_int: $elm$core$Result$Ok($elm$core$Basics$identity),
				invalid: invalid,
				octal: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return _Utils_eq(
								c,
								_Utils_chr('x')) || _Utils_eq(
								c,
								_Utils_chr('X'));
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(
							_Utils_chr('0')))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(
					_Utils_chr('#')))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq(
				_Utils_chr('&')))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (!_Utils_eq(
			c,
			_Utils_chr('&')));
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((!_Utils_eq(
			c,
			_Utils_chr('\"'))) && ((!_Utils_eq(
			c,
			_Utils_chr('\''))) && ((!_Utils_eq(
			c,
			_Utils_chr('='))) && ((!_Utils_eq(
			c,
			_Utils_chr('<'))) && ((!_Utils_eq(
			c,
			_Utils_chr('>'))) && ((!_Utils_eq(
			c,
			_Utils_chr('`'))) && (!_Utils_eq(
			c,
			_Utils_chr('&')))))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('=')))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue(
						_Utils_chr('\"')),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue(
						_Utils_chr('\''))
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || _Utils_eq(
						c,
						_Utils_chr('-'));
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 'Text', a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (!_Utils_eq(
								c,
								_Utils_chr('<'))) && (!_Utils_eq(
								c,
								_Utils_chr('&')));
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq(
									_Utils_chr('/'))),
								$elm$parser$Parser$succeed(_Utils_Tuple0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq(
						_Utils_chr('>')))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('>')))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq(
							_Utils_chr('<')))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
try {
	var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
	$hecrj$html_parser$Html$Parser$cyclic$node = function () {
		return $hecrj$html_parser$Html$Parser$node;
	};
	var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
	$hecrj$html_parser$Html$Parser$cyclic$element = function () {
		return $hecrj$html_parser$Html$Parser$element;
	};
} catch ($) {
	throw 'Some top-level definitions from `Html.Parser` are causing infinite recursion:\n\n  ┌─────┐\n  │    node\n  │     ↓\n  │    element\n  └─────┘\n\nThese errors are very tricky, so read https://elm-lang.org/0.19.1/bad-recursion to learn how to fix it!';}
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 'Element':
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 'Text':
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Pages$Home$renderViewMode = F3(
	function (model, brand, announcement) {
		var elmNodes = function () {
			if (announcement.$ === 'Nothing') {
				return _List_Nil;
			} else {
				var a = announcement.a;
				var _v2 = $hecrj$html_parser$Html$Parser$run(a.announcementBody);
				if (_v2.$ === 'Ok') {
					var parsedNodes = _v2.a;
					return $hecrj$html_parser$Html$Parser$Util$toVirtualDom(parsedNodes);
				} else {
					return _List_Nil;
				}
			}
		}();
		if (announcement.$ === 'Nothing') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('html')
					]),
				_List_Nil);
		} else {
			var a = announcement.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('html')
					]),
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('headline'),
									A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex),
									A2($elm$html$Html$Attributes$style, 'line-height', 'normal')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementSubject)
								])),
							A2($author$project$Pages$Home$renderServiceTag, model, brand),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('createdBy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementCreatedBy)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('created')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementCreated)
								]))
						]),
					elmNodes));
		}
	});
var $author$project$Pages$Home$renderRightSide = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('right', true),
							_Utils_Tuple2('edit', false)
						]))
				]),
			_List_fromArray(
				[
					A3($author$project$Pages$Home$renderViewMode, model, brand, model.selectedAnnouncement)
				]));
	});
var $author$project$Pages$Home$MobileToggleView = {$: 'MobileToggleView'};
var $author$project$Common$Helpers$createElmNodes = function (content) {
	var _v0 = $hecrj$html_parser$Html$Parser$run(content);
	if (_v0.$ === 'Ok') {
		var parsedNodes = _v0.a;
		return $hecrj$html_parser$Html$Parser$Util$toVirtualDom(parsedNodes);
	} else {
		return _List_Nil;
	}
};
var $author$project$Pages$Home$mobileNews = F2(
	function (model, brand) {
		var textColorPrimary = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.primary.rgb));
		var elmNodes = function () {
			var _v1 = model.selectedAnnouncement;
			if (_v1.$ === 'Just') {
				var a = _v1.a;
				return $author$project$Common$Helpers$createElmNodes(a.announcementBody);
			} else {
				return _List_Nil;
			}
		}();
		var _v0 = model.selectedAnnouncement;
		if (_v0.$ === 'Nothing') {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			var announcement = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('html')
							]),
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('headline'),
											A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex),
											A2($elm$html$Html$Attributes$style, 'line-height', 'normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(announcement.announcementSubject)
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('createdBy')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(announcement.announcementCreatedBy)
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('created')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(announcement.announcementCreated)
										]))
								]),
							elmNodes)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('m-nav'),
								A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
								$elm$html$Html$Events$onClick($author$project$Pages$Home$MobileToggleView)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons'),
										textColorPrimary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('arrow_back_ios')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[textColorPrimary]),
								_List_fromArray(
									[
										$elm$html$Html$text('Bläddra notiser')
									]))
							]))
					]));
		}
	});
var $author$project$Pages$Home$viewMobile = F2(
	function (model, brand) {
		var mobileViewContent = function () {
			var _v0 = model.mobileView;
			if (_v0.$ === 'Announcement') {
				return A2($author$project$Pages$Home$mobileNews, model, brand);
			} else {
				return A2($author$project$Pages$Home$renderAnnouncements, model, brand);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('m-main'),
					A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex + '15')
				]),
			_List_fromArray(
				[mobileViewContent]));
	});
var $author$project$Pages$Home$view = F2(
	function (model, brand) {
		var linearBackground = 'linear-gradient(0deg, ' + (brand.primary.hex + ('15 75%, ' + (brand.secondary.hex + ' 25%)')));
		return model.session.isMobile ? A2($author$project$Pages$Home$viewMobile, model, brand) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '20px'),
					A2($elm$html$Html$Attributes$style, 'height', 'calc(100% - 40px)'),
					A2($elm$html$Html$Attributes$style, 'background', linearBackground),
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'row'),
					A2($elm$html$Html$Attributes$style, 'justify-content', 'space-between')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Home$renderLeftSide, model, brand),
					A2($author$project$Pages$Home$renderRightSide, model, brand)
				]));
	});
var $author$project$Pages$HomeEdit$RemoveAnnouncement = function (a) {
	return {$: 'RemoveAnnouncement', a: a};
};
var $author$project$Pages$HomeEdit$ToggleConfirmation = function (a) {
	return {$: 'ToggleConfirmation', a: a};
};
var $author$project$Pages$HomeEdit$renderAnnouncementSubject = F3(
	function (color, selected, announcement) {
		var hsl = color.hsl;
		var lightColorHex = function () {
			var _v0 = $author$project$Common$Color$hslToColor(
				_Utils_update(
					hsl,
					{
						luminance: 90,
						saturation: $elm$core$Basics$round(color.hsl.saturation * 0.30)
					}));
			if (_v0.$ === 'Just') {
				var newColor = _v0.a;
				return newColor.hex;
			} else {
				return '#d0d0d0';
			}
		}();
		var backgroundColor = _Utils_eq(
			$elm$core$Maybe$Just(announcement),
			selected) ? A2($elm$html$Html$Attributes$style, 'background-color', lightColorHex) : A2($elm$html$Html$Attributes$style, 'background-color', '#fff');
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Pages$HomeEdit$SelectAnnouncement(announcement)),
					backgroundColor
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'height', '24px')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('createdBy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(announcement.announcementCreatedBy)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('created')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(announcement.announcementCreated)
								]))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('headline')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(announcement.announcementSubject)
						]))
				]));
	});
var $author$project$Pages$HomeEdit$renderAnnouncementSubjects = F3(
	function (brand, selected, announcements) {
		if (!announcements.b) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'min-width', '300px'),
						A2($elm$html$Html$Attributes$style, 'max-width', '300px')
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('subjects')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							A2($author$project$Pages$HomeEdit$renderAnnouncementSubject, brand.secondary, selected),
							announcements))
					]));
		}
	});
var $author$project$Pages$HomeEdit$renderAnnouncements = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('home-section')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h4,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', '#000')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Notiser')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('announcement')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$HomeEdit$renderAnnouncementSubjects, brand, model.selectedAnnouncement, model.announcements)
						]))
				]));
	});
var $author$project$Pages$HomeEdit$renderBasicAccountCard = function (brand) {
	var imageHeight = (brand.showLogoInAnnouncements && (!brand.showTitleInAnnouncements)) ? A2($elm$html$Html$Attributes$style, 'height', '110px') : A2($elm$html$Html$Attributes$style, 'height', '80px');
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('account-basic')
			]),
		_List_fromArray(
			[
				($elm$core$String$isEmpty(brand.image) || (!brand.showLogoInAnnouncements)) ? A2($elm$html$Html$div, _List_Nil, _List_Nil) : A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(brand.image),
						imageHeight
					]),
				_List_Nil),
				brand.showTitleInAnnouncements ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$core$String$isEmpty(brand.image) ? A2($elm$html$Html$Attributes$style, 'padding-left', '0') : A2($elm$html$Html$Attributes$style, 'padding-left', '5px'),
								A2(
								$elm$html$Html$Attributes$style,
								'color',
								$author$project$Common$Color$getColorContrast(brand.secondary.rgb))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(brand.title)
							]))
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('info')
					]),
				_List_Nil)
			]));
};
var $author$project$Pages$HomeEdit$renderLeftSide = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'column')
				]),
			_List_fromArray(
				[
					$author$project$Pages$HomeEdit$renderBasicAccountCard(brand),
					A2($author$project$Pages$HomeEdit$renderAnnouncements, model, brand)
				]));
	});
var $author$project$Pages$HomeEdit$Create = {$: 'Create'};
var $author$project$Pages$HomeEdit$ToggleMode = function (a) {
	return {$: 'ToggleMode', a: a};
};
var $author$project$Pages$HomeEdit$Edit = {$: 'Edit'};
var $author$project$Pages$HomeEdit$renderAnnouncementActions = F2(
	function (model, brand) {
		var removeButtonEvent = function () {
			var _v1 = model.selectedAnnouncement;
			if (_v1.$ === 'Just') {
				var a = _v1.a;
				return $elm$html$Html$Events$onClick(
					$author$project$Pages$HomeEdit$RemoveAnnouncement(a));
			} else {
				return $elm$html$Html$Events$onClick($author$project$Pages$HomeEdit$NoOp);
			}
		}();
		var createText = model.createModeOn ? 'Avbryt' : 'Skapa';
		var colorStyle = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.secondary.rgb));
		var exitMode = function () {
			var _v0 = model.mode;
			if (_v0.$ === 'View') {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$HomeEdit$ToggleMode($author$project$Pages$HomeEdit$View)),
							$elm$html$Html$Attributes$class('actionbtn')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									colorStyle
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('arrow_back')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[colorStyle]),
							_List_fromArray(
								[
									$elm$html$Html$text('Tillbaka')
								]))
						]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'row'),
					A2($elm$html$Html$Attributes$style, 'height', '40px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$HomeEdit$ToggleMode($author$project$Pages$HomeEdit$Create)),
							$elm$html$Html$Attributes$class('actionbtn')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									colorStyle
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('add')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[colorStyle]),
							_List_fromArray(
								[
									$elm$html$Html$text(createText)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$HomeEdit$ToggleConfirmation(true)),
							$elm$html$Html$Attributes$class('actionbtn')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									colorStyle
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('remove')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[colorStyle]),
							_List_fromArray(
								[
									$elm$html$Html$text('Ta bort')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(
							$author$project$Pages$HomeEdit$ToggleMode($author$project$Pages$HomeEdit$Edit)),
							$elm$html$Html$Attributes$class('actionbtn')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									colorStyle
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('edit')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[colorStyle]),
							_List_fromArray(
								[
									$elm$html$Html$text('Redigera')
								]))
						])),
					exitMode
				]));
	});
var $author$project$Pages$HomeEdit$renderServiceTag = F3(
	function (model, brand, announcement) {
		var serviceName = function () {
			var _v1 = model.selectedAnnouncement;
			if (_v1.$ === 'Just') {
				var a = _v1.a;
				var _v2 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (s) {
							return _Utils_eq(a.announcementServiceKey, s.serviceKey);
						},
						model.announcementServices));
				if (_v2.$ === 'Just') {
					var s = _v2.a;
					return s.serviceName;
				} else {
					return '';
				}
			} else {
				return '';
			}
		}();
		var sName = function () {
			var _v0 = $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (s) {
						return _Utils_eq(announcement.announcementServiceKey, s.serviceKey);
					},
					model.announcementServices));
			if (_v0.$ === 'Just') {
				var s = _v0.a;
				return s.serviceName;
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tag'),
					A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					$author$project$Common$Color$getColorContrast(brand.primary.rgb))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(sName)
				]));
	});
var $author$project$Pages$HomeEdit$renderViewMode = F3(
	function (model, brand, announcement) {
		var elmNodes = function () {
			if (announcement.$ === 'Nothing') {
				return _List_Nil;
			} else {
				var a = announcement.a;
				var _v2 = $hecrj$html_parser$Html$Parser$run(a.announcementBody);
				if (_v2.$ === 'Ok') {
					var parsedNodes = _v2.a;
					return $hecrj$html_parser$Html$Parser$Util$toVirtualDom(parsedNodes);
				} else {
					return _List_Nil;
				}
			}
		}();
		if (announcement.$ === 'Nothing') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('html', true),
								_Utils_Tuple2('edit', true)
							]))
					]),
				_List_Nil);
		} else {
			var a = announcement.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('html', true),
								_Utils_Tuple2('edit', true)
							]))
					]),
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('headline'),
									A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex),
									A2($elm$html$Html$Attributes$style, 'line-height', 'normal')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementSubject)
								])),
							A3($author$project$Pages$HomeEdit$renderServiceTag, model, brand, a),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('createdBy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementCreatedBy)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('created')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(a.announcementCreated)
								]))
						]),
					elmNodes));
		}
	});
var $author$project$Pages$HomeEdit$Preview = {$: 'Preview'};
var $author$project$Pages$HomeEdit$PublishAnnouncement = function (a) {
	return {$: 'PublishAnnouncement', a: a};
};
var $author$project$Pages$HomeEdit$UpdateNewAnnouncementBody = function (a) {
	return {$: 'UpdateNewAnnouncementBody', a: a};
};
var $author$project$Pages$HomeEdit$UpdateNewAnnouncementSubject = function (a) {
	return {$: 'UpdateNewAnnouncementSubject', a: a};
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Elements$Core$renderSimpleOptions = F2(
	function (selectedItem, _v0) {
		var optionKey = _v0.a;
		var optionValue = _v0.b;
		var isSelected = _Utils_eq(selectedItem, optionValue);
		var isDisabled = optionValue === '';
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(optionValue),
					$elm$html$Html$Attributes$selected(isSelected),
					$elm$html$Html$Attributes$disabled(isDisabled)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(optionKey)
				]));
	});
var $author$project$Elements$Core$renderSimpleDropdown = F5(
	function (color, selected, items, msg, attributes) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('default-select')
					]),
				attributes),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$placeholder('Test'),
							$elm$html$Html$Events$onInput(msg),
							A2($elm$html$Html$Attributes$style, 'border-bottom', '2px solid ' + color.hex)
						]),
					A2(
						$elm$core$List$map,
						$author$project$Elements$Core$renderSimpleOptions(selected),
						items))
				]));
	});
var $author$project$Pages$HomeEdit$serviceListToTuple = function (services) {
	return A2(
		$elm$core$List$map,
		function (s) {
			return _Utils_Tuple2(s.serviceName, s.serviceKey);
		},
		services);
};
var $author$project$Pages$HomeEdit$renderWriteableMode = F2(
	function (brand, model) {
		var settings = $author$project$Elements$Core$default;
		var selectedService = A2($author$project$Pages$HomeEdit$getSelectedDropdownServiceKey, model.announcementServices, model.newAnnouncement);
		var publishButton = function () {
			var _v3 = model.newAnnouncement;
			if (_v3.$ === 'Nothing') {
				return A5($author$project$Elements$Core$renderButton, brand.primary, 'Publicera', false, $author$project$Pages$HomeEdit$NoOp, _List_Nil);
			} else {
				var a = _v3.a;
				return A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Publicera',
					true,
					$author$project$Pages$HomeEdit$PublishAnnouncement(a),
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0 5px')
						]));
			}
		}();
		var previewButton = A5(
			$author$project$Elements$Core$renderButton,
			brand.primary,
			'Förhandsgranska',
			true,
			$author$project$Pages$HomeEdit$ToggleMode($author$project$Pages$HomeEdit$Preview),
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin', '0 5px')
				]));
		var information = function () {
			var _v2 = model.publishInformation;
			if (_v2.$ === 'Just') {
				var s = _v2.a;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', '#d12121'),
							A2($elm$html$Html$Attributes$style, 'margin', '10px 0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(s)
						]));
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var buttons = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
					A2($elm$html$Html$Attributes$style, 'bottom', '10px'),
					A2($elm$html$Html$Attributes$style, 'right', '10px')
				]),
			_List_fromArray(
				[previewButton, publishButton]));
		var _v0 = function () {
			var _v1 = model.newAnnouncement;
			if (_v1.$ === 'Nothing') {
				return _Utils_Tuple2('', '');
			} else {
				var a = _v1.a;
				return _Utils_Tuple2(a.announcementSubject, a.announcementBody);
			}
		}();
		var subject = _v0.a;
		var body = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('create'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'background-color', '#fff'),
					A2($elm$html$Html$Attributes$style, 'padding', '20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'flex'),
							A2($elm$html$Html$Attributes$style, 'flex-direction', 'row'),
							A2($elm$html$Html$Attributes$style, 'justify-content', 'center'),
							A2($elm$html$Html$Attributes$style, 'align-items', 'flex-end'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							A2($elm$html$Html$Attributes$style, 'margin-bottom', '10px')
						]),
					_List_fromArray(
						[
							A5(
							$author$project$Elements$Core$textbox,
							_Utils_update(
								settings,
								{
									color: brand.primary.hex,
									display: 'inline-block',
									input: $elm$core$Maybe$Just($author$project$Pages$HomeEdit$UpdateNewAnnouncementSubject),
									width: '80%'
								}),
							'announcementtitle',
							'Titel',
							$elm$core$Maybe$Nothing,
							subject),
							A5(
							$author$project$Elements$Core$renderSimpleDropdown,
							brand.primary,
							selectedService,
							$author$project$Pages$HomeEdit$serviceListToTuple(model.announcementServices),
							$author$project$Pages$HomeEdit$SelectAnnouncementService,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '20%')
								]))
						])),
					A6(
					$author$project$Elements$Core$renderTextArea,
					brand.primary,
					'announcementbody',
					$elm$core$Maybe$Nothing,
					'',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '90%')
						]),
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput($author$project$Pages$HomeEdit$UpdateNewAnnouncementBody),
							$elm$html$Html$Attributes$placeholder('Innehåll'),
							A2($elm$html$Html$Attributes$style, 'height', '100%'),
							A2($elm$html$Html$Attributes$style, 'width', '100%'),
							$elm$html$Html$Attributes$cols(130),
							$elm$html$Html$Attributes$value(body),
							A2($elm$html$Html$Attributes$style, 'resize', 'none')
						])),
					information,
					buttons
				]));
	});
var $author$project$Pages$HomeEdit$viewMode = F2(
	function (model, brand) {
		var _v0 = model.mode;
		switch (_v0.$) {
			case 'View':
				return A3($author$project$Pages$HomeEdit$renderViewMode, model, brand, model.selectedAnnouncement);
			case 'Create':
				return A2($author$project$Pages$HomeEdit$renderWriteableMode, brand, model);
			case 'Edit':
				return A2($author$project$Pages$HomeEdit$renderWriteableMode, brand, model);
			default:
				var selectedServiceKey = function () {
					var _v3 = model.selectedAnnouncementService;
					if (_v3.$ === 'Just') {
						var s = _v3.a;
						return s.serviceKey;
					} else {
						return '';
					}
				}();
				var announcement = model.newAnnouncement;
				var escapedBody = function () {
					if (announcement.$ === 'Just') {
						var a = announcement.a;
						return A3($elm$core$String$replace, '\n', '<br>', a.announcementBody);
					} else {
						return '';
					}
				}();
				var updatedAnnouncement = function () {
					if (announcement.$ === 'Just') {
						var a = announcement.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								a,
								{announcementBody: escapedBody, announcementCreated: 'yyyy-MM-dd HH:mm:ss', announcementCreatedBy: 'Xxxx Xxxxxxx', announcementServiceKey: selectedServiceKey}));
					} else {
						return announcement;
					}
				}();
				return A3($author$project$Pages$HomeEdit$renderViewMode, model, brand, updatedAnnouncement);
		}
	});
var $author$project$Pages$HomeEdit$renderRightSide = F2(
	function (model, brand) {
		var continueEditing = function () {
			var _v0 = model.mode;
			if (_v0.$ === 'Preview') {
				return A5(
					$author$project$Elements$Core$renderButton,
					brand.primary,
					'Fortsätt redigera',
					true,
					$author$project$Pages$HomeEdit$ToggleMode($author$project$Pages$HomeEdit$Create),
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
							A2($elm$html$Html$Attributes$style, 'bottom', '10px'),
							A2($elm$html$Html$Attributes$style, 'right', '10px')
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'none')
						]),
					_List_Nil);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('right', true),
							_Utils_Tuple2('edit', true)
						]))
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$HomeEdit$renderAnnouncementActions, model, brand),
					A2($author$project$Pages$HomeEdit$viewMode, model, brand),
					continueEditing
				]));
	});
var $author$project$Pages$HomeEdit$view = F2(
	function (model, brand) {
		var linearBackground = 'linear-gradient(0deg, ' + (brand.primary.hex + ('15 75%, ' + (brand.secondary.hex + ' 25%)')));
		var continueMsg = function () {
			var _v2 = model.selectedAnnouncement;
			if (_v2.$ === 'Just') {
				var a = _v2.a;
				return $author$project$Pages$HomeEdit$RemoveAnnouncement(a);
			} else {
				return $author$project$Pages$HomeEdit$NoOp;
			}
		}();
		var _v0 = function () {
			var _v1 = model.selectedAnnouncement;
			if (_v1.$ === 'Just') {
				var a = _v1.a;
				return _Utils_Tuple2('Ta bort ' + (a.announcementSubject + '?'), 'Denna borttagning är permanent');
			} else {
				return _Utils_Tuple2('Ta bort notis?', 'This action is not reversible');
			}
		}();
		var confirmationTitle = _v0.a;
		var confirmationText = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '20px'),
					A2($elm$html$Html$Attributes$style, 'height', 'calc(100% - 40px)'),
					A2($elm$html$Html$Attributes$style, 'background', linearBackground),
					A2($elm$html$Html$Attributes$style, 'display', 'flex'),
					A2($elm$html$Html$Attributes$style, 'flex-direction', 'row'),
					A2($elm$html$Html$Attributes$style, 'justify-content', 'space-between')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$HomeEdit$renderLeftSide, model, brand),
					A2($author$project$Pages$HomeEdit$renderRightSide, model, brand),
					A7(
					$author$project$Elements$Core$notificationOverlay,
					brand.primary,
					model.showConfirmation,
					continueMsg,
					$author$project$Pages$HomeEdit$ToggleConfirmation(false),
					confirmationTitle,
					confirmationText,
					A2($elm$html$Html$div, _List_Nil, _List_Nil))
				]));
	});
var $author$project$Pages$PlannicaGrid$view = F3(
	function (customerKey, tabKey, filters) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'id', 'pgrid-root'),
					A2($elm$html$Html$Attributes$attribute, 'class', 'pgrid-normal-mode'),
					A2($elm$html$Html$Attributes$attribute, 'data-pgrid-root-param-customerkey', customerKey),
					A2($elm$html$Html$Attributes$attribute, 'data-pgrid-root-param-tabkey', tabKey),
					A2(
					$elm$html$Html$Attributes$attribute,
					'data-pgrid-root-param-filters',
					A2($elm$core$Maybe$withDefault, '', filters))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'id', 'pgrid-root-container')
						]),
					_List_Nil)
				]));
	});
var $author$project$Pages$PowerBI$getTokenErrorMessage = F2(
	function (token, brand) {
		var status = function (t) {
			return A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(t)
					]));
		};
		switch (token.$) {
			case 'Failed':
				var err = token.a;
				switch (err.$) {
					case 'BadUrl':
						var s = err.a;
						return status(s);
					case 'Timeout':
						return status('Timeout');
					case 'NetworkError':
						return status('Network error');
					case 'BadStatus':
						var i = err.a;
						if (i === 401) {
							return status('Unauthorized');
						} else {
							return status('Bad status');
						}
					default:
						var s = err.a;
						return status(s);
				}
			case 'Loading':
				return A2($author$project$Elements$Core$renderLoading, brand, _List_Nil);
			default:
				return status('');
		}
	});
var $author$project$Pages$PowerBI$renderLoadingFailed = function (brand) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('material-icons'),
				A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex),
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'left', '50%'),
				A2($elm$html$Html$Attributes$style, 'top', '50%'),
				A2($elm$html$Html$Attributes$style, 'transform', 'translate(-50%, -50%)'),
				A2($elm$html$Html$Attributes$style, 'font-size', '70px')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('highlight_off')
			]));
};
var $author$project$Pages$PowerBI$view = F2(
	function (model, brand) {
		var details = function () {
			var _v0 = model.currentReport;
			switch (_v0.$) {
				case 'LoadingReport':
					return A2($author$project$Elements$Core$renderLoading, brand, _List_Nil);
				case 'LoadingToken':
					return A2($author$project$Elements$Core$renderLoading, brand, _List_Nil);
				case 'LoadingFailed':
					return $author$project$Pages$PowerBI$renderLoadingFailed(brand);
				default:
					return A2($author$project$Pages$PowerBI$getTokenErrorMessage, model.embedToken, brand);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('powerbi-report')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('reportContainer')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('report-information')
								]),
							_List_fromArray(
								[details]))
						]))
				]));
	});
var $author$project$Pages$ReportSettings$SelectReaactReport = function (a) {
	return {$: 'SelectReaactReport', a: a};
};
var $author$project$Pages$ReportSettings$getServiceAndApplicationFromSelectedTab = F2(
	function (selectedTab, services) {
		var allApps = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return s.applications;
				},
				services));
		var selectedApplication = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (a) {
					return A2($elm$core$List$member, selectedTab, a.tabs);
				},
				allApps));
		var selectedService = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (s) {
					if (selectedApplication.$ === 'Just') {
						var a = selectedApplication.a;
						return A2($elm$core$List$member, a, s.applications);
					} else {
						return false;
					}
				},
				services));
		return _Utils_Tuple2(selectedService, selectedApplication);
	});
var $author$project$Pages$ReportSettings$Switch = function (a) {
	return {$: 'Switch', a: a};
};
var $author$project$Pages$ReportSettings$UpdateReaactReport = F2(
	function (a, b) {
		return {$: 'UpdateReaactReport', a: a, b: b};
	});
var $author$project$Pages$ReportSettings$UpdateReportSettings = F2(
	function (a, b) {
		return {$: 'UpdateReportSettings', a: a, b: b};
	});
var $author$project$Pages$ReportSettings$groupAccessStatus = F2(
	function (hexColor, status) {
		var _v0 = function () {
			switch (status.$) {
				case 'Success':
					return _Utils_Tuple3('Power BI Workspace är tillgängligt', 'check_circle', 'valid');
				case 'Failed':
					return _Utils_Tuple3('Power BI Workspace är inte tillgängligt', 'error', 'invalid');
				default:
					return _Utils_Tuple3('', 'help', 'unknown');
			}
		}();
		var textString = _v0.a;
		var icon = _v0.b;
		var className = _v0.c;
		if (status.$ === 'Loading') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('circleloader'),
						A2($elm$html$Html$Attributes$style, 'color', hexColor)
					]),
				_List_Nil);
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('icon-status', true),
								_Utils_Tuple2(className, true)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('material-icons')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(icon)
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(textString)
							]))
					]));
		}
	});
var $author$project$Pages$ReportSettings$RequestSetSPN = {$: 'RequestSetSPN'};
var $author$project$Pages$ReportSettings$SelectServicePrincipal = function (a) {
	return {$: 'SelectServicePrincipal', a: a};
};
var $author$project$Pages$ReportSettings$UpdatePBITenant = F2(
	function (a, b) {
		return {$: 'UpdatePBITenant', a: a, b: b};
	});
var $author$project$Pages$ReportSettings$getSelectedReportPBITenantKey = function (selectedReport) {
	if (selectedReport.$ === 'Success') {
		var report = selectedReport.a;
		return report.pbiTenantKey;
	} else {
		return '';
	}
};
var $author$project$Pages$ReportSettings$settings = function (brand) {
	var options = $author$project$Elements$Core$default;
	return _Utils_update(
		options,
		{color: brand.primary.hex, height: '', width: '100%'});
};
var $author$project$Pages$ReportSettings$renderServicePrincipalSettings = F3(
	function (model, brand, lang) {
		var tbSettings = $author$project$Pages$ReportSettings$settings(brand);
		var pbiTenantItems = A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_Tuple2(p.pbiTenantName, p.pbiTenantKey);
			},
			model.pbiTenants);
		var newServicePrincipalElements = ($elm$core$List$length(model.pbiTenants) > 1) ? _List_Nil : _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(lang.settings.report.orCreateNewPbiTenant)
					])),
				A5(
				$author$project$Elements$Core$textbox,
				_Utils_update(
					tbSettings,
					{
						input: $elm$core$Maybe$Just(
							$author$project$Pages$ReportSettings$UpdatePBITenant(0))
					}),
				'pbitenantname',
				lang.settings.report.pbiTenantName,
				$elm$core$Maybe$Nothing,
				model.newPbiTenant.pbiTenantName),
				A5(
				$author$project$Elements$Core$textbox,
				_Utils_update(
					tbSettings,
					{
						input: $elm$core$Maybe$Just(
							$author$project$Pages$ReportSettings$UpdatePBITenant(3))
					}),
				'azuretenantid',
				'Azure Tenant ID',
				$elm$core$Maybe$Nothing,
				model.newPbiTenant.azureTenantId),
				A5(
				$author$project$Elements$Core$textbox,
				_Utils_update(
					tbSettings,
					{
						input: $elm$core$Maybe$Just(
							$author$project$Pages$ReportSettings$UpdatePBITenant(1))
					}),
				'spnid',
				'Service Principal ID',
				$elm$core$Maybe$Nothing,
				model.newPbiTenant.spnId),
				A5(
				$author$project$Elements$Core$textbox,
				_Utils_update(
					tbSettings,
					{
						input: $elm$core$Maybe$Just(
							$author$project$Pages$ReportSettings$UpdatePBITenant(2))
					}),
				'spnsecret',
				'Service Principal Secret',
				$elm$core$Maybe$Nothing,
				model.newPbiTenant.spnSecret),
				A5(
				$author$project$Elements$Core$renderButton,
				brand.primary,
				lang.settings.report.savePbiTenant,
				$author$project$Pages$ReportSettings$isPBITenantFilledOut(model.newPbiTenant),
				$author$project$Pages$ReportSettings$RequestSetSPN,
				_List_Nil)
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('principal-settings')
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Power BI Tenant')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(lang.settings.report.choosePbiTenant)
							])),
						A6(
						$author$project$Elements$Core$dropdown,
						brand,
						pbiTenantItems,
						$author$project$Pages$ReportSettings$getSelectedReportPBITenantKey(model.selectedReport),
						$author$project$Pages$ReportSettings$SelectServicePrincipal,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'display', 'block'),
								A2($elm$html$Html$Attributes$style, 'margin', '10px 0')
							]),
						true)
					]),
				newServicePrincipalElements));
	});
var $author$project$Elements$Core$switch = F5(
	function (color, switches, id, switchText, msg) {
		var isChecked = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Dict$get, id, switches));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('switch-wrap')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cb-switch')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$type_('checkbox'),
									$elm$html$Html$Attributes$checked(isChecked),
									$elm$html$Html$Events$onClick(
									msg(id))
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('slider', true),
											_Utils_Tuple2('round', true)
										])),
									isChecked ? A2($elm$html$Html$Attributes$style, 'background-color', color.hex) : A2($elm$html$Html$Attributes$style, 'background-color', '#ccc')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(switchText)
						]))
				]));
	});
var $author$project$Pages$ReportSettings$renderReportSettings = F3(
	function (model, brand, lang) {
		var statusDiv = function (content) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sidepanel-information')
					]),
				_List_fromArray(
					[content]));
		};
		var selectedTabKey = function () {
			var _v8 = model.selectedTab;
			if (_v8.$ === 'Just') {
				var tab = _v8.a;
				return tab.tabKey;
			} else {
				return '';
			}
		}();
		var saveState = function () {
			var _v4 = model.selectedTab;
			if (_v4.$ === 'Just') {
				var tab = _v4.a;
				var _v5 = A2($elm$core$Dict$get, tab.tabKey, model.reports);
				if (_v5.$ === 'Just') {
					var report = _v5.a;
					var _v6 = model.selectedReport;
					if (_v6.$ === 'Success') {
						var selectedReport = _v6.a;
						return !_Utils_eq(report, selectedReport);
					} else {
						return false;
					}
				} else {
					var _v7 = model.selectedReport;
					if (_v7.$ === 'Success') {
						var selectedReport = _v7.a;
						return !_Utils_eq(selectedReport, $author$project$Pages$ReportSettings$emptyReport);
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
		}();
		var groupAccess = function () {
			var _v3 = model.groupAccess;
			switch (_v3.$) {
				case 'Success':
					var x = _v3.a;
					return statusDiv(
						A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'color', '#32a852'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
								]),
							_List_Nil));
				case 'Loading':
					return statusDiv(
						A2($author$project$Elements$Core$renderLoading, brand, _List_Nil));
				case 'NotAsked':
					return A2($elm$html$Html$p, _List_Nil, _List_Nil);
				default:
					return statusDiv(
						A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'color', '#de2f2f'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(lang.settings.sidepanel.groupAccessFailed)
								])));
			}
		}();
		var _default = $author$project$Pages$ReportSettings$settings(brand);
		var basicLabel = F3(
			function (label, textContent, isLoading) {
				var content = isLoading ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hloader')
						]),
					_List_Nil) : A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0'),
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(textContent)
						]));
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'margin', '0'),
									A2($elm$html$Html$Attributes$style, 'font-weight', 'bold'),
									A2($elm$html$Html$Attributes$style, 'color', brand.primary.hex),
									A2($elm$html$Html$Attributes$style, 'margin-right', '5px'),
									A2($elm$html$Html$Attributes$style, 'margin-top', '10px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label + ':')
								])),
							content
						]));
			});
		var groupName = function () {
			var _v2 = model.groupMetaData;
			switch (_v2.$) {
				case 'Success':
					var metadata = _v2.a;
					return A3(basicLabel, 'Namn', metadata.name, false);
				case 'Loading':
					return A3(basicLabel, 'Namn', '', true);
				default:
					return A3(basicLabel, 'Namn', '-', false);
			}
		}();
		var reportName = function () {
			var _v1 = model.reportMetaData;
			switch (_v1.$) {
				case 'Success':
					var metadata = _v1.a;
					return A3(basicLabel, 'Namn', metadata.name, false);
				case 'Loading':
					return A3(basicLabel, 'Namn', '', true);
				default:
					return A3(basicLabel, 'Namn', '-', false);
			}
		}();
		var _v0 = model.selectedReport;
		if (_v0.$ === 'Success') {
			var report = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings-content'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '0')
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$ReportSettings$UpdateReportSettings(0))
							}),
						'reportname',
						lang.settings.report.name,
						$elm$core$Maybe$Nothing,
						report.reportName),
						A6(
						$author$project$Elements$Core$renderTextArea,
						brand.primary,
						'reportdesc',
						$elm$core$Maybe$Just(lang.settings.report.desc),
						'short_text',
						_List_Nil,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%'),
								$elm$html$Html$Attributes$value(report.reportDescription),
								$elm$html$Html$Events$onInput(
								$author$project$Pages$ReportSettings$UpdateReportSettings(1))
							])),
						groupName,
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								blur: $elm$core$Maybe$Just($author$project$Pages$ReportSettings$GetGroupMetaData),
								input: $elm$core$Maybe$Just(
									$author$project$Pages$ReportSettings$UpdateReportSettings(2))
							}),
						'group',
						lang.settings.report.group,
						$elm$core$Maybe$Nothing,
						report.groupId),
						reportName,
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								blur: $elm$core$Maybe$Just($author$project$Pages$ReportSettings$GetReportMetaData),
								input: $elm$core$Maybe$Just(
									$author$project$Pages$ReportSettings$UpdateReportSettings(3))
							}),
						'report',
						lang.settings.report.report,
						$elm$core$Maybe$Nothing,
						report.reportId),
						A2($author$project$Pages$ReportSettings$groupAccessStatus, brand.primary.hex, model.groupAccess),
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-bottom', '0')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Inbäddningsalternativ')
							])),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$ReportSettings$UpdateReportSettings(4)),
								margin: '0 0 10px 0'
							}),
						'firstpage',
						'Rapportens förstasida',
						$elm$core$Maybe$Nothing,
						report.reportInitialPage),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'rls', 'Row Level Security', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'bg', 'Transparent bakgrund', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'paneFilters', 'Filter', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'paneNav', 'Sidnavigering', $author$project$Pages$ReportSettings$Switch),
						A3($author$project$Pages$ReportSettings$renderServicePrincipalSettings, model, brand, lang),
						A5(
						$author$project$Elements$Core$renderButton,
						brand.primary,
						lang.settings.save,
						saveState,
						A2($author$project$Pages$ReportSettings$UpdateReaactReport, selectedTabKey, report),
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'right', '1.8em'),
								A2($elm$html$Html$Attributes$style, 'bottom', '0.5em')
							]))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings-content'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '0')
					]),
				_List_fromArray(
					[
						A5($author$project$Elements$Core$textbox, _default, 'reportname', lang.settings.report.name, $elm$core$Maybe$Nothing, ''),
						A6(
						$author$project$Elements$Core$renderTextArea,
						brand.primary,
						'reportdesc',
						$elm$core$Maybe$Just(lang.settings.report.desc),
						'short_text',
						_List_Nil,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'width', '100%')
							])),
						A5($author$project$Elements$Core$textbox, _default, 'group', lang.settings.report.group, $elm$core$Maybe$Nothing, ''),
						A5($author$project$Elements$Core$textbox, _default, 'report', lang.settings.report.report, $elm$core$Maybe$Nothing, ''),
						groupAccess,
						A2(
						$elm$html$Html$h4,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-bottom', '0')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Inbäddningsalternativ')
							])),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{margin: '0 0 10px 0'}),
						'firstpage',
						'Rapportens förstasida',
						$elm$core$Maybe$Nothing,
						''),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'rls', 'Row Level Security', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'bg', 'Transparent bakgrund', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'paneFilters', 'Filter', $author$project$Pages$ReportSettings$Switch),
						A5($author$project$Elements$Core$switch, brand.primary, model.switches, 'paneNav', 'Sidnavigering', $author$project$Pages$ReportSettings$Switch),
						A3($author$project$Pages$ReportSettings$renderServicePrincipalSettings, model, brand, lang),
						A5(
						$author$project$Elements$Core$renderButton,
						brand.primary,
						lang.settings.save,
						saveState,
						$author$project$Pages$ReportSettings$NoOp,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
								A2($elm$html$Html$Attributes$style, 'right', '1.8em'),
								A2($elm$html$Html$Attributes$style, 'bottom', '0.5em')
							]))
					]));
		}
	});
var $author$project$Pages$ReportSettings$tableColumnWidths = _List_fromArray(
	[
		$elm$core$Maybe$Just('450px'),
		$elm$core$Maybe$Just('450px'),
		$elm$core$Maybe$Just('450px')
	]);
var $author$project$Pages$ReportSettings$view = F3(
	function (model, brand, lang) {
		var tableRow = F3(
			function (service, app, tab) {
				return A4(
					$author$project$Elements$Table$createRow2,
					$author$project$Pages$ReportSettings$tableColumnWidths,
					_List_Nil,
					_List_fromArray(
						[service.serviceName, app.applicationName, tab.tabName]),
					$author$project$Pages$ReportSettings$SelectReaactReport(tab));
			});
		var tableRows = $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (s) {
					return $elm$core$List$concat(
						A2(
							$elm$core$List$map,
							function (a) {
								return A2(
									$elm$core$List$map,
									A2(tableRow, s, a),
									a.tabs);
							},
							s.applications));
				},
				model.reportServices));
		var tableHeader = A4(
			$author$project$Elements$Table$createRow2,
			$author$project$Pages$ReportSettings$tableColumnWidths,
			_List_Nil,
			_List_fromArray(
				[lang.settings.table.reaact.headerService, lang.settings.table.reaact.headerApp, lang.settings.table.reaact.headerTab]),
			$author$project$Pages$ReportSettings$NoOp);
		var selectedRow = function () {
			var _v1 = model.selectedTab;
			if (_v1.$ === 'Nothing') {
				return $elm$core$Maybe$Nothing;
			} else {
				var tab = _v1.a;
				var _v2 = A2($author$project$Pages$ReportSettings$getServiceAndApplicationFromSelectedTab, tab, model.reportServices);
				if ((_v2.a.$ === 'Just') && (_v2.b.$ === 'Just')) {
					var s = _v2.a.a;
					var a = _v2.b.a;
					return $elm$core$Maybe$Just(
						A4(
							$author$project$Elements$Table$createRow2,
							$author$project$Pages$ReportSettings$tableColumnWidths,
							_List_Nil,
							_List_fromArray(
								[s.serviceName, a.applicationName, tab.tabName]),
							$author$project$Pages$ReportSettings$SelectReaactReport(tab)));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}();
		var table = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, tableHeader, tableRows, selectedRow);
		var selected = function () {
			var _v0 = model.selectedCustomer;
			if (_v0.$ === 'Just') {
				var c = _v0.a;
				return c;
			} else {
				return A4($author$project$Common$Types$Customer, '', '', '', '');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('reaact-reportsettings')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-main')
						]),
					_List_fromArray(
						[table])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('settings-sidepanel')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$ReportSettings$renderReportSettings, model, brand, lang)
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('DEBUG')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$Debug$toString(model.debug))
						]))
				]));
	});
var $author$project$Pages$RoleManagement$SelectRole = function (a) {
	return {$: 'SelectRole', a: a};
};
var $author$project$Pages$RoleManagement$SelectUser = function (a) {
	return {$: 'SelectUser', a: a};
};
var $author$project$Pages$RoleManagement$UpdateSort = function (a) {
	return {$: 'UpdateSort', a: a};
};
var $author$project$Common$Helpers$distinctOn = F2(
	function (attribute, list) {
		distinctOn:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var l = list.a;
				var ls = list.b;
				if (A2(
					$elm$core$List$any,
					function (lls) {
						return _Utils_eq(
							attribute(l),
							attribute(lls));
					},
					ls)) {
					var $temp$attribute = attribute,
						$temp$list = ls;
					attribute = $temp$attribute;
					list = $temp$list;
					continue distinctOn;
				} else {
					return A2(
						$elm$core$List$cons,
						l,
						A2($author$project$Common$Helpers$distinctOn, attribute, ls));
				}
			}
		}
	});
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Pages$RoleManagement$Search = F2(
	function (a, b) {
		return {$: 'Search', a: a, b: b};
	});
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Pages$RoleManagement$AddUserRole = F2(
	function (a, b) {
		return {$: 'AddUserRole', a: a, b: b};
	});
var $author$project$Pages$RoleManagement$RemoveUserRole = F2(
	function (a, b) {
		return {$: 'RemoveUserRole', a: a, b: b};
	});
var $author$project$Pages$RoleManagement$renderRoleUser = F2(
	function (icon, user) {
		var clickEvent = function () {
			switch (icon) {
				case 'add':
					return A2(
						$author$project$Pages$RoleManagement$AddUserRole,
						$elm$core$Maybe$Just(user),
						$elm$core$Maybe$Nothing);
				case 'remove':
					return A2(
						$author$project$Pages$RoleManagement$RemoveUserRole,
						$elm$core$Maybe$Just(user),
						$elm$core$Maybe$Nothing);
				default:
					return $author$project$Pages$RoleManagement$NoOp;
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overflow')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(user.userEmail)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('role-btn'),
							$elm$html$Html$Events$onClick(clickEvent)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(icon)
								]))
						]))
				]));
	});
var $author$project$Pages$RoleManagement$renderAvailableUsers = function (model) {
	var users_ = function () {
		var _v0 = model.selectedRole;
		if (_v0.$ === 'Just') {
			var r = _v0.a;
			var _v1 = model.users;
			if (_v1.$ === 'Success') {
				var u = _v1.a;
				var _v2 = A2($elm$core$Dict$get, 'available-users', model.searches);
				if (_v2.$ === 'Just') {
					var s = _v2.a;
					return A2(
						$elm$core$List$filter,
						function (x) {
							return (!A2($elm$core$List$member, r, x.roles)) && A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(s),
								$elm$core$String$toLower(x.userFullName));
						},
						u);
				} else {
					return A2(
						$elm$core$List$filter,
						function (x) {
							return !A2($elm$core$List$member, r, x.roles);
						},
						u);
				}
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$Pages$RoleManagement$renderRoleUser('add'),
			users_));
};
var $author$project$Pages$RoleManagement$renderRoleUsers = function (users) {
	return A2(
		$elm$html$Html$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$Pages$RoleManagement$renderRoleUser('remove'),
			users));
};
var $author$project$Pages$RoleManagement$renderSelectedRole = F4(
	function (model, brand, lang, role) {
		var users_ = function () {
			var _v0 = model.users;
			if (_v0.$ === 'Success') {
				var u = _v0.a;
				var _v1 = A2($elm$core$Dict$get, 'current-users', model.searches);
				if (_v1.$ === 'Just') {
					var s = _v1.a;
					var _v2 = A2(
						$elm$core$List$filter,
						function (x) {
							return A2($elm$core$List$member, role, x.roles);
						},
						u);
					var list = _v2;
					return A2(
						$elm$core$List$filter,
						function (x) {
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(s),
								$elm$core$String$toLower(x.userFullName));
						},
						list);
				} else {
					return A2(
						$elm$core$List$filter,
						function (x) {
							return A2($elm$core$List$member, role, x.roles);
						},
						u);
				}
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-sidepanel'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'unset')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-info')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(role.roleName)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(role.customerRolePermissionLevelDescription)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('list')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('userroles-title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('group')
										])),
									$elm$html$Html$text(lang.settings.sidepanel.assignedMembersTitle)
								])),
							A4(
							$author$project$Elements$Core$renderSearch,
							brand,
							'current-users',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '250px'),
									A2($elm$html$Html$Attributes$style, 'display', 'flex'),
									A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
								]),
							$author$project$Pages$RoleManagement$Search),
							$author$project$Pages$RoleManagement$renderRoleUsers(users_)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('list')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('userroles-title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('group_add')
										])),
									$elm$html$Html$text(lang.settings.sidepanel.notAssignedMembersTitle)
								])),
							A4(
							$author$project$Elements$Core$renderSearch,
							brand,
							'available-users',
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '250px'),
									A2($elm$html$Html$Attributes$style, 'display', 'flex'),
									A2($elm$html$Html$Attributes$style, 'flex-direction', 'row')
								]),
							$author$project$Pages$RoleManagement$Search),
							$author$project$Pages$RoleManagement$renderAvailableUsers(model)
						]))
				]));
	});
var $author$project$Pages$RoleManagement$renderUserRole = F2(
	function (icon, role) {
		var clickEvent = function () {
			switch (icon) {
				case 'add':
					return A2(
						$author$project$Pages$RoleManagement$AddUserRole,
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(role));
				case 'remove':
					return A2(
						$author$project$Pages$RoleManagement$RemoveUserRole,
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(role));
				default:
					return $author$project$Pages$RoleManagement$NoOp;
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('overflow')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(role.roleName)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('role-btn'),
							$elm$html$Html$Events$onClick(clickEvent)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(icon)
								]))
						]))
				]));
	});
var $author$project$Pages$RoleManagement$renderAvailableRoles = function (model) {
	var customerRoles = function () {
		var _v1 = model.availableRoles;
		if (_v1.$ === 'Success') {
			var cr = _v1.a;
			return cr;
		} else {
			return _List_Nil;
		}
	}();
	var roles = function () {
		var _v0 = model.selectedUser;
		if (_v0.$ === 'Just') {
			var u = _v0.a;
			return A2(
				$elm$core$List$filter,
				function (r) {
					return !A2($elm$core$List$member, r, u.roles);
				},
				customerRoles);
		} else {
			return _List_Nil;
		}
	}();
	var distinctRoles = A2(
		$author$project$Common$Helpers$distinctOn,
		function ($) {
			return $.roleName;
		},
		roles);
	return A2(
		$elm$html$Html$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$Pages$RoleManagement$renderUserRole('add'),
			distinctRoles));
};
var $author$project$Pages$RoleManagement$renderUserRoles = function (roles) {
	return A2(
		$elm$html$Html$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			$author$project$Pages$RoleManagement$renderUserRole('remove'),
			roles));
};
var $author$project$Pages$RoleManagement$renderSelectedUser = F3(
	function (model, lang, user) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-sidepanel'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'unset')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('user-info')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(user.userFullName)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(user.userEmail)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('list')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('userroles-title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('group')
										])),
									$elm$html$Html$text(lang.settings.sidepanel.assignedRolesTitle)
								])),
							$author$project$Pages$RoleManagement$renderUserRoles(
							A2(
								$author$project$Common$Helpers$distinctOn,
								function ($) {
									return $.roleName;
								},
								user.roles))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('list')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('userroles-title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('group_add')
										])),
									$elm$html$Html$text(lang.settings.sidepanel.notAssignedRolesTitle)
								])),
							$author$project$Pages$RoleManagement$renderAvailableRoles(model)
						]))
				]));
	});
var $author$project$Pages$RoleManagement$viewDesktop = F3(
	function (model, brand, lang) {
		var users = function () {
			var _v8 = model.users;
			if (_v8.$ === 'Success') {
				var u = _v8.a;
				return u;
			} else {
				return _List_Nil;
			}
		}();
		var tableHeader = model.roleToggle ? A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('175px'),
					$elm$core$Maybe$Just('350px'),
					$elm$core$Maybe$Just('350px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(0))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(1))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(2))
					])
				]),
			_List_fromArray(
				[lang.settings.table.users.headerName, lang.settings.table.users.headerEmail, lang.settings.table.users.headerRoles]),
			$author$project$Pages$RoleManagement$NoOp) : A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('350px'),
					$elm$core$Maybe$Just('350px'),
					$elm$core$Maybe$Just('175px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(0))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(1))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(2))
					])
				]),
			_List_fromArray(
				[lang.settings.table.roles.headerName, lang.settings.table.roles.headerLevelDescription, lang.settings.table.roles.headerAssignedUsers]),
			$author$project$Pages$RoleManagement$NoOp);
		var roles = function () {
			var _v7 = model.availableRoles;
			if (_v7.$ === 'Success') {
				var r = _v7.a;
				return r;
			} else {
				return _List_Nil;
			}
		}();
		var management = function () {
			var _v4 = model.roleToggle;
			if (!_v4) {
				var _v5 = model.selectedRole;
				if (_v5.$ === 'Just') {
					var r = _v5.a;
					return A4($author$project$Pages$RoleManagement$renderSelectedRole, model, brand, lang, r);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings-sidepanel')
							]),
						_List_Nil);
				}
			} else {
				var _v6 = model.selectedUser;
				if (_v6.$ === 'Just') {
					var u = _v6.a;
					return A3($author$project$Pages$RoleManagement$renderSelectedUser, model, lang, u);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings-sidepanel')
							]),
						_List_Nil);
				}
			}
		}();
		var createListOfRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return A2(
					$elm$core$List$cons,
					x.roleName,
					createListOfRoleNames(xs));
			} else {
				return _List_Nil;
			}
		};
		var concatRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return _Utils_ap(
					x,
					concatRoleNames(xs));
			} else {
				return '';
			}
		};
		var selectedRow = function () {
			if (model.roleToggle) {
				var _v2 = model.selectedUser;
				if (_v2.$ === 'Nothing') {
					return $elm$core$Maybe$Nothing;
				} else {
					var user = _v2.a;
					var roleNames = createListOfRoleNames(
						A2(
							$author$project$Common$Helpers$distinctOn,
							function ($) {
								return $.roleName;
							},
							user.roles));
					return $elm$core$Maybe$Just(
						A4(
							$author$project$Elements$Table$createRow2,
							_List_fromArray(
								[
									$elm$core$Maybe$Just('175px'),
									$elm$core$Maybe$Just('350px'),
									$elm$core$Maybe$Just('350px')
								]),
							_List_Nil,
							_List_fromArray(
								[
									user.userFullName,
									user.userEmail,
									concatRoleNames(
									A2($elm$core$List$intersperse, ', ', roleNames))
								]),
							$author$project$Pages$RoleManagement$SelectUser(user)));
				}
			} else {
				var _v3 = model.selectedRole;
				if (_v3.$ === 'Nothing') {
					return $elm$core$Maybe$Nothing;
				} else {
					var role = _v3.a;
					return $elm$core$Maybe$Just(
						A4(
							$author$project$Elements$Table$createRow2,
							_List_fromArray(
								[
									$elm$core$Maybe$Just('350px'),
									$elm$core$Maybe$Just('350px'),
									$elm$core$Maybe$Just('175px')
								]),
							_List_Nil,
							_List_fromArray(
								[
									role.roleName,
									role.customerRolePermissionLevelDescription,
									$elm$core$String$fromInt(
									$elm$core$List$length(
										A2(
											$elm$core$List$filter,
											function (x) {
												return A2($elm$core$List$member, role, x.roles);
											},
											users)))
								]),
							$author$project$Pages$RoleManagement$SelectRole(role)));
				}
			}
		}();
		var tableRows = model.roleToggle ? A2(
			$elm$core$List$map,
			function (user) {
				var roleNames = createListOfRoleNames(
					A2(
						$author$project$Common$Helpers$distinctOn,
						function ($) {
							return $.roleName;
						},
						user.roles));
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('175px'),
							$elm$core$Maybe$Just('350px'),
							$elm$core$Maybe$Just('350px')
						]),
					_List_Nil,
					_List_fromArray(
						[
							user.userFullName,
							user.userEmail,
							concatRoleNames(
							A2($elm$core$List$intersperse, ', ', roleNames))
						]),
					$author$project$Pages$RoleManagement$SelectUser(user));
			},
			users) : A2(
			$elm$core$List$map,
			function (role) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('350px'),
							$elm$core$Maybe$Just('350px'),
							$elm$core$Maybe$Just('175px')
						]),
					_List_Nil,
					_List_fromArray(
						[
							role.roleName,
							role.customerRolePermissionLevelDescription,
							$elm$core$String$fromInt(
							$elm$core$List$length(
								A2(
									$elm$core$List$filter,
									function (x) {
										return A2($elm$core$List$member, role, x.roles);
									},
									users)))
						]),
					$author$project$Pages$RoleManagement$SelectRole(role));
			},
			roles);
		var table = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, tableHeader, tableRows, selectedRow);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('management-roles')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-main')
						]),
					_List_fromArray(
						[table])),
					management
				]));
	});
var $author$project$Pages$RoleManagement$MobileToggleView = {$: 'MobileToggleView'};
var $author$project$Pages$RoleManagement$viewMobile = F3(
	function (model, brand, lang) {
		var users = function () {
			var _v9 = model.users;
			if (_v9.$ === 'Success') {
				var u = _v9.a;
				return u;
			} else {
				return _List_Nil;
			}
		}();
		var textColorPrimary = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.primary.rgb));
		var tableHeader = model.roleToggle ? A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('350px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(1))
					])
				]),
			_List_fromArray(
				[lang.settings.table.users.headerEmail]),
			$author$project$Pages$RoleManagement$NoOp) : A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('350px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$RoleManagement$UpdateSort(0))
					])
				]),
			_List_fromArray(
				[lang.settings.table.roles.headerName]),
			$author$project$Pages$RoleManagement$NoOp);
		var roles = function () {
			var _v8 = model.availableRoles;
			if (_v8.$ === 'Success') {
				var r = _v8.a;
				return r;
			} else {
				return _List_Nil;
			}
		}();
		var management = function () {
			var _v5 = model.roleToggle;
			if (!_v5) {
				var _v6 = model.selectedRole;
				if (_v6.$ === 'Just') {
					var r = _v6.a;
					return A4($author$project$Pages$RoleManagement$renderSelectedRole, model, brand, lang, r);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings-sidepanel')
							]),
						_List_Nil);
				}
			} else {
				var _v7 = model.selectedUser;
				if (_v7.$ === 'Just') {
					var u = _v7.a;
					return A3($author$project$Pages$RoleManagement$renderSelectedUser, model, lang, u);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings-sidepanel')
							]),
						_List_Nil);
				}
			}
		}();
		var createListOfRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return A2(
					$elm$core$List$cons,
					x.roleName,
					createListOfRoleNames(xs));
			} else {
				return _List_Nil;
			}
		};
		var selectedRow = function () {
			if (model.roleToggle) {
				var _v3 = model.selectedUser;
				if (_v3.$ === 'Nothing') {
					return $elm$core$Maybe$Nothing;
				} else {
					var user = _v3.a;
					var roleNames = createListOfRoleNames(
						A2(
							$author$project$Common$Helpers$distinctOn,
							function ($) {
								return $.roleName;
							},
							user.roles));
					return $elm$core$Maybe$Just(
						A4(
							$author$project$Elements$Table$createRow2,
							_List_fromArray(
								[
									$elm$core$Maybe$Just('350px')
								]),
							_List_Nil,
							_List_fromArray(
								[user.userEmail]),
							$author$project$Pages$RoleManagement$SelectUser(user)));
				}
			} else {
				var _v4 = model.selectedRole;
				if (_v4.$ === 'Nothing') {
					return $elm$core$Maybe$Nothing;
				} else {
					var role = _v4.a;
					return $elm$core$Maybe$Just(
						A4(
							$author$project$Elements$Table$createRow2,
							_List_fromArray(
								[
									$elm$core$Maybe$Just('350px')
								]),
							_List_Nil,
							_List_fromArray(
								[role.roleName]),
							$author$project$Pages$RoleManagement$SelectRole(role)));
				}
			}
		}();
		var tableRows = model.roleToggle ? A2(
			$elm$core$List$map,
			function (user) {
				var roleNames = createListOfRoleNames(
					A2(
						$author$project$Common$Helpers$distinctOn,
						function ($) {
							return $.roleName;
						},
						user.roles));
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('350px')
						]),
					_List_Nil,
					_List_fromArray(
						[user.userEmail]),
					$author$project$Pages$RoleManagement$SelectUser(user));
			},
			users) : A2(
			$elm$core$List$map,
			function (role) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('350px')
						]),
					_List_Nil,
					_List_fromArray(
						[role.roleName]),
					$author$project$Pages$RoleManagement$SelectRole(role));
			},
			roles);
		var table = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, tableHeader, tableRows, selectedRow);
		var concatRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return _Utils_ap(
					x,
					concatRoleNames(xs));
			} else {
				return '';
			}
		};
		var _v2 = model.mobileView;
		if (_v2.$ === 'Browse') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('management-roles')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('management-main')
							]),
						_List_fromArray(
							[table]))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings')
					]),
				_List_fromArray(
					[
						management,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('m-nav'),
								A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
								$elm$html$Html$Events$onClick($author$project$Pages$RoleManagement$MobileToggleView)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons'),
										textColorPrimary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('arrow_back_ios')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[textColorPrimary]),
								_List_fromArray(
									[
										$elm$html$Html$text('Bläddra')
									]))
							]))
					]));
		}
	});
var $author$project$Pages$RoleManagement$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$RoleManagement$viewMobile, model, brand, lang) : A3($author$project$Pages$RoleManagement$viewDesktop, model, brand, lang);
	});
var $author$project$Pages$RolePermissions$NoOp = {$: 'NoOp'};
var $author$project$Pages$RolePermissions$SelectRole = function (a) {
	return {$: 'SelectRole', a: a};
};
var $author$project$Pages$RolePermissions$renderContent = F3(
	function (model, brand, lang) {
		var selectedRow = function () {
			var _v1 = model.selectedRolePermission;
			if (_v1.$ === 'Just') {
				var rp = _v1.a;
				return $elm$core$Maybe$Just(
					A4(
						$author$project$Elements$Table$createRow2,
						_List_fromArray(
							[
								$elm$core$Maybe$Just('100%')
							]),
						_List_Nil,
						_List_fromArray(
							[rp.roleName]),
						$author$project$Pages$RolePermissions$SelectRole(rp)));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var rpHeader = A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('100%')
				]),
			_List_Nil,
			_List_fromArray(
				[lang.settings.table.permissions.headerRoleName]),
			$author$project$Pages$RolePermissions$NoOp);
		var rolePermissions = function () {
			var _v0 = model.modifiedRolePermissions;
			if (_v0.$ === 'Success') {
				var rp = _v0.a;
				return rp;
			} else {
				return _List_Nil;
			}
		}();
		var rpRows = A2(
			$elm$core$List$map,
			function (rp) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('100%')
						]),
					_List_Nil,
					_List_fromArray(
						[rp.roleName]),
					$author$project$Pages$RolePermissions$SelectRole(rp));
			},
			rolePermissions);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('content')
				]),
			_List_fromArray(
				[
					A5($author$project$Elements$Table$renderTable, model.tableSettings, brand, rpHeader, rpRows, selectedRow)
				]));
	});
var $author$project$Pages$RolePermissions$SaveRolePermissions = function (a) {
	return {$: 'SaveRolePermissions', a: a};
};
var $author$project$Pages$RolePermissions$ToggleTreeChecked = function (a) {
	return {$: 'ToggleTreeChecked', a: a};
};
var $author$project$Pages$RolePermissions$ToggleTreeCollapsed = F2(
	function (a, b) {
		return {$: 'ToggleTreeCollapsed', a: a, b: b};
	});
var $author$project$Pages$RolePermissions$UpdateCollapseAll = function (a) {
	return {$: 'UpdateCollapseAll', a: a};
};
var $author$project$Pages$RolePermissions$UpdateCollapseAt = F2(
	function (a, b) {
		return {$: 'UpdateCollapseAt', a: a, b: b};
	});
var $author$project$Pages$RolePermissions$renderCollapsibleOptions = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('collapsible-options')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Pages$RolePermissions$UpdateCollapseAll(true))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Expand All')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					A2($author$project$Pages$RolePermissions$UpdateCollapseAt, 0, true))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Expand Services')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Pages$RolePermissions$UpdateCollapseAll(false))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Hide All')
				]))
		]));
var $author$project$Elements$Tree$numberOfLevelsChecked = function (tree) {
	if (tree.b) {
		var l = tree.a.a;
		var ls = tree.b;
		return l.isChecked ? (1 + $author$project$Elements$Tree$numberOfLevelsChecked(ls)) : (0 + $author$project$Elements$Tree$numberOfLevelsChecked(ls));
	} else {
		return 0;
	}
};
var $author$project$Elements$Tree$toggleLevelCheckedState = function (_v0) {
	var level = _v0.a;
	return $author$project$Elements$Tree$Level(
		_Utils_update(
			level,
			{isChecked: !level.isChecked}));
};
var $author$project$Elements$Tree$updateParentsState = function (tree) {
	var childrenHasChecked = function () {
		if (tree.b) {
			var l = tree.a.a;
			return A2(
				$elm$core$List$any,
				function (_v2) {
					var x = _v2.a;
					return x.isChecked;
				},
				$author$project$Elements$Tree$flatten(
					$author$project$Elements$Tree$updateParentsState(l.levels)));
		} else {
			return false;
		}
	}();
	if (tree.b) {
		var l = tree.a.a;
		var ls = tree.b;
		return childrenHasChecked ? A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					l,
					{
						isChecked: true,
						levels: $author$project$Elements$Tree$updateParentsState(l.levels)
					})),
			$author$project$Elements$Tree$updateParentsState(ls)) : ((!$elm$core$List$length(l.levels)) ? A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(l),
			$author$project$Elements$Tree$updateParentsState(ls)) : A2(
			$elm$core$List$cons,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					l,
					{
						isChecked: false,
						levels: $author$project$Elements$Tree$updateParentsState(l.levels)
					})),
			$author$project$Elements$Tree$updateParentsState(ls)));
	} else {
		return _List_Nil;
	}
};
var $author$project$Elements$Tree$updateChecked = F2(
	function (tree, isChecked) {
		if (tree.b) {
			var l = tree.a.a;
			var ls = tree.b;
			return A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							isChecked: isChecked,
							levels: A2($author$project$Elements$Tree$updateChecked, l.levels, isChecked)
						})),
				A2($author$project$Elements$Tree$updateChecked, ls, isChecked));
		} else {
			return tree;
		}
	});
var $author$project$Elements$Tree$updateTreeChildrenChecked = F2(
	function (_v0, tree) {
		var level = _v0.a;
		if (tree.b) {
			var l = tree.a.a;
			var ls = tree.b;
			return _Utils_eq(l.key, level.key) ? A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						level,
						{
							levels: A2($author$project$Elements$Tree$updateChecked, level.levels, level.isChecked)
						})),
				ls) : A2(
				$elm$core$List$cons,
				$author$project$Elements$Tree$Level(
					_Utils_update(
						l,
						{
							levels: A2(
								$author$project$Elements$Tree$updateTreeChildrenChecked,
								$author$project$Elements$Tree$Level(level),
								l.levels)
						})),
				A2(
					$author$project$Elements$Tree$updateTreeChildrenChecked,
					$author$project$Elements$Tree$Level(level),
					ls));
		} else {
			return tree;
		}
	});
var $author$project$Elements$Tree$updateCheckedState = F2(
	function (levelToUpdate, tree) {
		var updatedTree = $author$project$Elements$Tree$updateParentsState(
			A2(
				$author$project$Elements$Tree$updateTreeChildrenChecked,
				$author$project$Elements$Tree$toggleLevelCheckedState(levelToUpdate),
				tree));
		var returnTree = ($author$project$Elements$Tree$numberOfLevelsChecked(updatedTree) > 0) ? updatedTree : tree;
		return returnTree;
	});
var $author$project$Elements$Tree$updateCollapsedState = F2(
	function (_v0, tree) {
		var levelToUpdate = _v0.a;
		var updatedTree = A2(
			$author$project$Elements$Tree$replaceLevelInTree,
			$author$project$Elements$Tree$Level(
				_Utils_update(
					levelToUpdate,
					{isCollapsed: !levelToUpdate.isCollapsed})),
			tree);
		return updatedTree;
	});
var $author$project$Elements$Tree$renderLevel = F3(
	function (settings, tree, _v0) {
		var level = _v0.a;
		var nextLevels = ($elm$core$List$length(level.levels) > 0) ? A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree'),
					A2($elm$html$Html$Attributes$style, 'padding-left', '70px')
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Elements$Tree$renderLevel, settings, tree),
				level.levels)) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
		var collapseEvent = function () {
			var _v2 = settings.collapseEvent;
			if (_v2.$ === 'Just') {
				var event = _v2.a;
				return $elm$html$Html$Events$onClick(
					A2(
						event,
						A2(
							$author$project$Elements$Tree$updateCollapsedState,
							$author$project$Elements$Tree$Level(level),
							tree),
						$author$project$Elements$Tree$Level(level)));
			} else {
				return A2($elm$html$Html$Attributes$style, '', '');
			}
		}();
		var checkEvent = function () {
			var _v1 = settings.checkEvent;
			if (_v1.$ === 'Just') {
				var event = _v1.a;
				return $elm$html$Html$Events$onClick(
					event(
						A2(
							$author$project$Elements$Tree$updateCheckedState,
							$author$project$Elements$Tree$Level(level),
							tree)));
			} else {
				return A2($elm$html$Html$Attributes$style, '', '');
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('collapsible', true),
							_Utils_Tuple2('collapsed', level.isCollapsed)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title')
						]),
					_List_fromArray(
						[
							($elm$core$List$length(level.levels) > 0) ? A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									collapseEvent
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									level.isCollapsed ? 'remove' : 'add')
								])) : A2($elm$html$Html$div, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$label,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('cb'),
									A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
									A2($elm$html$Html$Attributes$style, 'color', settings.color.hex)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(level.name),
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('checkbox'),
											$elm$html$Html$Attributes$checked(level.isChecked),
											checkEvent
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('checkmark'),
											level.isChecked ? A2($elm$html$Html$Attributes$style, 'background-color', settings.color.hex) : A2($elm$html$Html$Attributes$style, 'background-color', '#e0e0e0')
										]),
									_List_Nil)
								]))
						])),
					nextLevels
				]));
	});
var $author$project$Elements$Tree$renderLevels = F3(
	function (indent, settings, levels) {
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree'),
					A2($elm$html$Html$Attributes$style, 'padding-left', indent)
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Elements$Tree$renderLevel, settings, levels),
				levels));
	});
var $author$project$Elements$Tree$view = F2(
	function (settings, levels) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', settings.width),
					A2($elm$html$Html$Attributes$style, 'height', settings.height),
					A2($elm$html$Html$Attributes$style, 'margin-top', settings.marginTop),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'scroll'),
					A2($elm$html$Html$Attributes$style, 'padding', '0 20px')
				]),
			_List_fromArray(
				[
					A3($author$project$Elements$Tree$renderLevels, '0', settings, levels)
				]));
	});
var $author$project$Pages$RolePermissions$renderSidepanel = F2(
	function (model, brand) {
		var treeSettings = {
			checkEvent: $elm$core$Maybe$Just($author$project$Pages$RolePermissions$ToggleTreeChecked),
			collapseEvent: $elm$core$Maybe$Just($author$project$Pages$RolePermissions$ToggleTreeCollapsed),
			color: brand.primary,
			height: 'calc(100% - 30px - 27px)',
			marginTop: '30px',
			width: ''
		};
		var isModified = !_Utils_eq(model.initialTree, model.tree);
		var buttonSettings = model.session.isMobile ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'block'),
				A2($elm$html$Html$Attributes$style, 'bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'width', '75%'),
				A2($elm$html$Html$Attributes$style, 'margin', '0 auto')
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
				A2($elm$html$Html$Attributes$style, 'bottom', '0px'),
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidepanel'),
					A2($elm$html$Html$Attributes$style, 'overflow', 'hidden')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sidepanel-content'),
							A2($elm$html$Html$Attributes$style, 'padding', '0')
						]),
					_List_fromArray(
						[
							$author$project$Pages$RolePermissions$renderCollapsibleOptions,
							A2($author$project$Elements$Tree$view, treeSettings, model.tree),
							A5(
							$author$project$Elements$Core$renderButton,
							brand.primary,
							'Save',
							isModified,
							$author$project$Pages$RolePermissions$SaveRolePermissions(model.tree),
							buttonSettings)
						]))
				]));
	});
var $author$project$Pages$RolePermissions$viewDesktop = F3(
	function (model, brand, lang) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings')
				]),
			_List_fromArray(
				[
					A3($author$project$Pages$RolePermissions$renderContent, model, brand, lang),
					A2($author$project$Pages$RolePermissions$renderSidepanel, model, brand)
				]));
	});
var $author$project$Pages$RolePermissions$MobileToggleView = {$: 'MobileToggleView'};
var $author$project$Pages$RolePermissions$viewMobile = F3(
	function (model, brand, lang) {
		var textColorPrimary = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.primary.rgb));
		var roleName = function () {
			var _v1 = model.selectedRolePermission;
			if (_v1.$ === 'Just') {
				var rp = _v1.a;
				return rp.roleName;
			} else {
				return '';
			}
		}();
		var _v0 = model.mobileView;
		if (_v0.$ === 'Browse') {
			return A3($author$project$Pages$RolePermissions$renderContent, model, brand, lang);
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('setting-title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(roleName)
							])),
						A2($author$project$Pages$RolePermissions$renderSidepanel, model, brand),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('m-nav'),
								A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
								$elm$html$Html$Events$onClick($author$project$Pages$RolePermissions$MobileToggleView)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons'),
										textColorPrimary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('arrow_back_ios')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[textColorPrimary]),
								_List_fromArray(
									[
										$elm$html$Html$text('Bläddra roller')
									]))
							]))
					]));
		}
	});
var $author$project$Pages$RolePermissions$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$RolePermissions$viewMobile, model, brand, lang) : A3($author$project$Pages$RolePermissions$viewDesktop, model, brand, lang);
	});
var $author$project$Common$Types$Column = F5(
	function (name, action, active, status, selected) {
		return {action: action, active: active, name: name, selected: selected, status: status};
	});
var $author$project$Pages$Subscriptions$NoOp = {$: 'NoOp'};
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $elm_community$list_extra$List$Extra$elemIndex = function (x) {
	return $elm_community$list_extra$List$Extra$findIndex(
		$elm$core$Basics$eq(x));
};
var $author$project$Common$Brand$Rgb = F3(
	function (red, green, blue) {
		return {blue: blue, green: green, red: red};
	});
var $author$project$Common$Brand$rgbToString = function (rgb) {
	return $elm$core$String$fromInt(rgb.red) + (',' + ($elm$core$String$fromInt(rgb.green) + (',' + $elm$core$String$fromInt(rgb.blue))));
};
var $author$project$Common$Brand$stringToRgb = function (rgbString) {
	var parts = A2(
		$elm$core$String$split,
		',',
		A3($elm$core$String$replace, ' ', '', rgbString));
	if (((parts.b && parts.b.b) && parts.b.b.b) && (!parts.b.b.b.b)) {
		var r = parts.a;
		var _v1 = parts.b;
		var g = _v1.a;
		var _v2 = _v1.b;
		var b = _v2.a;
		var _v3 = _Utils_Tuple3(
			$elm$core$String$toInt(r),
			$elm$core$String$toInt(g),
			$elm$core$String$toInt(b));
		if (((_v3.a.$ === 'Just') && (_v3.b.$ === 'Just')) && (_v3.c.$ === 'Just')) {
			var red = _v3.a.a;
			var green = _v3.b.a;
			var blue = _v3.c.a;
			return $elm$core$Maybe$Just(
				A3($author$project$Common$Brand$Rgb, red, green, blue));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Subscriptions$renderColumnItem = F3(
	function (color, showArrow, item) {
		var statusNotice = function () {
			var _v1 = item.status;
			switch (_v1) {
				case 1:
					return _List_Nil;
				case 2:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', '#32a852'),
									A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
									A2($elm$html$Html$Attributes$style, 'margin-left', '5px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('event')
								]))
						]);
				case 3:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', '#de2f2f'),
									A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
									A2($elm$html$Html$Attributes$style, 'margin-left', '5px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('priority_high')
								]))
						]);
				default:
					return _List_Nil;
			}
		}();
		var rgb = item.active ? $author$project$Common$Brand$rgbToString(color.rgb) : '184, 184, 184';
		var rgbBrand = function () {
			var _v0 = $author$project$Common$Brand$stringToRgb(rgb);
			if (_v0.$ === 'Just') {
				var color_ = _v0.a;
				return color_;
			} else {
				return A3($author$project$Common$Brand$Rgb, 255, 255, 255);
			}
		}();
		var opacity = item.selected ? '1' : '0.35';
		var rgba = 'rgba(' + (rgb + (', ' + (opacity + ')')));
		var label = A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$text(item.name)
					]),
				statusNotice));
		var icon = true ? 'done' : 'close';
		var gradientRgba2 = item.selected ? ('rgba(' + (rgb + ', 0.85)')) : ('rgba(' + (rgb + ', 0.50)'));
		var gradientRgba1 = rgba;
		var gradient = rgba;
		var arrow = showArrow ? _List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('material-icons'),
						A2($elm$html$Html$Attributes$style, 'float', 'right')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('keyboard_arrow_right')
					]))
			]) : _List_Nil;
		var content = A2($elm$core$List$cons, label, arrow);
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'color', '#000'),
					$elm$html$Html$Events$onClick(item.action),
					A2($elm$html$Html$Attributes$style, 'background', gradient),
					A2($elm$html$Html$Attributes$style, 'height', '26.33px')
				]),
			content);
	});
var $author$project$Pages$Subscriptions$renderColumn = F5(
	function (color, items, label, offset, showArrow) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('column')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h4,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'background-color', '#FFF'),
							A2($elm$html$Html$Attributes$style, 'color', '#000')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$style,
							'margin-top',
							'calc(47.33px * ' + ($elm$core$String$fromInt(offset) + ')'))
						]),
					A2(
						$elm$core$List$map,
						A2($author$project$Pages$Subscriptions$renderColumnItem, color, showArrow),
						items))
				]));
	});
var $author$project$Pages$Subscriptions$renderTable = F2(
	function (model, brand) {
		var isServiceActivated = function (service) {
			return A2(
				$elm$core$List$any,
				function (a) {
					return (a.applicationIsPaid === 1) || (a.applicationIsPaid === 2);
				},
				service.applications);
		};
		var isApplicationActivated = function (application) {
			return (application.applicationIsPaid === 1) || (application.applicationIsPaid === 2);
		};
		var tabColumns = function () {
			var _v7 = model.selectedApplication;
			if (_v7.$ === 'Just') {
				var a = _v7.a;
				return A2(
					$elm$core$List$map,
					function (t) {
						return A5(
							$author$project$Common$Types$Column,
							t.tabName,
							$author$project$Pages$Subscriptions$NoOp,
							isApplicationActivated(a),
							0,
							false);
					},
					a.tabs);
			} else {
				return _List_Nil;
			}
		}();
		var customerServices = function () {
			var _v6 = model.services;
			if (_v6.$ === 'Success') {
				var services = _v6.a;
				return services;
			} else {
				return _List_Nil;
			}
		}();
		var completeServiceList = customerServices;
		var serviceColumns = A2(
			$elm$core$List$map,
			function (s) {
				return A5(
					$author$project$Common$Types$Column,
					s.serviceName,
					$author$project$Pages$Subscriptions$SelectSubService(s),
					isServiceActivated(s),
					1,
					_Utils_eq(
						$elm$core$Maybe$Just(s),
						model.selectedService));
			},
			completeServiceList);
		var applicationColumns = function () {
			var _v5 = model.selectedService;
			if (_v5.$ === 'Just') {
				var s = _v5.a;
				return A2(
					$elm$core$List$map,
					function (a) {
						return A5(
							$author$project$Common$Types$Column,
							a.applicationName,
							$author$project$Pages$Subscriptions$SelectSubApplication(a),
							isApplicationActivated(a),
							a.applicationIsPaid,
							_Utils_eq(
								$elm$core$Maybe$Just(a),
								model.selectedApplication));
					},
					s.applications);
			} else {
				return _List_Nil;
			}
		}();
		var _v0 = function () {
			var _v1 = model.selectedService;
			if (_v1.$ === 'Just') {
				var service = _v1.a;
				var _v2 = model.services;
				if (_v2.$ === 'Success') {
					var services = _v2.a;
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$withDefault,
							0,
							A2($elm_community$list_extra$List$Extra$elemIndex, service, services)),
						service.applications);
				} else {
					return _Utils_Tuple2(
						A2(
							$elm$core$Maybe$withDefault,
							0,
							$elm$core$String$toInt('0')),
						_List_Nil);
				}
			} else {
				return _Utils_Tuple2(
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt('0')),
					_List_Nil);
			}
		}();
		var selectedServiceIndex = _v0.a;
		var applications = _v0.b;
		var selectedApplicationIndex = function () {
			var _v4 = model.selectedApplication;
			if (_v4.$ === 'Just') {
				var application = _v4.a;
				return A2(
					$elm$core$Maybe$withDefault,
					0,
					A2($elm_community$list_extra$List$Extra$elemIndex, application, applications));
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt('0'));
			}
		}();
		var _v3 = model.services;
		if (_v3.$ === 'Loading') {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('placeholder'),
						A2($elm$html$Html$Attributes$style, 'width', '100%'),
						A2($elm$html$Html$Attributes$style, 'height', 'calc(100% - 28px)')
					]),
				_List_fromArray(
					[
						A2($author$project$Elements$Core$renderLoading, brand, _List_Nil)
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('table'),
						A2($elm$html$Html$Attributes$style, 'width', '100%')
					]),
				_List_fromArray(
					[
						A5($author$project$Pages$Subscriptions$renderColumn, brand.primary, serviceColumns, 'Tjänst', 0, true),
						A5($author$project$Pages$Subscriptions$renderColumn, brand.primary, applicationColumns, 'Applikation', selectedServiceIndex, true),
						A5($author$project$Pages$Subscriptions$renderColumn, brand.primary, tabColumns, 'Tabb', selectedServiceIndex + selectedApplicationIndex, false)
					]));
		}
	});
var $author$project$Elements$Core$renderDropdownItem = F2(
	function (selectedCustomer, customer) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(customer.customerKey),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(customer, selectedCustomer))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(customer.customerName)
				]));
	});
var $author$project$Elements$Core$renderCustomerDropdownV2 = F5(
	function (customers, brand, selected, clickEvent, customStyles) {
		return A2(
			$elm$html$Html$select,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('app-dropdown', true),
								_Utils_Tuple2('bordered', true)
							])),
						$elm$html$Html$Events$onInput(clickEvent),
						A2($elm$html$Html$Attributes$style, 'color', brand.secondary.hex)
					]),
				customStyles),
			A2(
				$elm$core$List$map,
				$author$project$Elements$Core$renderDropdownItem(selected),
				customers));
	});
var $author$project$Pages$Subscriptions$renderTableTop = F2(
	function (model, brand) {
		var selectedCustomer = function () {
			var _v0 = model.selectedCustomer;
			if (_v0.$ === 'Just') {
				var c = _v0.a;
				return c;
			} else {
				return A4($author$project$Common$Types$Customer, '', '', '', '');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('topbar-content'),
					A2($elm$html$Html$Attributes$style, 'width', 'calc(100% - 10px)')
				]),
			_List_fromArray(
				[
					A5(
					$author$project$Elements$Core$renderCustomerDropdownV2,
					model.customers,
					brand,
					selectedCustomer,
					$author$project$Pages$Subscriptions$RequestCustomerSubscription,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', 'unset'),
							A2($elm$html$Html$Attributes$style, 'border-color', brand.primary.hex)
						]))
				]));
	});
var $author$project$Pages$Subscriptions$renderContent = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('management-main'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'unset')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Subscriptions$renderTableTop, model, brand),
					A2($author$project$Pages$Subscriptions$renderTable, model, brand)
				]));
	});
var $author$project$Pages$Subscriptions$UpdateCustomerPurchase = function (a) {
	return {$: 'UpdateCustomerPurchase', a: a};
};
var $author$project$Pages$Subscriptions$UpdateLicenseDuration = F2(
	function (a, b) {
		return {$: 'UpdateLicenseDuration', a: a, b: b};
	});
var $justinmimbs$date$Date$Years = {$: 'Years'};
var $author$project$Pages$Subscriptions$licenseTags = _List_fromArray(
	[
		_Utils_Tuple2(
		'Ej aktiv',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, -10)),
		_Utils_Tuple2(
		'1 månad',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Months, 1)),
		_Utils_Tuple2(
		'6 månader',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Months, 6)),
		_Utils_Tuple2(
		'1 år',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, 1)),
		_Utils_Tuple2(
		'2 år',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, 2)),
		_Utils_Tuple2(
		'5 år',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, 5)),
		_Utils_Tuple2(
		'10 år',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, 10)),
		_Utils_Tuple2(
		'Löpande',
		A2($author$project$Pages$Subscriptions$UpdateLicenseDuration, $justinmimbs$date$Date$Years, 100))
	]);
var $author$project$Pages$Subscriptions$ToDatePicker = F2(
	function (a, b) {
		return {$: 'ToDatePicker', a: a, b: b};
	});
var $CurrySoftware$elm_datepicker$DatePicker$Blur = {$: 'Blur'};
var $CurrySoftware$elm_datepicker$DatePicker$Focus = {$: 'Focus'};
var $CurrySoftware$elm_datepicker$DatePicker$SubmitText = {$: 'SubmitText'};
var $CurrySoftware$elm_datepicker$DatePicker$Text = function (a) {
	return {$: 'Text', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$ChangeFocus = function (a) {
	return {$: 'ChangeFocus', a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$MouseDown = {$: 'MouseDown'};
var $CurrySoftware$elm_datepicker$DatePicker$MouseUp = {$: 'MouseUp'};
var $CurrySoftware$elm_datepicker$DatePicker$Date$changeYear = F2(
	function (current, newYear) {
		var _v0 = $elm$core$String$toInt(newYear);
		if (_v0.$ === 'Just') {
			var year = _v0.a;
			return A3(
				$justinmimbs$date$Date$fromCalendarDate,
				year,
				$justinmimbs$date$Date$month(current),
				$justinmimbs$date$Date$day(current));
		} else {
			return current;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$groupDates = function (dates) {
	var go = F4(
		function (i, xs, racc, acc) {
			go:
			while (true) {
				if (!xs.b) {
					return $elm$core$List$reverse(acc);
				} else {
					var x = xs.a;
					var xxs = xs.b;
					if (i === 6) {
						var $temp$i = 0,
							$temp$xs = xxs,
							$temp$racc = _List_Nil,
							$temp$acc = A2(
							$elm$core$List$cons,
							$elm$core$List$reverse(
								A2($elm$core$List$cons, x, racc)),
							acc);
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					} else {
						var $temp$i = i + 1,
							$temp$xs = xxs,
							$temp$racc = A2($elm$core$List$cons, x, racc),
							$temp$acc = acc;
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					}
				}
			}
		});
	return A4(go, 0, dates, _List_Nil, _List_Nil);
};
var $CurrySoftware$elm_datepicker$DatePicker$maybeOr = F2(
	function (lhs, rhs) {
		if (rhs.$ === 'Just') {
			return rhs;
		} else {
			return lhs;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$mkClass = F2(
	function (_v0, c) {
		var classNamespace = _v0.classNamespace;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(classNamespace, c));
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $justinmimbs$date$Date$Day = {$: 'Day'};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd.$) {
		case 'Mon':
			return 1;
		case 'Tue':
			return 2;
		case 'Wed':
			return 3;
		case 'Thu':
			return 4;
		case 'Fri':
			return 5;
		case 'Sat':
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return $justinmimbs$date$Date$RD(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1);
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date.a;
		switch (interval.$) {
			case 'Year':
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 'Quarter':
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 'Month':
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 'Week':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Mon, date));
			case 'Monday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Mon, date));
			case 'Tuesday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Tue, date));
			case 'Wednesday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Wed, date));
			case 'Thursday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Thu, date));
			case 'Friday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Fri, date));
			case 'Saturday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Sat, date));
			case 'Sunday':
				return $justinmimbs$date$Date$RD(
					rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, $elm$time$Time$Sun, date));
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Days = {$: 'Days'};
var $justinmimbs$date$Date$Weeks = {$: 'Weeks'};
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval.$) {
		case 'Year':
			return _Utils_Tuple2(1, $justinmimbs$date$Date$Years);
		case 'Quarter':
			return _Utils_Tuple2(3, $justinmimbs$date$Date$Months);
		case 'Month':
			return _Utils_Tuple2(1, $justinmimbs$date$Date$Months);
		case 'Day':
			return _Utils_Tuple2(1, $justinmimbs$date$Date$Days);
		default:
			var week = interval;
			return _Utils_Tuple2(1, $justinmimbs$date$Date$Weeks);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3(
					$justinmimbs$date$Date$add,
					unit,
					step,
					$justinmimbs$date$Date$RD(current));
				var next = _v0.a;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2(
					$elm$core$List$cons,
					$justinmimbs$date$Date$RD(current),
					revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0.a;
		var until = _v1.a;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2(
			$justinmimbs$date$Date$ceiling,
			interval,
			$justinmimbs$date$Date$RD(start));
		var first = _v3.a;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = {$: 'Friday'};
var $justinmimbs$date$Date$Monday = {$: 'Monday'};
var $justinmimbs$date$Date$Saturday = {$: 'Saturday'};
var $justinmimbs$date$Date$Sunday = {$: 'Sunday'};
var $justinmimbs$date$Date$Thursday = {$: 'Thursday'};
var $justinmimbs$date$Date$Tuesday = {$: 'Tuesday'};
var $justinmimbs$date$Date$Wednesday = {$: 'Wednesday'};
var $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval = function (weekday) {
	switch (weekday.$) {
		case 'Mon':
			return $justinmimbs$date$Date$Monday;
		case 'Tue':
			return $justinmimbs$date$Date$Tuesday;
		case 'Wed':
			return $justinmimbs$date$Date$Wednesday;
		case 'Thu':
			return $justinmimbs$date$Date$Thursday;
		case 'Fri':
			return $justinmimbs$date$Date$Friday;
		case 'Sat':
			return $justinmimbs$date$Date$Saturday;
		default:
			return $justinmimbs$date$Date$Sunday;
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$prepareDates = F2(
	function (date, firstDayOfWeek) {
		var weekdayAsInterval = $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval(firstDayOfWeek);
		var start = A2(
			$justinmimbs$date$Date$floor,
			weekdayAsInterval,
			A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(date),
				$justinmimbs$date$Date$month(date),
				1));
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date),
			1);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayAsInterval,
			A3($justinmimbs$date$Date$add, $justinmimbs$date$Date$Months, 1, firstOfMonth));
		return {
			currentDates: A4($justinmimbs$date$Date$range, $justinmimbs$date$Date$Day, 1, start, end),
			currentMonth: date
		};
	});
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0.a;
	return rd;
};
var $CurrySoftware$elm_datepicker$DatePicker$mkClassList = F2(
	function (_v0, cs) {
		var classNamespace = _v0.classNamespace;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var c = _v1.a;
					var b = _v1.b;
					return _Utils_Tuple2(
						_Utils_ap(classNamespace, c),
						b);
				},
				cs));
	});
var $CurrySoftware$elm_datepicker$DatePicker$viewDay = F5(
	function (settings, picked, isOtherMonth, isToday, d) {
		var disabled = settings.isDisabled(d);
		var props = (!disabled) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$CurrySoftware$elm_datepicker$DatePicker$Pick(d))
			]) : _List_Nil;
		var classList = $CurrySoftware$elm_datepicker$DatePicker$mkClassList(settings);
		return A2(
			$elm$html$Html$td,
			_Utils_ap(
				_List_fromArray(
					[
						classList(
						_List_fromArray(
							[
								_Utils_Tuple2('day', true),
								_Utils_Tuple2('disabled', disabled),
								_Utils_Tuple2(
								'picked',
								picked(d)),
								_Utils_Tuple2(
								'today',
								isToday(d)),
								_Utils_Tuple2(
								'other-month',
								isOtherMonth(d))
							]))
					]),
				props),
			_List_fromArray(
				[
					settings.cellFormatter(
					$elm$core$String$fromInt(
						$justinmimbs$date$Date$day(d)))
				]));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$yearRange = F2(
	function (_v0, range) {
		var currentMonth = _v0.currentMonth;
		var today = _v0.today;
		switch (range.$) {
			case 'MoreOrLess':
				var num = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth) - num,
					$justinmimbs$date$Date$year(currentMonth) + num);
			case 'Between':
				var start = range.a;
				var end = range.b;
				return A2($elm$core$List$range, start, end);
			case 'From':
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					year_,
					$justinmimbs$date$Date$year(today));
			case 'To':
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(today),
					year_);
			default:
				return _List_Nil;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$yearRangeActive = function (yearRange) {
	return !_Utils_eq(yearRange, $CurrySoftware$elm_datepicker$DatePicker$Date$Off);
};
var $CurrySoftware$elm_datepicker$DatePicker$datePicker = F3(
	function (pickedDate, settings, model) {
		var focused = model.focused;
		var today = model.today;
		var picked = function (d) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (pdate) {
						return _Utils_eq(
							$justinmimbs$date$Date$toRataDie(pdate),
							$justinmimbs$date$Date$toRataDie(d));
					},
					pickedDate));
		};
		var onChange = function (handler) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, handler, $elm$html$Html$Events$targetValue));
		};
		var isToday = function (d) {
			return _Utils_eq(
				$justinmimbs$date$Date$toRataDie(d),
				$justinmimbs$date$Date$toRataDie(today));
		};
		var firstDayOffset = $justinmimbs$date$Date$weekdayToNumber(settings.firstDayOfWeek) - 1;
		var dpClass = $CurrySoftware$elm_datepicker$DatePicker$mkClass(settings);
		var currentDate = A2(
			$elm$core$Maybe$withDefault,
			today,
			A2($CurrySoftware$elm_datepicker$DatePicker$maybeOr, pickedDate, focused));
		var isOtherMonth = function (d) {
			return !_Utils_eq(
				$justinmimbs$date$Date$month(currentDate),
				$justinmimbs$date$Date$month(d));
		};
		var arrow = F2(
			function (className, message) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							dpClass(className),
							$elm$html$Html$Events$onClick(message),
							$elm$html$Html$Attributes$tabindex(-1),
							$elm$html$Html$Attributes$type_('button')
						]),
					_List_Nil);
			});
		var _v0 = A2($CurrySoftware$elm_datepicker$DatePicker$prepareDates, currentDate, settings.firstDayOfWeek);
		var currentMonth = _v0.currentMonth;
		var currentDates = _v0.currentDates;
		var dayList = A2(
			$elm$core$List$map,
			function (rowDays) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							dpClass('row')
						]),
					A2(
						$elm$core$List$map,
						A4($CurrySoftware$elm_datepicker$DatePicker$viewDay, settings, picked, isOtherMonth, isToday),
						rowDays));
			},
			$CurrySoftware$elm_datepicker$DatePicker$groupDates(currentDates));
		var isCurrentYear = function (selectedYear) {
			return _Utils_eq(
				$justinmimbs$date$Date$year(currentMonth),
				selectedYear);
		};
		var yearOption = F2(
			function (index, selectedYear) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(index),
					A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(selectedYear)),
								$elm$html$Html$Attributes$selected(
								isCurrentYear(selectedYear))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(selectedYear))
							])));
			});
		var _v1 = function () {
			var front = function (to_) {
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth),
					to_);
			};
			var back = function (from_) {
				return A2(
					$elm$core$List$range,
					from_,
					$justinmimbs$date$Date$year(currentMonth));
			};
			var _v2 = settings.changeYear;
			switch (_v2.$) {
				case 'From':
					var from_ = _v2.a;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(
							$justinmimbs$date$Date$year(today) + 1));
				case 'To':
					var to_ = _v2.a;
					return _Utils_Tuple2(
						front(
							$justinmimbs$date$Date$year(today) - 1),
						back(to_ + 1));
				case 'Between':
					var from_ = _v2.a;
					var to_ = _v2.b;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(to_ + 1));
				case 'MoreOrLess':
					var y = _v2.a;
					return _Utils_Tuple2(_List_Nil, _List_Nil);
				default:
					return _Utils_Tuple2(_List_Nil, _List_Nil);
			}
		}();
		var addedYearsFront = _v1.a;
		var addedYearsBack = _v1.b;
		var dropdownYear = A3(
			$elm$html$Html$Keyed$node,
			'select',
			_List_fromArray(
				[
					onChange(
					A2(
						$elm$core$Basics$composeR,
						$CurrySoftware$elm_datepicker$DatePicker$Date$changeYear(currentDate),
						$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus)),
					dpClass('year-menu')
				]),
			A2(
				$elm$core$List$indexedMap,
				yearOption,
				$elm$core$List$concat(
					_List_fromArray(
						[
							addedYearsFront,
							A2(
							$CurrySoftware$elm_datepicker$DatePicker$Date$yearRange,
							{currentMonth: currentMonth, today: today},
							settings.changeYear),
							addedYearsBack
						]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					dpClass('picker'),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mousedown',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseDown, true))),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mouseup',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseUp, true)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							dpClass('picker-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('prev-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'prev',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, $justinmimbs$date$Date$Months, -1, currentDate)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('month-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('month')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											settings.monthFormatter(
												$justinmimbs$date$Date$month(currentMonth)))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('year')
										]),
									_List_fromArray(
										[
											(!$CurrySoftware$elm_datepicker$DatePicker$yearRangeActive(settings.changeYear)) ? $elm$html$Html$text(
											settings.yearFormatter(
												$justinmimbs$date$Date$year(currentMonth))) : A3(
											$elm$html$Html$Keyed$node,
											'span',
											_List_Nil,
											_List_fromArray(
												[
													_Utils_Tuple2(
													$elm$core$String$fromInt(
														$justinmimbs$date$Date$year(currentMonth)),
													dropdownYear)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('next-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'next',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, $justinmimbs$date$Date$Months, 1, currentDate)))
								]))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							dpClass('table')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_fromArray(
								[
									dpClass('weekdays')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (d) {
											return A2(
												$elm$html$Html$td,
												_List_fromArray(
													[
														dpClass('dow')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														settings.dayFormatter(d))
													]));
										},
										A2(
											$elm$core$List$take,
											7,
											A2(
												$elm$core$List$drop,
												firstDayOffset,
												$elm$core$List$concat(
													A2(
														$elm$core$List$repeat,
														2,
														_List_fromArray(
															[$elm$time$Time$Mon, $elm$time$Time$Tue, $elm$time$Time$Wed, $elm$time$Time$Thu, $elm$time$Time$Fri, $elm$time$Time$Sat, $elm$time$Time$Sun])))))))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_fromArray(
								[
									dpClass('days')
								]),
							dayList)
						]))
				]));
	});
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $CurrySoftware$elm_datepicker$DatePicker$view = F3(
	function (pickedDate, settings, _v0) {
		var datepicker = _v0.a;
		var model = datepicker;
		var potentialInputId = A3(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$core$List$filterMap($elm$core$Basics$identity),
			A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, settings.inputId));
		var inputClasses = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(settings.classNamespace + 'input', true)
				]),
			settings.inputClassList);
		var inputCommon = function (xs) {
			return A2(
				$elm$html$Html$input,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(inputClasses),
							$elm$html$Html$Attributes$name(
							A2($elm$core$Maybe$withDefault, '', settings.inputName)),
							$elm$html$Html$Attributes$type_('text'),
							A2(
							$elm$html$Html$Events$on,
							'change',
							$elm$json$Json$Decode$succeed($CurrySoftware$elm_datepicker$DatePicker$SubmitText)),
							$elm$html$Html$Events$onInput($CurrySoftware$elm_datepicker$DatePicker$Text),
							$elm$html$Html$Events$onBlur($CurrySoftware$elm_datepicker$DatePicker$Blur),
							$elm$html$Html$Events$onClick($CurrySoftware$elm_datepicker$DatePicker$Focus),
							$elm$html$Html$Events$onFocus($CurrySoftware$elm_datepicker$DatePicker$Focus)
						]),
					_Utils_ap(
						settings.inputAttributes,
						_Utils_ap(potentialInputId, xs))),
				_List_Nil);
		};
		var dateInput = inputCommon(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$placeholder(settings.placeholder),
					$elm$html$Html$Attributes$value(
					A2(
						$elm$core$Maybe$withDefault,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, settings.dateFormatter, pickedDate)),
						model.inputText))
				]));
		var containerClassList = A2(
			$elm$core$List$cons,
			_Utils_Tuple2(settings.classNamespace + 'container', true),
			settings.containerClassList);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(containerClassList)
				]),
			_List_fromArray(
				[
					dateInput,
					model.open ? A3($CurrySoftware$elm_datepicker$DatePicker$datePicker, pickedDate, settings, model) : $elm$html$Html$text('')
				]));
	});
var $author$project$Pages$Subscriptions$renderDatePicker = F4(
	function (label, date, picker, pickType) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('datepicker')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '40%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(label)
										]))
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '60%')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('date_range')
										])),
									A2(
									$elm$html$Html$map,
									$author$project$Pages$Subscriptions$ToDatePicker(pickType),
									A3(
										$CurrySoftware$elm_datepicker$DatePicker$view,
										date,
										$author$project$Pages$Subscriptions$settings(picker),
										picker))
								]))
						]))
				]));
	});
var $author$project$Pages$Subscriptions$renderNotice = function (status) {
	var content = function () {
		switch (status) {
			case 2:
				return $elm$core$Maybe$Just(
					_Utils_Tuple3(
						'Prenumeration är schemalagd',
						'#32a852',
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
									A2($elm$html$Html$Attributes$style, 'margin-right', '2px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('event')
								]))));
			case 3:
				return $elm$core$Maybe$Just(
					_Utils_Tuple3(
						'Prenumeration har gått ut',
						'#de2f2f',
						A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
									A2($elm$html$Html$Attributes$style, 'margin-right', '2px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('priority_high')
								]))));
			default:
				return $elm$core$Maybe$Nothing;
		}
	}();
	if (content.$ === 'Nothing') {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		var _v1 = content.a;
		var noticeText = _v1.a;
		var color = _v1.b;
		var icon = _v1.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					A2($elm$html$Html$Attributes$style, 'color', color),
					A2($elm$html$Html$Attributes$style, 'text-align', 'center')
				]),
			_List_fromArray(
				[
					icon,
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0'),
							A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(noticeText)
						]))
				]));
	}
};
var $author$project$Pages$Subscriptions$renderTag = function (_v0) {
	var tagName = _v0.a;
	var tagMsg = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(tagMsg)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(tagName)
			]));
};
var $author$project$Pages$Subscriptions$renderTagList = function (tags) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('taglist'),
				A2($elm$html$Html$Attributes$style, 'display', 'flex'),
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]),
		A2($elm$core$List$map, $author$project$Pages$Subscriptions$renderTag, tags));
};
var $author$project$Pages$Subscriptions$renderCustomerSubscriptionSidepanel = F2(
	function (model, brand) {
		var selectedCustomer = function () {
			var _v3 = model.selectedCustomer;
			if (_v3.$ === 'Just') {
				var c = _v3.a;
				return c;
			} else {
				return A4($author$project$Common$Types$Customer, '', '', '', '');
			}
		}();
		var selectedAppIsActivated = function () {
			var _v1 = model.selectedApplication;
			if (_v1.$ === 'Just') {
				var application = _v1.a;
				var _v2 = _Utils_Tuple2(
					application.applicationIsPaid,
					$elm$core$String$isEmpty(application.applicationStartDateTime));
				if (!_v2.b) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}();
		var saveButtonText = selectedAppIsActivated ? 'Uppdatera' : 'Aktivera';
		var applicationStatus = function () {
			var _v0 = model.selectedApplication;
			if (_v0.$ === 'Just') {
				var application = _v0.a;
				return application.applicationIsPaid;
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-sidepanel')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('settings-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%'),
									A2($elm$html$Html$Attributes$style, 'height', '20px')
								]),
							_List_Nil),
							A4($author$project$Pages$Subscriptions$renderDatePicker, 'Startdatum:', model.startDate, model.startPicker, 'start'),
							A4($author$project$Pages$Subscriptions$renderDatePicker, 'Slutdatum:', model.endDate, model.endPicker, 'end'),
							$author$project$Pages$Subscriptions$renderTagList($author$project$Pages$Subscriptions$licenseTags),
							$author$project$Pages$Subscriptions$renderNotice(applicationStatus),
							A5(
							$author$project$Elements$Core$renderButton,
							brand.primary,
							saveButtonText,
							true,
							$author$project$Pages$Subscriptions$UpdateCustomerPurchase(selectedAppIsActivated),
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]))
						]))
				]));
	});
var $author$project$Pages$Subscriptions$view = F2(
	function (model, brand) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-full')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Subscriptions$renderContent, model, brand),
					A2($author$project$Pages$Subscriptions$renderCustomerSubscriptionSidepanel, model, brand),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(model.debug)
						]))
				]));
	});
var $author$project$Pages$UserInfo$NoOp = {$: 'NoOp'};
var $author$project$Pages$UserInfo$SelectUser = function (a) {
	return {$: 'SelectUser', a: a};
};
var $author$project$Pages$UserInfo$UpdateSort = function (a) {
	return {$: 'UpdateSort', a: a};
};
var $author$project$Pages$UserInfo$RequestUpdateUser = function (a) {
	return {$: 'RequestUpdateUser', a: a};
};
var $author$project$Pages$UserInfo$UpdateUser = F2(
	function (a, b) {
		return {$: 'UpdateUser', a: a, b: b};
	});
var $author$project$Common$Helpers$translateAccountType = function (acc) {
	switch (acc) {
		case 'WORK_OR_SCHOOL_ACCOUNT':
			return 'Work or school account';
		case 'MICROSOFT_ACCOUNT':
			return 'Microsoft account';
		default:
			return 'Unidentified';
	}
};
var $author$project$Pages$UserInfo$renderUserInfoSidepanel = F3(
	function (model, brand, lang) {
		var saveState = function () {
			var _v1 = model.selectedUser;
			if (_v1.$ === 'Just') {
				var u = _v1.a;
				var _v2 = model.users;
				if (_v2.$ === 'Success') {
					var us = _v2.a;
					var _v3 = A2(
						$elm$core$List$filter,
						function (x) {
							return _Utils_eq(x, u);
						},
						us);
					if (_v3.b) {
						var x = _v3.a;
						var xs = _v3.b;
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}
		}();
		var _default = function () {
			var s = $author$project$Elements$Core$default;
			return _Utils_update(
				s,
				{color: brand.primary.hex, height: '', margin: 'auto auto 7px auto', width: '100%'});
		}();
		var _v0 = model.selectedUser;
		if (_v0.$ === 'Just') {
			var u = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings-content'),
						A2($elm$html$Html$Attributes$style, 'margin-top', '0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'font-size', '2em')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Common$Helpers$translateAccountType(u.userLoginType))
							])),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserInfo$UpdateUser(0))
							}),
						'username',
						'Name',
						$elm$core$Maybe$Just('account_circle'),
						u.userFullName),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{readonly: true}),
						'email',
						'Email',
						$elm$core$Maybe$Just('email'),
						u.userEmail),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserInfo$UpdateUser(2))
							}),
						'workphone',
						'Work Phone',
						$elm$core$Maybe$Just('contact_phone'),
						u.userWorkPhone),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserInfo$UpdateUser(3))
							}),
						'mobilephone',
						'Mobile Phone',
						$elm$core$Maybe$Just('contact_phone'),
						u.userMobilePhone),
						A5(
						$author$project$Elements$Core$renderButton,
						brand.primary,
						lang.settings.save,
						saveState,
						$author$project$Pages$UserInfo$RequestUpdateUser(u),
						_List_Nil)
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('settings-content')
					]),
				_List_Nil);
		}
	});
var $author$project$Pages$UserInfo$viewDesktop = F3(
	function (model, brand, lang) {
		var users_ = function () {
			var _v6 = model.users;
			if (_v6.$ === 'Success') {
				var u = _v6.a;
				return u;
			} else {
				return _List_Nil;
			}
		}();
		var userHeader = A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('175px'),
					$elm$core$Maybe$Just('350px'),
					$elm$core$Maybe$Just('350px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserInfo$UpdateSort(0))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserInfo$UpdateSort(1))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserInfo$UpdateSort(2))
					])
				]),
			_List_fromArray(
				[lang.settings.table.users.headerName, lang.settings.table.users.headerEmail, lang.settings.table.users.headerRoles]),
			$author$project$Pages$UserInfo$NoOp);
		var filteredUsers = function () {
			var _v4 = model.users;
			if (_v4.$ === 'Success') {
				var u = _v4.a;
				var _v5 = A2($elm$core$Dict$get, 'userinfo', model.searches);
				if (_v5.$ === 'Just') {
					var s = _v5.a;
					return A2(
						$elm$core$List$filter,
						function (x) {
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(s),
								$elm$core$String$toLower(x.userFullName));
						},
						u);
				} else {
					return u;
				}
			} else {
				return _List_Nil;
			}
		}();
		var createListOfRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return A2(
					$elm$core$List$cons,
					x.roleName,
					createListOfRoleNames(xs));
			} else {
				return _List_Nil;
			}
		};
		var concatRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return _Utils_ap(
					x,
					concatRoleNames(xs));
			} else {
				return '';
			}
		};
		var selectedRow = function () {
			var _v3 = model.selectedUser;
			if (_v3.$ === 'Nothing') {
				return $elm$core$Maybe$Nothing;
			} else {
				var user = _v3.a;
				return $elm$core$Maybe$Just(
					A4(
						$author$project$Elements$Table$createRow2,
						_List_fromArray(
							[
								$elm$core$Maybe$Just('175px'),
								$elm$core$Maybe$Just('350px'),
								$elm$core$Maybe$Just('350px')
							]),
						_List_Nil,
						_List_fromArray(
							[
								user.userFullName,
								user.userEmail,
								concatRoleNames(
								A2(
									$elm$core$List$intersperse,
									', ',
									createListOfRoleNames(user.roles)))
							]),
						$author$project$Pages$UserInfo$SelectUser(user)));
			}
		}();
		var userRows = A2(
			$elm$core$List$map,
			function (user) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('175px'),
							$elm$core$Maybe$Just('350px'),
							$elm$core$Maybe$Just('350px')
						]),
					_List_Nil,
					_List_fromArray(
						[
							user.userFullName,
							user.userEmail,
							concatRoleNames(
							A2(
								$elm$core$List$intersperse,
								', ',
								createListOfRoleNames(user.roles)))
						]),
					$author$project$Pages$UserInfo$SelectUser(user));
			},
			filteredUsers);
		var userTable = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, userHeader, userRows, selectedRow);
		var modeView = function () {
			var _v2 = $elm$core$List$length(users_);
			if (_v2 === 1) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-users'),
							A2($elm$html$Html$Attributes$style, 'padding-left', '20%'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '20%')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$UserInfo$renderUserInfoSidepanel, model, brand, lang)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-users')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('management-main')
								]),
							_List_fromArray(
								[userTable])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('settings-sidepanel')
								]),
							_List_fromArray(
								[
									A3($author$project$Pages$UserInfo$renderUserInfoSidepanel, model, brand, lang)
								])),
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$Debug$toString(userHeader))
								]))
						]));
			}
		}();
		return modeView;
	});
var $author$project$Pages$UserInfo$MobileToggleView = {$: 'MobileToggleView'};
var $author$project$Pages$UserInfo$viewMobile = F3(
	function (model, brand, lang) {
		var users_ = function () {
			var _v7 = model.users;
			if (_v7.$ === 'Success') {
				var u = _v7.a;
				return u;
			} else {
				return _List_Nil;
			}
		}();
		var userHeader = A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('100%')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserInfo$UpdateSort(1))
					])
				]),
			_List_fromArray(
				[lang.settings.table.users.headerEmail]),
			$author$project$Pages$UserInfo$NoOp);
		var textColorPrimary = A2(
			$elm$html$Html$Attributes$style,
			'color',
			$author$project$Common$Color$getColorContrast(brand.primary.rgb));
		var selectedRow = function () {
			var _v6 = model.selectedUser;
			if (_v6.$ === 'Nothing') {
				return $elm$core$Maybe$Nothing;
			} else {
				var user = _v6.a;
				return $elm$core$Maybe$Just(
					A4(
						$author$project$Elements$Table$createRow2,
						_List_fromArray(
							[
								$elm$core$Maybe$Just('100%')
							]),
						_List_Nil,
						_List_fromArray(
							[user.userEmail]),
						$author$project$Pages$UserInfo$SelectUser(user)));
			}
		}();
		var filteredUsers = function () {
			var _v4 = model.users;
			if (_v4.$ === 'Success') {
				var u = _v4.a;
				var _v5 = A2($elm$core$Dict$get, 'userinfo', model.searches);
				if (_v5.$ === 'Just') {
					var s = _v5.a;
					return A2(
						$elm$core$List$filter,
						function (x) {
							return A2(
								$elm$core$String$contains,
								$elm$core$String$toLower(s),
								$elm$core$String$toLower(x.userFullName));
						},
						u);
				} else {
					return u;
				}
			} else {
				return _List_Nil;
			}
		}();
		var userRows = A2(
			$elm$core$List$map,
			function (user) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('100%')
						]),
					_List_Nil,
					_List_fromArray(
						[user.userEmail]),
					$author$project$Pages$UserInfo$SelectUser(user));
			},
			filteredUsers);
		var userTable = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, userHeader, userRows, selectedRow);
		var modeView = function () {
			var _v3 = $elm$core$List$length(users_);
			if (_v3 === 1) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-users'),
							A2($elm$html$Html$Attributes$style, 'padding-left', '20%'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '20%')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$UserInfo$renderUserInfoSidepanel, model, brand, lang)
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-users')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('management-main')
								]),
							_List_fromArray(
								[userTable]))
						]));
			}
		}();
		var createListOfRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return A2(
					$elm$core$List$cons,
					x.roleName,
					createListOfRoleNames(xs));
			} else {
				return _List_Nil;
			}
		};
		var concatRoleNames = function (list) {
			if (list.b) {
				var x = list.a;
				var xs = list.b;
				return _Utils_ap(
					x,
					concatRoleNames(xs));
			} else {
				return '';
			}
		};
		var _v2 = model.mobileView;
		if (_v2.$ === 'Browse') {
			return modeView;
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('management-users')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('settings-sidepanel')
							]),
						_List_fromArray(
							[
								A3($author$project$Pages$UserInfo$renderUserInfoSidepanel, model, brand, lang)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('m-nav'),
								A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex),
								$elm$html$Html$Events$onClick($author$project$Pages$UserInfo$MobileToggleView)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('material-icons'),
										textColorPrimary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('arrow_back_ios')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[textColorPrimary]),
								_List_fromArray(
									[
										$elm$html$Html$text('Bläddra användare')
									]))
							]))
					]));
		}
	});
var $author$project$Pages$UserInfo$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$UserInfo$viewMobile, model, brand, lang) : A3($author$project$Pages$UserInfo$viewDesktop, model, brand, lang);
	});
var $author$project$Pages$UserManagement$DiscardAddedUsers = {$: 'DiscardAddedUsers'};
var $author$project$Pages$UserManagement$NoOp = {$: 'NoOp'};
var $author$project$Pages$UserManagement$UpdateSort = function (a) {
	return {$: 'UpdateSort', a: a};
};
var $author$project$Pages$UserManagement$AddUser = {$: 'AddUser'};
var $author$project$Pages$UserManagement$CsvRequested = {$: 'CsvRequested'};
var $author$project$Pages$UserManagement$UpdateUser = F2(
	function (a, b) {
		return {$: 'UpdateUser', a: a, b: b};
	});
var $author$project$Pages$UserManagement$renderNewUserRadioButtons = function (model) {
	var accType = function () {
		var _v0 = model.newUser;
		if (_v0.$ === 'Just') {
			var u = _v0.a;
			return u.userLoginType;
		} else {
			return '';
		}
	}();
	return A2($elm$html$Html$div, _List_Nil, _List_Nil);
};
var $author$project$Pages$UserManagement$renderNewUser = F3(
	function (model, brand, lang) {
		var workPhone = function () {
			var _v7 = model.newUser;
			if (_v7.$ === 'Just') {
				var u = _v7.a;
				return u.userWorkPhone;
			} else {
				return '';
			}
		}();
		var username = function () {
			var _v6 = model.newUser;
			if (_v6.$ === 'Just') {
				var u = _v6.a;
				return u.userFullName;
			} else {
				return '';
			}
		}();
		var mobilePhone = function () {
			var _v5 = model.newUser;
			if (_v5.$ === 'Just') {
				var u = _v5.a;
				return u.userMobilePhone;
			} else {
				return '';
			}
		}();
		var issuer = function () {
			var _v4 = model.newUser;
			if (_v4.$ === 'Just') {
				var u = _v4.a;
				return u.issuer;
			} else {
				return '';
			}
		}();
		var _default = function () {
			var s = $author$project$Elements$Core$default;
			return _Utils_update(
				s,
				{color: brand.primary.hex, height: '', margin: 'auto auto 7px auto', width: '100%'});
		}();
		var customerDomain = function () {
			var _v3 = model.customer;
			if (_v3.$ === 'Just') {
				var customer = _v3.a;
				return customer.customerDomain;
			} else {
				return '';
			}
		}();
		var email = function () {
			var _v1 = model.newUser;
			if (_v1.$ === 'Just') {
				var u = _v1.a;
				var _v2 = u.userEmail === '';
				if (_v2) {
					return '@' + customerDomain;
				} else {
					return u.userEmail;
				}
			} else {
				return customerDomain;
			}
		}();
		var buttonState = function () {
			var _v0 = model.newUser;
			if (_v0.$ === 'Just') {
				var u = _v0.a;
				return $author$project$Common$Format$verifyUser(u);
			} else {
				return false;
			}
		}();
		var buttons = A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A5($author$project$Elements$Core$renderButton, brand.primary, lang.settings.sidepanel.userAdd, buttonState, $author$project$Pages$UserManagement$AddUser, _List_Nil)
				]),
			model.session.isMobile ? _List_Nil : _List_fromArray(
				[
					A5($author$project$Elements$Core$renderButton, brand.primary, lang.settings.upload, true, $author$project$Pages$UserManagement$CsvRequested, _List_Nil)
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-content')
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserManagement$UpdateUser(0))
							}),
						'username',
						lang.settings.sidepanel.userName,
						$elm$core$Maybe$Just('account_circle'),
						username),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserManagement$UpdateUser(1))
							}),
						'useremail',
						lang.settings.sidepanel.userEmail,
						$elm$core$Maybe$Just('email'),
						email),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserManagement$UpdateUser(2))
							}),
						'workphone',
						lang.settings.sidepanel.userWorkPhone,
						$elm$core$Maybe$Just('contact_phone'),
						workPhone),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserManagement$UpdateUser(3))
							}),
						'mobilephone',
						lang.settings.sidepanel.userMobilePhone,
						$elm$core$Maybe$Just('contact_phone'),
						mobilePhone),
						A5(
						$author$project$Elements$Core$textbox,
						_Utils_update(
							_default,
							{
								input: $elm$core$Maybe$Just(
									$author$project$Pages$UserManagement$UpdateUser(5))
							}),
						'issuer',
						'Issuer',
						$elm$core$Maybe$Nothing,
						issuer),
						$author$project$Pages$UserManagement$renderNewUserRadioButtons(model)
					]),
				buttons));
	});
var $author$project$Pages$UserManagement$viewDesktop = F3(
	function (model, brand, lang) {
		var users = function () {
			var _v1 = model.users;
			if (_v1.$ === 'Success') {
				var u = _v1.a;
				return A2($elm$core$List$append, u, model.newUsers);
			} else {
				return _List_Nil;
			}
		}();
		var userRows = A2(
			$elm$core$List$map,
			function (user) {
				return A4(
					$author$project$Elements$Table$createRow2,
					_List_fromArray(
						[
							$elm$core$Maybe$Just('175px'),
							$elm$core$Maybe$Just('350px'),
							$elm$core$Maybe$Just('350px')
						]),
					_List_Nil,
					_List_fromArray(
						[
							user.userFullName,
							user.userEmail,
							$author$project$Common$Helpers$translateAccountType(user.userLoginType)
						]),
					$author$project$Pages$UserManagement$NoOp);
			},
			users);
		var userHeader = A4(
			$author$project$Elements$Table$createRow2,
			_List_fromArray(
				[
					$elm$core$Maybe$Just('175px'),
					$elm$core$Maybe$Just('350px'),
					$elm$core$Maybe$Just('350px')
				]),
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserManagement$UpdateSort(0))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserManagement$UpdateSort(1))
					]),
					_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Pages$UserManagement$UpdateSort(2))
					])
				]),
			_List_fromArray(
				[lang.settings.table.users.headerName, lang.settings.table.users.headerEmail, 'Login Type']),
			$author$project$Pages$UserManagement$NoOp);
		var userTable = A5($author$project$Elements$Table$renderTable, model.tableConfig, brand, userHeader, userRows, $elm$core$Maybe$Nothing);
		var discardState = function () {
			var _v0 = model.newUsers;
			if (!_v0.b) {
				return false;
			} else {
				return true;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('management-users')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('management-main')
						]),
					_List_fromArray(
						[
							userTable,
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn-options')
								]),
							_List_fromArray(
								[
									A5($author$project$Elements$Core$renderButton, brand.primary, lang.settings.save, discardState, $author$project$Pages$UserManagement$RequestSaveUsers, _List_Nil),
									A5($author$project$Elements$Core$renderButton, brand.primary, lang.settings.discard, discardState, $author$project$Pages$UserManagement$DiscardAddedUsers, _List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('settings-sidepanel')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$UserManagement$renderNewUser, model, brand, lang),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('error-message'),
									model.userVerification.userExists ? A2($elm$html$Html$Attributes$style, 'display', 'block') : A2($elm$html$Html$Attributes$style, 'display', 'none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(lang.error.userExists)
								]))
						]))
				]));
	});
var $author$project$Pages$UserManagement$viewMobile = F3(
	function (model, brand, lang) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('settings-sidepanel')
				]),
			_List_fromArray(
				[
					A3($author$project$Pages$UserManagement$renderNewUser, model, brand, lang),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error-message'),
							model.userVerification.userExists ? A2($elm$html$Html$Attributes$style, 'display', 'block') : A2($elm$html$Html$Attributes$style, 'display', 'none')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(lang.error.userExists)
						]))
				]));
	});
var $author$project$Pages$UserManagement$view = F3(
	function (model, brand, lang) {
		return model.session.isMobile ? A3($author$project$Pages$UserManagement$viewMobile, model, brand, lang) : A3($author$project$Pages$UserManagement$viewDesktop, model, brand, lang);
	});
var $author$project$Main$routeToView = F2(
	function (model, brand) {
		var services = function () {
			var _v1 = model.drawer.customerService;
			if (_v1.$ === 'Success') {
				var cs = _v1.a;
				return cs.services;
			} else {
				return _List_Nil;
			}
		}();
		var applications = $author$project$Main$getAllServiceApplications(services);
		var tabs = $author$project$Main$getAllApplicationTabs(applications);
		var page = A2($author$project$Pages$Pages$isUrlAllowed, model.url.path, tabs) ? model.page : $author$project$Pages$Pages$NotFound;
		_v0$14:
		while (true) {
			switch (page.$) {
				case 'Home':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$HomeMsg,
						A2($author$project$Pages$Home$view, model.homepage, brand));
				case 'HomeEdit':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$HomeEditMsg,
						A2($author$project$Pages$HomeEdit$view, model.homeeditpage, brand));
				case 'Roles':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$RoleMsg,
						A3($author$project$Pages$RoleManagement$view, model.rolepage, brand, model.language));
				case 'UserManagement':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$UserMsg,
						A3($author$project$Pages$UserManagement$view, model.userpage, brand, model.language));
				case 'UserInfo':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$UserInfoMsg,
						A3($author$project$Pages$UserInfo$view, model.userinfopage, brand, model.language));
				case 'Branding':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$BrandingMsg,
						A3($author$project$Pages$Branding$view, model.brandingpage, brand, model.language));
				case 'Reaact':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$PowerBIMsg,
						A2($author$project$Pages$PowerBI$view, model.powerbipage, brand));
				case 'PlannicaGrid':
					if ((page.a.$ === 'Just') && (page.b.$ === 'Just')) {
						var tabKey = page.a.a;
						var customerKey = page.b.a;
						var filters = page.c;
						return A3($author$project$Pages$PlannicaGrid$view, customerKey, tabKey, filters);
					} else {
						break _v0$14;
					}
				case 'ReportSettings':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$ReaactMsg,
						A3($author$project$Pages$ReportSettings$view, model.reaactpage, brand, model.language));
				case 'CustomerSubscription':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$SubsMsg,
						A2($author$project$Pages$Subscriptions$view, model.subspage, brand));
				case 'ApplicationRegistration':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$AppRegMsg,
						A2($author$project$Pages$AppRegistration$view, model.appregpage, brand));
				case 'Customers':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$CustomersMsg,
						A2($author$project$Pages$Customers$view, model.customerspage, brand));
				case 'RolePermissions':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$RPMsg,
						A3($author$project$Pages$RolePermissions$view, model.rppage, brand, model.language));
				case 'Document':
					return A2(
						$elm$html$Html$map,
						$author$project$Messages$DocMsg,
						A3($author$project$Pages$Document$view, model.docpage, brand, model.language));
				default:
					break _v0$14;
			}
		}
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'padding', '0 20px')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Sidan kunde inte hittas')
						]))
				]));
	});
var $author$project$Drawer$RequestCustomerService = function (a) {
	return {$: 'RequestCustomerService', a: a};
};
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $author$project$Drawer$renderDropdownItem = F2(
	function (selectedCustomer, customer) {
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(customer.customerKey),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(customer, selectedCustomer))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(customer.customerName)
				]));
	});
var $author$project$Drawer$renderCustomerDropdown = F4(
	function (model, brand, selected, clickEvent) {
		var customers = function () {
			var _v0 = model.customers;
			if (_v0.$ === 'Success') {
				var c = _v0.a;
				return c;
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$select,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-dropdown'),
					$elm$html$Html$Events$onInput(clickEvent),
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.primary.rgb) : '#000')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Drawer$renderDropdownItem(selected),
				customers));
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Common$Helpers$chooseIconColorPath = F2(
	function (rgb, imageUrl) {
		var splitList = A2($elm$core$String$split, '.', imageUrl);
		var colorContrast = $author$project$Common$Color$getColorContrast(rgb);
		var isWhite = colorContrast === '#FFF';
		if (isWhite) {
			var _v0 = $elm$core$List$reverse(splitList);
			if (_v0.b) {
				var fileExtension = _v0.a;
				var tail = _v0.b;
				return A2(
					$elm$core$String$join,
					'.',
					$elm$core$List$reverse(tail)) + ('_white.' + fileExtension);
			} else {
				return imageUrl;
			}
		} else {
			return imageUrl;
		}
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Pages$Pages$query = function (list) {
	var parts = $elm$core$Dict$values(
		A2(
			$elm$core$Dict$map,
			function (k) {
				return function (v) {
					return k + ('=' + v);
				};
			},
			$elm$core$Dict$fromList(list)));
	var queryString = $elm$core$String$concat(
		A2($elm$core$List$intersperse, '&', parts));
	return '?' + queryString;
};
var $author$project$Drawer$renderApplication = F3(
	function (model, brand, application) {
		var tooltipFlow = A2($elm$html$Html$Attributes$attribute, 'flow', 'right');
		var tooltip = (model.open || model.isMobile) ? A2($elm$html$Html$Attributes$attribute, 'tooltip', '') : A2($elm$html$Html$Attributes$attribute, 'tooltip', application.applicationName);
		var isSelected = function () {
			var _v10 = model.selectedApplication;
			if (_v10.$ === 'Nothing') {
				return false;
			} else {
				var a = _v10.a;
				return _Utils_eq(a, application);
			}
		}();
		var iconPath = A2($elm$core$String$split, '.', application.applicationTileBackgroundImageUrl);
		var iconImageUrl = brand.useColorInHeaderAndNav ? A2($author$project$Common$Helpers$chooseIconColorPath, brand.primary.rgb, application.applicationTileBackgroundImageUrl) : application.applicationTileBackgroundImageUrl;
		var icon = function () {
			var _v7 = _Utils_Tuple2(
				$elm$core$List$length(iconPath) > 1,
				$elm$core$List$reverse(iconPath));
			if (_v7.a && _v7.b.b) {
				var _v8 = _v7.b;
				var x = _v8.a;
				var xs = _v8.b;
				switch (x) {
					case 'png':
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(iconImageUrl)
								]),
							_List_Nil);
					case 'svg':
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(iconImageUrl)
								]),
							_List_Nil);
					default:
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('/images/not_found.png')
								]),
							_List_Nil);
				}
			} else {
				return A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(application.applicationTileBackgroundImageUrl)
						]));
			}
		}();
		var customerKey = function () {
			var _v6 = model.selectedCustomer;
			if (_v6.$ === 'Just') {
				var customer = _v6.a;
				return customer.customerKey;
			} else {
				return '';
			}
		}();
		var pathToFirstTab = function () {
			var _v4 = application.tabs;
			if (_v4.b) {
				var t = _v4.a;
				var _v5 = t.tabPath;
				switch (_v5) {
					case '/report':
						return _Utils_ap(
							t.tabPath,
							$author$project$Pages$Pages$query(
								_List_fromArray(
									[
										_Utils_Tuple2('tk', t.tabKey),
										_Utils_Tuple2('ck', customerKey)
									])));
					case '/grid':
						return _Utils_ap(
							t.tabPath,
							$author$project$Pages$Pages$query(
								_List_fromArray(
									[
										_Utils_Tuple2('tk', t.tabKey),
										_Utils_Tuple2('ck', customerKey)
									])));
					case '/documents':
						return _Utils_ap(
							t.tabPath,
							$author$project$Pages$Pages$query(
								_List_fromArray(
									[
										_Utils_Tuple2('tk', t.tabKey)
									])));
					default:
						return t.tabPath;
				}
			} else {
				return '';
			}
		}();
		var closedIcon = function () {
			var _v1 = _Utils_Tuple2(
				$elm$core$List$length(iconPath) > 1,
				$elm$core$List$reverse(iconPath));
			if (_v1.a && _v1.b.b) {
				var _v2 = _v1.b;
				var x = _v2.a;
				var xs = _v2.b;
				switch (x) {
					case 'png':
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('alternative'),
									$elm$html$Html$Attributes$src(iconImageUrl)
								]),
							_List_Nil);
					case 'svg':
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('alternative'),
									$elm$html$Html$Attributes$src(iconImageUrl)
								]),
							_List_Nil);
					default:
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('alternative'),
									$elm$html$Html$Attributes$src('/images/not_found.png')
								]),
							_List_Nil);
				}
			} else {
				return A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('material-icons alternative')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(application.applicationTileBackgroundImageUrl)
						]));
			}
		}();
		var backgroundColor = function () {
			if (isSelected && brand.useColorInHeaderAndNav) {
				var _v0 = A2($author$project$Common$Color$convertColorWithOpacityToColor, brand.primary, 0.5);
				var color = _v0;
				return color.hex;
			} else {
				if (isSelected && (!brand.useColorInHeaderAndNav)) {
					return '#e3e3e3';
				} else {
					return 'inherit';
				}
			}
		}();
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('application-item', true),
							_Utils_Tuple2('selected', isSelected)
						])),
					A2(
					$elm$html$Html$Attributes$style,
					'color',
					brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.primary.rgb) : '#000'),
					A2($elm$html$Html$Attributes$style, 'background-color', backgroundColor),
					tooltip,
					tooltipFlow
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(pathToFirstTab)
						]),
					_List_fromArray(
						[
							icon,
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(application.applicationName)
								]))
						]))
				]));
	});
var $author$project$Drawer$renderApplications = F3(
	function (model, brand, service) {
		var applicationItems = A2(
			$elm$core$List$map,
			A2($author$project$Drawer$renderApplication, model, brand),
			service.applications);
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-applications')
				]),
			applicationItems);
	});
var $author$project$Drawer$renderService = F3(
	function (model, brand, service) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('service-item'),
					brand.useColorInHeaderAndNav ? A2(
					$elm$html$Html$Attributes$style,
					'border-bottom',
					'1px solid ' + $author$project$Common$Color$getColorContrast(brand.primary.rgb)) : A2($elm$html$Html$Attributes$style, 'border-bottom', '1px solid #e3e3e3')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('service-name'),
							A2(
							$elm$html$Html$Attributes$style,
							'color',
							brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.primary.rgb) : '#000')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(service.serviceName)
						])),
					A2($author$project$Drawer$renderApplications, model, brand)(service)
				]));
	});
var $author$project$Drawer$renderServices = F2(
	function (model, brand) {
		var serviceItems = function () {
			var _v0 = model.customerService;
			if (_v0.$ === 'Success') {
				var cs = _v0.a;
				return A2(
					$elm$core$List$map,
					A2($author$project$Drawer$renderService, model, brand),
					cs.services);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-services')
				]),
			serviceItems);
	});
var $author$project$Drawer$view = F2(
	function (model, brand) {
		var selected = function () {
			var _v0 = model.selectedCustomer;
			if (_v0.$ === 'Just') {
				var c = _v0.a;
				return c;
			} else {
				return A4($author$project$Common$Types$Customer, '', '', '', '');
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					model.open ? $elm$html$Html$Attributes$class('drawer open noprint') : $elm$html$Html$Attributes$class('drawer closed noprint'),
					model.open ? A2($elm$html$Html$Attributes$style, 'width', '190px') : A2($elm$html$Html$Attributes$style, 'width', '42px'),
					brand.useColorInHeaderAndNav ? A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex) : A2($elm$html$Html$Attributes$style, 'background-color', '#FFF'),
					brand.useColorInHeaderAndNav ? A2($elm$html$Html$Attributes$style, 'box-shadow', '2px 2px 2px 0 ' + brand.primary.hex) : A2($elm$html$Html$Attributes$style, 'box-shadow', '2px 2px 2px 0 grey'),
					A2($elm$html$Html$Attributes$style, 'overflow-y', 'auto')
				]),
			_List_fromArray(
				[
					A4($author$project$Drawer$renderCustomerDropdown, model, brand, selected, $author$project$Drawer$RequestCustomerService),
					A2(
					$elm$html$Html$hr,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '4px'),
							brand.useColorInHeaderAndNav ? A2(
							$elm$html$Html$Attributes$style,
							'border-top',
							'1px solid ' + $author$project$Common$Color$getColorContrast(brand.primary.rgb)) : A2($elm$html$Html$Attributes$style, 'border-top', '1px solid #ccc')
						]),
					_List_Nil),
					A2($author$project$Drawer$renderServices, model, brand)
				]));
	});
var $author$project$Header$ToggleDrawer = {$: 'ToggleDrawer'};
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Header$Logout = {$: 'Logout'};
var $author$project$Header$ToggleAccount = {$: 'ToggleAccount'};
var $author$project$Header$mobileLogoutButton = function (brand) {
	var buttonColor = brand.useColorInHeaderAndNav ? brand.primary.hex : '#FFF';
	var _v0 = A2($author$project$Common$Color$hexToColor, buttonColor, $author$project$Common$Color$empty);
	if (_v0.$ === 'Just') {
		var color = _v0.a;
		return A5($author$project$Elements$Core$iconButton, color, true, 'logout', '', $author$project$Header$Logout);
	} else {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Header$renderAccountMenu = F2(
	function (model, brand) {
		var textColor = brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.primary.rgb) : '#000';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('account')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('account-toggle'),
							$elm$html$Html$Events$onClick($author$project$Header$ToggleAccount)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('account_circle')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($elm$core$Maybe$withDefault, '', model.username))
								]))
						])),
					model.flags.isMobile ? $author$project$Header$mobileLogoutButton(brand) : A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'margin', '0'),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'list-style', 'none')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('account-content'),
									$elm$html$Html$Events$onClick($author$project$Header$Logout),
									model.accountOpen ? A2($elm$html$Html$Attributes$style, 'display', 'block') : A2($elm$html$Html$Attributes$style, 'display', 'none'),
									A2($elm$html$Html$Attributes$style, 'color', '#000')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Logga ut')
								]))
						]))
				]));
	});
var $author$project$Header$view = F2(
	function (model, brand) {
		var textColor = brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.primary.rgb) : '#000';
		var logoStyle = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '35px'),
				A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
				A2($elm$html$Html$Attributes$style, 'margin-top', '5px'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative'),
				A2($elm$html$Html$Attributes$style, 'vertical-align', 'unset')
			]);
		var logo = ($elm$core$String$isEmpty(brand.image) || (!brand.showLogoInHeader)) ? A2($elm$html$Html$img, logoStyle, _List_Nil) : A2(
			$elm$html$Html$img,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$src(brand.image),
				logoStyle),
			_List_Nil);
		var headerStyles = brand.useColorInHeaderAndNav ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex)
			]) : _List_Nil;
		return A2(
			$elm$html$Html$header,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('app-header')
					]),
				headerStyles),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('app-menu'),
							$elm$html$Html$Events$onClick($author$project$Header$ToggleDrawer)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('more_horiz')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('header-brand')
						]),
					_List_fromArray(
						[
							logo,
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('app-title'),
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									brand.showTitleInHeader ? brand.title : '')
								]))
						])),
					A2($author$project$Header$renderAccountMenu, model, brand)
				]));
	});
var $author$project$Navbar$PrintReport = {$: 'PrintReport'};
var $author$project$Navbar$ToggleReportFullscreen = {$: 'ToggleReportFullscreen'};
var $author$project$Common$Brand$getColorContrast = function (rgb) {
	return ((((rgb.red * 0.299) + (rgb.green * 0.587)) + (rgb.blue * 0.114)) > 186) ? '#000' : '#FFF';
};
var $author$project$Navbar$renderReportButtons = F2(
	function (model, brand) {
		var textColor = $author$project$Common$Brand$getColorContrast(brand.secondary.rgb);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('report-options'),
					A2($elm$html$Html$Attributes$style, 'display', 'inline')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('option'),
							$elm$html$Html$Events$onClick($author$project$Navbar$ToggleReportFullscreen),
							A2($elm$html$Html$Attributes$style, 'background-color', brand.secondary.hex)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('fullscreen')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('option'),
							$elm$html$Html$Events$onClick($author$project$Navbar$PrintReport),
							A2($elm$html$Html$Attributes$style, 'background-color', brand.secondary.hex)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons'),
									A2($elm$html$Html$Attributes$style, 'color', textColor)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('print')
								]))
						]))
				]));
	});
var $author$project$Navbar$renderTab = F3(
	function (model, brand, tab) {
		var textColorSelected = brand.useColorInHeaderAndNav ? $author$project$Common$Color$getColorContrast(brand.secondary.rgb) : '#000';
		var textColor = $author$project$Common$Brand$getColorContrast(brand.primary.rgb);
		var pageUrl = function () {
			var _v2 = tab.tabPath;
			switch (_v2) {
				case '/report':
					return _Utils_ap(
						tab.tabPath,
						$author$project$Pages$Pages$query(
							_List_fromArray(
								[
									_Utils_Tuple2('tk', tab.tabKey),
									_Utils_Tuple2('ck', model.customerKey)
								])));
				case '/grid':
					return _Utils_ap(
						tab.tabPath,
						$author$project$Pages$Pages$query(
							_List_fromArray(
								[
									_Utils_Tuple2('tk', tab.tabKey),
									_Utils_Tuple2('ck', model.customerKey)
								])));
				case '/documents':
					return _Utils_ap(
						tab.tabPath,
						$author$project$Pages$Pages$query(
							_List_fromArray(
								[
									_Utils_Tuple2('tk', tab.tabKey)
								])));
				default:
					return tab.tabPath;
			}
		}();
		var _v0 = function () {
			var _v1 = model.selectedTab;
			if (_v1.$ === 'Just') {
				var t = _v1.a;
				return (_Utils_eq(tab, t) && brand.useColorInHeaderAndNav) ? _Utils_Tuple2('selected', brand.secondary.hex) : ((_Utils_eq(tab, t) && (!brand.useColorInHeaderAndNav)) ? _Utils_Tuple2('selected', '#FFF') : _Utils_Tuple2('', 'transparent'));
			} else {
				return _Utils_Tuple2('', 'transparent');
			}
		}();
		var class_ = _v0.a;
		var backgroundColor = _v0.b;
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(class_),
					A2($elm$html$Html$Attributes$style, 'background-color', backgroundColor)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							(class_ === 'selected') ? A2($elm$html$Html$Attributes$style, 'color', textColorSelected) : A2($elm$html$Html$Attributes$style, 'color', textColor),
							$elm$html$Html$Attributes$href(pageUrl)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(tab.tabName)
						]))
				]));
	});
var $author$project$Navbar$renderTabs = F2(
	function (model, brand) {
		var tabs = A2(
			$elm$core$List$map,
			A2($author$project$Navbar$renderTab, model, brand),
			model.tabs);
		return A2($elm$html$Html$ul, _List_Nil, tabs);
	});
var $author$project$Navbar$view = F3(
	function (model, brand, page) {
		var extraButtons = function () {
			if (page.$ === 'Reaact') {
				return A2($author$project$Navbar$renderReportButtons, model, brand);
			} else {
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			}
		}();
		var display = function () {
			var _v0 = model.tabs;
			if (!_v0.b) {
				return 'block';
			} else {
				return 'block';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-tabs'),
					A2($elm$html$Html$Attributes$style, 'display', display),
					A2($elm$html$Html$Attributes$style, 'background-color', brand.primary.hex)
				]),
			_List_fromArray(
				[
					A2($author$project$Navbar$renderTabs, model, brand),
					extraButtons
				]));
	});
var $author$project$Pages$Loading$view = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loading-screen')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$object,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'data', '/svg/mimica_loading_animation.svg'),
					$elm$html$Html$Attributes$type_('image/svg+xml'),
					A2($elm$html$Html$Attributes$style, 'width', '30%')
				]),
			_List_Nil)
		]));
var $author$project$Pages$Login$LoginMsal = {$: 'LoginMsal'};
var $author$project$Pages$Login$LogoutMsal = {$: 'LogoutMsal'};
var $author$project$Pages$Login$loginFooter = function (footer) {
	return A2(
		$elm$html$Html$p,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(footer.text_)
					])),
				$elm$html$Html$text(' ' + footer.value)
			]));
};
var $author$project$Pages$Login$renderLoginWithMicrosoft = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src('svg/ms-symbollockup_signin_light.svg'),
			$elm$html$Html$Events$onClick($author$project$Pages$Login$LoginMsal),
			$elm$html$Html$Attributes$class('login-with-microsoft')
		]),
	_List_Nil);
var $author$project$Pages$Login$renderLogoutButton = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('btn-logout'),
			$elm$html$Html$Events$onClick($author$project$Pages$Login$LogoutMsal)
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$i,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('material-icons')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('exit_to_app')
				])),
			$elm$html$Html$text('Logga ut')
		]));
var $author$project$Pages$Login$view = F3(
	function (model, message, showLogoutButton) {
		var messageObject = function () {
			if (message.$ === 'Just') {
				var m = message.a;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('message')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(m)
							]))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var logoutButton = showLogoutButton ? _List_fromArray(
			[$author$project$Pages$Login$renderLogoutButton]) : _List_Nil;
		var loginParameters = model.flags.loginParameters;
		var loginCustomContent = function () {
			var pageGridResetPassword = $elm$core$String$isEmpty(loginParameters.resetPassword.userFlow) ? _List_Nil : _List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('custom-login-btn'),
							$elm$html$Html$Attributes$href(loginParameters.resetPassword.userFlow)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('material-icons')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('lock_reset')
								])),
							$elm$html$Html$text(loginParameters.resetPassword.text_)
						]))
				]);
			var pageGridFooter = function () {
				var _v1 = loginParameters.footer;
				if (!_v1.b) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pageGridFooter')
							]),
						_List_Nil);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('pageGridFooter')
							]),
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$hr,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'width', '30%'),
											A2($elm$html$Html$Attributes$style, 'margin', '0 auto'),
											A2($elm$html$Html$Attributes$style, 'margin-bottom', '20px')
										]),
									_List_Nil)
								]),
							A2($elm$core$List$map, $author$project$Pages$Login$loginFooter, loginParameters.footer)));
				}
			}();
			var pageGridContent = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pageGridContent')
					]),
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(loginParameters.loginText)
								])),
							_Utils_eq(message, $elm$core$Maybe$Nothing) ? A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('custom-login-btn'),
									$elm$html$Html$Events$onClick($author$project$Pages$Login$LoginMsal)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('lock_open')
										])),
									$elm$html$Html$text(loginParameters.loginButtonText)
								])) : A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('custom-login-btn'),
									$elm$html$Html$Events$onClick($author$project$Pages$Login$LogoutMsal)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('material-icons')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('lock')
										])),
									$elm$html$Html$text('Logga ut')
								]))
						]),
					A2($elm$core$List$append, pageGridResetPassword, messageObject)));
			var logo = A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(loginParameters.logoUrl),
						A2($elm$html$Html$Attributes$style, 'height', '100px'),
						(loginParameters.logoPosition === 'center') ? A2($elm$html$Html$Attributes$style, '', '') : ((loginParameters.logoPosition === 'right') ? A2($elm$html$Html$Attributes$style, 'right', '20px') : A2($elm$html$Html$Attributes$style, 'left', '20px'))
					]),
				_List_Nil);
			var pageGridHeader = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pageGridHeader')
					]),
				_List_fromArray(
					[logo]));
			var pageGrid = A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('login-grid')
					]),
				_List_fromArray(
					[pageGridHeader, pageGridContent, pageGridFooter]));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('b2c-login'),
						A2($elm$html$Html$Attributes$style, 'background-image', 'url(\"' + (loginParameters.loginImageUrl + '\")'))
					]),
				_List_fromArray(
					[pageGrid]));
		}();
		var _v0 = $elm$core$String$toLower(loginParameters.type_);
		if (_v0 === 'b2c') {
			return loginCustomContent;
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('login-page')
					]),
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('images/bv_logo.png'),
									$elm$html$Html$Attributes$class('login-logo')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$object,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data', '/svg/start_mimica_long_v2.svg'),
									$elm$html$Html$Attributes$type_('image/svg+xml'),
									$elm$html$Html$Attributes$class('login-animation')
								]),
							_List_Nil),
							$author$project$Pages$Login$renderLoginWithMicrosoft
						]),
					A2($elm$core$List$append, logoutButton, messageObject)));
		}
	});
var $author$project$Main$viewToggled = F4(
	function (model, useMargin, class_, content) {
		var leftMargin = useMargin ? (model.drawer.open ? '190px' : '42px') : '0';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(class_),
					A2($elm$html$Html$Attributes$style, 'margin-left', leftMargin),
					A2($elm$html$Html$Attributes$style, 'transition', '0.2s')
				]),
			_List_fromArray(
				[content]));
	});
var $author$project$Main$view = function (model) {
	var toggleable = function () {
		var _v4 = model.embedView;
		switch (_v4.$) {
			case 'None':
				return true;
			case 'Drawer':
				return true;
			case 'DrawerNavbar':
				return true;
			case 'HeaderDrawer':
				return true;
			default:
				return false;
		}
	}();
	var loginViewShowLogoutButton = function () {
		var _v3 = model.authContext;
		if (_v3.$ === 'Success') {
			return true;
		} else {
			return false;
		}
	}();
	var loginView = function (msg) {
		return A2(
			$elm$html$Html$map,
			$author$project$Messages$LoginMsg,
			A3($author$project$Pages$Login$view, model.loginpage, msg, loginViewShowLogoutButton));
	};
	var brand = function () {
		var _v2 = model.brand;
		if (_v2.$ === 'Success') {
			var b = _v2.a;
			return _Utils_update(
				b,
				{
					image: A2($author$project$Common$Helpers$createLogoUrl, model.flags.cfgLogoContainer, model.navbar.customerKey)
				});
		} else {
			return $author$project$Common$Brand$init;
		}
	}();
	var drawer = A2(
		$elm$html$Html$map,
		$author$project$Messages$DrawerMsg,
		A2($author$project$Drawer$view, model.drawer, brand));
	var header = A2(
		$elm$html$Html$map,
		$author$project$Messages$HeaderMsg,
		A2($author$project$Header$view, model.header, brand));
	var navbar = A4(
		$author$project$Main$viewToggled,
		model,
		toggleable,
		'top-navbar',
		A2(
			$elm$html$Html$map,
			$author$project$Messages$NavbarMsg,
			A3($author$project$Navbar$view, model.navbar, brand, model.page)));
	var embedViewParts = function () {
		var _v1 = model.embedView;
		switch (_v1.$) {
			case 'Simple':
				return _List_Nil;
			case 'Header':
				return _List_fromArray(
					[header]);
			case 'Navbar':
				return _List_fromArray(
					[navbar]);
			case 'Drawer':
				return _List_fromArray(
					[drawer]);
			case 'HeaderNavbar':
				return _List_fromArray(
					[header, navbar]);
			case 'HeaderDrawer':
				return _List_fromArray(
					[header, drawer]);
			case 'DrawerNavbar':
				return _List_fromArray(
					[drawer, navbar]);
			default:
				return _List_fromArray(
					[header, drawer, navbar]);
		}
	}();
	var pageView = A4(
		$author$project$Main$viewToggled,
		model,
		toggleable,
		$author$project$Common$Helpers$mapEmbedViewClassName(model.embedView),
		A2($author$project$Main$routeToView, model, brand));
	var activeView = function () {
		if ($author$project$Main$checkIfLoading(model)) {
			return _List_fromArray(
				[$author$project$Pages$Loading$view]);
		} else {
			var _v0 = _Utils_Tuple2(model.authContext, model.userLogin);
			_v0$2:
			while (true) {
				switch (_v0.b.$) {
					case 'Success':
						if (_v0.a.$ === 'Success') {
							var ul = _v0.b.a;
							return $elm$core$String$isEmpty(ul.userKey) ? _List_fromArray(
								[
									loginView(
									$elm$core$Maybe$Just(
										A2(
											$elm$core$Maybe$withDefault,
											'',
											$elm$core$List$head(ul.errors))))
								]) : A2(
								$elm$core$List$append,
								embedViewParts,
								_List_fromArray(
									[pageView]));
						} else {
							break _v0$2;
						}
					case 'Failed':
						var err = _v0.b.a;
						return _List_fromArray(
							[
								loginView(
								$elm$core$Maybe$Just(
									$elm$core$Debug$toString(err)))
							]);
					default:
						break _v0$2;
				}
			}
			return _List_fromArray(
				[
					loginView($elm$core$Maybe$Nothing)
				]);
		}
	}();
	return {
		body: activeView,
		title: $elm$core$String$isEmpty(brand.title) ? model.flags.loginParameters.title : brand.title
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{init: $author$project$Main$init, onUrlChange: $author$project$Messages$UrlChanged, onUrlRequest: $author$project$Messages$LinkClicked, subscriptions: $author$project$Main$subscriptions, update: $author$project$Main$update, view: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (selectedCustomer) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (loginParameters) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (isMobile) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (cfgLogoContainer) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (cfgLoginType) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (cfgDebugMode) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (cfgBaseUrl) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (cfgAuthUrl) {
																	return $elm$json$Json$Decode$succeed(
																		{cfgAuthUrl: cfgAuthUrl, cfgBaseUrl: cfgBaseUrl, cfgDebugMode: cfgDebugMode, cfgLoginType: cfgLoginType, cfgLogoContainer: cfgLogoContainer, isMobile: isMobile, loginParameters: loginParameters, selectedCustomer: selectedCustomer});
																},
																A2($elm$json$Json$Decode$field, 'cfgAuthUrl', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'cfgBaseUrl', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'cfgDebugMode', $elm$json$Json$Decode$int));
										},
										A2($elm$json$Json$Decode$field, 'cfgLoginType', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'cfgLogoContainer', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'isMobile', $elm$json$Json$Decode$bool));
				},
				A2(
					$elm$json$Json$Decode$field,
					'loginParameters',
					A2(
						$elm$json$Json$Decode$andThen,
						function (type_) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (title) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (resetPassword) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (logoUrl) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (logoPosition) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (loginText) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (loginImageUrl) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (loginButtonText) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (footer) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (faviconUrl) {
																									return $elm$json$Json$Decode$succeed(
																										{faviconUrl: faviconUrl, footer: footer, loginButtonText: loginButtonText, loginImageUrl: loginImageUrl, loginText: loginText, logoPosition: logoPosition, logoUrl: logoUrl, resetPassword: resetPassword, title: title, type_: type_});
																								},
																								A2($elm$json$Json$Decode$field, 'faviconUrl', $elm$json$Json$Decode$string));
																						},
																						A2(
																							$elm$json$Json$Decode$field,
																							'footer',
																							$elm$json$Json$Decode$list(
																								A2(
																									$elm$json$Json$Decode$andThen,
																									function (value) {
																										return A2(
																											$elm$json$Json$Decode$andThen,
																											function (text_) {
																												return $elm$json$Json$Decode$succeed(
																													{text_: text_, value: value});
																											},
																											A2($elm$json$Json$Decode$field, 'text_', $elm$json$Json$Decode$string));
																									},
																									A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string)))));
																				},
																				A2($elm$json$Json$Decode$field, 'loginButtonText', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'loginImageUrl', $elm$json$Json$Decode$string));
																},
																A2($elm$json$Json$Decode$field, 'loginText', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'logoPosition', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'logoUrl', $elm$json$Json$Decode$string));
										},
										A2(
											$elm$json$Json$Decode$field,
											'resetPassword',
											A2(
												$elm$json$Json$Decode$andThen,
												function (userFlow) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (text_) {
															return $elm$json$Json$Decode$succeed(
																{text_: text_, userFlow: userFlow});
														},
														A2($elm$json$Json$Decode$field, 'text_', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'userFlow', $elm$json$Json$Decode$string))));
								},
								A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'type_', $elm$json$Json$Decode$string))));
		},
		A2($elm$json$Json$Decode$field, 'selectedCustomer', $elm$json$Json$Decode$string)))(0)}});}(this));