import Vue from 'vue/dist/vue.js';

import vuexStore from './pgridVuexStore.js'
// import OfficeUIFabricVue from 'office-ui-fabric-vue';

// import 'office-ui-fabric-vue/dist/index.css';
// import '../../styles/pgrid/pgrid.scss'

// Vue.use(OfficeUIFabricVue);

export async function PGridStart(config = null) {
    var self = this;
    //Enable Vue DevTools
    Vue.config.devtools = false; //config.general.clientDebugMode;

    await vuexStore.dispatch('Action_DeclareWindowPGridVueStore', { config: config, source: 'PGrid()PreFilterRendr' });
    return self;
}

export default {
    PGridStart
}