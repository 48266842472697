import Handsontable from 'handsontable'
import vuexStore from './pgridVuexStore.js'
import PGridUtils from "./pgridUtils.js"
import PGridCell from "./pgridCell.js"
import PGridMatrices from './pgridMatrices.js'
import lodash from 'lodash'
import { toLabel, columnLabelToIndex, rowLabelToIndex } from '../formula-parser/helper/cell.js';

// import ApexCharts from 'apexcharts'

function PGridCellRendererYesNo(hotEl, td, row, column, prop, htValue, cellProperties) {

    Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);

    // Handsontable.dom.addEvent(container2, 'mousedown', function (event) {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       event.stopPropagation();
    //     }
    //   });

    cellProperties.strinct = true;
    cellProperties.filter = false;

    try {

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `PGridCellRendererYesNo()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return;// td;
        }

        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    // f.class.split(",").forEach(cl => {
                    //     if (cl == "normaltext") {
                    //         isValStr = true;
                    //     }
                    //     if (td.className.indexOf(cl) == -1) {
                    //         td.classList.add(cl.trim());
                    //     }
                    // });

                    let fcs = f.class.split(",");
                    for (let i = 0; i < fcs.length; i++) {
                        let cl = fcs[i];
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    }
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }



    return;// td;
}



function PGridCellRendererLookup(hotEl, td, row, column, prop, htValue, cellProperties) {

    Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);

    // Handsontable.dom.addEvent(container2, 'mousedown', function (event) {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       event.stopPropagation();
    //     }
    //   });

    cellProperties.strinct = true;
    cellProperties.filter = false;

    cellProperties.visibleRows = 20;

    try {

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `PGridCellRendererYesNo()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return;// td;
        }

        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    // f.class.split(",").forEach(cl => {
                    //     if (cl == "normaltext") {
                    //         isValStr = true;
                    //     }
                    //     if (td.className.indexOf(cl) == -1) {
                    //         td.classList.add(cl.trim());
                    //     }
                    // });

                    let fcs = f.class.split(",");
                    for (let i = 0; i < fcs.length; i++) {
                        let cl = fcs[i];
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    }
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }



    return;// td;
}




function PGridCellRendererCheckboxRenderer(hotInstance, td, row, column, prop, htValue, cellProperties) {

    try {

        cellProperties.checkedTemplate = "Ja";
        cellProperties.uncheckedTemplate = "Nej";

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `PGridCellRendererCheckboxRenderer()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return td;
        }


        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    f.class.split(",").forEach(cl => {
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    });
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRendererCheckboxRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }


    Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
    return td;
}




class PGridCellEditorYesNo extends Handsontable.editors.AutocompleteEditor {

    prepare(row, col, prop, td, originalValue, cellProperties) {
        // Invoke the original method...
        cellProperties.source = ["Ja", "Nej"];
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    finishEditing(restoreOriginalValue) {
        this.cellProperties.isInfinishEditing = true;

        // let newValueArr = super.getValue().split(',')
        // let newValue = null;

        // if (newValueArr.length > 0) {
        //     newValue = newValueArr.pop();
        // }
        let newValueArr = super.getValue().split(',')
        let newValue = null;


        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `beforeChange` });
        let chngCell = pgridDataBuffer[this.row][this.col];
        let changeWarn = lodash.get(chngCell, "Meta.Validator.ChangeWarn", null);

        if (changeWarn) {
            let userOKChange = true; //; confirm(changeWarn);

            let currCellVal = PGridCell.getHotValue2(chngCell);

            //When clicking on a dropdown cell
            if (this.state == "STATE_EDITING") {

                if (newValueArr.length == 0) {
                } else if (newValueArr.length == 1) {
                    newValue = newValueArr[0];
                } else {
                    window.PGridClientDebugMode > 1 && console.warn(`Detected multivalm which is currently not supported`);
                    newValue = null;
                }

                if (String(currCellVal) != String(newValue)) {

                    userOKChange = confirm(changeWarn);

                    if (userOKChange == false) {
                        restoreOriginalValue = true;
                    }
                }
            }
        }

        super.finishEditing(restoreOriginalValue);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


function EditIfFormula(row, col, originalValue) {
    let ret = originalValue
    let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `beforeChange` });
    let chngCell = pgridDataBuffer[row][col];

    if (chngCell && "Formula" in chngCell) {
        ret = chngCell.Formula;
    }

    return ret;
}

function delayDisable_isCellEditing() {
    setTimeout(function () {
        PGridVueStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: false, source: `delayDisable_isCellEditing` });
        PGridVueStore.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: false, source: `delayDisable_isCellEditing` });
    }, 1000); //1s delay if user go directly to SAVE button without exiting cell
}

class PGridCellEditorHandsontableEditor extends Handsontable.editors.TextEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);
        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


class PGridCellEditorLookup extends Handsontable.editors.AutocompleteEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


class PGridCellEditorCheckbox extends Handsontable.editors.CheckboxEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


class PGridCellEditorPercent extends Handsontable.editors.AutocompleteEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    getValue() {
        let ret = 0;
        if (PGridUtils.IsNumeric(this.TEXTAREA.value)) {
            ret = String(Number(this.TEXTAREA.value) / 100);
        }
        return ret;
    }

    setValue(newValue) {
        let ret = 0;
        if (PGridUtils.IsNumeric(newValue)) {
            let newValNum = Number(newValue);
            ret = newValNum * 100;
        }
        this.TEXTAREA.value = String(ret);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}

// PlannicaGrids custom cell renderer, which renders a varity of cell types


function PGridCellRenderer(hotInstance, td, row, column, prop, htValue, cellProperties) {

    Handsontable.renderers.BaseRenderer.apply(this, arguments);

    try {

        let cellIsBeyondVisible = false;

        if (
            PGridVueStore.state.LowestRightNonHiddenLR && PGridVueStore.state.LowestRightOptimization
            &&
            (
                row > PGridVueStore.state.LowestRightNonHiddenLR.y
                ||
                column > PGridVueStore.state.LowestRightNonHiddenLR.x
            )
        ) {

            cellIsBeyondVisible = true;
            td.classList.add("pg-hide-this-lr");
        }


        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `PGridRendererStyle()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return td;
        }


        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        let isValStr = false;
        let isSpecialApexCharts = false;
        //CSS Style

        if (pcell != null) {

            if ("Error" in pcell) {

                let indcateError = true;


                //Is this needed, should be handles in Insert_Calculated_Cell()?
                if (pcell.Error.indexOf("#") == 0 && pcell.Error.indexOf("!") != -1) {
                    // This was needed as this trick does not work '=IF(W45=0,0,W11/(W11+W45))'
                    let numberFormatZeroIfError = lodash.get(pcell, "Meta.NumberFormat.ZeroIfError", null);
                    if (numberFormatZeroIfError) {
                        htValue = "0"; //used by htValueNumIfPoss
                        indcateError = false;
                        pcell.Val = 0;
                        delete pcell.ValStr;
                    }
                }

                if (indcateError) {
                    if (td.className.indexOf("pg-cell-parse-error") == -1) {
                        td.classList.add("pg-cell-parse-error");
                    }
                    // isValStr = true;
                    htValue = pcell.Error;
                }
            }


            if ("Format" in pcell && cellIsBeyondVisible == false) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    f.class.split(",").forEach(cl => {
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    });
                }
            }
            if ("ValStr" in pcell) {
                isValStr = true;
            }

            if (htValue == null) {
                if ("RefType" in pcell) {
                    if (lodash.get(pcell, "Meta.Validator.Type", null) == "apexcharts")
                        isSpecialApexCharts = true;
                }
            }
        }

        if (htValue != null || isSpecialApexCharts) {

            let lastSel = null;

            if (isValStr) {

                if ("LimitStrLenBy" in cellProperties && cellProperties.LimitStrLenBy > 0) {

                    let htValueNoNewLineLimited = htValue;

                    if (cellIsBeyondVisible == false) {
                        td.classList.add("pg-overflow-ellipsis");
                    }

                    // while (td.firstChild) {
                    //     td.removeChild(td.lastChild);
                    // }
                    Handsontable.dom.empty(td);

                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValueNoNewLineLimited)));
                    td.appendChild(innerText);


                }
                else {

                    Handsontable.dom.empty(td)

                    if (cellIsBeyondVisible == false) {
                        td.classList.remove("pg-overflow-ellipsis");
                    }

                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                    td.appendChild(innerText);
                }

            } else {

                let formatRedOnNegative = lodash.get(pcell, "Meta.Format.RedOnNegative", false);
                let formatRedOnNonZero = lodash.get(pcell, "Meta.Format.RedOnNonZero", false);

                let validateType = lodash.get(pcell, "Meta.Save.Validator.Type", null);

                if (validateType == null) {
                    // FUB For not, both Meta.Save.Validator.Type and Meta.Validator.Type is working, this should be only way to set validation type
                    validateType = lodash.get(pcell, "Meta.Validator.Type", null);
                }

                let htValueNumIfPoss = PGridUtils.MakeNumericIfPossible(htValue);
                let htValueNumIfPoss_IsNum = PGridUtils.IsNumeric(htValueNumIfPoss)

                let formatAlternativeText = lodash.get(pcell, "Meta.Format.AlternativeCaption", null);

                if (formatAlternativeText !== null) {
                    Handsontable.dom.empty(td);
                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(formatAlternativeText)));
                    td.appendChild(innerText);
                }
                else {

                    if (htValueNumIfPoss_IsNum) {
                        if (formatRedOnNegative && htValueNumIfPoss < 0 || formatRedOnNonZero && htValueNumIfPoss !== 0) {
                            td.classList.add("pg-red");
                        }
                    }

                    if (validateType == "percent") {

                        if (htValueNumIfPoss_IsNum) {

                            let percetVal = Number(htValueNumIfPoss);
                            let percetVal100 = percetVal * 100;

                            td.classList.add("pg-cell-percentage");

                            //FUB same as below
                            let numberFormatHideIfZero_Default = false;
                            let numberFormatLocale_Default = "sv-SE";
                            let numberFormatOptions_Default = {
                                style: 'decimal',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            };

                            // let numberFormatHideIfZero = lodash.get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero_Default);
                            let numberFormatLocale = lodash.get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale_Default);
                            let numberFormatOptions = lodash.get(pcell, "Meta.NumberFormat.Options", numberFormatOptions_Default);

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");
                            Handsontable.dom.empty(td);

                            let innerText = document.createTextNode(`${percetVal100.toLocaleString(numberFormatLocale, numberFormatOptions)}%`);
                            td.appendChild(innerText);

                        }
                    }
                    else if (validateType == "apexcharts") {

                        try {
                            let anchorId = `apex_chart_${row}_${column}`;

                            Handsontable.dom.empty(td);
                            let apexchart_anchor = document.createElement('div');
                            apexchart_anchor.setAttribute('id', anchorId)
                            td.appendChild(apexchart_anchor);

                            // var options = {
                            //     chart: {
                            //         type: 'line',
                            //         height: '100%'
                            //     },
                            //     series: [{
                            //         name: 'sales',
                            //         data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
                            //     }],
                            //     xaxis: {
                            //         categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                            //     }
                            // }

                            setTimeout(() => {
                                let anchor = document.querySelector(`#${anchorId}`);
                                if (anchor.childNodes.length == 0) {
                                    // var chart = new ApexCharts(anchor, pcell.Meta.ApexCharts.options);
                                    // chart.render();
                                    console.error(`ApexCharts "apexcharts": "^3.27.1", removed in this release`)
                                }
                            }, 500);

                        } catch (err) {
                            console.warn(`PGridCellRenderer() got exception (y:${row} x:${column}): ${err.message}`);
                        }
                    }
                    //The rest cases
                    else {
                        if (htValueNumIfPoss_IsNum) {

                            let numberFormatHideIfZero_Default = false;
                            let numberFormatLocale_Default = "sv-SE";
                            let numberFormatOptions_Default = {
                                style: 'decimal',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            };

                            // "Meta": {
                            //     "NumberFormat": {
                            //         "Options": {
                            //             "style": "decimal",
                            //             "minimumFractionDigits": 0,
                            //             "maximumFractionDigits": 0
                            //         }
                            //     }
                            // }

                            let numberFormatHideIfZero = lodash.get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero_Default);
                            let numberFormatLocale = lodash.get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale_Default);
                            let numberFormatOptions = lodash.get(pcell, "Meta.NumberFormat.Options", numberFormatOptions_Default);

                            //Remove child text
                            Handsontable.dom.empty(td);

                            if (numberFormatHideIfZero && htValueNumIfPoss === 0) {

                            } else {
                                let innerText = document.createTextNode(`${htValueNumIfPoss.toLocaleString(numberFormatLocale, numberFormatOptions)}`);
                                td.appendChild(innerText);
                            }

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");
                        } else {
                            Handsontable.dom.empty(td);
                            let innerText = null;
                            innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                            td.appendChild(innerText);
                        }
                    }

                }
            }

            if ("DoubleClickLink" in cellProperties && cellProperties.DoubleClickLink) {
                if (cellIsBeyondVisible == false) {
                    td.classList.add("pgridNoDblClickEdit");
                }
            }

            let references = lodash.get(pcell, "Meta.References", null);
            if (references) {
                if (vuexStore.state.pgridSettings.PGridTableShowHiddenMode) {
                    if (cellIsBeyondVisible == false) {
                        td.setAttribute('title', references.join("\n"));
                    }
                }
            }
        }
        else {
            //Required to avoid unvanted late rendered cells with left aligned "0"
            Handsontable.dom.empty(td);
        }


    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }

    // cellProperties.HasRendered = true;

    return td;

}


let PGridValidators = {
    number: function (value, callback) {
        let valRet = false;

        if (value !== null && value !== undefined && value !== "") {

            if (PGridUtils.IsNumeric(value)) {
                valRet = true;
            } else if (value == undefined) {

                if (validatorAllowEmpty) {
                    valRet = true;
                }

            }
        } else {
            valRet = true;
        }
        callback(valRet);

    },
    percent: function (value, callback) {
        let valRet = false;

        if (value !== null && value !== undefined && value !== "") {

            if (PGridUtils.IsNumeric(value)) {
                valRet = true;
            } else if (value == undefined) {

                if (validatorAllowEmpty) {
                    valRet = true;
                }

            }
        } else {
            valRet = true;
        }
        callback(valRet);
    },
    yesno: String.raw`^(Ja|Nej)$`,
    lookup: String.raw`^.*$`,
    checkbox: String.raw`^(Ja|Nej)$`,
    text: String.raw`^.*$`,
    date: String.raw`^\d{4}\-\d{1,2}\-\d{1,2}$`,
}


export let HOTEventHandlers = {



    afterSelection: async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {

        window.PGridClientDebugMode >= 2 && console.debug("afterSelection() start");

        let context = vuexStore;

        // vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: false, source: `afterChange` });


        let lastSelected = null;

        if (vuexStore.state.hotRef && vuexStore.state.lastSelected && vuexStore.state.lastSelected != "") {
            let { y, x } = JSON.parse(vuexStore.state.lastSelected);
            lastSelected = { y, x };

            if ((row === row2 && row === lastSelected.y)
                &&
                (column === column2 && column === lastSelected.x)) {
                window.PGridClientDebugMode >= 2 && console.debug("afterSelection quit exit as last selected is same");
                return;
            }
        }

        await PGridUtils.handleOrDelayAfterChange(vuexStore, null, `afterSelection()`);


        window.PGridClientDebugMode >= 3 && console.debug(`afterSelection: ${JSON.stringify({
            row,
            column,
            inputBar_HasFocus: vuexStore.state.pgridSettings.inputBar_HasFocus,
            lastSelected: vuexStore.state.lastSelected,
            inputBar_HasFocusCellJumpBack: vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack,
            inputBar_HasFocus: vuexStore.state.pgridSettings.inputBar_HasFocus,
            DisableEvents: vuexStore.state.pgridSettings.DisableEvents
        }, null, 2)}`);


        vuexStore.commit("Mutation_UpdateRoot", {
            prop: "lastSelected",
            val: JSON.stringify({ y: row, x: column }),
            source: "afterSelection()"
        });


        if (vuexStore.state.pgridSettings.DisableEvents || vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes > 1) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterSelection: events disabled`);
            return;
        }

        //Always enable on first cell selection
        if (vuexStore.state.pgridSettings.inputBar_Enabled == false) {
            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Enabled', val: true, source: `afterSelection()` });
        }

        if (vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack) {

            let selectedCellLabel = toLabel({ index: row }, { index: column });

            PGridUtils.AddInputBarClasses(vuexStore, row, column, "pg-inputbar-clickedcell");

            let newSelectedCellLabel = vuexStore.state.pgridSettings.inputBar_SelectCellVal;

            let cursorPos = vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_CursorPos


            if (cursorPos != -1) {
                newSelectedCellLabel = newSelectedCellLabel.substring(0, cursorPos) + selectedCellLabel + newSelectedCellLabel.substring(cursorPos);
                cursorPos = cursorPos + selectedCellLabel.length;
            } else {
                newSelectedCellLabel = newSelectedCellLabel + selectedCellLabel;
                cursorPos = cursorPos + selectedCellLabel.length;
            }


            window.PGridClientDebugMode >= 3 && console.debug("afterSelection handleCursorChange cursorPos " + cursorPos);
            vuexStore.commit("Mutation_UpdatePGridSettings", {
                prop: "inputBar_HasFocusCellJumpBack_CursorPos",
                val: cursorPos,
                source: `afterSelection()`
            });

            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_SelectCellVal', val: newSelectedCellLabel, source: `afterSelection()` });


            //Return focus
            // if (vuexStore.state.hotRef && vuexStore.state.lastSelected && vuexStore.state.lastSelected != "") {
            //     let { y, x } = JSON.parse(vuexStore.state.lastSelected);
            if (lastSelected) {

                window.PGridClientDebugMode >= 3 && console.debug("Return focus")
                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_HasFocusCellJumpBack', val: true, source: `afterSelection()` });

                // PGridUtils.AddInputBarClasses(vuexStore, lastSelected.y, lastSelected.x, "pg-inputbar-editcell");

                if (vuexStore.state.pgridSettings.inputBar_inputRef) {
                    setTimeout(() => {
                        false && console.debug("Return focus inputBar_inputRef.deselectCell")

                        // vuexStore.state.hotRef.deselectCell();
                        Deselect({ source: `afterSelection()` })
                        setTimeout(() => {
                            false && console.debug("Return focus inputBar_inputRef.focus()")
                            vuexStore.state.pgridSettings.inputBar_inputRef.focus();
                        });

                        let cursorPos = vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_CursorPos;

                        console.log("Return focus inputBar_inputRef cursorPos: " + cursorPos);
                        vuexStore.state.pgridSettings.inputBar_inputRef.selectionEnd = cursorPos;
                    }, vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_delayedMiliseconds);
                }
            }

        } else {

            if (vuexStore.state.pgridSettings.inputBar_HasFocus == false) {

                window.PGridClientDebugMode >= 4 && console.debug("afterSelection B.1");

                let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `beforeChange` });

                let pcell = pgridDataBuffer[row][column];
                let selectCellVal = PGridCell.InputBar_ConvertPGridCellForEdit(vuexStore, { pcell, source: `afterSelection()` });


                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_SelectCellVal', val: selectCellVal, source: `afterSelection()` });

                // vuexStore.commit("Mutation_UpdateRoot", {
                //     prop: "lastSelected",
                //     val: JSON.stringify({ y: row, x: column }),
                //     source: "afterSelection()"
                // });


                if (vuexStore.state.pgridSettings.inputBar_BarMode != "small") {
                    PGridUtils.RemoveInputBarClasses(vuexStore);
                    PGridUtils.HeighlightRefCells(vuexStore, row, column);
                }


            } else {

                window.PGridClientDebugMode >= 4 && console.debug("afterSelection B.2");

            }
        }


        /*
            After an selection is changes, this code detects any registred,
            in pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) Overlay items
            rule (with If_Cell_Is_Selected) should be taken care of by lr.Phase8_Insert_Overlay(...["overlay_selectionchange"]
        */


        let cellLRRefs_Keys = null;
        if (context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
            cellLRRefs_Keys = Object.keys(context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays);
        }

        if (cellLRRefs_Keys) {

            window.PGridClientDebugMode >= 3 && console.debug(`afterSelection(): Has cellLRRefs_Keys`)
            let pgridDataBuffer = null;

            let y = row;
            let x = column;

            let foundIdx = cellLRRefs_Keys.indexOf(`overlay_selectionchange:${y}:${x}`);
            if (foundIdx != -1) {

                // This gets triggered when a cell If_Cell_Is_Selected is selected`
                window.PGridClientDebugMode >= 3 && console.debug(`This gets triggered when a cell If_Cell_Is_Selected is selected: overlay_selectionchange:${y}:${x}`);

                context.commit("Mutation_UpdateRoot", {
                    prop: "lastSelectedWasA_selectionchange",
                    val: true,
                    source: "afterSelection()"
                });

                let lrAffected_List = context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays[cellLRRefs_Keys[foundIdx]];

                for (let l = 0; l < lrAffected_List.length; l++) {
                    let lrAffected = lrAffected_List[l];

                    let [lrName, yLR, xLR] = lrAffected.split(":");

                    if (pgridDataBuffer == null) {
                        pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: context.state, commit: context.commit, source: `afterselection` });
                    }
                    const lr = PGridMatrices.Get_LRFromTableCoordinates(yLR, xLR, pgridDataBuffer);


                    let pgridDataBufferBefore = null;
                    if (window.PGridClientDebugMode >= 3) {
                        pgridDataBufferBefore = JSON.parse(JSON.stringify(pgridDataBuffer));
                    }

                    let { pgridDataInserted, hotTableInserted } = lr.Phase8_Insert_Overlay(
                        pgridDataBuffer
                        , context.state.hotRef.getData()
                        , context
                        , "selectionchangeEvent"
                    );

                    pgridDataBuffer = pgridDataInserted;

                    if (window.PGridClientDebugMode >= 2) {
                        PGridUtils.DebugDetectTableChanges(`HOTEventHandlers.afterSelection()`, pgridDataBuffer, pgridDataBufferBefore);
                    }

                }

            }
            else if (context.state.lastSelectedWasA_selectionchange) { //Handle change selection out from a precious selected cell

                window.PGridClientDebugMode >= 3 && console.debug(`This gets triggered when a cell If_Cell_Is_Selected is un-selected: (not on this cell) overlay_selectionchange:${y}:${x}`);

                context.commit("Mutation_UpdateRoot", {
                    prop: "lastSelectedWasA_selectionchange",
                    val: false,
                    source: "afterSelection()"
                });

                if (pgridDataBuffer == null) {
                    pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: context.state, commit: context.commit, source: `afterselection` });
                }

                // let foundLRs = PGridMatrices.Get_LRsFromTableCached(pgridDataBuffer); // Get all linked ranges
                let foundLRs = PGridMatrices.Get_LRsFromTable(pgridDataBuffer, { source: `afterSelectoin()` });

                let foundLRs_Keys = Object.keys(foundLRs);
                for (let i = 0; i < foundLRs_Keys.length; i++) {
                    let lr = foundLRs[foundLRs_Keys[i]];


                    let { pgridDataInserted, hotTableInserted } = lr.Phase8_Insert_Overlay(
                        pgridDataBuffer
                        , context.state.hotRef.getData()
                        , context
                        , "selectionchangeEvent"
                    );


                    pgridDataBuffer = pgridDataInserted;
                }
            }

            if (pgridDataBuffer) {

                window.PGridClientDebugMode >= 3 && console.debug("afterSelection() redraw");
                context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `afterselection` });
                context.dispatch("Action_Redraw", { source: `afterselection` });
            }
        }
    },

    beforeChange: async (changes) => {

        // if (!window.PGridTimers) {
        //     window.PGridTimers = {};
        // }
        // window.PGridTimers["StartTime_setDataAtCell"] = null;
        window.PGridClientDebugMode >= 2 && console.debug(`beforeChange: ${JSON.stringify(changes)}`);


        PGridUtils.PGridTimerStart('beforeChange:setDataAtCell');
        window.PGridClientDebugMode >= 2 && console.log(`beforeChange: ${JSON.stringify(changes)}`);

        if (vuexStore.state.pgridSettings.DisableEvents) {
            false && console.debug(`beforeChange: events disabled`);
            return;
        }

        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `beforeChange` });

        for (let i = 0; i < changes.length; i++) {
            let [changeY, changeX, oldVal, newVal] = changes[i];

            if (oldVal != newVal
                || (oldVal === 0 /*|| oldVal === ""*/) && newVal === "") /* This catches "deleted" 0 or empty values. Maybe only oldVal !== newVal could be used insted? */ {

                let chngCell = pgridDataBuffer[changeY][changeX];

                let changeWarn = lodash.get(chngCell, "Meta.Validator.ChangeWarn", null);
                let validatorType = lodash.get(chngCell, "Meta.Validator.Type", null);



                if (validatorType != "yesno" && changeWarn) {
                    let userOKChange = confirm("beforeChange: " + changeWarn);

                    if (userOKChange == false) {
                        changes = false;

                        return;
                    }
                }

                //Handels the case when a number cell is "deleted" that had a numer. As delete is not possible, it sets the value to 0 insted
                if (validatorType === "number" && oldVal !== undefined && typeof newVal === "string" && newVal.trim() === "") {
                    changes[i][3] = 0;
                }

            }

        }

        PGridUtils.PGridTimerStop('beforeChange');

    },

    afterBeginEditing: async (row, column) => {
        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: true, source: `afterBeginEditing` });
        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: true, source: `afterBeginEditing` });

        await PGridUtils.DirtyGridFlag(vuexStore, `afterBeginEditing()`);
    },

    afterChange: async (changes) => {

        window.PGridClientDebugMode >= 2 && console.debug(`afterChange: changes length: ${noOfChanges}`);

        let noOfChanges = -1;
        if (Array.isArray(changes)) {
            noOfChanges = changes.length;
        }


        PGridUtils.PGridTimerStart('afterChange:setDataAtCell');



        if (vuexStore.state.pgridSettings.DisableEvents) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: events disabled`);
            return;
        }

        if (vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes > 0) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: setDataAtCell events disabled (DisableEventsSetDataAtCellNTimes: ${vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes})`);
            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "subtract", val: 1, source: `afterChange` });

            if (vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes == 0) {

                PGridUtils.PGridTimerStop('afterChange:setDataAtCell');
                PGridUtils.PGridTimerStop('beforeChange:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_calc_allOrChanges:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_HOT_ApplyGridChange:setDataAtCell');
                PGridUtils.PGridTimerPrint();
                PGridUtils.PGridTimerReset();
            }

            return;
        }

        try {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: setDataAtCell events WILL call handleOrDelayAfterChange (DisableEventsSetDataAtCellNTimes: ${vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes}) number of changes: ${noOfChanges}`);

            await PGridUtils.handleOrDelayAfterChange(vuexStore, changes, `afterChange()`);
        } catch (err) {
            let errMsg = `> hot afterChange() got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }
    },

    cells: function (row, col, prop) {

        let renderer = null;
        let editor = PGridCellEditorHandsontableEditor;


        let cellProperties = {};

        try {

            if (PGridUtils.Is_DynamicMode(vuexStore.state)) {


                if (row == 0 && col == 0) {
                    FakeAction_ApplyLRPgrid_OnFirstCell(vuexStore, { source: `pgridHT.cells()` });
                }

                let pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `pgridHT cells() event` });
                if (pgridData != null && row < pgridData.length) {

                    let selCell = null;
                    selCell = pgridData[row][col]
                    if (selCell) {

                        let writable = false;
                        let readOnly = undefined;
                        let limitStrLenBy = null;
                        let newLineOnEnter = null;
                        let doubleClickLink = null;
                        let validatorType = null;
                        let validatorRegEx = null;
                        let validatorAllowEmpty = true;
                        let formatAddClasses = null;
                        let formatRemoveClasses = null;

                        if (false) {
                            if (lodash.has(selCell, "Meta")) {
                                writable = lodash.get(selCell, "Meta.Save.Writable", false);
                                readOnly = lodash.get(selCell, "Meta.Save.ReadOnly", false);
                                limitStrLenBy = lodash.get(selCell, "Meta.CellRender.LimitStrLenBy", null);
                                newLineOnEnter = lodash.get(selCell, "Meta.CellEditor.NewLineOnEnter", null);
                                doubleClickLink = lodash.get(selCell, "Meta.DoubleClickLink", null);
                                validatorType = lodash.get(selCell, "Meta.Validator.Type", null);
                                validatorRegEx = lodash.get(selCell, "Meta.Validator.RegEx", null);
                                validatorAllowEmpty = lodash.get(selCell, "Meta.Validator.AllowEmpty", true);
                                formatAddClasses = lodash.get(selCell, "Meta.Format.AddClasses", null);
                                formatRemoveClasses = lodash.get(selCell, "Meta.Format.RemoveClasses", null);
                            }
                        } else {
                            if ("Meta" in selCell) {

                                if ("Save" in selCell.Meta) {

                                    if ("Writable" in selCell.Meta.Save) {
                                        writable = selCell.Meta.Save.Writable;
                                    }
                                    else {
                                        readOnly = true;


                                    }

                                    if (readOnly != true) {
                                        if ("ReadOnly" in selCell.Meta.Save) {
                                            readOnly = selCell.Meta.Save.ReadOnly;
                                        }
                                    }
                                }

                                if ("CellRender" in selCell.Meta) {

                                    if ("LimitStrLenBy" in selCell.Meta.CellRender) {
                                        limitStrLenBy = selCell.Meta.CellRender.LimitStrLenBy;
                                    }
                                    // limitStrLenBy = lodash.get(selCell, "Meta.CellRender.LimitStrLenBy", null);
                                }


                                if ("CellEditor" in selCell.Meta) {
                                    if ("NewLineOnEnter" in selCell.Meta.CellEditor) {
                                        newLineOnEnter = selCell.Meta.CellEditor.ReadOnly;
                                    }
                                    // newLineOnEnter = lodash.get(selCell, "Meta.CellEditor.NewLineOnEnter", null);
                                }

                                if ("DoubleClickLink" in selCell.Meta) {
                                    doubleClickLink = selCell.Meta.DoubleClickLink;
                                }
                                // doubleClickLink = lodash.get(selCell, "Meta.DoubleClickLink", null);

                                if ("Validator" in selCell.Meta) {

                                    if ("Type" in selCell.Meta.Validator) {
                                        validatorType = selCell.Meta.Validator.Type;
                                    }
                                    // validatorType = lodash.get(selCell, "Meta.Validator.Type", null);

                                    if ("RegEx" in selCell.Meta.Validator) {
                                        validatorRegEx = selCell.Meta.Validator.RegEx;
                                    }
                                    // validatorRegEx = lodash.get(selCell, "Meta.Validator.RegEx", null);

                                    if ("AllowEmpty" in selCell.Meta.Validator) {
                                        validatorAllowEmpty = selCell.Meta.Validator.AllowEmpty;
                                    }
                                    // validatorAllowEmpty = lodash.get(selCell, "Meta.Validator.AllowEmpty", true);
                                }



                                if ("Format" in selCell.Meta) {
                                    if ("AddClasses" in selCell.Meta.Format) {
                                        formatAddClasses = selCell.Meta.Format.AddClasses;
                                    }
                                    // formatAddClasses = lodash.get(selCell, "Meta.Format.AddClasses", null);

                                    if ("RemoveClasses" in selCell.Meta.Format) {
                                        formatRemoveClasses = selCell.Meta.Format.RemoveClasses;
                                    }
                                }
                            }
                        }

                        if (limitStrLenBy != null) {
                            cellProperties.LimitStrLenBy = limitStrLenBy;
                        }

                        if (doubleClickLink) {
                            cellProperties.DoubleClickLink = true;
                        }

                        if (newLineOnEnter) {
                            cellProperties.NewLineOnEnter = true;
                        }



                        if (readOnly === true) {
                            cellProperties.readOnly = true;

                            selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-readonly");
                            selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, "pg-writable");
                        }
                        else if (writable === true) {
                            if (readOnly === false) {
                                cellProperties.readOnly = false;
                            }
                            selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-writable");
                            selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, "pg-readonly");
                        }

                        if (formatRemoveClasses) {
                            for (let i = 0; i < formatRemoveClasses.length; i++) {
                                let fDelCla = formatRemoveClasses[i];
                                selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, fDelCla);
                            }
                        }


                        if (formatAddClasses) {
                            for (let i = 0; i < formatAddClasses.length; i++) {
                                let fAddCla = formatAddClasses[i];
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, fAddCla);
                            }
                        }

                        let doValidation = false;
                        let matchValidation = null;

                        if (validatorType != null) {
                            doValidation = true;

                            if (validatorType == "number") {
                                matchValidation = PGridValidators.number;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                // editor = PGridCellEditorBase;
                            }
                            else if (validatorType == "percent") {

                                //matchValidation = String.raw`^-?\d+([\,\.]\d{1,17})?$`;  //The maximum number of decimals is 17, but floating point arithmetic is not always 100% accurate:

                                //function as a validator
                                matchValidation = PGridValidators.percent;

                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                editor = PGridCellEditorPercent;
                            }
                            else if (validatorType == "yesno") {
                                matchValidation = PGridValidators.yesno;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-right");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pgtype-yesno");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");
                                renderer = PGridCellRendererYesNo;
                                editor = PGridCellEditorYesNo; //Handsontable.renderers.getRenderer("pgridrenderer");

                            }
                            else if (validatorType.indexOf("lookup:") != -1) {


                                if (readOnly ? true : !writable) {
                                    renderer = PGridCellRenderer; //When read only, use this instead
                                }
                                else {

                                    selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");

                                    matchValidation = PGridValidators.lookup;
                                    renderer = PGridCellRendererLookup;
                                    editor = PGridCellEditorLookup; //Handsontable.renderers.getRenderer("pgridrenderer");

                                    {
                                        let hierarchyId = validatorType.split(":")[1];
                                        if (hierarchyId == null) {
                                            throw new Error(`cells() could not extract hierarchyId from ${validatorType}`);
                                        }
                                        let refTypeAndCoord = PGridUtils.extractRefTypeFromCoord(row, col, vuexStore, `cells()`)
                                        let lookupDSName = lodash.get(refTypeAndCoord, `RefType.Lookups[${hierarchyId}].PivotHierarchy`, null);

                                        if (lookupDSName == null) {
                                            throw new Error(`cells() could not extract lookup datasource name`);
                                        }
                                        // cellProperties.lookupDS = lookupDSName;

                                        // if (!cellProperties.lookupDS) {
                                        //     throw new Error("PGridCellEditorLookup(): Missing: cellProperties.lookupDS")
                                        // }


                                        let lookupSource = PGridUtils.extractCellLookupValuesFromDS(vuexStore, lookupDSName);

                                        if (!(lookupDSName in vuexStore.state.pgridSettings.cellLookups)) {

                                            vuexStore.commit("Mutation_UpdatePGridSettings", {
                                                prop: "cellLookups",
                                                op: "addkey",
                                                key: lookupDSName,
                                                val: lookupSource,
                                                source: `cells()`
                                            });

                                        }

                                        cellProperties.source = vuexStore.state.pgridSettings.cellLookups[lookupDSName];
                                        // cellProperties.source = ["Adam", "Bertil", "Cesar"];

                                    }
                                }
                            }
                            else if (validatorType == "checkbox") {
                                matchValidation = PGridValidators.checkbox;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-center");
                                renderer = PGridCellRendererCheckboxRenderer;
                                editor = PGridCellEditorCheckbox; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "text") {
                                matchValidation = PGridValidators.text;
                                doValidation = false;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "date") {
                                matchValidation = PGridValidators.date;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "apexcharts") {
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                doValidation = false; //override as this needs no validation
                            }
                        }

                        //Cell custom regex validator overrides
                        if (validatorRegEx != null) {
                            doValidation = true;
                            matchValidation = validatorRegEx;
                        }

                        //Dont validate non writable cells
                        if (writable == false) {
                            doValidation = false;
                        }

                        if (doValidation) {

                            if ((typeof matchValidation) === 'string') {
                                if (validatorAllowEmpty) {
                                    matchValidation = String.raw`^undefined$|^null$|^$|` + matchValidation;
                                }
                                cellProperties.validator = new RegExp(matchValidation);
                            } else if ((typeof matchValidation) == 'function') {
                                cellProperties.validator = matchValidation;
                            }
                        }
                    }
                }
            }

        } catch (err) {
            let errMsg = `pgridHT.js cells( ${row}, ${col} ) got exception: ${err.message || err}\n\nStack:${err.stack}`;
            console.warn(errMsg);
        }

        if (editor) {
            cellProperties.editor = editor;
        }


        if (!("editor" in cellProperties)) {
            // cellProperties.editor = Handsontable.editors.getEditor("text"); //This helped keyboard inputs in dropdowns to not crash after edit
        }

        if (renderer) {
            cellProperties.renderer = renderer;
        }
        if (!("renderer" in cellProperties)) {
            cellProperties.renderer = PGridCellRenderer;
        }

        return cellProperties
    },

    afterOnCellMouseDown: async function (event, coords, td) {
        window.PGridClientDebugMode >= 3 && console.debug(`afterOnCellMouseDown() event`, event)


        let detectedDblClick = false;
        let selectedHotCell_single = null;

        let cellAction = null; // only 'link' for now
        let cellAction_linkUrl = null;
        let cellAction_linkNewTab = true;
        let cellAction_linkFocus = true;


        var now = new Date().getTime();
        // check if dbl-clicked within 1/5th of a second. change 200 (milliseconds) to other value if you want
        if (!(td.lastClick && now - td.lastClick < 200)) {
            td.lastClick = now;
            //  return; // no double-click detected
        } else {
            detectedDblClick = true;
        }



        if (detectedDblClick) {

            window.PGridClientDebugMode >= 2 && console.debug(`afterOnCellMouseDown() detected double click`);

            let selectedHotCells = vuexStore.state.hotRef.getSelected();

            if (selectedHotCells.length === 1) { //As it may also be a multi select

                if (selectedHotCells[0][0] === selectedHotCells[0][2]
                    &&
                    selectedHotCells[0][1] === selectedHotCells[0][3]
                    &&
                    coords.row === selectedHotCells[0][0]
                    &&
                    coords.col === selectedHotCells[0][1]
                ) {
                    selectedHotCell_single = [selectedHotCells[0][0], selectedHotCells[0][1]];
                }

            }


        }
        // else{
        //     //await PGridUtils.handleOrDelayAfterChange_Clear(vuexStore, null, `afterOnCellMouseDown()`); //Old relic?
        // }



        if (selectedHotCell_single) {


            let pgridData = pgridData = PGridUtils.Get_CurrentPGridData({ state: vuexStore.state, commit: vuexStore.commit, source: `afterOnCellMouseDown() event` });
            if (pgridData != null) {

                let pcell_selected = pgridData[selectedHotCell_single[0]][selectedHotCell_single[1]];

                let pcellMetaDblClick = lodash.get(pcell_selected, "Meta.DoubleClickLink", null);


                if (pcellMetaDblClick != null) {

                    cellAction = 'link';

                    // // new URL() https://dmitripavlutin.com/parse-url-javascript/
                    let gotoUrl = new URL(pcellMetaDblClick, window.location.href);

                    if (gotoUrl.search == null) {
                        console.warn(`[onCellDblClick] link: ${pcellMetaDblClick} gotoUrl.search == null`);
                    } else {

                        cellAction_linkUrl = gotoUrl.href;

                    }
                }

            }
        }



        //////  Take action

        if (cellAction == 'link') {

            window.PGridClientDebugMode >= 2 && console.debug(`afterOnCellMouseDown() cellAction == 'link'`);

            PGridUtils.SaveScrollAndCellPositionForCurrentPage(vuexStore, selectedHotCell_single);


            let cellAction_linkUrl_URL = new URL(cellAction_linkUrl);

            let isSameSite = PGridUtils.IsSameUrlHost(window.location.href, cellAction_linkUrl_URL.href);

            if (isSameSite) {

                PGridUtils.HistoryPushStateIfNotSame(cellAction_linkUrl, `afterOnCellMouseDown()`);

                if (false) { //As it gave double confirmations
                    if (PGridUtils.ConfirmNavigation(vuexStore, `afterOnCelMouseDown: gotoUrl: ${gotoUrl}`, "Vill du ändå följa länken?")) {
                        if (false) {
                            PGridUtils.updateTabLinks(cellAction_linkUrl, `onCellDblClick()`);
                            //Reaload tablinks

                            await PGridUtils.sleep(100);
                            window.PGridClientDebugMode >= 3 && console.debug("Now call for loading of the new tab")
                            await PGridUtils.ReOrLoadInitialCustomerTabKey();
                        }
                        // window.location.href = gotoUrl.href ;

                        PGridUtils.HandlePlannicaGridUrlUpdates(gotoUrl.href);
                        // await PGridUtils.clickTabOrLinkEvent(gotoUrl.href);
                    }
                }

                PGridUtils.HandlePlannicaGridUrlUpdates(cellAction_linkUrl_URL.href);

                setTimeout(() => {
                    Deselect({ source: `afterSelection() same site` });
                }, 400);

            } else {

                let openLinkInNewTab = function (urlToOpen) {
                    // open in new tab
                    // window.location.href = gotoUrl.href;
                    try {
                        var win = window.open(urlToOpen, '_blank');
                        if (win) {

                            win.focus();

                            setTimeout(() => {
                                Deselect({ source: `afterSelection() new tab` });
                            }, 200);
                        }
                    } catch (err) {
                        console.warn(`[onCellDblClick] link: ${urlToOpen} winwos.open got exception: ${err.message}`);
                    }
                }


                //Async open link
                setTimeout(() => {
                    openLinkInNewTab(cellAction_linkUrl_URL.href);
                }, 100);



            }

        }
        else {

            //No link, check if PGridTableEditMode is enable, then open edit panel
            if (vuexStore.state.pgridSettings.PGridTableEditMode) {
                //Open side debug panel
                if (detectedDblClick && vuexStore.state.pgridSettings.showPanelOnSelect && !vuexStore.state.pgridSettings.showPanel) {
                    await vuexStore.dispatch("Action_UpdateDebugPanel", { newShowPanel: true, source: `afterOnCellMouseDown()` });
                }
            }

        }




        window.PGridClientDebugMode >= 3 && console.debug(`afterOnCellMouseDown() event end`)
    }
}


function log_events(event, data) {
    console.log("FLUX: event: " + event);

}

export async function Init({ source }) {

    const exStart = new Date();

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Init() source: ${source}`);
    document.body.classList.add('app-body');
    let appBody = document.getElementsByClassName('app-body')[0];
    PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
    if (PGridUtils.mobileCheck()) {
        appBody.classList.add("scrollmode-boundary");
    } else {
        appBody.classList.add("scrollmode-content");
    }


    // Action_PreProcessCellStyles(vuexStore, "pgridHT.Init()");
    // await vuexStore.dispatch("Action_PreProcessCellStyles", { source: `${source} -> pgridHT.Init()` });

    let state = vuexStore.state;

    let pgridOptions = {
        'data': state.hotSettings.data,
        'rowHeaders': true,
        'colHeaders': true,
        //  'autoColumnSize': { 'syncLimit': 300 },
        //  'autoRowSize': { 'syncLimit': 300 },
        // 'autoColumnSize': { 'syncLimit': '50%' },  //Does not work for merge cells?
        // 'autoRowSize': { 'syncLimit': '30%' },  //Does not work for merge cells?
        // 'autoRowSize': { 'syncLimit': 0 },

        //   'autoRowSize': { 'syncLimit': 0 },
        'autoColumnSize': { 'syncLimit': '10%' },
        // 'autoRowSize': false,
        // 'autoColumnSize': false,
        // 'colWidths': [50, 106, 87, 101, 87, 141, 92, 122, 116, 145, 120, 200, 110, 65, 65, 65, 65, 65, 171, 50, 50],

        'outsideClickDeselects': false,
        'viewportColumnRenderingOffset': 65536, //FUB, see below  //130 * 1000,
        'viewportRowRenderingOffset': 65536, //FUB If this is low, some table changes as not detected //40 was giving some visual and hotRef.getCell(y, x, true) --> undefined in some cases  //'auto', //120,
        'autoWrapRow': false,
        'autoWrapCol': false,
        'mergeCells': state.pgridSettings.PGridTableShowHiddenMode ? null : state.hotSettings.spanCells,
        'afterSelection': HOTEventHandlers.afterSelection,
        'beforeChange': HOTEventHandlers.beforeChange,
        'afterBeginEditing': HOTEventHandlers.afterBeginEditing,
        'afterChange': HOTEventHandlers.afterChange,
        'renderer': HOTEventHandlers.renderer,
        'cells': HOTEventHandlers.cells,
        'afterOnCellMouseDown': HOTEventHandlers.afterOnCellMouseDown,
        'comments': true,
        // 'afterDeselect': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: afterDeselect() start");
        // },
        // // 'afterDeselect': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        // //     window.PGridClientDebugMode >= 2 && console.debug("afterDeselect() start");
        // // },
        // 'beforeOnCellMouseOut': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: beforeOnCellMouseOut() start");
        // },
        // 'beforeInitWalkontable': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: beforeInitWalkontable() start");
        // },
        // 'afterOnCellMouseUp': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: afterOnCellMouseUp() start");
        // },
        // 'renderAllRows': true,
    }

    state.hotSettings.LRHotSettings.forEach(o => {
        window.PGridClientDebugMode >= 2 && console.debug(`pgridHT.Init() Adding HOT settings from LR's: ${JSON.stringify(o)}`);
        pgridOptions = lodash.merge(pgridOptions, o);
    });

    window.PGridClientDebugMode >= 3 && console.debug("pgridHT.Init() Creating Handsontable instance. source: " + source);
    var container = document.getElementById('pgrid-hot');

    vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "add", val: 1, source: `pgridHT.Init()` });
    //TEST vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 99999, source: `pgridHT.Init()` });

    var hotRefWrapper = new Handsontable(container,
        pgridOptions
    );


    if (window.PGridPerformanceMode) {

        if (!Handsontable.hooks.has("beforeChange")) {

            Handsontable.hooks.add("beforeChange", function (changes, source) {

                if (!changes) {
                    window.PGridClientDebugMode >= 2 && console.debug(`PerformanceModeDEBUG: Handsontable.hooks.add("beforeChange") changes.length: ${changes.length}`);
                    return;
                }

                if (PGridVueStore.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                    for (let i = 0; i < changes.length; i++) {
                        if (`overlay_postcalc:${changes[i][0]}:${changes[i][1]}` in PGridVueStore.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                            if (changes[i][2] !== changes[i][3]) {
                                //Force all cells to render if a overlay_postcalc cell has changee (FUB). Should insted keep track of cells to render...
                                this.lastChanges = null;
                                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'lastChangesLocked', val: true, source: `pgridHT.Init()` });
                                return;
                            }
                        }
                    }
                }


                if (PGridVueStore.state.pgridSettings.lastChangesLocked) { //this prevents an aftercalc event to owervrite an overlay_postcalc "this.lastChanges = null;", which should force a total rendering
                    //Dont alter
                }
                else {
                    this.lastChanges = changes;
                }
                // console.log("afterChanges: " + JSON.stringify(changes));
            });
        }
    }



    vuexStore.commit('Mutation_UpdateRoot', { prop: 'hotRef', val: hotRefWrapper, source: `${source} -> pgridHT.Init()` });

    //This will avoid afterChange event handling after init, but not after init
    // vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 1, source: `pgridHT.Init()` });


    /*
    Called by events afterScrollHorizontally, afterScrollHorizontally and "cellAction == 'link'"
    */

    function handleScrollChange() {
        PGridUtils.SaveScrollAndCellPositionForCurrentPage(vuexStore);
    }

    if (!Handsontable.hooks.has("afterScrollHorizontally")) {
        Handsontable.hooks.add("afterScrollVertically", handleScrollChange);
        Handsontable.hooks.add("afterScrollHorizontally", handleScrollChange);
    }

    (function () {
        var beforePrint = function () {

            window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js beforePrint()");

            let appBody = document.getElementsByClassName('app-body')[0];

            PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
            appBody.classList.add("scrollmode-boundary");
            appBody.classList.add("printingmode");

            if (vuexStore.state.hotRef != null) {
                window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js beforePrint() hotRef.render()");

                vuexStore.state.hotRef.render();
            }
        };
        var afterPrint = function () {
            window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js afterPrint()");

            let appBody = document.getElementsByClassName('app-body')[0];

            PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
            PGridUtils.removeClassesByPrefix(appBody, "printingmode");
            if (PGridUtils.mobileCheck()) {
                appBody.classList.add("scrollmode-boundary");
            } else {
                document.body.classList.add("scrollmode-content");
            }


            if (vuexStore.state.hotRef != null) {
                window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js afterPrint() hotRef.render()");

                vuexStore.state.hotRef.render();
            }

        };

        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    }());


    function GetScollbackMemory({ url, source = null }) {

        window.PGridClientDebugMode >= 2 && console.debug(`Vuex Action: Action_GetScollbackMemory: : ${url} got called from: ${source} `);

        let scollbackMemory = vuexStore.state.pgridSettings.scollbackMemory;
        let searchUrlQSAll = url.split("&");

        for (let scollbackMemory_itemKey of Object.keys(scollbackMemory)) {

            let foundAllIncommingInMemoryItem = true;

            let scollbackMemory_itemUrlQS = scollbackMemory_itemKey.split("&");

            for (let searchUrlQS of searchUrlQSAll) {
                if (scollbackMemory_itemUrlQS.indexOf(searchUrlQS) != -1) {
                    //Found, still OK
                } else {
                    //Not found, not OK
                    foundAllIncommingInMemoryItem = false;
                }
            }

            if (foundAllIncommingInMemoryItem) {
                return scollbackMemory[scollbackMemory_itemKey];
            }
        }

        return null;
    }

    let foundSavesScroll = GetScollbackMemory({ url: `${location.search}`, source: `${source} -> Init()` });

    if (foundSavesScroll) {
        window.PGridClientDebugMode >= 3 && console.debug(`Found existing currentSCROLLBACKTOpath in sessionStorage`);
        vuexStore.state.hotRef.scrollViewportTo(foundSavesScroll.row, foundSavesScroll.column, false, false);

        if (foundSavesScroll.clickedCell) {
            setTimeout(function () { //Delay needed to not5 interfere with scroll restore
                vuexStore.state.hotRef.selectCell(foundSavesScroll.clickedCell.y, foundSavesScroll.clickedCell.x);
            }, 0);
        }
    }



    let exTimeMS = (new Date().getTime() - exStart.getTime());

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.js Init(): took ${exTimeMS} ms`);

    return;
}


export function Destroy({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Destroy() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.destroy();
        // Handsontable.hooks.destroy();
        state.hotRef = null;
    }
}

export function Clear({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Clear() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.clear();
    }
}


export function Deselect({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Deselect() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.deselectCell();
    }
}

export function Update({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Update() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        console.debug(`pgridHT.Update() data source: ${source}`);

        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 1, source: `pgridHT.Init()` });

        Destroy({ source: `${source} > pgridHT.js Update()` });
        Init({ source: `${source} > pgridHT.js Update()` });

        //TEST vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 0, source: `pgridHT.Init()` });
    }
    else {
        console.warn(`pgridHT.Update() hotRef is null? source: ${source}`);
    }
}


export function ReConfig(context, { configUpdate = null, source = "unknown source for ReConfig()" }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.ReConfig() source: ${source}`);

    let state = context.state;

    if (state.hotRef != null) {
        state.hotRef.updateSettings(configUpdate);
    }
    else {
        console.warn(`pgridHT.ReConfig() hotRef is null? source: ${source}`);
    }
}


export function Redraw(context, { source }) {
    window.PGridClientDebugMode >= 2 && console.debug(`pgridHT.Redraw() source: ${source} -> pgridHT.Init()`);
    if (context.state.hotRef == null) {
        Init({ source: `${source} -> pgridHT.Redraw()` });
    } else {
        setTimeout(async () => {
            await PGridUtils.sleep(1);
            context.state.hotRef.render();
        }, 0);
    }
}



function FakeAction_ApplyLRPgrid_OnFirstCell(context, { source = "unknown" }) {
    try {

        let lrPGridSettings = lodash.get(context, "state.pgridSettings.LRPGridSettingsCollection", {});

        let lrPGridSettingsItem = lrPGridSettings;
        // lrPGridSettings.forEach(lrPGridSettingsItem => {
        {
            let lrPGridSettingsCol = lodash.get(lrPGridSettingsItem, "Columns", {});

            let lrPGridSettingsCol_Keys = Object.keys(lrPGridSettingsCol);

            for (let i = 0; i < lrPGridSettingsCol_Keys.length; i++) {

                let tableColIdx = -1;
                let colIdx_Key = lrPGridSettingsCol_Keys[i];

                if (PGridUtils.IsNumeric(colIdx_Key)) {
                    tableColIdx = Number(colIdx_Key);
                    if (tableColIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() Number(): Illegal column key: ${colIdx_Key}`)
                    }
                } else {
                    tableColIdx = columnLabelToIndex(colIdx_Key);
                    if (tableColIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() columnLabelToIndex(): Illegal column key: ${colIdx_Key}`)
                    }
                }

                let fixedColWidth = lodash.get(lrPGridSettingsCol[colIdx_Key], "FixWidth", null);
                if (fixedColWidth) {

                    // By setting the width property on the first row, an calculation error for the HT
                    // GhostTable width calculation gets avioded. Whish could lead to unwhanted horizontal
                    // Scrolling
                    // See: Handsontable > autoColumnSize > calculateColumnsWidth()
                    if (context.state.hotRef) {
                        context.state.hotRef.setCellMeta(0, tableColIdx, 'width', parseInt(fixedColWidth));
                    }
                }


                // "LRPGridSettings": {
                //     "Columns": {
                //         "F": {
                //             "MinWidth": 30,
                //             "MinWidthRepeatChar": ".",  //Default '-'
                //         }
                //     }
                // },
            }

            /*
            let lrPGridSettingsRow = lodash.get(lrPGridSettingsItem, "Rows", {});

            let lrPGridSettingsRow_Keys = Object.keys(lrPGridSettingsRow);

            for (let i = 0; i < lrPGridSettingsRow_Keys.length; i++) {

                let tableRowIdx = -1;
                let rowIdx_Key = lrPGridSettingsRow_Keys[i];

                if (PGridUtils.IsNumeric(rowIdx_Key)) {
                    tableRowIdx = Number(rowIdx_Key);
                    if (tableRowIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() Number(): Illegal row key: ${rowIdx_Key}`)
                    }
                } else {
                    tableRowIdx = rowLabelToIndex(rowIdx_Key);
                    if (tableRowIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() rowLabelToIndex(): Illegal row key: ${rowIdx_Key}`)
                    }
                }

            }
            */
        }//)

    } catch (err) {
        console.error(`FakeAction_ApplyLRPgrid_OnFirstCell: got exception: ${err.message}`);
    }
}



export default {
    Init,
    Destroy,
    Clear,
    Update,
    Redraw,
    ReConfig,
    HOTEventHandlers
}