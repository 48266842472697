import PGridMatrices from './pgridMatrices.js'
import PGridUtils from './pgridUtils.js'
import PGridBackendAccess from './pgridBackendAccess.js'
import lodash from 'lodash'

// import PGridCell, { Insert_Calculated_Cell, Get_Val_For_FormulaParser, Get_PGridCell_Value, expandIfNeeded, MaybieCopyObj } from './pgridCell.js'

export async function Handle_FilterMounted(context) {


  try {
    false && console.debug("DEBUG_STEP FILTERS mounted - viewMode: " + context.state.pgridSettings.viewMode);

    /*
     * Apply back button
     */
    setTimeout(() => {
      let backButtonTab = document.querySelectorAll("button.pgBackButton");

      if (backButtonTab.length == 0) {


        let backButton = document.createElement("button");
        backButton.className = "pgBackButton ms-Button stack-inline ms-Button--primary noprint";
        backButton.title = "Backa ett steg";

        backButton.innerHTML =
          '<svg version="1.1" fill="#FFFFFF" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="24" width="24" viewBox="0 0 120.64 122.88" style="enable-background:new 0 0 120.64 122.88" xml:space="preserve"><g><path d="M66.6,108.91c1.55,1.63,2.31,3.74,2.28,5.85c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12c-1.58,1.5-3.6,2.23-5.61,2.2 c-2.01-0.03-4.02-0.82-5.55-2.37C37.5,102.85,20.03,84.9,2.48,67.11c-0.07-0.05-0.13-0.1-0.19-0.16C0.73,65.32-0.03,63.19,0,61.08 c0.03-2.11,0.85-4.21,2.45-5.8l0.27-0.26C20.21,37.47,37.65,19.87,55.17,2.36C56.71,0.82,58.7,0.03,60.71,0 c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76c0.03,2.1-0.73,4.22-2.28,5.85L19.38,61.23L66.6,108.91 L66.6,108.91z M118.37,106.91c1.54,1.62,2.29,3.73,2.26,5.83c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12 c-1.57,1.5-3.6,2.23-5.61,2.21c-2.01-0.03-4.02-0.82-5.55-2.37C89.63,101.2,71.76,84.2,54.24,67.12c-0.07-0.05-0.14-0.11-0.21-0.17 c-1.55-1.63-2.31-3.76-2.28-5.87c0.03-2.11,0.85-4.21,2.45-5.8C71.7,38.33,89.27,21.44,106.8,4.51l0.12-0.13 c1.53-1.54,3.53-2.32,5.54-2.35c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76 c0.03,2.1-0.73,4.22-2.28,5.85L71.17,61.23L118.37,106.91L118.37,106.91z"/></g></svg>';

        backButton.onclick = function (e) {
          // onclick stuff

          let warnUnsavedFacts_BeforeContinue = false;
          if (context.state.pgridSettings.hasUnsavedFactChangesForUrl != null) {
            warnUnsavedFacts_BeforeContinue = confirm("Vill backa utan att spara ändrade värden?");

            if (warnUnsavedFacts_BeforeContinue) {
              context.commit("Mutation_UpdatePGridSettings", {
                prop: "hasUnsavedFactChangesForUrl",
                val: null,
                source: `ConfirmBackNavigation()`,
              });

              window.history.back();
            }
          } else {
            window.history.back();
          }
        };

        let appendHere = document.querySelector("div.pgrid-filter-list");
        if (appendHere) {
          appendHere.insertBefore(backButton, appendHere.firstChild);
        }
      }
    }, 100);

    let initTimestamp = null;
    initTimestamp = Date.now(); //mili seconds
    let initUrl = null;
    initUrl = window.location.href;

    try {
      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Timestamp",
        val: initTimestamp,
        source: `Handle_FilterMounted()`,
      });
      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Url",
        val: initUrl,
        source: `Handle_FilterMounted()`,
      });


      await context.dispatch("Action_Stage_Filter", { initTimestamp: initTimestamp, initUrl: initUrl, dontLoadGrid: false, source: `Handle_FilterMounted() 2` });
    } catch (err) {
      console.debug(`Filter fetch problem`, err);

      for (let i = 0; i < context.state.pgridFilter.Filters.length; i++) {
        context.state.pgridFilter.Filters[i].filterIsLoading = false;
      }
      context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_FilterMounted()` });
      context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `Handle_FilterMounted()` });

      return;
    }

    try {
      await context.dispatch("Action_Stage_Grid", {
        initTimestamp: initTimestamp,
        initUrl: initUrl,
        render: true,
        source: `Handle_FilterMounted() 3`,
      });
    } catch (err) {
      let prettifyMsg = PGridUtils.beautifyErrorMessage(`${err.stack || err.messge || err}`);

      let errMsg = `Fel uppstod vid laddning av grid: \n\n${prettifyMsg}`;
      if (context.state.pgridSettings.initOfLastQuery_Timestamp == initTimestamp) {
        alert(errMsg);
      }
      return;
    }
  } catch (err) {
    let errMsg = `> Handle_FilterMounted() got exception: ${err.message || err}`;

    alert(errMsg);

    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_FilterMounted()` });
    context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `Action_Stage_Filter()` });

  }

}


export async function Handle_Action_Load_FactsFromDBToGrid(context, { initTimestamp, initUrl, source = `<Handle_Action_Load_FactsFromDBToGrid got no source>` }) {

  try {
    window.PGridClientDebugMode >= 3 && console.debug(`Handle_Action_Load_FactsFromDBToGrid()`);
    if (PGridUtils.Is_DynamicMode(context.state)) {

      let pgridFilter = context.state.pgridFilter;
      let pgridVars = context.state.pgridVars;

      //Get current pgriddata
      let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({
        state: context.state, commit: context.commit, source: `${source} -> Handle_Action_Load_FactsFromDBToGrid`
      });

      let pgridDataBuffer = lodash.clone(pgridData);
      // const foundLRs = Get_LRsFromTable(pgridData)

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid Get_CurrentPGridData, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: true, source: `Handle_Action_Load_FactsFromDBToGrid() -> ${source}` });

      let pgridFilterSelection = PGridUtils.Filter_ExtractPGridFiltersSelection(pgridFilter, true);

      let varsAndFilters_UniqueAggrByAssocLR = [];

      const foundLRs = PGridMatrices.Get_LRsFromTable(pgridDataBuffer, { parseLR: true, source: `${source} -> Handle_Action_Load_FactsFromDBToGrid()` }); // Get all linked ranges

      //Check if all LR are here

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid foundLRs, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      // Aprox 300 ms

      // FUB: Called two times; in Handle_Action_Load_FactsFromDBToGrid, for calculation of column sized -- then in Action_Stage_Grid, for hiding rows. This may generate dublicate CSS rules and is sub optimal
      await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Handle_Action_Load_FactsFromDBToGrid()` });


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid Action_ApplyLRPgridCss, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      let foundLRs_Keys = Object.keys(foundLRs);


      for (let i = 0; i < lodash.get(foundLRs_Keys, "length", 0); i++) {
        let lr = foundLRs[foundLRs_Keys[i]];

        let pgridVarsCpy = lodash.clone(pgridVars);
        delete pgridVarsCpy.GridDefinition;

        let pgridFilterSelectionCpy = lodash.clone(pgridFilterSelection);
        let pgridOptionalCellFilters = null;

        let overrideGridKey = lr.overrideGridKey;
        let overrideTabKey = lr.overrideTabKey;
        let overrideRemoveOrTranslateCellKeyDims = lodash.get(lr, "overrideRemoveOrTranslateCellKeyDims", []);
        if (overrideRemoveOrTranslateCellKeyDims == null) {
          overrideRemoveOrTranslateCellKeyDims = [];
        }

        if (overrideGridKey != null) {
          pgridVarsCpy.GridKey = overrideGridKey;
        }
        if (overrideTabKey != null) {
          pgridVarsCpy.TabKey = overrideTabKey;
        }

        // pgridVarsCpy.AssociatedLR = lr.name; //must match with an LR name when recived
        let associatedLR = lr.name;
        // if (overrideRemoveOrTranslateCellKeyDims != null) {
        [pgridFilterSelectionCpy, pgridOptionalCellFilters] = PGridUtils.HandleDimensionOverrides(context, lr, pgridFilterSelectionCpy, overrideRemoveOrTranslateCellKeyDims);
        // }

        let strVarsAndFilts = JSON.stringify({ vars: pgridVarsCpy, filters: pgridFilterSelectionCpy, postFilterCell: pgridOptionalCellFilters });

        let strVarsAndFilts_existing = varsAndFilters_UniqueAggrByAssocLR.find(o => o.strVarsAndFilts == strVarsAndFilts)

        if (strVarsAndFilts_existing == null) {
          //No existing found, create nuew
          varsAndFilters_UniqueAggrByAssocLR.push({ strVarsAndFilts: strVarsAndFilts, associatedLRs: [associatedLR] });
        } else {
          //Add AccociatedLR to this 
          window.PGridClientDebugMode >= 2 && console.debug(`Handle_Action_Load_FactsFromDBToGrid adding associatedLR ${associatedLR} to existing uniqueStrVarsAndFilts`);
          strVarsAndFilts_existing.associatedLRs.push(associatedLR);
        }
      }

      let varsAndFiltsFinal = [];
      //Add associatedLR to varsAndFilters_UniqueAggrByAssocLR
      varsAndFilters_UniqueAggrByAssocLR.forEach(o => {
        let varsAndFiltsFinal_Item = JSON.parse(o.strVarsAndFilts);
        varsAndFiltsFinal_Item.vars["AssociatedLR"] = o.associatedLRs.join(',');
        varsAndFiltsFinal.push(varsAndFiltsFinal_Item);
      });

      let recivedFacts = []

      //Query backend in parelelle
      //Returns: list of facts

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid strVarsAndFilts, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      let foo2 = "bar";

      await Promise.all(varsAndFiltsFinal.map(async varsAndFilters => {

        let foo3 = "bar";

        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
          window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid varFilt map, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
          return;
        }


        // let varsAndFilters = JSON.parse(varFilt);
        let { query, variables } = PGridBackendAccess.Phase5_Get_FactsBackendQuery(varsAndFilters.vars, varsAndFilters.filters, varsAndFilters.postFilterCell);

        let factData = await PGridBackendAccess.Generic_Fetch_Fact({ query, variables });

        // //Add AssociatedLR to the recived factData. Could also be part of the query for easy debugging but is not for now.
        // factData.AssociatedLR = varsAndFilters.vars.AssociatedLR;

        recivedFacts.push(factData);
      }));


      // 
      let pgridDataHT = PGridUtils.Get_HOTUncalcedDataAndSettings(context, {
        source: `${source} -> Handle_Action_Load_FactsFromDBToGrid`,
        pgridDataToUse: pgridDataBuffer
      });
      let hotDataBuffer = pgridDataHT.data; //FUB -> the result 
      let hotDataBufferSpanCells = pgridDataHT.spanCells;  // FUB? Can this be calculated later, or in another order to also include overlay items?


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid pgridDataHT, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      // Insert facts into HOT and PGridTable 
      // recivedFacts.forEach(factData => {
      for (let f = 0; f < recivedFacts.length; f++) {
        let factData = recivedFacts[f];

        if (factData == null) {
          console.warn(`Handle_Action_Load_FactsFromDBToGrid() factData == null ({f})`);
          continue
        }

        if (!"AssociatedLR" in factData) {
          console.warn(`Missing AssociatedLR in factData`);
          continue;
        }


        for (let i = 0; i < foundLRs_Keys.length; i++) {
          let lr = foundLRs[foundLRs_Keys[i]];


          if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid 1 lr.name: ${lr.name}, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
          }

          let shouldProcess = false;



          // if (shouldProcess) {
          // if ("AssociatedLR" in factData) {

          // if (factData.GridKey == (lr.overrideGridKey != null ? lr.overrideGridKey : pgridVars.GridKey)) {
          //   shouldProcess = true;
          // }


          if (factData.AssociatedLR.split(',').indexOf(lr.name) != -1) {
            shouldProcess = true;
          }



          // }
          // }

          if (shouldProcess) {
            // if (lr.filterKeyConstrains) {

            //   let filterKeyConstrainsKeys = Object.keys(lr.filterKeyConstrains)
            //   for (let d = 0; d < filterKeyConstrainsKeys.length; d++) {

            //     let checkDimName = filterKeyConstrainsKeys[d];
            //     let checkDimVals = lr.filterKeyConstrains[filterKeyConstrainsKeys[d]];

            //     if ("MustH ave" in checkDimVals) {

            //       let unknownstuff = factData;

            //     }
            //   }

            // }
          }

          if (shouldProcess) {


            let StartTime_Phase6_Generate_FactRange = Date.now();

            let factRangeData = lr.Phase6_Generate_FactRange(pgridDataBuffer, factData);

            if (window.PGridClientDebugMode >= 2) {
              console.debug(`[Phase6_Generate_FactRange] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase6_Generate_FactRange) / (100)))} 10th: s`); // in seconds
            }

            let pgridTable_Recurse_Before = null;
            if (window.PGridClientDebugMode >= 4) {
              pgridTable_Recurse_Before = JSON.stringify(pgridDataBuffer);
            }


            let StartTime_Phase7_Insert_FactRange = Date.now();

            let pgridDataInserted, hotTableInserted;

            if (factRangeData) {


              // let { pgridDataInserted, hotTableInserted /*Implement this later*/ } = lr.Phase7_Insert_FactRange(pgridDataBuffer, hotDataBuffer, factRangeData);
              let phase7_ret = /*Implement this later*/ lr.Phase7_Insert_FactRange(pgridDataBuffer, hotDataBuffer, factRangeData);
              pgridDataInserted = phase7_ret.pgridDataInserted;
              hotTableInserted = phase7_ret.hotTableInserted;

              if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid 2 lr.name: ${lr.name}, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                return;
              }
            } else {
              console.warn(`factRangeData is null, not inserting facts`);
            }



            if (window.PGridClientDebugMode >= 2) {
              console.debug(`[Phase7_Insert_FactRange] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase7_Insert_FactRange) / (100)))} 10th: s`); // in seconds
            }

            // if (window.PGridClientDebugMode >= 3) {
            //   PGridUtils.DebugSomePGridTableChanges(pgridDataBuffer, pgridDataInserted);
            // }

            if (window.PGridClientDebugMode >= 4) {
              PGridUtils.DebugDetectTableChanges(`spinningTreeNet() Phase7_Insert_FactRange LR:${lr.name}`, pgridDataBuffer, pgridTable_Recurse_Before);
            }

          }
        }
      }
      // });

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid pgridDataHT, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      //Insert overlay content
      for (let i = 0; i < foundLRs_Keys.length; i++) {
        let lr = foundLRs[foundLRs_Keys[i]];

        let StartTime_Phase8_Insert_Overlay = Date.now();


        let pgridDataBufferBefore = null;
        if (window.PGridClientDebugMode >= 3) {
          pgridDataBufferBefore = JSON.parse(JSON.stringify(pgridDataBuffer));
        }

        let { pgridDataInserted, hotTableInserted } = lr.Phase8_Insert_Overlay(pgridDataBuffer, hotDataBuffer, context, "initEvent" /* "initEvent", "selectionchangeEvent", "postcalcEvent" */);

        pgridDataBuffer = pgridDataInserted;
        hotDataBuffer = hotTableInserted

        if (window.PGridClientDebugMode >= 3) {
          PGridUtils.DebugDetectTableChanges(`lr.Phase8_Insert_Overlay()`, pgridDataBuffer, pgridDataBufferBefore);
        }


        if (window.PGridClientDebugMode >= 2) {
          console.debug(`[Phase8_Insert_Overlay] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase8_Insert_Overlay) / (100)))} 10th: s`); // in seconds
        }
      }


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid before pgridDataDynContent, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      //FUB please only update factData in HOT, not update whole  ---- //This needs some work, disabling
      context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `${source} -> Get_CurrentPGridData` });

      //Updating spanCells
      context.commit('Mutation_UpdateHOTSettings', { prop: 'spanCells', val: hotDataBufferSpanCells, source: `${source} -> Action_UpdateHOT_DataAndSettings` });

      // await context.dispatch('Action_UpdateHOT_DataAndSettings', {
      //   source: `${source} -> Action_Load_FactsFromDBToGrid`,
      //   pgridDataHT: pgridDataHT
      // });

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid after pgridDataDynContent, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


    }
    else {
      throw new Error("Can only load in Default mode. source: " + source);
    }
  } catch (ex) {
    console.error(`Action_Load_FactsFromDBToGrid() got exception: ${ex.stack || ex}`);
    if (!context.state.hotRef) { //Only show error on first load
      alert(`Action_Load_FactsFromDBToGrid() got exception: ${ex.message || ex}`);
    }
    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Action_Load_FactsFromDBToGrid()  got exception` });
  }
  context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_Action_Load_FactsFromDBToGrid()` });
}

export default {
  Handle_FilterMounted,
  Handle_Action_Load_FactsFromDBToGrid,
}