/**
 * Convert row label to index.
 *
 * @param {String} label Row label (eq. '1', '5')
 * @returns {Number} Returns -1 if label is not recognized otherwise proper row index.
 */
export function rowLabelToIndex(label) {
  let result = parseInt(label, 10);

  if (Number.isNaN(result)) {
    result = -1;
  } else {
    result = Math.max(result - 1, -1);
  }

  return result;
}

/**
 * Convert row index to label.
 *
 * @param {Number} row Row index.
 * @returns {String} Returns row label (eq. '1', '7').
 */
export function rowIndexToLabel(row) {
  let result = '';

  if (row >= 0) {
    result = `${row + 1}`;
  }

  return result;
}

const COLUMN_LABEL_BASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const COLUMN_LABEL_BASE_LENGTH = COLUMN_LABEL_BASE.length;

/**
 * Convert column label to index.
 *
 * @param {String} label Column label (eq. 'ABB', 'CNQ')
 * @returns {Number} Returns -1 if label is not recognized otherwise proper column index.
 */
export function columnLabelToIndex(label) {
  let result = 0;

  if (typeof label === 'string') {
    label = label.toUpperCase();

    for (let i = 0, j = label.length - 1; i < label.length; i += 1, j -= 1) {
      result += Math.pow(COLUMN_LABEL_BASE_LENGTH, j) * (COLUMN_LABEL_BASE.indexOf(label[i]) + 1);
    }
  }
  --result;

  return result;
}

/**
 * Convert column index to label.
 *
 * @param {Number} column Column index.
 * @returns {String} Returns column label (eq. 'ABB', 'CNQ').
 */
export function columnIndexToLabel(column) {
  let result = '';

  while (column >= 0) {
    result = String.fromCharCode((column % COLUMN_LABEL_BASE_LENGTH) + 97) + result;
    column = Math.floor(column / COLUMN_LABEL_BASE_LENGTH) - 1;
  }

  return result.toUpperCase();
}

const LABEL_EXTRACT_REGEXP = /^([$])?([A-Za-z]+)([$])?([0-9]+)$/;
const LABEL_PGRID_RELATIVE_CELL_EXTRACT_REGEXP = /^([R])([\-\+]?[0-9]+)([C])([\-\+]?[0-9]+)$/;

/**
 * Extract cell coordinates.
 *
 * @param {String} label Cell coordinates (eq. 'A1', '$B6', '$N$98', 'C1R3').
 * @returns {Array} Returns an array of objects.
 */
export function extractLabel(label, sourceCell = null) {

  let ret = null;

  if (LABEL_PGRID_RELATIVE_CELL_EXTRACT_REGEXP.test(label)) {
    //PGRID relativistic syntax

    if (sourceCell === null) {
      throw new Error('SRCREF');
    }

    const [, row_r, row, column_c, column] = label.toUpperCase().match(LABEL_PGRID_RELATIVE_CELL_EXTRACT_REGEXP);

    let srcRow = 0;
    let srcCol = 0;

    if (sourceCell !== null) {
      srcRow = sourceCell[0].index;
      srcCol = sourceCell[1].index;
    }

    ret = [
      {
        index: srcRow + parseInt(row),
        isAbsolute: false
      },
      {
        index: srcCol + parseInt(column),
        isAbsolute: false
      },
    ];

    
    // console.debug(`label: ${label} => ${JSON.stringify(ret)}`);



    ret[0].label = toLabelRow(ret[0]);
    ret[1].label = toLabelColumn(ret[1]);

  }
  else {
    if (typeof label !== 'string' || !LABEL_EXTRACT_REGEXP.test(label)) {
      return [];
    }
    const [, columnAbs, column, rowAbs, row] = label.toUpperCase().match(LABEL_EXTRACT_REGEXP);

    ret = [
      {
        index: rowLabelToIndex(row),
        label: row,
        isAbsolute: rowAbs === '$',
      },
      {
        index: columnLabelToIndex(column),
        label: column,
        isAbsolute: columnAbs === '$',
      },
    ];
  }



  if (ret[0].index < 0 || ret[1].index < 0) {
    throw new Error('REF');
  }

  return ret;
}

/**
 * Convert column indexes into column cell label.
 *
 * @param {Object} column Object with `index` and `isAbsolute` properties.
 * @returns {String} Returns cell label.
 */
export function toLabelColumn(column) {
  const columnLabel = (column.isAbsolute ? '$' : '') + columnIndexToLabel(column.index);
  return columnLabel
}

/**
 * Convert row indexes into row cell label.
 *
 * @param {Object} row Object with `index` and `isAbsolute` properties.
 * @returns {String} Returns cell label.
 */
export function toLabelRow(row) {
  const rowLabel = (row.isAbsolute ? '$' : '') + rowIndexToLabel(row.index);
  return rowLabel;
}

/**
 * Convert row and column indexes into cell label.
 *
 * @param {Object} row Object with `index` and `isAbsolute` properties.
 * @param {Object} column Object with `index` and `isAbsolute` properties.
 * @returns {String} Returns cell label.
 */
export function toLabel(row, column) {
  return toLabelColumn(column) + toLabelRow(row);
}

