// var PGridHandsonTable = require("./pgridHandsonTable");
// var PGridUtils = require("./pgridUtils");
// var lodash = require("lodash");


import { PGridStart } from "./pgridHandsonTable.js";
import PGridUtils from "./pgridUtils.js";
import lodash from "lodash";
import PGridHT from './pgridHT.js';

// import Vue from 'vue';

import cycle from '../../lib/cycle/cycle.js'


const SUPERWISE_MOUNT_RETRY_DELAY = 333; //MS

export async function superwise(app, config) {

    function redrawHOT() {

        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }

        if (context.state.pgridfiltersRef) {
            context.state.pgridfiltersRef.handleResize(null);
        }


        if (context.state && context.state.hotRef) {
            context.dispatch("Action_Redraw", { source: `redrawHOT()` });
        }
    }

    var doRedrawDelayOnResize = null;
    addEventListener("resize", async (event) => {

        window.PGridClientDebugMode >= 2 && console.debug(`resize event: ${JSON.stringify(event)}`);
        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }


        clearTimeout(doRedrawDelayOnResize);
        doRedrawDelayOnResize = setTimeout(redrawHOT, 500);
    });


    window.addEventListener('popstate', async (event) => {

        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }

        console.debug("popstate: document.location.href: " + document.location.href + ", state: " + JSON.stringify(event.state));

        // if (event.state) {
        //     console.error("no event.state");
        // }

        let popStateUrl = lodash.get(event, "state.path", null);

        // if ("path" in event.state) {

        if (popStateUrl) {

            let searchPart = popStateUrl.split('?');
            if (searchPart.length > 1) {

                setTimeout(async () => { //This avoid marking cells
                    if (PGridUtils.ConfirmNavigation(context, `popstate searchPart[1]: ${searchPart[1]}`, "Vill du ändå byta flik?")) {
                        window.PGridClientDebugMode >= 1 && console.debug(`popstate: popstate event go: ${'?' + searchPart[1]}`);
                        PGridUtils.updateTabLinks('?' + searchPart[1], `popstate event go`);
                        await PGridUtils.sleep(100);
                        await PGridUtils.ReOrLoadInitialCustomerTabKey();
                    } else {
                        let standStilPath = document.location.href.split('?');
                        window.PGridClientDebugMode >= 1 && console.warn(`popstate: popstate event no go  standStilPath[1]: ${standStilPath[1]}`);
                        await PGridUtils.sleep(100);
                        if (standStilPath.length > 1) {
                            PGridUtils.updateTabLinks('?' + standStilPath[1], `popstate event no go`);
                        }
                    }
                }, 0);
            }
        } else {
            let standStilPath = document.location.href.split('?');
            window.PGridClientDebugMode >= 1 && console.warn(`popstate: event is null standStilPath[1]; ${standStilPath[1]}`);
            await PGridUtils.sleep(100);
            if (standStilPath.length > 1) {
                PGridUtils.updateTabLinks('?' + standStilPath[1], `popstate event == null`);
                await PGridUtils.ReOrLoadInitialCustomerTabKey();
            }
        }
        // }
    });

    window.addEventListener('beforeunload', async (event) => {

        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }

        window.PGridClientDebugMode >= 1 && console.debug("beforeunload: location.href: " + document.location.href);

        let searchPart = document.location.href.split('?');
        if (searchPart.length > 1) {

            // if (PGridUtils.ConfirmNavigation(context)) {
            if (PGridUtils.ConfirmNavigation(context, `beforeunload event.state: ${JSON.stringify(event.state)}`, "Vill du ändå byta flik?")) {
                await PGridUtils.sleep(100);
                PGridUtils.updateTabLinks('?' + searchPart[1], `beforeunload`);
                await PGridUtils.sleep(100);
                await PGridUtils.ReOrLoadInitialCustomerTabKey();
            } else {

            }
        }
    });


    window.addEventListener('keydown', async (e) => {

        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }

        false && console.debug(`keyCode ${e.keyCode} shiftKey: ${e.shiftKey}`);

        // context.state.pgridSettings.lastKeyStrokeShiftWasPressed = e.shiftKey;
        if (context.state.pgridSettings.lastKeyStrokeShiftWasPressed != e.shiftKey) {
            context.commit('mutation_updatePgridSettings', { "prop": "lastKeyStrokeShiftWasPressed", "value": e.shiftKey });
        }

        switch (e.keyCode) {
            // //ESC
            case 27:
                context.commit('Mutation_UpdatePGridSettings', { "prop": "showPanel", "val": false });
                break;

            //F7
            case 118:
                await context.dispatch("Action_SwitchEditMode", { source: `window.addEventListener('keydown') F7` });
                break;
            //Fallback to default browser behaviour

            case 76: // L
                if (e.altKey) {
                    console.log(`Alt + L was pressed!`);
                    await context.dispatch("Action_InjectAndRendrFilters", { source: "source: `superwise.s keydown() L" });
                    console.log(`Alt + L was pressed! done`);
                }
                break;
            case 80: //p
                if (e.altKey) {

                    const exStart = new Date();

                    console.log(`Alt + P was pressed!`);
                    PGridHT.Destroy({ source: `superwise.s keydown() P` });
                    await PGridHT.Init({ source: `superwise.s keydown() P` });
                    console.log(`Alt + P was pressed! END`);

                    let exTimeMS = (new Date().getTime() - exStart.getTime());
                    console.log(`superwise.s keydown() P "print" took ${exTimeMS} ms`);
                }
                break;

            default:
                return true;
        }

        //Returning false overrides default browser event 
        return false;
    });



    if (window.PGridClientDebugMode >= 3) {
        if (!("PGridDTC" in window)) {
            window.PGridDTC = { FindChangeAfterFunc: null, FindChangeAfterBeforeFunc: null, DimValY: null, DimValX: null, PropNameBefore: null, PropNameAfter: null, PropValBefore: null, PropValAfter: null, CallCount: 0 };
        }
    }

    // if ("PGridDTC" in window) {
    //     window.PGridDTC.CallStopAt = 500;

    // }

    // window.PGridDTC = {
    //     DimValY: "kkg1-3",
    //     DimValX: "HyresIntakt1-Col9",
    //     PropNameBefore: "Meta.Save.Writable",
    //     PropNameAfter: "Meta.Save.Writable2",
    //     PropValBefore: false,
    //     PropValAfter: true,
    //     CallCount: 0,
    //     CallStopAt: 1967 
    // };

    window.PGridDTC = {

        /*
                FindChangeAfterBeforeFunc: (tableAfterIn, pgridTable_Recurse_Before_Str_In = null) => {
                    let ret = false;
        
                    if (pgridTable_Recurse_Before_Str_In) {
                        if (tableAfterIn.length != pgridTable_Recurse_Before_Str_In.length) {
        
                            let diff = PGridUtils.Diff_PgridData(tableAfterIn, pgridTable_Recurse_Before_Str_In);
        
                            if (Object.keys(diff.added).length > 0) {
                                console.debug(`\n\nFindChangeAfterBeforeFunc() CallCount: ${window.PGridDTC.CallCount}`, diff);
                                ret = true
                            }
                        }
                    }
        
                    return ret;
                },
        
        */

        FindChangeAfterFunc: tableAfterIn => {

            let ret = false;

            if (tableAfterIn) {
                // if (JSON.stringify(tableAfterIn).indexOf("HyresIntakt1-Objekt-") != -1) {
                if (JSON.stringify(tableAfterIn).indexOf("HyresIntakt1-Vakant-") != -1) {
                    ret = true;
                }
            }

            return ret;
        },

        // DimValY: "HyresIntakt1-Objekt",
        // DimValX: "HyresIntakt1-Col0",
        // // PropNameBefore: null,
        // PropNameAfter: "Meta.Save.Writable",
        // // PropValBefore: false,
        // PropValAfter: true,
        CallCount: 0,
        CallStopAt: null
    };



    const pgrid = await PGridStart(config); //FUB This always load, regarding if PGrid is used, can be optimized!


    if (window.location.href.indexOf("/grid") != -1) {
        await PGridUtils.ReOrLoadInitialCustomerTabKey(SUPERWISE_MOUNT_RETRY_DELAY, `pgridSuperwise.js superwise()`);
    }



    if (lodash.has(app, "ports.plannicaGridApplicationClick")) {

        app.ports.plannicaGridApplicationClick.subscribe((newUrl) => {

            window.PGridClientDebugMode >= 2 && console.debug(`->>>>>>>>>>>>>>>>>>>>>>>> app.ports.plannicaGridApplicationClick.subscribe(${JSON.stringify(newUrl)});`)
            PGridUtils.HandlePlannicaGridUrlUpdates(newUrl);

            if ((new URL(newUrl).pathname.indexOf("/grid") === 0)) {

                if (lodash.get(window, "PGridVueStore.state.mimicaAppSettings.closeDrawerOnClick", false)) {
                    window.PGridClientDebugMode >= 2 && console.debug(`->>>>>>>>>>>>>>>>>>>>>>>> app.ports.plannicaGridReciveToggleDrawerClose is called});`)

                    app.ports.plannicaGridReciveToggleDrawerClose.send(true);
                }
            }

        });
    }

    let doRedrawDelayOnDrawerToggle = null;
    if (lodash.has(app, "ports.plannicaGridToggleDrawerClick")) {

        let context = lodash.get(window, "PGridVueStore", null);
        if (!context) {
            return;
        }

        app.ports.plannicaGridToggleDrawerClick.subscribe(async (event) => {

            if (window.PGridClientDebugMode >= 2) {
                console.debug(`plannicaGridToggleDrawerClick() event: ${JSON.stringify(event)}`);
            }
            clearTimeout(doRedrawDelayOnDrawerToggle);
            doRedrawDelayOnDrawerToggle = setTimeout(redrawHOT, 500);
        });
    }
}

export async function ReOrLoadInitialCustomerTabKey(initInterv = null) {
    await PGridUtils.ReOrLoadInitialCustomerTabKey(initInterv);
}

export default {
    superwise,
    ReOrLoadInitialCustomerTabKey
}

// module.exports = {
//     superwise,
//     ReOrLoadInitialCustomerTabKey

// }
