import { GraphQLClient } from 'graphql-request';
import lodash from 'lodash'
import PGridUtils from './pgridUtils'

export async function QueryPGridGetGrid(variables) {

    try {
        const query = `
    query queryPGridGetGrid($CustomerKey: String, $TabKey: String, $GridKey: String)
    {
        PGridGetGrid(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey) 
        {
            UserKey
            UserFullName
            UserEmail
            RoleKey
            RoleName
            CustomerKey
            CustomerName
            ServiceKey
            ServiceName
            ApplicationKey
            ApplicationName
            TabKey
            TabName
            GridKey
            GridName
            GridDescription
            CustomerKey
            DatabaseKey
            GridDefinition
            ClientDebugMode
        }
    }
`
        var qlUrl = '/graphql';
        if (variables.hasOwnProperty("baseUrl")) {
            qlUrl = variables.baseUrl + qlUrl;
        }

        const client = new GraphQLClient(qlUrl, {
            headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") }
        });

        let requestQueryPGridGetGrid;
        try {
            requestQueryPGridGetGrid = await client.request(query, variables);
        } catch (err) {
            let errMsg = `> QueryPGridGetGrid() requestQueryPGridGetGrid got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestQueryPGridGetGrid;

    } catch (err) {
        let errMsg = `> QueryPGridGetGrid() got error ${err.message || err}`
        throw new Error(errMsg);
    }

}

export async function QueryPGridCustomStyle(variables) {

    try {
        const query = `
        query {
            PGridCustomStyle(CustomerKey: "${variables.CustomerKey}") 
            {
                Css,
                Error
            }
        }
`
        var qlUrl = '/graphql';
        if (variables.hasOwnProperty("baseUrl")) {
            qlUrl = variables.baseUrl + qlUrl;
        }

        const client = new GraphQLClient(qlUrl, {
            headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") }
        });

        let requestQueryPGridCustomStyle;
        try {
            requestQueryPGridCustomStyle = await client.request(query, variables);

        } catch (err) {
            let errMsg = `> QueryPGridCustomStyle() requestQueryPGridCustomStyle got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestQueryPGridCustomStyle;

    } catch (err) {
        let errMsg = `> QueryPGridCustomStyle() got error ${err.message || err}`
        throw new Error(errMsg);
    }

}


function getCookieValueOld(name) {
    var cookieString = RegExp(name + "=[^;]+").exec(document.cookie);
    return decodeURIComponent(!!cookieString ? cookieString.toString().replace(/^[^=]+./, "") : "");
}

export async function QueryPGridData(variables) {
    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })
        const query = `
            query paramsPGridGetGridData($CustomerKey: String, $TabKey: String, $GridKey: String, $VirtualQueryString: String)
            {
                PGridGetGridDataAndDataSets(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, VirtualQueryString: $VirtualQueryString) 
                {
                    GridDataJSON
                    GridDataSetsJSON
                    VirtualQueryString
                    IsCustomerAdmin
                    Error
                }
            }
    `
        let requestQueryPGridData;
        try {


            if (!("GridKey" in variables)) {
                throw "Missing GridKey";
            }

            requestQueryPGridData = await client.request(query, variables);

            let respErr = lodash.get(requestQueryPGridData, "PGridGetGridDataAndDataSets[0].Error", "ErrorReadingError");
            if (respErr) {
                throw new Error(`QueryPGridData() recived results with error: ${respErr}`);
            }
        } catch (err) {
            let errMsg = `> QueryPGridData() requestQueryPGridData got exception ${err.message || err}`
            throw new Error(errMsg);
        }
        return requestQueryPGridData;

    } catch (err) {
        let errMsg = `> QueryPGridData() got exception ${err.message || err}`
        throw new Error(errMsg);
    }
}


export async function SavePGridData(variables, pgridDataRows) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.RowsDataJSON = JSON.stringify(pgridDataRows);

        const query = `
            query paramsPGridSetGridData($CustomerKey: String, $TabKey: String, $GridKey: String, $RowsDataJSON: String, $fieldNames: String)
            {
                PGridSetGridData(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, RowsDataJSON: $RowsDataJSON, fieldNames: $fieldNames) 
                {
                    GridDataJSON
                    Error
                }
            }
    `
        let requestPGridSetGridData;

        try {
            requestPGridSetGridData = await client.request(query, variables)

            if (requestPGridSetGridData.PGridSetGridData != null) {
                if (requestPGridSetGridData.PGridSetGridData[0].Error != null) {
                    throw "requestPGridSetGridData: " + requestPGridSetGridData.PGridSetGridData[0].Error;
                }
            }
        } catch (requestPGridSetGridData_error) {
            throw "requestPGridSetGridData_error: " + requestPGridSetGridData_error
        }

        return requestPGridSetGridData;

    } catch (SavePGridData_error) {
        throw "SavePGridData_error: " + SavePGridData_error
    }
}

export async function SavePGridFact(variables, pgridFilterSelection, pgridFactRows, batchId, timestampMS, saveType = 'normal') {

    try {

        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.FactRowsDataJSON = JSON.stringify(pgridFactRows);
        variables.FilterSelection = JSON.stringify(pgridFilterSelection);
        variables.BatchId = batchId;
        variables.TimestampMS = String(timestampMS);
        const query = `
            query paramsPGridSetFact($CustomerKey: String, $TabKey: String, $GridKey: String, $BatchId: String, $TimestampMS: String, $FilterSelection: String, $FactRowsDataJSON: String)
            {
                PGridSetFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, BatchId: $BatchId, TimestampMS: $TimestampMS, FilterSelection: $FilterSelection, FactRowsDataJSON: $FactRowsDataJSON) 
                {
                    FactDataJSON
                    Error
                }
            }
    `
        let requestPGridSetFact;

        try {
            requestPGridSetFact = await client.request(query, variables)

            if (requestPGridSetFact.PGridSetFact != null) {
                if (requestPGridSetFact.PGridSetFact[0].Error != null) {
                    throw "requestPGridSetFact: " + requestPGridSetFact.PGridSetFact[0].Error;
                }
            }
        } catch (requestPGridSetFact_error) {
            throw "requestPGridSetFact_error: " + requestPGridSetFact_error
        }

        return requestPGridSetFact;

    } catch (SavePGridData_error) {
        throw "SavePGridData_error: " + SavePGridData_error
    }
}

export async function HandlePGridEvent(variables, pgridFilterSelection, pgridEventInputData, batchId, timestampMS, pgridEventType) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })


        let variablesClone = JSON.parse(JSON.stringify(variables));

        variablesClone.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variablesClone.EventInputData = pgridEventInputData;
        variablesClone.FilterSelection = JSON.stringify(pgridFilterSelection);
        variablesClone.BatchId = batchId;
        variablesClone.TimestampMS = String(timestampMS);
        variablesClone.EventType = pgridEventType;
        variablesClone.UserInputUrl = window.location.href;
        delete variablesClone.FactRowsDataJSON;

        const query = `
            query paramsPGridEvent($CustomerKey: String, $TabKey: String, $GridKey: String, $BatchId: String, $TimestampMS: String, $FilterSelection: String, $EventType: String, $EventInputData: String, $UserInputUrl: String)
            {
                PGridEvent(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, BatchId: $BatchId, TimestampMS: $TimestampMS, FilterSelection: $FilterSelection, EventType: $EventType, EventInputData: $EventInputData, UserInputUrl: $UserInputUrl) 
                {
                    EventDataJSON
                    Error
                }
            }`;

        let requestPGridSetFact = null;

        let foo = "bar";

        try {
            requestPGridSetFact = await client.request(query, variablesClone)

            if (requestPGridSetFact.PGridSetFact != null) {
                if (requestPGridSetFact.PGridSetFact[0].Error != null) {
                    throw "requestPGridSetFact: " + requestPGridSetFact.PGridSetFact[0].Error;
                }
            }


        } catch (err) {
            let errMsg = `> HandlePGridEvent() requestPGridSetFact got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridSetFact;

    } catch (err) {
        let errMsg = `> HandlePGridEvent() got error ${err.message || err}`
        throw new Error(errMsg);
    }
}

export async function SavePGridCss(variables) {
    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })

        const query = `
            mutation paramsPGridSetCss($CustomerKey: String, $Css: String) {
                PGridSetCss(CustomerKey: $CustomerKey, Css: $Css)
                {
                    Error
                }
            }
            `;
        let requestPGridSetCss;

        try {
            requestPGridSetCss = await client.request(query, variables)

            if (requestPGridSetCss.Error != null && requestPGridSetCss.Error != "") {
                throw "requestPGridSetCss: " + requestPGridSetCss.Error;
            }

        } catch (err) {
            let errMsg = `> SavePGridCss() requestPGridSetCss got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridSetCss;

    } catch (err) {
        let errMsg = `> SavePGridCss() got error ${err.message || err}`
        throw new Error(errMsg);
    }


}

export async function Generic_Fetch_Fact({ query, variables }) {

    let factData = null;

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })

        let result;

        result = await client.request(query, variables);

        if (result.PGridGetFact != null) {
            if ("Error" in result.PGridGetFact[0]
                && result.PGridGetFact[0].Error == null) {
                try {
                    factData = JSON.parse(result.PGridGetFact[0].FactDataJSON);
                    false && console.debug("Generic_Fetch_Fact factData:", factData);
                } catch {
                    throw new Error(`Generic_Fetch_Fact: Could not parse fact data json`);
                }
            } else {
                let errMsg = `> Generic_Fetch_Fact: got error from graphql: ${result.PGridGetFact[0].Error}`;
                console.error(errMsg);
                throw new Error(errMsg);
            }
        }
    } catch (ex) {
        console.error(`Generic_Fetch_Fact_error - ${ex.message}, stack trace - ${ex.stack}`);
    }

    return factData;
}

export async function Phase5_Get_FactsForSQLTableBackendQuery(variables, pgridFilterSelection, linkedRange) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: localStorage.getItem("customLogin") } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.FilterSelection = JSON.stringify(pgridFilterSelection);
        variables.LinkedRangeJSON = JSON.stringify(linkedRange);

        const query = `
            query paramsPGridGetTableFact($CustomerKey: String, $TabKey: String, $GridKey: String, $FilterSelection: String, $LinkedRangeJSON: String)
            {
                PGridGetTableFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, FilterSelection: $FilterSelection, LinkedRangeJSON: $LinkedRangeJSON) 
                {
                    FactDataJSON
                    Error
                }
            }
    `
        let requestPGridGetTableFact;

        try {
            requestPGridGetTableFact = await client.request(query, variables)

            let respError = lodash.get(requestPGridGetTableFact, "PGridGetFact[0].Error", null);
            if (respError) {
                throw new Error(respError);
            }

        } catch (err) {
            let errMsg = `> Phase5_Get_FactsForSQLTableBackendQuery() requestPGridGetTableFact got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridGetTableFact;

    } catch (err) {
        let errMsg = `> Phase5_Get_FactsForSQLTableBackendQuery() got error ${err.message || err}`
        throw new Error(errMsg);
    }
}

export function Phase5_Get_FactsBackendQuery(pgridVars, FilterSelection, OptionalCellFilters = null) {

    let pgridVarsCopy = JSON.parse(JSON.stringify(pgridVars));

    //Is here now
    let variables = {
        CustomerKey: pgridVarsCopy.CustomerKey,
        TabKey: pgridVarsCopy.TabKey,
        GridKey: pgridVarsCopy.GridKey,
        FilterSelection: JSON.stringify(FilterSelection),
        OptionalCellFilters: OptionalCellFilters == null ? null : JSON.stringify(OptionalCellFilters),
        AssociatedLR: pgridVars.AssociatedLR
    }

    const query = `
            query paramsPGridGetFact($CustomerKey: String, $TabKey: String, $GridKey: String, $FilterSelection: String, $OptionalCellFilters: String, $AssociatedLR: String)
            {
                            PGridGetFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, FilterSelection: $FilterSelection, OptionalCellFilters: $OptionalCellFilters, AssociatedLR: $AssociatedLR) {
                                FactDataJSON
                                Error
                            }
                        }`

    return { query, variables };
}


export default {
    QueryPGridGetGrid
    , QueryPGridData
    , QueryPGridCustomStyle
    , SavePGridData
    , SavePGridFact
    , HandlePGridEvent
    , Generic_Fetch_Fact
    , SavePGridCss
    , Phase5_Get_FactsBackendQuery
}