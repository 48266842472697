'use strict';
import { PublicClientApplication } from "@azure/msal-browser";
const PGridUtils = require("../lib/pgrid/pgridUtils");

require('./styles/main.scss'); // pull in desired CSS/SASS files
require('./index.html'); //so it't get copied to dist
// import * as pbi from 'powerbi-client';
var pbi = require('powerbi-client');

var pgridSuperwise = require('../lib/pgrid/pgridSuperwise');
// vat pgridSuperwise  from './lib/pgrid/pgridSuperwise';

require("core-js/stable");
require("regenerator-runtime/runtime");
// require('@babel--------/polyfill')

require('isomorphic-fetch');

var Elm = require('../elm/Main.elm');


if (window.PGridClientDebugMode >= 1) {
    console.warn("Lang gets hardcoded here!");
}
// var myLang = require("./lib/lang/lang." + "sv-SE"/* + lang*/);

const THRESHOLD_FORCE_IDTOKEN_REFRESH_SEC = 70 * 60; //900; // Force refresh if expire within 15min


window.addEventListener("unhandledrejection", function (event) {

    let stack = null;
    try {
        throw new Error(`Unhandled promise rejection. Reason`)
    } catch (err) {
        // console.log("index.js ERROR: unhandledrejection: " + err.stack);
        stack = err.stack;
    }

    let errMSg = `index.js ERROR: detected unhandledrejection. Reason ${event.reason}`;
    console.error(errMSg, event.reason, stack, event);

    if (window.PGridClientDebugMode >= 2) {
        alert(errMSg);
    }
});

var config = null;

var emptyUser = {
    name: "",
    email: "",
    idToken: "",
    accessToken: "",
    expiresOn: "",
    claims: [],
    tenantId: "",
    accountType: "",
    issuer: "",
};

function getCustomLoginParameter() {
    let result = null;
    result = localStorage.getItem('customLogin');
    if (!result) {
        let path = window.location.pathname;
        result = path[0] == '/' ? path.slice(1, path.length) : path;
    }
    return result;
}

// const b2cScopes = ["openid", "profile", "offline_access"];
const customLoginParameter = getCustomLoginParameter();
//Get mimica configs for the web browser environment
fetch(`mimicaconfig?customLogin=${customLoginParameter}`)
    .then(res => res.json())
    .then(config => initialize(config));


function initialize(config) {

    /**** The main app initialization ****/


    var mountNode = document.getElementById('elm-app');
    var app = Elm.Elm.Main.init({
        node: mountNode,
        flags: {
            'cfgBaseUrl': window.location.origin || config.www.url
            , 'cfgAuthUrl': config.wwwAuth.url
            , 'cfgLoginType': customLoginParameter
            , 'cfgDebugMode': parseInt(config.general.clientDebugMode)
            , 'cfgLogoContainer': config.general.logoContainer
            , 'loginParameters': config.loginParameters
            , 'isMobile': isMobile()
            , 'selectedCustomer': localStorage.getItem('selectedCustomer') || ''
        }
    });

    function isMobile() {
        if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }
        else {
            return false;
        }
    }

    const msalConfig = config.msal;
    msalConfig.auth["redirectUri"] = config.www.url;
    const msalInstance = new PublicClientApplication(msalConfig);

    function handleResponse(response) {
        console.log("handleResponse", response);
        if (response !== null) {
            // Display signed-in user content, call API, etc.

            // Add choose account code here
            let homeAccountId = response.account.homeAccountId;
            localStorage.setItem("homeAccountId", homeAccountId);

            const context = createAuthContext(response);
            app.ports.loginContext.send(context);


            // HandlePGridInit();


            // setTimeout(HandlePGridInit, 2000)


        } else {
            const tokenRequest = {
                scopes: config.scopes
            }
            // In case multiple accounts exist, you can select
            const currentAccounts = msalInstance.getAllAccounts();
            if (currentAccounts.length === 0) {
                // no accounts signed-in, attempt to sign a user in
                localStorage.removeItem("customLogin");
                app.ports.loginContext.send(emptyUser);
            } else if (currentAccounts.length >= 1) {

            }
        }
    }

    msalInstance.handleRedirectPromise().then(handleResponse);


    // setTimeout(HandlePGridInit, 500);
    HandlePGridInit();

    async function HandlePGridInit() {


        let idToken = null;
        const tryGetTokenMaxCount = 20;

        let tryCount = 0;
        while (tryCount < tryGetTokenMaxCount && (idToken === null)) {
            await PGridUtils.sleep(300);
            idToken = await PGridUtils.getIdTokenForGrid();
            tryCount++;
        }

        if (tryCount >= tryGetTokenMaxCount) {
            false && alert(`Faild to get idToken\n\nTry to reload page the page again`);
            return;
        }

        if (idToken && window.location.href.indexOf("/#code=") == -1) {


            //Only load PGrid superwise if not an JWT request
            (window.PGridClientDebugMode >= 2 || true) && console.debug("Only load PGrid superwise if not an JWT request")

            let doAfterloginRedirect = false;
            let afterLoginUrl = new URL(window.location.href);
            let afterLoginRedirectPathname = localStorage.getItem("afterLoginRedirectPathname");
            let afterLoginRedirectSearch = localStorage.getItem("afterLoginRedirectSearch");
            if (afterLoginRedirectPathname) {
                console.debug(`Detected afterLoginRedirectPathname redirecting path to: ${afterLoginRedirectPathname} ${afterLoginRedirectPathname} `);
                afterLoginUrl.pathname = afterLoginRedirectPathname;
                // localStorage.removeItem("afterLoginRedirectPathname");
                doAfterloginRedirect = true;
            }

            if (afterLoginRedirectSearch) {
                console.debug(`Detected afterLoginRedirectPathname redirecting search to: ${afterLoginRedirectSearch}`);
                afterLoginUrl.search = afterLoginRedirectSearch;
                // localStorage.removeItem("afterLoginRedirectSearch");
                doAfterloginRedirect = true;
            }



            if (doAfterloginRedirect) {

                localStorage.removeItem("afterLoginRedirectPathname");
                localStorage.removeItem("afterLoginRedirectSearch");

                if (window.location.href != afterLoginUrl.href) {

                    if (window.PGridClientDebugMode >= 0) {
                        console.debug(`Found afterLoginRedirectPathname || afterLoginRedirectSearch. Redirecting to: ${afterLoginUrl.href}`);
                        console.debug(`Found afterLoginRedirectPathname || plannicaGridReciveToggleDrawerClose.send(true) `);
                    }

                    // history.pushState({}, "Loading grid...", afterLoginUrl.href);
                    // setTimeout(function () {
                    app.ports.plannicaGridReciveToggleDrawerClose.send(true);
                    window.location.href = afterLoginUrl.href;
                    // }, 1000);
                }
            }



            /*
            runXTimes(() => {

                //Hack to auto-reload page when getting the "Page could not be found", which is initialy cause by an JWT expiration error.
                try {
                    let pageNotFoundDiv = document.querySelector('body > div.main > div > h2');
                    if (pageNotFoundDiv) {
                        if (pageNotFoundDiv.textContent.indexOf("Page could not be found") != -1) {
                            console.warn(`initialize() runXTimes() Detected "Page could not be found" message. Will execute: location.reload()`);
                            location.reload();
                        }
                    }
                } catch (err) {
                    console.warn(`initialize() runXTimes() Detected "Page could not be found" got exception: ${err.message}`);
                }

            }, 500, 30);
            */

            pgridSuperwise.superwise(app, config);


        } else {
            window.PGridClientDebugMode && window.PGridClientDebugMode >= 2 && console.debug("Detected JWT token response, will NOW also load Plannica Grid (did not before)");
        }


    }

    function createAuthContext(obj) {
        let context = {
            name: obj.account.name,
            email: obj.account.username || obj.idToken.preferredName || obj.idToken.claims["emails"][0],
            idToken: obj.idToken,
            accessToken: obj.accessToken ? obj.accessToken : "",
            expiresOn: (new Date(obj.expiresOn).getTime() / 1000).toString(),
            claims: createClaimsObject(obj.idTokenClaims),
            tenantId: obj.tenantId,
            accountType: getAccountType(obj),
            issuer: obj.idTokenClaims.iss
        };

        return context;
    }

    function createClaimsObject(obj) {
        let claims = []
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                claims.push({ typ: key, val: obj[key].toString() });
            }
        }
        return claims;
    }

    function getAccountType(obj) {
        let tId = obj.tenantId;
        if (tId) {
            switch (tId) {
                case "9188040d-6c67-4c5b-b112-36a304b66dad":
                    return "MICROSOFT_ACCOUNT"
                default:
                    return "WORK_OR_SCHOOL_ACCOUNT";
            }
        }
        else {
            console.log("getAccountType() environment:" + obj.account.environment);
            if (obj.account.environment.includes("b2clogin.com")) {
                return "B2C_ACCOUNT";
            }
        }
    }

    window.checkIfLoggedIn_ELM_AND_GRID = async function (grid_origin = false, elm_receiveUpdatedToken = false) {


        window.PGridClientDebugMode >= 2 && console.debug(`${(new Date()).toISOString()} window.checkIfLoggedIn_ELM_AND_GRID(grid_origin = ${grid_origin}, elm_receiveUpdatedToken=${elm_receiveUpdatedToken}) called `);

        let ret = null;
        let homeAccountId = null;
        let account = null;


        homeAccountId = localStorage.getItem("homeAccountId");
        if (homeAccountId) {
            account = msalInstance.getAccountByHomeId(homeAccountId);
        }

        /* 
        * 1) Check if we have an account
        */

        if (!account) {

            /* 
             * No account found, redirect to login page
             */

            if (grid_origin) {


                if (window.location.pathname === "/grid") {                //Store url for redirect after login
                    window.PGridClientDebugMode >= 2 && console.debug(`Detected grid url, saving to afterLoginRedirectPathname and afterLoginRedirectSearch: ${window.location.href}`);
                    localStorage.setItem("afterLoginRedirectPathname", window.location.pathname);
                    localStorage.setItem("afterLoginRedirectSearch", window.location.search);
                }

                //Go to first page to initiazte login from the "ROOT" page
                // window.location.href = new URL(window.location).origin;

            }

            // else {
            {

                //If in ELM, let elm send the user to the "ROOT" page
                app.ports.loginContext.send(emptyUser);
            }

            return;
        }




        /*
         * 2) Now we have an account, now lets get an token, and then an auth context
         */

        try {

            const tokenRequest = {
                scopes: config.scopes,
                account: account
            }
            // const tokenRequestForceRefresh = {
            //     scopes: config.scopes,
            //     account: account,
            //     forceRefresh: true
            // }

            let date2Epoc = function (dateObj) {
                let retEpoc = Number.parseInt(dateObj / 1000);
                return retEpoc
            }
            let epoc2Date = function (epocNr) {
                let retDate = new Date(epocNr * 1000);
                return retDate
            }

            // Test always refresh to avoid "bad status 401"

            // 3) Determin when we last called for an token with forceRefresh in EPOC format

            let epocLastExpiresOnStr = localStorage.getItem("epocLastExpiresOn");
            let epocLastExpiresOn = null

            if (epocLastExpiresOnStr == null) {
                epocLastExpiresOn = 0; // No prev lastExpiredOn, then assume you are living in 1970-01-01 :)
            } else {
                epocLastExpiresOn = Number(epocLastExpiresOnStr);
            }

            let epocNow = date2Epoc(Date.now());
            let epocDiff = epocLastExpiresOn - epocNow;

            // 4) LastExpiresOn is zero (from the 70s) or older thatn threhold. Then forceFrefresh: true
            if (epocLastExpiresOn === 0 || epocDiff < THRESHOLD_FORCE_IDTOKEN_REFRESH_SEC) {
                window.PGridClientDebugMode >= 1 && console.debug(`${(new Date()).toISOString()} window.checkIfLoggedIn_ELM_AND_GRID() forceFrefresh: true`);
                tokenRequest.forceRefresh = true;
            }

            let tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);

            window.PGridClientDebugMode >= 1 && (
                window.PGridClientDebugMode >= 3
                    ? console.debug(`${(new Date()).toISOString()} window.checkIfLoggedIn_ELM_AND_GRID()  got tokenResponse`, tokenResponse)
                    : console.debug(`${(new Date()).toISOString()} window.checkIfLoggedIn_ELM_AND_GRID()  got tokenResponse`))

            try {

                let context = null;

                //5) Now get the authContext from the request. In normal cases (absent "forceRefresh", or when close to expire? (not sure about this)), this will not generate any exteranal call to the AzureAD
                context = createAuthContext(tokenResponse);

                let contextExpiresOn = Number(context.expiresOn);

                if (contextExpiresOn != epocLastExpiresOn) {
                    window.PGridClientDebugMode >= 1 && console.debug(`${(new Date()).toISOString()} window.checkIfLoggedIn_ELM_AND_GRID() epocLastExpiresOn: ${epocLastExpiresOn} -> ${contextExpiresOn} (${epoc2Date(epocLastExpiresOn).toISOString()} -> ${epoc2Date(contextExpiresOn).toISOString()})`);
                    localStorage.setItem("epocLastExpiresOn", contextExpiresOn);
                }

                if (context) {
                    // Got context, store as return value
                    ret = context;
                    if (grid_origin) {
                        //No extra calls when called from grid
                    } else {
                        //Called from elm, call different ports depending on elm_receiveUpdatedToken
                        // if (elm_receiveUpdatedToken) {
                        //     app.ports.receiveUpdatedToken.send(context);
                        // } 
                        // else {
                        //     app.ports.loginContext.send(context);
                        // }

                        //Always send updated context to ELM?
                        app.ports.receiveUpdatedToken.send(context);

                        if (elm_receiveUpdatedToken) {
                            //Don´t call loginContext
                        }
                        else {
                            app.ports.loginContext.send(context);
                        }
                    }
                } else {
                    throw new Error(`Could not aquire authToken context`);
                }


            } catch (err1) {
                console.debug('checkIfLoggedIn_ELM_AND_GRID error 1', err1);
                throw (err1)
            }
        } catch (err2) {
            console.debug(`${(new Date()).toISOString()} checkIfLoggedIn_ELM_AND_GRID error 2`, err2);
            localStorage.removeItem("customLogin");
            localStorage.removeItem("epocLastExpiresOn");
            localStorage.removeItem("afterLoginRedirectPathname");
            localStorage.removeItem("afterLoginRedirectSearch");

            //See: https://aka.ms/msljs/browser-errors for error message explanations

            /*
            * Last resort loop avoidance
            * This avoids infinit looping on non resolved errorors
            */
            var n = localStorage.getItem("checkIfLoggedIn_ELM_AND_GRID_counter");
            if (n === null) {
                n = 0;
            } else {
                n++;
            }
            localStorage.setItem("checkIfLoggedIn_ELM_AND_GRID_counter", n);
            if (n > 3) {
                console.warn(`checkIfLoggedIn_ELM_AND_GRID got error 3 times in a row. Clearing localStoreage`);
                localStorage.clear();
            }


            if (grid_origin) {
                console.log(`sending app.ports.loginContext.send(emptyUser)`);
                app.ports.loginContext.send(emptyUser);
            } else {
                console.log(`Go to first page to initiazte login from the "ROOT" page`);
                window.location.href = new URL(window.location).origin;
            }
            return;
        }

        if (!ret) {
            localStorage.removeItem("checkIfLoggedIn_ELM_AND_GRID_counter") //Last resort loop avoidance
        }

        return ret;
    }


    //Periodically called 
    app.ports && app.ports.checkIfLoggedIn &&
        app.ports.checkIfLoggedIn.subscribe(async function () {
            (window.PGridClientDebugMode >= 2) && console.debug("ELM PORT app.ports.checkIfLoggedIn called");
            await window.checkIfLoggedIn_ELM_AND_GRID(false, false);
        })





    window.updateAuthContext_ELM = async function () {

        await window.checkIfLoggedIn_ELM_AND_GRID(false, true);
    };

    app.ports && app.ports.updateAuthContext &&
        app.ports.updateAuthContext.subscribe(function () {
            (window.PGridClientDebugMode >= 2) && console.debug("ELM PORT app.ports.updateAuthContext called");
            window.updateAuthContext_ELM()
        }
        );


    app.ports && app.ports.loginAzureAD &&
        app.ports.loginAzureAD.subscribe(function () {
            const tokenRequest = {
                scopes: config.scopes,
            }

            localStorage.removeItem('selectedCustomer');
            localStorage.setItem("customLogin", customLoginParameter);
            try {
                msalInstance.loginRedirect(tokenRequest);
            }
            catch (err) {
                console.debug(err);
            }
        });

    app.ports && app.ports.logoutAzureAD &&
        app.ports.logoutAzureAD.subscribe(async function () {
            const account = msalInstance.getAllAccounts()[0];
            localStorage.removeItem("customLogin");
            localStorage.removeItem("homeAccountId");
            localStorage.removeItem('selectedCustomer');

            var postLogoutRedirectUri = config.www.url;
            if (config.loginParameters.type_.toLowerCase() == "b2c") {
                postLogoutRedirectUri += "/" + customLoginParameter;
            }
            await msalInstance.logoutRedirect({
                account: account,
                postLogoutRedirectUri: postLogoutRedirectUri
            });
        });

    app.ports && app.ports.setState &&
        app.ports.setState.subscribe(function (state) {
            if (state) {
                if (window.PGridClientDebugMode >= 3) {
                    console.debug(`app.ports.setState() setting new state (${state.key}) <-- ${JSON.stringify(state.value)}`);
                }
                if (state.key && state.value) {
                    localStorage.setItem(state.key, state.value);
                }
            } else {
                if (window.PGridClientDebugMode >= 3) {
                    console.debug(`app.ports.setState() state was null`);
                }

            }
        });

    app.ports && app.ports.getState &&
        app.ports.getState.subscribe(function (key) {
            if (key != null && key != undefined) {
                let stateValue = localStorage.getItem(key);

                if (window.PGridClientDebugMode >= 3) {
                    console.debug(`app.ports.setState() getting old state (${state.key}) --> ${JSON.stringify(state.value)}`);
                }

                if (stateValue != null && stateValue != undefined) {
                    app.ports.state.send({ key: key, value: stateValue });
                }
            } else {

                if (window.PGridClientDebugMode >= 3) {
                    console.debug(`app.ports.getState() key was null`);
                }

            }
        });

    app.ports && app.ports.getStates &&
        app.ports.getStates.subscribe(function (keys) {
            let states = [];


            if (keys != null && keys != undefined) {
                for (let key of keys) {
                    let stateValue = localStorage.getItem(key);

                    if (window.PGridClientDebugMode >= 3) {
                        console.debug(`app.ports.setState() getting old state (${key}) --> ${JSON.stringify(stateValue)}`);
                    }


                    if (stateValue != null) {
                        states.push({ key: key, value: stateValue });
                    }
                }
            } else {

                if (window.PGridClientDebugMode >= 3) {
                    console.debug(`app.ports.getStates() keys was null`);
                }

            }

            if (states.length > 0) {
                app.ports.states.send(states);
            }
        });

    app.ports && app.ports.bootstrap &&
        app.ports.bootstrap.subscribe(function (reportId) {
            try {
                var reportContainer = document.getElementById("reportContainer");
                if (reportContainer) {
                    let settings = {
                        type: 'report',
                        settings: {
                            filterPaneEnabled: false,
                            navContentPaneEnabled: true
                        }
                    };
                    if (reportId && reportId.length > 0) {
                        let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}`;
                        settings["embedUrl"] = embedUrl;

                        var report = powerbi.bootstrap(
                            reportContainer,
                            settings
                        );
                    }
                }
                else {
                    console.debug("PowerBI Bootstrap -> Could not find report container");
                }
            }
            catch (error) {
                console.debug(error);
            }
        });

    app.ports && app.ports.embed &&
        app.ports.embed.subscribe(function (embedConfig) {
            try {
                /* Get models. models contains enums that can be used.*/
                var models = pbi.models;

                embedConfig.initialPage = embedConfig.initialPage ? embedConfig.initialPage : "";

                /* We give All permissions to demonstrate switching between View and Edit mode and saving report.*/
                var permissions = models.Permissions.All;
                var config = {
                    type: embedConfig.embedType,
                    tokenType: 1, //models.TokenType.Embed,
                    accessToken: embedConfig.embedToken,
                    embedUrl: embedConfig.embedURL,
                    id: embedConfig.reportID,
                    //permissions: permissions,
                    viewMode: embedConfig.editMode ? models.ViewMode.Edit : models.ViewMode.View,
                    settings: {
                        background: embedConfig.transparentBackground ? models.BackgroundType.Transparent : models.BackgroundType.Default,
                        panes: {
                            filters: {
                                expanded: false,
                                visible: embedConfig.showFilters
                            },
                            pageNavigation: {
                                visible: embedConfig.showPageNavigation
                            }
                        }
                    }
                };

                if (embedConfig.initialPage.length > 0) {
                    config["pageName"] = embedConfig.initialPage;
                }

                window.setTimeout(function () {
                    var reportContainer = document.getElementById("reportContainer");
                    var report = powerbi.embed(reportContainer, config);
                }, 100);
            }
            catch (error) {
                console.error(`app.ports.embed error: `, error);
                app.ports.error.send(error.message);
            }
        });

    app.ports && app.ports.fullscreen &&
        app.ports.fullscreen.subscribe(function () {
            try {
                const reportContainer = document.getElementById("reportContainer");
                const report = powerbi.get(reportContainer);
                report.fullscreen();
            }
            catch (error) {
                console.error("app.ports.fullscreen error: ", error);
            }
        });

    app.ports && app.ports.print &&
        app.ports.print.subscribe(function () {
            try {
                const reportContainer = document.getElementById("reportContainer");
                const report = powerbi.get(reportContainer);
                report.print()
                    .then(function (result) {
                        console.log("app.ports.print print!");
                    })
                    .catch(function (errors) {
                        console.error("app.ports.print error: ", errors);
                    });

            } catch (error) {
                console.error("app.ports.print err: " + error.message);
            }
        });

    app.ports && app.ports.reset &&
        app.ports.reset.subscribe(function () {
            var reportContainer = document.getElementById("reportContainer");
            if (reportContainer) {
                if (powerbi && "reset" in powerbi) {
                    powerbi.reset(reportContainer);
                } else {
                    (global && global.serverDebugMode >= 1) && console.error("powerbi in 'powerbi.reset(reportContainer);' is not defined")
                }
            }
        });

    app.ports && app.ports.getDefaultLang &&
        app.ports.getDefaultLang.subscribe(function () {
            var s = window.navigator.language || window.navigator.userLanguage;
            var lang = "en-US";
            switch (s) {
                case "sv-SE":
                case "en-US":
                    lang = s;
                    break;
                default:
                    lang = "en-US";
                    break;
            }
            var myLang = require("../lib/lang/lang." + lang);

            app.ports.receiveLang.send(myLang.lang);
        });

    // https://discourse.elm-lang.org/t/correct-way-of-using-ports-in-0-19/2327
    app.ports && app.ports.getLang &&
        app.ports.getLang.subscribe(function (s) {
            var lang = "en-US";
            switch (s) {
                case "sv-SE":
                case "en-US":
                    lang = s;
                    break;
                default:
                    lang = "en-US";
                    break;
            }
            var myLang = require("../lib/lang/lang." + lang);

            app.ports.receiveLang.send(myLang.lang);
        });

    app.ports && app.ports.upload &&
        app.ports.upload.subscribe(function () {
            var acceptedSuffixes = ["json", "xlsx"]

            var re = /(?:\.([^.]+))?$/;
            var fileSuffix = re.exec(file.name)[1];
            if (acceptedSuffixes.indexOf(fileSuffix) == -1) {
                console.log("File type is not supported");
                // send message
                app.ports.errorMessage.send(fileSuffix + " is not supported");
            }
            else {
                const fileReader = new FileReader();
                fileReader.onerror = function (ex) {
                    console.log(ex);
                    app.ports.errorMessage.send("Upload failed");
                }
                if (fileSuffix == "json") {
                    fileReader.onload = function () {
                        // console.log("File reader results: ");
                        var json = JSON.parse(fileReader.result);
                        // console.log(result);
                        var result = formatUserList(json.users);
                        try {
                            // console.log("Formatted Users:");
                            // console.log(result);
                            app.ports.receiveReadFile.send(result);
                        }
                        catch (ex) {
                            console.log(ex);
                            app.ports.errorMessage.send("Upload failed");
                        }
                    }
                    fileReader.readAsText(file);
                }
                else if (fileSuffix == "xlsx") {
                    fileReader.onload = function (e) {
                        var data = e.target.result;
                        var workbook = XLSX.read(data, {
                            type: 'binary'
                        });
                        workbook.SheetNames.forEach(function (sheetName) {
                            var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                            var json_object = JSON.stringify(XL_row_object);
                            var json = { "users": JSON.parse(json_object) }
                            // console.log(result);
                            var result = formatUserList(json.users);
                            try {
                                // console.log("Formatted Users:");
                                // console.log(result);
                                app.ports.receiveReadFile.send(result);
                            }
                            catch (ex) {
                                console.log(ex);
                                app.ports.errorMessage.send("Upload failed");
                            }
                        });
                    }
                    fileReader.readAsBinaryString(file);
                }
            }
        });

    function formatUserList(obj) {
        var returnObj = [];
        for (var i = 0; i < obj.length; i++) {
            var user = obj[i];
            returnObj[i] = {
                userEmail: user.email ? user.email : "",
                userLoginType: user.logintype ? user.logintype : "",
                userFullName: user.fullname ? user.fullname : "",
                userWorkPhone: user.workphone ? user.workphone : "",
                userMobilePhone: user.mobilephone ? user.mobilephone : "",
                userState: user.state ? (user.state.toLowerCase() == 'true') : false
            }
        }
        return returnObj;
    }


    app.ports && app.ports.porttest &&
        app.ports.porttest.subscribe(function () {
            console.log("Test successful!");
        });

    app.ports && app.ports.getCallbackHeaders &&
        app.ports.getCallbackHeaders.subscribe(function () {

            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
                    // console.log(res);
                    if (xhr.responseText && xhr.responseText != "" && xhr.responseText != null) {
                        console.log("xhr.responseText:");
                        // console.log(xhr.responseText);
                        if (xhr.responseText[0] != "[" && xhr.responseText[0] != "[]" && xhr.responseText[0] != "{") {
                            console.log("no claim");
                            return;
                        }
                        var res = JSON.parse(xhr.responseText)[0];
                        // console.log("res:");
                        // console.log(res);
                        var verAad = getClaim(res.user_claims, "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name");
                        var verMs = getClaim(res.user_claims, "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress");
                        var username_ = (verMs != "") ? verMs : verAad;
                        const claims_ =
                        {
                            userName: username_ //res.user_claims[18].val
                            , aud: ""//getClaim(res.user_claims, "aud") //res.user_claims[0].val
                            , exp: -1 //parseInt(getClaim(res.user_claims, "exp")) //parseInt(res.user_claims[4].val)
                            , family_name: "" //getClaim(res.user_claims, "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname") //res.user_claims[8].val
                            , given_name: "" //getClaim(res.user_claims, "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname") //res.user_claims[9].val
                            , iat: -1 //parseInt(getClaim(res.user_claims, "iat")) //(parseInt(res.user_claims[2].val)
                            , ipaddr: "" //getClaim(res.user_claims, "ipaddr") //res.user_claims[10].val
                            , iss: "" //getClaim(res.user_claims, "iss") //res.user_claims[1].val
                            , name: "" //getClaim(res.user_claims, "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name") //res.user_claims[11].val
                            , nbf: -1 //parseInt(getClaim(res.user_claims, "nbf")) //parseInt(res.user_claims[3].val)
                            , nonce: "" //getClaim(res.user_claims, "nonce") //res.user_claims[12].val
                            , oid: ""
                            , platf: ""
                            , sub: ""
                            , tid: getClaim(res.user_claims, "http://schemas.microsoft.com/identity/claims/tenantid") //res.user_claims[17].val
                            , unique_name: ""
                            , upn: "" //res.user_claims[19].val
                            , uti: "" //res.user_claims[20].val
                            , ver: "" //res.user_claims[21].val
                        }
                        const sUser =
                        {
                            accessToken: res.access_token
                            , expiresOn: res.expires_on
                            , idToken: res.id_token ? res.id_token : ""
                            , provider: res.provider_name
                            , refreshToken: res.refresh_token ? res.refresh_token : ""
                            , userId: res.user_id
                            , claims: claims_
                        }
                        // console.log("claims_:");
                        // console.log(claims_);
                        console.log("sUser:");
                        console.log(sUser);
                        console.log("Verified user");
                        app.ports.loginSuccessful.send(sUser);
                    }
                    else {
                        console.log("Unauthorized.");
                        app.ports.statusMessage.send(401);
                    }
                }
                else {
                    console.log("Unauthorized..");
                    app.ports.statusMessage.send(401);
                }
            }
            //console.log(configClient.www.url);
            xhr.open('GET', '/.auth/me', true); // https://mimica-utveckling.azurewebsites.net/.auth/me https://app.reaact.se/.auth/me
            xhr.setRequestHeader("Content-Type", "application/json");
            console.log("sending...");
            xhr.send(null);


        });
    app.ports && app.ports.requestEmbed &&
        app.ports.requestEmbed.subscribe(function () {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE && xhr.status === 200) {
                    if (xhr.responseText && xhr.responseText != "" && xhr.responseText != null) {
                        app.ports.receiveEmbed.send(xhr.responseText);
                    }
                    else {
                        app.ports.receiveEmbed.send(xhr.responseText);
                    }
                }
                else {
                    app.ports.receiveEmbed.send(xhr.responseText);
                }
                app.ports.receiveEmbed.send(xhr.responseText);
            }
            // xhr.open('GET', '/requestEmbed', true); // https://app.reaact.se/requestEmbed https://mimica-utveckling.azurewebsites.net/.auth/me https://app.reaact.se/.auth/me
            xhr.open('GET', '/requestEmbed', true); // https://app.reaact.se/requestEmbed https://mimica-utveckling.azurewebsites.net/.auth/me https://app.reaact.se/.auth/me
            // xhr.open('GET', 'https://mimica-portal-dev.azurewebsites.net/requestEmbed', true); // https://app.reaact.se/requestEmbed https://mimica-utveckling.azurewebsites.net/.auth/me https://app.reaact.se/.auth/me
            //xhr.open('GET', 'http://localhost:8080/requestEmbed', true); // https://app.reaact.se/requestEmbed https://mimica-utveckling.azurewebsites.net/.auth/me https://app.reaact.se/.auth/me
            xhr.setRequestHeader("Content-Type", "application/json");
            console.log("sending...");
            xhr.send(null);
        });

    function getClaim(claims, target) {
        var result = "";
        for (var i = 0; i < claims.length; i++) {
            if (claims[i].typ == target) {
                result = claims[i].val;
            }
        }
        return result;
    }

    app.ports && app.ports.clearCookies &&
        app.ports.clearCookies.subscribe(function () {
            var cookies = document.cookie.split(";");

            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                console.log("Cookie: " + name);
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }

            app.ports.statusMessage.send(900);
        });

    app.ports && app.ports.setFavicon &&
        app.ports.setFavicon.subscribe(function (url) {
            url = url == null ? "favicon.ico" : url;
            url = url.length <= 0 ? "favicon.ico" : url;
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = url;
        });


    /* app.ports.enterEditMode.subscribe(() => {*/
    app.ports && app.ports.enterEditMode &&
        app.ports.enterEditMode.subscribe(function () {
            try {
                const reportContainer = document.getElementById("reportContainer");
                const report = powerbi.get(reportContainer);
                report.switchMode("edit");
            } catch (error) {
                console.log(error.message);
            }
        });

    /* app.ports.enterViewMode.subscribe(() => {*/
    app.ports && app.ports.enterViewMode &&
        app.ports.enterViewMode.subscribe(function () {
            try {
                const reportContainer = document.getElementById("reportContainer");
                const report = powerbi.get(reportContainer);
                report.switchMode("view");
            } catch (error) {
                console.log(error.message);
            }
        });


    /* app.ports.hideFilterAndNav.subscribe((bool) => {*/
    app.ports && app.ports.hideFilterAndNav &&
        app.ports.hideFilterAndNav.subscribe(function (bool) {
            try {
                const reportContainer = document.getElementById("reportContainer");
                const report = powerbi.get(reportContainer);

                const newSettings = {
                    navContentPaneEnabled: bool,
                    filterPaneEnabled: bool
                };


                /* Update the settings by passing in the new settings you have configured.*/
                report.updateSettings(newSettings)
                    .then(function (result) {
                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            } catch (error) {
                console.log(error.message);
            }
        });




    app.ports && app.ports.removeEmbed &&
        app.ports.removeEmbed.subscribe(function () {
            console.log("..removeEmbed");
            try {
                var report = document.getElementById("powerbi-report");
                if (report) {
                    report.parentNode.removeChild(report);
                }
            }
            catch (err) {
                console.error(err.message);
            }
        });
}


/*
function runXTimes(callback, delay, repetitions) {
    var x = 0;
    var intervalID = window.setInterval(function () {

        callback();

        if (++x === repetitions) {
            window.clearInterval(intervalID);
        }
    }, delay);
}
*/