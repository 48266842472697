<template>
  <div class="pgrid-filter-item" v-bind:style="filter.settings_filterStyle">
    {{ filter.displayTitle }}
    <v-select ref="vueselectRef" v-bind:options="filter.filterOptions" label="text" v-model="optSelected" :clearable="true" />
    <div class="pgrid-error-msg" v-if="errMsg != null">{{ errMsg }}</div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import PGridUtils from "./pgridUtils";
import lodash from "lodash";

export default {
  name: "pgrid-filter-dropdown",
  props: {
    filterIdx: { type: Number, required: true },
  },
  data: function () {
    return {
      errMsg: null,
    };
  },

  computed: {
    filter() {
      return this.$store.state.pgridFilter.Filters[this.filterIdx];
    },
    optSelected: {
      // getter
      get: function () {
        return this.filter.filterValue;
      },
      // setter
      set: function (newOptSelected) {
        if (this.$store.state.pgridSettings.DisableEvents_Filters == true) {
          return;
        }

        let oldOptSelected = JSON.parse(JSON.stringify(this.filter.filterValue));

        if (newOptSelected == null) {
          //If pressed the x button in the dropdown

          this.filter.filterValue = newOptSelected;
          this.$emit("dropdownFilterUpdate", { filterIdx: this.filterIdx, newOptSelected: newOptSelected });
        } else if (this.filter.filterValue.value == newOptSelected.value) {
          //Is "resetted" by ConfirmNavigation(), do nothing
        } else {
          if (
            PGridUtils.ConfirmNavigation(
              this.$store,
              `pgrid-fiter-dropdown.vue optSelected: ${newOptSelected} - filterIdx ${this.filterIdx}`,
              "Vill du ändå ändra filter?"
            )
          ) {
            //This updates the UI
            this.filter.filterValue = newOptSelected;

            console.debug("pgrid-filter-dropdown: emit dropdownFilterUpdate", newOptSelected);
            this.$emit("dropdownFilterUpdate", {
              filterIdx: this.filterIdx,
              newOptSelected: newOptSelected,
              oldOptSelected: oldOptSelected,
            });
          } else {
            //Reset back to old value
            window.PGridClientDebugMode >= 3 && 
              console.debug(
                `pgrid-filter-dropdown:  ${JSON.stringify({ optSelected: this.optSelected, filterValue: this.filter.filterValue })}`
              );

            // this.$store.state.pgridSettings.DisableEvents_Filters = true;
            this.$store.commit("Mutation_UpdatePGridSettings", {
              prop: "DisableEvents_Filters",
              val: true,
              source: `pgrid-filter-dropdown.vue optSelected() SET`,
            });

            this.optSelected = lodash.clone(this.filter.filterValue); //Does not work for the dropdown. Is it v-select?
            window.PGridClientDebugMode >= 4 && console.debug(`pgrid-filter-dropdown:  ${JSON.stringify({ this_filter_filterValue: this.filter.filterValue })}`);

            this.$refs.vueselectRef.typeAheadPointer = this.filter.filterOptions.filter((o) => o.value == this.optSelected.value)[0].index;
            window.PGridClientDebugMode >= 4 && 
              console.debug(`pgrid-filter-dropdown:  ${JSON.stringify({ typeAheadPointer: this.$refs.vueselectRef.typeAheadPointer })}`);

            // this.$store.state.pgridSettings.DisableEvents_Filters = false;
            this.$store.commit("Mutation_UpdatePGridSettings", {
              prop: "DisableEvents_Filters",
              val: false,
              source: `pgrid-filter-dropdown.vue optSelected() SET`,
            });
          }
        }

        if (window.PGridClientDebugMode >= 2) {
          console.debug(
            `pgrid-filter-dropdown: ALL ${JSON.stringify({
              filter_filterOptions: this.filter.filterOptions,
              this_filterIdx: this.filterIdx,
              this_optSelected: this.optSelected,
              newOptSelected: newOptSelected,
            })}`
          );
        }
      },
    },
  },
  components: { vSelect },
};
</script>
<style scoped>
.pgrid-error-msg {
  color: red;
}
</style>
