import vuexStore from './pgridVuexStore.js'
import 'ace-builds/webpack-resolver.js';
import 'ace-builds/src-min-noconflict/mode-css.js';
import 'ace-builds/src-min-noconflict/theme-chrome.js';
import lodash from 'lodash';
import Beautify from "ace-builds/src-min-noconflict/ext-beautify.js";
import Css from 'css';

import PGridBackendAccess from './pgridBackendAccess.js'


export default {
  name: "pgrid-css-editor",
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String
    }
  },
  model: {
    event: "modified"
  },
  methods: {
    loadEditorCss: function () {


      try {
        let state = this.$store.state;
        let css = state.customCss;
        if (css == null || css == undefined) {
          css = "";
        }

        false && console.debug(`pgridCssEditor.js: loadEditorCss() css.length ${css.length}`);

        const editorOptions = {
          mode: "ace/mode/css",
          theme: "ace/theme/chrome",
          useWorker: false
        };


        if (css.length > 0) {
          let aceEditor = null;
          let secondTimeLoading = true;

          if (aceEditor == null) {
            secondTimeLoading = false;
            let aceEl = document.getElementById('pgrid-css-editor-box');
            aceEditor = ace.edit(aceEl);

            vuexStore.commit('Mutation_UpdateRoot', { prop: 'aceCssEditorRef', val: aceEditor, source: 'pgrid-css-editor' });

          }

          if (aceEditor != null) {
            window.PGridClientDebugMode >= 2 && console.debug(`pgridCssEditor.js: editor.setValue(css) ${css.length}`);
            aceEditor.setValue(css);
            aceEditor.setOptions(editorOptions);
            aceEditor.resize();


            if (secondTimeLoading == false) {
              Beautify.beautify(aceEditor.session);
              aceEditor.on("change", function () {
                let css2 = aceEditor.getValue();
                ValidateCss(css2);
                vuexStore.commit('Mutation_UpdateRoot', { prop: 'customCss', val: css2, source: 'pgrid-css-editor.on.change' });
              });
            }

          } else {
            console.debug(`pgridCssEditor.js: aceEditor == null`);

          }

          LoadStyleVariables();
        }

      }
      catch (err) {
        let errMsg = `> pgridCssEditor loadEditorCss() got exception: ${err.message || err}`;
        console.error(errMsg);
        // throw new Error(errMsg);
      }
    }
  },
  mounted() {

    this.loadEditorCss();

  },


  template: '<div id="pgrid-css-editor-box" style="width: 485px; height: 720px;"></div>',
  BeautifyCss,
  LoadStyleVariables,
  MapStyleVariables,
  ValidateCss,
  CustomCssIsLoaded,
  LoadPGridCss,
  UpdatePGridStyle,
}

export function BeautifyCss() {
  try {
    let editor = ace.edit("pgrid-css-editor-box");
    Beautify.beautify(editor.session);
  }
  catch (err) {
    let errMsg = `> BeautifyCss() got exception: ${err.message || err}`;
    console.error(errMsg);
  }
}

export function LoadStyleVariables() {
  let styleVariables = JSON.parse(localStorage.getItem('StyleVariables'));

  let table = document.getElementById('cssPanelSampleStyles');
  if (table) {
    while (table.firstChild) {
      table.removeChild(table.lastChild);
    }
  }

  if (table) {

    for (var key of Object.keys(styleVariables)) {
      let currentValue = styleVariables[key];

      if (currentValue != null && currentValue != undefined) {
        let tr = document.createElement('tr');
        let tdKey = document.createElement('td');
        let tdValue = document.createElement('td');
        let tdPreview = document.createElement('td');
        let preview = document.createElement('div');
        tdKey.style.borderBottom = "1px solid #e0e0e0";
        tdKey.style.width = "200px";
        tdKey.style.fontWeight = "bold";
        tdValue.style.borderBottom = "1px solid #e0e0e0";
        tdPreview.style.borderBottom = "1px solid #e0e0e0";
        tdPreview.style.width = "30px";
        preview.style.width = "20px";
        preview.style.height = "20px";
        preview.style.border = "1px solid #e0e0e0";
        preview.style.borderRadius = "5px";
        preview.style.backgroundColor = currentValue;
        tdKey.appendChild(document.createTextNode("$" + key));
        tdValue.appendChild(document.createTextNode(currentValue));
        tdPreview.appendChild(preview);
        tr.appendChild(tdKey);
        tr.appendChild(tdValue);
        tr.appendChild(tdPreview);
        table.appendChild(tr);
      }
    }
  }
}

export function MapStyleVariables(css) {
  let styleVariables = JSON.parse(localStorage.getItem('StyleVariables'));
  let result = css;

  for (var key of Object.keys(styleVariables)) {
    let re = new RegExp("\\$" + key, "g");
    let value = styleVariables[key];
    result = result.replace(re, value);
  }

  return result;
}

function AddCssAnchor(css) {

  /*
  limits the rule to only apply under the anchor element 
  #pgrid-root-container <rest of selectors> {
      .
      .
      .
  }
  */
  //  let anchor = "#pgrid-root-container";
  let anchor = "#pgrid-root-container"

  let parsedCss = "";

  false && console.debug(`AddCssAnchor() with css.length = ${css.length}`);

  try {
    parsedCss = Css.parse(css);

    if (parsedCss != null && parsedCss != undefined) {
      for (let rule of parsedCss["stylesheet"]["rules"]) {
        let selectors = [];
        let newSelectors = []
        selectors = rule["selectors"];
        if (selectors != undefined) {
          for (let s of selectors) {
            if (!s.includes(anchor)) {
              // newSelectors.push(anchor + " " + s);
              //Skipp root, then the rule is applyed to the whole page
              newSelectors.push(s);
            }
          }
        }
        rule["selectors"] = newSelectors;
      }
    }
  }
  catch (err) {
    let errMsg = `> AddCssAnchor() got exception: ${err.message || err}`;
    throw errMsg;
  }

  return Css.stringify(parsedCss);
}


export function CustomCssIsLoaded(key) {

  let pgridStyleIdName = 'id-pgridstyle-' + key;
  let existingPGridStyle = document.getElementById(pgridStyleIdName);
  if (existingPGridStyle) {
    return true;
  }
  else {
    return false;
  }
}


export async function LoadPGridCss(key) {

  try {

    const variables = {
      CustomerKey: key
    }

    false && console.debug("LoadPGridCss() fetching custom css");

    let cssResponse = await PGridBackendAccess.QueryPGridCustomStyle(variables);

    let error = lodash.get(cssResponse, "PGridCustomStyle[0].Error", null);
    if (error) {
      throw new Error(error);
    }

    let css = cssResponse.PGridCustomStyle[0].Css;

    false && console.debug("LoadPGridCss() loading custom css (length): " + css.length);

    if (css === null || css === undefined) {
      console.warn("Could not retrieve custom css");
      return;
    }

    UpdatePGridStyle(key, css);
    vuexStore.commit('Mutation_UpdateRoot', { prop: 'customCss', val: css, source: 'LoadPGridCss' });
  }
  catch (err) {
    let errMsg = `> pgridCssEditor LoadPGridCss() loading custom css got exception: ${err.message || err}`;
    console.error(errMsg);
  }
}

export function ValidateCss(css) {
  let errorSpan = document.getElementById("cssError");
  let saveButton = document.getElementById("saveCss");
  let applyButton = document.getElementById("applyCss");

  let isValid = false;
  let message = "";

  try {
    let parsedCss = Css.parse(css);
    isValid = true;
  }
  catch (error) {
    message = `${error.reason} at line: ${error.line}, column: ${error.column}`;
    isValid = false;
  }

  if (errorSpan && saveButton && applyButton) {
    errorSpan.innerText = message;
    saveButton.disabled = !isValid;
    applyButton.disabled = !isValid;
  }

  return isValid;
}

export function UpdatePGridStyle(key, css) {
  try {
    css = MapStyleVariables(css);
    css = AddCssAnchor(css);
  }
  catch (error) {
    throw error;
  }

  let head = document.head;
  let headTags = document.getElementsByTagName('head');
  if (!head && headTags) {
    head = headTags[0];
  }

  let pgridStyleIdName = 'id-pgridstyle-' + key;
  let pgridStyleClassName = 'pgridstyle-' + key;
  let existingPGridStyle = document.getElementById(pgridStyleIdName);
  let pgridStyles = document.getElementsByClassName(pgridStyleClassName);
  for (let i = 0; i < pgridStyles.length; i++) {
    let currentStyle = pgridStyles[i];
    if (currentStyle.id != pgridStyleIdName) {
      currentStyle.parentNode.removeChild(currentStyle);
    }
  }

  try {
    let newPGridStyle = document.createElement('style');

    newPGridStyle.id = pgridStyleIdName;
    // try {
    newPGridStyle.classList.add(pgridStyleClassName);
    // } catch {

    // }
    newPGridStyle.type = 'text/css';
    if (newPGridStyle.styleSheet) {
      newPGridStyle.styleSheet.cssText = css;
    }
    else {
      newPGridStyle.appendChild(document.createTextNode(css));
    }

    if (existingPGridStyle) {
      existingPGridStyle.parentNode.replaceChild(newPGridStyle, existingPGridStyle)
    }
    else {
      head.appendChild(newPGridStyle);
    }
  }
  catch (err) {
    let errMsg = `> pgridCssEditor LoadPGridCss() got exception: ${err.message || err}`;
    console.error(errMsg);
  }
}