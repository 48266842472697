import lodash from "lodash";
import cycle from '../../lib/cycle/cycle.js'
import pgridUtils from "./pgridUtils.js";

export function DynExtract(DYN_Ctx, dynExp, accumulator = undefined, vars = {}) {

    let ret = null;


    if ((typeof accumulator === "undefined") && arguments.length == 2) {
        accumulator = DYN_Ctx; //use accumulator or context
    }

    let dynExpToMatch = JSON.stringify(dynExp);

    /*
        let currPropVal_Dyn: {
            0. Check Operation { leafOrTuple: 'leaf', 'tuple', 
                                --tuple
                                opType: 'bool_anyChild','all',  
                                --leaf
                                opType: 'bool_pattern', 'bool_test', string_replace'
    
                                tuples : []
    
                                 ifTypeMatchRe: 'SELECTED'
                                 ifTypeMatch: 'SELECTED'
    
                                 opSpread Single, Multiple }    
    */

    switch (typeof dynExp) {

        case 'string':
        case 'number':
        case 'boolean':
            ret = dynExp;
            return ret;

        case 'object':

            if (dynExp === null) {
                ret = dynExp;
                return ret;
            }


            let desc = lodash.get(dynExp, "Description", "");

            // UpdateDynProps(DYN_Ctx, dynExp, accumulator, vars); //Apply _DYN extraction also for internal properties

            const validOpTypes_tuple = {
                'sequence': 'object' // pass accumulator 
                , 'until-true': 'object' // pass accumulator until not false
                , 'until-false': 'object' // pass accumulator until not true
                , 'if-then-else': 'object' // pass accumulator until not false
                , 'all': 'bool' // true when all is true
                , 'all-equal': 'bool' //true when all equals each others
                , 'any': 'bool' // true if any is true
                , 'concat': 'string' // concat strings
                , 'replace': 'bool' // true if any is true
            };
            const validOpTypes_leaf = {
                'staticValue': 'object'
                , 'nop': 'object'
                , 'parse-number': 'number'
                , 'parse-boolean': 'bool'
                , 'get': 'object'
                , 'find': 'object'
                , 'filter': 'object'
                , 'contains': 'bool'
                , 'equals': 'bool'
                , 'test': 'bool'
                , 'replace': 'string'
                , 'invert': 'bool'
            };

            let leafOrTuple = 'tuples' in dynExp ? 'tuple' : 'leaf';


            let opType = null;

            switch (leafOrTuple) {
                case 'tuple':

                    {
                        let ret_tuple = null;

                        if (opType === 'any') {
                            ret_tuple = false;
                        }


                        if (!Array.isArray(dynExp.tuples)) {
                            throw new Error(`DynExtract: tuples are not an array`);
                        }

                        opType = lodash.get(dynExp, "op", 'sequence'); // Default all


                        if (!(opType in validOpTypes_tuple)) {
                            throw new Error(`DynExtract: unknown tuple opType: ${opType}`);
                        }

                        let res_accumulator = accumulator;


                        switch (opType) {
                            case 'sequence':
                            case 'until-true':
                            case 'until-false':
                            case 'concat':

                                let concat_string = '';
                                // if (opType === 'concat') {
                                //     res_accumulator = ''; //Accumulator has the concationation string
                                // }

                                for (let ic = 0; ic < dynExp.tuples.length; ic++) {
                                    let currPropVal_Dyn_iter = dynExp.tuples[ic];

                                    let res_accumulator_undefinable = null;

                                    if (typeof currPropVal_Dyn_iter === 'undefined') {
                                        throw new Error(`DynExtract: tuple no ${ic} is undefined. Double commas?`);
                                    }


                                    if (opType === 'concat') {
                                        concat_string += DynExtract(DYN_Ctx, currPropVal_Dyn_iter, res_accumulator, vars);
                                    }
                                    else {

                                        res_accumulator_undefinable = DynExtract(DYN_Ctx, currPropVal_Dyn_iter, res_accumulator, vars);
                                        if (opType === 'sequence' && res_accumulator_undefinable !== undefined) { //Dont add undefined values to accumulator, as undefined  indcate that it should not be set to the accumulator

                                            res_accumulator = res_accumulator_undefinable;
                                        }
                                    }

                                    if (opType === 'until-true') {
                                        if (!!res_accumulator_undefinable) {
                                            res_accumulator = res_accumulator_undefinable;
                                            break;
                                        }
                                    }

                                    if (opType === 'until-false') {
                                        if (!res_accumulator_undefinable) {
                                            res_accumulator = res_accumulator_undefinable;
                                            break;
                                        }
                                    }
                                }


                                if (opType === 'concat') {
                                    ret_tuple = concat_string;
                                } else {
                                    ret_tuple = res_accumulator;
                                } break;
                            case 'all':
                            case 'any':
                            case 'all-equal':

                                let none_True = false;
                                let none_False = true;
                                let allEqual_Value = null;
                                let allEqual_StillTrue = true;

                                if (lodash.get(dynExp, "tuples.length", 0) == 0) {
                                    none_True = true;
                                }
                                else {

                                    for (let ic = 0; ic < dynExp.tuples.length; ic++) {
                                        let currPropVal_Dyn_iter = dynExp.tuples[ic];

                                        let res_inner = DynExtract(DYN_Ctx, currPropVal_Dyn_iter, accumulator, vars);

                                        if (opType == 'all-equal') {
                                            if (ic == 0) {
                                                allEqual_Value = res_inner
                                            }
                                            else {
                                                if (allEqual_Value != res_inner) {
                                                    allEqual_StillTrue = false;
                                                }
                                            }
                                        }
                                        else {

                                            if (!!res_inner) {
                                                none_True = false;
                                                if (opType === 'any') {
                                                    ret_tuple = true;
                                                    break;
                                                }
                                            }
                                            if (!res_inner) {
                                                if (none_False) {
                                                    none_False = false
                                                }
                                            }
                                        }
                                    }

                                }


                                if (opType === 'all') {
                                    if (!none_True && none_False) {
                                        ret_tuple = true;
                                    } else {
                                        ret_tuple = false;
                                    }
                                }

                                if (opType == 'all-equal') {
                                    if (allEqual_StillTrue) {
                                        ret_tuple = true;
                                    } else {
                                        ret_tuple = false;
                                    }
                                }

                                break;
                            case 'if-then-else':
                                // let res_accumulator = accumulator;

                                // let res_accumulator_undefinable = DynExtract(DYN_Ctx, currPropVal_Dyn_iter, res_accumulator, vars);
                                // if (res_accumulator_undefinable != undefined) { //Dont add undefined values to accumulator, as undefined  indcate that it should not be set to the accumulator
                                //     res_accumulator = res_accumulator_undefinable;
                                // }
                                let ifThenElse_tuplesLen = lodash.get(dynExp, "tuples.length", 0);

                                if (ifThenElse_tuplesLen != 2 && ifThenElse_tuplesLen != 3) {
                                    throw new Error(`DynExtract: tuple opType '${opType}' requires two or three touples`);
                                }


                                let res_accumulator_test = DynExtract(DYN_Ctx, dynExp.tuples[0], res_accumulator, vars);

                                if (!!res_accumulator_test) {
                                    ret_tuple = DynExtract(DYN_Ctx, dynExp.tuples[1], res_accumulator, vars);
                                }
                                else {
                                    if (ifThenElse_tuplesLen == 3) {
                                        ret_tuple = DynExtract(DYN_Ctx, dynExp.tuples[2], res_accumulator, vars);
                                    }
                                }

                                break;

                            default:
                                throw new Error(`DynExtract: tuple opType '${opType}' is not implemented`);
                        }

                        if (ret_tuple === undefined) {
                            console.warn(`tuple_res is undefined`)
                        }

                        ret = ret_tuple;
                    }
                    break;
                case 'leaf':

                    opType = lodash.get(dynExp, "op", 'nop');



                    if (!(opType in validOpTypes_leaf)) {
                        throw new Error(`DynExtract: unknown leaf opType: ${opType}`);
                    }

                    let loadFromVar = lodash.get(dynExp, "load", null);
                    let saveToVar = lodash.get(dynExp, "save", null);

                    let fromObj = null;
                    if (loadFromVar) {
                        fromObj = lodash.get(vars, loadFromVar, null);
                    } else {
                        fromObj = accumulator !== undefined ? accumulator : DYN_Ctx; //use accumulator or context
                    }


                    let ret_leaf = null;
                    switch (opType) {
                        case 'staticValue':
                            {
                                if (!('staticValue' in dynExp)) {
                                    throw new Error(`DynExtract: property 'staticValue' not found`);
                                }
                                ret_leaf = dynExp.staticValue;
                            }
                            break;

                        case 'get': //get from object
                        case 'find': //find in array
                        case 'filter': //find all in array
                            //Grab things
                            {
                                let path = lodash.get(dynExp, "path", null);
                                if (path === null) {
                                    throw new Error(`DynExtract: get: required 'path' property was not declared`);
                                }

                                let res_grab = null;

                                if (opType === 'get') {
                                    res_grab = lodash.get(fromObj, path, undefined);
                                } else if (opType === 'find') {
                                    res_grab = lodash.find(fromObj, path, undefined);
                                } else if (opType === 'filter') {
                                    res_grab = lodash.filter(fromObj, path, undefined);
                                }

                                if (res_grab === undefined) {
                                    if (window.PGridClientDebugMode >= 2) {
                                        console.debug(`DynExtract: ${opType} could not find propery for path ${JSON.stringify(path)}`);
                                    }
                                    res_grab = null;
                                }

                                ret_leaf = res_grab;
                            }
                            break;

                        case 'contains':
                        case 'equals':
                        case 'test':
                        case 'replace':
                            {
                                let res_testOrReplace = null;

                                if (accumulator === undefined) {
                                    throw new Error(`DynExtract: ${opType} accumulator is not specified`);
                                }

                                let pattern = lodash.get(dynExp, "pattern", null);
                                if (pattern === null && opType !== 'equals') {
                                    throw new Error(`DynExtract: ${opType} required 'pattern' property was not declared`);
                                }

                                if (opType === 'contains') {
                                    res_testOrReplace = String(fromObj).indexOf(pattern) != -1;
                                } else if (opType === 'equals') {
                                    let value = lodash.get(dynExp, "value", null);
                                    if (value === null) {
                                        throw new Error(`DynExtract: ${opType} required 'value' property was not declared`);
                                    }

                                    if (typeof value === 'object') {
                                        res_testOrReplace = JSON.stringify(value) === JSON.stringify(fromObj);
                                    } else {
                                        res_testOrReplace = value === fromObj;

                                    }
                                } else if (opType === 'test') {
                                    res_testOrReplace = new RegExp(pattern).test(String(fromObj));
                                } else if (opType === 'replace') {

                                    let replaceWith = lodash.get(dynExp, 'with', null);
                                    if (replaceWith === null) {
                                        throw new Error(`DynExtract: ${opType} required 'replace' property was not declared`);
                                    }

                                    if (typeof fromObj === "string") {
                                        res_testOrReplace = fromObj.replace(new RegExp(pattern), replaceWith);
                                    }
                                    else {
                                        console.warn(`DynExtract: ${opType} accumulator is not a string, skipping`);
                                    }
                                }
                                ret_leaf = res_testOrReplace;
                            }
                            break;
                        case 'invert':
                            {
                                if (fromObj === undefined) {
                                    throw new Error(`DynExtract: ${opType} accumulator is not specified`);
                                }
                                ret_leaf = !fromObj;
                            }
                            break;
                        case 'nop':
                            ret_leaf = fromObj;
                            break;
                        case 'parse-number':
                            if (pgridUtils.IsNumeric(fromObj)) {
                                ret_leaf = Number(fromObj);
                            } else {
                                ret_leaf = null;
                            }
                            break;
                        case 'parse-boolean':
                            if (pgridUtils.IsNumeric(fromObj)) {
                                let boolNum = Number(fromObj);
                                ret_leaf = boolNum > 0;
                            } else if (fromObj == null || fromObj == "") {
                                ret_leaf = false;
                            } else {
                                ret_leaf = true;
                            }
                            break;
                        default:
                            throw new Error(`DynExtract: leaf opType '${opType}' is unkown`);
                    }

                    if (ret_leaf === undefined) {
                        console.warn(`leaf_res is undefined`)
                    }

                    if (saveToVar) {
                        vars[saveToVar] = ret_leaf;
                    }

                    ret = ret_leaf;
                    break;
                default:
                    throw new Error(`DynExtract: unknown leafOrTuple: ${leafOrTuple}`);
            }
            break;
        default:
            throw new Error(`DynExtract: does not support type ${typeof dynExp}`);

    }
    return ret;
}



export function UpdateDynProps_ButtonList(context, dynBtnList) {
    for (let b = 0; b < dynBtnList.length; b++) {
        let dynBtn = dynBtnList[b];

        //Update DynamicButton
        UpdateDynProps_Button(context, dynBtn);
    }
}

export function UpdateDynProps_Button(context, dynBtn) {
    let DYN_Ctx_DynButton = {
        Type: "DynamicButton",
        State: context.state
    };

    UpdateDynProps(DYN_Ctx_DynButton, dynBtn);
}

export function UpdateDynProps_Generic(context, obj_DYN, removeDYNProps = false) {
    let DYN_Ctx_DynGeneric = {
        Type: "DynamicGenericObject",
        State: context.state
    };

    UpdateDynProps(DYN_Ctx_DynGeneric, obj_DYN, removeDYNProps);
}
export function UpdateDynProps_ExtraContext(context, extraContext, obj_DYN, removeDYNProps = false) {
    let DYN_Ctx = {
        Type: "DynamicGenericObject",
        State: context.state,
        ExtraContext: extraContext
    };

    if (window.PGridClientDebugMode >= 2) {   // let decycled2 = JSON.decycle(dump);
        let oCycle = { DYN_Ctx, obj_DYN, removeDYNProps };
        let oDeCycled = JSON.decycleSimple(oCycle, ["State", "fact", "linkedRangesFacts", "Source"]);
        console.debug(`UpdateDynProps_ExtraContext BEFORE:`, oDeCycled);
    }

    UpdateDynProps(DYN_Ctx, obj_DYN, true);


    if (window.PGridClientDebugMode >= 2) {
        let oCycle = { DYN_Ctx, obj_DYN, removeDYNProps };
        let oDeCycled = JSON.decycleSimple(oCycle, ["State", "fact", "linkedRangesFacts", "Source"]);
        console.debug(`UpdateDynProps_ExtraContext  AFTER:`, oDeCycled);
    }

}



/*
* Imputs: DynamicButton (may be null), DynamicButtonDefinition, PGridFilter
* Output: DynamicButton properties 
*/

export function UpdateDynProps_DEBUG(debugObj) {
    let ret = null;
    let { DYN_Ctx, obj_DYN, removeDYNProps } = debugObj;
    UpdateDynProps(DYN_Ctx, obj_DYN, removeDYNProps)
    return obj_DYN;
}

export function UpdateDynProps(DYN_Ctx, obj_DYN, removeDYNProps = false) {

    if (obj_DYN == null) {
        if (window.PGridClientDebugMode >= 2) {
            console.debug(`UpdateDynProps: obj_DYNWithDynProps is null, returning`);
        }
        return;
    }

    if ((typeof obj_DYN) !== 'object') {
        if (window.PGridClientDebugMode >= 1) {
            console.debug(`UpdateDynProps: obj_DYNWithDynProps not an object, returning`);
        }
        return;
    }

    let currentDynButtonKeys = Object.keys(obj_DYN);
    for (let j = 0; j < currentDynButtonKeys.length; j++) {
        let currPropKey = currentDynButtonKeys[j];


        if (currPropKey.indexOf("_DYN") != -1) {


            let currPropKey_Dyn = currPropKey; // (())`${currPropKey}_DYN`;
            let currPropKey_Dyn_Target = currPropKey.replace(/_DYN$/, "");
            let currPropVal_Target = obj_DYN[currPropKey_Dyn_Target];

            window.PGridClientDebugMode >= 2 && console.debug(`UpdateDynProps(): currPropKey_Dyn: ${currPropKey_Dyn},  currPropKey_Dyn_Target: ${currPropKey_Dyn_Target}`);
            //Do Dynamic Extract of value
            let dynExt_Dyn = obj_DYN[currPropKey_Dyn]

            // DYN_Ctx.Source = obj_DYN; // Add source object

            let dynExt_ExtractedVal = DynExtract(DYN_Ctx, dynExt_Dyn);


            //Only update if target differs

            if (!lodash.isEqual(currPropVal_Target, dynExt_ExtractedVal)) {

                if (window.PGridClientDebugMode >= 2) {
                    let dbgStr = `UpdateDynProps():  [${currPropKey_Dyn_Target}] ${currPropVal_Target} -> ${dynExt_ExtractedVal}`
                    if (currPropKey_Dyn_Target in obj_DYN) {
                        dbgStr += ` overwriting`;
                    }
                    console.debug(`DynExtract: dbgStr: ${dbgStr}`);
                }

                obj_DYN[currPropKey_Dyn_Target] = dynExt_ExtractedVal;
            }


            if (removeDYNProps) {
                if (window.PGridClientDebugMode >= 2) {
                    console.debug(`removeDYNProps: removing key: ${currPropKey}`);
                }
                delete obj_DYN[currPropKey];
            }
        }

    }
    return;
}



// let DYN_Ctx_DynButton = {
// };



// function DynExtract(DYN_Ctx_Any, currPropKey_Dyn, currPropVal_Dyn, currPropKey, currPropVal) {

//     const getCircularReplacer = () => {
//         const seen = new WeakSet();
//         return (key, value) => {
//             if (typeof value === "object" && value !== null) {
//                 if (seen.has(value)) {
//                     return;
//                 }
//                 seen.add(value);
//             }
//             return value;
//         };
//     };

//     let Context_Type = DYN_Ctx_Any.Context_Type;

//     if (Context_Type == null) {
//         throw new Error(`DynExtract: Missing Context_Type property`);
//     }



//     switch (Context_Type) {
//         case 'DynamicButtons':

//             /*
//                 let currPropVal_Dyn: {

//                     0. Check Operation { leafOrTuple: 'leaf', 'tuple', 

//                                         --tuple
//                                         opType: 'bool_anyChild','bool_allChild',  
//                                         --leaf
//                                         opType: 'bool_pattern', 'bool_test', 'string_staticValue''string_replace'

//                                         if()

//                                         tuples : []



//                                          ifTypeMatchRe: 'SELECTED'
//                                          ifTypeMatch: 'SELECTED'

//                                          opSpread Single, Multiple }
//                     0. 
//                     1. Check Ret_Type { bool, string}
//                     2. 


//                 }

//             */




//             break;
//         default:
//             throw new Error(`DynExtract: Unknown Context_Type: '${Context_Type}'`);
//             break;
//     }

//     JSON.stringify(circularReference, getCircularReplacer());

//     console.debug(`DynExtract begin: Context_Type: ${Context_Type} : ${JSON.stringify({ currPropKey_Dyn, currPropVal_Dyn, currPropKey, currPropVal })}`, { currPropKey_Dyn, currPropVal_Dyn, currPropKey, currPropVal });


//     // case

//     return ret;

// }


export function Filter_CurrentSelectionTextHasStr(pgridFilter, patternText) {
    let ret = false;

    let selectionText = lodash.get(pgridFilter, "filterValue.text", null);
    if (selectionText != null) {
        for (var i = 0; i < patternText.length; i++) {
            if (selectionText.toUpperCase().indexOf(patternText[i].toUpperCase()) != -1) {
                ret = true;
            }
        }
    }
}

export function sum(a, b) {
    return a + b;
}



// module.exports = {
//     sum,
//     DynExtract,
//     UpdateDynProps,
//     Filtering_UpdateButtons,
// }


export default {
    sum,
    DynExtract,
    UpdateDynProps_ButtonList,
    UpdateDynProps_Button,
    UpdateDynProps_Generic,
    UpdateDynProps_ExtraContext,
    UpdateDynProps,
    UpdateDynProps_DEBUG,
    Filter_CurrentSelectionTextHasStr
}