<template>
  <div
    id="pgrid-root-container"
    class="pgrid rootContainer"
    :class="[
      'tabKey_' +
        (this.$store.state.pgridVars != null && this.$store.state.pgridVars.TabKey != null
          ? this.$store.state.pgridVars.TabKey.replaceAll('-', '_')
          : 'unknown'),
    ]"
  >
    <pgrid-filters />
    <pgrid-main />
    <!-- <pgrid-modal /> -->
  </div>
</template>
<script>
import PgridFilters from "./pgrid-filters.vue";
import PgridMain from "./pgrid-main.vue";
// import PgridModal from "./pgrid-modal.vue";
import lodash from "lodash";
// import "office-ui-fabric-vue/dist/index.css";

import Vue from "vue/dist/vue.js";

import PGridCss from "./pgridCssEditor.js";

// import vuexStore from './pgridVuexStore.js'
import OfficeUIFabricVue from "office-ui-fabric-vue";

import "office-ui-fabric-vue/dist/index.css";
import "../../static/styles/pgrid/pgrid.scss";

Vue.use(OfficeUIFabricVue);

export default {
  name: "pgrid-root-app",
  components: {
    PgridFilters,
    PgridMain,
    //  , PgridModal
  },
  computed: {
    async showPGridMain() {
      let filters = this.$store.state.pgridFilter.Filters;
      let filtersReadyStatus = filters.map((f, idx) => {
        return { idx: idx, title: f.title, isReady: false };
      });

      for (let i = 0; i < filters.length; i++) {
        let filter = filters[i];
        let isReady = false;

        if (lodash.get(filter, "MustHaveValue", true) == false) {
          isReady = true;
        } else {
          if (filter.type == "Dropdown") {
            for (let i = 0; i < filter.filterOptions.length; i++) {
              let fOpt = filter.filterOptions[i];
              if (fOpt.isSelected) {
                isReady = true;
              }
            }
          }
        }

        filtersReadyStatus[i].isReady = isReady;
      }

      let isAllFiltersReady = true;
      for (let i = 0; i < filtersReadyStatus.length; i++) {
        if (filtersReadyStatus[i].isSelected == false) {
          isAllFiltersReady = false;
        }
      }

      false && console.debug("DEBUG_STEP_4.5_pgrid-root-app-computed.showPGridMain()");

      return isAllFiltersReady;
    },
  },
  mounted: async function () {
    try {
      let isCssLoaded = PGridCss.CustomCssIsLoaded(this.$store.state.pgridVars.CustomerKey);
      if (isCssLoaded == false) {
        await PGridCss.LoadPGridCss(this.$store.state.pgridVars.CustomerKey); //FUB
      } else {
        window.PGridClientDebugMode >= 3 && console.debug("CustomCssIsLoaded() custom css was already loaded");
      }
    } catch (err) {
      if (err.stack.indexOf("JwtToken could not be verified. Returning 401") != -1) {
        //FUB
        console.warn(`PGrid PGridCss.LoadPGridCss() was not using a valid JwtToken`);
      }
    }
  },
};
</script>
<style>
#pgrid-root-container {
  /* transform: scale(0.8);
  transform-origin: 0 0; */

  margin-top: 3px; /*3.2px; */ /*Some uneaven offset is (or was?) needed to avoid unwhanted light shadows in cells*/
  margin-left: 8px; /*8.7px; */ /*Some uneaven offset is (or was?) needed to avoid unwhanted light shadows in cells*/
}
</style>
