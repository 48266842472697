import { PGridLR_Base } from './pgridLR_Base.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_ApexCharts_Schema from './PGridLR_ApexCharts-schema.json';
import lodash from 'lodash';
import pgridCell, { expandIfNeeded, indexOfDSProp, mergeCells } from './pgridCell.js';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'

import { extractLabel } from '../formula-parser/helper/cell.js';
import { mark } from 'regenerator-runtime';


export class PGridLR_ApexCharts extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_ApexCharts_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            // COLUMNS: { type: 'COLUMNS', mapsTo: null },  //'Column' is a property in the linked range definition
            FACTS: { type: 'FACTS', mapsTo: null }
        }

        this.xLength = 1;
        this.yLength = 1;

        // this.xLengthSpan = 7;
        // this.yLengthSpan = 8;

        this.apexCharts_yLengthSpan = lodash.get(this.linkedRange, "ApexCharts.PGrid.SpanRows", null);
        this.apexCharts_xLengthSpan = lodash.get(this.linkedRange, "ApexCharts.PGrid.SpanColumns", null);
        this.apexCharts_options = lodash.get(this.linkedRange, "ApexCharts.options", null);

        this.xStart = null;
        this.yStart = null;

        this.Overlay = lodash.get(this.linkedRange, "Overlay", null);

        if (!this.ApexChartsMap) {
            this.ApexChartsMap = [];
        }
    }

    whoAmI() {
        return 'I am a PGridLR_ApexCharts'
    }

    // Nothing to return
    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {
        console.warn(`ApexCharts.Phase1_Get_DataSetDefs()`);
        let ret = Array();
        return ret;
    }

    // Nothing to to here
    Phase2_Load_DimDataInAxisData(pgridDim) {
        console.warn(`ApexCharts.Phase2_Load_DimDataInAxisData()`);
        return null;
    }

    // Nothing to to here
    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {
        console.warn(`ApexCharts.Phase3_Insert_DynDimData()`);

        let ret = { pgridTableDyn: pgridTableDyn, addedRowsOffset: 0, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }
        return ret;
    }

    // Nothing to to here
    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {
        console.warn(`ApexCharts.Phase4_Insert_Metadata()`);


        let lrParsed = PGridMatrices.Get_LRsFromTable(pgridTable, { onlyThisLR: this.name, source: `pgridLR_ApexCharts.js Phase3_Insert_DynDimData() name: ${this.name}` });

        this.y = lrParsed.y;



        //POC hack to make span work
        let pcell = pgridTable[this.y][this.x];


        lodash.set(pcell, "Meta.Validator.Type", "apexcharts");
        lodash.set(pcell, "Meta.SpanRows", this.apexCharts_yLengthSpan - 1);
        lodash.set(pcell, "Meta.SpanColumns", this.apexCharts_xLengthSpan - 1);
        lodash.set(pcell, "Meta.ApexCharts.options", this.apexCharts_options);

        //Add rows
        PGridMatrices.AddEmptyRow(pgridTable, this.apexCharts_yLengthSpan, this.y + 1);

        return pgridTable;
    }

    // Nothing to to here
    Phase6_Generate_FactRange(pgridDataDyn, factData) {
        console.warn(`ApexCharts.Phase6_Generate_FactRange()`);
        let factRangeData = [[undefined]];
        return factRangeData;
    }

}