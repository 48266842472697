var lang = {
    login : {
        office365 : "Log in with Office 365"
    },
    main : {
        title : "Business Vision | RE(a)ACT"
    },
    error : {
        notfound : "Page could not be found!",
        userExists : "User already exists!"
    },
    settings : {
        save : "SAVE",
        apply : "APPLY",
        discard : "DISCARD",
        fileDrop : "Drop file here",
        upload : "Upload file",
        workOrSchoolAccount : "Work or school account",
        microsoftAccount : "Microsoft account",
        statusEnabled : "Enabled",
        statusDisabled : "Disabled",
        addRole : "Create new role",
        cancel : "Cancel",
        topbar : {
            userSwitch : "Users",
            roleSwitch : "Roles"
        },
        sidepanel : {
            assignedMembersTitle : "MEMBERS",
            notAssignedMembersTitle : "NOT ASSIGNED",
            assignedRolesTitle : "USER ROLE(S)",
            notAssignedRolesTitle : "AVAILABLE ROLES",
            userName : "Name",
            userEmail : "Email",
            userWorkPhone : "Work Phone",
            userMobilePhone : "Mobile Phone",
            userAdd : "ADD USER",
            groupAccessSuccess : "Connection to group successful",
            groupAccessFailed : "Connection to group failed"
        },
        table : {
            permissions: {
                headerRoleName: "ROLE NAME"
            },
            roles : {
                headerName : "NAME",
                headerLevelDescription : "LEVEL DESCRIPTION",
                headerAssignedUsers : "ASSIGNED USERS"
            },
            users : {
                headerName : "NAME",
                headerEmail : "EMAIL",
                headerRoles : "ROLE(S)"
            },
            usermgt : {
                headerName : "NAME",
                headerEmail : "EMAIL",
                headerLoginType : "LOGIN TYPE"
            },
            reaact : {
                headerService : "SERVICE",
                headerApp : "APPLICATION",
                headerTab : "TAB",
                headerName : "REPORT NAME"
            }
        },
        report : {
            name : "Report Name",
            desc : "Report Description",
            group : "Power BI Group ID",
            report : "Power BI Report ID",
            save : "Save",
            choosePbiTenant : "Choose Power BI Tenant",
            orCreateNewPbiTenant : "or create new",
            pbiTenantName : "Power BI Tenant name",
            savePbiTenant : "Save Power BI Tenant"
        },
        documents : {
            deleteConfirmationTitle : "Are you sure?",
            deleteConfirmationContent : " object(s) will be removed.",
            moreInfo : "Show more information",
            table : {
                headerFilename: "Filename",
                headerCreated: "Created",
                headerLastModified: "Modified",
                headerDescription: "Description",
                headerUploadedBy: "Uploaded by"
            },
            buttons : {
                refresh: "Refresh",
                upload: "Upload",
                delete: "Delete"
            },
            textbox : {
                selectedFile: "Selected file"
            }
        }
    }
}

module.exports = {
    lang
}