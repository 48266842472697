import { PGridLR_Base } from './pgridLR_Base.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_MetaCell_Schema from './PGridLR_MetaCell-schema.json';
import lodash from 'lodash';
import pgridCell, { expandIfNeeded, indexOfDSProp, mergeCells } from './pgridCell.js';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'

import { extractLabel } from '../formula-parser/helper/cell.js';
import { mark } from 'regenerator-runtime';


export class PGridLR_MetaCell extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_MetaCell_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            // COLUMNS: { type: 'COLUMNS', mapsTo: null },  //'Column' is a property in the linked range definition
            FACTS: { type: 'FACTS', mapsTo: null }
        }

        this.xLength = 1;
        this.yLength = 1;
        this.xStart = null;
        this.yStart = null;

        this.Overlay = lodash.get(this.linkedRange, "Overlay", null);

        if (!this.MetaCellMap) {
            this.MetaCellMap = [];
        }
    }

    whoAmI() {
        return 'I am a PGridLR_MetaCell'
    }

    // Nothing to return
    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {
        console.warn(`MetaCell.Phase1_Get_DataSetDefs()`);
        let ret = Array();
        return ret;
    }

    // Nothing to to here
    Phase2_Load_DimDataInAxisData(pgridDim) {
        console.warn(`MetaCell.Phase2_Load_DimDataInAxisData()`);
        return null;
    }

    // Nothing to to here
    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {
        console.warn(`MetaCell.Phase3_Insert_DynDimData()`);
        let ret = { pgridTableDyn: pgridTableDyn, addedRowsOffset: 0, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }
        return ret;
    }

    // Nothing to to here
    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {
        console.warn(`MetaCell.Phase4_Insert_Metadata()`);
        return pgridTable;
    }

    // Nothing to to here
    Phase6_Generate_FactRange(pgridDataDyn, factData) {
        console.warn(`MetaCell.Phase6_Generate_FactRange()`);
        let factRangeData = [[undefined]];
        return factRangeData;
    }

}