import { PGridLR_Base } from './pgridLR_Base.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_MasterDataGrid_Schema from './PGridLR_MasterDataGrid-schema.json';
import lodash from 'lodash';
import pgridCell, { expandIfNeeded, indexOfDSProp, mergeCells } from './pgridCell.js';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'

import { extractLabel } from '../formula-parser/helper/cell.js';
import pgridMatrices from './pgridMatrices.js';


export class PGridLR_MasterDataGrid extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_MasterDataGrid_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            MDG_COLUMNS: { type: 'MDG_COLUMNS', mapsTo: 'TableData', IsTableAxis: true, IsTableColumnOnlyAxis: true },  //'Column' is a property in the linked range definition
            MDG_ROWS_IDS: { type: 'MDG_ROWS_IDS', mapsTo: 'TableData', IsTableAxis: true, IsTableIdOnlyAxis: true /* , FirstRowIsColumns: true */ } //,
            // MDG_FACTS: { type: 'FACTS', mapsTo: 'TableData' }
        }

        // this.Templates = lodash.get(this.linkedRange, "Templates", null);
        // this.MasterDataGridMap = lodash.get(this.linkedRange, "MasterDataGridMap", null);
        this.xStart = null;
        this.yStart = null;

        // if (!this.Templates) {
        //     this.Templates =
        //         //Default template for inserting dim metadata for facts load/save
        //         [
        //             {
        //                 "Name": "Default",
        //                 "Description": "Default template",
        //                 "Sort": 1,
        //                 "PrimaryTemplate": true,
        //                 "Rule": {
        //                     "If_Axis": "Columns",
        //                     "If_Level": "any"
        //                 },
        //                 "Insert": {
        //                     "LevelPosition": "MasterDataGridMapOneLeft",
        //                     "InNewRow": false,
        //                     "Merge": false,
        //                     "CellTemplate": {
        //                         "ValStr": "<<NODE_NAME>>"
        //                     }
        //                 }
        //             },
        //             {
        //                 "Name": "WriteCell1",
        //                 "Description": "Write cell",
        //                 "Sort": 2,
        //                 "PrimaryTemplate": false,
        //                 "Rule": {
        //                     "If_Axis": "RowsAndColumns",
        //                     "If_Level": "any"
        //                 },
        //                 "Insert": {
        //                     "LevelPosition": "MasterDataGridMap",
        //                     "InNewRow": false,
        //                     "Merge": true,
        //                     "CellTemplate": {
        //                         "Format": "writable",
        //                         "Meta": {
        //                             "DimVal": "<<NODE_VALUE>>",
        //                             "DimName": "<<PIVOTHIERARARCHY_NAME>>",
        //                             "LRName": "<<LINKEDRANGE_NAME>>"
        //                         }
        //                     }
        //                 }
        //             }
        //         ]
        // }

        // this.Overlay = lodash.get(this.linkedRange, "Overlay", null);


        // if (!this.MasterDataGridMap) {
        //     this.MasterDataGridMap = [];
        // }
    }

    whoAmI() {
        return 'I am a PGridLR_MasterDataGrid'
    }

    // FUB May be same as in EPivot, extract to a function?
    //Context: server
    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {

        let ret = Array();

        const dsType = "MDG_TableRecords"

        try {

            let addTableData = function (dtName, overrides) {

                let tableData = dataSetDefinitions.filter(dsd => {
                    return dsd.Type === "MDG_TableRecords" && dsd.Name == dtName
                });

                if (tableData.length != 1) {
                    throw new Error(`Could not find one  dataset of name '${dtName}' of type '${dsType}'`);
                }

                let dataTableDSDef = tableData[0];

                if (!(ret.find(x => x.DSName == dtName))) {
                    ret.push({ DSName: dtName, DSDef: dataTableDSDef, DSOverrides: overrides, LRName: me.name });
                }
            }

            const axisToLoad = Object.keys(this.AxisDefMap).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

            for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
                let atl = axisToLoad[i];
                let axisDef = me.linkedRange[atl.value.mapsTo]

                // let cols = me.linkedRange["Columns"];
                // let rows = me.linkedRange["Rows"];

                // let dsMDGListTableRecords = dataSetDefinitions.filter(dsd => {
                //     return dsd.Type === dsType // "MDG_TableRecords"
                // });


                // let axisObjs = [cols, rows];

                // axisObjs.forEach(axisDef => {
                if ("DataSetName" in axisDef) {

                    let dsName = axisDef.DataSetName;

                    // Sample:
                    // {
                    //     "Title": "Master Data Grid Manage View",
                    //     "Type": "MasterDataGrid",
                    //     "LinkedRangeName": "MDGTableView",
                    //     "TableData": {
                    //         "DataSetName": "MDG_ManageView"
                    //     },
                    // }


                    let overrides = lodash.get(axisDef, "Override", null);

                    addTableData(dsName, overrides);
                } else {
                    throw new Error(`Missing "PivotHierarchy" in LR ${me.name}`);
                }
                // });

            }


            let lookups = lodash.get(me, "linkedRange.Lookups", null);
            if (lookups) {
                if (lookups.AutoLoadFromTableRecord) {
                    //Dont process here
                } else {
                    new Error("lookups.AutoLoadFromTableRecord != true is not implemented");
                    // let lookupKeys = Object.keys(lookups);
                    // for (let l = 0; l < lookupKeys.length; l++) {
                    //     let lookup = lookups[lookupKeys[l]];
                    //     let dsName = lodash.get(lookup, "PivotHierarchy", null);
                    //     let overrides = lodash.get(lookup, "Override", null);
                    //     addPivotHierarchy(dsName, overrides);
                    // }
                }
            }


            // ret.push({ DSName: pH, DSDef: pivotHier, DSOverrides: overrides, LRName: me.name });


        } catch (err) {
            throw new Error(`PGridLR_MasterDataGrid:Phase1_Get_DataSetDefs() Could not get dataset with name: ${err.message || err}`);
        }

        return ret;
    }


    // FUB May be same as in EPivot, extract to a function?
    Phase2_Load_DimDataInAxisData(pgridDim) {

        const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

        for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
            let atl = axisToLoad[i].value;

            false && console.debug(`Phase2_Load_DimDataInAxisData() Processing ${atl.type}`)

            let axisDef = this.linkedRange[atl.mapsTo];


            if (lodash.get(axisDef, "MasterDataGrid", null) != null || lodash.get(axisDef, "DataSetName", null) != null) {



                let workDataSet = pgridDim[axisDef.DataSetName];
                let resDataSet = [];

                if (atl.IsTableColumnOnlyAxis) {
                    for (let j = 0; j < workDataSet[0].length; j++) {
                        resDataSet.push(workDataSet[0][j]); //Only one row
                    }
                }

                else if (atl.IsTableIdOnlyAxis) {

                    for (let j = 1 /*skip first header row*/; j < workDataSet.length; j++) {
                        //first column is assumed Identity
                        resDataSet.push(Object.values(workDataSet[j])[0]);
                    }
                }


                let dimAxisRes = {
                    // Name: `${pgridDSDefLvl.Name}`,//`${dsName}_LEVEL_${mdLvl.Level}_${mdLvl.Name}`,
                    // Header: {
                    //     Id: null,
                    //     Value: null,
                    //     Name: null,
                    //     Description: null
                    // },
                    // // Parent: null,
                    // // ParentChildInxLookup: null, //Fill here
                    DataSet: resDataSet

                }

                if (!(atl.type in this.axisData)) {
                    this.axisData[atl.type] = [];
                }
                this.axisData[atl.type].push(dimAxisRes);
            }

            else {
                throw new Error(`No dimensions specified for ${axisDef}`)
            }
        }
    }


    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {

        this.y = pgridMatrices.Get_LRsFromTable(pgridTableDyn, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase3_Insert_DynDimData() name: ${this.name}` }).y;

        let ret = { pgridTableDyn: null, addedRowsOffset: null, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }


        // alert("HEJSAN");

        // let pgridTableDynCopy =  JSON.parse(JSON.stringify(pgridTableDyn));
        let pgridTableDynCopy = pgridTableDyn;

        try {

            let currentAxis = this.axisData[pgridAxis.type]
            let currentAxisData = currentAxis[0].DataSet;

            if (pgridAxis.type == "MDG_COLUMNS") {
                this.xLength = Math.max(this.xLength, currentAxisData.length + 1);
            }
            if (pgridAxis.type == "MDG_ROWS_IDS") {
                this.yLength = Math.max(this.yLength, currentAxisData.length + 1 + 1 /* one extra room for the header*/);
            }


            pgridCell.expandIfNeeded(pgridTableDynCopy, this.yLength, this.xLength);


            if (pgridAxis.type == "MDG_COLUMNS") {
                for (let i = 0; i < currentAxisData.length; i++) {

                     //Main Column cell
                    pgridTableDynCopy[this.y][this.x + i] = pgridCell.ECellMerge(pgridTableDynCopy[this.y][this.x + i], { ValStr: currentAxisData[i], Format: "pg-is-colheader,pg-is-lr", cId: pgridTableDynCopy[this.y][this.x + i] });

                    //Top border
                    if (this.y >= 1) {
                        pgridTableDynCopy[this.y - 1][this.x + i] = pgridCell.ECellMerge(pgridTableDynCopy[this.y - 1][this.x + i], { Format: "pg-is-lr-just-above" });
                    }

                }
            }
            if (pgridAxis.type == "MDG_ROWS_IDS") {
                for (let i = 0; i < currentAxisData.length; i++) {

                    //Main ROW ID cell
                    pgridTableDynCopy[this.y + 1 + i][this.x] = pgridCell.ECellMerge(pgridTableDynCopy[this.y + 1 + i][this.x], { ValStr: currentAxisData[i], Format: "pg-is-lr", rId: pgridTableDynCopy[this.y + 1 + i][this.x - 1]  });

                    //Left border first row
                    if (this.y >= 1) {
                        pgridTableDynCopy[this.y + 1 + i - 1][this.x - 1] = pgridCell.ECellMerge(pgridTableDynCopy[this.y + 1 + i][this.x - 1], { Format: "pg-is-lr-just-left"});
                    }
                    //Left border the rest
                    if (this.x >= 1) {
                        pgridTableDynCopy[this.y + 1 + i][this.x - 1] = pgridCell.ECellMerge(pgridTableDynCopy[this.y + 1 + i][this.x - 1], { Format: "pg-is-lr-just-left" });
                    }

                }
            }

            ret.pgridTableDyn = pgridTableDynCopy;
            // ret.addedRowsOffset += accAddedRowsOffset

            ret.lrIsHidden = this.lrIsHidden;
            ret.lowerRightCoord.y = this.y + this.yLength;
            ret.lowerRightCoord.x = this.x + this.xLength;

        } catch (Phase3_Insert_DynDimData_error) {
            let errMsg = `> ${this.type}: Phase3_Insert_DynDimData_error: ${Phase3_Insert_DynDimData_error.message || Phase3_Insert_DynDimData_error}`;
            throw new Error(errMsg);
            this.errors.push(JSON.parse(JSON.stringify(Phase3_Insert_DynDimData_error.toString())));
        }

        return ret;
    }


    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {

        return pgridTable;
    }

    Phase6_Generate_FactRange(pgridDataDyn, factData) {

        let factRangeData = null;

        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_MasterDataGrid.js Phase6_Generate_FactRange() name: ${this.name}` }).y;

        //Make a copy
        // let pgridDataDynCopy = JSON.parse(JSON.stringify(pgridDataDyn));

        try {

            const factDataParsed = new Array();

            //Step 1 - parse fact data from db

            // let dimLookupTable = factData.DimLookup.sort((a, b) => {
            //     if (a.Id > b.Id) {
            //         return 1;
            //     }
            //     if (a.Id < b.Id) {
            //         return -1;
            //     }
            //     return 0;
            // }).map(o => o.DimName);

            let dimLookupTable = {};
            if ("DimLookup" in factData) {
                factData.DimLookup.forEach(o => {
                    dimLookupTable[o.Id] = o.DimName;
                });
            }


            if ("Rows" in factData) {
                //There are fact rows                            

                for (let k = 0; k < factData.Rows.length; k++) {
                    let fItem = factData.Rows[k];

                    let newData = {};


                    if ("V" /*"Val"*/ in fItem) {
                        // newData.Val = fItem.Val;
                        newData.Val = fItem.V;
                    }
                    if ("S" /*"ValStr"*/ in fItem) {
                        // newData.ValStr = fItem.ValStr;
                        newData.ValStr = fItem.S;
                    }


                    // let cellXYKey = JSON.parse(fItem.CellKey);
                    let cellXYKey = fItem.K;  //Handles the new non-stringified format


                    //Insert DimLookup values
                    for (let i = 0; i < cellXYKey.length; i++) {
                        cellXYKey[i].N = dimLookupTable[cellXYKey[i].D];
                    }


                    if (cellXYKey != null && Array.isArray(cellXYKey) && cellXYKey.length > 0) {

                        if (cellXYKey != null && length in cellXYKey) {
                        }
                        else {
                            false && console.warn("This clause gave false before");
                        }

                        for (let y = 0; y < cellXYKey.length; y++) {
                            //Only store dims not in filter
                            let keyId = null;
                            if (isNaN(cellXYKey[y].W)) {
                                keyId = cellXYKey[y].W;
                            }
                            else {
                                keyId = Number(cellXYKey[y].W);
                            }

                            if (!("Key" in newData)) {
                                newData.Key = {};
                            }
                            newData.Key[cellXYKey[y].N] = keyId;
                        }
                        factDataParsed.push(newData);
                    } else {
                        console.warn("Empty cellXYKey");
                    }
                }
            }

            const pgridDataLRCell = pgridDataDyn[this.y][this.x];

            //Only load if has Load meta in LRCell
            if (lodash.get(pgridDataLRCell, "Meta.Load", null) != null) {

                //Get stored LR dimension sizes
                this.yLength = pgridDataLRCell.Meta.Load.DimSizeY;
                this.xLength = pgridDataLRCell.Meta.Load.DimSizeX;

                //Step 2 - Initialize 2D data structure

                // factRangeData = [...Array(this.yLength)].map(x => Array(this.xLength).fill({})); // https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript
                factRangeData = [];

                for (let newY = 0; newY < this.yLength; newY++) {
                    let newRow = [];
                    for (let newX = 0; newX < this.xLength; newX++) {
                        newRow.push(undefined);
                    }
                    factRangeData.push(newRow);
                }

                let lookupX = {};
                let lookupY = {};

                // Step 2,3 was removed after optimization  
                // Step 4 fill in data
                for (let i = 0; i < Object.keys(this.MasterDataGridMap).length; i++) {
                    let fcAddr = this.MasterDataGridMap[Object.keys(this.MasterDataGridMap)[i]];

                    let readmetaY = null;
                    let readmetaX = null;
                    let insertY = null;
                    let insertX = null;

                    let regEx = /R(-?\d+)?C(-?\d+)/;
                    let match = regEx.exec(fcAddr);

                    if (match != null && match.length > 1) {

                        insertY = Number(match[1]);
                        insertX = Number(match[2]);
                        readmetaY = this.y + Number(match[1]);
                        readmetaX = this.x + Number(match[2]);

                    } else {

                        let [fcRow, fcCol] = extractLabel(fcAddr);
                        insertY = fcRow.index - this.y;
                        insertX = fcCol.index - this.x;
                        readmetaY = fcRow.index;
                        readmetaX = fcCol.index;

                    }

                    let fCell = pgridDataDyn[readmetaY][readmetaX];

                    //FUB Special case for MasterDataGrid, if Meta.Writable is string "null", then Meta.Save.Writable = true
                    let dimValWritable = lodash.get(fCell, "Meta.Writable", null);
                    if (dimValWritable === "null") {
                        dimValWritable = true;
                    }

                    if (dimValWritable !== null) {
                        lodash.set(pgridDataDyn[readmetaY][readmetaX], "Meta.Save.Writable", dimValWritable); //This write direct to pgridDataDyn
                    }


                    if (lodash.get(fCell, "Meta.DimVal", undefined) === undefined) {
                        console.warn(`PgridLR_MasterDataGrid() Missing "Meta.DimVal" for ${fcAddr}`);
                    } else {
                        //Not optimized
                        let lrName = lodash.get(fCell, "Meta.LRName", null);
                        let dimVal = lodash.get(fCell, "Meta.DimVal", null);
                        let dimName = lodash.get(fCell, "Meta.DimName", null);
                        if (lrName == this.name) {

                            for (let j = 0; j < Object.keys(factDataParsed).length; j++) {

                                let factD = factDataParsed[Object.keys(factDataParsed)[j]];

                                let factDKey = lodash.get(factD, `Key`, null);

                                if (Object.keys(factDKey).length != 1) {
                                    //Skipping, as this cannot be freecell facts, as it not only one dimension
                                } else {
                                    if (dimName in factDKey) {
                                        //LR Match!
                                        let factDKeyVal = factDKey[dimName];
                                        if (factDKeyVal == dimVal) {
                                            //Found match! insert value
                                            expandIfNeeded(factRangeData, insertY, insertY);
                                            let cellValue = pgridCell.Get_Val_For_PGrid_FromObj(factD);
                                            factRangeData[insertY][insertX] = cellValue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                console.warn("Missing Meta.Load");
            }
        } catch (ex) {
            console.error(`Get_FactsForLR_error - ${ex.message}, stack trace - ${ex.stack}`);
        }

        return factRangeData;

    }
}