<template>
  <div id="pgrid-main-container">
    <!-- pgridPanel -->

    <ou-panel size="md" fixed v-model="showPanel" title="Cell info">
      <pgrid-cell-panel />
    </ou-panel>

    <!-- showCssPanel -->
    <ou-panel size="md" fixed v-model="$store.state.pgridSettings.showCssPanel">
      <br />
      <table id="cssPanelSampleStyles" style="border-spacing: 0; width: 100%; font-size: 11px"></table>
      <br />

      <div class="stack-inline-space" />
      <pgrid-css-editor ref="pgridCssEditorRef" id="pgrid-css"></pgrid-css-editor>
      <div class="stack-inline-space" />

      <span id="cssError" style="color: #ad2117"></span>

      <div style="position: absolute; bottom: 10px; right: 10px">
        <ou-button
          id="saveCss"
          type="primary"
          class="stack-inline"
          @click="uiButtonSaveCss"
          v-show="$store.state.pgridSettings.viewMode == 'Default'"
          >Save</ou-button
        >
        <ou-button
          id="applyCss"
          type="primary"
          class="stack-inline"
          @click="uiButtonApplyCss"
          v-show="$store.state.pgridSettings.viewMode == 'Default'"
          >Apply</ou-button
        >
        <ou-button
          type="primary"
          class="stack-inline"
          @click="uiButtonBeautifyCss"
          v-show="$store.state.pgridSettings.viewMode == 'Default'"
          >Beautify</ou-button
        >
      </div>
    </ou-panel>

    <!-- pgridTmplInputBar -->
    <div class="pgridInputBarContainer">
      <div class="overlay-inputbar-loading" v-show="isLoading"></div>
      <textarea-pgrid v-bind:disabled="pgridInputBarDisabled" ref="pgridTmplInputBarRef" cols="80" />
    </div>

    <!--pgridTmplHT-->
    <div
      id="pgrid-my-hot"
      class=""
      v-bind:class="['pgrid-ht', pgridEditMode ? 'pgrid-edit-mode' : '', 'inputbarmode-' + $store.state.pgridSettings.inputBar_BarMode]"
      ref="pgridhttest"
    >
      <div class="overlay-loading" v-if="isLoading">
        <div class="lds-grid" v-if="isLoading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="bepatient">
          <div class="bepatient-value"></div>
        </div>
      </div>
      <div id="pgrid-hot" class="hot"></div>
    </div>

    <!-- pgridTmplRibbon -->
    <div class="pgrid-ribbon">
      <div v-show="pgridEditMode && false">
        <ou-pivot size="large" @click.native="method_switchViewMode">
          <ou-pivot-item label="Default"></ou-pivot-item>
          <ou-pivot-item label="Design"></ou-pivot-item>
          <ou-pivot-item label="Raw"></ou-pivot-item>
          <ou-pivot-item label="Formats"></ou-pivot-item>
          <ou-pivot-item label="Formulas"></ou-pivot-item>
          <ou-pivot-item label="Refs"></ou-pivot-item>
          <ou-pivot-item label="JSON"></ou-pivot-item>
        </ou-pivot>
      </div>
    </div>

    <!-- pgridTmplPrimarycmd -->
    <div class="pgrid-primarycommands">
      <!-- <ou-button v-show="pgridEditMode" type="primary" class="stack-inline" @click="uiButtonShowCellDef">Cell Panel</ou-button> -->
      <ou-button v-show="pgridEditMode" type="primary" class="stack-inline" @click="uiButtonShowCss">CSS Panel</ou-button>
      <ou-button v-show="pgridEditMode" type="primary" class="stack-inline" @click="uiButtonSavePGridData">Save template</ou-button>
      <!-- <ou-button v-if="pgridEditMode" type="primary" class="stack-inline" @click="uiButtonReset">Reset</ou-button> -->
      <ou-button v-show="pgridEditMode" type="primary" class="stack-inline" @click="uiButtonToggleSpanAndHidden">{{
        PGridTableShowHiddenMode ? "Hide hidden" : "Show hidden"
      }}</ou-button>
    </div>

    <!-- pgridTmplPreviewdata -->
    <div id="pgrid-debugdata"></div>
  </div>
</template>
<script>
import PGridUtils from "./pgridUtils";

import { Get_PGridCell_Value } from "./pgridCell";

import lodash, { functionsIn } from "lodash";

import Vue from "vue/dist/vue.js";
import Vuex from "vuex";

import TextareaPgrid from "./pgrid-textarea.vue";

import PgridCssEditor from "./pgridCssEditor";

import PgridCellPanel from "./pgrid-cellpanel.vue";

export default {
  name: "main-pgrid",
  data: function () {
    return {
      hotRef: null,
      pgridSettings: this.$store.state.pgridSettings,
    };
  },

  mounted: async function () {
    try {
      false && console.debug("pgrid-main mounted...............");
    } catch (err) {
      let errMsg = `> pgrid-main-container got exception: ${err.message || err}`;
      alert(errMsg);
    }
  },
  computed: {
    pgridEditMode: {
      get() {
        return this.$store.state.pgridSettings.PGridTableEditMode;
      },
    },
    PGridTableShowHiddenMode: {
      get() {
        return this.$store.state.pgridSettings.PGridTableShowHiddenMode;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.pgridSettings.isLoading || this.$store.state.pgridSettings.isSaving;
      }
    },
    showPanel: {
      get() {
        return this.$store.state.pgridSettings.showPanel;
      },
      set(value) {
        this.$store.commit("Mutation_UpdatePGridSettings", {
          prop: "showPanel",
          val: value,
          source: `pgrid-main.vue showPanel set`,
        });
      },
    },

    pgridInputBarDisabled: {
      get() {
        return this.$store.state.pgridSettings.inputBar_Enabled === false;
      },
    },

    pgridDimDisplay: {
      get() {
        return "";
      },
    },
  },
  methods: {
    method_switchViewMode: function (event) {
      console.log(`uiSwitchViewMode() event: ${JSON.stringify(event)}`);
      if (event.target.classList.contains("is-selected")) {
        let vMode = event.target.title;
        console.debug("method_switchViewMode(): switching to viewMode: " + vMode);
        let context = this.$store;

        setTimeout(async () => {
          context.commit("Mutation_UpdatePGridSettings", {
            prop: "viewMode",
            val: vMode,
            source: "method_switchViewMode",
          });
          console.debug("method_switchViewMode(): viewMode is: " + context.state.pgridSettings.viewMode);

          await context.dispatch("Action_PgridRESET", { type: "methodSwitchViewMode", source: "method_switchViewMode()" });

          if (true || vMode == "Default") {
            //Always do this, until bug is fixed
            await context.dispatch("Action_InjectAndRendrFilters", { source: "method_switchViewMode()" });
          } else {
            // await this.$store.dispatch("Action_Stage_Filter", {
            //   initTimestamp: context.state.pgridSettings.initOfLastQuery_Timestamp,
            //   source: `method_switchViewMode`
            // });
          }
        }, 1000);
      }
      return true;
    },
    uiButtonShowCellDef: function (event) {
      this.$store.dispatch("action_pgridUpdatePanel", true);
    },
    uiButtonShowCss: function (event) {
      this.$refs.pgridCssEditorRef.loadEditorCss();
      this.$store.dispatch("action_pgridUpdateCssPanel", true);
    },
    uiButtonToggleSpanAndHidden: function (event) {
      console.log("this.$store.state.pgridSettings.PGridTableShowHiddenMode:" + this.$store.state.pgridSettings.PGridTableShowHiddenMode);
      this.$store.dispatch("Action_PGridToggleSpansAndHidden", {
        disableSpanAndShowHidden: !this.$store.state.pgridSettings.PGridTableShowHiddenMode,
        source: `uiButtonToggleSpanAndHidden()`,
      });
    },
    uiButtonSavePGridData: function (event) {
      let initTimestamp = Date.now();
      this.$store.dispatch("action_SavePgridDataToDatabase", { initTimestamp });
    },
    uiButtonSaveFact: async function (event) {
      let context = this.$store;

      let initTimestamp = null;
      initTimestamp = Date.now(); //mili seconds
      let initUrl = null;
      initUrl = window.location.href;

      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Timestamp",
        val: initTimestamp,
        source: `uiButtonSaveFact()`,
      });
      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Url",
        val: initUrl,
        source: `uiButtonSaveFact()`,
      });

      await this.$store.dispatch("Action_Calc_AllOrChanges", {
        initTimestamp: initTimestamp,
        initUrl: initUrl,
        source: "uiButtonSaveFact",
      });

      console.log("uiButtonSaveFact() forced a calculation");

      this.$store.state.hotRef.validateCells((valid) => {
        if (valid) {
          this.$store.dispatch("action_SavePgridFactToDatabase");
        } else {
          alert("Fel upptäckta i tabellen. Sparning avbruten");
        }
      });
    },
    uiButtonSaveCss: function (event) {
      this.$store.dispatch("action_SavePgridCssToDatabase");
    },

    uiButtonApplyCss: function (event) {
      this.$store.dispatch("action_ApplyPgridCss");
    },
    uiButtonBeautifyCss: function (event) {
      PgridCssEditor.BeautifyCss();
    },

    uiButtonReset: async function (event) {
      let context = this.$store;

      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Timestamp",
        val: initTimestamp,
        source: `pgrid-main.vue uiButtonReset()`,
      });

      await this.$store.dispatch("Action_InjectAndRendrFilters", { source: "pgrid-main.vue uiButtonReset()" });
    },
  },

  components: { TextareaPgrid, PgridCellPanel, PgridCssEditor },
};
</script>
<style scoped></style>
