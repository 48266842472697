import { SavePGridFact, HandlePGridEvent } from './pgridBackendAccess.js';

import { PGridLR_EPivot } from './pgridLR_EPivot.js';
import { PGridLR_FreeCell } from './pgridLR_FreeCell.js';
import { PGridLR_MetaCell } from './pgridLR_MetaCell.js';
import { PGridLR_ApexCharts } from './pgridLR_ApexCharts';
import { PGridLR_MasterDataGrid } from './pgridLR_MasterDataGrid.js';

import { pgridCellHasValue, pgridCellGetOnlyValOrValStr, expandIfNeeded } from './pgridCell.js';
import { extractLabel } from '../formula-parser/helper/cell.js';
import PGridUtils from './pgridUtils.js';

import PGridDynExtract from './pgridDynExtract.js'

import lodash from 'lodash';
import uuidv1 from 'uuid/v1.js';

const DimCountY_MAX = 10; //Maximum dimensions in Y axis supported
const DimCountX_MAX = 10; //Maximum dimensions in X axis supported

function isEmpty(str) {
    return (!str || 0 === str.length);
}

/*
Function: Takes a grid definition and datasets and inserts dynamic data from RefType cells (LinkedRange)
Returns: a new grid
Notes: Does not manipulate parameters
*/
export async function Load_Phase2to4(pgridTableStaticOrDynamic, pgridDim, state, commit, source = `<Load_Phase2to4() has no source>`) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridMatrices.js Load_Phase2to4() - ${source}`);
    let pgridTableDyn = null;

    try {

        pgridTableDyn = lodash.clone(pgridTableStaticOrDynamic);
        const foundLRs = Get_LRsFromTable(pgridTableStaticOrDynamic, { source: `${source} -> Load_Phase2to4()` }); // Get all linked ranges

        let LowestRight_Static = { y: 0, x: 0 };
        for (let y = 0; y < pgridTableDyn.length; y++) {
            for (let x = 0; x < pgridTableDyn[0].length; x++) {
                if (pgridTableDyn[y][x]) {

                    let tmpCell = lodash.clone(pgridTableDyn[y][x])

                    LowestRight_Static.y = Math.max(LowestRight_Static.y, y);
                    LowestRight_Static.x = Math.max(LowestRight_Static.x, x);

                    //Keep track of static data for saving PGridTable (not facts)
                    lodash.set(pgridTableDyn[y][x], "Meta.StaticSrc", { y, x });
                    lodash.set(pgridTableDyn[y][x], "Meta.StaticSrc.Data", tmpCell);
                }
            }
        }


        // ** Then insert Data to them
        let foundLRs_Keys = Object.keys(foundLRs);
        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];


            try {
                false && console.debug(`Load_Phase2to4() ${lr.name}:${lr.type} Phase2_Load_DimDataInAxisData axisData = `, lr.axisData);
                lr.Phase2_Load_DimDataInAxisData(pgridDim);
            } catch (err) {
                let errMsg = `> Load_Phase2to4() lr.Phase2_Load_DimDataInAxisData(pgridDim) got exception: ${err.message || err}`;
                throw new Error(errMsg);
            }

        }


        // ** Lastly update the grid with the data collected in the linked ranges

        // let addedRowsOffsetLR = 0;

        let LowestRightNonHiddenLR = { y: 0, x: 0 }

        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];


            if (!("Type" in lr.linkedRange)) {
                nonHaltErrorMessage += `LinkedRange on row ${y} col ${xSearch} is missing its Type property`
            }


            // Process columns & 
            // Process rows, will also add rows to grid

            // lr.RowsOffset = addedRowsOffsetLR; //Offset from last lr processing  // Was alwatys 0

            if (lr.AxisDefMap) {

                let StartTime_Phase3_Insert_DynDimData = Date.now();

                let lrAxisDefMapaObject_Keys = Object.keys(lr.AxisDefMap);
                for (let i = 0; i < lrAxisDefMapaObject_Keys.length; i++) {
                    let pgridAx = lr.AxisDefMap[lrAxisDefMapaObject_Keys[i]];

                    if (pgridAx.IsTableAxis) {
                        let res = await lr.Phase3_Insert_DynDimData(pgridTableDyn, pgridAx, 0 /*addedRowsOffsetLR Was alwatys 0 */, state);
                        pgridTableDyn = res.pgridTableDyn;
                        //  addedRowsOffsetLR += res.addedRowsOffset; Was alwatys 0

                        //Adjust the lowest static cell if added rows above
                        if (res.addedRowsOffset > 0 && lr.y < LowestRight_Static.y) {
                            LowestRight_Static.y += res.addedRowsOffset;
                        }

                        // if (pgridAx.type == "ROWS") {

                        //     //Adjust the lowest static cell if added rows above (no likely)
                        //     if (res.addedRowsOffsetLR_0 > 0 && lr.y < LowestRight_Static.y) {
                        //         LowestRight_Static.y += res.addedRowsOffsetLR_0;
                        //     }

                        //     //Adjust the  static cell if added rows above (no likely)
                        //     if (res.addedRowsOffsetLR_0 > 0) {

                        //         let updatedLRCoordinatesHints = state.pgridLRCoordinatesHints;
                        //         let hints_Keys = Object.keys(updatedLRCoordinatesHints);
                        //         let updatedHints = false;

                        //         for (let i = 0; i < hints_Keys.length; i++) {
                        //             let hint = updatedLRCoordinatesHints[hints_Keys[i]];

                        //             if (lr.y < hint.y) {
                        //                 hint.y += res.addedRowsOffsetLR_0;
                        //                 updatedHints = true;
                        //             }
                        //         }

                        //         if (updatedHints) {
                        //             commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: updatedLRCoordinatesHints, source: `${source} -> Load_Phase2to4()` });
                        //         }

                        //     }
                        // }


                        if (!res.lrIsHidden) {
                            LowestRightNonHiddenLR.x = Math.max(LowestRightNonHiddenLR.x, res.lowerRightCoord.x);
                            LowestRightNonHiddenLR.y = Math.max(LowestRightNonHiddenLR.y, res.lowerRightCoord.y);
                        }
                    }
                }

                if (window.PGridClientDebugMode >= 2) {
                    console.debug(`[Phase3_Insert_DynDimData] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase3_Insert_DynDimData) / (100)))} 10th: s`); // in seconds
                }
            }


            let StartTime_Phase3_1_Update_HOTSettings = Date.now();
            { // Process facts cells here (but will not insert values)
                // console.error(`Load_Phase2to4() This (Phase3_1_Update_HOTSettings) gets called to late, should be called before Action_ApplyLRPgridCss  ${source} -> Load_Phase2to4()`)
                lr.Phase3_1_Update_HOTSettings(commit, `${source} -> Load_Phase2to4()`);
            }
            if (window.PGridClientDebugMode >= 2) {
                console.debug(`[Phase3_1_Update_HOTSettings] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase3_1_Update_HOTSettings) / (100)))} 10th: s`); // in seconds
            }

            let StartTime_Phase4_Insert_Metadata = Date.now();
            { // Process facts cells here (but will not insert values)
                pgridTableDyn = lr.Phase4_Insert_Metadata(pgridTableDyn, lr.AxisDefMap.FACTS, lr);
            }
            if (window.PGridClientDebugMode >= 2) {
                console.debug(`[Phase4_Insert_Metadata] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase4_Insert_Metadata) / (100)))} 10th: s`); // in seconds
            }
        }

        // console.warn(`LowestRightNonHiddenLR: ${JSON.stringify(LowestRightNonHiddenLR)}`);
        // commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: foundLR, op: 'push', source: `${source} -> FindLRCoordinates()` });
        // commit('Mutation_UpdatePGridSettings', { prop: 'LowestRightNonHiddenLR', val: LowestRightNonHiddenLR, source: `${source} -> Load_Phase2to4()` });
        if (LowestRightNonHiddenLR.y != null) {
            commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenLR', val: LowestRightNonHiddenLR, source: `${source} -> Load_Phase2to4()` });
        }

        let LowestRightNonHiddenCell = {
            y: Math.max(LowestRightNonHiddenLR.y, LowestRight_Static.y),
            x: Math.max(LowestRightNonHiddenLR.x, LowestRight_Static.x)
        }

        commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenCell', val: LowestRightNonHiddenCell, source: `${source} -> Load_Phase2to4()` });


        // PGridVueStore.state.LowestRightNonHiddenLR = LowestRightNonHiddenLR;
        // * Collect any error messages

        let allErrors = Array();

        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let foundLR = foundLRs[foundLRs_Keys[i]];

            if (foundLR.errors.length > 0) {
                //Fond errors

                allErrors.push(JSON.parse(JSON.stringify({
                    linkedRange: foundLR.name,
                    x: foundLR.x,
                    y: foundLR.y,
                    errors: foundLR.errors
                })));
            }
        }


        if (allErrors.length) {
            console.warn(`Load_Phase2to4_error: Found errors in: ${JSON.stringify(allErrors, null, 2)}`);
        }

        window.PGridClientDebugMode >= 3 && console.debug(`pgridMatrices.js Load_Phase2to4() exits`);

    } catch (err) {
        let errMsg = `> Load_Phase2to4 got exception: ${err.message || err}`;
        throw new Error(errMsg);
    }

    return pgridTableDyn;

}

export function Get_LRFromTableCoordinates(y, x, pgridTableStaticOrDynamic) {

    let newLRObj = null;

    let pcell = pgridTableStaticOrDynamic[y][x];

    if (pcell && pcell.hasOwnProperty("RefType") && pcell.RefType.indexOf("{") >= 0) {

        let linkedRangeDef = null;

        try {
            linkedRangeDef = JSON.parse(pcell.RefType)
        } catch (error) {
            console.error(`Could not JSON.parse(pcell.RefType) #1:\n\npcell.RefType: "${pcell.RefType}"\n\nError: ${error.toString()}`);
        }

        if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("Type"))) {
        } else {
            console.error("Linked range definitino is missing a \"Type\"");
        }


        if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("LinkedRangeName") /*|| pCellJSON.hasOwnProperty("output") */)) {

            try {
                linkedRangeDef = JSON.parse(pcell.RefType)
            } catch (error) {
                console.error(`Could not JSON.parse(pcell.RefType) #2:\n\npcell.RefType: "${pcell.RefType}"\n\nError: ${error.toString()}`);
            }

            if (linkedRangeDef != null && ("Type" in linkedRangeDef)) {

                newLRObj = NewPGridLRTypeObj(linkedRangeDef.Type, y, x, pcell, pgridTableStaticOrDynamic)

            } else {
                console.error(`Linked range "Type" is missing in pcell.RefType: ${pcell.RefType}`)
            }
        }
    }

    return newLRObj;
}



/*
Function: Search for linked ranges in a static (non rendered grid)
Returns:  returs a object of linked range plus some pre render meta data (PGType_LinkedRangePreRenderMeta)
*/
//Context: server, client
export function Get_LRsFromTable(pgridData, opt = { onlyThisLR: null, parse: true, validateCache: true, source: `<Get_LRsFromTable no source specified>`, context: null }) {

    try {

        let ret = null;

        if (opt.onlyThisLR === undefined) {
            opt.onlyThisLR = null;
        }
        if (opt.parse === undefined) {
            opt.parse = true;
        }
        if (opt.validateCache === undefined) {
            opt.validateCache = true;
        }
        if (opt.source === undefined) {
            opt.source = `<Get_LRsFromTableCached() no source specified>`;
        }
        if (opt.context === undefined) {
            opt.context = null;

            if ("PGridVueStore" in window) {
                opt.context = PGridVueStore;
            }
        }

        (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX:Z Get_LRsFromTable() opt: ${JSON.stringify({ "onlyThisLR": opt.onlyThisLR, "validateCache": opt.validateCache })} source: ${opt.source}`);


        let pcellCpy = null;


        let iterations = 0;
        //Almost same as resolver.js:extract_LinkedDataSources() merge in future?


        if (pgridData == null) {
            throw new Error(`Get_LRsFromTable() pgridData == null`);
        }

        if (opt.onlyThisLR) {
            /********* SINGLE RESULT MODE ******/
            let ret_single = null;

            // 1:st look in hints

            let foundInHints = null;;

            if (opt.context) {

                if (opt.context.state.pgridLRCoordinatesHints && opt.onlyThisLR in opt.context.state.pgridLRCoordinatesHints) {

                    foundInHints = opt.context.state.pgridLRCoordinatesHints[opt.onlyThisLR];

                    // for (let i = 0; i < opt.context.state.pgridLRCoordinatesHints.length; i++) {

                    //     let hint = opt.context.state.pgridLRCoordinatesHints[i];
                    //     if (hint.name == linkedRangeName) {
                    //         foundInHints = hint;
                    //     }
                    // }
                }
            }

            //2:nd Check if hint valud is correct
            if (opt.validateCache && !!foundInHints) {
                let pcell = lodash.get(pgridData, `[${foundInHints.y}][${foundInHints.x}]`, null);

                let cacheValid = false;
                if (!!pcell && "RefType" in pcell) {
                    let pcell_refType = JSON.parse(pcell.RefType);
                    if (opt.onlyThisLR == pcell_refType.LinkedRangeName) {
                        //Cache was valid
                        cacheValid = true;
                    }

                }

                if (cacheValid) {
                    ret_single = foundInHints;
                } else {
                    //Cache was not valid
                    (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX: Z Get_LRsFromTable() Cache is invalid`);
                    foundInHints = null;

                    if (opt.context) {
                        opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: [], source: `${opt.source} -> Get_LRsFromTable()` });
                    }
                }
            }

            if (ret_single == null) {
                //3:rd No luck with cache, Look it up using iterations
                SEACHROWS: for (let y = 0, yOffset = 0; y < pgridData.length; y++) {

                    if (pgridData[y] != null) {
                        for (let xSearch = 0; xSearch < pgridData[y].length; xSearch++) {

                            let pcell = pgridData[y][xSearch];

                            iterations++;

                            if (pcell != undefined && ("RefType" in pcell) && pcell.RefType.indexOf("{") >= 0) {
                                if (opt.parse) {

                                    let [lrName, lrObj] = ParseLR(pgridData, pcell, y, xSearch);

                                    if (!lrName) {
                                        throw new Error(`lrName was not defined`);
                                    }

                                    if (lrName == opt.onlyThisLR) {
                                        ret_single = lrObj
                                        continue SEACHROWS;
                                    }

                                } else {
                                    //Only get y,x and name
                                    let pcell_RefType = JSON.parse(pcell.RefType);
                                    let lrName = pcell_RefType.LinkedRangeName;
                                    if (lrName == opt.onlyThisLR) {
                                        // retFoundLRs[lrName] = { y: y, x: xSearch, name: lrName };
                                        ret_single = { y: y, x: xSearch, name: lrName };
                                        continue SEACHROWS;
                                    }
                                }
                            }
                        }
                    }
                }

                //Update cache
                if (!!ret_single && opt.context) {
                    let pgridLRCoordinatesHintsForCache = opt.context.state.pgridLRCoordinatesHints;
                    pgridLRCoordinatesHintsForCache[ret_single.name] = ret_single;
                    opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: pgridLRCoordinatesHintsForCache, source: `${opt.source} -> Get_LRsFromTable()` });
                }
            }


            ret = ret_single;
        } else {


            /********* MULTI RESULT MODE ******/

            let ret_foundLRs = null;

            // 1:st look in hints

            let foundInHints = {};

            if (opt.context) {

                if (opt.context.state.pgridLRCoordinatesHints && opt.onlyThisLR in opt.context.state.pgridLRCoordinatesHints) {

                    foundInHints = opt.context.state.pgridLRCoordinatesHints;

                    // for (let i = 0; i < opt.context.state.pgridLRCoordinatesHints.length; i++) {

                    //     let hint = opt.context.state.pgridLRCoordinatesHints[i];
                    //     if (hint.name == linkedRangeName) {
                    //         foundInHints = hint;
                    //     }
                    // }
                }
            }

            //2:nd Check if hint valud is correct
            if (opt.validateCache) {

                let cacheValid = false;
                let foundInHints_Keys = Object.keys(foundInHints);
                if (foundInHints_Keys.length > 0) {
                    cacheValid = true; //True until proven false by any item
                    SEACHCACHE: for (let i = 0; i < foundInHints_Keys.length; i++) {

                        let foundInHints_item_key = foundInHints_Keys[i];
                        foundInHints_Item = foundInHints[foundInHints_item_key];

                        let pcell = lodash.get(pgridData, `[${foundInHints_Item.y}][${foundInHints_Item.x}]`, null);

                        if ("RefType" in pcell) {
                            let pcell_refType = JSON.parse(pcell.RefType);
                            if (foundInHints_Item.name == pcell_refType.LinkedRangeName) {
                                //Cache is still valid
                            } else {
                                //Cache is in-valid
                                cacheValid = false;
                                continue SEACHCACHE;
                            }
                        }
                    }
                }
                if (cacheValid) {
                    ret_foundLRs = foundInHints;
                } else {
                    //Cache was not valid
                    (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX: Z Get_LRsFromTable() Cache is invalid`);
                    foundInHints = null;

                    if (opt.context) {
                        opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: [], source: `${opt.source} -> Get_LRsFromTable()` });
                    }

                }
            }

            if (ret_foundLRs == null) {
                //3:rd No luck with cache, Look it up using iterations
                for (let y = 0, yOffset = 0; y < pgridData.length; y++) {

                    if (pgridData[y] != null) {
                        for (let xSearch = 0; xSearch < pgridData[y].length; xSearch++) {

                            let pcell = pgridData[y][xSearch];

                            iterations++;


                            if (pcell != undefined && ("RefType" in pcell) && pcell.RefType.indexOf("{") >= 0) {
                                if (opt.parse) {

                                    let [lrName, lrObj] = ParseLR(pgridData, pcell, y, xSearch);

                                    if (!lrName) {
                                        throw new Error(`lrName was not defined`);
                                    }

                                    if (ret_foundLRs == null) {
                                        ret_foundLRs = {};
                                    }
                                    ret_foundLRs[lrName] = lrObj;
                                    // ret_single = lrObj
                                } else {
                                    //Only get y,x and name
                                    let pcell_RefType = JSON.parse(pcell.RefType);
                                    let lrName = pcell_RefType.LinkedRangeName;
                                    ret_foundLRs[lrName] = { y: y, x: xSearch, name: lrName };
                                    // ret_single = { y: y, x: xSearch, name: lrName };
                                }
                            }
                        }
                    }
                }

                if (opt.context) {
                    opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: ret_foundLRs, source: `${opt.source} -> Get_LRsFromTable()` });
                }


            }


            ret = ret_foundLRs;
        }

        (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX:Z Get_LRsFromTable iterations: ${iterations}`);


        if (ret == null) {
            ret = [];
        }

        return ret;

    } catch (err) {
        let errMsg = `> Get_LRsFromTable got exception: ${err.message || err}`;
        // console.error(errMsg)
        throw new Error(errMsg);
    }
}



function ParseLR(pgridData, pcell, y, x) {

    let ret = [null, null];

    let linkedRangeDef = null;

    try {
        linkedRangeDef = JSON.parse(pcell.RefType)
    } catch (error) {
        console.error(`Could not JSON.parse(pcell.RefType) #1: \n\npcell.RefType: "${pcell.RefType}"\n\nError: ${error.toString()}`);
    }

    if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("Type"))) {
    } else {
        console.error("Linked range definition is missing a \"Type\"");
    }

    if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("LinkedRangeName") /*|| pCellJSON.hasOwnProperty("output") */)) {

        // try {
        //     linkedRangeDef = JSON.parse(pcell.RefType)
        // } catch (error) {
        //     console.error(`Could not JSON.parse(pcell.RefType) #2: \n\npcell.RefType: "${pcell.RefType}"\n\nError: ${ error.toString() } `);
        // }

        let newLRObj = null;

        if (linkedRangeDef != null && ("Type" in linkedRangeDef)) {

            newLRObj = NewPGridLRTypeObj(linkedRangeDef.Type, y, x, pcell, pgridData)

        } else {
            console.error(`Linked range "Type" is missing in pcell.RefType: ${pcell.RefType}`)
        }

        // console.log("newLRObj.ToString(): " + newLRObj.whoAmI());

        // retFoundLRs[linkedRangeDef.LinkedRangeName] = newLRObj;
        ret = [linkedRangeDef.LinkedRangeName, newLRObj];
    }

    return ret;
}


export function Convert_pgridTableDynToPGridFactRows(pgridDataIn, pgridDim, source = `< Convert_pgridTableDynToPGridFactRows got no source > `) {

    let linkedRangesFactsCollections = {}; // = new Array();

    linkedRangesFactsCollections.Default = {
        overrideGridKey: null,
        overrideTabKey: null,
        overrideRemoveOrTranslateCellKeyDims: null,
        linkedRangesFacts: null,
    }

    linkedRangesFactsCollections["Default"].linkedRangesFacts = {}



    try {

        const foundLRs = Get_LRsFromTable(pgridDataIn, { source: `${source} -> Convert_pgridTableDynToPGridFactRows()` })

        console.debug("###################### collect facts #####################");

        let foundLRs_Keys = Object.keys(foundLRs);
        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];

            if (lr.lrIsReadOnly) {
                if (window.PGridClientDebugMode >= 1) {
                    console.debug(`Convert_pgridTableDynToPGridFactRows(): skipping write for readonly LR`);
                }
                continue;
            }

            let factsCollectName = "Default";

            if (lr.overrideGridKey != null || lr.overrideTabKey != null || lr.overrideRemoveOrTranslateCellKeyDims != null) {

                factsCollectName = "OverrideVars" + i;
                linkedRangesFactsCollections[factsCollectName] = {
                    overrideTabKey: lr.overrideTabKey,
                    overrideGridKey: lr.overrideGridKey,
                    overrideRemoveOrTranslateCellKeyDims: lr.overrideRemoveOrTranslateCellKeyDims,
                    linkedRangesFacts: {},
                    linkedRangeName: lr.name
                }
            }

            if (lr.type == "EPivot") {

                let rowLength = lodash.get(lr, "adjacentCells.selfCell.Meta.yLRLength", null);

                //Hack FUB
                if (rowLength == null) {
                    lr.adjacentCellsRecalc();
                    rowLength = lodash.get(lr, "adjacentCells.selfCell.Meta.yLRLength", null);
                }


                let colLength = lodash.get(lr, "adjacentCells.selfCell.Meta.xLRLength", null);

                let DSCol = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSCol", null);
                let DSRow = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSRow", null);

                let DSColHierarchyId = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSColHierarchyId", null);
                let DSRowHierarchyId = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSRowHierarchyId", null);

                if (DSCol == null) {
                    throw new Error(`${lr.name} adjacentCells.selfCell.Meta.Save.DSCol == null`);
                }
                if (DSRow == null) {
                    throw new Error(`${lr.name} adjacentCells.selfCell.Meta.Save.DSRow == null`);
                }

                for (var y = lr.y; y < lr.y + rowLength; y++) {
                    for (var x = lr.x; x < lr.x + colLength; x++) {


                        if (pgridDataIn[y][x] != null) {


                            if (pgridCellHasValue(pgridDataIn[y][x])) {


                                if (lodash.get(pgridDataIn[y][x], "Meta.Save.DimValX", undefined) != undefined
                                    &&
                                    lodash.get(pgridDataIn[y][x], "Meta.Save.DimValY", undefined) != undefined) {


                                    if (lodash.get(pgridDataIn[y][x], "Meta.Save.Writable", false) == true) {


                                        const lrCell = JSON.parse(JSON.stringify(pgridDataIn[y][x]));
                                        lrCell.y = y;
                                        lrCell.x = x;

                                        if (!(lr.name in linkedRangesFactsCollections[factsCollectName].linkedRangesFacts)) {
                                            let lrFacts = {
                                                name: lr.name, type: lr.type, factDims: {
                                                    column: DSCol,
                                                    row: DSRow,
                                                    colHierarchyId: DSColHierarchyId,
                                                    rowHierarchyId: DSRowHierarchyId
                                                }, facts: []
                                            }

                                            linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name] = lrFacts;
                                        }

                                        const lrCellValue = pgridCellGetOnlyValOrValStr(lrCell);
                                        lrCellValue.Meta = { Save: { DimValX: pgridDataIn[y][x].Meta.Save.DimValX, DimValY: pgridDataIn[y][x].Meta.Save.DimValY } };
                                        linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name].facts.push(lrCellValue)

                                    }

                                }
                            }
                        }

                    }
                }
            } else if (lr.type == "FreeCell") {

                let DSCol = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSCol", null); //Set in Phase4_Insert_Metadata
                let DSColHierarchyId = lodash.get(lr, "adjacentCells.selfCell.Meta.Save.DSColHierarchyId", null);


                // Create JSON structor for facts saving, if needed
                if (!(lr.name in linkedRangesFactsCollections[factsCollectName].linkedRangesFacts)) {

                    //Skipping row axis stuff
                    let lrFacts = {
                        name: lr.name, type: lr.type, factDims: {
                            column: DSCol,
                            colHierarchyId: DSColHierarchyId
                        }
                        , facts: []
                    }
                    linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name] = lrFacts;
                }

                let lrCoordFacts = GetFreeCellMapCellCoordVals(pgridDataIn, lr, true);

                let lrFacts_ValSaveMeta = lrCoordFacts.map(o => o[1]);

                console.debug(`Adding ${lrFacts_ValSaveMeta.length} facts from LR: ${lr.name}`);
                linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name].facts = lrFacts_ValSaveMeta;

            } else {
                console.warn(`Saving for ${lr.type} is not impemented yet`);
            }

        }


        false && console.log(`linkedRangesFactsCollections: ${JSON.stringify(linkedRangesFactsCollections, null, 2)}`);
    }
    catch (err) {
        let errMsg = `> Convert_pgridTableDynToPGridFactRows() got exception: ${err.message || err}`;
        throw new Error(errMsg);
    }
    return linkedRangesFactsCollections;
}


export function GetFreeCellMapCellCoordVals(pgridDataIn, lr = null, onlyWritable = true) {
    let ret = [];

    let lrFreeCellMap_Keys = Object.keys(lr.FreeCellMap);
    for (let i = 0; i < lrFreeCellMap_Keys.length; i++) {

        let addr = lr.FreeCellMap[lrFreeCellMap_Keys[i]];

        let srcCell =
            [
                {
                    index: parseInt(lr.y),
                    isAbsolute: false
                },
                {
                    index: parseInt(lr.x),
                    isAbsolute: false
                },
            ];


        let cellCorrd = extractLabel(addr, srcCell);

        let y = cellCorrd[0].index;
        let x = cellCorrd[1].index;


        // FUU const lrCell = JSON.parse(JSON.stringify(pgridDataIn[y][x]));
        const lrCell = lodash.clone(pgridDataIn[y][x]);
        lrCell.y = y;
        lrCell.x = x;

        const lrCellValue = pgridCellGetOnlyValOrValStr(lrCell);
        // Used by Convert_FactRowsDataJSONToDBFactData
        lrCellValue.Meta = {
            Save: {
                DimValX: pgridDataIn[y][x].Meta.DimVal
                /*.Meta.Save.DimValX , DimValY: pgridDataIn[y][x].Meta.Save.DimValY */
            }
        };

        // let metaWritable = lodash.get(pgridDataIn[y][x], "Meta.Writable", null);
        let metaSaveWritable = lodash.get(pgridDataIn[y][x], "Meta.Save.Writable", null)


        let doSaveValue = false;

        let isExplicitSave = false;

        if (String(metaSaveWritable) == "true") {
            isExplicitSave = true;
        }

        if (isExplicitSave) {
            doSaveValue = true;

        }

        if (doSaveValue /*onlyWritable*/) {
            // if (lodash.get(pgridDataIn[y][x], "Meta.Writable", false) == true) {
            // linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name].facts.push(lrCellValue)
            // VS keeps inserting spaces!!  ret.push([`${y}: ${x} `, lrCellValue]);
            ret.push([y + ":" + x, lrCellValue]);
            // }
        } //else don´t add 

    }

    return ret;
}

/*
Function: Save pgrid dynamn data  to backend
*/
export async function SavePgridFactToDatabase(context, pgridDataDynCont, pgridFilter, pgridVars, pgridDim, eventArgs = {}) {

    try {
        const batchId = uuidv1().toUpperCase();
        const timestampMS = Math.round(Date.now()); //ms seconds
        const timestamp = Math.round(timestampMS / 1000); //seconds

        context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: true, source: `SavePgridFactToDatabase()` });

        window.PGridClientDebugMode >= 2 && console.log(`\nNew BatchId: ${batchId}, Timestamp: ${timestamp} \n`);


        const linkedRangesFactsCollections = Convert_pgridTableDynToPGridFactRows(pgridDataDynCont, pgridDim, `SavePgridFactToDatabase()`);

        let pgridFilterSelection = PGridUtils.Filter_ExtractPGridFiltersSelection(pgridFilter, false);


        window.PGridClientDebugMode >= 2 && console.debug(`SavePgridFactToDatabase() collected facts: `, linkedRangesFactsCollections);


        let SendEvent = async function (eventType /*'AfterSaveFact' or 'BeforeSaveFact'*/, responseSavePGridFact = null) {
            try {
                let pGridEventTypeProps = { EventType: `${eventType}` }
                lodash.merge(pGridEventTypeProps, eventArgs);
                // let customProperties = lodash.get(context, `state.pgridVars.GridDefinition.Events.${ eventType }.CustomProperties`, null);
                let customProperties = lodash.get(context, `state.pgridVars.GridDefinition.Events.CustomEventProperties`, null);
                if (customProperties != null) {
                    //Make a copy for reusability
                    customProperties = JSON.parse(JSON.stringify(customProperties));
                }
                PGridDynExtract.UpdateDynProps_ExtraContext(context, { Facts: linkedRangesFactsCollections, Event: pGridEventTypeProps }, customProperties, true);
                pGridEventTypeProps.CustomProperties = customProperties;

                let responseHandlePGridFact = await HandlePGridEvent(pgridVars, pgridFilterSelection, JSON.stringify({ responseSavePGridFact }), batchId, timestampMS, JSON.stringify(pGridEventTypeProps));

                let isError = lodash.get(responseHandlePGridFact, "PGridEvent[0].Error", "");
                if (!isEmpty(isError)) {
                    console.error("SendEvent() recived error: responseHandlePGridFact: " + isError)
                    throw new Error(isError);

                }
            } catch (err) {
                let errMsg = `SendEvent() (${eventType}) recived error: ${err.message}`
                console.error(errMsg)
                throw new Error(errMsg);
            }
        }

        if (lodash.get(eventArgs, "SkipEventBeforeSaveFact", false) == true) {
            window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() skipping 'BeforeSaveFact'")
        } else {
            await SendEvent("BeforeSaveFact");
        }

        window.PGridClientDebugMode >= 2 && console.debug(`SavePGridFactToDatabase() sleeping (delay after BeforeSaveFact) ${context.state.pgridSettings.delayedAfter_BeforeSaveFact_Miliseconds} ms`);
        await PGridUtils.sleep(context.state.pgridSettings.delayedAfter_BeforeSaveFact_Miliseconds);

        window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after 'BeforeSaveFact'")

        let linkedRangesFactsCollections_keys = Object.keys(linkedRangesFactsCollections);

        for (let i = 0; i < linkedRangesFactsCollections_keys.length; i++) {
            const pgridFactObj = linkedRangesFactsCollections[linkedRangesFactsCollections_keys[i]];
            const pgridFactRows = pgridFactObj.linkedRangesFacts;

            let pgridVarsCpy = JSON.parse(JSON.stringify(pgridVars)); //This works!
            // let pgridVarsCpy = pgridVars; //This does not work 100%

            if (pgridFactObj.overrideGridKey != null) {
                pgridVarsCpy.GridKey = pgridFactObj.overrideGridKey;
            }
            if (pgridFactObj.overrideTabKey != null) {
                pgridVarsCpy.TabKey = pgridFactObj.overrideTabKey;
            }


            window.PGridClientDebugMode >= 3 && console.debug("Will send the following rows to server", pgridFactRows);

            if (pgridFactObj.overrideRemoveOrTranslateCellKeyDims != null) {
                [pgridFilterSelection, _] = PGridUtils.HandleDimensionOverrides(context, null, pgridFilterSelection, pgridFactObj.overrideRemoveOrTranslateCellKeyDims);
            }

            let responseSavePGridFact;

            try {
                responseSavePGridFact = await SavePGridFact(pgridVarsCpy, pgridFilterSelection, pgridFactRows, batchId, timestampMS);
                window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after SavePGridFact()")

                let isError = lodash.get(responseSavePGridFact, "PGridSetFact[0].Error", "");
                if (!isEmpty(isError)) {
                    console.error("SavePGridFactToDatabase() recived error: " + isError)
                    throw new Error(isError);
                }

            } catch (err) {
                let errMsg = `SavePgridFactToDatabase() got exception: ${err.message || err}`
                console.error(errMsg)
                throw new Error(errMsg);
            }

        }

        // window.PGridClientDebugMode >= 2 && console.debug(`SavePGridFactToDatabase() sleeping (delay after save) ${context.state.pgridSettings.delayedAfter_Save_Miliseconds} ms`);
        // await PGridUtils.sleep(context.state.pgridSettings.delayedAfter_Save_Miliseconds);


        if (lodash.get(eventArgs, "SkipEventAfterSaveFact", false) == true) {
            window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() skipping 'AfterSaveFact'")
        } else {
            await SendEvent("AfterSaveFact");
        }



        //await context.dispatch("Action_Stage_Filter", { initTimestamp: context.state.pgridSettings.initOfLastQuery_Timestamp, initUrl: context.state.pgridSettings.initOfLastQuery_Url, dontLoadGrid: true, source: `SavePgridFactToDatabase()` });

        context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `SavePgridFactToDatabase()` });

        window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after 'AfterSaveFact'")


    } catch (err) {
        throw new Error(`SavePgridFactToDatabase_error: ${err.message || err} `);
    }

}



export function NewPGridLRTypeObj(pgridLRtype, y, x, pcell, pgridTableStatic) {
    let newLRObj = null;
    switch (pgridLRtype) {
        case "Table":
            newLRObj = new PGridLR_Table(x, y, pcell, pgridTableStatic);
            break;
        case "Pivot":
            newLRObj = new PGridLR_Pivot(x, y, pcell, pgridTableStatic);
            break;
        case "EPivot":
            newLRObj = new PGridLR_EPivot(x, y, pcell, pgridTableStatic);
            break;
        case "FreeCell":
            newLRObj = new PGridLR_FreeCell(x, y, pcell, pgridTableStatic);
            break;
        case "MetaCell":
            newLRObj = new PGridLR_MetaCell(x, y, pcell, pgridTableStatic);
            break;
        case "ApexCharts":
            newLRObj = new PGridLR_ApexCharts(x, y, pcell, pgridTableStatic);
            break;
        case "MasterDataGrid":
            newLRObj = new PGridLR_MasterDataGrid(x, y, pcell, pgridTableStatic);
            break;
        default:
            throw `GetSchemaForType_error: invalid PGridLRType: ${pgridLRtype} `
    }
    return newLRObj;
}

export function ApplyCssStyles(updateStyleClasses, pgridDataDyn, yStart, xStart, yLen, xLen, add = true) {

    expandIfNeeded(pgridDataDyn, yStart + yLen, xStart + xLen);

    let finalStyles = [];

    for (let yRep = 0; yRep < yLen; yRep++) {
        let y = yRep + yStart;
        if (y >= 0) {
            for (let xRep = 0; xRep < xLen; xRep++) {
                let x = xRep + xStart;

                if (x >= 0 && y >= 0) {

                    if (pgridDataDyn[y][x] == null) {
                        pgridDataDyn[y][x] = {};
                    }

                    let currentStyleClasse = [];

                    if ("Format" in pgridDataDyn[y][x]) {
                        currentStyleClasse = pgridDataDyn[y][x].Format.split(",");
                    }

                    finalStyles = lodash.clone(currentStyleClasse);

                    updateStyleClasses.forEach(sc => {

                        if (add) {
                            if (finalStyles.indexOf(sc) == -1) {
                                finalStyles.push(sc)
                            }
                        } else {
                            //Remove

                            finalStyles = finalStyles.filter(o => {
                                let ret = false;
                                if (
                                    o != sc
                                ) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                                return ret;
                            });
                        }
                    });

                    if (finalStyles.length == 0 && !("Format" in pgridDataDyn[y][x])) {
                        //Don't set
                    }
                    else if (finalStyles.length == 0 && "Format" in pgridDataDyn[y][x]) {
                        delete pgridDataDyn[y][x].Format;
                    } else {
                        pgridDataDyn[y][x].Format = finalStyles.join(",");
                    }
                }
            }
        }


    }
}

export function FillDimPropsDown(pgridDataDyn, yStart, xStart, axisLen, axisLengthHeaders, axis = "Rows", paths) {

    if (axis == "Rows") {
        for (let y = yStart; y < yStart + axisLen; y++) {

            let latestFoundDimProp = {};

            for (let x = xStart; x < xStart + axisLengthHeaders; x++) {


                for (let p = 0; p < paths.length; p++) {
                    let path = paths[p];
                    let dimVal = lodash.get(pgridDataDyn[y][x], path, null);

                    if (String(dimVal) != "null") {
                        latestFoundDimProp[path] = dimVal;
                    } else {
                        if (path in latestFoundDimProp) { //Has a path value from an dim cell on the left side of this, will insert this

                            if (!pgridDataDyn[y][x]) {
                                pgridDataDyn[y][x] = {};
                            }
                            lodash.set(pgridDataDyn[y][x], path, latestFoundDimProp[path]);
                        }
                    }
                }
            }
        }
    }
    else {
        throw 'not implemented';
    }
}

function UpdateCellReferences(changeCellAddr, refsKeys_Old, refsKeys_New, cellsThatAreReferenced, cellsThatAreReferenced_Inv) {

    //Update cellsThatAreReferenced

    // 1st remve all references from cellsThatAreReferenced
    let cellsWithRefs = lodash.get(cellsThatAreReferenced_Inv, changeCellAddr, []); //Look in inverted obj

    if (JSON.stringify(lodash.clone(cellsWithRefs).sort()) !== JSON.stringify(lodash.clone(refsKeys_Old).sort())) {
        window.PGridClientDebugMode >= 3 && console.debug(`UpdateCellReferences() updating (but no remove yet) cell references for cell ${changeCellAddr}. (OLD) ${JSON.stringify(lodash.clone(cellsWithRefs).sort())} -> (NEW) ${JSON.stringify(lodash.clone(refsKeys_Old).sort())}`);
    }

    for (let j = 0; j < cellsWithRefs.length; j++) {
        let cellWRef = cellsWithRefs[j];
        cellsThatAreReferenced[cellWRef].splice(cellsThatAreReferenced[cellWRef].indexOf(changeCellAddr), 1); //Remove the cell reference
    }
    // 2nd remve  references from cellsThatAreReferenced_Inv
    delete cellsThatAreReferenced_Inv[changeCellAddr];

    //3rd add the reference to cellsThatAreReferenced_Inv
    cellsThatAreReferenced_Inv[changeCellAddr] = refsKeys_New;

    //4rd add the references to cellsThatAreReferenced
    for (let j = 0; j < refsKeys_New.length; j++) {
        let refsKeys_New_item = refsKeys_New[j]

        if (!cellsThatAreReferenced[refsKeys_New_item]) {
            cellsThatAreReferenced[refsKeys_New_item] = [];
        }
        cellsThatAreReferenced[refsKeys_New_item].push(changeCellAddr);
    }

}

export function AddEmptyRow(pgridData, rows, onRow) {
    for (let i = 0; i < rows; i++) {
        let newRow = new Array()
        for (let c = 0; c < pgridData[0].length; c++) {
            newRow.push(undefined);
        }
        pgridData.splice(onRow, 0, newRow);
    }

}

export default {
    Load_Phase2to4,
    Get_LRFromTableCoordinates,
    // Get_LRsFromTableCached,
    Get_LRsFromTable,
    Convert_pgridTableDynToPGridFactRows,
    SavePgridFactToDatabase,
    NewPGridLRTypeObj,
    ApplyCssStyles,
    // FindLRCoordinates,
    FillDimPropsDown,
    AddEmptyRow,
    UpdateCellReferences
}