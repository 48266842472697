import { PGridLR_Base } from './pgridLR_Base.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_EPivot_Schema from './PGridLR_EPivot-schema.json';
import lodash from 'lodash';
import { expandIfNeeded, indexOfDSProp, mergeCells } from './pgridCell.js';

import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'

export class PGridLR_EPivot extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange
    //this.xLength
    //this.yLength

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}


    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.yLengthHeaders = 0;
        this.xLengthHeaders = 0;
        this.yLengthHeadersAdjust = 0;
        this.xLengthHeadersAdjust = 0;

        this.ySafeOffsetDistance = lodash.get(pcell, "Meta.Load.SafeOffsetDistanceY", null);
        this.xSafeOffsetDistance = lodash.get(pcell, "Meta.Load.SafeOffsetDistanceX", null);

        this.schema = PGridLR_EPivot_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            COLUMNS: { type: 'COLUMNS', mapsTo: 'Columns', IsTableAxis: true },  //'Column' is a property in the linked range definition
            ROWS: { type: 'ROWS', mapsTo: 'Rows', IsTableAxis: true },
            LOOKUPS: { type: 'LOOKUPS', mapsTo: 'Lookups' },
            FACTS: { type: 'FACTS', mapsTo: 'Facts' }
        }

        this.Templates = lodash.get(this.linkedRange, "Templates", null);

        if (!this.Templates) {
            this.Templates =
                //Default template for inserting dim metadata for facts load/save
                [
                    {
                        "Name": "Default",
                        "Description": "Default template",
                        "Sort": 1,
                        "PrimaryTemplate": true,
                        "Rule": {
                            "If_Axis": "RowsAndColumns",
                            "If_Level": "any"
                        },
                        "Insert": {
                            "LevelPosition": "CurrentLevel",
                            "InNewRow": false,
                            "Merge": false,
                            "CellTemplate": {
                                "ValStr": "<<NODE_NAME>>",
                                "Meta": {
                                    "DimVal": "<<NODE_VALUE>>",
                                    "DimName": "<<PIVOTHIERARARCHY_NAME>>",
                                    "Span<<CURRENT_AXIS>>": "<<CHILDREN_COUNT_MINUS_ONE>>"
                                }
                            }
                        }
                    }
                ]
        }


        this.Overlay = lodash.get(this.linkedRange, "Overlay", null);

        let FillDimPropsDown_default = {
            "Rows": [
                "Meta.DimVal",
                "Meta.DimName"
            ]
        }

        this.FillDimPropsDown = lodash.get(this.linkedRange, "FillDimPropsDown", FillDimPropsDown_default);
        this.RemoveUpperRightCakebite = true;
    }

    whoAmI() {
        return 'I am a PGridLR_EPivot'
    }

    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {

        let ret = Array();

        let addPivotHierarchy = function (dtName, overrides) {

            const dsType = "Pivot_Hierarcy" //From LR 

            let pivotHiers = dataSetDefinitions.filter(dsd => {
                return dsd.Type === "Pivot_Hierarcy" && dsd.Name == dtName
            });

            if (pivotHiers.length != 1) {
                throw new Error(`Could not find one  dataset of name '${dtName}' of type '${dsType}'`);
            }

            let pivotHier = pivotHiers[0];

            ret.push({ DSName: dtName, DSDef: pivotHier, DSOverrides: overrides, LRName: me.name, AltArgs: null });
        }

        try {

            // let cols = me.linkedRange[me.AxisDefMap.COLUMNS.mapsTo];
            // let rows = me.linkedRange[me.AxisDefMap.ROWS.mapsTo];
            let cols = me.linkedRange["Columns"];
            let rows = me.linkedRange["Rows"];
            let lookups = lodash.get(me, "linkedRange.Lookups", null);

            let axisObjs = [cols, rows];

            axisObjs.forEach(axisDef => {
                if ("PivotHierarchy" in axisDef) {

                    let dsName = axisDef.PivotHierarchy;
                    // },
                    // "Rows": {
                    //     "PivotHierarchy": "Hierarki_GetPlanningAccountHierarchy",
                    //     "Override": {
                    //         "GridKey": "CC748C64-0424-4617-93A6-338451B144C1"    <--------- Like this
                    //     },
                    //     "TotalLengthAdjust": 0
                    // },
                    // "OverlayCSSClass": "pg-hide-this-lr",
                    let overrides = lodash.get(axisDef, "Override", null);

                    addPivotHierarchy(dsName, overrides);
                } else {
                    throw new Error(`Missing "PivotHierarchy" in LR ${me.name}`);
                }
            });

            if (lookups) {
                let lookupKeys = Object.keys(lookups);
                for (let l = 0; l < lookupKeys.length; l++) {
                    let lookup = lookups[lookupKeys[l]];
                    let dsName = lodash.get(lookup, "PivotHierarchy", null);
                    let overrides = lodash.get(lookup, "Override", null);
                    addPivotHierarchy(dsName, overrides);
                }
            }

        } catch (err) {
            throw new Error(`PGridLR_EPivot:Phase1_Get_DataSetDefs() ${me.name}: Could not get dataset with name: ${err.message || err}`);
        }

        return ret;
    }


    //contect: client (resolver_common.js)
    //inputs: pgridDim
    //sets: this.axisData ()

    /* example:
    this.axisData: {
        "COLUMNS": [
            {
                "Name": "Hierarki_GetPlanningColumnHierarchy",
                "Header": {
                    "Id": {
                        "SrcCol": "ColId",
                        "ColIdx": 0
                    },
                    "Value": {
                        "SrcCol": "ColId",
                        "ColIdx": 1
                    },
                    "Name": {
                        "SrcCol": "ColName",
                        "ColIdx": 2
                    },
                    "Description": {
                        "SrcCol": "ColDescr",
                        "ColIdx": 3
                    },
                    "HierarchyLevel": {
                        "SrcCol": "HierarchyLevel",
                        "ColIdx": 4
                    },
                    "HierarchyId": {
                        "SrcCol": "HierarchyId",
                        "ColIdx": 5
                    },
                    "Writable": {
                        "SrcCol": "mimPg_IsWritable",
                        "ColIdx": 6
                    },
                    "Type": {
                        "SrcCol": "ColType",
                        "ColIdx": 7
                    }
                },
                "DataSet": [
                    [
                        "kkg1-3",
                        "kkg1-3",
                        "Investeringskalkyl / Projektansökan",
                        "Kalkyl angiven i projektanmälan inkl utökningar.",
                        1,
                        "KK-cols1",
                        0,
                        "MEASURE_ON_PROJECT_PERIOD"
                    ],
                    [
                        "kkg1-4",
                        "kkg1-4",
                        "Upparbetat",
                        "Upparbetad kostnad (Utfall).",
                        1,
                        "KK-cols1",
                        0,
                        "MEASURE_ON_PROJECT_PERIOD"
                    ],
                    [
                        "kkg1-5",
                        "kkg1-5",
                        "Aktuell Slutkostnadsprognos",
                        "Sammanställd prognos för aktuellt konto.",
                        1,
                        "KK-cols1",
                        1,
    .
    .
    .
    "ROWS": [
        {
            "Name": "Hierarki_GetPlanningAccountHierarchy",
            "Header": {
                "Id": {
                    "SrcCol": "AccountId",
                    "ColIdx": 0
                },
                "Value": {
                    "SrcCol": "AccountId",
                    "ColIdx": 1
    .
    .
    .
                    1,
                        1
                    ],
                    [
                        "2291",
                        "2291",
                        "2291 Bonus",
                        "2291 Bonus",
                        "prod19",
                        4,
                        "ProjKonto1",
                        1,
                        1
                    ],
                    [
                        "2293",
                        "2293",
                        "2293 Valfritt projektspecifikt",
                        "2293 Valfritt projektspecifikt",
                        "prod19",
                        4,
                        "ProjKonto1",
                        1,
                        1
                    ]
                    ],
                    "Parent": {
                    "SrcCol": "ParentAccountId"
                    },
                    "LookupIdx_ParentToMe": {
                    "0": [
                        0,
                        1,
                        2,
                        3,
                        4
                    ],
                    "1": [
                        5,
                        6,
                        7
                    ],
                    "2": [
                        8,
                        9,
                        10,
                        11,
                        12,
                        13
                    ],
                    "3": [
                        14,
                        15,
                        16,
    */


    Phase2_Load_DimDataInAxisData(pgridDim) {

        try {
            const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

            for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
                let atl = axisToLoad[i];

                false && console.debug(`Phase2_Load_DimDataInAxisData() Processing ${atl.key}`)

                let axisDef = this.linkedRange[atl.value.mapsTo];


                if (lodash.get(axisDef, "PivotHierarchy", null) != null || lodash.get(axisDef, "DataSetName", null) != null) {

                    let dsName = axisDef.PivotHierarchy || axisDef.DataSetName ;
                    if (dsName === null) {
                        throw new Error("No PivotHierarchy name fund");
                    }

                    let pgridDimDS = lodash.get(pgridDim, dsName, null);

                    if (pgridDimDS == null) {
                        throw new Error("DataSet not in result");
                    }


                    let metaDataDS = lodash.get(pgridDim, `${dsName}_(¤HierMetadata¤)`, null);
                    if (metaDataDS === null) {
                        throw new Error("No PivotHierarchy metedata fund");
                    }

                    this.axisMeta[atl.key] = JSON.parse(JSON.stringify(metaDataDS));
                    delete this.axisMeta[atl.key].Dimensions; //Redundant

                    let dsLevelsMetadataSorted = metaDataDS.Dimensions.sort((x_left, x_right) => (x_left.Level > x_right.Level ? 1 : -1));
                    const dsLevelsMetadataSorted_length = dsLevelsMetadataSorted.length;
                    for (let l = 0; l < dsLevelsMetadataSorted_length; l++) {
                        const pgridDSDefLvl = lodash.clone(dsLevelsMetadataSorted[l]);

                        let dimAxisRes = {
                            Name: `${pgridDSDefLvl.Name}`,//`${dsName}_LEVEL_${mdLvl.Level}_${mdLvl.Name}`,
                            Header: {
                                Id: null,
                                Value: null,
                                Name: null,
                                Description: null
                            },
                            // Parent: null,
                            // ParentChildInxLookup: null, //Fill here
                            DataSet: [] //Fill here
                        }

                        //Add parent def to axis result
                        if ("Parent" in pgridDSDefLvl) {
                            dimAxisRes.Parent = pgridDSDefLvl.Parent;
                        }

                        // Populate Header
                        let countCol = 0;
                        Object.keys(pgridDSDefLvl.SrcColMapping).forEach(function (prop) {
                            let val = pgridDSDefLvl.SrcColMapping[prop];
                            dimAxisRes.Header[prop] = { SrcCol: val, ColIdx: countCol++ };
                        });

                        for (let w = 0; w < pgridDimDS.length; w++) {

                            if (w === 0) {
                                continue;
                            }

                            let newRow = [];


                            let filterOutRow = false;

                            if ("Filter" in pgridDSDefLvl) {

                                filterOutRow = true;

                                for (let v = 0, n = pgridDSDefLvl.Filter.length; v < n; v++) {
                                    let filt = pgridDSDefLvl.Filter[v];

                                    if (("SrcCol" in filt)) {
                                        let filtCol = filt.SrcCol;

                                        if (("SrcValEqual" in filt)) {
                                            let filtVar = filt.SrcValEqual;
                                            let rowColInd = indexOfDSProp(pgridDimDS, filtCol);
                                            let rowColVal = pgridDimDS[w][rowColInd];

                                            if (rowColVal == filtVar) {
                                                filterOutRow = false;
                                            }
                                        }
                                        else if (("SrcValNotEqual" in filt)) {
                                            let filtVar = filt.SrcValEqual;
                                            let rowColInd = indexOfDSProp(pgridDimDS, filtCol);
                                            let rowColVal = pgridDimDS[w][rowColInd];

                                            if (rowColVal != filtVar) {
                                                filterOutRow = false;
                                            }
                                        }
                                    }
                                }
                            }

                            if (filterOutRow == false) {
                                let SrcColMapping_Keys = Object.keys(pgridDSDefLvl.SrcColMapping);
                                for (let z = 0; z < SrcColMapping_Keys.length; z++) {
                                    let prop = SrcColMapping_Keys[z];

                                    let idxDSCol = indexOfDSProp(pgridDimDS, pgridDSDefLvl.SrcColMapping[prop]);
                                    let valDSCell = pgridDimDS[w][idxDSCol];
                                    newRow.push(valDSCell);
                                }

                                dimAxisRes.DataSet.push(newRow);
                            }
                        }

                        if (!(atl.key in this.axisData)) {
                            this.axisData[atl.key] = [];
                        }
                        this.axisData[atl.key].push(dimAxisRes);
                    }
                }
                else {
                    throw new Error(`No dimensions specified for ${axisDef}`)
                }
            }


            for (let i = 0; i < axisToLoad.length; i++) { //COLUMNS & ROWS
                let atl = axisToLoad[i];


                let currentAxisMeta = this.axisMeta[atl.key];
                let currentAxisData = this.axisData[atl.key];

                let doParentChildCals = false;

                let hierarchyType = lodash.get(currentAxisMeta, "HierarchyType", null);
                let relationType = lodash.get(currentAxisMeta, "RelationType", null);



                if (/* hierarchyType == "Tree" && */relationType == "ParentChild") {
                    doParentChildCals = true;
                }

                //Assume InlineHierarchy (if not exsist) the do do_parentChildPreCals (obsolete?)
                if (JSON.toString(lodash.get(currentAxisMeta, "", {})) == "{}") {
                    doParentChildCals = true;
                }

                if (doParentChildCals) {


                    //Start at 1, as first has no parents
                    for (let lvlZ = 1; lvlZ < currentAxisData.length; lvlZ++) { // Dim levels 0 to x, where 0 is first (level 1) (leftmost, or upper in grid labels)

                        let axisDataCurrent = null;
                        let axisDataParent = null;

                        axisDataCurrent = currentAxisData[lvlZ];

                        if (lvlZ - 1 >= 0) {
                            axisDataParent = currentAxisData[lvlZ - 1];
                        } else {
                            throw new Error(`Cannot calculate parent relation when no parent dim exits`);
                        }

                        let parTargColName = lodash.get(axisDataCurrent, "Parent.TargetCol", "Id");
                        let mySrcColName = lodash.get(axisDataCurrent, "Parent.SrcCol", "Id");

                        if ((!parTargColName) || (!mySrcColName)) {
                            console.debug(`Skipping parentchildCalc for level ${axisDataAxLvl.Name} as has no "Parent.SrcCol"`);
                        }
                        else {

                            let parTargCol = lodash.get(axisDataParent, `Header[${parTargColName}]`, null);
                            let mySrcCol = lodash.get(axisDataCurrent, `Header[${mySrcColName}]`, null);


                            if (!parTargCol) {
                                throw new Error(`parTargCol == null`);
                            }
                            if (!mySrcCol) {
                                throw new Error(`mySrcCol == null`);
                            }

                            axisDataCurrent.LookupIdx_ParentToMe = {};

                            //Iterate current
                            for (let currRow = 0, currDSLen = axisDataCurrent.DataSet.length; currRow < currDSLen; currRow++) {

                                let currDSRow = lodash.get(axisDataCurrent, `DataSet[${currRow}]`, null);
                                if (currDSRow == null) {
                                    throw new Error(`Could not lookup row from dataset`);
                                }

                                let mySrcColVal = lodash.get(currDSRow, `[${mySrcCol.ColIdx}]`, "<No_ColIdx_Match>");

                                if (mySrcColVal == "<No_ColIdx_Match>") {
                                    throw new Error(`Could not find a col index match in currDSRow`);
                                }


                                let parMatchId = null;


                                for (let parRow = 0, parDSLen = axisDataParent.DataSet.length; parRow < parDSLen; parRow++) {


                                    let parDSRow = axisDataParent.DataSet[parRow];

                                    let parTargColVal = parDSRow[parTargCol.ColIdx];

                                    if (parTargColVal == null) {
                                        continue;
                                    }

                                    if (parTargColVal == "<No_ColIdx_Match>") {
                                        throw new Error(`Could not find a col index match in parDSRow`);
                                    }


                                    if (parTargColVal == mySrcColVal) {
                                        if (parMatchId != null) {
                                            // throw new Error("multiple parent node matched, this is now allowed");
                                            console.warn(`multiple parent node matched, this is now allowed. Ignoring ${mySrcColVal}`);
                                            continue;
                                        } else {
                                            parMatchId = parRow;
                                        }
                                    }
                                }


                                //Found parent child link, now store it in lookup
                                if (parMatchId != null) {

                                    if (!(parMatchId in axisDataCurrent.LookupIdx_ParentToMe)) {
                                        axisDataCurrent.LookupIdx_ParentToMe[parMatchId] = [];
                                    }

                                    axisDataCurrent.LookupIdx_ParentToMe[parMatchId].push(currRow)
                                }
                            }

                        }
                    }
                }
            } // end for atl = COLUMNS & ROWS

            //Dont return anything (have insted populated axisData)
            return null;

        } catch (err) {
            let errMsg = `Phase2_Load_DimDataInAxisData (${this.name}) got exception: ${err.message || err}`;
            throw new Error(errMsg)
        }
    }


    // Phase3_Insert_DynDimData(pgridTableDynCopy, pgridAxis, addedRowsOffsetLR, state) {
    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {

        this.y = PGridMatrices.Get_LRsFromTable(pgridTableDyn, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase3_Insert_DynDimData() name: ${this.name}` }).y;

        let pgridTableDynCopy = lodash.clone(pgridTableDyn); //PERF?

        let ret = { pgridTableDyn: null, addedRowsOffset: null, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }

        try {
            let currentAxis = this.axisData[pgridAxis.type]

            let firstLvlWithData = null;
            let firstLvlWithDataLevelZ = 0;

            for (let i = 0; i < currentAxis.length; i++) {

                if (firstLvlWithData == null) {
                    let testLvl = currentAxis[i];
                    if (testLvl.DataSet.length > 0) {
                        firstLvlWithData = testLvl;
                        firstLvlWithDataLevelZ = i;
                    }
                }
            }


            let accSpan = 0;
            let accAddedRowsOffset = 0;


            let usedMaxOffsetY = 0;
            let usedMaxOffsetX = 0;
            let usedMinOffsetY = 0;
            let usedMinOffsetX = 0;

            let addedRowsTracking = {}


            if (firstLvlWithData == null) {
                throw new Error("Found no data for " + pgridAxis.type);
                // console.error("Found no data for " + pgridAxis.type);
                // ret = { pgridTableDyn: pgridTableDyn, addedRowsOffset: 0 };
                // return ret;
            }

            let shiftCellsDownOffset = {}
            let shiftCellsDownOffset2 = {}
            let shiftRowsDownTracker = {}



            let currentVirtualAxis = currentAxis;

            let AppendVirtualRootNode = false;
            let AppendVirtualRootNodeLabel = null;
            let RootNodeSpanAdjust = lodash.get(this.linkedRange[pgridAxis.mapsTo], "RootNodeSpanAdjust", 0);
            let TotalLengthAdjust = lodash.get(this.linkedRange[pgridAxis.mapsTo], "TotalLengthAdjust", 0);
            let RemoveUpperRightCakebite = lodash.get(this.linkedRange[pgridAxis.mapsTo], "RemoveUpperRightCakebite", true);
            this.RemoveUpperRightCakebite = RemoveUpperRightCakebite;

            if (firstLvlWithDataLevelZ > 0) {
                window.PGridClientDebugMode >= 2 && console.warn("Detected missing data in at least first level, don´t create any virtual root")
            } else {

                AppendVirtualRootNode = lodash.get(this.linkedRange[pgridAxis.mapsTo], "AppendVirtualRootNode", false);
                AppendVirtualRootNodeLabel = lodash.get(this.linkedRange[pgridAxis.mapsTo], "AppendVirtualRootNodeLabel", "Summa");

                if (AppendVirtualRootNode) {
                    false && console.debug("First level (with data) has many nodes, create a virtual heigher root node")

                    let virtRootHeader = lodash.clone(currentAxis[firstLvlWithDataLevelZ].Header);

                    let theOnlyRow = [];

                    for (let i = 0; i < currentAxis[firstLvlWithDataLevelZ].DataSet[0].length; i++) {
                        theOnlyRow.push(null);
                    }

                    theOnlyRow[virtRootHeader.Description.ColIdx] = "Virtual root node";
                    theOnlyRow[virtRootHeader.HierarchyId.ColIdx] = null;
                    theOnlyRow[virtRootHeader.Name.ColIdx] = AppendVirtualRootNodeLabel;
                    theOnlyRow[virtRootHeader.HierarchyLevel.ColIdx] = -1;
                    theOnlyRow[virtRootHeader.Id.ColIdx] = 0;
                    theOnlyRow[virtRootHeader.Value.ColIdx] = null;
                    if ("Writable" in virtRootHeader) {
                        theOnlyRow[virtRootHeader.Writable.ColIdx] = false;
                    }
                    if ("Type" in theOnlyRow) {
                        theOnlyRow[virtRootHeader.Type.ColIdx] = 'number';
                    }
                    if ("IsGroup" in virtRootHeader) {
                        theOnlyRow[virtRootHeader.IsGroup.ColIdx] = false;
                    }

                    let newVirtualRootLevel = { DataSet: [theOnlyRow], Header: virtRootHeader, name: currentAxis[firstLvlWithDataLevelZ].Name };

                    let theLevelBelowVirtualRoot = currentVirtualAxis[firstLvlWithDataLevelZ];

                    let LookupIdx_ParentToMe = { "0": [] };

                    for (let idx = 0; idx < theLevelBelowVirtualRoot.DataSet.length; idx++) {
                        // theLevelBelowVirtualRoot.DataSet.forEach((row, idx) => {
                        LookupIdx_ParentToMe["0"].push(idx);
                        // });
                    }

                    theLevelBelowVirtualRoot.LookupIdx_ParentToMe = LookupIdx_ParentToMe;

                    //Add a new node at first level with data
                    currentVirtualAxis = lodash.clone(currentAxis);
                    currentVirtualAxis.splice(firstLvlWithDataLevelZ, 0, newVirtualRootLevel);

                    for (let a = 0; a < currentVirtualAxis.length; a++) {
                        let olvl = currentVirtualAxis[a];
                        // currentVirtualAxis.forEach(olvl => {

                        for (let idx = 0; idx < olvl.DataSet.length; idx++) {
                            // olvl.DataSet.forEach((olvlRow, idx) => {
                            olvl.DataSet[idx][virtRootHeader.HierarchyLevel.ColIdx] += 1;
                            // })
                        }
                        // })
                    }
                }
            }


            //Not sure this is needed, now when we promices a single root node in the highest level?
            for (let rootLvlNodesCount = 0; rootLvlNodesCount < currentVirtualAxis[firstLvlWithDataLevelZ].DataSet.length; rootLvlNodesCount++) {

                let nextOffsetY = 0;
                let nextOffsetX = 0;

                if (pgridAxis.type == "COLUMNS") {

                    nextOffsetY = 0;
                    nextOffsetX = 0 + accSpan;
                    if (this.type == "FreeCell") {
                        nextOffsetX = 0;
                    }
                }

                if (pgridAxis.type == "ROWS") {
                    nextOffsetY = 0 + accSpan;
                    nextOffsetX = 0;
                }

                if (!("PGridSpinningTreeCount" in window)) {
                    window.PGridSpinningTreeCount = 0;
                    window.PGridSpinningTreeCountSYNC = 40;
                }

                window.PGridSpinningTreeCount += 1;
                let retSTN = null;

                if (window.PGridSpinningTreeCount % window.PGridSpinningTreeCountSYNC === 0) {
                    window.PGridClientDebugMode >= 2 && console.debug(`await PGridUtils.spinningTreeNet()`);
                    retSTN = await PGridUtils.spinningTreeNet(
                        state
                        , this
                        , pgridTableDynCopy
                        , currentVirtualAxis
                        , pgridAxis
                        , firstLvlWithDataLevelZ
                        , rootLvlNodesCount
                        , AppendVirtualRootNode
                        , nextOffsetY
                        , nextOffsetX
                        , addedRowsTracking
                        , shiftCellsDownOffset
                        , shiftCellsDownOffset2
                        , shiftRowsDownTracker
                        , []
                        , addedRowsOffsetLR
                    );
                }
                else {
                    retSTN = PGridUtils.spinningTreeNetSYNC(
                        state
                        , this
                        , pgridTableDynCopy
                        , currentVirtualAxis
                        , pgridAxis
                        , firstLvlWithDataLevelZ
                        , rootLvlNodesCount
                        , AppendVirtualRootNode
                        , nextOffsetY
                        , nextOffsetX
                        , addedRowsTracking
                        , shiftCellsDownOffset
                        , shiftCellsDownOffset2
                        , shiftRowsDownTracker
                        , []
                        , addedRowsOffsetLR
                    );
                }

                accSpan += retSTN.span;


                let levelOutShiftCellsDownOffset = function () {
                    let findMax = 0;

                    for (let e = 0; e < currentVirtualAxis.length; e++) {

                        let found = lodash.get(shiftCellsDownOffset, `[${e}]`, 0);

                        if (found > findMax) {
                            findMax = found;
                        }
                    }

                    for (let e = 0; e < currentVirtualAxis.length; e++) {
                        shiftCellsDownOffset[e] = findMax;
                    }

                }

                levelOutShiftCellsDownOffset();


                accAddedRowsOffset += retSTN.addedRowsOffset;

                usedMaxOffsetY = Math.max(usedMaxOffsetY, retSTN.usedMaxOffsetY);
                usedMaxOffsetX = Math.max(usedMaxOffsetX, retSTN.usedMaxOffsetX);

                usedMinOffsetY = Math.min(usedMinOffsetY, retSTN.usedMinOffsetY);
                usedMinOffsetX = Math.min(usedMinOffsetX, retSTN.usedMinOffsetX);

            }

            this.xLength = Math.max(this.xLength, usedMaxOffsetX + 1 + (pgridAxis.type == "COLUMNS" ? TotalLengthAdjust : 0));
            this.yLength = Math.max(this.yLength, usedMaxOffsetY + 1 + (pgridAxis.type == "ROWS" ? TotalLengthAdjust : 0));


            this.xLengthHeaders = Math.max(this.xLengthHeaders, -usedMinOffsetX);
            this.yLengthHeaders = Math.max(this.yLengthHeaders, -usedMinOffsetY - 1);

            if (this.FillDimPropsDown) {
                //0 Make sure DimVal is copied to last level if only exists further to the "left" or "top"
                for (const [axis, paths] of Object.entries(this.FillDimPropsDown)) {
                    if (axis == pgridAxis.mapsTo && axis == "Rows") {
                        false && console.debug("calling FillDimPropsDown()");
                        PGridMatrices.FillDimPropsDown(pgridTableDynCopy, this.y, this.x - this.xLengthHeaders, this.yLength, this.xLengthHeaders, axis, paths);
                    }
                }
                //Make sure DimVal is copied end
            }

            ret.lrIsHidden = this.lrIsHidden;
            ret.lowerRightCoord.y = this.y + this.yLength;
            ret.lowerRightCoord.x = this.x + this.xLength;

            ret.pgridTableDyn = pgridTableDynCopy;
            ret.addedRowsOffset = accAddedRowsOffset

        } catch (Phase3_Insert_DynDimData_error) {
            let errMsg = `> ${this.type}: ${this.type}: Phase3_Insert_DynDimData_error: ${Phase3_Insert_DynDimData_error.message || Phase3_Insert_DynDimData_error}`;
            throw new Error(errMsg);
            this.errors.push(JSON.parse(JSON.stringify(Phase3_Insert_DynDimData_error.toString())));
        }

        return ret;
    }

    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {

        let pgridTableCopy = pgridTable;

        this.y = PGridMatrices.Get_LRsFromTable(pgridTable, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase4_Insert_Metadata()` }).y;

        let yLR = this.y;
        let xLR = this.x;

        let rowCellPosDelta = -1;


        let firstLvlZCols = -1;
        for (let i = 0; i < this.axisData["COLUMNS"].length; i++) {

            if (firstLvlZCols == -1) {
                if (this.axisData["COLUMNS"][i].DataSet.length > 0) {
                    firstLvlZCols = i;
                }
            }
        }

        let firstLvlZRows = -1;

        for (let i = 0; i < this.axisData["ROWS"].length; i++) {

            if (firstLvlZRows == -1) {
                if (this.axisData["ROWS"][i].DataSet.length > 0) {
                    firstLvlZRows = i;
                }
            }
        }

        let DSCol = this.axisData[lr.AxisDefMap.COLUMNS.type][firstLvlZCols].Name;
        let DSRow = this.axisData[lr.AxisDefMap.ROWS.type][firstLvlZRows].Name;

        let DSColHierarchyId = null;
        let DSRowHierarchyId = null;
        try {
            DSColHierarchyId = this.axisData[lr.AxisDefMap.COLUMNS.type][firstLvlZCols].DataSet[0][this.axisData[lr.AxisDefMap.COLUMNS.type][firstLvlZCols].Header.HierarchyId.ColIdx];
            DSRowHierarchyId = this.axisData[lr.AxisDefMap.ROWS.type][firstLvlZRows].DataSet[0][this.axisData[lr.AxisDefMap.ROWS.type][firstLvlZRows].Header.HierarchyId.ColIdx];
        } catch {
            console.warn("Faild to get DSColHierarchyId or DSRowHierarchyId");
        }

        for (let yIter = 0; yIter < this.yLength; yIter++) {

            let yIter_latestFoundDimProp = {};

            for (let xIter = 0; xIter < this.xLength; xIter++) {
                let srcFormatCell = null;
                let insert_Y = null;
                let insert_X = null;
                const insert_cell = {};

                let skipCell = false;

                // if (dimType == "Facts") { //Always true?
                if (pgridAxis == this.AxisDefMap.FACTS) {
                    insert_Y = yLR + yIter;
                    insert_X = xLR + xIter;

                    srcFormatCell = this.adjacentCells.selfCell;


                    if (yIter == 0 && xIter == 0) {
                        insert_cell.RefType = srcFormatCell.RefType;

                        lodash.set(insert_cell, "Meta.yLRLength", this.yLength);
                        lodash.set(insert_cell, "Meta.xLRLength", this.xLength);

                        lodash.set(insert_cell, "Meta.Load.DimSizeY", this.yLength);
                        lodash.set(insert_cell, "Meta.Load.DimSizeX", this.xLength);

                        lodash.set(insert_cell, "Meta.Load.HeadersSizeY", this.yLengthHeaders);
                        lodash.set(insert_cell, "Meta.Load.HeadersSizeX", this.xLengthHeaders);

                        lodash.set(insert_cell, "Meta.Save.DSCol", DSCol);
                        lodash.set(insert_cell, "Meta.Save.DSRow", DSRow);

                        lodash.set(insert_cell, "Meta.Save.DSColHierarchyId", DSColHierarchyId);
                        lodash.set(insert_cell, "Meta.Save.DSRowHierarchyId", DSRowHierarchyId);

                        lodash.set(insert_cell, "Meta.Load.SafeOffsetDistanceY", PGridUtils.SumAllRowForAxis(this, "ROWS"));
                        lodash.set(insert_cell, "Meta.Load.SafeOffsetDistanceX", PGridUtils.SumAllRowForAxis(this, "COLUMNS"));

                        //Always insert this propertis for the LR (eaven if sum row)
                        let merged_cell_lr_meta = mergeCells(pgridTableCopy[insert_Y][insert_X], insert_cell);
                        pgridTableCopy[insert_Y][insert_X] = merged_cell_lr_meta;
                    }

                    if ("Format" in srcFormatCell) {
                        lodash.set(insert_cell, "Format", srcFormatCell["Format"]);
                    }


                    expandIfNeeded(pgridTableCopy, yLR - 1, insert_X);
                    expandIfNeeded(pgridTableCopy, insert_Y, xLR + rowCellPosDelta);

                    let dimValX = null;
                    let dimValY = null;
                    let dimValXWritable = null;
                    let dimValYWritable = null;

                    dimValX = lodash.get(pgridTableCopy[yLR - 1][insert_X], "Meta.DimVal", null);
                    dimValY = lodash.get(pgridTableCopy[insert_Y][xLR + rowCellPosDelta], "Meta.DimVal", null);


                    // See: NODE_WRITABLE
                    dimValXWritable = lodash.get(pgridTableCopy[yLR - 1][insert_X], "Meta.Writable", null);
                    dimValYWritable = lodash.get(pgridTableCopy[insert_Y][xLR + rowCellPosDelta], "Meta.Writable", null);

                    if (dimValXWritable === "null") {
                        dimValXWritable = null;
                    }
                    if (dimValYWritable === "null") {
                        dimValYWritable = null;
                    }

                    let fillEmptyDimMetaDown = lodash.get(this.FillDimPropsDown, "Rows", []);

                    //Insted of FillDimPropsDown()
                    for (let f = 0; f < fillEmptyDimMetaDown.length; f++) {

                        let p = fillEmptyDimMetaDown[f]; //path

                        let v = lodash.get(pgridTableCopy[insert_Y][xLR + rowCellPosDelta], p, null);

                        if (v != null) {
                            yIter_latestFoundDimProp[p] = v;
                        } else {
                            if (yIter_latestFoundDimProp != null) {
                                lodash.set(pgridTableCopy[insert_Y][xLR + rowCellPosDelta], p, yIter_latestFoundDimProp[p]);
                            }
                        }
                    }

                    if (dimValY && dimValX) { //Skipp if not there

                        if (String(dimValY) == "null") {
                            console.warn(`EPivot.Phase4_Insert_Metadata() dimValY is null`);
                        }

                        lodash.set(insert_cell, "Meta.Save.DimValX", dimValX);
                        lodash.set(insert_cell, "Meta.Save.DimValY", dimValY);


                        let writable = null;
                        // Case
                        //No writable information => allow write
                        if (dimValYWritable === null && dimValXWritable === null) {
                            writable = true;
                        }

                        //If only Y specified => allow if YWritable
                        else if (dimValYWritable !== null && dimValXWritable === null) {
                            writable = dimValYWritable;
                        }

                        //If only X specified => allow if XWritable
                        else if (dimValYWritable === null && dimValXWritable !== null) {
                            writable = dimValXWritable;
                        }
                        //If both specified => allow if both YWritable and XWritable
                        else {
                            writable = dimValYWritable && dimValXWritable;
                        }

                        // if (writable === false) {
                        //     lodash.set(insert_cell, "Meta.Save.ReadOnly", false);
                        // }

                        if (writable === true) {

                            lodash.set(insert_cell, "Meta.Save.Writable", true);
                        } else {
                            lodash.set(insert_cell, "Meta.Save.ReadOnly", true);

                        }

                    } else {
                        skipCell = true;
                    }

                    if (skipCell == false) {
                        let merged_cell = mergeCells(pgridTableCopy[insert_Y][insert_X], insert_cell);
                        pgridTableCopy[insert_Y][insert_X] = merged_cell;
                    }
                }
            }
        }

        PGridMatrices.ApplyCssStyles(["pg-is-content",], pgridTableCopy, yLR, xLR, this.yLength, this.xLength);
        PGridMatrices.ApplyCssStyles(["pg-is-colheader"], pgridTableCopy, yLR - this.yLengthHeaders, xLR, this.yLengthHeaders, this.xLength);
        PGridMatrices.ApplyCssStyles(["pg-is-rowheader"], pgridTableCopy, yLR, xLR - this.xLengthHeaders, this.yLength, this.xLengthHeaders);

        PGridMatrices.ApplyCssStyles(["pg-is-lr", "pg-is-epivot"], pgridTableCopy, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, this.yLength + this.yLengthHeaders, this.xLength + this.xLengthHeaders);
        PGridMatrices.ApplyCssStyles(["pg-is-lr-border-top"], pgridTableCopy, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);
        PGridMatrices.ApplyCssStyles(["pg-is-lr-border-left"], pgridTableCopy, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, this.yLength + this.yLengthHeaders, 1);

        PGridMatrices.ApplyCssStyles(["pg-is-lr-border-bottom"], pgridTableCopy, yLR + this.yLength, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);
        PGridMatrices.ApplyCssStyles(["pg-is-lr-border-right"], pgridTableCopy, yLR - this.yLengthHeaders, xLR + this.xLength, this.yLength + this.yLengthHeaders, 1);

        PGridMatrices.ApplyCssStyles(["pg-is-lr-just-left"], pgridTableCopy, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders - 1, this.yLength + this.yLengthHeaders, 1);
        PGridMatrices.ApplyCssStyles(["pg-is-lr-just-above"], pgridTableCopy, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);

        PGridMatrices.ApplyCssStyles(["pg-is-content",], pgridTableCopy, yLR, xLR, this.yLength, this.xLength);
        PGridMatrices.ApplyCssStyles(["pg-is-colheader"], pgridTableCopy, yLR - this.yLengthHeaders, xLR, this.yLengthHeaders, this.xLength);

        for (let lvl = 0; lvl < this.xLengthHeaders; lvl++) {
            PGridMatrices.ApplyCssStyles([`pg-is-rowheader-lvl-${lvl}`], pgridTableCopy, yLR, xLR - this.xLengthHeaders + lvl, this.yLength, 1);
        }
        for (let lvl = 0; lvl < this.yLengthHeaders; lvl++) {
            PGridMatrices.ApplyCssStyles([`pg-is-colheader-lvl-${lvl}`], pgridTableCopy, yLR - this.yLengthHeaders + lvl, xLR, 1, this.xLength);
        }

        //The upper left "cackebite" 
        if (this.RemoveUpperRightCakebite) {
            PGridMatrices.ApplyCssStyles([
                "pg-is-lr-just-above",
                "pg-is-lr-just-left",
                "pg-is-lr",
                "pg-is-epivot",
                "pg-is-lr-border-top",
                "pg-is-lr-border-left"
            ], pgridTableCopy, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders - 1, this.yLengthHeaders + 1, this.xLengthHeaders + 1, false);
        }

        PGridMatrices.ApplyCssStyles([
            "pg-is-lr-just-above"
        ], pgridTableCopy, yLR - 1, xLR - this.xLengthHeaders, 1, this.xLengthHeaders, true);

        PGridMatrices.ApplyCssStyles([
            "pg-is-lr-just-left"
        ], pgridTableCopy, yLR - this.yLengthHeaders, xLR - 1, this.yLengthHeaders, 1, true);



        if (this.overlayCSSClass) {
            PGridMatrices.ApplyCssStyles([
                this.overlayCSSClass
            ], pgridTableCopy, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders - 1, this.yLength + this.yLengthHeaders + 2, this.xLength + this.xLengthHeaders + 2, true);
        }

        return pgridTableCopy;
    }


    Phase6_Generate_FactRange(pgridDataDyn, factData) {
        let factRangeData = null;

        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase6_Generate_FactRange() name: ${this.name}` }).y;

        let yLR = this.y; // + lr.RowsOffset dont, add offset, as this.y is allready "in place" updated
        let xLR = this.x;

        //Make a copy
        // let pgridDataDynCopy = JSON.parse(JSON.stringify(pgridDataDyn));

        try {

            const factDataParsed = new Array();


            //Step 1 - parse fact data from db

            if ("Rows" in factData) {
                //There are fact rows


                //Translate Name Val to N W
                let parseFilterCellKey = function (inJsonTxt) {

                    let ret = [];
                    let filterKey = JSON.parse(inJsonTxt)

                    for (let i = 0; i < filterKey.length; i++) {
                        ret.push({ N: filterKey[i].Name, W: filterKey[i].Val });
                    }

                    return ret;
                }

                // let filterDimKeyValues = JSON.parse(factData.FilterKey);

                let filterDimKeyValues = parseFilterCellKey(factData.FilterKey);

                // let dimLookupTable = factData.DimLookup.sort((a, b) => {
                //     if (a.Id > b.Id) {
                //         return 1;
                //     }
                //     if (a.Id < b.Id) {
                //         return -1;
                //     }
                //     return 0;
                // }).map(o => o.DimName);
                // let dimLookupTable = factData.DimLookup.forEach(o) => {
                //     return 0;
                // }).map(o => o.DimName);


                let dimLookupTable = {};
                if ("DimLookup" in factData) {
                    factData.DimLookup.forEach(o => {
                        dimLookupTable[o.Id] = o.DimName;
                    });
                }


                for (let k = 0; k < factData.Rows.length; k++) {
                    let fItem = factData.Rows[k];



                    let newData = {};

                    let hasValue = false;
                    if ("V" /*"Val"*/ in fItem) {
                        // newData.Val = fItem.Val;
                        newData.Val = fItem.V;
                        hasValue = true;
                    }
                    if ("S" /*"ValStr"*/ in fItem) {
                        // newData.ValStr = fItem.ValStr;
                        newData.ValStr = fItem.S;
                        hasValue = true;
                    }
                    if (!hasValue) {
                        window.PGridClientDebugMode >= 3 && console.debug(`PGridLR_EPrivot.js: This fact row has no value: ${JSON.stringify(fItem)}`);
                    }
                    // let rowDimKeyValues = JSON.parse(fItem.CellKey);
                    let rowDimKeyValues = fItem.K;  //Handles the new non-stringified format


                    //Insert DimLookup values
                    for (let i = 0; i < rowDimKeyValues.length; i++) {
                        rowDimKeyValues[i].N = dimLookupTable[rowDimKeyValues[i].D];
                    }

                    //https://stackoverflow.com/questions/39127565/merge-array-of-objects-by-property-using-lodash
                    let rowAndFilterSimKeyValues = lodash.unionBy(rowDimKeyValues, filterDimKeyValues, "N");

                    if (rowDimKeyValues != null && Array.isArray(rowDimKeyValues) && rowDimKeyValues.length > 0) {

                        if (rowDimKeyValues != null && length in rowDimKeyValues) {
                        }
                        else {
                            false && console.warn("This clause gave false before");

                        }

                        rowAndFilterSimKeyValues.forEach(keyValPair => {

                            let keyId = null;
                            if (isNaN(keyValPair.W)) {
                                keyId = keyValPair.W;
                            }
                            else {
                                keyId = Number(keyValPair.W);
                            }

                            if (!("Key" in newData)) {
                                newData.Key = {};
                            }
                            newData.Key[keyValPair.N] = keyId;
                        });


                        factDataParsed.push(newData);
                    } else {
                        console.warn("Empty cellXYKey");
                    }
                }
            }

            const pgridDataLRCell = pgridDataDyn[yLR][xLR];

            //Only load if has Load meta in LRCell
            if (lodash.get(pgridDataLRCell, "Meta.Load", null) != null) {

                //Get stored LR dimension sizes
                this.yLength = pgridDataLRCell.Meta.Load.DimSizeY;
                this.xLength = pgridDataLRCell.Meta.Load.DimSizeX;

                //Step 2 - Initialize 2D data structure

                // factRangeData = [...Array(this.yLength)].map(x => Array(this.xLength).fill({})); // https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript
                factRangeData = [];

                for (let newY = 0; newY < this.yLength; newY++) {
                    let newRow = [];
                    for (let newX = 0; newX < this.xLength; newX++) {
                        newRow.push(undefined);
                    }
                    factRangeData.push(newRow);
                }

                let lookupX = {};
                let lookupY = {};

                // Step 2 - Get lookups for x axis
                for (let x = 0; x < this.xLength /* pgridDataLRCell.Meta.Load.DimSizeX */; x++) {

                    let xLookupRef = pgridDataDyn[yLR - 1][xLR + x];

                    if (lodash.get(xLookupRef, "Meta.DimVal", undefined) !== undefined) {
                        lookupX[xLookupRef.Meta.DimVal] = x;
                    } else {
                        console.debug(`Missing Meta.DimVal x - axis at x: ${xLR + x} y: ${yLR - 1}, which is normal when using spaces between groups`);
                    }
                }

                // Step 3 - Get lookups for y axis
                for (let y = 0; y < this.yLength/*pgridDataLRCell.Meta.Load.DimSizeY*/; y++) {

                    let yLookupRef = pgridDataDyn[yLR + y][xLR - 1];
                    if (lodash.get(yLookupRef, "Meta.DimVal", undefined) !== undefined) {

                        let yCoord = yLookupRef.Meta.DimVal;

                        lookupY[yCoord] = y;

                    } else {
                        false && console.debug(`Missing Meta.DimVal y - axis at x: ${xLR - 1} y: ${yLR + y}, which is normal when using spaces between groups`);
                    }

                }


                // Step 4 fill in data
                for (let j = 0; j < factDataParsed.length; j++) {
                    let insertColKeyVal = null;
                    let insertRowsKeyVal = null;
                    let insertFact = factDataParsed[j];

                    let colKey = pgridDataLRCell.Meta.Save.DSCol;
                    let rowKey = pgridDataLRCell.Meta.Save.DSRow;

                    insertColKeyVal = insertFact.Key[colKey];
                    insertRowsKeyVal = insertFact.Key[rowKey];

                    if (insertColKeyVal != undefined && insertRowsKeyVal != undefined) {

                        //Seems to by "my" facts 

                        const insertX = lookupX[insertColKeyVal];
                        const insertY = lookupY[insertRowsKeyVal];


                        if (insertX == undefined || insertY == undefined) {
                            //Skipp this, probably data for another LR

                            if (insertX == undefined) {
                                if (window.PGridClientDebugMode >= 3) {
                                    console.debug(`LR: ${this.name} Faild to find matching col for col dim '${insertColKeyVal}' val: ${insertFact.Val || insertFact.ValStr}`);
                                }
                            }
                            if (insertY == undefined) {
                                if (window.PGridClientDebugMode >= 3) {
                                    console.debug(`LR: ${this.name} Faild to find matching row for '${insertRowsKeyVal}' val: ${insertFact.Val || insertFact.ValStr}`);
                                }
                            }

                        } else {

                            factRangeData[insertY][insertX] = {};

                            if ("Val" in insertFact) {

                                factRangeData[insertY][insertX].Val = insertFact.Val;
                            } else if ("ValStr" in insertFact) {
                                factRangeData[insertY][insertX].ValStr = insertFact.ValStr;
                            }
                            // else {
                            //     console.error("This is wrong");
                            // }
                        }
                    }
                }

            }
            else {
                console.warn("Missing Meta.Load");
            }

        } catch (ex) {
            console.error(`Get_FactsForLR_error - ${ex.message}, stack trace - ${ex.stack}`);
        }

        return factRangeData;
    }
}

